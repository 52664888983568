import { Component, OnInit } from '@angular/core';

import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData, ParagraphComponent } from '@spartacus/storefront';
import { RegistrationConfirmationService } from '../../services/registration-confirmation.services';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ErrorType } from '@shared/models/ErrorTypes';

@Component({
  selector: 'app-registration-confirmation',
  templateUrl: './registration-confirmation.component.html'
})
export class RegistrationConfirmationComponent extends ParagraphComponent implements OnInit {
  sending$: Observable<boolean>;
  error$: Observable<ErrorType>;

  constructor(
    public component: CmsComponentData<CmsParagraphComponent>,
    private registrationConfirmationService: RegistrationConfirmationService,
    protected router: Router,
  ) {
    super(component, router);
  }

  ngOnInit(): void {
    const token = decodeURIComponent(this.router.url.split('=')[1]);

    this.registrationConfirmationService.registrationConfirmation(token);
    this.sending$ = this.registrationConfirmationService.isSending();
    this.error$ = this.registrationConfirmationService.getError();
  }
}
