import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransportDamageRequest } from '../../model/transport-damage.model';
import { TransportDamageAdapter } from '../adapters/transport-damage.adapter';

@Injectable({
  providedIn: 'root',
})
export class TransportDamageConnector {
  constructor(protected adapter: TransportDamageAdapter) {}

  adviseOfTransportDamage(request: TransportDamageRequest): Observable<void> {
    return this.adapter.adviseOfTransportDamage(request);
  }
}
