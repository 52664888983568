import { ChangeDetectionStrategy, Component } from '@angular/core';
import { filter, first, switchMap, take } from 'rxjs/operators';
import {
  OutletContextData,
  TableDataOutletContext,
} from '@spartacus/storefront';
import {
  LoadStatus,
  OrganizationItemStatus,
} from '@spartacus/organization/administration/core';
import { Observable } from 'rxjs';
import {
  AssignCellComponent,
  CellComponent,
  ItemService,
  ListService, MessageService,
  SubListService
} from '@spartacus/organization/administration/components';

@Component({
  selector: 'app-efa-org-assign-cell',
  template: `
    <!-- <button *ngIf="hasItem" (click)="toggleAssign()" class="link">
      {{ isAssigned ? ('userProfiles.unassign' | cxTranslate) : ('userProfiles.assign' | cxTranslate) }}
    </button> -->
   <span class="form-check check-item">
   <input type="checkbox" class="form-check-input"   [checked]="isAssigned"
                            (change)="toggleAssign()" />
   </span>

  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaAssignCellComponent<T> extends AssignCellComponent<T> {
}
//   constructor(
//     protected outlet: OutletContextData<TableDataOutletContext>,
//     protected organizationItemService: ItemService<T>,
//     protected messageService: MessageService,
//     protected organizationSubListService: ListService<T>
//   ) {
//     super(outlet, organizationItemService, messageService, organizationSubListService);
//   }
//
//   get isAssigned(): boolean {
//     return (this.item as any)?.selected;
//   }
//
//   toggleAssign(): void {
//     const isAssigned = this.isAssigned;
//     this.organizationItemService.key$
//       .pipe(
//         first(),
//         switchMap((key) =>
//           isAssigned
//             ? this.unassign(key, this.link)
//             : this.assign(key, this.link)
//         ),
//         take(1),
//         filter(
//           (data: OrganizationItemStatus<T>) =>
//             data.status === LoadStatus.SUCCESS
//         )
//       )
//       .subscribe((data) =>
//         this.notify(data.item, isAssigned ? 'unassigned' : 'assigned')
//       );
//   }
//
//   protected assign(
//     key: string,
//     linkKey: string
//   ): Observable<OrganizationItemStatus<T>> {
//     return (this.organizationSubListService as SubListService<T>).assign(
//       key,
//       linkKey
//     );
//   }
//
//   protected unassign(
//     key: string,
//     linkKey: string
//   ): Observable<OrganizationItemStatus<T>> {
//     return (this.organizationSubListService as SubListService<T>).unassign(
//       key,
//       linkKey
//     );
//   }
//
//   /**
//    * Returns the key for the linked object.
//    *
//    * At the moment, we're using a generic approach to assign objects,
//    * but the object do not have a normalized shape. Therefor, we need
//    * to evaluate the context to return the right key for the associated
//    * item.
//    */
//   protected get link(): string {
//     return (
//       this.outlet.context.code ??
//       this.outlet.context.customerId ??
//       this.outlet.context.uid ??
//       this.outlet.context.identifier
//     );
//   }
//
//   protected notify(item, state): void {
//     this.messageService.add({
//       message: {
//         key: `${this.organizationSubListService.viewType}.${state}`,
//         params: {
//           item,
//         },
//       },
//     });
//   }
// }
