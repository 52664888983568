export const translationOverwrites = {
  en: { // lang
    common: {
      formErrors: {
        maxlength: 'Please don\'t insert more than {{requiredLength}} characters.',
        minlength: 'Please don\'t insert less than {{requiredLength}} characters.',
        pattern: 'the format is invalid.',
        maxFileSizeExceeded: 'The maximum file size ({{maxFileSize}} MB) was exceeded.',
        fileEmpty: 'File is empty',
        max: 'Maximum number was exceeded.',
        min: 'The number entered is below the minimum number {{min}}.',
        validFileExtension: 'Only the following file extensions are supported: ({{fileExtensions}}).',
      },
      sorting: {
        total: 'Total',
        documentType: 'Document Type',
      },
      httpHandlers: {
        validationErrors: {
          invalid: {
            documents: 'There are mandatory files missing.',
            sepaIban: 'The given IBAN is not valid. Please check you input.'
          }
        }
      },
      searchBox: {
        placeholder: 'Search for oil, accessories or tools',
        ariaLabelInput: 'Search for oil, accessories or tools',
      }
    },
    product: { // chunk
      addToCart: {
        addToCart: 'Add to cart',
      },
      productSummary: {
        id: 'Article Number'
      },
      productList: {
        appliedFilter: 'Current Filter:'
      },
    },
    organization: {
      organization: {
        add: 'Create user',
        done: 'Save',
      },
      orgUserGroup: {
        header: 'All rights profiles ({{count}})',
        roles: 'Permissions',
        links: {
          permission: 'Permissions'
        },
        action: {
          cancel: 'Cancel'
        },
        details: {
          title: 'Details Permission Profile',
          subtitle: '{{ item.name }}',
        },
        edit: {
          title: 'Edit permission profile',
          subtitle: 'Permission Profile: {{ item.name }}',
        },
        breadcrumbs: {
          list: 'All permission profiles',
          details: '{{name}}',
          users: 'Users',
          permissions: 'Permissions',
        },
        hint:
          'User groups allow you to combine users together in one group, for the purposes of assigning permissions for many users at once.',
      },
      orgUserGroupAssignedPermissions: {
        title: 'Assigned permissions',
        subtitle: '{{ item.name }}',
        assigned: 'Permission \'{{item.name}}\' assigned successfully',
        unassigned: 'Permission \'{{item.name}}\' unassigned successfully',
      },
      orgUserGroupPermissions: {
        title: 'Manage permissions',
        subtitle: '{{ item.name }}',
        assigned: 'Permission \'{{item.name}}\' assigned successfully',
        unassigned: 'Permission \'{{item.name}}\' unassigned successfully',
      },
      orgUserGroupAssignedUsers: {
        subtitle: '{{ item.name }}',
      },
      orgUserGroupUsers: {
        subtitle: '{{ item.name }}',
      },
      orgUser: {
        uid: 'ID',
        roles: 'Rights profiles',
        permissionGroupNames: 'Rights profiles',
        name: 'Name',
        active: 'Status',
        lastLogin: 'Last login',
        email: 'E-mail address',
        userGroup: {
          link: 'Permission profiles'
        },
        links: {
          userGroup: 'Set permission profiles'
        },
        breadcrumbs: {
          userGroups: 'Permission profiles'
        },
        gender: {
          '0001': 'Ms / Mrs',
          '0002': 'Mr',
          '0003': 'Mx'
        },
        details: {
          title: 'User details',
          subtitle: '{{ item.name }}',
        },
        hint:
          'Users can access the shop and have different permissions regarding MyAccount and checkout which are controlled by permission profiles.',
        noProfilesAssigned: 'There aren\'t any assigned permission profiles',
      },
      orgUserAssignedUserGroups: {
        title: 'Assigned permission profiles',
        subtitle: '{{ item.name }}',
        assigned: 'Permission profile \'{{item.name}}\' assigned successfully',
        unassigned: 'Permission profile \'{{item.name}}\' unassigned successfully',
      },
      orgUserUserGroups: {
        title: 'Manage permission profiles',
        subtitle: '{{ item.name }}',
        assigned: 'Permission profile \'{{item.name}}\' assigned successfully',
        unassigned: 'Permission profile \'{{item.name}}\' unassigned successfully',
      }
    },
    orderCost: {
      orderSummary: 'Summary',
      subtotal: 'Subtotal',
      shipping: 'Freight charges',
      estimatedShipping: 'Estimated shipping',
      discount: 'You saved',
      salesTax: 'VAT',
      total: 'Total',
    },
    checkout: {
      checkoutReview: {
        review: 'Summary',
        placeOrder: 'Chargeable order',
      },
      checkoutShipping: {
        shippingMethod: 'Delivery type',
        standardDelivery: 'Standard Delivery',
        premiumDelivery: 'Premium Delivery',
      },
      checkoutOrderConfirmation: {
        thankYou: 'Thank you for your order!',
        confirmationOfOrder: 'Your order number:',
        confirmationOfConfiguratorOrder: 'Your order will be created briefly.',
        invoiceHasBeenSentByEmail: 'Your invoice will be sent by email to {{email}}. You can see an overview of the status of your order in the',
        orderHistory: 'Order history.',
        orderItems: 'Ordered Items',
      },
    },
    address: {
      addressCard: {
        shipTo: 'Delivery address',
        setAsDefault: 'Set as default address',
      }
    },
    myAccount: {
      orderHistory: {
        total: 'Total (net)',
      },
      updateProfileForm: {
        title: 'Salutation',
        gender: {
          '0001': 'Ms / Mrs',
          '0002': 'Mr',
          '0003': 'Mx'
        },
        locName: 'Customer Name',
        b2bUnitId: 'Customer ID',

      },
      updatePasswordForm: {
        oldPassword: {
          label: 'Current Password',
          placeholder: 'Current Password',
        },
        passwordMinRequirements: 'The password must contain a capital letter, a number and a special character and be at least 6 characters long.',
        confirmPassword: {
          label: 'Confirm new password',
          placeholder: 'Confirm new password',
        },
        action: {
          cancel: 'Cancel',
          save: 'Save'
        },
        formMandatoryFields: 'Mandatory fields are marked with *.'
      },
    },
    cart: {
      cartDetails: {
        proceedToCheckout: 'Check order'
      },
      cartItems: {
        id: 'Art.-Nr.',
        quantity: 'Amount',
        item: 'Article',
        itemPrice: 'Unit price',
        itemPriceRrp: 'Unit price RRP'
      }
    },
    order: {
      orderDetails: {
        orderNumber: 'Your order number',
        cancellationAndReturn: {
          submit: 'Cancel items',
        }
      }
    },
    wishlist: {
      wishlist: {
        empty: 'Your watchlist is empty.',
        notfound: 'There aren\'t any articles in the wishlist belonging to the selected brands.'
      },
    },
    user: {
      anonymousConsents: {
        banner: {
          description:
            "We use cookies to improve your experience and analyze your interactions with our website. Below you can make an individual selection of those technically non-essential cookies that you\n" +
            "would like to allow us to use and consent to the processing of your personal data accordingly. You can change your selection at any time via the consent management link in the footer of our website.",
        },
      }
    }

  },
  de: {
    product: { // chunk
      addToCart: {
        itemsAddedToYourCart: 'Zum Warenkorb hinzugefügte Artikel',
        items: 'Artikel',
        updatingCart: 'Aktualisiere Warenkorb...',
        addToCart: 'In den Warenkorb',
        viewCart: 'Warenkorb bearbeiten',
        proceedToCheckout: 'Zum Checkout',
        quantity: 'Menge',
      },
      productSummary: {
        id: 'Artikelnummer'
      },
      productList: {
        filterBy: {
          label: 'Filtern nach',
          action: 'Filtern nach',
        },
        appliedFilter: 'Aktueller Filter:',
        showLess: 'Weniger anzeigen...',
        showMore: 'Mehr anzeigen...',
        sortBy: 'Sortieren nach',
        backToTopBtn: 'Zurück nach oben',
      },
      addToWishList: {
        add: 'Zur Merkliste hinzufügen',
        remove: 'Aus der Merkliste entfernen'
      },
      productDetails: {
        productDetails: 'Beschreibung'
      },
      TabPanelContainer: {
        tabs: {
          ProductDetailsTabComponent: 'Beschreibung'
        }
      }
    },
    common: {
      common: {
        cancel: 'Abbrechen',
        delete: 'Löschen',
        remove: 'Entfernen',
        edit: 'Bearbeiten',
        back: 'Zurück',
        submit: 'Senden',
        submitForgotPassword: 'Mail anfordern zur Passwortänderung',
        continue: 'Weiter',
        save: 'Speichern',
        done: 'Erledigt',
        home: 'Home'
      },
      pageMetaResolver: {
        baseSite: {
          name: 'EFA Autoteilewelt'
        },
        category: {
          title: '{{count}} Treffer für Kategorie \'{{query}}\'',
          title_plural: '{{count}} Treffer für Kategorie \'{{query}}\''
        },
        checkout: {
          title: 'Checkout'
        },
        search: {
          title: '{{count}} Treffer für \'{{query}}\'',
          title_plural: '{{count}} Treffer für \'{{query}}\'',
        },
        product: {
          description: '{{description}}',
          heading: '{{heading}}',
          title: '{{title}}'
        }
      },
      spinner: {
        loading: 'Lädt...'
      },
      navigation: {
        shopAll: 'Zeige alle {{ navNode}} >'
      },
      searchBox: {
        placeholder: 'Suche nach Öl, Zubehör oder Werkzeug',
        ariaLabelInput: 'Suche nach Öl, Zubehör oder Werkzeug',
        help: {
          insufficientChars: 'Bitte geben Sie mehr Buchstaben ein',
          noMatch: 'Wir konnten keine Ergebnisse finden',
          exactMatch: '{{ term }}',
          empty: 'Fragen Sie uns etwas'
        }
      },
      sorting: {
        date: 'Datum',
        orderNumber: 'Bestellnummer',
        total: 'Gesamtsumme',
        documentType: 'Belegart',
      },
      httpHandlers: {
        badRequest: {
          bad_credentials: 'Die Zugangsdaten waren ungültig. Bitte versuchen Sie es erneut.',
        },
        badGateway: 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
        badRequestPleaseLoginAgain: 'Die Zugangsdaten waren ungültig. Bitte versuchen Sie es erneut.',
        badRequestOldPasswordIncorrect: 'Altes Passwort, nicht korrekt.',
        conflict: 'Existiert bereits',
        forbidden: 'Sie sind nicht berechtigt diese Aktion auszuführen.',
        gatewayTimeout: 'Der Server reagiert nicht, bitte versuchen Sie es später erneut.',
        internalServerError: 'Ein interner Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
        sessionExpired: 'Die Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
        unknownError: 'Ein unbekannter Fehler ist aufgetreten',
        unauthorized: {
          common: 'Ein unbekannter Autorisierungsfehler ist aufgetreten',
          invalid_client: 'Falsche Zugangsdaten'
        },
        cartNotFound: 'Warenkorb nicht gefunden.',
        invalidCodeProvided: 'Ungültiger Code angegeben.',
        orderNotPlaced: 'Fehler bei Bestellung, bitte wenden Sie sich an das Support-Team',
        sapOrderSimulationError: 'Bei der Berechnung des Warenkorbs ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.',
        api_down: 'Das Backend ist derzeit nicht verfügbar. Bitte versuchen Sie es später noch einmal',
        validationErrors: {
          invalid: {
            documents: 'Es wurden nicht alle erforderlichen Dokumente übergeben.',
            sepaIban: 'Die angegebene IBAN ist nicht gültig. Bitte überprüfen Sie Ihre Angaben.'
          }
        }
      },
      miniCart: {
        item: '{{count}} Artikel ist momentan im Warenkorb',
        item_plural: '{{count}} Artikel sind momentan im Warenkorb',
        total: '{{total}}',
        count: '{{count}}'
      },
      miniLogin: {
        userGreeting: 'Hallo, {{name}}',
        signInRegister: 'Anmelden/Registrieren'
      },
      skipLink: {
        skipTo: 'Überspringen zu',
        labels: {
          header: 'Header',
          main: 'Hauptinhalt',
          footer: 'Footer',
          productFacets: 'Produkt-Facetten',
          productList: 'Produktliste'
        }
      },
      formErrors: {
        globalMessage: 'Das Formular, das Sie absenden möchten, enthält Fehler.',
        required: 'Dies ist eine Pflichtangabe',
        cxInvalidEmail: 'Dies ist kein gültiges E-Mail-Format',
        cxInvalidPassword: 'Dies ist kein gültiges Passwort-Format',
        cxPasswordsMustMatch: 'Die beiden Passwort-Felder müssen übereinstimmen',
        cxEmailsMustMatch: 'Die beiden E-Mail-Felder müssen übereinstimmen',
        cxStarRatingEmpty: 'Das Bewertungsfeld muss ausgefüllt werden.',
        cxNoSelectedItemToCancel: 'Wählen Sie mindestens einen Artikel um abzubrechen',
        pattern: 'Das Format ist ungültig.',
        maxFileSizeExceeded: 'Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.',
        fileEmpty: 'Die Datei besitzt keinen Inhalt.',
        max: 'Die Maximalzahl wurde überschritten.',
        min: 'Die eingegebene Zahl liegt unter dem Minimalwert {{min}}.',
        maxlength: 'Bitte fügen Sie nicht mehr als {{requiredLength}} Zeichen ein.',
        minlength: 'Bitte fügen Sie nicht weniger als {{requiredLength}} Zeichen ein.',
        validFileExtension: 'Es werden nur folgende Dateierweiterungen unterstützt: ({{fileExtensions}}).',
      }
    },
    organization: {
      organization: {
        add: 'Benutzer erstellen',
        save: 'Speichern',
        edit: 'Ändern',
        assign: 'Zuordnen',
        done: 'Speichern',
        cancel: 'Abbrechen',
        enabled: 'Aktiv',
        disabled: 'Inaktiv',

        enable: 'Aktivieren',
        disable: 'Deaktivieren',
        messages: {
          emptyList: 'keine Zuordnungen',
        },
        confirmation: {
          cancel: 'Abbrechen',
          confirm: 'Bestätigen',
          disable: 'Deaktivieren',
          delete: 'Löschen',
        },
        httpHandlers: {
          conflict: {
            user: 'Ein Benutzer mit E-Mail Adresse {{ code }} existiert bereits für diesen Kunden.',
          }
        }
      },
      orgUserGroup: {
        header: 'Alle Rechteprofile ({{count}})',
        byName: 'Name',
        byUnitName: 'Organisation',
        byGroupID: 'Profile-ID',
        roles: 'Berechtigungen',
        action: {
          cancel: 'Abbrechen'
        },
        links: {
          permission: 'Berechtigungen',
          user: 'Benutzer',
        },
        details: {
          title: 'Details Rechteprofil',
          subtitle: '{{ item.name }}',
        },
        create: {
          title: 'Rechteprofil erstellen',
        },
        edit: {
          title: 'Rechteprofil ändern',
          subtitle: 'Rechteprofil: {{ item.name }}',
        },
        messages: {
          update: 'Rechteprofil \'{{ item.name }}\' erfolgreich aktualisiert',
          create: 'Rechteprofil \'{{ item.name }}\' erfolgreich erstellt',
          deleteTitle: 'Rechteprofil löschen',
          delete: 'Sind Sie sicher, dass dieses Rechteprofil \'{{ item.name }}\' gelöscht werden soll?',
          deleteBody:
            'Die Benutzer dieses Profils bleiben bestehen.',
          deleted: 'Rechteprofil \'{{ item.name }}\' erfolgreich gelöscht',
        },
        breadcrumbs: {
          list: 'Alle Rechteprofile',
          details: '{{name}}',
          users: 'Benutzer',
          permissions: 'Berechtigungen',
        },
        hint:
          'Rechteprofile autorisieren User, Shop-Funktionen zu nutzen. Die fünf Standard-Profile lassen sich konfigurieren. Jedem bestehenden und neuen Nutzer muss ein Profil zugeordnet sein.',
      },
      orgUserGroupAssignedPermissions: {
        title: 'Zugeordnete Berechtigungen',
        subtitle: '{{ item.name }}',
        assigned: 'Berechtigung \'{{item.name}}\' erfolgreich zugeordnet',
        unassigned: 'Berechtigung \'{{item.name}}\' erfolgreich entfernt',
      },
      orgUserGroupPermissions: {
        title: 'Berechtigungen verwalten',
        subtitle: '{{ item.name }}',
        assigned: 'Berechtigung \'{{item.name}}\' erfolreich zugeordnet',
        unassigned: 'Berechtigung \'{{item.name}}\' erfolgreich entfernt',
      },
      orgUserGroupAssignedUsers: {
        title: 'Zugeordnete Benutzer',
        subtitle: '{{ item.name }}',
        assigned: 'Benutzer \'{{item.name}}\' erfolgreich zugeordnet',
        unassigned: 'Benutzer \'{{item.name}}\' erfolgreich entfernt',
      },
      orgUserAssignedUserGroups: {
        title: 'Zugeordnete Rechteprofile',
        subtitle: '{{ item.name }}',
        assigned: 'Rechteprofil \'{{item.name}}\' erfolgreich zugeordnet',
        unassigned: 'Rechteprofil \'{{item.name}}\' erfolgreich entfernt'
      },
      orgUserGroupUsers: {
        title: 'Benutzer verwalten',
        subtitle: '{{ item.name }}',
        assigned: 'Benutzer \'{{item.name}}\' erfolgreich zugeordnet',
        unassigned: 'Benutzer \'{{item.name}}\' erfolgreich entfernt',
        unassignAll: 'Alle entfernen',
        unassignAllConfirmation: 'Alle Benutzer erfolgreich entfernt',
      },
      orgUserUserGroups: {
        title: 'Rechteprofile verwalten',
        subtitle: '{{ item.name }}',
        assigned: 'Rechteprofil \'{{item.name}}\' erfolgreich zugeordnet',
        unassigned: 'Rechteprofil \'{{item.name}}\' erfolgreich entfernt',
      },
      orgUser: {
        header: 'Alle Benutzer ({{count}})',
        disabled: '(deaktiviert)',
        uid: 'ID',
        roles: 'Rechteprofile',
        permissionGroupNames: 'Rechteprofile',
        lastLogin: 'Letzte Anmeldung',
        active: 'Status',
        name: 'Name',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail Adresse',
        orgUnit: 'Unternehmen',
        unit: 'Unternehmen',
        title: 'Anrede',
        hint:
          'Für ein bestehendes Konto bei Autoteilewelt können mehrere Nutzer angelegt werden. Jeder Nutzer erhält ein Rechteprofil. Ergänzen Sie hier Nutzer, vergeben Sie Rechte oder deaktivieren Sie Nutzer.',
        noProfilesAssigned: 'Es sind noch keine Rechteprofile zugeordnet.',
        actions: '',

        byName: 'Name',
        byUnit: 'Organisation',
        gender: {
          '0001': 'Frau',
          '0002': 'Herr',
          '0003': 'Firma'
        },
        details: {
          title: 'Benutzer Details',
          subtitle: '{{ item.name }}',
        },
        edit: {
          title: 'Benutzer ändern',
          subtitle: 'Benutzer: {{ item.name }}',
        },
        create: {
          title: 'Benutzer erstellen',
          subtitle: '',
        },

        links: {
          password: 'Passwort ändern',
          userGroup: 'Rechteprofile festlegen',
          permission: 'Berechtigungen',
        },

        messages: {
          deactivateTitle: 'Benutzer deaktivieren',
          deactivate: 'Sind Sie sicher, dass Sie diesen Benutzer deaktivieren wollen?',
          deactivateBody:
            'Deaktivierte Benutzer können sich nicht am Shop anmelden.',
          confirmEnabled:
            'Benutzer \'{{item.firstName}} {{item.lastName}}\' erfolgreich aktiviert',
          confirmDisabled:
            'Benutzer \'{{item.firstName}} {{item.lastName}}\' erfolgreich deaktiviert',
          update: 'Benutzer \'{{item.firstName}} {{item.lastName}}\' erfolgreich aktualisiert',
          create: 'Benutzer erfolgreich erstellt. Bitte ordnen Sie noch passende Rechteprofile zu.',
          updatePassword:
            'Passwort von Benutzer \'{{item.firstName}} {{item.lastName}}\' erfolgreich aktualisiert',
        },
        info: {
          disabledEdit: 'Aktivieren Sie den Benutzer, um Änderungen zu ermöglichen.'
        },
        userGroup: {
          link: 'Rechteprofile',
          header: 'Rechteprofile von {{code}}',
          assign: 'Rechteprofile zuordnen',
          assignHeader: 'Rechteprofile von {{code}}',
          back: 'zurück',
          instructions: {
            check:
              'To assign an user group to this user, select the user\'s check box.',
            uncheck: 'To remove aa user group, clear the user\'s check box.',
            changes: 'Changes are saved automatically.',
          },
        },

        permission: {
          link: 'Berechtigungen',
          header: 'Berechtigungen von {{code}}',
          assign: 'Berechtigungen zuordnen',
          assignHeader: 'Berechtigungen zuordnen von {{code}}',
          back: 'zurück',
          instructions: {
            check: 'To assign a purchase limits to this user, select its check box.',
            uncheck: 'To unassign a purchase limits, clear its check box.',
            changes: 'Changes are saved automatically.',
          },
        },

        password: {
          title: 'Passwort ändern',
          subtitle: 'Benutzer: {{ item.email }}',
          newPassword: 'Neues Passwort',
          confirmPassword: 'Passwort wiederholen',
        },

        breadcrumbs: {
          list: 'Alle Benutzer',
          details: '{{name}}',
          userGroups: 'Rechteprofile',
          approvers: 'Approvers',
          permissions: 'Berechtigungen',
        },
      }
    },
    cart: {
      cartDetails: {
        proceedToCheckout: 'Bestellung prüfen',
        cartName: 'Warenkorb #{{code}}',
      },
      cartItems: {
        id: 'Art.-Nr.',
        description: 'Beschreibung',
        item: 'Artikel',
        itemPrice: 'Einzelpreis',
        itemPriceRrp: 'Einzelpreis UPE',
        quantity: 'Menge',
        quantityTitle:
          'Die Menge stellt die Gesamtanzahl dieses Artikels in Ihrem Warenkorb dar.',
        total: 'Summe',
        cartTotal: 'Warenkorb Gesamtartikel ({{count}})',
        cartTotal_plural: 'Warenkorb Gesamtartikel ({{count}})'
      },
      orderCost: {
        orderSummary: 'Zusammenfassung',
        subtotal: 'Zwischensumme',
        shipping: 'Frachtkosten',
        estimatedShipping: 'Geschätzter Versand',
        discount: 'Sie haben gespart',
        salesTax: 'MWST.',
        grossTax: 'Die Bestellsumme enthält keine Steuern von',
        grossIncludeTax: 'Die Gesamtsumme der Bestellung beinhaltet Steuern von',
        total: 'Summe',
        toBeDetermined: 'TBD',
      },
      voucher: {
        coupon: 'Haben Sie einen Coupon?',
        coupon_plural: 'Gutscheincodes',
        apply: 'Anwenden',
        placeholder: 'Promo-Code',
        applyVoucherSuccess: '{{voucherCode}} wurde angewendet.',
        removeVoucherSuccess: '{{voucherCode}} wurde entfernt.',
        anchorLabel: 'Geben Sie Ihren Gutscheincode ein oder entfernen Sie ihn',
        vouchersApplied: 'coupons anwenden',
        availableCoupons: 'Verfügbare Coupons',
        availableCouponsLabel: 'Sie können diese Coupons zu dieser Bestellung hinzufügen.',
      },
    },
    checkout: {
      checkoutReview: {
        review: 'Bestellzusammenfassung',
        orderItems: 'Artikel bestellen',
        autoReplenishOrder: 'Bestellung automatisch auffüllen',
        confirmThatRead: 'Ich bestätige, dass ich gelesen habe und damit einverstanden bin',
        placeOrder: 'Kostenpflichtig bestellen',
        termsAndConditions: 'Allgemeine Geschäftsbedingungen',
      },
      checkoutShipping: {
        shippingMethod: 'Lieferart',
        standardDelivery: 'Standard Delivery',
        premiumDelivery: 'Premium Delivery',
      },
      checkoutOrderConfirmation: {
        confirmationOfOrder: 'Ihre Auftragsnummer:',
        confirmationOfConfiguratorOrder: 'Ihr Auftrag wird in Kürze angelegt.',
        thankYou: 'Vielen Dank für Ihre Bestellung!',
        invoiceHasBeenSentByEmail: 'Eine Rechnung wird per E-Mail an {{email}} gesendet. Eine Übersicht zum Status Ihrer Bestellung sehen Sie in der',
        orderHistory: 'Auftragshistorie.',
        orderItems: 'Bestellte Artikel',
        status: 'Status',
        placedOn: 'Erstellt am',
        orderNumber: 'Auftragsnummer',
      },
    },
    address: {
      addressCard: {
        shipTo: 'Lieferadresse',
        setAsDefault: 'Als Standardlieferadresse festlegen',
        default: 'Standardadresse',
      }
    },
    myAccount: {
      orderHistory: {
        orderHistory: 'Bestellverlauf',
        orderId: 'Bestellung #',
        emptyPurchaseOrderId: 'Keiner',
        date: 'Datum',
        status: 'Status',
        total: 'Gesamt (netto)',
        noOrders: 'Wir haben keine Bestelldatensätze für dieses Konto',
        noReplenishmentOrders: 'Wir haben keine Nachschubauftragsdatensätze für dieses Konto.',
        startShopping: 'Einkauf starten',
        sortByMostRecent: 'Sortieren nach Neueste',
        replenishmentOrderHistory: 'Nachschubbestellhistorie',
        replenishmentOrderId: 'Nachschub #',
        purchaseOrderNumber: 'PO #',
        startOn: 'beginnen am',
        frequency: 'Frequenz',
        nextOrderDate: 'Nächstes Bestelldatum',
        cancel: 'Abbrechen',
        cancelled: 'Abgesagt',
        replenishmentHistory: 'Nachschubhistorie',
        notFound: 'Keine Bestellungen gefunden',
      },
      orderDetails: {
        statusDisplay_Beendet: 'Beendet',
        orderNumber: 'Auftragsnummer',
        shippingMethod: 'Versandart',
        cancellationAndReturn: {
          returnAction: 'eine Rücksendung anfordern',
          cancelAction: 'Artikel stornieren',
          item: 'Artikel',
          itemPrice: 'Stückpreis',
          quantity: 'Maximale Menge',
          returnQty: 'Zu retournierende Menge',
          cancelQty: 'Zu stornierende Menge',
          setAll: 'Alle Mengen auf Maximum stellen',
          totalPrice: 'Gesamt',
          submit: 'Anfrage einreichen',
          returnSuccess: 'Ihre Rücksendeanfrage ({{rma}}) wurde gesendet',
          cancelSuccess:
            'Ihre Stornierungsanfrage wurde gesendet (ursprüngliche Bestellung {{orderCode}} wird aktualisiert)',
        },
      },
      updateProfileForm: {
        title: 'Anrede',
        gender: {
          '0001': 'Frau',
          '0002': 'Herr',
          '0003': 'Divers'
        },
        locName: 'Name des Kunden',
        b2bUnitId: 'Kundennummer',
        none: '',
        firstName: {
          label: 'Vorname',
          placeholder: 'Vorname',
        },
        firstNameIsRequired: 'Vorname ist ein Pflichtfeld.',
        lastName: {
          label: 'Nachname',
          placeholder: 'Nachname',
        },
        lastNameIsRequired: 'Nachname ist ein Pflichtfeld.',
        profileUpdateSuccess: 'Daten wurden gespeichert',
        customerId: 'Benutzernummer',
      },
      updatePasswordForm: {
        formMandatoryFields: 'Pflichtfelder sind mit einem * gekennzeichnet.',
        oldPassword: {
          label: 'Aktuelles Passwort',
          placeholder: 'Aktuelles Passwort',
        },
        oldPasswordIsRequired: 'Das aktuelle Passwort ist ein Pflichtfeld.',
        newPassword: {
          label: 'Neues Passwort',
          placeholder: 'Neues Passwort',
        },
        passwordMinRequirements:
          'Das Passwort muss einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten und mindestens 6 Zeichen lang sein.',
        confirmPassword: {
          label: 'Neues Passwort bestätigen',
          placeholder: 'Neues Passwort bestätigen',
        },
        action: {
          cancel: 'Abbrechen',
          save: 'Speichern'
        },
        bothPasswordMustMatch: 'Beide Passwörter müssen übereinstimmen',
        passwordUpdateSuccess: 'Passwort erfolgreich gespeichert',
      },
      consentManagementForm: {
        message: {
          success: {
            given: 'Die Einwilligung wurde gespeichert.',
            withdrawn: 'Der Widerruf der Einwilligung wurde gespeichert.',
          },
        },
      },
    },
    userProfile: {
      forgottenPassword: {
        passwordResetSuccess: 'Das Passwort wurde erfolgreich gesetzt. Sie können sich nun am Shop anmelden.',
        passwordResetEmailSent: 'Eine E-Mail mit Informationen zum Zurücksetzen Ihres Passworts wurde an Sie versendet.'
      },
      register: {
        confirmPassword: {
          action: 'Passwort bestätigen',
          label: 'Passworteingabe wiederholen',
          placeholder: 'Passwort bitte erneut nennen',
        },
        confirmNewPassword: 'Neues Passwort bestätigen',
        resetPassword: 'Neues Passwort festlegen',
        password: {
          label: 'Passwort',
          placeholder: 'Passwort',
        },
        newPassword: 'Bitte wählen Sie ein neues Passwort',
        passwordMinRequirements:
          'Bitte beachten Sie die Regeln für die sichere Passwortvergabe: Ihr neues Kennwort muss mindestens 6 Zeichen lang sein und einen Großbuchstaben, eine Zahl sowie ein Sonderzeichen enthalten.',
        bothPasswordMustMatch: 'Beide Passwörter müssen übereinstimmen.',
      },
    },
    order: {
      orderDetails: {
        orderNumber: 'Ihre Auftragsnummer',
        shippingMethod: 'Versandart',
        cancellationAndReturn: {
          submit: 'Artikel stornieren',
        }
      }
    },
    wishlist: {
      wishlist: {
        empty: 'Ihre Merkliste ist leer.',
        notfound: 'Zu dieser Markenauswahl sind keine Artikel in der Merkliste.',
      }
    },
    user: {
      anonymousConsents: {
        preferences: 'Einwilligungsmanagement',
        dialog: {
          title: 'Einwilligungsmanagement',
          selectAll: 'Alle auswählen',
          clearAll: 'Alle entfernen',
        },
        banner: {
          title: 'Diese Webseite verwendet Cookies',
          description:
            "Wir verwenden Cookies, um Ihre Erfahrung zu verbessern und Ihre Interaktionen mit unserer Website zu analysieren. Nachfolgend können Sie eine individuelle Auswahl derjenigen technisch nicht unbedingt erforderlichen Cookies treffen, deren Nutzung Sie uns erlauben möchten und entsprechend in die Verarbeitung Ihrer personenbezogenen Daten einwilligen. Die von Ihnen getroffene Auswahl können Sie über den Link 'Einwilligungsmanagement' im Footer unserer Website jederzeit ändern.",
          allowAll: 'Alle erlauben',
          viewDetails: 'Details anzeigen',
        },
      }
    }
  }
}

