import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { UpdateCartArg } from '../../model/update-cart.model';

export const updateCartReset = createAction(
  '[Update Cart] Reset'
);


export const updateCart = createAction(
  '[Update Cart] Execute',
  props<{ data: UpdateCartArg }>()
);

export const updateCartSuccess = createAction(
  '[Update Cart] Success',
  props<{ data: UpdateCartArg }>()
);

export const updateCartFailure = createAction(
  '[Update Cart] Failed',
  props<{ error: ErrorType }>()
);
