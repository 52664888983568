import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FaqOverviewResponse } from '../model/faq-items.model';
import { FaqOverviewActions } from '../store/actions';
import * as FaqOverviewFeature from '../store/reducers/faq-overview.reducer';
import * as FaqOverviewSelectors from '../store/selectors/faq-overview.selectors';

@Injectable({
  providedIn: 'root',
})
export class FaqOverviewService {
  constructor(protected store: Store<FaqOverviewFeature.FaqOverviewState>) {}

  loadOverview(isUserLoggedIn: boolean): void {
    this.store.dispatch(FaqOverviewActions.loadFaqOverview({ isUserLoggedIn }));
  }
  
  isLoading(): Observable<boolean> {
    return this.store.select(FaqOverviewSelectors.selectLoading);
  }

  getFaqOverviewResponse(): Observable<FaqOverviewResponse> {
    return this.store.select(FaqOverviewSelectors.selectResponse);
  }

  resetFaqOverview(): void {
    this.store.dispatch(FaqOverviewActions.resetFaqOverview());
  }
}
