import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as OrderOverviewFeature from '../reducers/order-overview.reducer';
import { ORDER_OVERVIEW_FEATURE_KEY } from '../reducers/order-overview.reducer';
import { getOrderHistoryState, OrderHistoryState } from '../reducers';
import { OrderHistoryList } from '@spartacus/order';

export const selectLoading: MemoizedSelector<OrderOverviewFeature.OrderOverviewState, boolean> = createSelector(
  getOrderHistoryState,
  (state: OrderHistoryState) => state[ORDER_OVERVIEW_FEATURE_KEY].loading
);

export const selectResponse: MemoizedSelector<OrderOverviewFeature.OrderOverviewState, OrderHistoryList> = createSelector(
  getOrderHistoryState,
  (state: OrderHistoryState) => state[ORDER_OVERVIEW_FEATURE_KEY].response
);
