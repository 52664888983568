import { Component, OnInit } from '@angular/core';
import { CartTotalsComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade, OrderEntry } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {
  OrderInfoFieldsFormsValidationService
} from '../../../checkout-order/service/order-info-fields-forms-validation.service';
import { PermissionCheckService } from '../../../user-profiles/service/permission-check.service';
import { B2BUserAccountService } from "../../services/b2-b-user-account.service";
import { UpdateCartService } from '../../services/update-cart.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-efa-cart-totals',
  templateUrl: './efa-cart-totals.component.html'
})
export class EfaCartTotalsComponent extends CartTotalsComponent implements OnInit {


  constructor(activeCartService: ActiveCartFacade,
              protected permissionCheckService: PermissionCheckService,
              private orderInfoFieldsFormsValidationService: OrderInfoFieldsFormsValidationService,
              private updateCartService: UpdateCartService,
              private b2BUserAccountService: B2BUserAccountService,
              )
  {
    super(activeCartService);
  }
  entries$: Observable<OrderEntry[]>;
  displayCustomerPrices$: Observable<boolean> =  this.permissionCheckService.displayCustomerPrices();
  canPlaceOrders$: Observable<boolean> =  this.permissionCheckService.canPlaceOrders();
  isCartContainingEntriesWithExchangeTaxRequired$: Observable<boolean>;

  ngOnInit(): void {
    super.ngOnInit();
    this.entries$ = this.activeCartService
    .getEntries()
    .pipe(filter((entries) => entries.length > 0));
    this.isCartContainingEntriesWithExchangeTaxRequired$ = this.entries$.pipe(map((entries: OrderEntry[]) => entries?.find((entry: OrderEntry) => entry.exchangeTaxRequired) != null));
  }

  calculateCart(): void {
    this.updateCartService.calculateCart(this.b2BUserAccountService.userId);
  }

  publishFieldMarks(): void {
    this.orderInfoFieldsFormsValidationService.markAllAsTouched();
  }

}
