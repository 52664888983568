import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ReturnReasonAdapter } from './adapters/return-reason.adapter';
import { OccReturnReasonAdapter } from './adapters/impl/occ-return-reason.adapter';
import { ReturnCreationAdapter } from './adapters/return-creation.adapter';
import { OccReturnCreationAdapter } from './adapters/impl/occ-return-creation.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: ReturnReasonAdapter,
      useClass: OccReturnReasonAdapter,
    },
    {
      provide: ReturnCreationAdapter,
      useClass: OccReturnCreationAdapter,
    }
  ],
})
export class OccModule {
}
