import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { I18nModule } from '@spartacus/core';
import { HomepageGreetingComponent } from './components/homepage-greeting/homepage-greeting.component';
import { SharedModules } from '@shared/shared.modules';

@NgModule({
  declarations: [HomepageGreetingComponent],
  imports: [
    CommonModule,
    I18nModule,
    SharedModules,
    ConfigModule.withConfig({
      cmsComponents: {
        HomepageGreetingComponent: {
          component: HomepageGreetingComponent,
        },
      },
    }),
  ],
})
export class GreetingModule {}
