import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { AuthActions, SiteContextActions } from '@spartacus/core';
import { produce } from 'immer';

function authenticationOrLanguageChangedDetails(action: Action, state): any {
  if (action.type === AuthActions.LOGIN ||
    action.type === AuthActions.LOGOUT ||
    action.type === SiteContextActions.LANGUAGE_CHANGE
  ) {
    return produce(state, (draftState) => {
      draftState.user.order = undefined;
    });
  }
  return state;
}

export function metaReducerFactoryOrderDetails(): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const newState = authenticationOrLanguageChangedDetails(action, state);
    return reducer(newState, action);
  };
}


