import { createReducer, on } from "@ngrx/store";
import { AllowedFileExtensionsResponse } from "@shared/models/shared.model";
import { AllowedDocumentExtensionsActions } from "../actions";

export const ALLOWED_DOCUMENT_EXTENSIONS_FEATURE_KEY = 'allowedDocumentExtensions';

export interface AllowedDocumentExtensionsState {
  contextId: string;
  response: AllowedFileExtensionsResponse;
  loading: boolean;
  success: boolean;
}

export const initialState: AllowedDocumentExtensionsState = {
  contextId: undefined,
  response: undefined,
  loading: false,
  success: undefined
}

export const allowedDocumentExtensionsReducer = createReducer(
  initialState,

  on(AllowedDocumentExtensionsActions.loadAllowedDocumentExtensions, (state, action) => ({
    ...state, 
    loading: true,
    contextId: action.contextId,
    response: undefined
  })),
  on(AllowedDocumentExtensionsActions.loadAllowedDocumentExtensionsSuccess, (state, action) => ({
    ...state,
    success: true,
    response: action.response,
    loading: false
  })),
  on(AllowedDocumentExtensionsActions.loadAllowedDocumentExtensionsFailure, (state, action) => ({
    ...state,
    success: false,
    error: action.errorResponse,
    loading: false,
  }))
);
