import { Component, OnInit } from '@angular/core';
import { PDP_CALL_SOURCE } from '@shared/models/shared.model';
import {
  CmsProductReferencesComponent,
  Product,
  ProductReference,
  ProductReferenceService,
  UserIdService
} from '@spartacus/core';
import { CmsComponentData, CurrentProductService, ProductReferencesComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CartParametersService } from '../../../cart-administration/services/cart-parameters.service';
import { PermissionCheckService } from '../../../user-profiles/service/permission-check.service';
import { PriceResponseItem } from '../../model/product-prices.model';
import { ProductPricesService } from '../../service/product-prices.service';

@Component({
  selector: 'app-efa-product-references',
  templateUrl: './efa-product-references.component.html'
})
export class EfaProductReferencesComponent extends ProductReferencesComponent implements OnInit {

  prices$: Observable<PriceResponseItem[]>;
  displayCustomerPrices$: Observable<boolean> = this.permissionCheckService.displayCustomerPrices();

  readonly pdpCallSource: PDP_CALL_SOURCE = PDP_CALL_SOURCE.CAROUSEL;

  constructor(cmsComponentData: CmsComponentData<CmsProductReferencesComponent>,
              currentProductService: CurrentProductService,
              productReferenceService: ProductReferenceService,
              private productPricesService: ProductPricesService,
              private cartParametersService: CartParametersService,
              private userIdService: UserIdService,
              private permissionCheckService: PermissionCheckService
  ) {
    super(cmsComponentData, currentProductService, productReferenceService);
  }

  ngOnInit(): void {
    this.prices$ = this.items$
      .pipe(
        first(),
        withLatestFrom(this.componentData$, this.productCode$),
        switchMap(([items, data, productCode]) =>
          this.getReferenceProducts(productCode, data.productReferenceTypes)),
        withLatestFrom(this.cartParametersService.getCurrentOrderType(), this.userIdService.getUserId()),
        filter(([items, orderType, userId]) => items && items.length > 0),
        first(),
        switchMap(([items, orderType, userId]) => {
          const codes = items.map(product => product.code);
          return this.productPricesService.getOrLoadPrices(codes, userId, orderType, false);
        }),
        filter(prices => prices?.length > 0));
  }

  private getReferenceProducts(
    code: string,
    referenceType: string
  ): Observable<Product[]> {
    return this.productReferenceService
      .getProductReferences(code, referenceType)
      .pipe(
        filter(Boolean),
        map((references: ProductReference[]) =>
          references.map((reference) => reference.target)
        )
      );
  }

  getCustomerPrice(prices: PriceResponseItem[], code: string): string {
    return prices.find(item => item.productCode === code)?.customerPrice?.formattedValue;
  }

  getListPrice(prices: PriceResponseItem[], code: string): string {
    return prices.find(item => item.productCode === code)?.listPrice?.formattedValue;
  }
}
