import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {NgbDatepickerConfig, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {Address, B2BUser, Config} from '@spartacus/core';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, withLatestFrom} from 'rxjs/operators';

import {ICON_TYPE} from '../../model/order-history-icon.model';
import {OrderOverviewFilter, OrderState} from '../../model/order-history.model';
import {OrderOverviewUiService} from '../../services/order-overview-ui.service';
import {getInitialDateFrom} from '@shared/utils/date-tools';


@Component({
  selector: 'app-order-overview-filter',
  templateUrl: './order-overview-filter.component.html',
})
export class OrderOverviewFilterComponent implements OnInit, OnDestroy {
  @Output() orderOverviewFilterChanges: EventEmitter<OrderOverviewFilter> = new EventEmitter<OrderOverviewFilter>();
  @Input() availableOrderStates: OrderState[];
  iconTypes = ICON_TYPE;
  orderOverviewFilterForm: UntypedFormGroup;
  shippingAddresses: Address[];
  currentDate: Date = new Date();
  maxDate: NgbDateStruct;

  private subscription: Subscription = new Subscription();
  private readonly formProperties = {
    orderNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    orderStateId: new UntypedFormControl(''),
    receiverAddressId: new UntypedFormControl(''),
    dateFrom: new UntypedFormControl(getInitialDateFrom(this.config.dateFrom.orderHistoryOverview)),
    dateTo: new UntypedFormControl(undefined),
    consignmentInfo: new UntypedFormControl('', {updateOn: 'blur', validators: [Validators.maxLength(35)]})
  };
  private readonly resetFormState = {
    orderNumber: '',
    orderStateId: '',
    receiverAddressId: '',
    dateFrom: getInitialDateFrom(this.config.dateFrom.orderHistoryOverview),
    dateTo: undefined,
    consignmentInfo: '',
  };
  private wasReset = false;
  private filterHasChanged = false;

  constructor(
    private fb: UntypedFormBuilder,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    private userAccountFacade: UserAccountFacade,
    private orderOverviewUiService: OrderOverviewUiService,
    @Inject(Config) private config: any
  ) {
  }

  ngOnInit(): void {
    this.disableFutureDates();
    this._loadB2BOrgUnitData();
    this.orderOverviewFilterForm = this.fb.group(this.formProperties);
    this.onChanges();
    this.setSelectedFilterEntity();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private _loadB2BOrgUnitData(): void {
    this.userAccountFacade.get().subscribe((b2bUser: B2BUser) => {
      if (b2bUser && b2bUser.orgUnit) {
        this.shippingAddresses = b2bUser.orgUnit.shippingAddresses;
      }
    });
  }

  onChanges(): void {
    this.subscription.add(
      this.orderOverviewFilterForm.valueChanges
      .pipe(
        delay(1000),
        distinctUntilChanged((a, b) =>
          a.orderNumber === b.orderNumber &&
          a.orderStateId === b.orderStateId &&
          a.dateTo === b.dateTo &&
          a.dateFrom === b.dateFrom &&
          a.receiverAddressId === b.receiverAddressId &&
          a.consignmentInfo === b.consignmentInfo))
      .subscribe((filter: OrderOverviewFilter) => {
        if (this.orderOverviewFilterForm.valid) {
          this.filterHasChanged = true;
          this.orderOverviewUiService.setSelectedFilterEntity(filter);
        }
      })
    );
  }

  setSelectedFilterEntity(): void {
    this.subscription.add(
      this.orderOverviewUiService.selectedFilterEntity()
      .pipe(
        withLatestFrom(this.orderOverviewUiService.returningFromDetails()))
      .subscribe(([filterEntity, fromDetails]: [OrderOverviewFilter, boolean]) => {
        let filterToEmit = filterEntity;
        if (this.filterHasChanged) {
          this.filterHasChanged = false;
        } else {
          if (fromDetails) {
            this.orderOverviewFilterForm.patchValue(
              {
                orderNumber: filterEntity.orderNumber,
                orderStateId: filterEntity.orderStateId,
                receiverAddressId: filterEntity.receiverAddressId,
                dateFrom: filterEntity.dateFrom,
                dateTo: filterEntity.dateTo,
                consignmentInfo: filterEntity.consignmentInfo,
              },
              {emitEvent: false}
            );
          } else if (!this.wasReset) {
            this.wasReset = false;
          } else {
            filterToEmit = this.resetFormState;
          }
        }
        this.orderOverviewFilterChanges.emit(filterToEmit);
      })
    );
  }

  disableFutureDates(): void {
    this.maxDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear()
    };
  }

  triggerOnChanges(event: any): void {
    this.orderOverviewFilterForm.patchValue({orderNumber: event.target.value});
  }

  clearForm(): void {
    this.wasReset = true;
    this.orderOverviewFilterForm.reset(this.resetFormState);
  }

  getSelectPlaceholderTextClass(value: string): string {
    return value === '' ? 'placeholder-text' : '';
  }
}
