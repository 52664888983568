import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageType } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map } from 'rxjs/operators';

import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import { UpdateCartConnector } from '../../occ/connectors/update-cart.connector';
import { UpdateCartActions } from '../actions';

@Injectable()
export class UpdateCartEffect {
  updateCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateCartActions.updateCart),
      filter((a) => a.data.cartId !== undefined && a.data.userId !== undefined),
      concatMap((action) =>
        this.connector.updateCart(action.data).pipe(
          map(() => {
            if (action.data.reloadCart) {
              this.multiCartService.loadCart({cartId: action.data.cartId, userId: action.data.userId});
            }
            return UpdateCartActions.updateCartSuccess({
              data: action.data
            });
          }),
          catchError((error) => {
            this.globalMessageService.add(
              {
                key: 'cartAdministration.globalMessage.updateCartFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            this.multiCartService.loadCart({cartId: action.data.cartId, userId: action.data.userId});
            return of(UpdateCartActions.updateCartFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: UpdateCartConnector,
    private multiCartService: MultiCartFacade,
    private globalMessageService: EfaGlobalMessageService
  ) {}
}
