import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as CartProductSearchFeature from '../reducers/cart-product-search.reducer';
import { CART_PRODUCT_SEARCH_FEATURE_KEY } from '../reducers/cart-product-search.reducer';
import { CartProductSearchResponse } from '../../model/cart-administration.model';
import { CartAdministrationState, getCartAdministrationState } from '../reducers';

export const selectProductSearchResponse: MemoizedSelector<CartProductSearchFeature.CartProductSearchState,
  CartProductSearchResponse> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_PRODUCT_SEARCH_FEATURE_KEY].response
);

export const selectLoading: MemoizedSelector<CartProductSearchFeature.CartProductSearchState,
  boolean> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_PRODUCT_SEARCH_FEATURE_KEY].loading
);
