import {Component} from '@angular/core';
import {UserGroupFormComponent} from '@spartacus/organization/administration/components';
import {map, tap} from 'rxjs/operators';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-efa-user-group-form',
  templateUrl: './efa-user-group-form.component.html'
})
export class EfaUserGroupFormComponent extends UserGroupFormComponent {

  ngOnInit(): void {
    super.ngOnInit();
    this.units$ = this.units$.pipe(
      map((units) => units?.length > 0 ? units.slice(0, 1) : units),
      tap((units) => {
        this.form?.get('orgUnit.uid')?.setValue(units[0]?.id);
      }));
  }

  createUidWithNameAndUnit(name: AbstractControl, code: AbstractControl, unit: AbstractControl): void {
    if (!code.value) {
      const codeFromName = name.value.replace(/\s+/g, '-').toLowerCase();
      code.patchValue(unit.value.uid + '_' +  codeFromName);
    }
  }
}
