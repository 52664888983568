import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthStorageService } from '@spartacus/core';
import { UserIdService } from '@spartacus/core';
import { StateWithClientAuth } from '@spartacus/core';
import { OAuthLibWrapperService } from '@spartacus/core';
import { RoutingService } from '@spartacus/core';
import { AuthService } from '@spartacus/core';
import { EfaAuthRedirectService } from './efa-auth-redirect.service';

@Injectable({
  providedIn: 'root'
})
export class EfaAuthService extends AuthService {

  constructor(
    protected store: Store<StateWithClientAuth>,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected authStorageService: AuthStorageService,
    protected authRedirectService: EfaAuthRedirectService,
    protected routingService: RoutingService
  ) {
    super(store, userIdService, oAuthLibWrapperService, authStorageService, authRedirectService, routingService);
  }


  coreLogout(): Promise<void> {
    this.authRedirectService.clearRedirectUrlPublic();
    return super.coreLogout();
  }
}
