import { Component, OnInit } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-efa-order-confirmation-overview',
  templateUrl: './efa-order-confirmation-overview.component.html',
})
export class EfaOrderConfirmationOverviewComponent implements OnInit {
  
  constructor(protected orderDetailsService: OrderDetailsService) {}

  order$: Observable<any>;
  isOrderLoading$: Observable<boolean>;

  ngOnInit() {
    this.order$ = this.orderDetailsService.getOrderDetails();
    this.isOrderLoading$ = this.orderDetailsService.isOrderDetailsLoading();
  }

}
