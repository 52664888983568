<div class="row tac">
  <div class="tac__btn-wrapper col-12 col-md-4">
    <button type="button" class="btn btn-secondary btn-block" (click)="declineTermsAndConditions()">
      {{ 'efaConsentDialog.labelDecline' | cxTranslate }}
    </button>
  </div>
  <div class="tac__btn-wrapper col-12 col-md-4">
    <button type="button" class="btn btn-primary btn-block" (click)="acceptTermsAndConditions()">
      {{ 'efaConsentDialog.labelAccept' | cxTranslate }}
    </button>
  </div>
</div>
