import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ReturnReasonService } from '../../services/return-reason.service';
import { ReturnCreationService } from '../../services/return-creation.service';
import {
  ReturnablePosition,
  ReturnCreationRequest,
  ReturnCreationResponse,
  ReturnDocumentType,
  ReturnPosition,
  ReturnPositionResponseData,
  ReturnReason,
  ReturnReasonResponse
} from '../../model/return-creation.model';
import { DeliveryNoteDetailsResponse } from '../../../delivery-notes/model/delivery-notes.model';
import { DeliveryNotesDetailsService } from '@core/delivery-notes/delivery-notes-details.service';
import { ReturnPositionService } from '../../services/return-position.service';
import { B2BUserAccountService } from '../../../cart-administration/services/b2-b-user-account.service';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { GlobalMessageType } from '@spartacus/core';

@Component({
  selector: 'app-return-creation',
  templateUrl: './return-creation.component.html',
})
export class ReturnCreationComponent implements OnInit, OnDestroy {
  deliveryNotesDetails$: Observable<DeliveryNoteDetailsResponse>;
  returnablePositions$: Observable<ReturnablePosition[]>;
  returnablePositions: ReturnablePosition[];
  returnPositions: ReturnPosition[];
  allReasonCode$: Observable<ReturnReason[]>;
  reasonCodesNoDeposits$: Observable<ReturnReason[]>;
  reasonCodesDeposits$: Observable<ReturnReason[]>;
  isLoading$: Observable<boolean>;
  returnCreationForm: UntypedFormGroup;
  items: UntypedFormArray = new UntypedFormArray([]);
  returnsDeduction: boolean;
  commentRequired: Map<number, boolean> = new Map<number, boolean>();
  deliveryNoteReturnablePositionsRoute = 'deliveryNoteReturnablePositions';

  private documentNumber: string;
  private documentType: ReturnDocumentType;
  private rejectedPositions: ReturnPositionResponseData[];
  private subscription: Subscription = new Subscription();


  constructor(
    protected deliveryNotesDetailsService: DeliveryNotesDetailsService,
    private b2BUserAccountService: B2BUserAccountService,
    private returnReasonService: ReturnReasonService,
    private returnCreationService: ReturnCreationService,
    private returnPositionService: ReturnPositionService,
    private formBuilder: UntypedFormBuilder,
    private globalMessageService: EfaGlobalMessageService,
  ) {
  }

  ngOnInit(): void {
    this.returnCreationService.reset();
    this.deliveryNotesDetails$ = this.deliveryNotesDetailsService.getDeliveryNotesDetailsResponse(true, false);
    this.returnablePositions$ = this.returnPositionService.getReturnablePositions()
      .pipe(
        map((positions: ReturnablePosition[]) =>
          positions?.filter((position: ReturnablePosition) => position.quantityToReturn > 0))
      );
    this.returnablePositions$.subscribe((positions: ReturnablePosition[]) => {
      this.returnablePositions = [];
      positions?.forEach((item) => {
        this.returnablePositions.push({...item});
      });
      positions?.forEach((_value, index: number) => {
        this.items.push(this.createItem());
        this.commentRequired.set(index, false);
      });
    });
    this.allReasonCode$ = this.returnReasonService.getResponse().pipe(
      map((reasonResponse: ReturnReasonResponse) => reasonResponse?.reasons)
    );
    this.reasonCodesNoDeposits$ = this.allReasonCode$
      .pipe(
        filter(Boolean),
        map((reasons: ReturnReason[]) =>
          reasons.filter((reason: ReturnReason) => !reason.neededForDeposits))
      );
    this.reasonCodesDeposits$ = this.allReasonCode$
    .pipe(
      filter(Boolean),
      map((reasons: ReturnReason[]) =>
        reasons.filter((reason: ReturnReason) => reason.neededForDeposits))
    );
    this.isLoading$ = combineLatest([
      this.returnCreationService.isProcessing(),
      this.deliveryNotesDetailsService.isLoading(),
      this.returnReasonService.isLoading(),
    ]).pipe(
      map(
        ([
          isReturnCreationLoading,
          isDeliveryNotesDetailsLoading,
          isReturnReasonLoading,
        ]) =>
          isReturnCreationLoading ||
          isDeliveryNotesDetailsLoading ||
          isReturnReasonLoading
      )
    );
    this.returnCreationForm = this.formBuilder.group({
      items: this.items
    });
    this.setDocumentNumber();
    this.setDocumentType();
    this.subscription.add(
      this.b2BUserAccountService.getB2bOrgUnitLoaded().subscribe(() => {
        this.returnsDeduction = this.b2BUserAccountService.returnsDeduction;
      })
    );
    this.setRejectedPositions();
    this.globalMessageService.add({
      key: 'returnCreation.hint', params: {hideCloseButton: true, styleClass: 'staticInfoMessage'}
    },
    GlobalMessageType.MSG_TYPE_WARNING
  );
  }

  private createItem(): UntypedFormGroup {
    return this.formBuilder.group({
      reasonCode: ['', Validators.required],
      comment: '',
    });
  }

  onSubmit(): void {
    if (this.returnCreationForm.invalid) {
      this.returnCreationForm.markAllAsTouched();
      return null;
    }
    this.returnPositions = this.returnablePositions?.map((item, index) => ({
      positionNumber: item.positionNumber,
      materialNumber: item.materialNumber,
      reasonCode: this.items.controls[index].get('reasonCode').value.code,
      comment: this.items.controls[index].get('comment').value,
      quantity: item.quantityToReturn,
    }));
    const request: ReturnCreationRequest = {
      documentNumber: this.documentNumber,
      documentType: this.documentType,
      positions: this.returnPositions,

    };
    this.returnCreationService.doReturnCreation(request);
  }

  returnCreationReasonChange(creationReason: ReturnReason, index: number): void {
    if (creationReason.mandatoryComment) {
      this.items.controls[index].get('comment').setValidators(Validators.required);
      this.commentRequired.set(index, true);
    } else {
      this.items.controls[index].get('comment').setValidators(null);
      this.commentRequired.set(index, false);
    }
    this.items.controls[index].get('comment').updateValueAndValidity();
  }

  private setDocumentNumber(): void {
    this.subscription.add(
      this.returnPositionService.getDocumentNumber().subscribe((documentNumber: string) => {
        this.documentNumber = documentNumber;
      })
    );
  }

  private setDocumentType(): void {
    this.subscription.add(
      this.returnPositionService.getDocumentType().subscribe((documentType: ReturnDocumentType) => {
        this.documentType = documentType;
      })
    );
  }


  private setRejectedPositions(): void {
    this.subscription.add(this.returnCreationService.getReturnCreationResponse().subscribe((returnCreationResponse: ReturnCreationResponse) => {
      this.rejectedPositions = returnCreationResponse?.rejectedPositions;
    }));
  }

  getReturnPositionHighlightClass(positionNumber: number): string {
    const resultFound: ReturnPositionResponseData = this.rejectedPositions?.find(resultItem =>
      resultItem.positionNumber === positionNumber);
    if (resultFound != null) {
      return 'rejected-position';
    }
    return '';
  }

  getSelectPlaceholderTextClass(value: string): string {
    return value === '' ? 'placeholder-text' : '';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
