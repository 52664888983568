import { createAction, props } from '@ngrx/store';

import { CartProductSearchRequest, CartProductSearchResponse, CartProductSearchResult } from '../../model/cart-administration.model';
import { ErrorType } from '@shared/models/ErrorTypes';

export const loadCartProductSearch = createAction(
  '[CartProductSearch] Load CartProductSearch',
  props<{ request: CartProductSearchRequest }>()
);

export const resetCartProductSearch = createAction(
  '[CartProductSearch] Reset CartProductSearch'
);

export const loadCartProductSearchSuccess = createAction(
  '[CartProductSearch] Load CartProductSearch Success',
  props<{ response: CartProductSearchResponse, oemNumber: string }>()
);

export const loadCartProductSearchFailure = createAction(
  '[CartProductSearch] Load CartProductSearch Failure',
  props<{ error: ErrorType }>()
);
