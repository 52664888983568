import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthGuard, ConfigModule, I18nModule } from '@spartacus/core';
import { CardModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { AddressFormModule } from "@spartacus/user/profile/components";
import { EfaAddressBookComponent } from './components/efa-address-book/efa-address-book.component';
import { EfaCardComponent } from './components/efa-card/efa-card.component';
import { addressBookTranslations, addressBookTranslationsChunksConfig } from './translation/address-book.translation';

@NgModule({
  declarations: [EfaAddressBookComponent, EfaCardComponent],
  imports: [
    CommonModule,
    I18nModule,
    CardModule,
    AddressFormModule,
    SpinnerModule,
    IconModule,
    ConfigModule.withConfig({
      i18n: {
        resources: addressBookTranslations,
        chunks: addressBookTranslationsChunksConfig
      },

      cmsComponents: {
        AccountAddressBookComponent: {
          component: EfaAddressBookComponent,
          guards: [AuthGuard],
        },
      }
    })
  ]
})
export class AddressBookModule { }
