import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule, I18nModule, OccConfig } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { TopmotiveCatalogComponent } from './components/topmotive-catalog/topmotive-catalog.component';
import { TopmotiveConfirmationDialogComponent } from './components/topmotive-confirmation-dialog/topmotive-confirmation-dialog.component';
import { TopmotiveCatalogGuard } from './guards/topmotive-catalog.guard';
import { OccModule } from './occ/occ.module';
import {
  externalCatalogsTranslationChunksConfig,
  externalCatalogsTranslations,
} from './translations/external-catalogs.translations';

@NgModule({
  declarations: [
    TopmotiveCatalogComponent,
    TopmotiveConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    OccModule,
    ConfigModule.withConfig({
      cmsComponents: {
        TopmotiveCatalogComponent: {
          component: TopmotiveCatalogComponent,
          guards: [TopmotiveCatalogGuard],
        },
      },
    }),
    ConfigModule.withConfig({
      routing: {
        routes: {
          topmotiveCatalog: {
            paths: ['car-parts-catalog'],
          },
        },
      },
    }),
    ConfigModule.withConfig({
      i18n: {
        resources: externalCatalogsTranslations,
        chunks: externalCatalogsTranslationChunksConfig,
      },
    }),
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            isAuthorizedToActivateExternalCatalog:
              'users/${userId}/isAuthorizedToActivateExternalCatalog?targetUrl=${targetUrl}',
          },
        },
      },
    } as OccConfig),
  ],
})
export class ExternalCatalogsModule {}
