import { createReducer, on } from '@ngrx/store';
import {
  ShippingBacklogOverviewRequest,
  ShippingBacklogOverviewResponse,
} from '../../model/shipping-backlog.model';
import { ShippingBacklogOverviewActions } from '../actions';

export const SHIPPING_BACKLOG_OVERVIEW_FEATURE_KEY = 'shippingBacklogOverview';

export interface ShippingBacklogOverviewState {
  request: ShippingBacklogOverviewRequest;
  response: ShippingBacklogOverviewResponse;
  loading: boolean;
  success: boolean;
}

export const initialShippingBacklogOverview: ShippingBacklogOverviewState = {
  request: undefined,
  response: undefined,
  loading: false,
  success: undefined,
};

export const shippingBacklogOverviewReducer = createReducer(
  initialShippingBacklogOverview,

  on(
    ShippingBacklogOverviewActions.loadShippingBacklogOverview,
    (state, action) => ({
      ...state,
      request: action.request,
      loading: true,
      success: false,
    })
  ),
  on(
    ShippingBacklogOverviewActions.loadShippingBacklogOverviewSuccess,
    (state, action) => ({
      ...state,
      response: action.response,
      loading: false,
      success: true,
    })
  ),
  on(
    ShippingBacklogOverviewActions.loadShippingBacklogOverviewFailure,
    (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.errorResponse,
    })
  )
);
