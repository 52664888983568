<div class="row">
  <ng-container *ngIf="importMode === 'Csv'">
    <div class="col-12">
      <p
        class="mb-0">{{ 'importExport.cartImportDialog.importFile.userManual.csv.line1.preFileExtension' | cxTranslate }}
        <span
          class="u-font-bold">{{ 'importExport.cartImportDialog.importFile.userManual.csv.line1.fileExtension' |
          cxTranslate }}</span>
        {{ 'importExport.cartImportDialog.importFile.userManual.csv.line1.postFileExtension' | cxTranslate }}
      </p>
    </div>
    <div class="col-12">
      <ol>
        <li>{{ 'importExport.cartImportDialog.importFile.userManual.csv.line2' | cxTranslate }}</li>
        <li>
          {{ 'importExport.cartImportDialog.importFile.userManual.csv.line3' | cxTranslate }}
          <ul>
            <li>
              {{ 'importExport.cartImportDialog.importFile.userManual.csv.line4' | cxTranslate }}
            </li>
            <li>
              {{ 'importExport.cartImportDialog.importFile.userManual.csv.line5' | cxTranslate }}
            </li>
            <li>
              {{ 'importExport.cartImportDialog.importFile.userManual.csv.line6' | cxTranslate }}
              <ul>
                <li>
                  {{ 'importExport.cartImportDialog.importFile.userManual.csv.line7' | cxTranslate }}
                </li>
                <li>
                  {{ 'importExport.cartImportDialog.importFile.userManual.csv.line8' | cxTranslate }}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
    </div>
    <div class="col-12">
      <span
        class="u-font-bold">{{ 'importExport.cartImportDialog.importFile.userManual.csv.line9' | cxTranslate }}</span>
      <ul>
        <li>
          {{ 'importExport.cartImportDialog.importFile.userManual.csv.line10' | cxTranslate }}
        </li>
        <li>
          {{ 'importExport.cartImportDialog.importFile.userManual.csv.line11' | cxTranslate }}
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-container *ngIf="importMode === 'Microcat'">
    <div class="col-12">
      <p
        class="mb-0">{{ 'importExport.cartImportDialog.importFile.userManual.microcat.line1.preFileExtension' | cxTranslate }}
        <span
          class="u-font-bold">{{ 'importExport.cartImportDialog.importFile.userManual.microcat.line1.fileExtension' |
          cxTranslate }}</span>
        {{ 'importExport.cartImportDialog.importFile.userManual.microcat.line1.postFileExtension' | cxTranslate }}
      </p>
      <br>
    </div>
    <div class="col-12">
      <span
        class="u-font-bold">{{ 'importExport.cartImportDialog.importFile.userManual.microcat.line2' | cxTranslate }}</span>
      <p>
        {{ 'importExport.cartImportDialog.importFile.userManual.microcat.line3' | cxTranslate }}
      </p>
    </div>
  </ng-container>
</div>
