import { createSelector, MemoizedSelector } from "@ngrx/store";
import { ShippingBacklogItem } from "../../model/shipping-backlog.model";
import { getShippingBacklogState, ShippingBacklogState } from "../reducers";
import { SHIPPING_BACKLOG_SELECTED_ITEMS_FEATURE_KEY } from "../reducers/shipping-backlog-selected-items.reducer";
import * as ShippingBacklogSelectedItemsFeature from '../reducers/shipping-backlog-selected-items.reducer';

export const selectItems: MemoizedSelector<ShippingBacklogSelectedItemsFeature.ShippingBacklogSelectedItemsState,
  ShippingBacklogItem[]>
  = createSelector(
  getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_SELECTED_ITEMS_FEATURE_KEY].selectedItems
);