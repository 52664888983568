import { KeyValue } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { RegistrationStatus } from '../../model/login-register-enum.model';
import { RegistrationData } from '../../model/login-register.model';
import { AbstractRegistrationStepComponent } from '../abstract-registration-step/abstract-registration-step.component';
import {GlobalMessageType} from "@spartacus/core";

const LOGIN_ROUTE_NAME = 'login';

@Component({
  selector: 'app-registration-submission',
  templateUrl: './registration-submission.component.html',
})
export class RegistrationSubmissionComponent extends AbstractRegistrationStepComponent {

  registrationForm: UntypedFormGroup = this.formBuilder.group({
    newsletter: [false],
    newsletterEmail: [''],
    termsAndConditions: [false, [Validators.requiredTrue]],
    privacyProtection: [false, [Validators.requiredTrue]],
    attentionReasonInfos: new UntypedFormArray([]),
  });

  private attentionReasonInfosSubFormFields = {
    attentionReason: [false],
    attentionReasonInfo: [''],
  };

  private attentionReasonInfosSubFormField = {
    attentionReason: [false]
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.resetForm$.asObservable().subscribe(() => this.resetForm())
    );
  }

  protected fillFormDataFromResponse(
    registrationData: RegistrationData
  ): void {
    const attentionReasonCodesList: string[] = registrationData.attentionReasonCodes;
    const attentionReasonInfosList: KeyValue<string, string>[] = registrationData.attentionReasonInfos;
    attentionReasonCodesList.forEach((attentionReasonCode) => {
      const index: number = this.registrationInformationMultiOptionsFieldsValues.attentionReasons.findIndex((attentionReason) => attentionReason.code === attentionReasonCode);
      this.attentionReasonInfosFormControls[index].patchValue({
        attentionReason: true
      });

      const attentionReasonInfo: KeyValue<string, string>= attentionReasonInfosList?.find((attentionReasonInfo) => attentionReasonInfo.key === attentionReasonCode);
      if (attentionReasonInfo != null) {
        this.attentionReasonInfosFormControls[index].patchValue({
          attentionReasonInfo: attentionReasonInfo.value
        });
      }
    })

    this.registrationForm.patchValue({
      newsletter: registrationData.newsletter,
      newsletterEmail: registrationData.newsletter ? registrationData.newsletterEmail : '',
      termsAndConditions: registrationData.termsAndConditions,
      privacyProtection: registrationData.privacyProtection,
    });
    this.subscribeToNewsletterChange();
  }

  protected getStatus(): RegistrationStatus {
    return RegistrationStatus.COMPLETED;
  }

  protected onSubmit(registrationData: RegistrationData): void {
    this.registrationForm.markAllAsTouched();
    if (this.registrationForm.invalid) {
      this.scrollToFirstInvalidControl();
      return;
    }

    const attentionReasonInfosKeyValueList: KeyValue<string, string>[] = [];
    const attentionReasonCodesList: string[] = [];
    this.attentionReasonInfosFormControls.forEach((control, index) => {
      if (control.value.attentionReason) {
        const attentionReasonCode: string = this.registrationInformationMultiOptionsFieldsValues.attentionReasons[index].code;
        attentionReasonCodesList.push(attentionReasonCode);
        attentionReasonInfosKeyValueList.push({
          key: attentionReasonCode,
          value: control.value.attentionReasonInfo
        });
      }
    });


    const registrationInformationRequest: RegistrationData = {
      ...registrationData,
      newsletter: this.registrationForm.value.newsletter,
      newsletterEmail: this.registrationForm.value.newsletter ? this.registrationForm.value.newsletterEmail : '',
      termsAndConditions: this.registrationForm.value.termsAndConditions,
      privacyProtection: this.registrationForm.value.privacyProtection,
      attentionReasonCodes: attentionReasonCodesList,
      attentionReasonInfos: attentionReasonInfosKeyValueList,
    };

    this.submit(registrationInformationRequest);
  }

  protected initializeMultiOptionFields(): Observable<boolean> {
    const attentionReasonInfosFormArray: UntypedFormArray = this.registrationForm.get('attentionReasonInfos') as UntypedFormArray;
    if (attentionReasonInfosFormArray.length === 0) {
      this.registrationInformationMultiOptionsFieldsValues.attentionReasons.forEach((reason) => {
        if (reason.additionalInfo) {
          attentionReasonInfosFormArray.push(this.formBuilder.group(this.attentionReasonInfosSubFormFields));
        } else {
          attentionReasonInfosFormArray.push(this.formBuilder.group(this.attentionReasonInfosSubFormField));
        }
      });
    }

    return of(true);
  }

  subscribeToNewsletterChange(): void {
    const newsletterEmailControl: AbstractControl = this.registrationForm.get('newsletterEmail');

    if (this.registrationForm.value.newsletter) {
      newsletterEmailControl.addValidators([Validators.required, CustomFormValidators.emailValidator]);
    } else {
      newsletterEmailControl.removeValidators([Validators.required, CustomFormValidators.emailValidator]);
    }

    newsletterEmailControl.updateValueAndValidity();
  }

  private resetForm(): void {
    this.routingService.go({cxRoute: LOGIN_ROUTE_NAME}, {
      state: {
        messageTypesForRemoval: [GlobalMessageType.MSG_TYPE_WARNING,
          GlobalMessageType.MSG_TYPE_ERROR,
          GlobalMessageType.MSG_TYPE_INFO]
      }});
  }

  get attentionReasonInfosFormControls(): AbstractControl[] {
    return (this.registrationForm.get('attentionReasonInfos') as UntypedFormArray).controls;
  }
}
