import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {RoutingService} from '@spartacus/core';
import {Subscription} from 'rxjs';

import {ReturnablePosition, ReturnDocumentType} from '../../model/return-creation.model';
import {ReturnPositionService} from '../../services/return-position.service';
import {validReturnableQuantity, zeroMatchValidator} from '@shared/validators/returnable-quantity';

@Component({
  selector: 'app-returnable-positions',
  templateUrl: './returnable-positions.component.html',
})
export class ReturnablePositionsComponent implements OnInit, OnDestroy {
  @Input() documentNumber: string;
  @Input() documentType: ReturnDocumentType;
  @Input() positions: ReturnablePosition[];
  @Input() nextRoute: string;
  @Input() backRoute: string;
  @Input() routeOrigin: string;
  @Output() isSubmitActiveEvent = new EventEmitter<boolean>(false);

  returnablePositionsForm: UntypedFormGroup;
  items: UntypedFormArray = new UntypedFormArray([]);
  isSubmitButtonEnabled = false;

  private subscription: Subscription = new Subscription();

  constructor(
    private returnPositionService: ReturnPositionService,
    private formBuilder: UntypedFormBuilder,
    private routingService: RoutingService,
  ) {
  }

  ngOnInit(): void {
    if (this.positions?.length > 0) {
      this.positions.forEach((position: ReturnablePosition,index: number) => {
        this.items.push(this.createItem(position));
        // initialize counter's input with 0
        this.items.controls[index].patchValue({
          quantityToReturn: 0
        });
      });
      this.returnablePositionsForm = this.formBuilder.group({
        items: this.items
      }, {validators: zeroMatchValidator});
      this.setQuantityToReturnValue();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private createItem(position: ReturnablePosition): UntypedFormGroup {
    return this.formBuilder.group({
      quantityToReturn: [null, [validReturnableQuantity(position.returnableQuantity)]]
    });
  }

  setAllReturnableQuantity(): void {
    const deliveredQuantities: number[] = Array.from(this.positions, ({deliveredQuantity}) => deliveredQuantity);
    deliveredQuantities.forEach((value: number, index: number) => this.items.controls[index].patchValue({
      quantityToReturn: value
    }));
  }

  onSubmit(): void {
    this.positions.forEach((position: ReturnablePosition, index: number) => {
      position.quantityToReturn = this.items.controls[index].get('quantityToReturn').value;
    });
    this.returnPositionService.saveDocumentData(this.documentNumber, this.documentType, this.routeOrigin);
    this.returnPositionService.saveReturnablePositions(this.positions);
    this.routingService.go({cxRoute: this.nextRoute});
  }

  setQuantityToReturnValue(): void {
    console.log('I am in setQuantityToReturnValue')
    this.subscription.add(
      this.returnPositionService.getReturnablePositions().subscribe((positions: ReturnablePosition[]) => {
        console.log(positions)
        positions?.forEach((position: ReturnablePosition, index: number) => {
          // Reset Input with 0
          this.items.controls[index].patchValue({
            quantityToReturn: 0
          });
          if (position.quantityToReturn) {
            this.items.controls[index].patchValue({
              quantityToReturn: position.quantityToReturn
            });
            if (position.quantityToReturn > 0) {
              this.isSubmitButtonEnabled = true;
              this.isSubmitActiveEvent.emit(true)
            }
          }
        });
      })
    );
  }

  returnPositionQuantityToReturnChange(): void {
    let validControls = true;
    let validQuantityToReturnForSinglePosition = false;
    Array.from(this.items.controls).forEach((control: AbstractControl) => {
      if (control.get('quantityToReturn').value != null && control.get('quantityToReturn').value > 0) {
        validQuantityToReturnForSinglePosition = true;
      }

      if (!control.get('quantityToReturn').valid) {
        validControls = false;
      }
    });
    this.isSubmitButtonEnabled = validQuantityToReturnForSinglePosition && validControls;
    this.isSubmitActiveEvent.emit(validQuantityToReturnForSinglePosition && validControls);
  }

}
