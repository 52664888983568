<div *ngIf="order$ | async as order">
    <h2 class="cx-page-title" *ngIf="order.replenishmentOrderCode || order.sapOrderNumber; else configuratorOrder">
      {{ 'checkoutOrderConfirmation.confirmationOfOrder' | cxTranslate }}
      {{
      (isReplenishmentOrderType$ | async)
        ? order.replenishmentOrderCode
        : order.sapOrderNumber
      }}
    </h2>

    <ng-template #configuratorOrder>
      <h2>
        {{ 'checkoutOrderConfirmation.confirmationOfConfiguratorOrder' | cxTranslate }}
      </h2>
    </ng-template>

  <div class="cx-order-confirmation-message" *ngIf="user$ | async as user">
    <p>
      {{ 'checkoutOrderConfirmation.invoiceHasBeenSentByEmail' | cxTranslate: {email: user.email} }}
      <a [routerLink]="{ cxRoute: ordersRoute } | cxUrl">{{ 'checkoutOrderConfirmation.orderHistory' | cxTranslate}}</a>
    </p>
  </div>
</div>
