import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as ReturnOverviewFeature from '../store/reducers/return-overview.reducer';
import * as ReturnOverviewSelectors from '../store/selectors/return-overview.selectors';
import { ReturnOverviewRequest, ReturnOverviewResponse } from '../model/return-history.model';
import { ReturnOverviewActions } from '../store/actions';

@Injectable({
  providedIn: 'root'
})
export class ReturnOverviewService {

  constructor(protected store: Store<ReturnOverviewFeature.ReturnOverviewState>) {}

  loadOverview(request: ReturnOverviewRequest): void {
    this.store.dispatch(ReturnOverviewActions.loadReturnOverview({ request }));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(ReturnOverviewSelectors.selectLoading);
  }

  getReturnOverviewResponse(): Observable<ReturnOverviewResponse> {
    return this.store.select(ReturnOverviewSelectors.selectResponse);
  }
}
