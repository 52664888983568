import { createAction, props } from '@ngrx/store';
import { InvoicesCreditDetailsResponse } from '../../model/invoices-credits.model';
import { ErrorType } from '@shared/models/ErrorTypes';

export const loadInvoiceCreditDetails = createAction(
  '[InvoicesCredits] Load Invoice Credit Details',
  props<{ documentNumber: string }>()
);

export const loadInvoiceCreditDetailsSuccess = createAction(
  '[InvoicesCredits] Load Invoice Credit Details Success',
  props<{ invoiceDetailsResponse: InvoicesCreditDetailsResponse }>()
);

export const loadInvoiceCreditDetailsFaillure = createAction(
  '[InvoicesCredits] Load Invoice Credit Details Success',
  props<{ errorResponse: ErrorType }>()
);
