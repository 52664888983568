import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as WarrantyProcessFeature from '../store/reducers/warranty-process.reducer';
import * as WarrantyProcessActions from '../store/actions/warranty-process.action';
import * as WarrantyProcessSelectors from '../store/selectors/warranty-process.selectors';
import { WarrantyProcessRequest } from '../model/warranty-process.model';

@Injectable({
  providedIn: 'root'
})
export class WarrantyProcessService {

  constructor(protected store: Store<WarrantyProcessFeature.WarrantyProcessState>) {}

  startWarrantyProcess(request: WarrantyProcessRequest): void {
    this.store.dispatch(WarrantyProcessActions.startWarrantyProcess({request}));
  }

  isProcessing(): Observable<boolean> {
    return this.store.select(WarrantyProcessSelectors.selectProcessing);
  }

  success(): Observable<boolean> {
    return this.store.select(WarrantyProcessSelectors.selectSuccess);
  }
}
