export enum ICON_TYPE {
  CLOSE_LIGHT = 'CLOSE_LIGHT',
  CART_WHITE = 'CART_WHITE',
}

export enum ADD_TO_CART_DIALOG_CLOSE_STATUS {
  SUCCESS_ROUTE_TO_SAME_PAGE = '[Success] Route to same page',
  SUCCESS_ROUTE_TO_CONFIGURED_PAGE = '[Success] Route to configured page',
  CLOSED_ROUTE_TO_SAME_PAGE = '[Closed] Route to same page'
}
