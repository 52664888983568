export const transportDamage = {
  transportDamage: {
    notice: 'Please note that transport damage must be reported in writing by 11:00 a.m. at the latest.',
    globalMessage: {
      adviseOfTransportDamageFailure: 'Advise of transport damage failed.',
      adviseOfTransportDamageSuccess: 'Advise of transport damage succeeded.'
    },
    transportDamageForm: {
      legend1: 'Please enter your details:',
      legend2: 'Please describe the nature of the damage:',
      legend3: 'Please upload your documents:',
      orderNumber: {
        label: 'Order number',
        placeholder: 'Order number'
      },
      deliveryNoteNumber: {
        label: 'Delivery note number',
        placeholder: 'Delivery note number'
      },
      descriptionOfDamage: {
        label: 'Type of damage',
        placeholder: 'Type of damage'
      },
      oemNumber: {
        label: 'OEM number',
        placeholder: 'OEM number'
      },
      additionalHints: {
        label: 'Additional notes',
        placeholder: 'Additional notes'
      },
      repairPossible: {
        label: 'Repair possible',
        placeholder: 'Repair possible'
      },
      repairCosts: {
        label: 'Repair costs',
        placeholder: 'Repair costs'
      },
      replacementWanted: {
        label: 'Replacement delivery desired',
        placeholder: 'Replacement delivery desired'
      },
      packagingImage: {
        label: 'Photo of the entire packaging'
      },
      damagedPartOfPackagingImage: {
        label: 'Photo of the packaging damage'
      },
      articleImage: {
        label: 'Photo of the spare part'
      },
      damagedPartOfArticleImage: {
        label: 'Photo of the damage to the spare part'
      },
      shippingLabelImage: {
        label: 'Photo of the label of the shipping unit'
      },
      articleNumberImage: {
        label: 'Photo of the article number of the spare part'
      },
      additionalImage: {
        label: 'Additional images'
      },
      radio: {
        yes: 'Yes',
        no: 'No'
      },
      submitTransportDamageForm: 'Submit a message',
      formMandatoryFields: 'Mandatory fields are marked with *.',
      messages: {
        maxFileSizeLimitExceeded: 'File upload failed as file size limit ({{maxFileSize}} MB) exceeded'
      }
    }
  },
};
