import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index-de';
import { en } from './en/index-en';

export const contentTilesTranslations = {
  de,
  en
};

// expose all translation chunk mapping for contentTiles feature
export const contentTilesTranslationChunksConfig: TranslationChunksConfig = {
  contentTiles: [
    'contentTiles',
  ],
  tokenGeneration: [
    'tokenGeneration'
  ]
};
