import { createSelector, MemoizedSelector } from '@ngrx/store';

import { Address } from '@spartacus/core';

import * as CartParametersFeature from '../reducers/cart-parameters.reducer';
import { CART_PARAMETERS_FEATURE_KEY } from '../reducers/cart-parameters.reducer';
import { CartAdministrationState, getCartAdministrationState } from '../reducers';
import { OrderType } from '../../model/cart-administration.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ProductCategory} from '../../model/cart-administration-enum.model';
import { DeliveryMode } from '@spartacus/cart/base/root';

export const selectOrderType: MemoizedSelector<CartParametersFeature.CartParametersState, OrderType> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_PARAMETERS_FEATURE_KEY].orderType
);

export const selectShippingType: MemoizedSelector<CartParametersFeature.CartParametersState, DeliveryMode> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_PARAMETERS_FEATURE_KEY].shippingType
);


export const selectDeliveryAddress: MemoizedSelector<CartParametersFeature.CartParametersState, Address> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_PARAMETERS_FEATURE_KEY].deliveryAddress
);


export const selectRequestedDeliveryDate: MemoizedSelector<CartParametersFeature.CartParametersState, NgbDateStruct> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_PARAMETERS_FEATURE_KEY].requestedDeliveryDate
);

export const selectProductCategory: MemoizedSelector<CartParametersFeature.CartParametersState, ProductCategory> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_PARAMETERS_FEATURE_KEY].productCategory
);
