import { createReducer, on } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import { ShippingBacklogUiActions } from '../actions';
import { ShippingBacklogOverviewFilter } from '../../model/shipping-backlog.model';

export const SHIPPING_BACKLOG_UI_FEATURE_KEY = 'shippingBacklogUi';

export interface ShippingBacklogUiState {
  returningFromDetails: boolean;
  selectedFilterEntity?: ShippingBacklogOverviewFilter;
  selectedPagination?: PaginationModel;
  selectedSort?: SortModel;
}

export const initialState: ShippingBacklogUiState = {
  returningFromDetails: false,
  selectedFilterEntity: {
    orderNumber: '',
    oemNumber: '',
    consignmentInfo: '',
    dateFrom: undefined,
    dateTo: undefined,
  },
  selectedPagination: {
    currentPage: 0,
    pageSize: 30,
    sort: 'creationDate',
  },
  selectedSort: {
    code: 'creationDate',
    selected: true,
  },
};

export const shippingBacklogUiReducer = createReducer(
  initialState,

  on(ShippingBacklogUiActions.setSelectedFilterEntity, (state, action) => ({
    ...state,
    selectedFilterEntity: action.selectedFilterEntity,
  })),
  on(ShippingBacklogUiActions.setSelectedPagination, (state, action) => ({
    ...state,
    selectedPagination: action.selectedPagination,
  })),
  on(ShippingBacklogUiActions.setSelectedSorting, (state, action) => ({
    ...state,
    selectedSort: action.selectedSort,
  })),
  on(ShippingBacklogUiActions.setReturningFromDetails, (state, action) => ({
    ...state,
    returningFromDetails: action.returningFromDetails,
  }))
);
