import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, withLatestFrom} from 'rxjs/operators';

import {ICON_TYPE} from '../../model/delivery-notes-icon.model';
import {DeliveryNoteOverviewFilter} from '../../model/delivery-notes.model';
import {DeliveryNotesUiService} from '../../services/delivery-notes-ui.service';
import {DeliveryNotesOverviewService} from '../../services/delivery-notes-overview.service';
import {NgbDatepickerConfig, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Config} from '@spartacus/core';
import {getInitialDateFrom} from '@shared/utils/date-tools';

@Component({
  selector: 'app-delivery-notes-filter',
  templateUrl: './delivery-notes-filter.component.html',
})
export class DeliveryNotesFilterComponent implements OnInit, OnDestroy {
  @Output() deliveryNotesOverviewFilterChanges: EventEmitter<DeliveryNoteOverviewFilter> = new EventEmitter<DeliveryNoteOverviewFilter>();
  deliveryNotesOverviewFilterForm: UntypedFormGroup;
  iconTypes = ICON_TYPE;
  currentDate: Date = new Date();
  maxDate: NgbDateStruct;

  private subscription: Subscription = new Subscription();
  private readonly formProperties = {
    deliveryNoteNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    oemNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    orderNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    consignmentInfo: new UntypedFormControl('', {updateOn: 'blur'}),
    dateFrom: new UntypedFormControl(getInitialDateFrom(this.config.dateFrom.deliverNotesOverview)),
    dateTo: new UntypedFormControl(undefined),
  };
  private readonly resetFormState = {
    deliveryNoteNumber: '',
    oemNumber: '',
    orderNumber: '',
    consignmentInfo: '',
    dateFrom: getInitialDateFrom(this.config.dateFrom.deliverNotesOverview),
    dateTo: undefined,
  };
  private wasReset = false;
  private filterHasChanged = false;

  constructor(
    private fb: UntypedFormBuilder,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    private deliveryNotesOverviewService: DeliveryNotesOverviewService,
    private deliveryNotesUiService: DeliveryNotesUiService,
    @Inject(Config) private config: any,
  ) {
  }

  ngOnInit(): void {
    this.disableFutureDates();
    this.deliveryNotesOverviewFilterForm = this.fb.group(this.formProperties);
    this.onChanges();
    this.setSelectedFilterEntity();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChanges(): void {
    this.subscription.add(
      this.deliveryNotesOverviewFilterForm.valueChanges
      .pipe(
        delay(1000),
        distinctUntilChanged((a, b) =>
          a.deliveryNoteNumber === b.deliveryNoteNumber &&
          a.oemNumber === b.oemNumber &&
          a.orderNumber === b.orderNumber &&
          a.consignmentInfo === b.consignmentInfo &&
          a.dateTo === b.dateTo &&
          a.dateFrom === b.dateFrom)
      ).subscribe((filter: DeliveryNoteOverviewFilter) => {
        this.deliveryNotesOverviewFilterChanges.emit(filter);
        this.filterHasChanged = true;
        this.deliveryNotesUiService.setSelectedFilterEntity(filter);
      })
    );
  }

  setSelectedFilterEntity(): void {
    this.subscription.add(
      this.deliveryNotesUiService.selectedFilterEntity()
      .pipe(
        withLatestFrom(this.deliveryNotesUiService.returningFromDetails()))
      .subscribe(([filterEntity, fromDetails]: [DeliveryNoteOverviewFilter, boolean]) => {
        let filterToEmit = filterEntity;
        if (this.filterHasChanged) {
          this.filterHasChanged = false;
        } else {
          if (fromDetails) {
          this.deliveryNotesOverviewFilterForm.patchValue(
            {
              deliveryNoteNumber: filterEntity.deliveryNoteNumber,
              orderNumber: filterEntity.orderNumber,
              oemNumber: filterEntity.oemNumber,
              consignmentInfo: filterEntity.consignmentInfo,
              dateFrom: filterEntity.dateFrom,
              dateTo: filterEntity.dateTo,
            },
            {emitEvent: false}
          );
        } else if (!this.wasReset) {
          this.wasReset = false;
        } else {
          filterToEmit = this.resetFormState;
        }
      }
        this.deliveryNotesOverviewFilterChanges.emit(filterToEmit);
      })
    );
  }

  disableFutureDates(): void {
    this.maxDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear()
    };
  }

  triggerOnChanges(field: string, event: any): void {
    const patchValue = {};
    patchValue[field] = event.target.value;
    this.deliveryNotesOverviewFilterForm.patchValue(patchValue);
  }

  clearForm(): void {
    this.wasReset = true;
    this.deliveryNotesOverviewFilterForm.reset(this.resetFormState);
  }
}
