import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';

export const loadRegistrationConfirmation = createAction(
  '[RegistrationConfirmation] Load Registration Confirmation',
  props<{ token: string }>()
);

export const loadRegistrationConfirmationSuccess = createAction(
  '[RegistrationConfirmation] Load Registration Confirmation Success',
);

export const loadRegistrationConfirmationFailure = createAction(
  '[RegistrationConfirmation] Load Registration Confirmation Failure',
  props<{ errorResponse: ErrorType }>()
);
