import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ReturnOverviewAdapter } from './adapters/return-overview.adapter';
import { OccReturnOverviewAdapter } from './adapters/impl/occ-return-overview.adapter';
import { ReturnDetailsAdapter } from './adapters/return-details.adapter';
import { OccReturnDetailsAdapter } from './adapters/impl/occ-return-details.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: ReturnOverviewAdapter,
      useClass: OccReturnOverviewAdapter,
    },
    {
      provide: ReturnDetailsAdapter,
      useClass: OccReturnDetailsAdapter,
    }
  ],
})
export class OccModule {
}
