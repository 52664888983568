import {formatDate} from '@angular/common';
import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ActiveCartFacade, Cart, DeliveryMode} from '@spartacus/cart/base/root';
import {CheckoutReviewSubmitComponent, CheckoutStepService} from '@spartacus/checkout/base/components';
import {CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade, CheckoutPaymentFacade} from '@spartacus/checkout/base/root';
import {Address, Config, GlobalMessageType, TranslationService} from '@spartacus/core';
import {Card, CmsComponentData} from '@spartacus/storefront';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {OrderInfoFieldValue, OrderType} from '../../../cart-administration/model/cart-administration.model';
import {PlaceOrderComponentData} from '../../model/checkout-order.model';
import {CheckoutOrderUiService} from '../../service/checkout-order-ui.service';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';


@Component({
  selector: 'app-efa-review-submit',
  templateUrl: './efa-review-submit.component.html',
})
export class EfaReviewSubmitComponent extends CheckoutReviewSubmitComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  deliveryAddress: Address;
  orderType: OrderType;
  requestedDeliveryDate: string;
  deliveryMode: DeliveryMode;
  orderInfoFields: OrderInfoFieldValue[];
  activeCart: Cart;
  deactivatePlaceOrder: boolean;
  mandatoryOrderInfoFieldsExist: boolean;
  @Input() readonly: boolean;
  isValidForm$: Observable<boolean>;
  cartRoute: string = 'cart';

  readonly displayRequestedDeliveryDate: boolean = this.config.displayRequestedDeliveryDate;

  constructor(
    public component: CmsComponentData<PlaceOrderComponentData>,
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected checkoutPaymentFacade: CheckoutPaymentFacade,
    protected activeCartFacade: ActiveCartFacade,
    protected translationService: TranslationService,
    protected checkoutStepService: CheckoutStepService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected checkoutOrderUIService: CheckoutOrderUiService,
    private globalMessageService: EfaGlobalMessageService,
    @Inject(Config) protected config: any
  ) {
    super(checkoutDeliveryAddressFacade, checkoutPaymentFacade, activeCartFacade, translationService, checkoutStepService, checkoutDeliveryModesFacade);
  }

  ngOnInit(): void {
    this.isValidForm$ = this.checkoutOrderUIService.selectedIsValidForm();
    this.setDeactivatePlaceOrder();
    this.setReviewData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setReviewData(): void {
    this.subscription.add(
      this.activeCartFacade.getActive().subscribe((cart: Cart) => {
        this.activeCart = cart;
        this.deliveryAddress = cart.deliveryAddress;
        this.orderType = cart.orderType;
        this.requestedDeliveryDate = cart.requestedDeliveryDate;
        this.deliveryMode = cart.deliveryMode;
        this.orderInfoFields = cart.orderInfoFields;
        this.mandatoryOrderInfoFieldsExist = this.orderInfoFields?.findIndex((orderInfoField: OrderInfoFieldValue) => orderInfoField.orderInfoField?.mandatory) > -1
      })
    );
  }

  getShippingAddressCard(
    deliveryAddress: Address,
    countryName: string
  ): Observable<Card> {
    return combineLatest([
      this.translationService.translate('addressCard.shipTo'),
    ]).pipe(
      map(([textTitle]) => {

        let extendedDeliveryAddressDetails: string = deliveryAddress.postalCode + ' ' + deliveryAddress.town;
        let mainDeliveryAddressDetails: string = deliveryAddress.line1;

        if (deliveryAddress.line2 != null) {
          mainDeliveryAddressDetails = mainDeliveryAddressDetails + ' ' + deliveryAddress.line2;
        }

        if (
          deliveryAddress &&
          deliveryAddress.region &&
          deliveryAddress.region.name &&
          deliveryAddress.region.name.length > 0
        ) {
          extendedDeliveryAddressDetails = extendedDeliveryAddressDetails + ',' + deliveryAddress.region.name;
        }

        if (countryName != null && countryName.length > 0) {
          extendedDeliveryAddressDetails = extendedDeliveryAddressDetails + ',' + countryName;
        }

        return {
          title: textTitle,
          textBold: deliveryAddress.companyName,
          text: [
            mainDeliveryAddressDetails,
            extendedDeliveryAddressDetails,
          ],
        };
      })
    );
  }

  getDeliveryModeCard(deliveryMode: DeliveryMode): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutOrder.checkoutShipping.shippingMethod'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: deliveryMode.name,
          text: [
            deliveryMode.description,
          ],
        };
      })
    );
  }

  getOrderType(orderType: OrderType): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutOrder.checkoutReview.orderType'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: orderType.name,
        };
      })
    );
  }

  getRequestedDeliveryDate(requestedDeliveryDate: string): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutOrder.checkoutReview.requestedDeliveryDate'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: formatDate(requestedDeliveryDate, 'dd.MM.yyyy', 'en-US'),
        };
      })
    );
  }

  placeOrder(): void {
    this.checkoutOrderUIService.triggerPlaceOrder();
  }

  private setDeactivatePlaceOrder(): void {
    this.subscription.add(this.component.data$.pipe(tap((data: PlaceOrderComponentData) => {
      this.deactivatePlaceOrder = data.deactivatePlaceOrder === true || String(data.deactivatePlaceOrder) === 'true';
    })).subscribe());
  }
}
