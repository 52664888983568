import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {OccEndpointsService} from '@spartacus/core';

import {DeliveryNoteDetailsResponse} from '../../../model/delivery-notes.model';
import {DeliveryNotesDetailsAdapter} from '../delivery-notes-details.adapter';

@Injectable({providedIn: 'root'})
export class OccDeliveryNotesDetailsAdapter implements DeliveryNotesDetailsAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  loadDetails(deliveryNoteNumber: string, returnablePositions: boolean, download: boolean): Observable<DeliveryNoteDetailsResponse> {
    const url = this.occEndpoints.buildUrl(
      'deliveryNoteDetails',
      {
        urlParams: {
          userId: 'current',
          deliveryNumber: deliveryNoteNumber,
          returnablePositions,
          download,
        }
      }
    );

    return this.http.get<DeliveryNoteDetailsResponse>(url);
  }
}
