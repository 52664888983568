import { Injectable } from '@angular/core';
import { SetCartEntryInfosAdapter } from '../adapters/set-cart-entry-infos.adapter';
import { Observable } from 'rxjs';
import { SetCartCalculationModeAdapter } from '../adapters/set-cart-calculation-mode.adapter';

@Injectable({
  providedIn: 'root'
})
export class SetCartCalculationModeConnector {

  constructor(protected adapter: SetCartCalculationModeAdapter) {}

  setCartCalculationMode(cartCalculationMode: boolean, cartId: string): Observable<any> {
    return this.adapter.setCartCalculationMode(cartCalculationMode, cartId);
  }
}
