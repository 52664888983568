import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CellComponent} from '@spartacus/organization/administration/components';

@Component({
  selector: 'app-efa-user-group-details-cell',
  templateUrl: './efa-user-group-details-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaUserGroupDetailsCellComponent extends CellComponent{
}
