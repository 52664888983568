import { TranslationChunksConfig } from "@spartacus/core";
import { de } from "./de/index";
import { en } from "./en/index";

export const tiresWarrantyTranslations = {
  de,
  en
};

export const tiresWarrantyTranslationChunksConfig: TranslationChunksConfig = {
  tiresWarranty: [
    'tiresWarranty',
  ],
};
