import { createReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { RegistrationData } from '../../model/login-register.model';
import { RegistrationStartActions } from '../actions';

export const REGISTRATION_START_FEATURE_KEY = 'registrationStart';

export interface RegistrationStartState {
  request: RegistrationData;
  sending: boolean;
  success: boolean;
  error: ErrorType;
}

export const initialStateRegistrationStart: RegistrationStartState = {
  request: undefined,
  sending: false,
  success: false,
  error: undefined,
};

export const registrationStartReducer = createReducer(
  initialStateRegistrationStart,

  on(RegistrationStartActions.loadRegistrationStart, (state, action) => ({
    ...state,
    request: action.request,
    sending: true,
    success: false,
  })),
  on(RegistrationStartActions.loadRegistrationStartSuccess, (state) => ({
    ...state,
    sending: false,
    success: true,
  })),
  on(RegistrationStartActions.loadRegistrationStartFailure, (state, action) => ({
    ...state,
    sending: false,
    success: false,
    error: action.errorResponse
  })),
);

