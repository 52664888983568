import {Component, OnDestroy, OnInit} from '@angular/core';

import {Observable, Subscription} from 'rxjs';
import {TranslationService} from '@spartacus/core';

import {DeliveryNotesDetailsService} from '@core/delivery-notes/delivery-notes-details.service';
import {DeliveryNoteDetailsResponse} from '../../model/delivery-notes.model';
import {PermissionCheckService} from '../../../user-profiles/service/permission-check.service';
import {DeliveryNotesUiService} from '../../services/delivery-notes-ui.service';

const VIEW_RETURNS_PERMISSION = 'viewReturns';

@Component({
  selector: 'app-delivery-notes-details',
  templateUrl: './delivery-notes-details.component.html',
})
export class DeliveryNotesDetailsComponent implements OnInit, OnDestroy {
  deliveryNotesDetails$: Observable<DeliveryNoteDetailsResponse>;
  isLoading$: Observable<boolean>;
  isViewReturnsPermissionAllowed$ = this.permissionCheckService.isAllowed(VIEW_RETURNS_PERMISSION);

  private subscription: Subscription = new Subscription();

  constructor(
    protected translation: TranslationService,
    protected deliveryNotesDetailsService: DeliveryNotesDetailsService,
    protected permissionCheckService: PermissionCheckService,
    private deliveryNotesUiService: DeliveryNotesUiService
  ) {
  }

  ngOnInit(): void {
    this.deliveryNotesDetailsService.reset();
    this.isLoading$ = this.deliveryNotesDetailsService.isLoading();
    this.deliveryNotesDetails$ = this.deliveryNotesDetailsService.getDeliveryNotesDetailsResponse(false, false);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goBack(): void {
    this.deliveryNotesUiService.setReturningFromDetails(true);
  }
}
