import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_SEPARATOR = ', ';

@Pipe({
  name: 'reverseUsername',
})
export class ReverseUsernamePipe implements PipeTransform {
  transform(input: string, separator: string = DEFAULT_SEPARATOR): string {
    if (input == null) {
      return undefined;
    }
    return input.split(' ').reverse().join(DEFAULT_SEPARATOR);
  }
}
