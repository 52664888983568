import { Component } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'app-consent-management-dialog',
  templateUrl: './consent-management-dialog.component.html',
})
export class ConsentManagementDialogComponent {
  isWithdraw = false;

  constructor(
    protected launchDialogService: LaunchDialogService
  ) {
  }

  withdraw(): void {
    this.launchDialogService.closeDialog(!this.isWithdraw);
  }

  cancelWithdraw(): void {
    this.launchDialogService.closeDialog(this.isWithdraw);
  }
}
