import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import { ShippingBacklogCancellationRequest } from '../../model/shipping-backlog.model';

export const sendShippingBacklogCancellation = createAction(
  '[ShippingBacklog] Send Shipping Backlog Cancellation',
  props<{ request: ShippingBacklogCancellationRequest }>()
);

export const sendShippingBacklogCancellationSuccess = createAction(
  '[ShippingBacklog] Send Shipping Backlog Cancellation Success'
);

export const sendShippingBacklogCancellationFailure = createAction(
  '[ShippingBacklog] Send Shipping Backlog Cancellation Failure',
  props<{ errorResponse: ErrorType }>()
);
