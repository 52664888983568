import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CartImportComponent } from './components/cart-import/cart-import.component';

export const defaultCartImportLayoutConfig: LayoutConfig = {
    launch: {
       CART_IMPORT: {
          inlineRoot: true,
          component: CartImportComponent,
          dialogType: DIALOG_TYPE.DIALOG,
       },
    },
};