<div class="cx-order-summary px-0" *ngIf="order$ | async as order">
  <div class="d-flex flex-column flex-md-row justify-content-end mb-3">
    <div class="d-flex flex-column col-12 col-md-4 p-0">
      <app-efa-order-summary [cart]="order"></app-efa-order-summary>
      <div *ngIf="order.fromConfigurator" class="c-configurator-order-tax-notice-container">
        <p class="c-configurator-order-tax-notice">
          <sup>*</sup> {{ 'checkoutOrder.orderCost.noticeConfiguratorTaxAndDeliveryCosts' | cxTranslate }}
        </p>
      </div>
    </div>
  </div>
</div>
