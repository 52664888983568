import { createReducer, on } from '@ngrx/store';
import { TokenGenerationActions } from '../actions';

export const TOKEN_GENERATION_FEATURE_KEY = 'tokenGeneration';

export interface TokenGenerationState {
  tokenName: string;
  saveToken: boolean;
  tokenValue: string;
  loading: boolean;
  success: boolean;
}

export const initialState: TokenGenerationState = {
  tokenName: undefined,
  saveToken: undefined,
  tokenValue: undefined,
  loading: false,
  success: false
};

export const tokenGenerationReducer = createReducer(
  initialState,

  on(TokenGenerationActions.generateToken, (state, action) => ({
    ...state,
    loading: true,
    success: false,
    tokenValue: undefined,
    tokenName: action.tokenName,
    saveToken: action.saveToken
  })),
  on(TokenGenerationActions.generateTokenSuccess, (state, action) => ({
    ...state,
    loading: false,
    success: true,
    tokenValue: action.tokenValue
  })),
  on(TokenGenerationActions.generateTokenFailure, (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.errorResponse
  })),
  on(TokenGenerationActions.reset, (state, action) => ({
      ...
    initialState}
  ))
);
