import { Component, OnDestroy, OnInit } from '@angular/core';
import { PDP_CALL_SOURCE } from '@shared/models/shared.model';
import { CmsService, Page } from '@spartacus/core';
import { ProductListItemComponent, ProductListItemContext, ProductListItemContextSource } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-efa-product-list-item',
  templateUrl: './efa-product-list-item.component.html',
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource
    },
  ],
})
export class EfaProductListItemComponent extends ProductListItemComponent implements OnInit, OnDestroy {
  pdpCallSource: PDP_CALL_SOURCE;

  private subscription: Subscription = new Subscription();

  constructor(
    protected productListItemContextSource: ProductListItemContextSource,
    protected cmsService: CmsService
  ) {
    super(productListItemContextSource);
  }

  ngOnInit(): void {
    this.subscription.add(this.cmsService.getCurrentPage().subscribe((page: Page) => {
      if (page?.pageId === 'search') {
        this.pdpCallSource = PDP_CALL_SOURCE.SEARCH;
      } else {
        this.pdpCallSource = PDP_CALL_SOURCE.CATEGORY;
      }
    }));
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }
}
