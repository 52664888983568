export enum ICON_TYPE {
  CALENDAR = 'CALENDAR',
  RESET = 'RESET',
  DOCUMENTPDF = 'DOCUMENTPDF',
  DELETEFOREVER = 'DELETEFOREVER'
}

export const PDFIcon = `<svg id ="DocumentPDF" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 6L10 0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H14C15.1046 20 16 19.1046 16 18V6ZM14 7V18H2V2H9V7H14ZM10 13V10C10 9.45 9.55 9 9 9H7V14H9C9.55 14 10 13.55 10 13ZM8 10H9V13H8V10ZM12 12H13V11H12V10H13V9H11V14H12V12ZM5 12H4V14H3V9H5C5.55 9 6 9.45 6 10V11C6 11.55 5.55 12 5 12ZM5 10H4V11H5V10Z" fill="#7E8490"/>
</svg>`
