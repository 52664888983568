<div ngbAccordion #acc="ngbAccordion">
  <div ngbAccordionItem="deliveryNotesFilterPanelOne" #deliveryNotesFilterPanelOne="ngbAccordionItem">
    <div ngbAccordionHeader>
      <div class="justify-content-between">
        <h5 class="mb-0">
          <button ngbAccordionToggle class="btn btn-block btn-filter">
            <cx-icon [type]="iconTypes.BROWSE" class="mr-2">
              <span class="btn-filter-text">{{ 'deliveryNotesCustom.myAccount.filter' | cxTranslate }}</span>
            </cx-icon>
          </button>
        </h5>
      </div>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <form [formGroup]="deliveryNotesOverviewFilterForm" class="row">
            <div class="col-md-4">
              <label class="form-group">
                    <span class="label-content">
                      {{ 'deliveryNotesCustom.deliveryNotesFilterForm.deliveryNoteNumber.label' | cxTranslate }}
                    </span>
                <input class="form-control"
                       type="text"
                       formControlName="deliveryNoteNumber"
                       (keydown.enter)="triggerOnChanges('deliveryNoteNumber', $event)"
                       placeholder="{{ 'deliveryNotesCustom.deliveryNotesFilterForm.deliveryNoteNumber.placeholder' | cxTranslate }}"/>
              </label>
            </div>
    
            <div class="col-md-4">
              <label class="form-group">
                    <span class="label-content">
                      {{ 'deliveryNotesCustom.deliveryNotesFilterForm.orderNumber.label' | cxTranslate }}
                    </span>
                <input class="form-control"
                       type="text"
                       formControlName="orderNumber"
                       (keydown.enter)="triggerOnChanges('orderNumber', $event)"
                       placeholder="{{ 'deliveryNotesCustom.deliveryNotesFilterForm.orderNumber.placeholder' | cxTranslate }}"/>
              </label>
            </div>
    
            <div class="col-md-4">
              <label class="form-group">
                    <span class="label-content">
                      {{ 'deliveryNotesCustom.deliveryNotesFilterForm.oemNumber.label' | cxTranslate }}
                    </span>
                <input class="form-control"
                       type="text"
                       formControlName="oemNumber"
                       (keydown.enter)="triggerOnChanges('oemNumber', $event)"
                       placeholder="{{ 'deliveryNotesCustom.deliveryNotesFilterForm.oemNumber.placeholder' | cxTranslate }}"/>
              </label>
            </div>
    
            <div class="col-md-4">
              <label class="form-group">
                    <span class="label-content">
                      {{ 'deliveryNotesCustom.deliveryNotesFilterForm.consignmentInfo.label' | cxTranslate }}
                    </span>
                <input class="form-control"
                       type="text"
                       formControlName="consignmentInfo"
                       (keydown.enter)="triggerOnChanges('consignmentInfo', $event)"
                       placeholder="{{ 'deliveryNotesCustom.deliveryNotesFilterForm.consignmentInfo.placeholder' | cxTranslate }}"/>
              </label>
            </div>
    
            <div class="col-md-4">
              <label class="form-group">
                  <span class="label-content">
                    {{ 'deliveryNotesCustom.deliveryNotesFilterForm.dateFrom.placeholder' | cxTranslate }}
                  </span>
                <div class="input-group">
                  <input class="form-control" ngbDatepicker #df="ngbDatepicker"
                         formControlName="dateFrom"
                         [maxDate]="maxDate"
                         [readonly]="true"
                         efaNgbDatepickerNavigationTooltipsRemove
                         placeholder="{{ 'deliveryNotesCustom.deliveryNotesFilterForm.dateFrom.placeholder' | cxTranslate }}"
                         container="body"/>
                  <div class="input-group-append">
                    <button class="btn btn-primary d-flex" (click)="df.toggle()" type="button">
                      <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                    </button>
                  </div>
                </div>
              </label>
            </div>
    
            <div class="col-md-4">
              <label class="form-group">
                  <span class="label-content">
                    {{ 'deliveryNotesCustom.deliveryNotesFilterForm.dateTo.placeholder' | cxTranslate }}
                  </span>
                <div class="input-group">
                  <input class="form-control" ngbDatepicker #dt="ngbDatepicker"
                         formControlName="dateTo"
                         [maxDate]="maxDate"
                         [readonly]="true"
                         efaNgbDatepickerNavigationTooltipsRemove
                         placeholder="{{ 'deliveryNotesCustom.deliveryNotesFilterForm.dateTo.placeholder' | cxTranslate }}"
                         container="body"/>
                  <div class="input-group-append">
                    <button class="btn btn-primary d-flex" (click)="dt.toggle()" type="button">
                      <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                    </button>
                  </div>
                </div>
              </label>
            </div>
          </form>
          <div class="row justify-content-end">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <button type="button" (click)="clearForm()"  style="color: #003899;" class="btn btn-block btn-secondery">{{ 'clearForm' | cxTranslate }}</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
