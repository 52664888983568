export const invoicesCredits = {
  invoicesCreditsCustom: {
    globalMessage: {
      downloadContentEmpty: 'There isn\'t any PDF document available for te selected document.',
      loadingInvoicesCreditsOverviewFailure: 'Loading invoice credits failed.',
    },
    invoiceCreditOverviewFilterForm: {
      documentNumber: {
        label: 'Document number',
        placeholder: 'Document number'
      },
      oemNumber: {
        label: 'Article number',
        placeholder: 'Article number',
      },
      deliveryNoteNumber: {
        label: 'Delivery note number',
        placeholder: 'Delivery note number',
      },
      orderNumber: {
        label: 'Order number',
        placeholder: 'Order number',
      },
      dateFrom: {
        label: 'Document date from',
        placeholder: 'Document date from',
      },
      dateTo: {
        label: 'Document date to',
        placeholder: 'Document date to',
      },
      documentTypeId: {
        label: 'Document type',
        placeholder: 'Document type',
      },
      consignmentInfo: {
        label: 'Your additional order information',
        placeholder: 'Your additional order information',
      }
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Sort invoices',
      creditBonus: {
        sorting: 'Sort credits and bonuses'
      },
      documentNumber: 'Document number',
      CreationDate: 'Creation date',
      documentTypeId : 'Document type id',
      documentTypeName : 'Document type id',
      total: 'Total',
      select: 'Select',
      download: 'Download',
      selectAll: 'Select All',
    },
    invoiceCreditOverview: {
      downloadInvoiceDocumentFileNamePrefix: 'invoice',
      downloadInvoiceDocumentsCompressedFileNamePrefix: 'invoices',
      downloadCreditDocumentFileNamePrefix: 'Credit',
      downloadCreditDocumentsCompressedFileNamePrefix: 'Credits',
    }
  }
};
