import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CellComponent} from '@spartacus/organization/administration/components';

@Component({
  selector: 'app-efa-org-permission-details-cell',
  templateUrl: './efa-permission-details-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaPermissionDetailsCellComponent extends CellComponent {
}
