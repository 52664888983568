import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TiresWarrantyFormComponent} from './components/tires-warranty-form/tires-warranty-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfigModule, I18nModule, OccConfig} from '@spartacus/core';
import {tiresWarrantyTranslationChunksConfig, tiresWarrantyTranslations} from './translations/tires-warranty.translations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormErrorsModule, IconModule, SpinnerModule, SupplementHashAnchorsModule} from '@spartacus/storefront';
import {StoreModule} from '@ngrx/store';
import {reducers, TIRES_WARRANTY_INDEX_FEATURE_KEY} from './store/reducers';
import {EffectsModule} from '@ngrx/effects';
import {effects} from './store/effects';
import {OccModule} from './occ/occ.module';
import {EfaParagraphComponent} from './components/efa-paragraph/efa-paragraph.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    TiresWarrantyFormComponent,
    EfaParagraphComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    NgbModule,
    NgSelectModule,
    IconModule,
    FormErrorsModule,
    OccModule,
    SpinnerModule,
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            startTiresWarranty: 'users/${userId}/startTiresWarranty'
          },
        },
      },
    } as OccConfig),
    ConfigModule.withConfig({
      i18n: {
        resources: tiresWarrantyTranslations,
        chunks: tiresWarrantyTranslationChunksConfig
      },
      cmsComponents: {
        TiresWarrantyFormComponent: {
          component: TiresWarrantyFormComponent,
        },
        CMSParagraphComponent: {
          component: EfaParagraphComponent,
        },
      }
    }),
    StoreModule.forFeature(TIRES_WARRANTY_INDEX_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
    SupplementHashAnchorsModule,

  ],
  exports: [TiresWarrantyFormComponent]
})
export class TiresWarrantyModule {
}
