import {Injectable, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {B2BUser, GlobalMessageType} from '@spartacus/core';
import {User} from '@spartacus/user/account/root';
import {Title, UserProfileFacade} from '@spartacus/user/profile/root';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class EfaUpdateProfileComponentService implements OnDestroy {
  constructor(
    protected userProfile: UserProfileFacade,
    protected globalMessageService: EfaGlobalMessageService
  ) {}

  private subscription: Subscription = new Subscription();

  protected user$ = this.userProfile
    .get()
    .pipe(filter((user): user is User => Boolean(user)));

  protected busy$ = new BehaviorSubject(false);

  isUpdating$: Observable<boolean> = this.user$.pipe(
    tap((user) => this.patchFormAsB2BUser(user)),
    switchMap((_user: User) => this.busy$),
    tap((state) => (state === true ? this.form.disable() : this.form.enable()))
  );

  titles$: Observable<Title[]> = this.userProfile.getTitles();

  form: UntypedFormGroup = new UntypedFormGroup({
    titleName: new UntypedFormControl(''),
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    locName: new UntypedFormControl(''),
    b2bUnitId: new UntypedFormControl(''),
  });

  /**
   * Updates the user's details and handles the UI.
   */
  updateProfile(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    this.userProfile.update(this.form.value).subscribe({
      next: () => this.onSuccess(),
      error: (error: Error) => this.onError(error),
    });
  }

  protected onSuccess(): void {
    this.globalMessageService.add(
      {
        key: 'updateProfileForm.profileUpdateSuccess',
      },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );

    this.busy$.next(false);
    this.form.reset();
  }

  protected onError(_error: Error): void {
    this.busy$.next(false);
  }

  protected patchFormAsB2BUser(user: User): void {
    this.subscription.add(
      this.titles$.subscribe((titles: any[]) => {
        if (titles != null && titles.length > 0) {
          const title: any = titles.find(
            (title: any) => title.code === user.titleCode
          );
          this.form.patchValue(user);
          if (title != null) {
            this.form.patchValue({
              locName: (user as B2BUser).orgUnit.name,
              b2bUnitId: (user as B2BUser).orgUnit.uid,
              titleName: title.code,
            });
          } else {
            this.form.patchValue({
              locName: (user as B2BUser).orgUnit.name,
              b2bUnitId: (user as B2BUser).orgUnit.uid
            });
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }
}
