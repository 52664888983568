import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {OccEndpointsService} from '@spartacus/core';

import {SetCartEntryInfosAdapter} from '../set-cart-entry-infos.adapter';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class OccSetCartEntryInfosAdapter implements SetCartEntryInfosAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  setCartEntryInfos(currentCartId, currentEntryNumber, currentConsignmentInfo, currentOrderInfoFieldValues): Observable<any> {
    const url = this.occEndpoints.buildUrl('setCartEntryInfos',
      {
        urlParams: {
          userId: 'current',
          cartId: currentCartId,
          entryNumber: currentEntryNumber
        }
      }
    );
    return this.http.post<any>(url, {
      consignmentInfo: currentConsignmentInfo,
      orderInfoFields: currentOrderInfoFieldValues
    });
  }
}
