import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index-de';
import { en } from './en/index-en';

export const checkoutOrderTranslations = {
  de,
  en
};

// expose all translation chunk mapping for checkoutOrder feature
export const checkoutOrderTranslationChunksConfig: TranslationChunksConfig = {
  checkoutOrder: [
    'checkoutOrder',
  ],
};
