import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseItem, CardComponent, ItemService, MessageService } from '@spartacus/organization/administration/components';



@Component({
  selector: 'app-efa-org-card',
  templateUrl: './efa-org-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'content-wrapper' },
  providers: [MessageService],
})
export class EfaOrgCardComponent extends CardComponent< BaseItem> implements OnInit {

  constructor(
    protected itemService: ItemService<BaseItem>,
    protected messageService: MessageService
  ) {
    super(itemService, messageService);
   }

  ngOnInit(): void {
  }

}
