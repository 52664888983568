import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_SEPARATOR = ', ';

@Pipe({
  name: 'joinBy',
})
export class JoinByPipe implements PipeTransform {
  transform(input: string[], separator: string = DEFAULT_SEPARATOR): string {
    if (input == null) {
      return undefined;
    }
    return input.join(separator);
  }
}
