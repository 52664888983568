export const categoryInformation = {
  categoryInformation: {
    pageMetaResolver: {
      baseSite: {
        name: 'Emil Frey Autoteilewelt Webshop',
      },
      help: {
        name: 'Help & Contact'
      },
      customerInfo: {
        name: 'Customer information'
      },
      myAccount: {
        name: 'My account',
        profile: {
          name: 'Profile'
        },
        favorites: {
          name: 'Favorites'
        },
        documents: {
          name: 'Documents management'
        },
        userManagement: {
          name: 'Users & Permissions'
        },
        forms: {
          name: 'Guarantee & Notification of claim'
        },
        users: {
          name: 'Manage users'
        },
        userGroups: {
          name: 'Define permission profiles'
        },
      }
    }
  }
};
