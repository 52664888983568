import { Observable } from 'rxjs';

import { OrderOverviewRequest } from '../../model/order-history.model';
import { OrderHistoryList } from '@spartacus/order';

export abstract class OrderOverviewAdapter {
  abstract loadOverview(
    request: OrderOverviewRequest
  ): Observable<OrderHistoryList>;
}
