import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { OccTiresWarrantyAdapter } from "./adapters/impl/occ-tires-warranty.adapter";
import { TiresWarrantyAdapter } from "./adapters/tires-warranty.adapter";

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers:[
    {
      provide: TiresWarrantyAdapter,
      useClass: OccTiresWarrantyAdapter
    }
  ]
})
export class OccModule { }
