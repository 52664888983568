import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ShippingBacklogItem } from "../model/shipping-backlog.model";
import * as ShippingBacklogSelectedItemsFeature from '../store/reducers/shipping-backlog-selected-items.reducer';
import * as ShippingBacklogSelectedItemsSelectors from '../store/selectors/shipping-backlog-selected-items.selectors';
import * as ShippingBacklogSelectedItemsActions from '../store/actions/shipping-backlog-selected-items.actions';

@Injectable({
  providedIn: 'root',
})
export class ShippingBacklogSelectedItemsService {

  constructor(
    protected store: Store<ShippingBacklogSelectedItemsFeature.ShippingBacklogSelectedItemsState>
  ) {}

  saveSelectedItems(selectedItems: ShippingBacklogItem[]): void {
    this.store.dispatch(ShippingBacklogSelectedItemsActions.saveSelctedItems({ selectedItems }));
  }

  getItems(): Observable<ShippingBacklogItem[]> {
    return this.store.select(ShippingBacklogSelectedItemsSelectors.selectItems);
  }
}
