import { Injectable } from '@angular/core';
import {
  GlobalMessageType
} from '@spartacus/core';
import { UpdatePasswordComponentService } from '@spartacus/user/profile/components';

@Injectable({
    providedIn: 'root'
})
export class EfaUpdatePasswordComponentService extends UpdatePasswordComponentService {
  protected onSuccess(): void {
    this.globalMessageService.add(
      { key: 'updatePasswordForm.passwordUpdateSuccess' },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
    this.busy$.next(false);
    this.form.reset();
  }
}
