import { Component } from '@angular/core';
import { CartAndCheckoutLoadingService } from '@shared/services/cart-and-checkout-loading.service';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'app-cart-delete',
  templateUrl: './cart-delete.component.html',
})
export class CartDeleteComponent {

  constructor(private cartAndCheckoutLoadingService: CartAndCheckoutLoadingService,
    private launchDialogService: LaunchDialogService) {
  }

  openDeleteDialog(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CART_DELETE, undefined, undefined);
    this.launchDialogService.dialogClose.subscribe(result => {
      if (result === 'deleted') {
        setTimeout(() => {this.cartAndCheckoutLoadingService.setCartDeleting(false);
                          this.cartAndCheckoutLoadingService.setCartItemListLoading(false); }, 2000);
      }
    });
  }

}
