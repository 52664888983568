export const userProfiles = {
  userProfiles: {
    assign: 'Assign',
    unassign: 'Unassign',
    viewPrices: 'permission to have access to customer specific prices which includes discounts',
    viewReturns: 'permission to view triggered returns',
    manageUserProfiles: 'permission to manage user profiles with their assigned permissions',
    manageUsers: 'permission to manage (create/edit/delete) users of the current organization',
    viewCredits: 'permission to view received credits',
    startWarrantyProcesses: 'permission to start warranty processes',
    reportTransportDamages: 'permission to report transport damages',
    placeOrders: 'permission to place orders',
    viewInvoices: 'permission to view invoices',
    viewOrders: 'permission to view orders',
    viewDeliveryNotes: 'permission to view delivery notes'
  }
};
