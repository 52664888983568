<section class="cx-page-section container" *ngIf="!(isLoading$ | async); else loading">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 px-2">
      <div class="cx-section">
        <form [formGroup]="registrationCompanyForm">
          <p>{{"efaRegistrationForm.formMandatoryFields" | cxTranslate }}</p>
          <fieldset class="register_fieldset">
            <legend class="register_fieldset-legend numbered">
              {{ "efaRegistrationForm.legend1" | cxTranslate }}
            </legend>

            <div class="register_fieldset-content">
              <label class="form-group">
                <span class="label-content">{{
                  "efaRegistrationForm.companyName.label" | cxTranslate
                  }}
                  *</span>
                <input type="text" class="form-control" autocomplete="organization" formControlName="companyName"
                  placeholder="{{
                    'efaRegistrationForm.companyName.placeholder' | cxTranslate
                  }}" />
                <cx-form-errors prefix="efaRegistrationForm.formErrors.companyName"
                  [control]="registrationCompanyForm.get('companyName')"></cx-form-errors>
              </label>
              <label class="form-group w-100 select-arrow">
                <span
                  class="label-content">{{'efaRegistrationForm.role' | cxTranslate}}
                *</span>
                <select formControlName="roleCode" class="form-control" [ngClass]="getSelectPlaceholderTextClass(registrationCompanyForm.get('roleCode').value)">
                  <option selected value="" disabled>
                    {{ 'efaRegistrationForm.selectPosition' | cxTranslate }}
                  </option>
                  <option
                    *ngFor="let registrationRole of registrationInformationMultiOptionsFieldsValues?.registrationRoles"
                    [value]="registrationRole.code">
                    {{ registrationRole.name }}
                  </option>
                </select>
                <cx-form-errors
                  [control]="registrationCompanyForm.get('roleCode')"></cx-form-errors>
              </label>

              <label class="form-group w-100">
                <span class="label-content">{{ 'efaRegistrationForm.title' | cxTranslate }}
                  *</span>
                <div class="d-inline-flex flex-row flex-wrap">
                  <ng-container
                    *ngFor="let title of titles" >
                    <label class="form-check form-check-inline m-0 mr-4">
                      <input type="radio"
                             formControlName="titleCode"
                             name="titleCode"
                             [value]="title"/>
                             {{ "efaRegistrationForm.gender." + title | cxTranslate }}
                    </label>
                  </ng-container>
                </div>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.title"
                                [control]="registrationCompanyForm.get('titleCode')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{
                  "efaRegistrationForm.yourFirstName.label" | cxTranslate
                  }}
                  *</span>
                <input class="form-control" type="text" name="firstName" autocomplete="given-name" placeholder="{{
                    'efaRegistrationForm.yourFirstName.placeholder'
                      | cxTranslate
                  }}" formControlName="firstName" />
                <cx-form-errors prefix="efaRegistrationForm.formErrors.yourFirstName"
                  [control]="registrationCompanyForm.get('firstName')"></cx-form-errors>
              </label>
              <label class="form-group">
                <span class="label-content">{{
                  "efaRegistrationForm.yourLastName.label" | cxTranslate
                  }}
                  *</span>
                <input class="form-control" type="text" name="lastName" autocomplete="family-name" placeholder="{{
                    'efaRegistrationForm.yourLastName.placeholder' | cxTranslate
                  }}" formControlName="lastName" />
                <cx-form-errors prefix="efaRegistrationForm.formErrors.yourLastName"
                  [control]="registrationCompanyForm.get('lastName')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{ "efaRegistrationForm.taxId.label" | cxTranslate }} *</span>
                <input class="form-control" type="text" placeholder="{{
                    'efaRegistrationForm.taxId.placeholder' | cxTranslate
                  }}" formControlName="taxId" />
                <cx-form-errors prefix="efaRegistrationForm.formErrors.taxId"
                  [control]="registrationCompanyForm.get('taxId')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content"> {{ "efaRegistrationForm.phone.label" | cxTranslate }}
                  *</span>
                <input class="form-control" type="text" autocomplete="phone" placeholder="{{ 'efaRegistrationForm.phone.placeholder' | cxTranslate }}"
                  formControlName="phone" />
                <cx-form-errors prefix="efaRegistrationForm.formErrors.phone"
                  [control]="registrationCompanyForm.get('phone')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{
                  "efaRegistrationForm.emailAddress.label" | cxTranslate
                  }}
                  *</span>
                <input class="form-control" type="email" autocomplete="email" placeholder="{{
                    'efaRegistrationForm.emailAddress.placeholder' | cxTranslate
                  }}" formControlName="email" />
                <cx-form-errors prefix="efaRegistrationForm.formErrors.emailAddress"
                  [control]="registrationCompanyForm.get('email')"></cx-form-errors>
              </label>

              <div class="form-group row">
                <label class="col-md-9">
                  <span class="label-content">{{
                    "efaRegistrationForm.street.label" | cxTranslate
                    }}
                    *</span>
                  <input class="form-control" type="text" name="street" autocomplete="address-line1" placeholder="{{
                      'efaRegistrationForm.street.placeholder' | cxTranslate
                    }}" formControlName="street" />
                  <cx-form-errors prefix="efaRegistrationForm.formErrors.street"
                    [control]="registrationCompanyForm.get('street')"></cx-form-errors>
                </label>
                <label class="col-md-3">
                  <span class="label-content">{{
                    "efaRegistrationForm.streetNumber.label" | cxTranslate
                    }}
                    *</span>
                  <input class="form-control" type="text" name="streetNumber" autocomplete="address-line2" placeholder="{{
                      'efaRegistrationForm.streetNumber.placeholder'
                        | cxTranslate
                    }}" formControlName="streetNumber" />
                  <cx-form-errors prefix="efaRegistrationForm.formErrors.streetNumber"
                    [control]="registrationCompanyForm.get('streetNumber')"></cx-form-errors>
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-4">
                  <span class="label-content">{{
                    "efaRegistrationForm.postalCode.label" | cxTranslate
                    }}
                    *</span>
                  <input class="form-control" type="text" name="postalCode" autocomplete="postal-code" placeholder="{{
                      'efaRegistrationForm.postalCode.placeholder' | cxTranslate
                    }}" formControlName="postalCode" />
                  <cx-form-errors prefix="efaRegistrationForm.formErrors.postalCode"
                    [control]="registrationCompanyForm.get('postalCode')"></cx-form-errors>
                </label>

                <label class="col-md-8">
                  <span class="label-content">{{
                    "efaRegistrationForm.town.label" | cxTranslate
                    }}
                    *</span>
                  <input class="form-control" type="text" placeholder="{{
                      'efaRegistrationForm.town.placeholder' | cxTranslate
                    }}" formControlName="town" />
                  <cx-form-errors prefix="efaRegistrationForm.formErrors.town"
                    [control]="registrationCompanyForm.get('town')"></cx-form-errors>
                </label>
              </div>

              <label class="form-group select-arrow">
                <span class="label-content">{{
                  "efaRegistrationForm.countryIsoCode.label" | cxTranslate
                  }}
                  *</span>
                <select formControlName="countryIsoCode" class="form-control" [ngClass]="getSelectPlaceholderTextClass(registrationCompanyForm.get('countryIsoCode').value)">
                  <option selected value="" disabled>
                    {{
                    "efaRegistrationForm.countryIsoCode.selectCountry"
                    | cxTranslate
                    }}
                  </option>
                  <option *ngFor="let country of countries$ | async" [value]="country.isocode">
                    {{ country.name }}
                  </option>
                </select>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.countryIsoCode"
                  [control]="registrationCompanyForm.get('countryIsoCode')"></cx-form-errors>
              </label>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend class="register_fieldset-legend numbered">
              {{ "efaRegistrationForm.legend2" | cxTranslate }}
              *
            </legend>
            <div class="register_fieldset-content">
              <div class="form-group">
                <div class="d-inline-flex flex-row flex-wrap">
                  <ng-container *ngFor="
                      let businessType of registrationInformationMultiOptionsFieldsValues?.businessTypes
                    ">
                    <label class="form-check form-check-inline m-0 mr-4">
                      <input type="radio" formControlName="businessTypeCode" name="businessTypeCode"
                        [value]="businessType.code" />
                      {{ businessType.name }}
                    </label>
                  </ng-container>
                </div>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.businessTypeCode"
                  [control]="registrationCompanyForm.get('businessTypeCode')"></cx-form-errors>
              </div>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend class="register_fieldset-legend numbered">
              {{ "efaRegistrationForm.legend3" | cxTranslate }}
            </legend>
            <div class="register_fieldset-content">
              <div class="form-group" formArrayName="specializationCodes">
                <div class="d-inline-flex flex-column flex-md-row flex-wrap">
                  <ng-container *ngFor="
                      let registrationSpecificBusinessTypeControl of registrationSpecificBusinessTypeFormControls;
                      let i = index
                    ">
                    <label class="form-check form-check-inline m-0 mr-4">
                      <input type="checkbox" [formControlName]="i" />
                      {{
                      registrationInformationMultiOptionsFieldsValues
                      .registrationSpecificBusinessTypes[i].name
                      }}
                    </label>
                  </ng-container>
                </div>
                <cx-form-errors [control]="registrationCompanyForm.get('specializationCodes')"></cx-form-errors>
              </div>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend class="register_fieldset-legend numbered">
              {{ "efaRegistrationForm.legend4" | cxTranslate }}
            </legend>

            <div class="register_fieldset-content">
              <label class="form-group">
                <span class="label-content">{{
                  "efaRegistrationForm.membership.label" | cxTranslate
                  }}</span>
                <input type="text" class="form-control" formControlName="membership" placeholder="{{
                    'efaRegistrationForm.membership.placeholder' | cxTranslate
                  }}" />
                <cx-form-errors [control]="registrationCompanyForm.get('membership')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{
                  "efaRegistrationForm.membershipId.label" | cxTranslate
                  }}</span>
                <input type="text" class="form-control" formControlName="membershipId" placeholder="{{
                    'efaRegistrationForm.membershipId.placeholder' | cxTranslate
                  }}" />
                <cx-form-errors [control]="registrationCompanyForm.get('membershipId')"></cx-form-errors>
              </label>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
