import { createSelector, MemoizedSelector } from '@ngrx/store';

import { RegistrationData } from '../../model/login-register.model';
import { getLoginRegistrationState, LoginRegistrationState } from '../reducers';
import * as RegistrationLoadFeature from '../reducers/registration-load.reducer';
import { REGISTRATION_LOAD_FEATURE_KEY } from '../reducers/registration-load.reducer';

export const selectLoading: MemoizedSelector<RegistrationLoadFeature.RegistrationLoadState, boolean>
  = createSelector(
  getLoginRegistrationState,
  (state: LoginRegistrationState) => state[REGISTRATION_LOAD_FEATURE_KEY].loading
);

export const selectSuccess: MemoizedSelector<RegistrationLoadFeature.RegistrationLoadState, boolean>
  = createSelector(
  getLoginRegistrationState,
  (state: LoginRegistrationState) => state[REGISTRATION_LOAD_FEATURE_KEY].success
);

export const selectResponse: MemoizedSelector<RegistrationLoadFeature.RegistrationLoadState, RegistrationData>
  = createSelector(
    getLoginRegistrationState,
  (state: LoginRegistrationState) => state[REGISTRATION_LOAD_FEATURE_KEY].response
);
