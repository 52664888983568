import { Component, OnInit } from '@angular/core';
import { CardComponent } from '@spartacus/storefront';

enum ICON_TYPE {
  CHECK_CIRCLE_FILLED = 'CHECK_CIRCLE_FILLED',
}

@Component({
  selector: 'app-efa-card',
  templateUrl: './efa-card.component.html'
})
export class EfaCardComponent extends CardComponent {
  readonly iconType = ICON_TYPE;
  constructor() {
    super()
   }

  ngOnInit(): void {
  }

}
