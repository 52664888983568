export const userProfiles = {
  userProfiles: {
    assign: 'Zuordnen',
    unassign: 'Entfernen',
    viewPrices: 'Berechtigung für den Zugriff auf kundenspezifische Preise',
    viewReturns: 'Berechtigung zum Zugriff auf Retouren',
    manageUserProfiles: 'Berechtigung zur Verwaltung von Benutzerprofilen',
    manageUsers: 'Berechtigung zur Verwaltung von Benutzern',
    viewCredits: 'Berechtigung für den Zugriff auf Gutschriften',
    startWarrantyProcesses: 'Berechtigung zur Garantieabwicklung',
    reportTransportDamages: 'Berechtigung zur Meldung von Transportschäden',
    placeOrders: 'Berechtigung zur Anlage von Bestellungen',
    viewInvoices: 'Berechtigung zur Ansicht von Rechnungen',
    viewOrders: 'Berechtigung zur Ansicht von Bestellungen',
    viewDeliveryNotes: 'Berechtigung zur Ansicht von Lieferscheinen'
  }
};
