import { Injectable } from '@angular/core';
import { SLASH, SLASH_ENCODED_URL_MARKER } from '@shared/models/shared.model';
import { OccCmsComponentAdapter, PageContext, PageType } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class EfaOccCmsComponentAdapter extends OccCmsComponentAdapter {
  protected getContextParams(context: PageContext): {
    [key: string]: string;
  } {
    let httpParams = super.getContextParams(context);

    if (context.type === PageType.PRODUCT_PAGE) {
      httpParams = {
        ...httpParams,
        productCode: context.id?.replace(
          SLASH_ENCODED_URL_MARKER.substring(0, 3),
          SLASH
        ),
      };
    }

    return httpParams;
  }
}
