import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as TransportDamageReducers from './transport-damage.reducer';

export const TRANSPORT_DAMAGE_INDEX_FEATURE_KEY = 'transportDamage';

export interface TransportDamageState {
  [TransportDamageReducers.TRANSPORT_DAMAGE_FEATURE_KEY]: TransportDamageReducers.TransportDamageState;
}

export const reducers: ActionReducerMap<TransportDamageState> = {
  [TransportDamageReducers.TRANSPORT_DAMAGE_FEATURE_KEY]: TransportDamageReducers.transportDamageReducer
};

export const getTransportDamageState = createFeatureSelector<TransportDamageState>(TRANSPORT_DAMAGE_INDEX_FEATURE_KEY);