import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as OrderOverviewFeature from '../store/reducers/order-overview.reducer';
import * as OrderOverviewSelectors from '../store/selectors/order-overview.selectors';
import { OrderOverviewRequest } from '../model/order-history.model';
import { OrderOverviewActions } from '../store/actions';
import { OrderHistoryList } from '@spartacus/order';

@Injectable({
  providedIn: 'root'
})
export class OrderOverviewService {

  constructor(protected store: Store<OrderOverviewFeature.OrderOverviewState>) {}

  loadOverview(request: OrderOverviewRequest): void {
    this.store.dispatch(OrderOverviewActions.loadOrderOverview({ request }));
  }

  resetOverview(): void {
    this.store.dispatch(OrderOverviewActions.resetOrderOverview());
  }

  isLoading(): Observable<boolean> {
    return this.store.select(OrderOverviewSelectors.selectLoading);
  }

  getOrderHistoryList(): Observable<OrderHistoryList> {
    return this.store.select(OrderOverviewSelectors.selectResponse);
  }
}
