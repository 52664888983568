import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ReturnReasonActions } from '../store/actions';
import * as ReturnReasonFeature from '../store/reducers/return-reason.reducer';
import * as ReturnReasonSelectors from '../store/selectors/return-reason.selectors';
import { ReturnReasonResponse } from '../model/return-creation.model';


@Injectable({
  providedIn: 'root'
})
export class ReturnReasonService {
  constructor(protected store: Store<ReturnReasonFeature.ReturnReasonState>) {}

  loadReturnReasons(): void {
    this.store.dispatch(ReturnReasonActions.loadReturnReasons());
  }

  isLoading(): Observable<boolean> {
    return this.store.select(ReturnReasonSelectors.selectLoading);
  }

  getResponse(): Observable<ReturnReasonResponse> {
    this.loadReturnReasons();
    return this.store.select(ReturnReasonSelectors.selectResponse);
  }
}
