<app-shipping-backlog-filter
  (shippingBacklogOverviewFilterChanges)="setShippingBacklogOverviewFilter($event)"></app-shipping-backlog-filter>
  <ng-container *ngIf="{
    isLoading: isLoading$ | async,
    shippingBacklogItems: shippingBacklogItems$ | async,
    dateFormat: dateFormat$ | async
  } as type">
  <ng-container *ngIf="type.shippingBacklogItems && !type.isLoading && type.dateFormat; else loading">
    <!-- BODY -->
    <div class="cx-order-history-body">
      <ng-container *ngIf="type.shippingBacklogItems.pagination.totalResults > 0; else noResult">
        <!-- Select Form and Pagination Top -->
        <div class="cx-order-history-sort top row">
          <div class="cx-order-history-form-group form-group col-sm-12 col-md-4 col-lg-4">
            <!-- <h3>
              <cx-icon [type]="iconTypes.SORTING" class="mr-2"></cx-icon>
              {{ 'shippingBacklog.myAccount.sorting' | cxTranslate }}
            </h3> -->
            <cx-sorting
              [sortOptions]="type.shippingBacklogItems.sorts"
              [sortLabels]="getSortLabels() | async"
              (sortListEvent)="changeSortCode($event)"
              [selectedOption]="type.shippingBacklogItems.pagination.sort"
              placeholder="{{ 'shippingBacklog.myAccount.sorting' | cxTranslate }}"></cx-sorting>
          </div>
          <div *ngIf="type.shippingBacklogItems.pagination.totalPages > 1"
               class="cx-order-history-pagination col-sm-12 col-md-4 col-lg-4">
            <cx-pagination [pagination]="type.shippingBacklogItems.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
        <!-- TABLE -->
        <table class="table cx-order-history-table">
          <thead class="cx-order-history-thead-mobile">
            <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.orderNumber' | cxTranslate }}</th>
            <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.orderPositionNumber' | cxTranslate }}</th>
            <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.oemNumber' | cxTranslate }}</th>
            <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.requestedQuantity' | cxTranslate }}</th>
            <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.expectedDeliveryDate' | cxTranslate }}</th>
            <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.status' | cxTranslate }}</th>
          </thead>
          <tbody>
          <tr *ngFor="let shippingBacklogItem of type.shippingBacklogItems.items">
            <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.orderNumber' | cxTranslate }}"
                class="cx-order-history-number">
              {{ shippingBacklogItem?.orderNumber }}
            </td>
            <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.orderPositionNumber' | cxTranslate }}"
                class="cx-order-history-number">
              {{ shippingBacklogItem?.orderPositionNumber }}
            </td>
            <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.oemNumber' | cxTranslate }}"
                class="cx-order-history-number">
              {{ shippingBacklogItem?.oemNumber }}
            </td>
            <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.requestedQuantity' | cxTranslate }}"
                class="cx-order-history-number">
              {{ shippingBacklogItem?.requestedQuantity }}
            </td>
            <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.expectedDeliveryDate' | cxTranslate }}"
                class="cx-order-history-date">
              {{ shippingBacklogItem?.expectedDeliveryDate | cxDate: type.dateFormat }}
            </td>
            <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.status' | cxTranslate }}"
                class="cx-order-history-type-name">
              {{ shippingBacklogItem?.status }}
            </td>
           <!-- <td>
             <input type="checkbox" class="select-btn" (change)="onItemSelectedChange(shippingBacklogItem)"
                    [checked]="isItemSelected(shippingBacklogItem)"/>
           </td> -->
          </tr>
          </tbody>
        </table>
        <!-- Select Form and Pagination Bottom -->
        <div class="cx-order-history-sort bottom row">
          <div *ngIf="type.shippingBacklogItems.pagination.totalPages > 1"
               class="cx-order-history-pagination">
            <cx-pagination [pagination]="type.shippingBacklogItems.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
       <!-- <button class="c-btn-submit btn btn-primary" (click)="routeToShippingCancellation()">
         {{ 'shippingBacklog.requestCancellationButton' | cxTranslate }}
       </button> -->
      </ng-container>
      <!-- NO RESULT CONTAINER -->
      <ng-template #noResult>
        <app-no-display-result-notice noticeHeadline="{{ 'filteredDocumentsResult.noResult' | cxTranslate }}"></app-no-display-result-notice>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #loading>
    <div *ngIf="type.isLoading" class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</ng-container>
