import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, pairwise, switchMap, withLatestFrom } from 'rxjs/operators';
import { RegistrationStatus } from '../model/login-register-enum.model';
import { RegistrationData } from '../model/login-register.model';
import { RegistrationLoadService } from './registration-load.service';
import { RegistrationProcessLocalStorageService } from './registration-process-local-storage.service';

const REGISTRATION_COMPANY_ROUTE_NAME = 'registrationCompany';

@Injectable({
  providedIn: 'root',
})
export class RegistrationOrchestratorGuard  {
  constructor(
    protected localStorageService: RegistrationProcessLocalStorageService,
    protected registrationLoadService: RegistrationLoadService,
    protected router: Router,
    protected semanticPathService: SemanticPathService
  ) {
    this.localStorageService.initSync();
  }

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.localStorageService.getProcessId().pipe(
      switchMap((processId: string) => {
        this.registrationLoadService.loadRegistrationData(processId);
        return combineLatest([this.registrationLoadService.getRegistrationDataResponse(), this.registrationLoadService.isLoading()]);
      }),
      pairwise(),
      filter(([v1, v2]) => v1[1] === true && v2[1] === false),
      map(([v2]) => v2[0]),
      withLatestFrom(this.registrationLoadService.success()),
      switchMap(([registrationDataResponse, isSuccessful]: [RegistrationData, boolean]) => {
        if (!isSuccessful || registrationDataResponse.status === RegistrationStatus.COMPLETED
        ) {
          this.localStorageService.createProcessId();
          return of(
            this.router.parseUrl(
              this.semanticPathService.get(REGISTRATION_COMPANY_ROUTE_NAME)
            )
          );
        }

        return of(
          this.router.parseUrl(
            this.semanticPathService.get(registrationDataResponse.routeName)
          )
        );
      })
    );
  }
}
