import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs';
import * as AllowedImageExtensionsFeature from '../store/reducers/allowed-image-extensions.reducer';
import * as AllowedImageExtensionsActions from '../store/actions/allowed-image-extensions.action';
import * as AllowedImageExtensionsSelectors from '../store/selectors/allowed-image-extensions.selectors';
import { AllowedFileExtensionsResponse } from '@shared/models/shared.model';

const IMAGES = 'Images';

@Injectable({
  providedIn: 'root'
})
export class AllowedImageExtensionsService {

  constructor(protected store: Store<AllowedImageExtensionsFeature.AllowedImageExtensionsState>) {}

  loadAllowedImageExtensions (formId: string): void {
    const contextId: string = formId + IMAGES;
    this.store.dispatch(AllowedImageExtensionsActions.loadAllowedImageExtensions({ contextId }));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(AllowedImageExtensionsSelectors.selectLoading);
  }

  success(): Observable<boolean> {
    return this.store.select(AllowedImageExtensionsSelectors.selectSuccess);
  }

  getResponse(): Observable<AllowedFileExtensionsResponse> {
    return this.store.select(AllowedImageExtensionsSelectors.selectResponse);
  }
}