export const returnHistory = {
  returnOverview: {
    returnOverviewFilterForm: {
      returnNumber: {
        label: 'Return number',
        placeholder: 'Return number'
      },
      deliveryNoteNumber: {
        label: 'Delivery note number',
        placeholder: 'Delivery note number'
      },
      status: {
        label: 'Status',
        placeholder: 'Status'
      },
      dateFrom: {
        label: 'Date from',
        placeholder: 'Date from'
      },
      dateTo: {
        label: 'Date to',
        placeholder: 'Date to'
      }
    },
    sorting: {
      returnNumber: 'Return number',
      creationDate: 'Date',
      deliveryNoteNumber: 'Delivery note number',
      statusCode: 'State',
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Sort returns',
      returnNumber: 'Return ID',
      deliveryNoteNumber: 'Delivery note number',
      statusCode: 'Status code',
      statusDisplay: 'Status',
      creationDate: 'Creation Date',
      positionCount: 'Number of positions',
    },
    globalMessage: {
      loadingReturnsOverviewFailure: 'Loading returns failed.'
    }
  },
  returnDetails: {
    tableTitle: 'Delivery',
    creationDate: 'Date',
    returnNumber: 'Your return number',
    deliveryNoteNumber: 'Delivery note number',
    statusDisplay: 'Status',
    article: 'Article',
    quantity: 'Quantity / Status',
    order: 'Order',
    returnableReason: 'Return Reason ',
    positionNumber: 'Delivery Note position',
    orderNumber: 'Order Number',
    orderPositionNumber: 'Order Position Number',
    deliveryNotePositionNumber: 'Delivery Note Position Number',
    oemNumber: 'Art.-Nr',
    articleName: 'Article Name',
    consignmentInfo: 'Consignment Info',
    reasonCode: 'Reason Code',
    reasonName: 'Reason Name',
    comment: 'Comment',
    statusCode: 'Status Code',
    requestedReturnQuantity: 'Returned',
    returnedQuantity: 'Approved',
    positionStatusDisplay: 'Return position status',
  }
};
