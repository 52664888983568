import {TranslationChunksConfig} from '@spartacus/assets';

import { de } from './de/index';
import { en } from './en/index';


export const userProfilesTranslations = {
  de,
  en
};

// expose all translation chunk mapping for productDetails feature
export const userProfilesTranslationChunksConfig: TranslationChunksConfig = {
  userProfiles: [
    'userProfiles',
  ],
};
