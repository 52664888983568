export const efaProductDetails = {
  eProductDetails: {
    deliveryTimeLabel: 'Delivery time',
    deliveryTimeText: 'Per piece:',
    deliveryTimeValue: 'day(s)',
    brand: 'Brand',
    goBack: {
      cart: 'back to cart',
      order: 'back to order',
      wishlist: 'back to wishlist',
      search: 'back to search',
      category: 'back to overview',
      carousel: 'back',
      default: 'Close tab',
    },
     description : {
      label: 'Product Details',
      showMore: 'Show more..',
      showLess: 'Show less'
    },
  }
};
