import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TopmotiveCatalogConnector } from '../occ/connectors/topmotive-catalog.connector';

@Injectable({
  providedIn: 'root',
})
export class TopmotiveCatalogService {
  constructor(protected connector: TopmotiveCatalogConnector) {}

  isAuthorized(targetUrl: string): Observable<boolean> {
    return this.connector.isAuthorized(targetUrl);
  }
}
