import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en/index';
import { de } from './de/index';

export const loginRegisterTranslations: TranslationResources = {
  en,
  de
};

// expose all translation chunk mapping for savedCart feature
export const loginRegisterTranslationChunksConfig: TranslationChunksConfig = {
  loginRegister: [
    'efaLoginForm',
    'efaHttpHandlers',
    'efaConsentDialog',
    'efaRegistrationForm',
    'efaRegistrationStart',
    'efaRegistrationConfirmation',
    'efaForgotPasswordForm',
  ],
};
