import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';
import { Observable } from 'rxjs';

import { ReturnOverviewFilter } from '../model/return-history.model';
import { ReturnHistoryUiActions } from '../store/actions';
import * as ReturnHistoryUiFeature from '../store/reducers/return-history-ui.reducer';
import * as ReturnHistoryUiSelectors from '../store/selectors/return-history-ui.selectors';

@Injectable({
  providedIn: 'root'
})
export class ReturnHistoryUiService {

  constructor(protected store: Store<ReturnHistoryUiFeature.ReturnHistoryUiState>) {
  }

  selectedFilterEntity(): Observable<ReturnOverviewFilter> {
    return this.store.select(ReturnHistoryUiSelectors.selectFilterEntity);
  }

  setSelectedFilterEntity(selectedFilterEntity: ReturnOverviewFilter): void {
    this.store.dispatch(ReturnHistoryUiActions.setSelectedFilterEntity({ selectedFilterEntity }));
  }

  selectedPagination(): Observable<PaginationModel> {
    return this.store.select(ReturnHistoryUiSelectors.selectPagination);
  }

  setSelectedPagination(selectedPagination: PaginationModel): void {
    this.store.dispatch(ReturnHistoryUiActions.setSelectedPagination({ selectedPagination }));
  }

  selectedSort(): Observable<SortModel> {
    return this.store.select(ReturnHistoryUiSelectors.selectSort);
  }

  setSelectedSort(selectedSort: SortModel): void {
    this.store.dispatch(ReturnHistoryUiActions.setSelectedSorting({ selectedSort }));
  }

  setReturningFromDetails(returningFromDetails: boolean): void {
    this.store.dispatch(ReturnHistoryUiActions.setReturningFromDetails({ returningFromDetails }));
  }

  returningFromDetails(): Observable<boolean> {
    return this.store.select(ReturnHistoryUiSelectors.returningFromDetails);
  }
}
