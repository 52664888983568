import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { FaqItemAdapter } from './adapters/faq-item.adapter';
import { FaqOverviewAdapter } from './adapters/faq-overview.adapter';
import { OccFaqItemAdapter } from './adapters/impl/occ-faq-item.adapter';
import { OccFaqOverviewAdapter } from './adapters/impl/occ-faq-overview.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: FaqOverviewAdapter,
      useClass: OccFaqOverviewAdapter,
    },
    {
      provide: FaqItemAdapter,
      useClass: OccFaqItemAdapter,
    },
  ],
})
export class OccModule {}
