import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index';
import { en } from './en/index';

export const faqItemsTranslations = {
  de,
  en
};

export const faqItemsTranslationChunksConfig: TranslationChunksConfig = {
  faqItems: [
    'faqItems',
  ],
};