import {CommonModule, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {META_REDUCERS, StoreModule} from '@ngrx/store';
import {SharedModules} from '@shared/shared.modules';
import {AuthGuard, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {
  CardModule,
  CmsPageGuard,
  IconModule,
  ListNavigationModule,
  PageLayoutComponent,
  PaginationModule,
  SpinnerModule
} from '@spartacus/storefront';
import {effects} from '../return-history/store/effects';
import {reducers} from '../return-history/store/reducers';
import {ReturnDetailsComponent} from './components/return-details/return-details.component';
import {ReturnOverviewComponent} from './components/return-overview/return-overview.component';
import {OccModule} from './occ/occ.module';
import {RETURN_HISTORY_FEATURE_KEY} from './store/reducers';
import {metaReducerFactoryReturnDetails} from './store/reducers/return-details.reducer';
import {metaReducerFactoryReturnOverview} from './store/reducers/return-overview.reducer';
import {returnHistoryTranslationChunksConfig, returnHistoryTranslations} from './translations/return-history.translations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReturnOverviewFilterComponent } from './components/return-overview-filter/return-overview-filter.component';


@NgModule({
  declarations: [
    ReturnOverviewComponent,
    ReturnDetailsComponent,
    ReturnOverviewFilterComponent
  ],
  imports: [
    CommonModule,
    UrlModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModules,
    OccModule,
    ListNavigationModule,
    PaginationModule,
    IconModule,
    SpinnerModule,
    CardModule,
    ConfigModule.withConfig({
      i18n: {
        resources: returnHistoryTranslations,
        chunks: returnHistoryTranslationChunksConfig,
      }
    }),
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            returnsOverview: 'users/${userId}/returnsOverview?fields=DEFAULT',
            returnsDetails: 'users/${userId}/returnDetails?returnNumber=${returnNumber}&fields=DEFAULT',
          }
        }
      },
      routing: {
        routes: {
          returnsDetails: {
            paths: ['my-account/documents/returnDetails/:returnNumber'],
          },
          returnsOverview: {
            paths: ['my-account/documents/returns'],
          }
        }
      }
    }),
    ConfigModule.withConfig({
      cmsComponents: {
        ReturnOverviewComponent: {
          component: ReturnOverviewComponent,
        },
        ReturnDetailsComponent: {
          component: ReturnDetailsComponent,
        }
      },
    }),
    ConfigModule.withConfig({
      icon: {
        symbols: {
          FILTER: 'fas fa-filter',
          SORTING: 'fas fa-sort',
          DOWNLOAD: 'fas fa-file-download',
        }
      }
    }),
    RouterModule.forChild([
      {
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {cxRoute: 'returnsDetails'},
      },
    ]),
    StoreModule.forFeature(RETURN_HISTORY_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
  ],
  providers: [
    DatePipe,
  {
    provide: META_REDUCERS,
    deps: [],
    useFactory: metaReducerFactoryReturnOverview,
    multi: true,
  },
  {
    provide: META_REDUCERS,
    deps: [],
    useFactory: metaReducerFactoryReturnDetails,
    multi: true,
  },
]
})
export class ReturnHistoryModule {
}
