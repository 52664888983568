import {FormatWidth, getLocaleDateFormat} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {GlobalMessageType, LanguageService, RoutingService} from '@spartacus/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {filter, first, map} from 'rxjs/operators';
import {
  ShippingBacklogCancellationItem,
  ShippingBacklogCancellationRequest,
  ShippingBacklogItem,
  ShippingBacklogOverviewRequest,
  ShippingBacklogOverviewResponse
} from '../../model/shipping-backlog.model';
import {ShippingBacklogCancellationService} from '../../services/shipping-backlog-cancellation.service';
import {ShippingBacklogOverviewService} from '../../services/shipping-backlog-overview.service';
import {ShippingBacklogSelectedItemsService} from '../../services/shipping-backlog-selected-items.service';
import {ShippingBacklogUiService} from '../../services/shipping-backlog-ui.service';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Component({
  selector: 'app-shipping-backlog-cancellation',
  templateUrl: './shipping-backlog-cancellation.component.html',
})
export class ShippingBacklogCancellationComponent implements OnInit {
  shippingBacklogCancellationItems$: Observable<
    ShippingBacklogCancellationItem[]
  >;
  isLoading$: Observable<boolean>;
  dateFormat$: Observable<string>;

  private subscription: Subscription = new Subscription();

  constructor(
    private shippingBacklogSelectedItemsService: ShippingBacklogSelectedItemsService,
    private shippingBacklogOverviewService: ShippingBacklogOverviewService,
    private shippingBacklogCancellationService: ShippingBacklogCancellationService,
    private shippingBacklogUiService: ShippingBacklogUiService,
    private globalMessageService: EfaGlobalMessageService,
    private languageService: LanguageService,
    private routingService: RoutingService
  ) {}

  ngOnInit(): void {
    this.shippingBacklogCancellationItems$ =
      this.shippingBacklogSelectedItemsService
        .getItems()
        .pipe(
          map((shippingBacklogItems: ShippingBacklogItem[]) =>
            this.mapToShippingBacklogCancellationItems(shippingBacklogItems)
          )
        );

    this.subscription.add(
      combineLatest([
        this.shippingBacklogOverviewService.getRequest(),
        this.shippingBacklogCancellationItems$,
      ])
        .pipe(
          filter(
            ([
              shippingBacklogOverviewRequest,
              shippingBacklogCancellationItems,
            ]: [
              ShippingBacklogOverviewRequest,
              ShippingBacklogCancellationItem[]
            ]) =>
              shippingBacklogOverviewRequest != null &&
              shippingBacklogCancellationItems != null
          ),
          first(),
        )
        .subscribe(
          ([shippingBacklogOverviewRequest, shippingBacklogCancellationItems]: [
            ShippingBacklogOverviewRequest,
            ShippingBacklogCancellationItem[]
          ]) => {
            this.shippingBacklogOverviewService.loadOverview({
              ...shippingBacklogOverviewRequest,
              calculateDeliveredQuantity: true,
              items: shippingBacklogCancellationItems,
            });

            this.shippingBacklogCancellationItems$ = combineLatest([
              this.shippingBacklogCancellationItems$,
              this.shippingBacklogOverviewService
                .getResponse()
                .pipe(
                  map(
                    (
                      shippingBacklogOverviewResponse: ShippingBacklogOverviewResponse
                    ) => shippingBacklogOverviewResponse.items
                  )
                ),
            ]).pipe(
              map(([shippingBacklogCancellationItems, shippingBacklogOverviewItems]) =>
                this.mapToShippingBacklogCancellationItems(
                  shippingBacklogOverviewItems.filter((si) =>
                    shippingBacklogCancellationItems.find(
                      (ci) =>
                        ci.orderNumber === si.orderNumber &&
                        ci.orderPositionNumber === si.orderPositionNumber
                    )
                  )
                )
              )
            );
          }
        )
    );

    this.dateFormat$ = this.languageService
      .getActive()
      .pipe(
        map((language: string) =>
          getLocaleDateFormat(language, FormatWidth.Short).replace(
            new RegExp('y+'),
            'yyyy'
          )
        )
      );
  }

  sendShippingBacklogCancellation(
    shippingBacklogCancellationItems: ShippingBacklogCancellationItem[]
  ): void {
    const shippingBacklogCancellationRequest: ShippingBacklogCancellationRequest =
      {
        cancellationItems: shippingBacklogCancellationItems,
      };
    this.shippingBacklogCancellationService.sendCancellationRequest(
      shippingBacklogCancellationRequest
    );
    this.isLoading$ = this.shippingBacklogCancellationService.isLoading();
    this.subscription.add(
      this.shippingBacklogCancellationService
        .success()
        .subscribe((success: boolean) => {
          if (success) {
            this.showSendShippingBacklogCancellationRequestSuccessMessage();
            this.routingService.go({ cxRoute: 'shippingBacklogOverview' });
          }
        })
    );
  }

  mapToShippingBacklogCancellationItems(
    shippingBacklogItems: ShippingBacklogItem[]
  ): ShippingBacklogCancellationItem[] {
    if (shippingBacklogItems == null) {
      return undefined;
    }

    const shippingBacklogCancellationItems: ShippingBacklogCancellationItem[] =
      [];
    shippingBacklogItems.forEach((shippingBacklogItem: ShippingBacklogItem) => {
      const shippingBacklogCancellationItem: ShippingBacklogCancellationItem = {
        oemNumber: shippingBacklogItem.oemNumber,
        orderNumber: shippingBacklogItem.orderNumber,
        orderPositionNumber: shippingBacklogItem.orderPositionNumber,
        materialNumber: shippingBacklogItem.materialNumber,
        expectedDeliveryDate: shippingBacklogItem.expectedDeliveryDate,
        requestedQuantity: shippingBacklogItem.requestedQuantity,
        deliverableQuantity: shippingBacklogItem.deliverableQuantity,
        status: shippingBacklogItem.status,
        alreadyDeliveredQuantity: shippingBacklogItem.alreadyDeliveredQuantity,
        quantityToCancel:
          shippingBacklogItem.requestedQuantity -
          shippingBacklogItem.alreadyDeliveredQuantity,
      };
      shippingBacklogCancellationItems.push(shippingBacklogCancellationItem);
    });
    return shippingBacklogCancellationItems;
  }

  private showSendShippingBacklogCancellationRequestSuccessMessage(): void {
    this.globalMessageService.add(
      {
        key: 'shippingBacklog.globalMessage.cancellingShippingBacklogItemsSuccess',
      },
      GlobalMessageType.MSG_TYPE_INFO
    );
  }

  goBack(): void {
    this.shippingBacklogUiService.setReturningFromDetails(true);
  }
}
