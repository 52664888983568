import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';

import { ReturnReasonAdapter } from '../return-reason.adapter';
import { ReturnReasonResponse } from '../../../model/return-creation.model';

@Injectable({providedIn: 'root'})
export class OccReturnReasonAdapter implements ReturnReasonAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  loadReasons(): Observable<ReturnReasonResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'returnReason',
      {
        urlParams: {
          userId: 'current'
        }
      }
    );

    return this.http.get<ReturnReasonResponse>(url, {headers});
  }
}
