import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { InvoiceCreditOverviewActions } from '../store/actions';
import * as InvoiceCreditOverviewFeature from '../store/reducers/invoice-credit-overview.reducers';
import * as InvoiceCreditOverviewSelectors from '../store/selectors/invoice-credit-overview.selectors';
import { InvoicesCreditOverviewRequest, InvoicesCreditOverviewResponse } from '../model/invoices-credits.model';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class InvoiceCreditOverviewService {
  constructor(protected store: Store<InvoiceCreditOverviewFeature.InvoiceCreditOverviewState>) {
  }

  loadInvoiceOverview(invoiceRequest: InvoicesCreditOverviewRequest): void {
    return this.store.dispatch(InvoiceCreditOverviewActions.loadInvoiceCreditOverview({ invoiceRequest }));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(InvoiceCreditOverviewSelectors.selectInvoiceOverviewLoading);
  }

  getResponse(): Observable<InvoicesCreditOverviewResponse> {
    return this.store.select(InvoiceCreditOverviewSelectors.selectInvoiceOverviewResponse);
  }
}
