import {NgModule} from '@angular/core';
import {HeaderTelephoneComponent} from './components/header-telephone.component';
import {GenericLinkModule, IconModule,} from '@spartacus/storefront';
import {ConfigModule} from '@spartacus/core';

@NgModule({
  imports: [GenericLinkModule, IconModule, ConfigModule.withConfig({
    cmsComponents: {
      HeaderTelephoneComponent: {
        component: HeaderTelephoneComponent,
      }
    }
  })
  ],
  declarations: [HeaderTelephoneComponent]
})
export class HeaderTelephoneModule {
}
