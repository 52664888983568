import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EfaUserDetailsComponent } from './components/user-details/efa-user-details.component';
import { EfaUserFormComponent } from './components/user-form/efa-user-form.component';
import {
  FormErrorsModule,
  KeyboardFocusModule,
  PopoverModule,
  SplitViewModule,
  TableModule,
  TableConfig,
  IconModule,
  PaginationModule
} from '@spartacus/storefront';
import * as components from '@spartacus/organization/administration/components';
import {
  ActiveLinkCellComponent,
  CardModule,
  CellComponent,
  DeleteItemModule,
  DisableInfoModule,
  FormModule,
  ListModule,
  MessageModule,
  OrganizationTableType,
  StatusCellComponent,
  SubListModule,
  ToggleStatusModule,
  UnitCellComponent,
  UserChangePasswordFormService,
  UserItemService,
  UserFormService,
} from '@spartacus/organization/administration/components';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, OccConfig, provideConfig, UrlModule } from '@spartacus/core';
import { ManageUserProfilesGuard } from './guards/manage-user-profile.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { ProfileTestComponent } from './components/profile-test/profile-test.component';
import { EfaUserGroupDetailsComponent } from './components/efa-user-group-details/efa-user-group-details.component';
import { EfaUserGroupFormComponent } from './components/efa-user-group-form/efa-user-group-form.component';
import {
  EfaPermissionDetailsCellComponent
} from './components/efa-permission-details-cell/efa-permission-details-cell.component';
import { EfaAssignCellComponent } from './components/efa-assign-cell/efa-assign-cell.component';
import {
  userProfilesTranslationChunksConfig,
  userProfilesTranslations
} from './translations/user-profiles.translations';
import { EfaUserDetailsCellComponent } from './components/efa-user-details-cell/efa-user-details-cell.component';
import { ManageUsersGuard } from './guards/manage-user.guard';
import {
  EfaUserGroupDetailsCellComponent
} from './components/efa-user-group-details-cell/efa-user-group-details-cell.component';
import {
  EfaManageUserGroupsListComponent
} from './components/efa-manage-user-groups-list/efa-manage-user-groups-list.component';
import { EfaUserChangePasswordFormService } from './service/efa-user-change-password-form.service';
import { EfaUserItemService } from './service/efa-user-item.service';
import { EfaToggleStatusComponent } from './components/efa-toggle-status/efa-toggle-status.component';
import { SharedModules } from '@shared/shared.modules';
import { EfaListCellComponent } from './components/efa-list-cell/efa-list-cell.component';
import { EfaManageUsersListComponent } from './components/efa-manage-users-list/efa-manage-users-list.component';
import { EfaActiveLinkCellComponent } from './components/efa-active-link-cell/efa-active-link-cell.component';
import { EfaOrgCardComponent } from './components/efa-org-card/efa-org-card.component';
import { EfaUserGroupAssignedPermissionListComponent } from './components/efa-user-group-assigned-permission-list/efa-user-group-assigned-permission-list.component';
import { EfaSubListComponent } from './components/efa-sub-list/efa-sub-list.component';
import { EfaRemoveAssignedCellComponent } from './components/efa-remove-assigned-cell/efa-remove-assigned-cell.component';
import { EfaUserGroupAssignedUserListComponent } from './components/efa-user-group-assigned-user-list/efa-user-group-assigned-user-list.component';
import { EfaUserGroupPermissionListComponent } from './components/efa-user-group-permission-list/efa-user-group-permission-list.component';
import { EfaUserGroupUserListComponent } from './components/efa-user-group-user-list/efa-user-group-user-list.component';
import { EfaUserAssignedUserGroupListComponent } from './components/efa-user-assigned-user-group-list/efa-user-assigned-user-group-list.component';
import { EfaUserUserGroupListComponent } from './components/efa-user-user-group-list/efa-user-user-group-list.component';
import { EfaUserChangePasswordFormComponent } from './components/efa-user-change-password-form/efa-user-change-password-form.component';
import { EfaUserFormService } from './service/efa-user-form.service';

const pagination = {
  pageSize: 2147483647,
};
const actions = {
  dataComponent: components.AssignCellComponent,
};

export const efaUserGroupCmsConfig: CmsConfig = {
  cmsComponents: {
    ManageUserGroupsListComponent: {
      component: EfaManageUserGroupsListComponent,
      childRoutes: {
        children: [
          {
            path: `:${'userGroupCode'}`,
            component: EfaUserGroupDetailsComponent,
            data: {
              cxPageMeta: {breadcrumb: 'orgUserGroup.breadcrumbs.details'},
            },
            children: [
              {
                path: 'edit',
                component: EfaUserGroupFormComponent,
              },
              {
                path: 'users',
                data: {
                  cxPageMeta: {breadcrumb: 'orgUserGroup.breadcrumbs.users'},
                },
                children: [
                  {
                    path: '',
                    component: EfaUserGroupAssignedUserListComponent,
                  },
                  {
                    path: 'assign',
                    component: EfaUserGroupUserListComponent,
                  },
                ],
              },
              {
                path: 'purchase-limits',
                data: {
                  cxPageMeta: {
                    breadcrumb: 'orgUserGroup.breadcrumbs.permissions',
                  },
                },
                children: [
                  {
                    path: '',
                    component: EfaUserGroupAssignedPermissionListComponent,
                  },
                  {
                    path: 'assign',
                    component: EfaUserGroupPermissionListComponent,
                  },
                ],
              },
            ],
          },
        ],
      },
      guards: [AuthGuard, ManageUserProfilesGuard],
    },
  },
};

const efaUserCmsConfig: CmsConfig = {
  cmsComponents: {
    ManageUsersListComponent: {
      component: EfaManageUsersListComponent,
      childRoutes: {
        children: [
          {
            path: 'create',
            component: EfaUserFormComponent,
          },
          {
            path: `:${'userCode'}`,
            component: EfaUserDetailsComponent,
            data: {
              cxPageMeta: {breadcrumb: 'orgUser.breadcrumbs.details'},
            },
            children: [
              {
                path: `edit`,
                component: EfaUserFormComponent,
              },
              {
                path: `change-password`,
                component: EfaUserChangePasswordFormComponent,
              },
              {
                path: 'user-groups',
                data: {
                  cxPageMeta: {breadcrumb: 'orgUser.breadcrumbs.userGroups'},
                },
                children: [
                  {
                    path: '',
                    component: EfaUserAssignedUserGroupListComponent,
                  },
                  {
                    path: 'assign',
                    component: EfaUserUserGroupListComponent,
                  },
                ],
              },
              {
                path: 'permissions',
                data: {
                  cxPageMeta: {breadcrumb: 'orgUser.breadcrumbs.permissions'},
                },
                children: [
                  {
                    path: '',
                    component: components.UserAssignedPermissionListComponent,
                  },
                  {
                    path: 'assign',
                    component: components.UserPermissionListComponent,
                  },
                ],
              },
            ],
          },
        ],
      },
      guards: [AuthGuard, ManageUsersGuard],
    },
  }
};

const tableConfig: TableConfig = {
  table: {
    [OrganizationTableType.USER_GROUP]: {
      cells: ['name', 'roles'],
      options: {
        dataComponent: CellComponent,
        cells: {
          name: {
            dataComponent: ActiveLinkCellComponent,
          },
          uid: {
            dataComponent: CellComponent,
          },
          unit: {
            dataComponent: UnitCellComponent,
          },
          roles: {
            dataComponent: EfaListCellComponent,
          }
        },
      },
    },
    [OrganizationTableType.USER_GROUP_PERMISSIONS]: {
      cells: ['code', 'actions'],
      options: {
        cells: {
          code: {
            dataComponent: EfaPermissionDetailsCellComponent,
          },
          actions: {
            dataComponent: EfaAssignCellComponent,
          },
        },
      },
    },
    [OrganizationTableType.USER_GROUP_ASSIGNED_PERMISSIONS]: {
      cells: ['code', 'actions'],
      options: {
        cells: {
          code: {
            dataComponent: EfaPermissionDetailsCellComponent,
          },
          actions: {
            dataComponent: EfaRemoveAssignedCellComponent,
          },
        },
        pagination: {
          pageSize: pagination.pageSize,
        },
      },
    },
    [OrganizationTableType.USER_GROUP_ASSIGNED_USERS]: {
      cells: ['name', 'actions'],
      options: {
        cells: {
          name: {
            dataComponent: EfaUserDetailsCellComponent,
          },
          actions: {
            dataComponent: EfaRemoveAssignedCellComponent,
          },
        },
        pagination: {
          pageSize: pagination.pageSize,
        },
      },
    },
    [OrganizationTableType.USER_GROUP_USERS]: {
      cells: ['name', 'actions'],
      options: {
        cells: {
          name: {
            dataComponent: EfaUserDetailsCellComponent,
          },
          actions: {
            dataComponent: EfaAssignCellComponent,
          },
        },
      },
    },
    [OrganizationTableType.USER_ASSIGNED_USER_GROUPS]: {
      cells: ['name', 'actions'],
      options: {
        cells: {
          name: {
            dataComponent: EfaUserGroupDetailsCellComponent,
          },
          actions: {
            dataComponent: EfaRemoveAssignedCellComponent,
          },
        },
        pagination,
      },
    },
    [OrganizationTableType.USER_USER_GROUPS]: {
      cells: ['name', 'actions'],
      options: {
        cells: {
          name: {
            dataComponent: EfaUserGroupDetailsCellComponent,
          },
          actions: {
            dataComponent: EfaAssignCellComponent,
          },
        },
        pagination,
      },
    },
    [OrganizationTableType.USER]: {
      cells: ['name', 'active', 'lastLogin', 'email', 'permissionGroupNames'],
      options: {
        cells: {
          name: {
            dataComponent: EfaActiveLinkCellComponent,
          },
          active: {
            dataComponent: StatusCellComponent,
          },
          lastLogin: {
            dataComponent: CellComponent,
          },
          email: {
            dataComponent: CellComponent,
          },
          permissionGroupNames: {
            dataComponent: EfaListCellComponent,
          },
        },
      },
    },
  }
};

@NgModule({
  declarations: [
    EfaUserDetailsComponent,
    EfaUserFormComponent,
    ProfileTestComponent,
    EfaUserGroupDetailsComponent,
    EfaUserGroupDetailsComponent,
    EfaUserGroupFormComponent,
    EfaPermissionDetailsCellComponent,
    EfaAssignCellComponent,
    EfaUserDetailsCellComponent,
    EfaUserGroupDetailsCellComponent,
    EfaManageUserGroupsListComponent,
    EfaToggleStatusComponent,
    EfaListCellComponent,
    EfaManageUsersListComponent,
    EfaActiveLinkCellComponent,
    EfaSubListComponent,
    EfaOrgCardComponent,
    EfaUserGroupAssignedPermissionListComponent,
    EfaRemoveAssignedCellComponent,
    EfaUserGroupAssignedUserListComponent,
    EfaUserGroupPermissionListComponent,
    EfaUserGroupUserListComponent,
    EfaUserAssignedUserGroupListComponent,
    EfaUserUserGroupListComponent,
    EfaUserChangePasswordFormComponent,
    ],
  exports: [EfaManageUserGroupsListComponent, EfaUserDetailsComponent, EfaUserFormComponent, ProfileTestComponent,EfaOrgCardComponent],
  imports: [
    CommonModule,
    FormModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModules,
    I18nModule,
    FormErrorsModule,
    KeyboardFocusModule,
    RouterModule,
    UrlModule,
    ToggleStatusModule,
    DisableInfoModule,
    CardModule,
    PopoverModule,
    SplitViewModule,
    ListModule,
    TableModule,
    DeleteItemModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    PaginationModule,
    MessageModule,
    SubListModule,
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            user: '/orgUsers/${userId}?fields=DEFAULT,permissionIds,permissionGroupNames,defaultManufacturers,termsAccepted',
          },
        },
      },
    } as OccConfig),
    ConfigModule.withConfig({
      i18n: {
        resources: userProfilesTranslations,
        chunks: userProfilesTranslationChunksConfig
      }
    }),
  ],
  providers: [
    provideConfig(efaUserGroupCmsConfig),
    provideConfig(efaUserCmsConfig),
    provideConfig(tableConfig),
    {
      provide: UserChangePasswordFormService,
      useClass: EfaUserChangePasswordFormService
    },
    {
      provide: UserItemService,
      useClass: EfaUserItemService
    },
    {
      provide: UserFormService,
      useClass: EfaUserFormService,
    }
  ]
})
export class UserProfilesModule {
}
