import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DeliveryNoteDetailsResponse } from '../../model/delivery-notes.model';
import { DeliveryNotesDetailsAdapter } from '../adapters/delivery-notes-details.adapter';

@Injectable({
  providedIn: 'root',
})
export class DeliveryNotesDetailsConnector {
  constructor(protected adapter: DeliveryNotesDetailsAdapter) {}

  loadDetails(deliveryNoteNumber: string, returnablePositions: boolean, download: boolean): Observable<DeliveryNoteDetailsResponse> {
    return this.adapter.loadDetails(deliveryNoteNumber, returnablePositions, download);
  }
}
