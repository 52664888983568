import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { OccEndpointsService } from '@spartacus/core';

import { Observable } from 'rxjs';
import { RegistrationData } from '../../../model/login-register.model';
import { RegistrationLoadAdapter } from '../registration-load.adapter';

@Injectable({ providedIn: 'root' })
export class OccRegistrationLoadAdapter implements RegistrationLoadAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  loadRegistrationData(processId: string): Observable<RegistrationData> {
    const url = this.occEndpoints.buildUrl(
      'registrationLoad',
      {
        urlParams: {
          processId
        }
    });
  
    return this.http.get<RegistrationData>(url);
  }
}