import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ConfigModule, I18nModule} from '@spartacus/core';
import {FormErrorsModule, IconModule, ListNavigationModule, SpinnerModule} from '@spartacus/storefront';
import {META_REDUCERS, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {invoiceCreditFeatureKey, reducers} from './store/reducers';
import {effects} from './store/effects';
import {OccModule} from './occ/occ.module';
import {InvoiceCreditFilterComponent} from './components/invoice-credit-filter/invoice-credit-filter.component';
import {InvoiceCreditOverviewComponent} from './components/invoice-credit-overview/invoice-credit-overview.component';
import {
  invoicesCreditsTranslationChunksConfig,
  InvoicesCreditsTranslations
} from './translations/invoices-credits.translations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {metaReducerFactoryInvoiceOverview} from './store/reducers/invoice-credit-overview.reducers';
import {metaReducerFactoryInvoiceDetails} from './store/reducers/invoice-credit-details.reducers';
import {CreditBonusOverviewComponent} from './components/credit-bonus-overview/credit-bonus-overview.component';
import { SharedModules } from '@shared/shared.modules';


@NgModule({
  declarations: [InvoiceCreditFilterComponent, InvoiceCreditOverviewComponent, CreditBonusOverviewComponent],
  imports: [
    CommonModule,
    OccModule,
    I18nModule,
    FormErrorsModule,
    ReactiveFormsModule,
    FormsModule,
    IconModule,
    NgbModule,
    ListNavigationModule,
    SpinnerModule,
    SharedModules,
    ConfigModule.withConfig({
      icon: {
        symbols: {
          FILTER: 'fas fa-filter',
          SORTING: 'fas fa-sort',
          DOWNLOAD: 'fas fa-file-download'
        }
      }
    }),
    ConfigModule.withConfig({
      i18n: {
        resources: InvoicesCreditsTranslations,
        chunks: invoicesCreditsTranslationChunksConfig
      },
      cmsComponents: {
        InvoiceCreditOverviewComponent: {
          component: InvoiceCreditOverviewComponent,
        },
        CreditBonusOverviewComponent: {
          component: CreditBonusOverviewComponent,
        },
      },
      backend: {
        occ: {
          endpoints: {
            invoiceCreditsOverview: 'users/${userId}/invoiceCreditsOverview?fields=DEFAULT',
            invoiceCreditsDetails: 'users/${userId}/invoiceCreditsDetails?documentNumber=${documentNumber}&fields=DEFAULT',
          }
        }
      }
    }),
    StoreModule.forFeature(invoiceCreditFeatureKey, reducers),
    EffectsModule.forFeature(effects)
  ],
  providers: [
    {
      provide: META_REDUCERS,
      deps: [],
      useFactory: metaReducerFactoryInvoiceOverview,
      multi: true,
    },
    {
      provide: META_REDUCERS,
      deps: [],
      useFactory: metaReducerFactoryInvoiceDetails,
      multi: true,
    },
  ]
})
export class InvoicesCreditsModule {
}
