import {
  CartProductSearchResult,
  CartProductSearchResultItem
} from "../../model/cart-administration.model";


export const PRUEFARTIKEL = 'PRUEFARTIKEL';
export const SAPPRUEFARTIKEL = 'SAP' + PRUEFARTIKEL;

export function isCartProductSearchResultPurchasable(result: CartProductSearchResult): boolean {
  const nonPurchasableItem = result?.resultItems?.find((resultItem: CartProductSearchResultItem) => !isCartProductSearchResultItemPurchasable(resultItem));
  return !nonPurchasableItem;
}

function isCartProductSearchResultItemPurchasable(resultItem: CartProductSearchResultItem): boolean {
  return (!resultItem?.salesStatus || resultItem?.salesStatus === '00' || resultItem?.salesStatus === '01') && resultItem?.materialNumber !== SAPPRUEFARTIKEL;
}

export function getCartProductSearchResultsNotPurchasableReason(results: CartProductSearchResult[]) {
  if (results?.length > 1) {
    return 'articleNotPurchasable';
  } else {
    return getCartProductSearchResultItemNotPurchasableReason(results[0]?.resultItems[0])
  }
}

function getCartProductSearchResultItemNotPurchasableReason(resultItem: CartProductSearchResultItem): string {
  switch (resultItem?.salesStatus) {
    case '02':
      return 'articleCannotBeDelivered';
    case '03':
    case '04':
      return 'articleNotApprovedForSale';
    default:
      return resultItem?.materialNumber === SAPPRUEFARTIKEL ? 'articleCannotBeDelivered' : 'articleNotFound';
  }
}
