import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OccTokenGenerationAdapter } from './adapters/impl/occ-token-generation.adapter';
import { TokenGenerationAdapter } from './adapters/token-generation.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: TokenGenerationAdapter,
      useClass: OccTokenGenerationAdapter,
    },
  ],
})
export class OccModule {}
