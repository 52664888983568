import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFlowRoutesService } from '@spartacus/core';
import { AuthRedirectStorageService } from '@spartacus/core';
import { RoutingService } from '@spartacus/core';
import { AuthRedirectService } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class EfaAuthRedirectService extends AuthRedirectService {
  constructor(
    protected routing: RoutingService,
    protected router: Router,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected authFlowRoutesService: AuthFlowRoutesService
  ) {
    super(routing, router, authRedirectStorageService, authFlowRoutesService);
  }

  clearRedirectUrlPublic(): void {
     this.clearRedirectUrl();
  }
}
