import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as TokenGenerationReducers from './token-generation.reducer';

export const TOKEN_GENERATION_INDEX_FEATURE_KEY = 'tokenGeneration';

export interface TokenGenerationState {
  [TokenGenerationReducers.TOKEN_GENERATION_FEATURE_KEY]: TokenGenerationReducers.TokenGenerationState;
}

export const reducers: ActionReducerMap<TokenGenerationState> = {
  [TokenGenerationReducers.TOKEN_GENERATION_FEATURE_KEY]: TokenGenerationReducers.tokenGenerationReducer,
};

export const getTokenGenerationState = createFeatureSelector<TokenGenerationState>(TOKEN_GENERATION_INDEX_FEATURE_KEY);