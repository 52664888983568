import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EfaMiniCartComponent } from './components/efa-mini-cart/efa-mini-cart.component';
import { RouterModule } from '@angular/router';
import { UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';
import { ConfigModule } from '@spartacus/core';



@NgModule({
  declarations: [
    EfaMiniCartComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MiniCartComponent: {
          component: EfaMiniCartComponent,
        }
      }
    }),
  ]
})
export class MiniCartModule { }
