<ng-container *ngIf="(deliveryNotesDetails$ | async) as deliveryNoteDetails; else loading">
  <div class="delivery-note-returnable-container">
    <app-delivery-notes-header [deliveryNoteNumber]="deliveryNoteDetails?.deliveryNoteNumber"
                             [creationDate]="deliveryNoteDetails?.creationDate"
                             [warehouseName]="deliveryNoteDetails?.warehouseName"
                             [receiverAddress]="deliveryNoteDetails?.receiverAddress">
    </app-delivery-notes-header>
    <app-returnable-positions [positions]="returnablePositions"
                              [documentType]="documentType"
                              [documentNumber]="documentNumber"
                              [nextRoute]="deliveryNoteReturnCreationRoute"
                              [backRoute]="deliveryNoteDetailsRoute"
                              [routeOrigin]="routeOrigin"
                              (isSubmitActiveEvent)="setSubmitActiveStatus($event)">
    </app-returnable-positions>
  </div>

  <div class="row justify-content-end">
    <div class="col-12" [ngClass]="deliveryNoteDetails?.positions?.length > 0 ? 'col-md-2' : 'col-md-4'">
      <button type="button"
          class="btn btn-block" [ngClass]="deliveryNoteDetails?.positions?.length > 0 ? 'btn-secondary' : 'btn-primary'"
          [routerLink]="{ cxRoute: 'deliveryNoteDetails', params: { deliveryNoteNumber: deliveryNoteDetails?.deliveryNoteNumber } } | cxUrl">
          {{ 'common.back' | cxTranslate }}
      </button>
    </div>
    <div *ngIf="deliveryNoteDetails?.positions?.length > 0"  class="col-12 col-md-4">
      <button (click)="submit()"
          class="btn btn-block btn-primary"
          [disabled]="!isSubmitButtonEnabled">
          {{ 'returnablePositions.nextButton' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
