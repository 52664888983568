import { createReducer, on } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import { OrderOverViewUiActions } from '../actions';
import { OrderOverviewFilter } from '../../model/order-history.model';

export const ORDER_OVERVIEW_UI_FEATURE_KEY = 'orderOverviewUi';

export interface OrderOverviewUiState {
  selectedFilterEntity?: OrderOverviewFilter;
  selectedPagination?: PaginationModel;
  selectedSort?: SortModel;
  returningFromDetails?: boolean
}

export const initialState: OrderOverviewUiState = {
    selectedFilterEntity: {
      orderNumber: '',
      orderStateId: '',
      receiverAddressId: '',
      dateFrom: undefined,
      dateTo: undefined,
      consignmentInfo: '',
    },
  selectedPagination: {
      currentPage: 0,
      pageSize: 30,
      sort: 'creationDate',
    },
  selectedSort: {
    code: 'creationDate',
    selected: true
    },
  returningFromDetails: false
  }
;

export const orderOverviewUiReducer = createReducer(
  initialState,

  on(OrderOverViewUiActions.setSelectedFilterEntity, (state, action) => ({
    ...state,
    selectedFilterEntity: action.selectedFilterEntity
  })),
  on(OrderOverViewUiActions.setSelectedPagination, (state, action) => ({
    ...state,
    selectedPagination: action.selectedPagination
  })),
  on(OrderOverViewUiActions.setSelectedSorting, (state, action) => ({
    ...state,
    selectedSort: action.selectedSort
  })),
  on(OrderOverViewUiActions.setReturningFromDetails, (state, action) => ({
    ...state,
    returningFromDetails: action.returningFromDetails
  })),
);
