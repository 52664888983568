import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { AmendOrderActionsComponent } from '@spartacus/order/components';

@Component({
  selector: 'app-efa-amend-order-actions',
  templateUrl: './efa-amend-order-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaAmendOrderActionsComponent extends AmendOrderActionsComponent {

  constructor(protected routingService: RoutingService) { 
    super(routingService);
  }
}
