import {
  DIALOG_TYPE,
  LayoutConfig
} from '@spartacus/storefront';
import { ConfiguratorCartConfirmationMessageComponent } from '../functions/cart-administration/components/configurator-cart-confirmation-message/configurator-cart-confirmation-message.component';

export const defaultConfiguratorCartConfirmationLayoutConfig: LayoutConfig = {
  launch: {
    CONFIGURATOR_CART_CONFIRMATION: {
      inline: true,
      component: ConfiguratorCartConfirmationMessageComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
      multi: false,
    },
  },
};

export const CONFIGURATOR_CART_CONFIRMATION = 'CONFIGURATOR_CART_CONFIRMATION';