import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { TopmotiveConfirmationDialogComponent } from '../functions/external-catalogs/components/topmotive-confirmation-dialog/topmotive-confirmation-dialog.component';

export const defaultTopmotiveConfirmationLayoutConfig: LayoutConfig = {
  launch: {
    TOPMOTIVE_CONFIRMATION: {
      inlineRoot: true,
      component: TopmotiveConfirmationDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
      multi: false,
    },
  },
};

export const TOPMOTIVE_CONFIRMATION = 'TOPMOTIVE_CONFIRMATION';
