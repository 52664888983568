import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ListService, UserGroupAssignedPermissionListComponent, UserGroupAssignedPermissionsListService } from '@spartacus/organization/administration/components';

@Component({
  selector: 'app-efa-user-group-assigned-permission-list',
  templateUrl: './efa-user-group-assigned-permission-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'content-wrapper' },
  providers: [
    {
      provide: ListService,
      useExisting: UserGroupAssignedPermissionsListService,
    },
  ],
})
export class EfaUserGroupAssignedPermissionListComponent extends UserGroupAssignedPermissionListComponent implements OnInit {

  ngOnInit(): void {
  }

}
