import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {OccEndpointsService} from '@spartacus/core';

import {DeliveryNotesOverviewAdapter} from '../delivery-notes-overview.adapter';
import {DeliveryNoteOverviewRequest, DeliveryNoteOverviewResponse} from '../../../model/delivery-notes.model';

@Injectable({providedIn: 'root'})
export class OccDeliveryNotesOverviewAdapter implements DeliveryNotesOverviewAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  loadOverview(request: DeliveryNoteOverviewRequest): Observable<DeliveryNoteOverviewResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'deliveryNoteOverview',
      {
        urlParams: {
          userId: 'current'
        }
      }
    );

    return this.http.post<DeliveryNoteOverviewResponse>(url, request, {headers});
  }
}
