import { Component, OnInit } from '@angular/core';
import { UpdatePasswordComponent, UpdatePasswordComponentService } from '@spartacus/user/profile/components';

enum ICON_TYPE {
  INFOCIRCLEFILLED= 'INFOCIRCLEFILLED',
}

@Component({
  selector: 'app-efa-update-password',
  templateUrl: './efa-update-password.component.html'
})

export class EfaUpdatePasswordComponent extends UpdatePasswordComponent implements OnInit {

  readonly iconTypes = ICON_TYPE;
  constructor(protected service: UpdatePasswordComponentService) {
    super(service);
  }

  ngOnInit(): void {
  }

}
