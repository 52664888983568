import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as ReturnPositionFeature from '../reducers/return-position.reducer';
import { RETURN_POSITION_FEATURE_KEY } from '../reducers/return-position.reducer';
import { getReturnCreationState, ReturnCreationState } from '../reducers';
import { ReturnablePosition, ReturnDocumentType } from '../../model/return-creation.model';

export const selectPositions: MemoizedSelector<ReturnPositionFeature.ReturnPositionState, ReturnablePosition[]> = createSelector(
  getReturnCreationState,
  (state: ReturnCreationState) => state[RETURN_POSITION_FEATURE_KEY].positions
);

export const selectDocumentNumber: MemoizedSelector<ReturnPositionFeature.ReturnPositionState, string> = createSelector(
  getReturnCreationState,
  (state: ReturnCreationState) => state[RETURN_POSITION_FEATURE_KEY].documentNumber
);

export const selectDocumentType: MemoizedSelector<ReturnPositionFeature.ReturnPositionState, ReturnDocumentType> = createSelector(
  getReturnCreationState,
  (state: ReturnCreationState) => state[RETURN_POSITION_FEATURE_KEY].documentType
);

export const selectRouteOrigin: MemoizedSelector<ReturnPositionFeature.ReturnPositionState, string> = createSelector(
  getReturnCreationState,
  (state: ReturnCreationState) => state[RETURN_POSITION_FEATURE_KEY].routeOrigin
);
