import { createAction, props } from "@ngrx/store";
import { ErrorType } from "../../model/ErrorTypes";
import { TransportDamageRequest } from "../../model/transport-damage.model";

export const adviseOfTransportDamage = createAction(
  '[TransportDamage] Advise of Transport Damage',
  props<{ request: TransportDamageRequest }>()
);

export const adviseOfTransportDamageSuccess = createAction(
  '[TransportDamage] Advise of Transport Damage Success'
);

export const adviseOfTransportDamageFailure = createAction(
  '[TransportDamage] Advise of Transport Damage Failure',
  props<{ errorResponse: ErrorType }>()
);

