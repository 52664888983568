import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { B2BUser, Country, GlobalMessageType, TranslationService, UserAddressService } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PartsLinkAccessRequest } from '../../model/parts-links-access.model';
import { PartsLinkAccessService } from '../../services/parts-link-access.service';

const GERMANY_ISOCODE = 'DE';

@Component({
  selector: 'app-parts-link-access-form',
  templateUrl: './parts-link-access-form.component.html',
  styleUrls: ['./parts-link-access-form.component.scss']
})
export class PartsLinkAccessFormComponent implements OnInit {
  partsLinkAccessForm: UntypedFormGroup;
  countries$: Observable<Country[]>;
  titleCodes: string[] = ['MALE', 'FEMALE', 'OTHER'];
  b2bUnitId: string;
  private subscription: Subscription = new Subscription();


  private readonly formProperties = {
    dealerData: new UntypedFormGroup({
      customerNumber: new UntypedFormControl('', Validators.required),
      companyName: new UntypedFormControl('', Validators.required),
      street: new UntypedFormControl('', Validators.required),
      streetnumber: new UntypedFormControl('', Validators.required),
      countryIsoCode: new UntypedFormControl(GERMANY_ISOCODE, Validators.required),
      postalCode: new UntypedFormControl('', Validators.required),
      town: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, CustomFormValidators.emailValidator]),
    }),
    customerData: new UntypedFormGroup({
      titleCode: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
    })
  };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private userAddressService: UserAddressService,
    private globalMessageService: EfaGlobalMessageService,
    private translationService: TranslationService,
    private partsLinkAccessService: PartsLinkAccessService,
    private userAccountFacade: UserAccountFacade
  ) { }

  ngOnInit(): void {
    this.partsLinkAccessForm = this.formBuilder.group(this.formProperties);
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountries();
        }
      }),
    );
    this.subscription.add(this.userAccountFacade.get().subscribe((b2bUser: B2BUser) => {
      this.partsLinkAccessForm.controls.dealerData.get('companyName').patchValue(b2bUser.orgUnit.name);
      this.partsLinkAccessForm.controls.dealerData.get('customerNumber').patchValue(b2bUser.orgUnit.uid);
      this.partsLinkAccessForm.controls.dealerData.get('email').patchValue(b2bUser.email);
    }));
  }

  onSubmit() {
    Object.values(this.partsLinkAccessForm.controls).forEach((nestedForm: UntypedFormGroup | UntypedFormControl) => {
      if (nestedForm instanceof UntypedFormGroup) {
        Object.values(nestedForm.controls).forEach((control: UntypedFormControl) => {
          control.markAsTouched();
        });
      }
      else {
        nestedForm.markAsTouched();
      }

    });
    const partsLinkAccessRequest: PartsLinkAccessRequest = {
      dealerData: {
        customerNumber: this.partsLinkAccessForm.get('dealerData.customerNumber').value,
        companyName: this.partsLinkAccessForm.get('dealerData.companyName').value,
        street: this.partsLinkAccessForm.get('dealerData.street').value,
        streetnumber: this.partsLinkAccessForm.get('dealerData.streetnumber').value,
        countryIsoCode: this.partsLinkAccessForm.get('dealerData.countryIsoCode').value,
        postalCode: this.partsLinkAccessForm.get('dealerData.postalCode').value,
        town: this.partsLinkAccessForm.get('dealerData.town').value,
        email: this.partsLinkAccessForm.get('dealerData.email').value,
      },
      customerData: {
        titleCode: this.partsLinkAccessForm.get('customerData.titleCode').value,
        name: this.partsLinkAccessForm.get('customerData.name').value,
      }
    };

    this.partsLinkAccessService.startTiresWarranty(partsLinkAccessRequest);
    this.subscription.add(
      this.partsLinkAccessService.sucess().subscribe((succes: boolean) => {
        if (succes){
          this.resetFormFields();
          this.showPartsLinkAccessSuccessMessage();
        }
      })
    );

  }

  onReset(): void {
    this.resetFormFields();
  }

  getSelectPlaceholderTextClass(value: string): string {
    return value === '' ? 'placeholder-text' : '';
  }

  private resetFormFields(): void  {
    this.partsLinkAccessForm.reset();
    this.partsLinkAccessForm.controls.dealerData.get('countryIsoCode').patchValue(GERMANY_ISOCODE);
    this.subscription.add(this.userAccountFacade.get().subscribe((b2bUser: B2BUser) => {
      this.partsLinkAccessForm.controls.dealerData.get('companyName').patchValue(b2bUser.orgUnit.name);
      this.partsLinkAccessForm.controls.dealerData.get('customerNumber').patchValue(b2bUser.orgUnit.uid);
      this.partsLinkAccessForm.controls.dealerData.get('email').patchValue(b2bUser.email);
    }));
  }

  private showPartsLinkAccessSuccessMessage(): void  {
      this.globalMessageService.add(
        {
          key: 'partsLinkAccess.globalMessage.startPartsLinkAccessSuccess',
        },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
    }
  }

