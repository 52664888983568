import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EfaUpdateProfileComponent} from './components/efa-update-profile/efa-update-profile.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormErrorsModule, SpinnerModule} from '@spartacus/storefront';
import {AuthGuard, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {UpdateProfileComponentService} from '@spartacus/user/profile/components';
import {EfaUpdateProfileComponentService} from './services/efa-update-profile-component.service';
import {UserProfileFacade} from '@spartacus/user/profile/root';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {personalDetailsTranslationChunksConfig, personalDetailsTranslations} from './translations/personal-details.translations';

@NgModule({
  declarations: [
    EfaUpdateProfileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      i18n: {
        resources: personalDetailsTranslations,
        chunks: personalDetailsTranslationChunksConfig
      },
      cmsComponents: {
        UpdateProfileComponent: {
          component: EfaUpdateProfileComponent,
          guards: [AuthGuard],
          providers: [
            {
              provide: UpdateProfileComponentService,
              useClass: EfaUpdateProfileComponentService,
              deps: [UserProfileFacade, EfaGlobalMessageService],
            },
          ],
        },
      },
    }),
  ]
})
export class PersonalDetailsModule { }
