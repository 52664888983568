import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {InvoiceCreditOverviewActions} from '../actions';
import {InvoicesCreditOverviewResponse} from '../../model/invoices-credits.model';
import {ErrorType} from '@shared/models/ErrorTypes';
import {InvoiceCreditOverviewConnector} from '../../occ/connectors/invoice-credit-overview.connector';
import {Injectable} from '@angular/core';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {GlobalMessageType} from '@spartacus/core';

@Injectable()
export class InvoiceCreditOverviewEffect {
  loadInvoiceOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InvoiceCreditOverviewActions.loadInvoiceCreditOverview),
      concatMap((action) =>
        this.connector.loadInvoiceOverview(action.invoiceRequest).pipe(
          map((invoiceResponse: InvoicesCreditOverviewResponse) => {
            return InvoiceCreditOverviewActions.loadInvoiceCreditOverviewSuccess({ invoiceResponse });
          }),
          catchError((error: ErrorType) => {
            this.globalMessageService.add(
              {
                key: 'invoicesCreditsCustom.globalMessage.loadingInvoicesCreditsOverviewFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return  of(InvoiceCreditOverviewActions.loadInvoiceCreditOverviewFaillure({errorResponse: error}));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: InvoiceCreditOverviewConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {
  }
}
