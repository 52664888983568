<div *ngIf="messages$ | async as messages">
  <div *ngFor="let confMsg of messages[messageType.MSG_TYPE_CONFIRMATION]; let i = index"
    class="alert alert-success {{confMsg?.params && confMsg?.params?.styleClass ?? confMsg.params.styleClass}}"
  >
    <span class="alert-icon">
      <cx-icon [type]="iconTypes.SUCCESS"></cx-icon>
    </span>
    <span>{{ confMsg | cxTranslate }}</span>

    <span *ngIf="confMsg?.params && confMsg?.params.href">&nbsp;</span>
    <a *ngIf="confMsg?.params && confMsg?.params?.href" [href]="confMsg.params.href"
       target="_blank">{{confMsg.params.anchorText}}</a>

    <button *ngIf="!confMsg?.params || confMsg?.params?.hideCloseButton !== true"
            class="close"
            type="button"
            (click)="clear(messageType.MSG_TYPE_CONFIRMATION, i)"
    >
      <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
    </button>
  </div>
  <div *ngFor="let infoMsg of messages[messageType.MSG_TYPE_INFO]; let i = index"
    class="alert alert-info {{infoMsg?.params && infoMsg?.params?.styleClass ?? infoMsg.params.styleClass}}"
  >
    <span class="alert-icon">
      <cx-icon [type]="iconTypes.INFO"></cx-icon>
    </span>
    <span>{{ infoMsg | cxTranslate }}</span>

    <span *ngIf="infoMsg?.params && infoMsg?.params.href">&nbsp;</span>
    <a *ngIf="infoMsg?.params && infoMsg?.params?.href" [href]="infoMsg.params.href"
       target="_blank">{{infoMsg.params.anchorText}}</a>

    <button *ngIf="!infoMsg?.params || infoMsg?.params?.hideCloseButton !== true"
            class="close"
            type="button"
            (click)="clear(messageType.MSG_TYPE_INFO, i)"
    >
      <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
    </button>
  </div>
  <div *ngFor="let infoMsg of messages[messageType.MSG_TYPE_WARNING]; let i = index"
    class="alert alert-warning {{infoMsg?.params && infoMsg?.params?.styleClass ?? infoMsg.params.styleClass}}"
  >
    <span class="alert-icon">
      <cx-icon [type]="iconTypes.WARNING"></cx-icon>
    </span>
    <span>{{ infoMsg | cxTranslate }}</span>

    <span *ngIf="infoMsg?.params && infoMsg?.params?.href">&nbsp;</span>
    <a *ngIf="infoMsg?.params && infoMsg?.params?.href" [href]="infoMsg.params.href"
       target="_blank">{{infoMsg.params.anchorText}}</a>

    <button *ngIf="!infoMsg?.params || infoMsg?.params?.hideCloseButton !== true"
            class="close"
            type="button"
            (click)="clear(messageType.MSG_TYPE_WARNING, i)"
    >
      <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
    </button>

  </div>
  <div *ngFor="let errorMsg of messages[messageType.MSG_TYPE_ERROR]; let i = index"
    class="alert alert-danger {{errorMsg?.params &&  errorMsg?.params?.styleClass ?? errorMsg.params.styleClass}}"
  >
    <span class="alert-icon">
      <cx-icon [type]="iconTypes.ERROR"></cx-icon>
    </span>
    <span>{{ errorMsg | cxTranslate }}</span>

    <span *ngIf="errorMsg?.params && errorMsg.params.href">&nbsp;</span>
    <a *ngIf="errorMsg?.params && errorMsg?.params?.href" [href]="errorMsg.params.href"
       target="_blank">{{errorMsg.params.anchorText}}</a>

    <button *ngIf="!errorMsg?.params || errorMsg?.params?.hideCloseButton !== true"
            class="close"
            type="button"
            (click)="clear(messageType.MSG_TYPE_ERROR, i)"
    >
      <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
    </button>
  </div>
</div>
