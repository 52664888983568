import { createReducer, on } from "@ngrx/store";
import { WarrantyProcessRequest } from "../../model/warranty-process.model";
import { WarrantyProcessActions } from '../actions';

export const WARRANTY_PROCESS_FEATURE_KEY = 'warrantyProcess';

export interface WarrantyProcessState {
  request: WarrantyProcessRequest;
  processing: boolean;
  success: boolean;
}

export const initialState: WarrantyProcessState = {
  request: undefined,
  processing: false,
  success: undefined
}

export const warrantyProcessReducer = createReducer(
  initialState,

  on(WarrantyProcessActions.startWarrantyProcess, (state, action) => ({
    ...state,
    processing: true,
    request: action.request
  })),
  on(WarrantyProcessActions.startWarrantyProcessSuccess, (state) => ({
    ...state,
    processing: false,
    success: true,
    request: undefined
  })),
  on(WarrantyProcessActions.startWarrantyProcessFailure, (state, action) => ({
    ...state,
    processing: false,
    success: false,
    error: action.errorResponse
  }))
);
