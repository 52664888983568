<!-- Item Start Outlet -->
<ng-template [cxOutlet]="CartOutlets.ITEM" [formGroup]="form">
  <!-- Item Description -->
  <td attr.data-label="{{ 'cartItems.item' | cxTranslate }}"
      class="article"
      [ngClass]="{'article-parent' : !isOrderDetails && item.parent}">
    <!-- Item Name -->
    <div *ngIf="item.product.name" class="cx-name">
      <a *ngIf="item.productAvailable; else dummy" class="cx-link"
         [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
         [queryParams]="{ source: pdpCallSource }"
         cxModal="dismiss">{{ item.product.name }}</a>
      <ng-template #dummy>
        <span>{{ item.product.name }}</span>
      </ng-template>
    </div>
    <!-- Item Code -->
    <div *ngIf="item.product.manufacturerAID" class="cx-code article-info">
      {{ item.product.manufacturerAID }}
      <span
        *ngIf="item.product.manufacturer; else displayManufacturerId">&nbsp;/&nbsp;{{ item.product.manufacturer }}</span>
      <ng-template #displayManufacturerId>
        <span
          *ngIf="item.product.manufacturerId">&nbsp;/&nbsp;{{ item.product.manufacturerId }}</span>
      </ng-template>
    </div>
    <!-- Item SubstitutedOemNumber -->
    <div *ngIf="item.substitutedOemNumber && item?.substitutedOemNumber !== item?.product?.manufacturerAID && !compact" class="searched-article-info">
      <span
        class="u-font-bold">{{ 'cartAdministration.cartItems.searchedArticleNumber' | cxTranslate }}
        :&nbsp;</span>
      <span class="substitutedOemNumber">{{ item.substitutedOemNumber }}</span>
      <button
        *ngIf="(item?.substitutedOemNumber !== item?.product?.manufacturerAID)"
        class="info-btn"
        [cxPopover]="substitutInfo"
        [cxPopoverOptions]="{
        placement: 'auto',
        class: 'my-company-popover',
        appendToBody: true,
        displayCloseButton: true
        }">
      </button>
    </div>

    <ng-container *ngIf="compact">
      <div *ngIf="item.basePrice" class="cx-code">
        {{ item.basePrice?.formattedValue }}
      </div>
    </ng-container>

    <!-- Item Details Outlet -->
    <ng-template [cxOutlet]="CartOutlets.ITEM_DETAILS"></ng-template>

    <!-- Variants -->
    <ng-container *ngIf="item.product.baseOptions?.length">
      <div *ngFor="let variant of item.product.baseOptions[0]?.selected?.variantOptionQualifiers"
           class="cx-property">
        <div class="cx-label" *ngIf="variant.name && variant.value">
          {{ variant.name }}: {{ variant.value }}
        </div>
      </div>
    </ng-container>

    <!-- Item Bundle Details Outlet -->
    <ng-template [cxOutlet]="CartOutlets.ITEM_BUNDLE_DETAILS"></ng-template>

    <!-- Promotion -->
    <ng-container *ngIf="appliedProductPromotions$ | async as appliedProductPromotions">
      <cx-promotions [promotions]="appliedProductPromotions"></cx-promotions>
    </ng-container>
  </td>
  <!-- Item StatusDisplay -->
  <ng-container *ngIf="item.statusDisplay">
    <td attr.data-label="{{ 'cartAdministration.cartItems.status' | cxTranslate }}" class="status">

      <div>{{ item.statusDisplay }}</div>
    </td>
  </ng-container>

  <ng-container *ngIf="!compact">
    <!-- Item Quantity -->
    <td attr.data-label="{{ 'cartItems.quantity' | cxTranslate }}" class="quantity">
      <div class="cx-value">
        <app-efa-item-counter [control]="quantityControl"
                              [readonly]="!item.updateable || readonly || options.isSaveForLater || item.parent || fromConfigurator"
                              [ngClass]="{ 'order-detail-items-counter' : isOrderDetails }"
                              [allowZero]="true">
        </app-efa-item-counter>
      </div>
    </td>

    <!-- Availability -->
    <td attr.data-label="{{ 'cartAdministration.cartItems.available' | cxTranslate }}"
        class="available align-center">
      <ng-container *ngFor="let del of item.deliveryItems">
        <div>{{ del.availableQuantity }}</div>
      </ng-container>
    </td>

    <!-- Item delivery date -->
    <td attr.data-label="{{ 'cartAdministration.cartItems.deliveryDate' | cxTranslate }}"
        class="delivery-date align-center">
      <ng-container *ngFor="let del of item.deliveryItems">
        <div *ngIf="del.deliveryDate; else emptyDeliveryDate">{{ del.deliveryDate | date: 'dd.MM.yyyy' }}</div>
        <ng-template #emptyDeliveryDate>
          <div>-</div>
        </ng-template>
      </ng-container>
    </td>

    <!-- Item Warehouse -->
    <td attr.data-label="{{ 'cartAdministration.cartItems.warehouse' | cxTranslate }}"
        class="warehouse align-center">
      <ng-container *ngFor="let del of item.deliveryItems">
        <div *ngIf="del.warehouseName; else emptyWarehouseName">{{ del.warehouseName }}</div>
        <ng-template #emptyWarehouseName>
          <div>-</div>
        </ng-template>
      </ng-container>
    </td>

    <!-- Item Price -->
    <ng-container *ngIf="item.basePrice">
      <td *ngIf="!fromConfigurator; else fromConfiguratorUnitPrice" attr.data-label="{{ 'cartItems.itemPriceRrp' | cxTranslate }}"
      class="price align-center">
        <div *ngIf="item.basePrice" class="cx-value">
          {{ item.basePrice?.formattedValue }}
          <sup *ngIf="item.exchangeTaxRequired" class="exchange-tax-required">*</sup>
        </div>
      </td>

      <ng-template #fromConfiguratorUnitPrice>
        <td attr.data-label="{{ 'cartItems.itemPrice' | cxTranslate }}"
          class="price align-center">
          <div *ngIf="item.basePrice" class="cx-value">
            {{ item.basePrice?.formattedValue }}
            <sup *ngIf="item.exchangeTaxRequired" class="exchange-tax-required">*</sup>
          </div>
        </td>
      </ng-template>
    </ng-container>


    <!-- Total -->
    <ng-container *ngIf="options.isSaveForLater; else total">
      <td attr.data-label="{{ 'saveForLaterItems.stock' | cxTranslate }}"
          class="total align-center">
        <div *ngIf="item.product?.stock?.stockLevel >= 0; else forceInstock"
             class="cx-value">
          {{ item.product.stock.stockLevel }}
        </div>
        <ng-template #forceInstock>
          <div class="cx-value">
            {{ 'saveForLaterItems.forceInStock' | cxTranslate }}
            <sup *ngIf="item.exchangeTaxRequired" class="exchange-tax-required">*</sup>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <ng-template #total>
      <ng-container *ngIf="displayCustomerPrices$ | async; else showListPrice">
        <td *ngIf="item.customerTotalPrice" attr.data-label="{{ 'cartItems.total' | cxTranslate }}"
            class="price align-center">
          <div *ngIf="item.customerTotalPrice" class="cx-value">
            {{ item.customerTotalPrice?.formattedValue }}
            <sup *ngIf="item.exchangeTaxRequired" class="exchange-tax-required">*</sup>
          </div>
        </td>
      </ng-container>

      <ng-template #showListPrice>
        <td *ngIf="item.totalPrice" attr.data-label="{{ 'cartItems.total' | cxTranslate }}"
            class="price align-center">
          <div *ngIf="item.totalPrice" class="cx-value">
            {{ item.totalPrice?.formattedValue }}
            <sup *ngIf="item.exchangeTaxRequired" class="exchange-tax-required">*</sup>
          </div>
        </td>
      </ng-template>
    </ng-template>

    <!-- Actions -->

    <td attr.data-label="{{'cartAdministration.cartItems.commission' | cxTranslate }}">
      <cx-icon *ngIf="!readonly && !consignmentInfoEditable" type="PENCIL"
               ngbTooltip="{{ 'cartAdministration.cartItems.actions.editCommission' | cxTranslate }}"
               placement="top"
               (click)="toggleConsignmentInfo()"
               class="u-pointer"></cx-icon>

      <cx-icon *ngIf="!readonly && consignmentInfoEditable" type="ARROW_UP"
               (click)="toggleConsignmentInfo()"
               ngbTooltip="{{ 'cartAdministration.cartItems.actions.hideCommission' | cxTranslate }}"
               class="u-pointer"></cx-icon>
    </td>

    <td *ngIf="!fromConfigurator || isOrderDetails"
        attr.data-label="{{ 'cartAdministration.cartItems.wishlist' | cxTranslate }}">
      <app-efa-cart-item-add-to-wish-list [product]="item.product"
                                          [orderInfoRefId]="item.orderInfoRefId"></app-efa-cart-item-add-to-wish-list>
    </td>

    <td *ngIf="!readonly && !item.parent && item.updateable && !fromConfigurator"
        attr.data-label="{{ 'cartAdministration.cartItems.actions.delete' | cxTranslate }}"
        class="action">

      <cx-icon type="CLEAR"
               (click)="removeItem()"
               ngbTooltip="{{ 'cartAdministration.cartItems.actions.delete' | cxTranslate }}"
               class="u-pointer"></cx-icon>
    </td>
  </ng-container>
</ng-template>
<ng-template #substitutInfo>
  <div class="popover-details">
    <div class="property">
      {{ 'cartAdministration.cartItems.substitutInfoMessage' | cxTranslate }}
    </div>
  </div>
</ng-template>

