import {createFeatureSelector, createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import * as productPricesFeature from './product-prices.reducer';
import {adapter, ProductPriceState, StateWithPrices} from './product-prices.reducer';
import {Dictionary} from '@ngrx/entity';
import {PriceResponseItem} from '../model/product-prices.model';

export const selectProductPriceState: MemoizedSelector<StateWithPrices, ProductPriceState> = createFeatureSelector<ProductPriceState>(
  productPricesFeature.productPricesFeatureKey
);

const {selectEntities} = adapter.getSelectors();

export const selectIsLoading: MemoizedSelector<StateWithPrices, boolean> = createSelector(
  selectProductPriceState,
  (state: ProductPriceState) => state.isLoading
);

export const selectProductPriceEntities: MemoizedSelector<StateWithPrices, Dictionary<PriceResponseItem>> = createSelector(
  selectProductPriceState,
  selectEntities
);

export const selectProductPrice: MemoizedSelectorWithProps<StateWithPrices, string, PriceResponseItem> = createSelector(
  selectProductPriceEntities,
  (state, props) => state[props]);

export const selectProductPrices: MemoizedSelectorWithProps<StateWithPrices, string[], PriceResponseItem[]> = createSelector(
  selectProductPriceEntities,
  (state, props) => {
    const prices = [];
    {
      props.forEach(p => {
          if (state[p] !== undefined) {
            prices.push(state[p]);
          }
        }
      );
    }
    return prices;
  });

export const selectAllProductPrices: MemoizedSelectorWithProps<StateWithPrices, boolean, PriceResponseItem[]> = createSelector(
  selectProductPriceEntities,
  (state, props) => {
    const prices = [];

    // tslint:disable-next-line:forin
    for (const key in state) {
      prices.push(state[key]);
    }
    return prices;
  });
