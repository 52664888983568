import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { WarrantyProcessAdapter } from './adapters/warranty-process.adapter';
import { OccWarrantyProcessAdapter } from './adapters/impl/occ-warranty-process.adapter';



@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: WarrantyProcessAdapter,
      useClass: OccWarrantyProcessAdapter,
    }
  ],
})
export class OccModule { }
