import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { ManufacturersSelectionComponent } from './components/manufacturers-selection/manufacturers-selection.component';

export const defaultManufacturersSelectionLayoutConfig: LayoutConfig = {
   launch: {
      MANUFACTURERS_SELECTION: {
         inlineRoot: true,
         component: ManufacturersSelectionComponent,
         dialogType: DIALOG_TYPE.DIALOG,
      },
   },
};