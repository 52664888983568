import {TranslationChunksConfig, TranslationResources} from '@spartacus/core';
import {en} from './en/index';
import {de} from './de/index';

export const productSearchTranslations: TranslationResources = {
  en,
  de
};

// expose all translation chunk mapping for savedCart feature
export const productSearchTranslationChunksConfig: TranslationChunksConfig = {
  productSearch: [
    'productSearch'
  ],
};
