import { Component, Inject, OnInit } from '@angular/core';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  Config,
  PaginationModel,
  RoutingService,
  SortModel,
  TranslationService
} from '@spartacus/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderHistoryList } from '@spartacus/order';
import { ICON_TYPE } from '../../model/order-history-icon.model';
import { OrderOverviewFilter, OrderOverviewRequest } from '../../model/order-history.model';
import { OrderOverviewUiService } from '../../services/order-overview-ui.service';
import { OrderOverviewService } from '../../services/order-overview.service';

@Component({
  selector: 'app-efa-order-history',
  templateUrl: './efa-order-history.component.html',
})
export class EfaOrderHistoryComponent implements OnInit {

  constructor(
    protected routing: RoutingService,
    protected translation: TranslationService,
    private orderOverviewService: OrderOverviewService,
    private orderOverviewUiService: OrderOverviewUiService,
    @Inject(Config) public config: any,
  ) {
  }

  private sortCode = '';
  private returningFromDetails = false;
  private currentPage = 0;
  private subscription: Subscription = new Subscription();

  iconTypes = ICON_TYPE;
  isLoading$: Observable<boolean>;
  orders$: Observable<OrderHistoryList>;
  orderOverviewFilter: OrderOverviewFilter = {
    orderNumber: '',
    orderStateId: '',
    receiverAddressId: '',
    dateFrom: undefined,
    dateTo: undefined,
    consignmentInfo: ''
  };

  private static formatFilterDate(date: NgbDateStruct): string {
    return date ? date.year + '-' + date.month + '-' + date.day : '';
  }

  ngOnInit(): void {
    this.isLoading$ = this.orderOverviewService.isLoading();
    this.orders$ = this.orderOverviewService.getOrderHistoryList();
    this.subscription.add(combineLatest([this.orderOverviewUiService.selectedSort(), this.orderOverviewUiService.selectedPagination(), this.orderOverviewUiService.selectedFilterEntity(), this.orderOverviewUiService.returningFromDetails()])
    .subscribe(([selectedSort, selectedPagination, filter, returningFromDetails]: [SortModel, PaginationModel, OrderOverviewFilter, boolean]) => {
      this.returningFromDetails = returningFromDetails;
      if (selectedSort) {
        this.sortCode = selectedSort.code;
      }

      if (selectedPagination) {
        this.currentPage = selectedPagination.currentPage;
      } else {
        this.currentPage = 0;
      }
    }));
  }

  setOrderOverviewFilter(filter: OrderOverviewFilter): void {
    this.orderOverviewFilter = filter;
    // make sure first page of new result is displayed
    if (!this.returningFromDetails) {
      this.pageChange(0);
    }
    else {
      this.fetchOrders();
      this.orderOverviewUiService.setReturningFromDetails(false);
    }
  }

  changeSortCode(sortCode: string): void {
    this.sortCode = sortCode;
    this.orderOverviewUiService.setSelectedSort({
      code: sortCode,
      selected: true
    });
    this.pageChange(0);
  }

  pageChange(page: number): void {
    this.currentPage = page;
    this.orderOverviewUiService.setSelectedPagination({
      currentPage: page,
      pageSize: this.config.pageSize.orderHistoryOverview,
    });
    this.fetchOrders();
  }

  getSortLabels(): Observable<{ byDate: string; byOrderNumber: string }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.orderNumber'),
    ]).pipe(
      map(([textByDate, textByOrderNumber]) => {
        return {
          byDate: textByDate,
          byOrderNumber: textByOrderNumber,
        };
      })
    );
  }

  private fetchOrders(): void {
    const orderOverviewRequest: OrderOverviewRequest = {
      orderNumber: this.orderOverviewFilter.orderNumber,
      statusCode: this.orderOverviewFilter.orderStateId,
      receiverId: this.orderOverviewFilter.receiverAddressId,
      dateTo: EfaOrderHistoryComponent.formatFilterDate(this.orderOverviewFilter.dateTo),
      dateFrom: EfaOrderHistoryComponent.formatFilterDate(this.orderOverviewFilter.dateFrom),
      consignmentInfo: this.orderOverviewFilter.consignmentInfo,
      sort: {
        code: this.sortCode,
        selected: true
      },
      pagination: {
        currentPage: this.currentPage,
        pageSize: this.config.pageSize.orderHistoryOverview,
        sort: this.sortCode,
      }
    };
    this.orderOverviewService.loadOverview(orderOverviewRequest);
  }

}
