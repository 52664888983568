import { createAction, props } from '@ngrx/store';
import { ErrorType } from '../../model/ErrorTypes';

import { TiresWarrantyRequest } from '../../model/tires-warranty.model';

export const startTiresWarranty = createAction(
  '[TiresWarranty] Start Tires Warranty',
  props<{request: TiresWarrantyRequest}>()
);

export const tiresWarrantySuccess = createAction(
  '[TiresWarranty] Start Tires Warranty Success'
);

export const TiresWarrantyFailer = createAction(
  '[TiresWarranty] Start Tires Warranty Failer',
  props<{errorResponse: ErrorType}>()
);
