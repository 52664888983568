import { createReducer, on } from '@ngrx/store';

import { CheckoutOrderUiActions } from '../actions';

export const CHECKOUT_ORDER_UI_FEATURE_KEY = 'checkoutOrderUI';

export interface CheckoutOrderUiState {
  isValidForm?: boolean;
  placeOrderTriggered?: boolean;
}

export const initialState: CheckoutOrderUiState = {
  isValidForm: false,
  placeOrderTriggered: false
};

export const checkoutOrderUiReducer = createReducer(
  initialState,

  on(CheckoutOrderUiActions.setIsValidForm, (state, action) => ({
    ...state,
    isValidForm: action.isValidForm
  })),
  on(CheckoutOrderUiActions.setTriggerPlaceOrder, (state, action) => ({
    ...state,
    placeOrderTriggered: action.trigger
  })),
);
