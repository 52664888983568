import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PartsLinkAccessRequest } from "../../model/parts-links-access.model";
import { PartsLinkAccessAdapter } from "../adapters/parts-link-access.adapter";

@Injectable({
  providedIn: 'root'
})

export class PartsLinkAccessConnector {
  constructor(protected adapter: PartsLinkAccessAdapter) {}

  startPartsLinkAccess (request : PartsLinkAccessRequest): Observable<void> {
    return this.adapter.startPartsLinkAccess(request);
  }
}
