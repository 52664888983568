  <div class="row" [ngClass]="getStyle()">
    <div class="col-12 col-md-4 col-lg-4">
      <div ngbDropdown class="dropdown">
        <button
          ngbDropdownToggle
          class="btn btn-link dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ 'cartAdministration.cart.actions' | cxTranslate }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownMenuButton" >
          <app-cart-export *ngIf="hasEntries"></app-cart-export>
          <app-cart-import-action *ngIf="internal"></app-cart-import-action>
          <app-cart-import-microcat-action *ngIf="internal"></app-cart-import-microcat-action>
          <app-cart-delete *ngIf="hasEntries"></app-cart-delete>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-8 col-lg-6" *ngIf="internal">
      <app-cart-calculate-mode [skipAutomaticCalculation]="skipAutomaticCalculation"></app-cart-calculate-mode>
    </div>
  </div>
  
