import { createSelector, MemoizedSelector } from '@ngrx/store'
import { getTiresWarrantyState, TiresWarrantyState } from '../reducers'
import * as TiresWarrantyFeature from '../reducers/tires-warranty.reducer'

export const selectProcessing : MemoizedSelector<TiresWarrantyFeature.TiresWarrantyState,boolean> = createSelector(
  getTiresWarrantyState,
(state: TiresWarrantyState) => state[TiresWarrantyFeature.TIRES_WARRANTY_FEATURE_KEY].proccessing
)

export const selectSuccess : MemoizedSelector<TiresWarrantyFeature.TiresWarrantyState,boolean> = createSelector(
  getTiresWarrantyState,
  (state: TiresWarrantyState)=> state[TiresWarrantyFeature.TIRES_WARRANTY_FEATURE_KEY].success
);
