import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@spartacus/core';
import { UserChangePasswordFormService } from '@spartacus/organization/administration/components';
import { CustomFormValidators } from '@spartacus/storefront';

@Injectable({
  providedIn: 'root'
})
export class EfaUserChangePasswordFormService extends UserChangePasswordFormService{

  constructor() { 
    super();
  }

  protected build() {
    super.build();
    this.form.setControl(
      'email',
      new UntypedFormControl('', [
        Validators.required,
        CustomFormValidators.emailValidator,
      ])
    );
    this.form.setControl(
      'orgUnit',
      new UntypedFormGroup({
        uid: new UntypedFormControl(undefined, Validators.required),
      })
    );
  }

  getForm(item?: User): UntypedFormGroup {
    this.form = null;
    return super.getForm(item);
  }
}
