import { Component, OnInit } from '@angular/core';
import {PermissionCheckService} from '../../service/permission-check.service';

@Component({
  selector: 'app-profile-test',
  templateUrl: './profile-test.component.html'
})
export class ProfileTestComponent implements OnInit {

  constructor(protected permissionCheckService: PermissionCheckService) { }

  ngOnInit(): void {

    this.permissionCheckService.isAllowed('viewPrices').subscribe(console.log);
    this.permissionCheckService.isAllowed('manageUsers').subscribe(console.log);
    this.permissionCheckService.isAllowed('MMM').subscribe(console.log);
  }

}
