import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GlobalMessageType} from '@spartacus/core';
import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {TransportDamageConnector} from '../../occ/connectors/transport-damage.connector';
import {TransportDamageActions} from '../actions';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class TransportDamageEffect {

  adviseOfTransportDamage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransportDamageActions.adviseOfTransportDamage),
      concatMap((action) =>
        this.connector.adviseOfTransportDamage(action.request).pipe(
          map(() => {
            return TransportDamageActions.adviseOfTransportDamageSuccess();
          }),
          catchError((error) => {
            this.globalMessageService.add(
              {
                key: 'transportDamage.globalMessage.adviseOfTransportDamageFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(TransportDamageActions.adviseOfTransportDamageFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: TransportDamageConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {

  }
}
