import { Price } from '@spartacus/core';

export enum TransportDamageImageType {
    PACKAGING = 'PACKAGING',
    DAMAGEDPARTOFPACKAGING = 'DAMAGEDPARTOFPACKAGING',
    ARTICLE = 'ARTICLE',
    DAMAGEDPARTOFARTICLE = 'DAMAGEDPARTOFARTICLE',
    SHIPPINGLABEL = 'SHIPPINGLABEL',
    ARTICLENUMBER = 'ARTICLENUMBER',
    ADDITIONAL = 'ADDITIONAL'
  }

export interface TransportDamageImageData {
    encodedContent: string;
    fileName: string;
    type: TransportDamageImageType;
  }

export interface TransportDamageRequest {
    orderNumber:string;
    deliveryNoteNumber: string;
    oemNumber: string;
    descriptionOfDamage: string;
    additionalHints: string;
    repairPossible: boolean;
    repairCosts: Price;
    replacementWanted: boolean;
    images: TransportDamageImageData[];
  }

