<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <label>
        <span class="label-content">{{
            'updatePasswordForm.oldPassword.label' | cxTranslate
            }}*</span>
        <input class="form-control mb-2" type="password" name="oldPassword" placeholder="{{
        'updatePasswordForm.oldPassword.placeholder' | cxTranslate
      }}" formControlName="oldPassword" />
        <cx-form-errors [control]="form.get('oldPassword')"></cx-form-errors>
    </label>

    <label>
        <span class="label-content">{{
            'updatePasswordForm.newPassword.label' | cxTranslate
            }}*</span>
        <input class="form-control mb-2" type="password" name="newPassword" placeholder="{{
        'updatePasswordForm.newPassword.placeholder' | cxTranslate
      }}" formControlName="newPassword" />
        <cx-form-errors [control]="form.get('newPassword')"></cx-form-errors>
    </label>
  <span class="label-content d-flex mb-3">
    <span> <img src="../../../../../../assets/icons/infoCircleFilled.svg"></span>
    <!-- <cx-icon [type]="iconTypes.INFOCIRCLEFILLED"></cx-icon> -->
    <span class="ml-1"> {{'updatePasswordForm.passwordMinRequirements' | cxTranslate}}</span>

  </span>
    <label>
        <span class="label-content">
          {{'updatePasswordForm.confirmPassword.label' | cxTranslate}}*
        </span>

        <input class="form-control mb-4" type="password" name="newPasswordConfirm" placeholder="{{
        'updatePasswordForm.confirmPassword.placeholder' | cxTranslate
      }}" formControlName="newPasswordConfirm" />
        <cx-form-errors [control]="form.get('newPasswordConfirm')"></cx-form-errors>
    </label>

    <a class="btn btn-block btn-secondary" [routerLink]="{ cxRoute: 'home' } | cxUrl">
        {{ 'updatePasswordForm.action.cancel' | cxTranslate }}
    </a>

    <button class="btn btn-block btn-primary" [disabled]="form.disabled">
        {{ 'updatePasswordForm.action.save' | cxTranslate }}
    </button>
    <p style="font-size: 12px;font-weight: 400;" class="mt-4 mb-4">{{ 'updatePasswordForm.formMandatoryFields' | cxTranslate }}</p>
</form>
