import {DatePipe, FormatWidth, getLocaleDateFormat} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Config, GlobalMessageType, LanguageService, PaginationModel, RoutingService, SortModel, TranslationService} from '@spartacus/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ICON_TYPE} from '../../model/shipping-backlog-enum.model';
import {
  ShippingBacklogItem,
  ShippingBacklogOverviewFilter,
  ShippingBacklogOverviewRequest,
  ShippingBacklogOverviewResponse,
} from '../../model/shipping-backlog.model';
import {ShippingBacklogOverviewService} from '../../services/shipping-backlog-overview.service';
import {ShippingBacklogSelectedItemsService} from '../../services/shipping-backlog-selected-items.service';
import {ShippingBacklogUiService} from '../../services/shipping-backlog-ui.service';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

const DATE_FORMAT = 'yyyy-MM-dd';

@Component({
  selector: 'app-shipping-backlog-overview',
  templateUrl: './shipping-backlog-overview.component.html',
})
export class ShippingBacklogOverviewComponent implements OnInit {
  shippingBacklogItems$: Observable<ShippingBacklogOverviewResponse>;
  isLoading$: Observable<boolean>;
  iconTypes = ICON_TYPE;
  shippingBacklogOverviewFilter: ShippingBacklogOverviewFilter = {
    oemNumber: '',
    orderNumber: '',
    consignmentInfo: '',
    dateFrom: undefined,
    dateTo: undefined,
  };
  isAllShippingBacklogItemsSelected = false;
  dateFormat$: Observable<string>;

  private sortCode = 'orderNumber';
  private currentPage = 0;
  private pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.config.pageSize.shippingBacklogOverview,
    sort: 'orderNumber',
  };
  private sort: SortModel = {
    code: 'orderNumber',
    selected: true,
  };
  private selectedShippingBacklogItems: ShippingBacklogItem[] = [];
  private returningFromDetails = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private shippingBacklogOverviewService: ShippingBacklogOverviewService,
    private shippingBacklogSelectedItemsService: ShippingBacklogSelectedItemsService,
    private shippingBacklogUiService: ShippingBacklogUiService,
    private globalMessageService: EfaGlobalMessageService,
    private datePipe: DatePipe,
    private routingService: RoutingService,
    private translation: TranslationService,
    private languageService: LanguageService,
    @Inject(Config) private config: any
  ) {
  }

  ngOnInit(): void {
    this.isLoading$ = this.shippingBacklogOverviewService.isLoading();
    this.shippingBacklogItems$ =
      this.shippingBacklogOverviewService.getResponse();
    this.dateFormat$ = this.languageService.getActive().pipe(
      map((language: string) => getLocaleDateFormat(language, FormatWidth.Short).replace(new RegExp('y+'), 'yyyy'))
    );
    this.subscription.add(combineLatest(
      [this.shippingBacklogUiService.selectedSort(),
        this.shippingBacklogUiService.selectedPagination(),
        this.shippingBacklogUiService.selectedFilterEntity(),
        this.shippingBacklogUiService.returningFromDetails()])
    .subscribe(([selectedSort, selectedPagination, _filter, returningFromDetails]:
                  [SortModel, PaginationModel, ShippingBacklogOverviewFilter, boolean]) => {
      this.returningFromDetails = returningFromDetails;
      if (selectedSort) {
        this.sortCode = selectedSort.code;
      }

      if (selectedPagination) {
        this.currentPage = selectedPagination.currentPage;
      } else {
        this.currentPage = 0;
      }
    }));
  }

  setShippingBacklogOverviewFilter(
    overviewFilter: ShippingBacklogOverviewFilter
  ): void {
    this.shippingBacklogOverviewFilter = overviewFilter;
    // make sure first page of new result is displayed
    if (!this.returningFromDetails) {
      this.pageChange(0);
    } else {
      this.fetchShippingBacklogItems();
      this.shippingBacklogUiService.setReturningFromDetails(false);
    }
  }

  changeSortCode(sortCode: string): void {
    this.sortCode = sortCode;
    this.fetchShippingBacklogItems();
    this.shippingBacklogUiService.setSelectedSort({
      code: sortCode,
      selected: true,
    });
    this.pageChange(0);
  }

  pageChange(page: number): void {
    this.currentPage = page;
    this.shippingBacklogUiService.setSelectedPagination({
      currentPage: page,
      pageSize: this.config.pageSize.shippingBacklogOverview,
    });
    this.fetchShippingBacklogItems();
  }

  onAllSelectedChange(shippingBacklogItems: ShippingBacklogItem[]): void {
    this.selectedShippingBacklogItems.splice(
      0,
      this.selectedShippingBacklogItems.length
    );
    this.isAllShippingBacklogItemsSelected =
      !this.isAllShippingBacklogItemsSelected;
    if (this.isAllShippingBacklogItemsSelected) {
      shippingBacklogItems.forEach((shippingBacklogItem: ShippingBacklogItem) =>
        this.selectedShippingBacklogItems.push(shippingBacklogItem)
      );
    }
  }

  isItemSelected(shippingBacklogItem: ShippingBacklogItem): boolean {
    const foundSelectedShippingBacklogItem: ShippingBacklogItem =
      this.selectedShippingBacklogItems.find(
        (selectedShippingBacklogItem: ShippingBacklogItem) =>
          selectedShippingBacklogItem.orderNumber === shippingBacklogItem.orderNumber && selectedShippingBacklogItem.orderPositionNumber ===
          shippingBacklogItem.orderPositionNumber
      );
    return foundSelectedShippingBacklogItem != null;
  }

  onItemSelectedChange(shippingBacklogItem: ShippingBacklogItem): void {
    const shippingBacklogItemIndex: number =
      this.selectedShippingBacklogItems.indexOf(shippingBacklogItem);
    if (shippingBacklogItemIndex !== -1) {
      this.isAllShippingBacklogItemsSelected = false;
      this.selectedShippingBacklogItems.splice(shippingBacklogItemIndex, 1);
    } else {
      this.selectedShippingBacklogItems.push(shippingBacklogItem);
    }
  }

  private fetchShippingBacklogItems(): void {
    this.selectedShippingBacklogItems.splice(0, this.selectedShippingBacklogItems.length);
    this.isAllShippingBacklogItemsSelected = false;
    let dateStruct: NgbDateStruct = this.shippingBacklogOverviewFilter.dateTo;
    const dateTo: Date = dateStruct
      ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day)
      : undefined;
    dateStruct = this.shippingBacklogOverviewFilter.dateFrom;
    const dateFrom: Date = dateStruct
      ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day)
      : undefined;
    const shippingBacklogOverviewRequest: ShippingBacklogOverviewRequest = {
      orderNumber: this.shippingBacklogOverviewFilter.orderNumber,
      oemNumber: this.shippingBacklogOverviewFilter.oemNumber,
      consignmentInfo: this.shippingBacklogOverviewFilter.consignmentInfo,
      dateTo: this.datePipe.transform(dateTo, DATE_FORMAT),
      dateFrom: this.datePipe.transform(dateFrom, DATE_FORMAT),
      sort: {
        code: this.sortCode,
        selected: true,
      },
      pagination: {
        currentPage: this.currentPage,
        pageSize: this.config.pageSize.shippingBacklogOverview,
        sort: this.sortCode,
      },
    };
    this.shippingBacklogOverviewService.loadOverview(
      shippingBacklogOverviewRequest
    );
  }

  getSortLabels(): Observable<{ byDate: string; byOrderNumber: string }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.orderNumber'),
    ]).pipe(
      map(([textByDate, textByOrderNumber]) => {
        return {
          byDate: textByDate,
          byOrderNumber: textByOrderNumber,
        };
      })
    );
  }

  routeToShippingCancellation(): void {
    if (this.selectedShippingBacklogItems.length === 0) {
      this.globalMessageService.add(
        {key: 'shippingBacklog.globalMessage.noSelectedShippingBacklogItems'},
        GlobalMessageType.MSG_TYPE_ERROR
      );
      return;
    }
    this.shippingBacklogSelectedItemsService.saveSelectedItems(
      this.selectedShippingBacklogItems
    );
    this.routingService.go({cxRoute: 'shippingBacklogCancellation'});
  }
}
