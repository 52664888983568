import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductPricesReponse} from '../model/product-prices.model';
import {ProductPricesAdapter} from './product-prices.adapter';
import {OrderType} from '../../cart-administration/model/cart-administration.model';

@Injectable({
  providedIn: 'root',
})
export class ProductPricesConnector {
  constructor(protected adapter: ProductPricesAdapter) {
  }

  retrievePrices(products: string[], orderType: OrderType, userId: string, returnDeliveryTime: boolean): Observable<ProductPricesReponse> {
    return this.adapter.retrievePrices(products, orderType, userId, returnDeliveryTime);
  }
}
