<div class="row mb-4">
  <div class="manufacturer-title">
    <p class="lead text-center">
      {{ 'defaultManufacturers.globalMessage.defaultManufacturersNotice' | cxTranslate }}
    </p>
  </div>
</div>
<div class="cx-spinner" *ngIf="isLoading$ | async">
  <cx-spinner></cx-spinner>
</div>
<div class="container"
     *ngIf="finalVisibleManufacturers && currentLanguage$ | async as currentLanguage">

  <div class="row manufactures-box">
    <label class="header-actions">
      <div class="form-check mb-0">
        <input class="form-check-input" type="checkbox" (change)="isAllSelected($event)"
               [checked]="isAllManufacturerSelected"/>
        <span
          class="header-actions-text">{{ 'cartAdministration.modalDialog.selectDeselectAll' | cxTranslate }}</span>
      </div>
    </label>
    <div class="col-12 manufactures-group-box">
      <div class="row d-flex justify-space-around">
        <div class="col-{{ 12 / visibleManufacturerGroups.length }} manufacturer-group-tab"
             *ngFor="let manufacturerGroup of visibleManufacturerGroups | orderBy: 'code'">
          <btn type="button" class="btn btn-primary btn-block btn-tab"
               [ngClass]="{ 'btn-tab-active' : manufacturerGroup.code === selectedManufacturerGroup.code }"
               (click)="setSelectedManufacturerGroup(manufacturerGroup)">
            {{ manufacturerGroup.name | localizeTo : currentLanguage }}
          </btn>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let item of finalVisibleManufacturers | orderBy: 'name'">
      <div class="col-md-6 col-lg-4"
           *ngIf="item.manufacturerGroup?.code === selectedManufacturerGroup.code">
        <label>
          <div class="form-check check-item">
            <input type="checkbox" class="form-check-input" [(ngModel)]="item.isChecked"
                   [value]="item.id"
                   (change)="changeSelection($event)"/>
            <span class="check-item-text">{{ item.name }}</span>
          </div>
        </label>
      </div>
    </ng-container>
  </div>
</div>
<div class="row">
  <div class="col-md-12 manufacturer-btn-box">
    <button class="btn w-25 btn-primary " (click)="submitData()" type="submit">
      {{ "defaultManufacturers.saveButton" | cxTranslate }}
    </button>
  </div>
</div>
