<button
  *ngIf="current$ | async as item"
  class="button active"
  [disabled]="
    isDisabled(item) ||
    ((isInEditMode$ | async) && item.active && disabled !== true)
  "
  (click)="toggle(item)"
>
  <!-- {{ 'organization.' + (item.active ? 'disable' : 'enable') | cxTranslate }} -->

<ng-container *ngIf="item.active; else enable">
  <cx-icon
  class="u-pointer"
  [type]="iconTypes.BLACK_ICON_DELETE ">
  </cx-icon>
</ng-container>

<ng-template #enable>
  {{ 'organization.enable' | cxTranslate }}
</ng-template>
</button>
