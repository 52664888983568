import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as WarrantyProcessReducers from './warranty-process.reducer';

export const WARRANTY_PROCESS_INDEX_FEATURE_KEY = 'warrantyProcess';

export interface WarrantyProcessState {
  [WarrantyProcessReducers.WARRANTY_PROCESS_FEATURE_KEY]: WarrantyProcessReducers.WarrantyProcessState;
}

export const reducers: ActionReducerMap<WarrantyProcessState> = {
  [WarrantyProcessReducers.WARRANTY_PROCESS_FEATURE_KEY]: WarrantyProcessReducers.warrantyProcessReducer,
};

export const getWarrantyProcessState = createFeatureSelector<WarrantyProcessState>(WARRANTY_PROCESS_INDEX_FEATURE_KEY);