<div class="col-12 col-md-2">
    <a [routerLink]="
        {
          cxRoute: backRoute,
          params: { code: orderCode }
        } | cxUrl
      " class="btn btn-block btn-secondary">
        {{ 'common.back' | cxTranslate }}
    </a>
</div>
<div class="col-12 col-md-5 col-cancel-btn">
    <button *ngIf="forwardRoute" class="btn btn-block btn-primary" (click)="continue($event)">
        {{ 'orderCancellation.cancellationAndReturn.cancelSelectedItems' | cxTranslate }}
    </button>

    <button *ngIf="!forwardRoute" class="btn btn-block btn-primary" type="submit">
        {{ 'orderDetails.cancellationAndReturn.submit' | cxTranslate }}
    </button>
</div>