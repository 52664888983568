<ng-container *ngIf="{
    isLoading: isLoading$ | async,
    order: order$ | async
  } as type">

  <ng-container *ngIf="type.order && !type.isLoading; else loading">
    <app-efa-order-overview [order]="type.order"></app-efa-order-overview>
  </ng-container>

  <ng-template #loading>
    <ng-container *ngIf="type.isLoading">
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
    </ng-container>
  </ng-template>
</ng-container>
