<ng-template #details>
  <div class="popover-details">
    <div class="property">
      <label>{{ 'orgUserGroup.name' | cxTranslate }}</label>
      <a
        class="value"
        [routerLink]="
          {
            cxRoute: 'orgUserGroupDetails',
            params: model
          } | cxUrl
        "
      >{{ model.name }}</a
      >
    </div>

    <div class="property">
      <label>{{ 'orgUserGroup.roles' | cxTranslate }}</label>
      <ng-container *ngFor="let role of model.roles">
      <span class="value">
        {{role}}
      </span><br>
      </ng-container>
    </div>
  </div>
</ng-template>

<button
  class="button text"
>
  {{ model?.name }}
</button>
<button
      class="info-btn"
      [cxPopover]="details"
      [cxPopoverOptions]="{
        placement: 'auto',
        class: 'my-company-popover',
        appendToBody: true,
        displayCloseButton: true
      }"
      >
</button>

