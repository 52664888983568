import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index-de';
import { en } from './en/index-en';

export const categoryInformationTranslations = {
  de,
  en
};

// expose all translation chunk mapping for categoryInformation feature
export const categoryInformationTranslationChunksConfig: TranslationChunksConfig = {
  categoryInformation: [
    'categoryInformation',
  ],
};
