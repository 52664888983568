import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConverterService, OccEndpointsService, Product} from '@spartacus/core';
import {PRODUCT_PRICES_SERIALIZER} from '../product-prices.module';
import {ProductPriceRequest, ProductPricesReponse} from '../model/product-prices.model';
import {Observable} from 'rxjs';
import {OrderType} from '../../cart-administration/model/cart-administration.model';

@Injectable({
  providedIn: 'root',
})

export class ProductPricesAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
  }

  retrievePrices(products: string[], orderType: OrderType, userId: string, returnDeliveryTime: boolean): Observable<ProductPricesReponse> {
    const request: ProductPriceRequest = this.converter.convert(products, PRODUCT_PRICES_SERIALIZER);
    request.orderTypeId = orderType !== undefined ? orderType.id : 'ZTA';
    const url = this.occEndpoints.buildUrl('productPrices',
      {
        urlParams:
          {userId},
        queryParams:
          {returnDeliveryTime}
      });
    return this.http.post<ProductPricesReponse>(url, request);
  }
}
