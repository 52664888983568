import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as ReturnReasonFeature from '../reducers/return-reason.reducer';
import { RETURN_REASON_FEATURE_KEY } from '../reducers/return-reason.reducer';
import { getReturnCreationState, ReturnCreationState } from '../reducers';
import { ReturnReasonResponse } from '../../model/return-creation.model';

export const selectLoading: MemoizedSelector<ReturnReasonFeature.ReturnReasonState, boolean> = createSelector(
  getReturnCreationState,
  (state: ReturnCreationState) => state[RETURN_REASON_FEATURE_KEY].loading
);

export const selectResponse: MemoizedSelector<ReturnReasonFeature.ReturnReasonState, ReturnReasonResponse> = createSelector(
  getReturnCreationState,
  (state: ReturnCreationState) => state[RETURN_REASON_FEATURE_KEY].response
);
