<ng-container *ngIf="form$ | async as form">
  <app-efa-cancel-or-return-items *ngIf="entries$ | async as entries"
                                  [entries]="entries"></app-efa-cancel-or-return-items>

  <cx-form-errors [control]="form.get('entries')"></cx-form-errors>

  <ng-container *ngTemplateOutlet="actions"></ng-container>

  <ng-template #actions>
    <app-efa-amend-order-actions *ngIf="orderCode"
                            class="row justify-content-end"
                            [orderCode]="orderCode"
                            [amendOrderForm]="form"
                            backRoute="orderDetails"
                            forwardRoute="orderCancelConfirmation"></app-efa-amend-order-actions>
  </ng-template>
</ng-container>
