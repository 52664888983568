export const productSearch = {
  productSearch: {
    item: {
      actions: {
        goToProductDetails: 'Product details'
      }
    },
    productFacetNavigation: {
      header: 'Filter'
    }
  }
};
