import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs';
import * as TransportDamageFeature from '../store/reducers/transport-damage.reducer';
import * as TransportDamageActions from '../store/actions/transport-damage.action';
import * as TransportDamageSelectors from '../store/selectors/transport-damage.selectors';
import { TransportDamageRequest } from '../model/transport-damage.model';

@Injectable({
  providedIn: 'root'
})
export class TransportDamageService {

  constructor(protected store: Store<TransportDamageFeature.TransportDamageState>) {}

  adviseOfTransportDamage (request: TransportDamageRequest): void {
    this.store.dispatch(TransportDamageActions.adviseOfTransportDamage({ request }));
  }

  isProcessing(): Observable<boolean> {
    return this.store.select(TransportDamageSelectors.selectProcessing);
  }

  success(): Observable<boolean> {
    return this.store.select(TransportDamageSelectors.selectSuccess);
  }
}
