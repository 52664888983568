<ng-container *ngIf="product$ | async as product">
  <div class="manufacturerAID">
    <span class="u-font-bold">{{ 'productSummary.id' | cxTranslate }}: </span>
    {{ product?.manufacturerAID }}
  </div>

  <div class="brand">
    <span class="u-font-bold">{{ 'eProductDetails.brand' | cxTranslate }}: </span>
    {{ product?.manufacturer }}
  </div>
</ng-container>
