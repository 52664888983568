import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { GlobalMessageType } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { TiresWarrantyConnector } from '../../occ/connectors/tires-warranty.connector';
import { TiresWarrantyActions } from '../actions';

@Injectable()

export class TiresWarrantyEffect {

  startTiresWarranty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TiresWarrantyActions.startTiresWarranty),
      concatMap((action) =>
        this.connector.startTiresWarranty(action.request).pipe(
          map(() => {
            return TiresWarrantyActions.tiresWarrantySuccess();
          }),
          catchError((error) => {
            this.globalMessageService.add(
              {
                key: 'tiresWarranty.globalMessage.startTiresWarrantyFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(TiresWarrantyActions.TiresWarrantyFailer(error));
          })
        )
      )
    );
  });
  constructor(
    private actions$: Actions,
    private connector: TiresWarrantyConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {}
}
