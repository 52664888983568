import { Config } from '@spartacus/core';

export abstract class PaginationConfig
{
  pageSize?: {
    orderHistoryOverview: number;
    invoiceCreditOverview: number;
    deliverNotesOverview: number;
    returnOverview: number;
    shippingBacklogOverview: number;
  };
}


export const defaultPaginationConfig: PaginationConfig = {
  pageSize: {
    orderHistoryOverview: 30,
    invoiceCreditOverview: 50,
    deliverNotesOverview: 30,
    returnOverview: 30,
    shippingBacklogOverview: 30,
  },
};


declare module '@spartacus/core' {
  interface Config extends PaginationConfig {}
}
