import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import { FaqItem } from '../../model/faq-items.model';

export const loadFaqItem = createAction(
  '[FaqItems] Load Faq Item Details',
  props<{ faqItemCode: string; isUserLoggedIn: boolean }>()
);

export const loadFaqItemSuccess = createAction(
  '[FaqItems] Load Faq Item Details Success',
  props<{ response: FaqItem }>()
);

export const loadFaqItemFailure = createAction(
  '[FaqItems] Load Faq Item Details Failure',
  props<{ errorResponse: ErrorType }>()
);

export const resetFaqItem = createAction('[FaqItems] Reset Faq Item Details');
