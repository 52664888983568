export const defaultManufacturers = {
  defaultManufacturers: {
    globalMessage: {
      setDefaultManufacturersFailure: 'Setting default brands failed.',
      setDefaultManufacturersSuccess: 'Setting default brands succeeded.',
      defaultManufacturersNotice: 'Please select those brands, which should be preselected in other dialogues (e.g. in brand selection in cart):'
    },
    saveButton: 'Save'
  }
};
