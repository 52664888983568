import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { InvoiceCreditOverviewAdapter } from '../adapters/invoice-credit-overview.adapter';
import { InvoicesCreditOverviewRequest, InvoicesCreditOverviewResponse } from '../../model/invoices-credits.model';

@Injectable({ providedIn: 'root' })
export class InvoiceCreditOverviewConnector {
  constructor(protected adapter: InvoiceCreditOverviewAdapter) {}

  loadInvoiceOverview(request: InvoicesCreditOverviewRequest): Observable<InvoicesCreditOverviewResponse> {
    return this.adapter.loadInvoiceOverview(request);
  }
}

