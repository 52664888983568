import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as DefaultManufacturersReducers from './default-manufacturers.reducer';

export const DEFAULT_MANUFACTURERS_INDEX_FEATURE_KEY = 'defaultManufacturers';

export interface DefaultManufacturersState {
  [DefaultManufacturersReducers.DEFAULT_MANUFACTURERS_FEATURE_KEY]: DefaultManufacturersReducers.DefaultManufacturersState;
}

export const reducers: ActionReducerMap<DefaultManufacturersState> = {
  [DefaultManufacturersReducers.DEFAULT_MANUFACTURERS_FEATURE_KEY]: DefaultManufacturersReducers.defaultManufacturersReducer,
};

export const getDefaultManufacturersState = createFeatureSelector<DefaultManufacturersState>(DEFAULT_MANUFACTURERS_INDEX_FEATURE_KEY);