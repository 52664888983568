import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import * as OrderOverviewUiFeature from '../reducers/order-overview-ui.reducer';
import { ORDER_OVERVIEW_UI_FEATURE_KEY } from '../reducers/order-overview-ui.reducer';
import { getOrderHistoryState, OrderHistoryState } from '../reducers';
import { OrderOverviewFilter } from '../../model/order-history.model';


export const selectFilterEntity: MemoizedSelector<OrderOverviewUiFeature.OrderOverviewUiState, OrderOverviewFilter> = createSelector(
  getOrderHistoryState,
  (state: OrderHistoryState) => state[ORDER_OVERVIEW_UI_FEATURE_KEY].selectedFilterEntity
);

export const selectPagination: MemoizedSelector<OrderOverviewUiFeature.OrderOverviewUiState, PaginationModel> = createSelector(
  getOrderHistoryState,
  (state: OrderHistoryState) => state[ORDER_OVERVIEW_UI_FEATURE_KEY].selectedPagination
);


export const selectSort: MemoizedSelector<OrderOverviewUiFeature.OrderOverviewUiState, SortModel> = createSelector(
  getOrderHistoryState,
  (state: OrderHistoryState) => state[ORDER_OVERVIEW_UI_FEATURE_KEY].selectedSort
);

export const returningFromDetails: MemoizedSelector<OrderOverviewUiFeature.OrderOverviewUiState, boolean> = createSelector(
  getOrderHistoryState,
  (state: OrderHistoryState) => state[ORDER_OVERVIEW_UI_FEATURE_KEY].returningFromDetails
);
