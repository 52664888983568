import { Injectable } from '@angular/core';

import { GlobalMessageType } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorType } from '@shared/models/ErrorTypes';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { RegistrationStatus } from '../../model/login-register-enum.model';
import { RegistrationStartConnector } from '../../occ/connectors/registration-start.connector';
import { RegistrationStartActions } from '../actions';

const SEPA_IBAN_FIELD_NAME = 'sepaIban';

@Injectable()
export class RegistrationStartEffect {
  loadRegistrationStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegistrationStartActions.loadRegistrationStart),
      concatMap((action) =>
        this.connector.registrationStart(action.request).pipe(
          map(() => {
            if (action.request.status === RegistrationStatus.COMPLETED) {
              this.globalMessageService.add(
                {
                  key: 'efaRegistrationForm.globalMessage.registrationSuccess',
                },
                GlobalMessageType.MSG_TYPE_CONFIRMATION
              );
            }
            
            return RegistrationStartActions.loadRegistrationStartSuccess();
          }),
          catchError((error: ErrorType) => {
            const invalidIban: any = error.error?.errors?.find(((e: any) => e.subject === SEPA_IBAN_FIELD_NAME));
            if (invalidIban != null) {
              this.globalMessageService.add(
                {
                  key: 'efaRegistrationForm.globalMessage.invalidIban',
                },
                GlobalMessageType.MSG_TYPE_ERROR
              );
            } else {
              this.globalMessageService.add(
                {
                  key: 'efaRegistrationForm.globalMessage.registrationFailure',
                },
                GlobalMessageType.MSG_TYPE_ERROR
              );
            }
            return of(RegistrationStartActions.loadRegistrationStartFailure({ errorResponse: error }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: RegistrationStartConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {}
}
