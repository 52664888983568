import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index';
import { en } from './en/index';

export const externalCatalogsTranslations = {
  de,
  en,
};

export const externalCatalogsTranslationChunksConfig: TranslationChunksConfig = {
  externalCatalogs: ['externalCatalogs'],
};
