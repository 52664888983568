import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as CartEntryInfosFeature from '../reducers/set-cart-entry-infos.reducer';
import { CART_ENTRY_INFOS_FEATURE_KEY } from '../reducers/set-cart-entry-infos.reducer';
import { CartAdministrationState, getCartAdministrationState } from '../reducers';
import { OrderInfoFieldValue } from '../../model/cart-administration.model';

export const selectUpdating: MemoizedSelector<CartEntryInfosFeature.CartEntryInfosState, boolean> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_ENTRY_INFOS_FEATURE_KEY].updating
);

export const selectEntryNumber: MemoizedSelector<CartEntryInfosFeature.CartEntryInfosState, number> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_ENTRY_INFOS_FEATURE_KEY].entryNumber
);

export const selectConsignmentInfo: MemoizedSelector<CartEntryInfosFeature.CartEntryInfosState, string> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_ENTRY_INFOS_FEATURE_KEY].consignmentInfo
);

export const selectOrderInfoFieldValues: MemoizedSelector<CartEntryInfosFeature.CartEntryInfosState,
  OrderInfoFieldValue[]> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_ENTRY_INFOS_FEATURE_KEY].orderInfoFieldValues
);

export const selectIsAllManufacturerSelected: MemoizedSelector<CartEntryInfosFeature.CartEntryInfosState, boolean> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_ENTRY_INFOS_FEATURE_KEY].isAllSelected
);

export const selectCartEntryInfoType: MemoizedSelector<CartEntryInfosFeature.CartEntryInfosState, string> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CART_ENTRY_INFOS_FEATURE_KEY].cartEntryInfoType
);
