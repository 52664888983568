import { createAction, props } from '@ngrx/store';

export const setIsValidForm = createAction(
  '[Checkout Order UI] Set Is Valid Form',
  props<{ isValidForm: boolean }>()
);

export const setTriggerPlaceOrder = createAction(
  '[Checkout Order UI] Trigger Place Order',
  props<{ trigger: boolean }>()
);
