import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Address, Config, GlobalMessageType, TranslationService} from '@spartacus/core';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, pairwise, switchMap, withLatestFrom} from 'rxjs/operators';

import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {ICON_TYPE} from '../../model/cart-administration-enum.model';
import {OrderType} from '../../model/cart-administration.model';
import {UpdateCartArg} from '../../model/update-cart.model';
import {B2BUserAccountService} from '../../services/b2-b-user-account.service';
import {CartParametersService} from '../../services/cart-parameters.service';
import {UpdateCartService} from '../../services/update-cart.service';
import {CartHeaderSupportService} from '../../services/cart-header-support.service';
import {ActiveCartFacade, Cart, DeliveryMode, OrderEntry} from '@spartacus/cart/base/root';


@Component({
  selector: 'app-cart-header',
  templateUrl: './cart-header.component.html',
  providers: [FormGroupDirective]
})
export class CartHeaderComponent implements OnInit, OnDestroy {
  minDate: NgbDateStruct;
  currentDate: Date = new Date();
  deliveryDate: NgbDateStruct = {
    day: this.currentDate.getDate(),
    month: this.currentDate.getMonth() + 1,
    year: this.currentDate.getFullYear()
  };

  currentOrderType: OrderType = {};
  iconTypes = ICON_TYPE;
  shippingTypes: DeliveryMode[];
  shippingAddresses: Address[] = this.b2bUserAccountService.shippingAddresses;
  selectedShippingAddress: Address = this.b2bUserAccountService.shippingAddress;
  selectedShippingType: DeliveryMode = this.b2bUserAccountService.defaultShippingType;
  orderDataForm: UntypedFormGroup;
  cart$: Observable<Cart> = this.activeCartService.getActive().pipe(filter(Boolean));

  readonly displayRequestedDeliveryDate: boolean = this.config.displayRequestedDeliveryDate;

  private subscription: Subscription = new Subscription();
  private skipUpdateCart = false;


  constructor(
    protected b2bUserAccountService: B2BUserAccountService,
    protected updateCartService: UpdateCartService,
    protected cartParametersService: CartParametersService,
    protected fb: UntypedFormBuilder,
    protected activeCartService: ActiveCartFacade,
    protected globalMessageService: EfaGlobalMessageService,
    protected translationService: TranslationService,
    @Inject(Config) protected config: any,
    private cartHeaderSupportService: CartHeaderSupportService,
  ) {
  }

  ngOnInit(): void {
    this.createOrderDateForm();
    this.disablePastDates();
    this.addSubscriptionToOrderType();
    this.addSubscriptionToShippingAddress();
    this.addSubscriptionToShippingType();
    this.addSubscriptionToDeliveryDate();

    this.orderDataForm.markAsUntouched();
    this.onChanges();
    this.activeCartService.reloadActiveCart();
  }

  private addSubscriptionToShippingType(): void {
    combineLatest([this.cartParametersService.getCurrentShippingType(), this.cart$])
    .pipe(filter(([shippingType, cart]: [DeliveryMode, Cart]) => shippingType != null && cart != null))
    .subscribe(([shippingType, cart]: [DeliveryMode, Cart]) => {
      this.selectedShippingType = this.shippingTypes.find(st => st.code === shippingType.code);
      this.selectedShippingType =
        (this.selectedShippingType === undefined && cart.fromConfigurator)
          ? this.cartHeaderSupportService.getParcelServiceShippingType()
          : this.selectedShippingType;
      this.orderDataForm.patchValue({
        shippingType: this.selectedShippingType
      }, {emitEvent: cart.fromConfigurator});

      if (cart.fromConfigurator) {
        this.orderDataForm.get('shippingType').disable();
      }
    });
  }

  private addSubscriptionToDeliveryDate(): void {
    this.subscription.add(this.cartParametersService.getRequestDeliveryDate().pipe(filter(Boolean),
    ).subscribe((deliveryDate: NgbDateStruct) => {
      this.orderDataForm.patchValue({
        deliveryDate
      }, {emitEvent: false});
      this.deliveryDate = deliveryDate;
    }));
  }

  private addSubscriptionToShippingAddress(): void {
    this.subscription.add(this.cartParametersService.getCurrentDeliveryAddress()
    .pipe(
      filter(Boolean),
      distinctUntilChanged((addr1: Address, addr2: Address) => addr1?.id === addr2?.id),
      withLatestFrom(this.cart$))
    .subscribe(([address, cart]) => {
      this.selectedShippingAddress = this.shippingAddresses.find(addr => addr.id === address.id);
      this.orderDataForm.patchValue({
        deliveryAddress: this.selectedShippingAddress
      }, {emitEvent: false});
      this.fillShippingTypes();
      this.addShippingTypeParcelService(cart);
    }));
  }

  private addSubscriptionToOrderType(): void {
    this.subscription.add(
      combineLatest([this.cartParametersService.getCurrentOrderType(), this.cart$])
      .pipe(filter(([orderType, cart]) => orderType !== undefined))
      .subscribe(([orderType, cart]) => {
        this.currentOrderType = this.b2bUserAccountService.orderTypes.find(ot => ot.id === orderType.id);
        this.currentOrderType = this.currentOrderType === undefined && cart.fromConfigurator
          ? this.cartHeaderSupportService.getForwardOrderType()
          : this.currentOrderType;
        this.orderDataForm.patchValue({
          orderType: this.currentOrderType
        }, {emitEvent: false});
      })
    );
  }

  onChanges(): void {
    this.subscription.add(
      this.orderDataForm.valueChanges.pipe(
        switchMap(() => of(this.orderDataForm.getRawValue())),
        filter(value => value.deliveryAddress?.id !== undefined
          && value.shippingType?.code !== undefined
          && value.orderType?.id !== undefined),
        pairwise())
      .subscribe(([prev, next]) => {
        if (prev.shippingType?.code !== next.shippingType?.code ||
          prev.deliveryAddress?.id !== next.deliveryAddress?.id ||
          this.dateStructToString(prev.deliveryDate) !== this.dateStructToString(next.deliveryDate) ||
          prev.orderType?.id !== next.orderType?.id) {
          const invalid = this.isInvalidOrderTypeCartEntriesCombination(next.orderType, this.b2bUserAccountService.cart.entries);
          if (invalid) {
            this.globalMessageService.add(
              {key: 'globalMessage.incompatibleArticleAndOrderType'},
              GlobalMessageType.MSG_TYPE_ERROR
            );
            this.skipUpdateCart = true;
            this.cartParametersService.setCurrentOrderType(prev.orderType);
            this.orderDataForm.patchValue({
              orderType: prev.orderType
            });
          } else if (!this.skipUpdateCart) {
            let requestedDeliveryDate: string;
            if (this.config.displayRequestedDeliveryDate) {
              requestedDeliveryDate = next.deliveryDate.year + '-' + next.deliveryDate.month + '-' + next.deliveryDate.day;
            } else {
              const updatedCurrentDate: Date = new Date;
              requestedDeliveryDate = updatedCurrentDate.getFullYear() + '-' + (updatedCurrentDate.getMonth() + 1) + '-' + updatedCurrentDate.getDate();
            }

            const data: UpdateCartArg = {
              cartId: this.b2bUserAccountService.cartId,
              userId: this.b2bUserAccountService.userId,
              orderTypeId: next.orderType.id,
              shippingTypeId: next.shippingType.code,
              addressId: next.deliveryAddress.id,
              requestedDeliveryDate,
              calculate: !!this.b2bUserAccountService.cart.entries && !this.b2bUserAccountService.cart.fromConfigurator,
              reloadCart: !!this.b2bUserAccountService.cart.entries
            };
            this.updateCartService.updateCart(data);
          } else {
            this.skipUpdateCart = false;
          }
        }
      })
    );
  }


  dateStructToString(date: NgbDateStruct): string {
    return date?.year + '-' + date?.month + '-' + date?.day;
  }

  setShippingTypeId(): void {
  }

  setRequestedDeliveryDate(): void {
  }

  setShippingAddress(): void {
    let emitEvent = false;
    // avoid setting default shipping type if address has not changed and accordion is opened
    combineLatest([this.cartParametersService.getCurrentDeliveryAddress(), this.cart$]).subscribe(([add, cart]) => {
      if (add?.id !== this.selectedShippingAddress.id) {
        this.fillShippingTypes();
        if (!cart.fromConfigurator) {
          this.selectedShippingType = this.getSelectedShippingType();
          emitEvent = true;
        } else {
          this.addShippingTypeParcelService(cart);
        }
      }
    }).unsubscribe();

    const shippingType = this.shippingTypes.find(st => st.code === this.selectedShippingType?.code);
    this.orderDataForm.patchValue({
      shippingType
    }, {emitEvent});
  }

  isDisabled(date: NgbDateStruct): boolean {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  disablePastDates(): void {
    this.minDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear()
    };
  }

  private fillShippingTypes(): void {
    this.shippingTypes = [];
    if (this.selectedShippingAddress != null &&
      this.selectedShippingAddress.shippingTypes != null
      && this.selectedShippingAddress.shippingTypes.length > 0) {
      this.selectedShippingAddress.shippingTypes.forEach(val => this.shippingTypes.push(val));
    } else {
      this.b2bUserAccountService.shippingTypes.forEach(val => this.shippingTypes.push(val));
    }
  }

  private addShippingTypeParcelService(cart: Cart): void {
    if (!this.shippingTypes.find(st =>
        st.code === this.cartHeaderSupportService.getParcelServiceShippingTypeCode())
      && cart.fromConfigurator) {
      this.shippingTypes.push(this.cartHeaderSupportService.getParcelServiceShippingType());
    }
  }

  private getSelectedShippingType(): DeliveryMode {
    if (this.selectedShippingAddress != null &&
      this.selectedShippingAddress.shippingTypes != null &&
      this.selectedShippingAddress.shippingTypes.length > 0) {
      return this.selectedShippingAddress.defaultShippingType !== undefined
        ? this.selectedShippingAddress.defaultShippingType
        : this.selectedShippingAddress.shippingTypes[0];
    } else if (this.b2bUserAccountService.shippingTypes != null &&
      this.b2bUserAccountService.shippingTypes.length > 0) {
      return this.b2bUserAccountService.defaultShippingType !== undefined
        ? this.b2bUserAccountService.defaultShippingType
        : this.b2bUserAccountService.shippingTypes[0];
    }
  }

  private createOrderDateForm(): void {
    this.orderDataForm = this.fb.group({
      orderType: [this.currentOrderType, [Validators.required]],
      deliveryDate: [this.deliveryDate, [Validators.required]],
      deliveryAddress: [this.selectedShippingAddress, [Validators.required]],
      shippingType: [this.selectedShippingType, [Validators.required]],
    });
  }

  orderTypeHasChanged(orderType: OrderType): void {
    this.currentOrderType = orderType;
    this.orderDataForm.patchValue({
      orderType: this.currentOrderType
    });
  }

  private isInvalidOrderTypeCartEntriesCombination(orderType: OrderType, entries: OrderEntry[]): boolean {
    if (!!entries &&
      orderType.manufacturers != null &&
      orderType.manufacturers.length > 0) {
      if (orderType.manufacturersIncluded) {
        for (const entry of entries) {
          const index = orderType.manufacturers.findIndex(
            (m) => m.id === entry.product?.manufacturerId);
          if (index < 0) {
            return true;
          }
        }
      } else {
        for (const entry of entries) {
          const index = orderType.manufacturers.findIndex(
            (m) => m.id === entry.product?.manufacturerId);
          if (index > -1) {
            return true;
          }
        }
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
