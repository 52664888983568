import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { TmsConfig } from '@spartacus/tracking/tms/core';
import { EfaGaCollectorService } from './services/efa-ga-collector.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    provideConfig(<TmsConfig>{
      tagManager: {
        ga: {
          collector: EfaGaCollectorService,
          events: [NavigationEvent],
        },
      },
    }),
  ],
})
export class GoogleAnalyticsModule {}
