import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import * as ShippingBacklogUiFeature from '../reducers/shipping-backlog-ui.reducer';
import { SHIPPING_BACKLOG_UI_FEATURE_KEY } from '../reducers/shipping-backlog-ui.reducer';
import { ShippingBacklogState, getShippingBacklogState } from '../reducers';
import { ShippingBacklogOverviewFilter } from '../../model/shipping-backlog.model';

export const returningFromDetails: MemoizedSelector<ShippingBacklogUiFeature.ShippingBacklogUiState, boolean>
  = createSelector(
  getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_UI_FEATURE_KEY].returningFromDetails
);
export const selectFilterEntity: MemoizedSelector<ShippingBacklogUiFeature.ShippingBacklogUiState, ShippingBacklogOverviewFilter>
  = createSelector(
  getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_UI_FEATURE_KEY].selectedFilterEntity
);

export const selectPagination: MemoizedSelector<ShippingBacklogUiFeature.ShippingBacklogUiState, PaginationModel> = createSelector(
    getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_UI_FEATURE_KEY].selectedPagination
);


export const selectSort: MemoizedSelector<ShippingBacklogUiFeature.ShippingBacklogUiState, SortModel> = createSelector(
    getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_UI_FEATURE_KEY].selectedSort
);
