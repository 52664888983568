import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ShippingBacklogCancellationFeature from '../store/reducers/shipping-backlog-cancellation.reducer';
import * as ShippingBacklogCancellationSelectors from '../store/selectors/shipping-backlog-cancellation.selectors';
import * as ShippingBacklogCancellationActions from '../store/actions/shipping-backlog-cancellation.actions';
import { ShippingBacklogCancellationRequest } from '../model/shipping-backlog.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShippingBacklogCancellationService {
  constructor(protected store: Store<ShippingBacklogCancellationFeature.ShippingBacklogCancellationState>) {}

  sendCancellationRequest(request: ShippingBacklogCancellationRequest): void {
    this.store.dispatch(ShippingBacklogCancellationActions.sendShippingBacklogCancellation({ request }));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(ShippingBacklogCancellationSelectors.selectLoading);
  }

  success(): Observable<boolean> {
    return this.store.select(ShippingBacklogCancellationSelectors.selectSuccess);
  }
}
