import { Observable } from 'rxjs';

import { DeliveryNoteDetailsResponse } from '../../model/delivery-notes.model';

export abstract class DeliveryNotesDetailsAdapter {
  abstract loadDetails(
    deliveryNoteNumber: string,
    returnablePositions: boolean,
    download: boolean
  ): Observable<DeliveryNoteDetailsResponse>;
}
