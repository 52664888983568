import {Injectable} from '@angular/core';
import {
  BasePageMetaResolver, BreadcrumbMeta,
  CategoryPageMetaResolver,
  CmsService,
  PageDescriptionResolver,
  PageHeadingResolver,
  ProductSearchPage,
  ProductSearchService,
  TranslationService
} from '@spartacus/core';
import {combineLatest, Observable, of} from 'rxjs';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {CurrentCategoryService} from '../service/current-category.service';
import {Category} from '../model/category.model';

@Injectable({
  providedIn: 'root',
})
export class CustomCategoryPageMetaResolver extends CategoryPageMetaResolver
  implements PageHeadingResolver, PageDescriptionResolver {

  constructor(protected currentCategoryService: CurrentCategoryService,
              productSearchService: ProductSearchService,
              cmsService: CmsService,
              translation: TranslationService,
              basePageMetaResolver?: BasePageMetaResolver
  ) {
    super(productSearchService, cmsService, translation, basePageMetaResolver);
  }

  category$: Observable<Category> = this.currentCategoryService.get();
  baseSite$ = this.translation.translate('categoryInformation.pageMetaResolver.baseSite.name');

  resolveHeading(): Observable<string> {
    return this.category$.pipe(
      map((category) => category.name),
      mergeMap((categoryName) =>
        this.searchPage$.pipe(
          filter((page: ProductSearchPage) => !!page.pagination),
          switchMap((p: ProductSearchPage) =>
            this.translation.translate('pageMetaResolver.category.title', {
              count: p.pagination.totalResults,
              query: categoryName,
            })
          )
        )
      )
    );
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {
    return of([]);
  }

  resolveTitle(): Observable<string> {
    const obs = combineLatest([this.baseSite$, this.category$]);
    return obs.pipe(
      map(([baseSite, category]) => baseSite + ' - ' + category.name));
  }

  resolveDescription(): Observable<string> {
    return this.category$.pipe(map((category) => category.description));
  }
}
