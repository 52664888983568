import { Component, OnDestroy, OnInit } from '@angular/core';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import {
  RoutingService,
  UserIdService,
} from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { ConfiguratorCartService } from '../../../cart-administration/services/configurator-cart.service';

@Component({
  selector: 'app-configurator-cart',
  templateUrl: './configurator-cart.component.html',
})
export class ConfiguratorCartComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  constructor(
    private multiCartService: MultiCartFacade,
    private configuratorCartService: ConfiguratorCartService,
    private userIdService: UserIdService,
    private routingService: RoutingService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.userIdService
        .getUserId()
        .subscribe((userId: string) => {
          this.configuratorCartService.loadConfiguratorCart(userId);
      })
    );

    this.isLoading$ = this.configuratorCartService.isLoading();

    this.subscription.add(
      this.configuratorCartService
        .getConfiguratorCart()
        .pipe(withLatestFrom(this.userIdService.getUserId()))
        .subscribe(([carts, userId]) => {
          if (carts && userId) {
            if (carts.length > 0) {
              const configuratorCart = carts[0];
              const cartId = configuratorCart?.code;
              this.multiCartService.loadCart({
                userId,
                cartId,
                extraData: {
                  active: true,
                },
              });
            }
            this.routingService.go({ cxRoute: 'cart' });
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
