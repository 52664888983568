import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { AuthActions, SiteContextActions } from '@spartacus/core';
import { produce } from 'immer';
import { InvoicesCreditOverviewRequest, InvoicesCreditOverviewResponse } from '../../model/invoices-credits.model';
import { InvoiceCreditOverviewActions } from '../actions';

export const INVOICE_CREDIT_OVERVIEW_FEATURE_KEY = 'invoiceCreditOverview';

export interface InvoiceCreditOverviewState {
  request: InvoicesCreditOverviewRequest;
  response: InvoicesCreditOverviewResponse;
  loading: boolean;
  error: ErrorType;
}

export const initialStateOverview: InvoiceCreditOverviewState = {
  request: undefined,
  response: undefined,
  loading: false,
  error: undefined
};

export const invoiceCreditOverviewReducer = createReducer(
  initialStateOverview,

  on(InvoiceCreditOverviewActions.loadInvoiceCreditOverview, (state, action) => ({
    ...state,
    loading: true,
    request: action.invoiceRequest,
    response: undefined
  })),
  on(InvoiceCreditOverviewActions.loadInvoiceCreditOverviewSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.invoiceResponse,
    request: undefined
  })),
  on(InvoiceCreditOverviewActions.loadInvoiceCreditOverviewFaillure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse
  })),
);

function authenticationChangedOverview(action: Action, state): any {
  if (action.type === AuthActions.LOGIN ||
    action.type === AuthActions.LOGOUT
  ) {
    return produce(state, (draftState) => {
      draftState.invoiceCredit.invoiceCreditOverview = initialStateOverview;
    });
  }
  return state;
}

export function metaReducerFactoryInvoiceOverview(): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const newState = authenticationChangedOverview(action, state);
    return reducer(newState, action);
  };
}
