import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as DeliveryNotesOverviewReducers from './delivery-notes-overview.reducer';
import * as DeliveryNotesDetailsReducers from './delivery-notes-details.reducer';
import * as DeliveryNotesUiReducers from './delivery-notes-ui.reducer';

export const DELIVERY_NOTES_FEATURE_KEY = 'deliveryNotes';

export interface DeliveryNotesState {
  [DeliveryNotesOverviewReducers.DELIVERY_NOTES_OVERVIEW_FEATURE_KEY]: DeliveryNotesOverviewReducers.DeliveryNotesOverviewState;
  [DeliveryNotesDetailsReducers.DELIVERY_NOTES_DETAILS_FEATURE_KEY]: DeliveryNotesDetailsReducers.DeliveryNotesDetailsState;
  [DeliveryNotesUiReducers.DELIVERY_NOTES_UI_FEATURE_KEY]: DeliveryNotesUiReducers.DeliveryNotesUiState;
}

export const reducers: ActionReducerMap<DeliveryNotesState> = {
  [DeliveryNotesOverviewReducers.DELIVERY_NOTES_OVERVIEW_FEATURE_KEY]: DeliveryNotesOverviewReducers.deliveryNotesOverviewReducer,
  [DeliveryNotesDetailsReducers.DELIVERY_NOTES_DETAILS_FEATURE_KEY]: DeliveryNotesDetailsReducers.deliveryNotesDetailsReducer,
  [DeliveryNotesUiReducers.DELIVERY_NOTES_UI_FEATURE_KEY]: DeliveryNotesUiReducers.deliveryNotesUiReducer,
};

export const getDeliveryNotesState = createFeatureSelector<DeliveryNotesState>(DELIVERY_NOTES_FEATURE_KEY);
