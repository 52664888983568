import { createReducer, on } from '@ngrx/store';

import { MultiAddToCartActions } from '../actions';
import { AddToCartParam, CartModificationList } from '../../model/cart-administration.model';
import { ErrorType } from '@shared/models/ErrorTypes';

export const MULTI_ADD_TO_CART_FEATURE_KEY = 'multiAddToCart';

export interface MultiAddToCartState {
  entries: AddToCartParam[];
  modifications: CartModificationList;
  adding: boolean;
  error: ErrorType;
}

export const initialState: MultiAddToCartState = {
  entries: undefined,
  modifications: undefined,
  adding: false,
  error: undefined
};


export const multiAddToCartReducer = createReducer(
  initialState,

  on(MultiAddToCartActions.loadMultiAddToCart, (state, action) => ({
    ...state,
    adding: true,
    entries: action.entries,
    error: undefined
  })),
  on(MultiAddToCartActions.loadMultiAddToCartSuccess, (state, action) => ({
    ...state,
    adding: false,
    modifications: action.modifications,
    error: undefined
  })),
  on(MultiAddToCartActions.loadMultiAddToCartFailure, (state, action) => {
    return ({
      ...state,
      adding: false,
      error: action.errorResponse
    });
  }),
  on(MultiAddToCartActions.loadMultiAddToCartReset, (state, action) => (
    initialState
  )),
);
