export const partsLinkAccess = {
  partsLinkAccess: {
    globalMessage: {
      startPartsLinkAccessFailure: 'The submission of the data has failed. Please try again.',
      startPartsLinkAccessSuccess: 'Thank you! Your data was submitted successfully. An email with all information was sent to the email address you have provided.'
    },
    partsLinkAccessForm: {
      formTitle: 'Order 14 day trial access with full functionality',
      formMandatoryFields: 'Mandatory fields are marked with *.',
      legend1: '1. Company Data',
      legend2: '2. Contact person',
      submit: 'Submit',
      reset: 'Reset to default',
      dealerData: {
        customerNumber: {
          lable: 'EFA customer number',
          placeholder: 'EFA customer number',
        },
        companyName: {
          lable: 'Company',
          placeholder: 'your company name',
        },
        street: {
          lable: 'Street',
          placeholder: 'Street',
        },
        streetnumber: {
          lable: 'House number',
          placeholder: 'House number',
        },
        countryIsoCode: {
          lable: 'Location',
          placeholder: 'Select country',
        },
        postalCode: {
          lable: 'POSTCODE',
          placeholder: 'Postcode',
        },
        town: {
          lable: 'Location',
          placeholder: 'Location',
        },
        email: {
          lable: 'E-mail address',
          placeholder: 'E-mail address',
        },
      },
      customerData: {
        titleCode: {
          lable: 'Salutation',
          gender: {
            MALE: 'Mister',
            FEMALE: 'Woman',
            OTHER: 'Miscellaneous',
          },
        },
        name: {
          lable: 'First and last name / company name',
          placeholder: 'Your first and last name or company name',
        },
      },
    },
  },
};
