<ng-container *ngIf="iframeUrl$ | async as iframeUrl">
  <iframe #topmotive *ngIf="catalogCanBeOpened$ | async; else customerInformation"
          title='Topmotive Catalog' width="100%" height="1000px"
          [src]="getSanitizedIframeUrl(iframeUrl)"></iframe>

  <ng-template #customerInformation>
    <h2 class="text-center mb-3"> {{ 'externalCatalogs.topmotiveCatalog.unauthorizedAccessRequestMessage.title' | cxTranslate }} </h2>
    <p class="u-font-bold mb-4 text-center">{{'externalCatalogs.topmotiveCatalog.unauthorizedAccessRequestMessage.text' | cxTranslate }}</p>
    <span class="d-flex justify-content-center mb-5">
      <button class="btn btn-secondary btn-block btn-back" type="button" (click)="goBack()">
        {{ 'common.back' | cxTranslate }}
      </button>
    </span>
  </ng-template>
</ng-container>
