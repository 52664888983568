import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FaqItem } from '../../model/faq-items.model';
import { FaqItemAdapter } from '../adapters/faq-item.adapter';

@Injectable({
  providedIn: 'root',
})
export class FaqItemConnector {
  constructor(protected adapter: FaqItemAdapter) {}

  getDetails(
    faqItemCode: string,
    isUserLoggedIn: boolean
  ): Observable<FaqItem> {
    return this.adapter.getDetails(faqItemCode, isUserLoggedIn);
  }
}
