import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  FeaturesConfigModule,
  I18nModule,
  OccConfig,
  provideConfig,
  provideDefaultConfig,
  UrlModule
} from '@spartacus/core';

import {
  ActiveFacetsModule,
  defaultViewConfig,
  FacetListModule,
  IconModule,
  ItemCounterModule,
  ListNavigationModule,
  MediaModule,
  OutletModule,
  PAGE_LAYOUT_HANDLER,
  ProductFacetNavigationComponent,
  ProductListModule,
  SpinnerModule,
  ViewConfig
} from '@spartacus/storefront';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AddToCartModule} from '../add-to-cart/add-to-cart.module';
import {EfaProductGridItemComponent} from './components/efa-product-grid-item/efa-product-grid-item.component';
import {EfaProductListItemComponent} from './components/efa-product-list-item/efa-product-list-item.component';
import {EfaProductListComponent} from './components/efa-product-list/efa-product-list.component';
import {EfaProductScrollComponent} from './components/efa-product-scroll/efa-product-scroll.component';
import {productSearchTranslationChunksConfig, productSearchTranslations} from './translations/product-search.translations';
import {EfaSearchResultsGridPageLayoutHandler} from './layout-handlers/efa-search-results-grid-page-layout-handler';
import {EfaSearchResultsListPageLayoutHandler} from './layout-handlers/efa-search-results-list-page-layout-handler';
import {EfaProductFacetNavigationComponent} from './components/efa-product-facet-navigation/efa-product-facet-navigation.component';
import {EfaSearchBoxComponent} from './components/efa-search-box/efa-search-box.component';
import { SharedModules } from '@shared/shared.modules';
import { BannerModule } from '../banner/banner.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    ProductListModule,
    ItemCounterModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    SpinnerModule,
    InfiniteScrollModule,
    FeaturesConfigModule,
    OutletModule,
    ListNavigationModule,
    BannerModule,
//    EffectsModule.forFeature([ProductPricesEffects]),
    ProductListModule,
    SharedModules,
    ConfigModule.withConfig({
      backend:
        {
          occ: {
            endpoints: {
              productSearch:
                'products/search?fields=products(manufacturer,code,name,manufacturerAID,manufacturerId,images(DEFAULT),stock(FULL),productCampaign(DEFAULT)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
            }
          }
        }
    } as OccConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        ProductRefinementComponent: {
          component: EfaProductFacetNavigationComponent,
        },
      },
    }),
    AddToCartModule,
    ActiveFacetsModule,
    IconModule,
    FacetListModule
  ],
  providers: [
    provideConfig({
      i18n: {
        resources: productSearchTranslations,
        chunks: productSearchTranslationChunksConfig,
      },

    }),
    provideDefaultConfig(defaultViewConfig as ViewConfig),
    provideDefaultConfig({
      cmsComponents: {
        CMSProductListComponent: {
          component: EfaProductListComponent,
        },
        ProductGridComponent: {
          component: EfaProductListComponent,
        },
        SearchResultsListComponent: {
          component: EfaProductListComponent,
        },
        SearchBoxComponent: {
          component: EfaSearchBoxComponent,
        },
      },
    } as CmsConfig),
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: EfaSearchResultsListPageLayoutHandler,
      multi: true,
    },
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: EfaSearchResultsGridPageLayoutHandler,
      multi: true,
    },
  ],
  declarations: [
    EfaProductListComponent,
    EfaProductListItemComponent,
    EfaProductGridItemComponent,
    EfaProductScrollComponent,
    EfaProductFacetNavigationComponent,
    EfaSearchBoxComponent
  ],
  exports: [
    EfaProductListComponent,
    EfaProductListItemComponent,
    EfaProductGridItemComponent,
    EfaProductScrollComponent,
    EfaProductFacetNavigationComponent
  ],
})
export class ProductSearchModule {
}
