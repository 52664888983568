import { Component, Input } from '@angular/core';
import { GenericLinkComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-download-link',
  templateUrl: './download-link.component.html',
})
export class DownloadLinkComponent extends GenericLinkComponent {
  @Input() url: string;
  @Input() target: string;
  @Input() classes: string[];
}
