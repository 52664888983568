import { createAction, props } from '@ngrx/store';

import { OrderOverviewRequest } from '../../model/order-history.model';
import { ErrorType } from '@shared/models/ErrorTypes';
import { OrderHistoryList } from '@spartacus/order';

export const loadOrderOverview = createAction(
  '[OrderHistory] Load Order History',
  props<{ request: OrderOverviewRequest }>()
);

export const resetOrderOverview = createAction(
  '[OrderHistory] Reset Order History'
);

export const loadOrderOverviewSuccess = createAction(
  '[OrderHistory] Load Order History Success',
  props<{ response: OrderHistoryList }>()
);

export const loadOrderOverviewFailure = createAction(
  '[OrderHistory] Load Order History Failure',
  props<{ errorResponse: ErrorType }>()
);
