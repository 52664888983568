import {AbstractControl, UntypedFormArray, UntypedFormGroup, ValidatorFn} from '@angular/forms';

export function validReturnableQuantity(returnableQuantity: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value !== undefined && (control.value > returnableQuantity || control.value < 0)) {
      return {returnableQuantity: true};
    }
    return null;
  };
}

export function zeroMatchValidator(fg: UntypedFormGroup): {[key: string]: boolean} | null {
  const formArray: UntypedFormArray = fg.get('items') as UntypedFormArray;
  let sum = 0;
  formArray.controls.forEach((item) => {
    sum += item.get('quantityToReturn').value;
  });
  return (sum > 0) ? null : {zeroMatchValidator: true};
}
