import { Component } from '@angular/core';
import { ProductIntroComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-efa-product-intro',
  templateUrl: './efa-product-intro.component.html'
})
export class EfaProductIntroComponent extends ProductIntroComponent {

}
