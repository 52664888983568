import { RegistrationStartEffect } from './registration-start.effect';
import { RegistrationLoadEffect } from './registration-load.effect';
import { RegistrationConfirmationEffect } from './registration-confirmation.effect';
import { RegistrationInformationMultiOptionFieldsValuesEffect } from './registration-information-multi-option-fields-values.effect';

export const effects: any[] = [
  RegistrationStartEffect,
  RegistrationLoadEffect,
  RegistrationConfirmationEffect,
  RegistrationInformationMultiOptionFieldsValuesEffect
];
