import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { PaginationModel, SortModel } from '@spartacus/core';
import { Store } from '@ngrx/store';

import * as InvoiceCreditUiFeature from '../store/reducers/invoice-credit-ui.reducers';
import * as InvoiceCreditUiSelectors from '../store/selectors/invoice-credit-ui.selectors';
import { InvoiceCreditUiActions } from '../store/actions';
import { InvoiceCreditOverviewFilter } from '../model/invoices-credits.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceCreditUiService {

  constructor(protected store: Store<InvoiceCreditUiFeature.InvoiceCreditUiState>) {
  }

  selectedFilterEntity(): Observable<InvoiceCreditOverviewFilter> {
    return this.store.select(InvoiceCreditUiSelectors.selectFilterEntity);
  }

  setSelectedFilterEntity(selectedFilterEntity: InvoiceCreditOverviewFilter): void {
    this.store.dispatch(InvoiceCreditUiActions.setSelectedFilterEntity({ selectedFilterEntity }));
  }

  selectedPagination(): Observable<PaginationModel> {
    return this.store.select(InvoiceCreditUiSelectors.selectPagination);
  }

  setSelectedPagination(selectedPagination: PaginationModel): void {
    this.store.dispatch(InvoiceCreditUiActions.setSelectedPagination({ selectedPagination }));
  }

  selectedSort(): Observable<SortModel> {
    return this.store.select(InvoiceCreditUiSelectors.selectSort);
  }

  setSelectedSort(selectedSort: SortModel): void {
    this.store.dispatch(InvoiceCreditUiActions.setSelectedSorting({ selectedSort }));
  }
}
