import { createReducer, on } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import { FaqOverviewResponse } from '../../model/faq-items.model';
import { FaqOverviewActions } from '../actions';

export const FAQ_OVERVIEW_FEATURE_KEY = 'faqOverview';

export interface FaqOverviewState {
  isUserLoggedIn: boolean;
  response: FaqOverviewResponse;
  loading: boolean;
  error: ErrorType;
}

export const initialStateOverview: FaqOverviewState = {
  isUserLoggedIn: undefined,
  response: undefined,
  loading: false,
  error: undefined,
};

export const faqOverviewReducer = createReducer(
  initialStateOverview,

  on(FaqOverviewActions.loadFaqOverview, (state, action) => ({
    ...state,
    isUserLoggedIn: action.isUserLoggedIn,
    loading: true,
    response: undefined,
  })),
  on(FaqOverviewActions.loadFaqOverviewSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.response,
    isUserLoggedIn: undefined,
  })),
  on(FaqOverviewActions.loadFaqOverviewFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse,
  })),
  on(FaqOverviewActions.resetFaqOverview, (state, action) => ({
    ...initialStateOverview,
  }))
);
