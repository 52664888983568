import { createSelector, MemoizedSelector } from "@ngrx/store";
import * as WarrantyProcessFeature from '../reducers/warranty-process.reducer';
import { WARRANTY_PROCESS_FEATURE_KEY } from '../reducers/warranty-process.reducer';;
import { getWarrantyProcessState, WarrantyProcessState } from '../reducers';

export const selectProcessing: MemoizedSelector<WarrantyProcessFeature.WarrantyProcessState, boolean> = createSelector(
  getWarrantyProcessState,
  (state: WarrantyProcessState) => state[WARRANTY_PROCESS_FEATURE_KEY].processing
);

export const selectSuccess: MemoizedSelector<WarrantyProcessFeature.WarrantyProcessState, boolean> = createSelector(
  getWarrantyProcessState,
  (state: WarrantyProcessState) => state[WARRANTY_PROCESS_FEATURE_KEY].success
);