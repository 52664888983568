import { PDP_CALL_SOURCE } from '@shared/models/shared.model';
import { Product } from '@spartacus/core';

export const pdpCallSourceToGoBackTranslation: Record<PDP_CALL_SOURCE, string> = {
  CART: 'eProductDetails.goBack.cart',
  CAROUSEL: 'eProductDetails.goBack.carousel',
  CATEGORY: 'eProductDetails.goBack.category',
  ORDER: 'eProductDetails.goBack.order',
  SEARCH: 'eProductDetails.goBack.search',
  WISHLIST: 'eProductDetails.goBack.wishlist',
}

declare module '@spartacus/core' {
  interface Product {
    deliveryTime?: number;
    manufacturerAID?: string;
  }
}
