import { Observable } from 'rxjs';

import { AddToCartParam, CartModificationList } from '../../model/cart-administration.model';

export abstract class MultiAddToCartAdapter {
  abstract addEntries(
    entries: AddToCartParam[],
    userId: string,
    cartId: string
  ): Observable<CartModificationList>;
}
