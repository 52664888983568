import {ViewportScroller} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {Manufacturer} from '../../../cart-administration/model/cart-administration.model';
import {B2BUserAccountService} from '../../../cart-administration/services/b2-b-user-account.service';
import {ICON_TYPE} from '../../model/wish-list-icon.model';
import {WishListComponent} from '@spartacus/cart/wish-list/components';
import {WishListFacade} from '@spartacus/cart/wish-list/root';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {SetCartEntryInfosService} from '../../../cart-administration/services/set-cart-entry-infos.service';

const EMPTY_STRING = '';

@Component({
  selector: 'app-efa-wish-list',
  templateUrl: './efa-wish-list.component.html',
})
export class EfaWishListComponent extends WishListComponent implements OnInit {
  wishListFilterForm: UntypedFormGroup;
  iconTypes = ICON_TYPE;
  selectedManufacturers$: Observable<Manufacturer[]> =
    this.b2bUserAccountService.getSelectedManufacturers();
  private selectedManufacturers: Manufacturer[];
  private subscription: Subscription = new Subscription();
  isWishListHeaderHidden: boolean = false;

  constructor(
    protected wishListService: WishListFacade,
    protected b2bUserAccountService: B2BUserAccountService,
    private fb: UntypedFormBuilder,
    private scroller: ViewportScroller,
    private launchDialogService: LaunchDialogService,
    private setCartEntryInfosService: SetCartEntryInfosService
  ) {
    super(wishListService);
  }

  ngOnInit(): void {
    this.b2bUserAccountService.setSelectedManufacturer([]);
    this.setCartEntryInfosService.setIsAllManufacturerSelected(false);
    this.wishListFilterForm = this.fb.group({
      manufacturerCodes: new UntypedFormControl(''),
    });
    this.setSelectedManufacturerNamesCodes();
  }

  onOpenModal(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.MANUFACTURERS_SELECTION, undefined, undefined);
  }

  setSelectedManufacturerNamesCodes(): void {
    this.subscription.add(combineLatest([this.selectedManufacturers$, this.wishList$]).subscribe(([manufacturers, wishList]) => {
        this.selectedManufacturers = [];
        if (manufacturers.length) {
          this.isWishListHeaderHidden = true;
          manufacturers.forEach((manufacturer: Manufacturer) => {
            this.selectedManufacturers.push(manufacturer);
          });
          wishList?.entries?.forEach((entry: any) => {
            this.selectedManufacturers.forEach((manufacturer: Manufacturer) => {
              if (manufacturer.name === entry.product?.manufacturer) {
                this.isWishListHeaderHidden = false;
              }
            })
          })
        }
    }));
  }

  isManufacturerForEntrySelected(entry: any): boolean {
    if (this.selectedManufacturers.length <= 0 || this.selectedManufacturers[0].name === EMPTY_STRING) {
      this.isWishListHeaderHidden = false;
      return true;
    }
    return this.selectedManufacturers.map((manufacturer) => manufacturer.name).filter(
      (manufacturerName) => manufacturerName === entry.product?.manufacturer
    ).length > 0
  }

  onRemoveSelectedManufacturer(manufacturerId: string) {
    const index: number = this.selectedManufacturers.findIndex((manufacturer: Manufacturer) => manufacturer.id === manufacturerId);
    if (index != -1) {
      this.selectedManufacturers.splice(index, 1);
      this.b2bUserAccountService.setSelectedManufacturer(this.selectedManufacturers);
    }
  }

  backToTop() {
    this.scroller.scrollToAnchor("topHeader");
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
