import {Injectable} from '@angular/core';
import {Converter, ConverterService, Occ} from '@spartacus/core';
import {Category} from '../model/category.model';

@Injectable({
  providedIn: 'root',
})
export class OccCategoryNormalizer implements Converter<Occ.CategoryHierarchy, Category> {
  constructor(private converter: ConverterService) {
  }

  convert(source: Occ.CategoryHierarchy, target?: Category): Category {
    if (target === undefined) {
      target = {...(source as any)};
    }
    if (source && source.subcategories) {
      target.subCategories = source.subcategories.map((category) => ({
        ...category,
      }));
    }
    return target;
  }
}
