import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { SetCartCalculationModeActions } from "../store/actions";

import * as SetCartCalculationModeFeature from '../store/reducers/set-cart-calculation-mode.reducer';
import * as SetCartCalculationModeSelectors from '../store/selectors/set-cart-calculation-mode.selectors';

@Injectable({providedIn: 'root'})
export class SetCartCalculationModeService {

  constructor(
    private store: Store<SetCartCalculationModeFeature.SetCartCalculationModeState>
  ) {
  }

  setCartCalculationMode(cartCalculationMode: boolean, cartId: string): void {
    this.store.dispatch(SetCartCalculationModeActions.setCartCalculationMode({ cartCalculationMode, cartId }));
  }

  isUpdating(): Observable<boolean> {
    return this.store.select(SetCartCalculationModeSelectors.selectIsUpdating);
  }

  success(): Observable<boolean> {
    return this.store.select(SetCartCalculationModeSelectors.selectSuccess);
  }

  reset(): void {
    this.store.dispatch(SetCartCalculationModeActions.resetCartCalculationMode());
  }
}
