import { Injectable } from '@angular/core';
import { UserAccountEventListener } from '@spartacus/user/account/root';

@Injectable({
  providedIn: 'root',
})
export class EfaUserAccountEventListener extends UserAccountEventListener {
  protected onAuth(): void {
    // This method is left intentionally blank to avoid the display of logout success message.
    // However, the logic should be reconstructed in case we need to register auth events apart from displaying messages.
  }
}
