import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  ManufacturersBaseComponent
} from '@shared/components/manufacturers-base/manufacturers-base.component';
import {Config, LanguageService} from '@spartacus/core';
import {Manufacturer} from "../../../manufacturers-selection/model/default-manufacturer.model";
import {ICON_TYPE} from '../../model/cart-administration-enum.model';
import {FocusConfig, LaunchDialogService} from '@spartacus/storefront';
import {OrderType} from "../../model/cart-administration.model";
import {B2BUserAccountService} from '../../services/b2-b-user-account.service';
import {CartParametersService} from "../../services/cart-parameters.service";
import {SetCartEntryInfosService} from '../../services/set-cart-entry-infos.service';
import {
  DefaultManufacturersService
} from "../../../manufacturers-selection/services/default-manufacturers.service";
import {EfaGlobalMessageService} from "@shared/services/efa-global-message.service";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    MANUFACTURERS_SELECTION = 'MANUFACTURERS_SELECTION',
  }
}

@Component({
  selector: 'app-manufacturers-selection',
  templateUrl: './manufacturers-selection.component.html',
})
export class ManufacturersSelectionComponent extends ManufacturersBaseComponent implements OnInit, OnDestroy {
  readonly iconType = ICON_TYPE;

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: true,
    focusOnEscape: true,
  };


  private currentOrderType: OrderType;

  constructor(
    protected b2bUserAccountService: B2BUserAccountService,
    protected setCartEntryInfosService: SetCartEntryInfosService,
    protected cartParametersService: CartParametersService,
    protected languageService: LanguageService,
    protected launchDialogService: LaunchDialogService,
    defaultManufacturersService: DefaultManufacturersService,
    globalMessageService: EfaGlobalMessageService,
    @Inject(Config) protected config: any,
  ) {
    super(b2bUserAccountService, setCartEntryInfosService, languageService, defaultManufacturersService, globalMessageService,config);
    this.subscription.add(this.cartParametersService.getCurrentOrderType().subscribe(o => this.currentOrderType = o));
    this.displaySuccessMessage = false;
  }

  closeModal(): void {
    this.launchDialogService.closeDialog(null);
  }

  submitData(): void {
    super.submitData();
    this.closeModal();
  }

  setFinalVisibleManufacturers(): void {
    if (this.currentOrderType && this.currentOrderType.manufacturers != null && this.currentOrderType.manufacturers.length > 0) {
      if (!this.currentOrderType.manufacturersIncluded) {
        this.visibleManufacturers = this.visibleManufacturers.filter(
          (manufacturer: Manufacturer) =>
            this.currentOrderType.manufacturers?.findIndex(
              (orderTypeManufacturer: Manufacturer) =>
                orderTypeManufacturer.id === manufacturer.id
            ) < 0
        );
      }
    }
    super.setFinalVisibleManufacturers();
  }
}

