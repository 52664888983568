<form *ngIf="productCode && (cart$ | async) as cart" [formGroup]="addToCartForm" (submit)="addToCart()">
  <div class="quantity" *ngIf="showQuantity">
    <label>{{ 'addToCart.quantity' | cxTranslate }}</label>
    <app-efa-item-counter *ngIf="hasStock"
                     [max]="maxQuantity"
                     [control]="addToCartForm.get('quantity')"></app-efa-item-counter>
    <span class="info">{{
      hasStock
        ? ('addToCart.inStock' | cxTranslate)
        : ('addToCart.outOfStock' | cxTranslate)
      }}</span>
  </div>

  <button *ngIf="(hasStock && !cart.fromConfigurator) || (hasStock && cart.fromConfigurator && (isCalledFromSERP || isCalledFromDPD))"
          class="c-btn-add-to-cart btn btn-primary submit-btn"
          type="submit"
          [disabled]="quantity <= 0 || quantity > maxQuantity || !priceAvailable">
          <span> <cx-icon [type]="myIconTypes.CART_WHITE"></cx-icon></span>
          <span style="color: inherit; background-color: inherit;" class="btn-text">{{ 'addToCart.addToCart' | cxTranslate }}</span>
  </button>

  <div class="quantity-hint" *ngIf="showQuantityHint && hasStock && !cart.fromConfigurator; else showQuantityHintForConfiguratorEntry">
    <span class="hint">{{ 'eAddToCart.showQuantityHint' | cxTranslate }}</span>
  </div>

  <ng-template #showQuantityHintForConfiguratorEntry>
    <div class="quantity-hint" *ngIf="showQuantityHint && hasStock">
      <span class="hint">{{ 'eAddToCart.showQuantityHintForConfiguratorEntry' | cxTranslate }}</span>
    </div>
  </ng-template>
</form>
