import { createReducer, on } from '@ngrx/store';
import { ShippingBacklogItem } from '../../model/shipping-backlog.model';
import { ShippingBacklogSelectedItemsActions } from '../actions';

export const SHIPPING_BACKLOG_SELECTED_ITEMS_FEATURE_KEY = 'shippingBacklogSelectedItems';

export interface ShippingBacklogSelectedItemsState {
  selectedItems: ShippingBacklogItem[];
}

export const initialShippingBacklogShippingBacklogSelectedItems: ShippingBacklogSelectedItemsState = {
    selectedItems: undefined,
};

export const shippingBacklogSelectedItemsReducer = createReducer(
  initialShippingBacklogShippingBacklogSelectedItems,

  on(ShippingBacklogSelectedItemsActions.saveSelctedItems, (state, action) => ({
    ...state,
    selectedItems: action.selectedItems,
  })),
);
