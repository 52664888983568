import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {StateWithPrices} from '../store/product-prices.reducer';
import * as ProductPricesActions from '../store/product-prices.actions';
import * as ProductPricesSelectors from '../store/product-prices.selectors';
import {OrderType} from '../../cart-administration/model/cart-administration.model';
import {PriceResponseItem} from '../model/product-prices.model';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductPricesService {

  constructor(protected store: Store<StateWithPrices>) {
  }

  retrievePrices(products: string[], userId: string, orderType: OrderType, returnDeliveryTime: boolean): void {
    this.store.dispatch(ProductPricesActions.loadProductPrices({
      data: {
        products,
        orderType,
        userId,
        returnDeliveryTime
      }
    }));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(ProductPricesSelectors.selectIsLoading);
  }

  getPrice(productCode: string): Observable<PriceResponseItem> {
    return this.store.pipe(select(ProductPricesSelectors.selectProductPrice, productCode));
  }

  getPrices(productPricesCodes: string[]): Observable<PriceResponseItem[]> {
    return this.store.pipe(select(ProductPricesSelectors.selectProductPrices, productPricesCodes));
  }

  getOrLoadPrices(productPricesCodes: string[], userId: string, orderType: OrderType, returnDeliveryTime: boolean):
    Observable<PriceResponseItem[]> {
    return this.store
      .pipe(
        select(ProductPricesSelectors.selectProductPrices, productPricesCodes),
        tap(prices => {
            if (prices.length < productPricesCodes.length) {
              this.retrievePrices(productPricesCodes, userId, orderType, returnDeliveryTime);
            }
          }
        ));
  }

  loadAndGetPrices(productPricesCodes: string[], userId: string, orderType: OrderType, returnDeliveryTime: boolean):
    Observable<PriceResponseItem[]> {
    this.retrievePrices(productPricesCodes, userId, orderType, returnDeliveryTime);
    return this.store
      .pipe(
        select(ProductPricesSelectors.selectProductPrices, productPricesCodes));
  }

  getAllPrices(): Observable<PriceResponseItem[]> {
    return this.store
      .pipe(select(
        ProductPricesSelectors.selectAllProductPrices, true));
  }
}
