import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as CheckoutOrderUiFeature from '../store/reducers/checkout-order-ui.reducers';
import { CheckoutOrderUiActions } from '../store/actions';
import * as CheckoutOrderUiSelectors from '../store/selectors/checkout-order-ui.selectors';

@Injectable({
  providedIn: 'root'
})
export class CheckoutOrderUiService {

  constructor(protected store: Store<CheckoutOrderUiFeature.CheckoutOrderUiState>) {
  }

  selectedIsValidForm(): Observable<boolean> {
    return this.store.select(CheckoutOrderUiSelectors.selectIsValidForm);
  }

  selectedPlaceOrderTriggered(): Observable<boolean> {
    return this.store.select(CheckoutOrderUiSelectors.selectPlaceOrderTriggered);
  }

  setSelectedIsValidForm(isValidForm: boolean): void {
    this.store.dispatch(CheckoutOrderUiActions.setIsValidForm({ isValidForm }));
  }

  triggerPlaceOrder(): void {
    this.store.dispatch(CheckoutOrderUiActions.setTriggerPlaceOrder({trigger: true}));
  }

  resetTriggerPlaceOrder(): void {
    this.store.dispatch(CheckoutOrderUiActions.setTriggerPlaceOrder({trigger: false}));
  }

}
