import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { PneuConfiguratorToken } from '../../../model/content-tiles.model';
import { TokenGenerationAdapter } from '../token-generation.adapter';

@Injectable({
  providedIn: 'root',
})
export class OccTokenGenerationAdapter implements TokenGenerationAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  generateToken(tokenName: string, saveToken: boolean): Observable<PneuConfiguratorToken> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl('tokenGeneration', {
      urlParams: { userId: 'current', tokenName, saveToken },
    });

    return this.http.post<PneuConfiguratorToken>(url, null, { headers });
  }
}
