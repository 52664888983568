import {DatePipe, ViewportScroller} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {exceedMaxFileSize} from '@shared/utils/upload-file-size';
import {validFileExtension} from '@shared/validators/file-extension';
import {B2BUser, Config, Country, GlobalMessageType, TranslationService, UserAddressService, WindowRef} from '@spartacus/core';
import {CustomFormValidators} from '@spartacus/storefront';
import {combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ICON_TYPE, PDFIcon} from '../../model/tires-warranty-icon.model';
import {InvoiceDocument, TiresWarrantyRequest} from '../../model/tires-warranty.model';
import {TiresWarrantyService} from '../../services/tires-warranty.service';
import {UserAccountFacade} from '@spartacus/user/account/root';

const DATE_FORMAT = 'yyyy-MM-dd';
const GERMANY_ISOCODE = 'DE';

@Component({
  selector: 'app-tires-warranty-form-component',
  templateUrl: './tires-warranty-form.component.html',
  styleUrls: ['./tires-warranty-form.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class TiresWarrantyFormComponent implements OnInit, OnDestroy {
  tiresWarrantyForm: UntypedFormGroup;
  countries$: Observable<Country[]>;
  referenceTypeCodes: string[] = ['INVOICE', 'DELIVERY_NOTE', 'ORDER'];
  tireBrands: string[] = ['Michelin / BF Goodrich', 'Continental / Semperit / Barum', 'Goodyear / Dunlop / Fulda / Sava', 'Bridgestone / Firestone', 'Pirelli', 'Falken', 'Nokian'];
  titleCodes: string[] = ['MALE', 'FEMALE', 'OTHER', 'COMPANY'];
  iconTypes = ICON_TYPE;
  maxDate: NgbDateStruct;
  isLoading$: Subject<boolean> = new Subject();
  private subscription: Subscription = new Subscription();
  private currentDate = new Date();
  private maxFileSize: number;
  private invoiceDocument: InvoiceDocument;

  private readonly formProperties = {
    dealerData: new UntypedFormGroup({
      companyName: new UntypedFormControl('', Validators.required),
      customerNumber: new UntypedFormControl('', Validators.required),
      street: new UntypedFormControl('', Validators.required),
      streetnumber: new UntypedFormControl('', Validators.required),
      countryIsoCode: new UntypedFormControl('', Validators.required),
      postalCode: new UntypedFormControl('', Validators.required),
      town: new UntypedFormControl('', Validators.required),
      phone: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, CustomFormValidators.emailValidator]),
      referenceNumber: new UntypedFormControl('', Validators.required),
      referenceTypeCode: new UntypedFormControl('', Validators.required),
      invoiceDocument: new UntypedFormControl(
        undefined,
        [Validators.required, validFileExtension(this.config.fileExtension.tiresWarrantyForm)]
      ),
    }),
    tireCarData: new UntypedFormGroup({
      installatonDate: new UntypedFormControl(undefined, Validators.required),
      tireBrand: new UntypedFormControl('', Validators.required),
      quantity: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      tireSize: new UntypedFormControl('', Validators.required),
      tireProfile: new UntypedFormControl('', Validators.required),
      carBrand: new UntypedFormControl('', Validators.required),
      numberPlate: new UntypedFormControl('', Validators.required),
      totalKilometers: new UntypedFormControl(undefined, Validators.required)
    }),
    customerData: new UntypedFormGroup({
      titleCode: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      street: new UntypedFormControl('', Validators.required),
      streetnumber: new UntypedFormControl('', Validators.required),
      countryIsoCode: new UntypedFormControl('', Validators.required),
      postalCode: new UntypedFormControl('', Validators.required),
      town: new UntypedFormControl('', Validators.required),
      phone: new UntypedFormControl('', Validators.required)
    }),
    warrantyTerms: new UntypedFormControl(null, Validators.requiredTrue)
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userAddressService: UserAddressService,
    private globalMessageService: EfaGlobalMessageService,
    private datePipe: DatePipe,
    private tiresWarrantyService: TiresWarrantyService,
    @Inject(Config) private config: any,
    private translationService: TranslationService,
    private userAccountFacade: UserAccountFacade,
    protected winRef: WindowRef,
    private scroller: ViewportScroller
  ) {
  }

  ngOnInit(): void {
    this.maxFileSize = this.config.maxFileSize.warrantyProcessForm;
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountries();
        }
      }),
    );
    this.tiresWarrantyForm = this.formBuilder.group(this.formProperties);
    this.subscription.add(
      this.tiresWarrantyService.isProcessing().subscribe((isProcessing: boolean) => {
        this.isLoading$.next(isProcessing);
      }),
    );

    this.subscription.add(this.userAccountFacade.get().subscribe((b2bUser: B2BUser) => {
      this.tiresWarrantyForm.controls.dealerData.get('companyName').patchValue(b2bUser.orgUnit.name);
      this.tiresWarrantyForm.controls.dealerData.get('customerNumber').patchValue(b2bUser.orgUnit.uid);
      this.tiresWarrantyForm.controls.dealerData.get('email').patchValue(b2bUser.email);
    }));

    this.disableFutureDates();
  }

  onSubmit(): void {
    Object.values(this.tiresWarrantyForm.controls).forEach((nestedForm: UntypedFormGroup | UntypedFormControl) => {
      if (nestedForm instanceof UntypedFormGroup) {
        Object.values(nestedForm.controls).forEach((control: UntypedFormControl) => {
          control.markAsTouched();
        });
      } else {
        nestedForm.markAsTouched();
      }


    });
    if (this.tiresWarrantyForm.invalid) {
      return;
    }

    const dateStruct = this.tiresWarrantyForm.controls.tireCarData.get('installatonDate').value;
    const installationDate: Date = dateStruct ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : undefined;


    const tiresWarrantyRequest: TiresWarrantyRequest = {
      dealerData: {
        companyName: this.tiresWarrantyForm.get('dealerData.companyName').value,
        customerNumber: this.tiresWarrantyForm.get('dealerData.customerNumber').value,
        street: this.tiresWarrantyForm.get('dealerData.street').value,
        streetnumber: this.tiresWarrantyForm.get('dealerData.streetnumber').value,
        countryIsoCode: this.tiresWarrantyForm.get('dealerData.countryIsoCode').value,
        postalCode: this.tiresWarrantyForm.get('dealerData.postalCode').value,
        town: this.tiresWarrantyForm.get('dealerData.town').value,
        phone: this.tiresWarrantyForm.get('dealerData.phone').value,
        email: this.tiresWarrantyForm.get('dealerData.email').value,
        referenceNumber: this.tiresWarrantyForm.get('dealerData.referenceNumber').value,
        referenceTypeCode: this.tiresWarrantyForm.get('dealerData.referenceTypeCode').value,
        invoiceDocument: this.invoiceDocument

      },
      tireCarData: {
        installatonDate: this.datePipe.transform(installationDate, DATE_FORMAT),
        tireBrand: this.tiresWarrantyForm.get('tireCarData.tireBrand').value,
        quantity: +this.tiresWarrantyForm.get('tireCarData.quantity').value,
        tireSize: this.tiresWarrantyForm.get('tireCarData.tireSize').value,
        tireProfile: this.tiresWarrantyForm.get('tireCarData.tireProfile').value,
        carBrand: this.tiresWarrantyForm.get('tireCarData.carBrand').value,
        numberPlate: this.tiresWarrantyForm.get('tireCarData.numberPlate').value,
        totalKilometers: this.tiresWarrantyForm.get('tireCarData.totalKilometers').value
      },
      customerData: {
        titleCode: this.tiresWarrantyForm.get('customerData.titleCode').value,
        name: this.tiresWarrantyForm.get('customerData.name').value,
        street: this.tiresWarrantyForm.get('customerData.street').value,
        streetnumber: this.tiresWarrantyForm.get('customerData.streetnumber').value,
        countryIsoCode: this.tiresWarrantyForm.get('customerData.countryIsoCode').value,
        postalCode: this.tiresWarrantyForm.get('customerData.postalCode').value,
        town: this.tiresWarrantyForm.get('customerData.town').value,
        phone: this.tiresWarrantyForm.get('customerData.phone').value
      }
    };

    this.tiresWarrantyService.startTiresWarranty(tiresWarrantyRequest);
    this.subscription.add(
      this.tiresWarrantyService.sucess().subscribe((succes: boolean) => {
        if (succes) {
          this.resetFormFields();
          this.showWarrantyProcessSuccessMessage();
        }
      })
    );

  }

  private exceedMaxFileSize(file: File, documentTypeId: string): boolean {
    if (file.size === 0) {
      this.tiresWarrantyForm.controls.dealerData.get('invoiceDocument').setErrors({
        fileEmpty: true
      });

    } else if (exceedMaxFileSize(file, this.maxFileSize)) {
      this.tiresWarrantyForm.controls.dealerData.get('invoiceDocument').setErrors({
        maxFileSizeExceeded: {
          maxFileSize: this.config.maxFileSize.warrantyProcessForm
        }
      });
      return true;
    }
    return false;
  }

  private readDocument(file: File, documentTypeId: string): void {
    const fr: FileReader = new FileReader();
    this.readDocumentPriv(file, documentTypeId, fr, () => {
      const self = this;
      this.invoiceDocument = this.createDocument(fr, file);
      document.getElementById(documentTypeId).innerHTML =
        file.name;
    });
  }

  private readDocumentPriv(file: File,
                           documentTypeId: string,
                           fr: FileReader,
                           func: () => void): void {
    fr.onloadend = func;
    fr.readAsBinaryString(file);
  }

  private createDocument(fr: FileReader, file: File): InvoiceDocument {
    return {
      encodedContent: btoa(fr.result.toString()),
      fileName: file.name,
    };
  }

  public onInvoiceDocumentChange(event: any, documentTypeId: string): void {
    const fileList: FileList = event.target.files;
    if (this.exceedMaxFileSize(fileList[0], documentTypeId)) {
      return;
    }

    this.readDocument(fileList[0], documentTypeId);

  }

  public removeSelectedFile(documentTypeId: string): void {
    const uploadTranslationKey = 'uploadFile.placeholder';
    this.invoiceDocument = undefined;
    this.subscription.add(
      this.translationService.translate(uploadTranslationKey).subscribe((value: string) => {

        document.getElementById(documentTypeId).innerHTML = PDFIcon + value;
        this.tiresWarrantyForm.controls.dealerData.get('invoiceDocument').reset();
        this.tiresWarrantyForm.controls.dealerData.get('invoiceDocument').markAsTouched();
      })
    );
  }

  private resetFormFields(): void {
    this.tiresWarrantyForm.reset();
    this.tiresWarrantyForm.controls.dealerData.get('countryIsoCode').patchValue('');
    this.tiresWarrantyForm.controls.customerData.get('countryIsoCode').patchValue('');
    this.invoiceDocument = undefined;
    this.subscription.add(
      combineLatest([
        this.translationService.translate(
          'tiresWarranty.tiresWarrantyForm.dealerData.invoiceDocument.placeholder'
        ),
        this.translationService.translate(
          'tiresWarranty.tiresWarrantyForm.dealerData.countryIsoCode.placeholder'
        ),
      ]).subscribe(
        ([
           singleFile,
           countryIsoCode,

         ]) => {
          document.getElementById('invoiceDocument').innerHTML = PDFIcon + singleFile as string;
          this.tiresWarrantyForm.controls.tireCarData.get('tireBrand').patchValue('');
        }
      ),
    );
    this.subscription.add(this.userAccountFacade.get().subscribe((b2bUser: B2BUser) => {
      this.tiresWarrantyForm.controls.dealerData.get('companyName').patchValue(b2bUser.orgUnit.name);
      this.tiresWarrantyForm.controls.dealerData.get('customerNumber').patchValue(b2bUser.orgUnit.uid);
      this.tiresWarrantyForm.controls.dealerData.get('email').patchValue(b2bUser.email);
    }));
  }

  private showWarrantyProcessSuccessMessage(): void {
    this.globalMessageService.add(
      {
        key: 'tiresWarranty.globalMessage.startTiresWarrantySuccess',
      },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
  }

  getPath(anchorId: string): string {
    const currentUrlWithoutFragment = this.winRef.location.href.replace(
      /#.*$/,
      ''
    );
    return `${currentUrlWithoutFragment}${anchorId}`;
  }
  scrollToFooter(){
    this.scroller.scrollToAnchor("tiresWarrantyFooter")
  }

  scrollToForm(){
    this.scroller.scrollToAnchor("tiresWarrantyForm")
  }

  disableFutureDates(): void {
    this.maxDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear(),
    };
  }

  clearForm(): void {
    this.resetFormFields();
  }

  getSelectPlaceholderTextClass(value: string): string {
    return value === '' ? 'placeholder-text' : '';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
