export const deliveryNotes = {
  deliveryNotesCustom: {
    globalMessage: {
      downloadContentEmpty: 'Für den ausgewählten Lieferschein ist kein PDF-Dokument hinterlegt.',
      loadingDeliveryNotesOverviewFailure: 'Beim Öffnen der Lieferscheinübersicht ist ein Fehler aufgetreten.'
    },
    deliveryNotesFilterForm: {
      deliveryNoteNumber: {
        label: 'Lieferscheinnummer',
        placeholder: 'Lieferscheinnummer'
      },
      consignmentInfo: {
        label: 'Ihre zusätzliche Information',
        placeholder: 'Ihre zusätzliche Information'
      },
      oemNumber: {
        label: 'Artikelnummer',
        placeholder: 'Artikelnummer'
      },
      orderNumber: {
        label: 'Auftragsnummer',
        placeholder: 'Auftragsnummer'
      },
      dateFrom: {
        label: 'Datum von',
        placeholder: 'Datum von'
      },
      dateTo: {
        label: 'Datum bis',
        placeholder: 'Datum bis'
      }
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Lieferscheine sortieren',
      deliveryNoteNumber: 'Lieferscheinnummer',
      warehouseName: 'Lieferlager',
      creationDate: 'Lieferdatum',
      select: 'Select',
      download:'Herunterladen',
      selectAll: 'Alle auswählen',
    },
    deliveryNotesDetails: {
      positionNumber: 'Positions-Id',
      oemNumber: 'OEM-Nr.',
      deliveredQuantity: 'Menge',
      consignmentInfo: 'Kommissionstext',
      deliveryDate: 'Lieferdatum',
      orderNumber: 'Auftragsnummer',
      returnableQuantity: 'Retournierbare Menge',
      returnProducts: 'Produkte retournieren',
    },
    deliveryNotesTitle:'Lieferscheinpositionen',
    deliveryNoteNumber: 'Ihre Belegnummer',
    creationDate: 'Belegdatum',
    warehouseName: 'Lieferlager',
    receiverAddress: 'Warenempfänger',
    deliveryNotesOverview: {
      downloadDeliveryNoteDocumentFileNamePrefix: 'Lieferschein',
      downloadDeliveryNoteDocumentsCompressedFileNamePrefix: 'Lieferscheine',
    }
  }
};
