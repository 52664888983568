import {Component, OnInit} from '@angular/core';
import {GlobalMessageComponent} from '@spartacus/storefront';

@Component({
  selector: 'app-efa-global-message',
  templateUrl: './efa-global-message.component.html',
  styleUrls: ['./efa-global-message.component.scss']
})
export class EfaGlobalMessageComponent extends GlobalMessageComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

}
