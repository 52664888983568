import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  OrderInfoField,
  OrderInfoFieldValue
} from '../../../cart-administration/model/cart-administration.model';
import {
  SetCartEntryInfosService
} from '../../../cart-administration/services/set-cart-entry-infos.service';
import {
  OrderInfoFieldsFormsValidationService
} from '../../../checkout-order/service/order-info-fields-forms-validation.service';

interface CustomFormControl {
  key: string;
  shopText: string;
  orderInfoFieldValue: OrderInfoFieldValue;
  placeholderText: string;
  toolTipText: string;
  inputType: string;
}

const LEFT_PARENTHESIS = '(';
const RIGHT_PARENTHESIS = ')';
const OR_CONJUNCTION = '|';
const ORDER_ENTRY_INFO_FIELDS_FORM_KEY_SUFFIX = '_ORDER_ENTRY_INFO_FIELDS';

@Component({
  selector: 'app-order-info-fields',
  templateUrl: './order-info-fields.component.html',
})
export class OrderInfoFieldsComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() orderInfoFields: OrderInfoFieldValue[] = [];
  @Input() entryNumber: number;
  @Input() entryKey: string;
  @Output() OrderInfoFieldValueChange: EventEmitter<OrderInfoFieldValue> = new EventEmitter<OrderInfoFieldValue>();

  form: UntypedFormGroup;
  customFormControls: CustomFormControl[] = [];
  cartEntryInfosUpdating$: Observable<boolean> = this.setCartEntryInfosService.getIsUpdating();
  currentCartEntryNumberUpdating$: Observable<number> = this.setCartEntryInfosService.getEntryNumber();
  currentCartEntryOrderInfoFieldIdUpdating$: Observable<string> = this.setCartEntryInfosService.getOrderInfoFieldValues().pipe(filter(Boolean), map((oifvs: OrderInfoFieldValue[]) => oifvs.length > 0 ? oifvs[0]?.orderInfoField?.fieldId : undefined));
  currentCartEntryInfoTypeUpdating$: Observable<string> = this.setCartEntryInfosService.getCartEntryInfoType();

  private formIndex: number;

  constructor(
    private fb: UntypedFormBuilder,
    private orderInfoFieldsFormsValidationService: OrderInfoFieldsFormsValidationService,
    private setCartEntryInfosService: SetCartEntryInfosService,
  ) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  orderEntryFieldsChange(event: Event, orderInfoField: OrderInfoField, formControl: AbstractControl): void {
    const target = event.target as HTMLInputElement;
    const object = {
      orderInfoField,
      value: target.value,
    };
    if (formControl.status === 'VALID') {
      this.OrderInfoFieldValueChange.emit(object);
    }
  }

  trackByFn(index: number, _value: any): number {
    return index;
  }

  private createForm(): void {
    this.form = this.fb.group([]);
    for (const orderInfoField of this.orderInfoFields) {
      let formControl: UntypedFormControl;
      const value = orderInfoField.value ? orderInfoField.value : '';
      formControl = new UntypedFormControl({value, disabled: this.readonly ? true : false});
      this.addOrderInfoFieldFormControlValidators(orderInfoField, formControl);
      this.customFormControls.push({
        key: orderInfoField.orderInfoField.fieldId,
        orderInfoFieldValue: orderInfoField,
        shopText: orderInfoField.orderInfoField.shopText,
        placeholderText: orderInfoField.orderInfoField.placeholderText?.length > 0 ? orderInfoField.orderInfoField.placeholderText : orderInfoField.orderInfoField.shopText,
        toolTipText: orderInfoField.orderInfoField.toolTipText?.length > 0 ? orderInfoField.orderInfoField.toolTipText : '',
        inputType: orderInfoField.orderInfoField.numeric ? 'number' : 'text',
      });
      this.form.addControl(orderInfoField.orderInfoField.fieldId, formControl);
    }
    this.orderInfoFieldsFormsValidationService.add(this.entryKey + ORDER_ENTRY_INFO_FIELDS_FORM_KEY_SUFFIX, this.form);
  }

  private addOrderInfoFieldFormControlValidators(orderInfoField: OrderInfoFieldValue, formControl: UntypedFormControl) {
    if (orderInfoField.orderInfoField.mandatory) {
      formControl.addValidators(Validators.required);
    }

    const validOrderInfoFieldLengthRange: boolean = this.validOrderInfoFieldLengthRange(orderInfoField);

    if (validOrderInfoFieldLengthRange) {
      if (orderInfoField.orderInfoField.numeric) {
        this.addNumericOrderInfoFieldFormControlValidators(orderInfoField, formControl);
      } else {
        this.addTextOrderInfoFieldFormControlValidators(orderInfoField, formControl);
      }
    }
  }

  private addTextOrderInfoFieldFormControlValidators(orderInfoField: OrderInfoFieldValue, formControl: UntypedFormControl) {
    if (orderInfoField.orderInfoField.exactLength) {
      formControl.addValidators([Validators.minLength(orderInfoField.orderInfoField.length)]);
    } 

    if (orderInfoField.orderInfoField.patterns?.length > 0) {
      const patternsInConjuctionForm: string = orderInfoField.orderInfoField.patterns.map((pattern: string) => LEFT_PARENTHESIS + pattern + RIGHT_PARENTHESIS).join(OR_CONJUNCTION);
      formControl.addValidators(Validators.pattern(patternsInConjuctionForm));
    }
  }

  private addNumericOrderInfoFieldFormControlValidators(orderInfoField: OrderInfoFieldValue, formControl: UntypedFormControl) {
    if (orderInfoField.orderInfoField.exactLength) {
      formControl.addValidators([Validators.max(this.getMaxNumberForNumericLength(orderInfoField.orderInfoField.length)), Validators.min(this.getMinNumberForNumericLength(orderInfoField.orderInfoField.length))]);
    } else {
      formControl.addValidators([Validators.max(this.getMaxNumberForNumericLength(orderInfoField.orderInfoField.length)), Validators.min(0)]);
    }
  }

  private validOrderInfoFieldLengthRange(orderInfoField: OrderInfoFieldValue): boolean {
    return orderInfoField.orderInfoField.length != null && orderInfoField.orderInfoField.length > 0;
  }

  private getMaxNumberForNumericLength(length: number): number {
    let nom = '9';
    for (let i = 0; i < length - 1; i++) {
      nom += '9';
    }
    return Number.parseInt(nom);
  }

  private getMinNumberForNumericLength(length: number): number {
    let nom = '1';
    for (let i = 0; i < length - 1; i++) {
      nom += '0';
    }
    return Number.parseInt(nom);
  }

  ngOnDestroy(): void {
      this.orderInfoFieldsFormsValidationService.remove(this.entryKey + ORDER_ENTRY_INFO_FIELDS_FORM_KEY_SUFFIX);
  }
}
