export const efaProductDetails = {
  eProductDetails: {
    deliveryTimeLabel: 'Lieferzeit',
    deliveryTimeText: 'Pro Stück:',
    deliveryTimeValue: 'Tag(e)',
    brand: 'Marke',
    goBack: {
      cart: 'zurück zum Warenkorb',
      order: 'zurück zum Auftrag',
      wishlist: 'zurück zur Merkliste',
      search: 'zurück zur Suche',
      category: 'zurück zur Übersicht',
      carousel: 'zurück',
      default: 'Fenster schließen',
    },
    description : {
      label: 'Beschreibung',
      showMore: 'Mehr Anzeigen..',
      showLess: 'Weniger Anzeigen'
    },
  }
};
