import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModules} from '@shared/shared.modules';
import {ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {FormErrorsModule, IconModule, MediaModule, PAGE_LAYOUT_HANDLER} from '@spartacus/storefront';
import {AddToCartModule} from '../add-to-cart/add-to-cart.module';
import {EfaWishListItemComponent} from './components/efa-wish-list-item/efa-wish-list-item.component';
import {EfaWishListComponent} from './components/efa-wish-list/efa-wish-list.component';
import {EfaWishListPageLayoutHandler} from './layout-handlers/efa-wish-list-page-layout-handler';
import {customWishListTranslationChunksConfig, customWishListTranslations,} from './translations/wish-list.translations';

@NgModule({
  declarations: [EfaWishListComponent, EfaWishListItemComponent],
  imports: [
    CommonModule,
    SharedModules,
    RouterModule,
    I18nModule,
    UrlModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    IconModule,
    MediaModule,
    AddToCartModule,
    ConfigModule.withConfig({
      i18n: {
        resources: customWishListTranslations,
        chunks: customWishListTranslationChunksConfig,
      },
    }),
    ConfigModule.withConfig({
      cmsComponents: {
        WishListComponent: {
          component: EfaWishListComponent,
        },
        WishListItemComponent: {
          component: EfaWishListItemComponent,
        },
      },
    }),
  ],
  providers: [
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: EfaWishListPageLayoutHandler,
      multi: true,
    },
  ],
})
export class WishListModule {}
