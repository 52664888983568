import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ReturnOverviewAdapter } from '../adapters/return-overview.adapter';
import { ReturnOverviewRequest, ReturnOverviewResponse } from '../../model/return-history.model';

@Injectable({
  providedIn: 'root',
})
export class ReturnOverviewConnector {
  constructor(protected adapter: ReturnOverviewAdapter) {}

  loadOverview(request: ReturnOverviewRequest): Observable<ReturnOverviewResponse> {
    return this.adapter.loadOverview(request);
  }
}
