import { Injectable } from '@angular/core';
import { B2BUser } from '@spartacus/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { B2BWebShopPermission } from '../model/user-profiles.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionCheckService {
  constructor(public userFacade: UserAccountFacade) {
  }

  isAllowed(permissionId: string): Observable<boolean> {
    return this.userFacade.get().pipe(
      filter(user => Boolean(user)),
      map((user: User) => user.permissionIds.indexOf(permissionId) !== -1));
  }

  displayCustomerPrices(): Observable<boolean> {
    return this.userFacade.get().pipe(
      filter(user => Boolean(user)),
      map((user: User) => (user.permissionIds.indexOf(B2BWebShopPermission.VIEW_PRICES) !== -1)
        && (user as B2BUser).orgUnit.discountVisible !== false));
  }

  canPlaceOrders(): Observable<boolean> {
    return this.isAllowed(B2BWebShopPermission.PLACE_ORDERS);
  }
}
