import {Injectable} from '@angular/core';
import {B2BUser, Converter, Occ} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class EfaOccB2BUserNormalizer implements Converter<Occ.B2BUser, B2BUser> {
  constructor() {
  }

  convert(source: Occ.B2BUser, target?: B2BUser): B2BUser {
    if (target === undefined) {
      target = {...(source as any)};
    }
    if (target.email === undefined || target.email.trim().length === 0) {
      target.email = source?.uid;
    }
    // the default normalizer is called afterwards anyway and would overwrite the target.email by the source.uid
    // therefore we 'fix' it before
    if (source != null) {
      source.uid = target.email;
    }
    return target;
  }
}
