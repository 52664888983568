export const partsLinkAccess = {
  partsLinkAccess: {
    globalMessage: {
      startPartsLinkAccessFailure: 'Die Daten für den Testzugang konnten nicht übertragen werden. Bitte versuchen Sie es erneut.',
      startPartsLinkAccessSuccess: 'Vielen Dank! Ihre Daten wurden erfolgreich übertragen. Eine E-Mail mit allen Informationen wurde an die von Ihnen angegebene E-Mail-Adresse versendet.'
    },
    partsLinkAccessForm: {
      formTitle: '14-Tage-Testzugang mit vollem Funktionsumfang beantragen',
      formMandatoryFields: 'Pflichtfelder sind mit einem * gekennzeichnet.',
      legend1: '1. Firmendaten',
      legend2: '2. Ansprechpartner',
      submit: 'Absenden',
      reset: 'Zurücksetzen',
      dealerData: {
        customerNumber: {
          lable: 'EFA Kundennummer',
          placeholder: 'Kundennummer',
        },
        companyName: {
          lable: 'Firma',
          placeholder: 'Ihr Firmenname',
        },
        street: {
          lable: 'Straße',
          placeholder: 'Straße',
        },
        streetnumber: {
          lable: 'Hausnummer',
          placeholder: 'Hausnummer',
        },
        countryIsoCode: {
          lable: 'Land',
          placeholder: 'Land auswählen',
        },
        postalCode: {
          lable: 'PLZ',
          placeholder: 'PLZ',
        },
        town: {
          lable: 'Ort',
          placeholder: 'Ort',
        },
        email: {
          lable: 'E-Mail Adresse',
          placeholder: 'E-Mail Adresse',
        },
      },
      customerData: {
        titleCode: {
          lable: 'Anrede',
          gender: {
            MALE: 'Herr',
            FEMALE: 'Frau',
            OTHER: 'Divers',
          },
        },
        name: {
          lable: 'Vor- und Nachname / Firmenname',
          placeholder: 'Ihr Vor- und Nachname oder Firmenname',
        },
      },
    },
  },
};
