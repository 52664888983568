import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TiresWarrantyRequest } from "../../model/tires-warranty.model";
import { TiresWarrantyAdapter } from "../adapters/tires-warranty.adapter";

@Injectable({
  providedIn: 'root'
})

export class TiresWarrantyConnector {
  constructor(protected adapter: TiresWarrantyAdapter) {}

  startTiresWarranty (request : TiresWarrantyRequest): Observable<void> {
    return this.adapter.startTiresWarranty(request);
  }
}
