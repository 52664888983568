import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  provideDefaultConfig,
} from '@spartacus/core';
import {
  GenericLinkModule,
  NavigationEventModule,
} from '@spartacus/storefront';
import { EfaFooterNavigationComponent } from './components/efa-footer-navigation/efa-footer-navigation.component';
import { NavigationModule } from '../navigation/navigation.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NavigationModule,
    NavigationEventModule,
    GenericLinkModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FooterNavigationComponent: {
          component: EfaFooterNavigationComponent,
        },
      },
    }),
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        FooterNavigationComponent: {
          component: EfaFooterNavigationComponent,
        },
      },
    }),
  ],
  declarations: [EfaFooterNavigationComponent],
  exports: [EfaFooterNavigationComponent],
})
export class FooterNavigationModule {}
