import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllowedFileExtensionsResponse } from '@shared/models/shared.model';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AllowedFileExtensionsAdapter } from '../allowed-file-extensions.adapter';

@Injectable({
  providedIn: 'root',
})
export class OccAllowedFileExtensionsAdapter implements AllowedFileExtensionsAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  loadAllowedFileExtensions(contextId: string): Observable<AllowedFileExtensionsResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl('allowedFileExtensions', {
      urlParams: { contextId },
    });

    return this.http.get<AllowedFileExtensionsResponse>(url, { headers });
  }
}
