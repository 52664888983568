import {Component, Input} from '@angular/core';
import {ImportMode} from "../../model/import-export-enum.model";

@Component({
  selector: 'app-cart-import-user-manual',
  templateUrl: './cart-import-user-manual.component.html',
})
export class CartImportUserManualComponent {
  @Input()
  importMode: ImportMode;
}
