import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  ShippingBacklogOverviewRequest,
  ShippingBacklogOverviewResponse,
} from '../../../model/shipping-backlog.model';
import { ShippingBacklogOverviewAdapter } from '../shipping-backlog-overview.adapter';

@Injectable({
  providedIn: 'root',
})
export class OccShippingBacklogOverviewAdapter
  implements ShippingBacklogOverviewAdapter
{
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  loadOverview(
    request: ShippingBacklogOverviewRequest
  ): Observable<ShippingBacklogOverviewResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl('shippingBacklogOverview', {
      urlParams: {
        userId: 'current',
      },
    });

    return this.http.post<ShippingBacklogOverviewResponse>(url, request, {
      headers,
    });
  }
}
