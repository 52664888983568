<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form
  *ngIf="token$ | async as token"
  (ngSubmit)="onSubmit(token)"
  [formGroup]="form"
>
  <label>
    <span class="label-content">{{
      'register.newPassword' | cxTranslate
      }}</span>
    <input
      aria-required="true"
      class="form-control"
      type="password"
      placeholder="{{ 'register.password.placeholder' | cxTranslate }}"
      formControlName="password"
    />
    <cx-form-errors
      aria-live="assertive"
      aria-atomic="true"
      [control]="form.get('password')"
    ></cx-form-errors>
  </label>
  <br>
  <span class="label-content">{{
    'register.passwordMinRequirements' | cxTranslate
    }}</span>
  <label>
    <br>
    <span class="label-content">{{
      'register.confirmPassword.label' | cxTranslate
      }}</span>
    <input
      aria-required="true"
      class="form-control"
      type="password"
      placeholder="{{ 'register.confirmPassword.placeholder' | cxTranslate }}"
      formControlName="passwordConfirm"
    />
    <cx-form-errors
      aria-live="assertive"
      aria-atomic="true"
      [control]="form.get('passwordConfirm')"
    ></cx-form-errors>
  </label>

  <button class="btn btn-block btn-primary" [disabled]="form.disabled">
    {{ 'register.resetPassword' | cxTranslate }}
  </button>
</form>
