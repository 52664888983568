import {Injectable} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';

import {LoginFormComponentService} from '@spartacus/user/account/components';
import {AuthService, B2BUser, EventService, RoutingService, WindowRef} from '@spartacus/core';
import {from, Subscription} from 'rxjs';
import {filter, tap, withLatestFrom} from 'rxjs/operators';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {padStart} from '../utils/login-register.utils';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {TermsConfirmationEvent} from '../model/login-register.model';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable({
  providedIn: 'root'
})
export class EfaLoginFormComponentService extends LoginFormComponentService {
  dialogModalRef: NgbModalRef;
  subscription: Subscription = new Subscription();


  constructor(
    protected auth: AuthService,
    protected globalMessage: EfaGlobalMessageService,
    protected winRef: WindowRef,
    private userFacade: UserAccountFacade,
    private routingService: RoutingService,
    private eventService: EventService,
  ) {
    super(auth, globalMessage, winRef);
    this.form.addControl('partnerId', new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(10)
    ]));
  }


  login(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    from(
      this.auth.loginWithCredentials(
        padStart(this.form.value.partnerId) + '_' + this.form.value.userId.toLowerCase(),
        this.form.value.password
      )
    ).pipe(
      withLatestFrom(this.auth.isUserLoggedIn()),
      tap(([_, isLoggedIn]) => this.onMySuccess(isLoggedIn))
    ).subscribe();
  }

  onMySuccess(isLoggedIn: boolean): void {
    super.onSuccess(isLoggedIn);
    if (isLoggedIn) {
      this.subscription.add(this.userFacade.get().pipe(filter(Boolean)).subscribe((user: B2BUser) => {
        if (!user.termsAccepted) {
           this.routingService.go({
             cxRoute: 'termsAndConditionsConfirmation'
           });
        }
        else {
          this.eventService.dispatch(new TermsConfirmationEvent(true));
        }
      }));
    }
  }
}
