import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { MultiAddToCartAdapter } from '../adapters/multi-add-to-cart.adapter';
import { AddToCartParam, CartModificationList } from '../../model/cart-administration.model';

@Injectable({providedIn: 'root'})
export class MultiAddToCartConnector {
  constructor(protected adapter: MultiAddToCartAdapter) {}

  addEntries(
    entries: AddToCartParam[],
    userId: string,
    cartId: string
  ): Observable<CartModificationList> {
    return this.adapter.addEntries(entries, userId, cartId);
  }
}
