import { TranslationChunksConfig } from "@spartacus/core";
import { de } from "./de/index";
import { en } from "./en/index";

export const partsLinkAccessTranslations = {
  de,
  en
};

export const partsLinkAccessTranslationChunksConfig: TranslationChunksConfig = {
  partsLinkAccess: [
    'partsLinkAccess',
  ],
};
