import { createReducer, on } from '@ngrx/store';

import { OrderCancelActions } from '../actions';
import { OrderCancelResponse } from '../../model/order-cancel.model';
import { CancellationRequestEntryInputList } from '@spartacus/order/root';

export const ORDER_CANCEL_FEATURE_KEY = 'orderCancel';

export interface OrderCancelState {
  orderNumber: string;
  positions: CancellationRequestEntryInputList;
  cancelling: boolean;
  success: boolean;
  response: OrderCancelResponse;
}

export const initialStateOrderCancel: OrderCancelState = {
  orderNumber: null,
  positions: undefined,
  cancelling: false,
  success: false,
  response: undefined
};

export const OrderCancelReducer = createReducer(
  initialStateOrderCancel,

  on(OrderCancelActions.orderCancellation, (state, action) => ({
    ...state,
    orderNumber: action.orderNumber,
    positions: action.positions,
    cancelling: true,
    success: false
  })),
  on(OrderCancelActions.orderCancellationSuccess, (state, action) => ({
    ...state,
    cancelling: false,
    success: true,
    response: action.response,
  })),
  on(OrderCancelActions.orderCancellationFailure, (state, action) => ({
    ...state,
    success: false,
    cancelling: false,
    error: action.errorResponse,
    response: undefined,
  })),
  on(OrderCancelActions.resetOrderCancellation, () => ({
    ...initialStateOrderCancel,
  })),
);
