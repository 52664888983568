<ng-container *ngIf="product">
  <ng-container *ngIf="userLoggedIn$ | async; else loginPrompt">
    <ng-container *ngIf="wishListEntries$ | async as entries">
        <div *ngIf="!getProductInWishList(product, entries) as entry">
          <div *ngIf="IsAddedFromSearchPage;else addedFromCart">
            <cx-icon class="u-pointer" (click)="add(product)" [disabled]="loading$ | async"
            [type]="sharedIconTypes.EMPTY_BLUE_HEART"
            ngbTooltip=""
            placement="top"></cx-icon>
          </div>

          <ng-template #addedFromCart>
            <cx-icon class="u-pointer" (click)="add(product)" [disabled]="loading$ | async"
            [type]="iconTypes.EMPTY_HEART"
            ngbTooltip=""
            placement="top"></cx-icon>
          </ng-template>

        </div>
        <div *ngIf="getProductInWishList(product, entries) as entry">
          <div *ngIf="IsAddedFromSearchPage;else addedFromCart">
            <cx-icon class="u-pointer" (click)="remove(entry)" [disabled]="loading$ | async"
            [type]="sharedIconTypes.BLUE_HEART"
            ngbTooltip=""
            placement="top"></cx-icon>
          </div>

          <ng-template #addedFromCart>
            <cx-icon class="u-pointer" (click)="remove(entry)" [disabled]="loading$ | async"
            [type]="iconTypes.HEART"
            ngbTooltip=""
            placement="top"></cx-icon>
          </ng-template>

        </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loginPrompt>
  <ng-container *ngIf="hasStock">
    <a class="btn btn-link button-add-link cx-action-link" [routerLink]="{ cxRoute: 'login' } | cxUrl">
      <cx-icon [type]="iconTypes.EMPTY_HEART"></cx-icon>
    </a>
  </ng-container>
</ng-template>
