import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModules } from '@shared/shared.modules';
import { ConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, IconModule, KeyboardFocusModule, SpinnerModule } from '@spartacus/storefront';
import { defaultCartExportLayoutConfig } from './cart-export-layout-config';
import { defaultCartImportLayoutConfig } from './cart-import-layout-config';
import { defaultCartImportSelectionLayoutConfig } from './cart-import-selection-layout-config';
import { CartExportDialogComponent } from './components/cart-export-dialog/cart-export-dialog.component';
import { CartExportComponent } from './components/cart-export/cart-export.component';
import { CartImportActionComponent } from './components/cart-import-action/cart-import-action.component';
import { CartImportMicrocatActionComponent } from './components/cart-import-microcat-action/cart-import-microcat-action.component';
import { CartImportSelectionComponent } from './components/cart-import-selection/cart-import-selection.component';
import { CartImportUserManualComponent } from './components/cart-import-user-manual/cart-import-user-manual.component';
import { CartImportComponent } from './components/cart-import/cart-import.component';
import { importExportTranslationChunksConfig, importExportTranslations } from './translations/import-export.translations';

@NgModule({
  declarations: [CartExportComponent,
    CartImportComponent,
    CartImportActionComponent,
    CartImportSelectionComponent,
    CartExportDialogComponent,
    CartImportUserManualComponent,
    CartImportMicrocatActionComponent],
  exports: [
    CartExportComponent, CartImportComponent,  CartImportActionComponent, CartImportMicrocatActionComponent
  ],
  imports: [
    CommonModule,
    SharedModules,
    IconModule,
    I18nModule,
    FormErrorsModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerModule,
    KeyboardFocusModule,
    ConfigModule.withConfig({
      i18n: {
        resources: importExportTranslations,
        chunks: importExportTranslationChunksConfig
      },
      icon: {
        symbols: {
          SPINNER: 'fas fa-spinner fa-spin',
//          DOWNLOAD: 'fas fa-download',
//          REMOVE: 'fas fa-trash-alt',
//          UPLOAD: 'fas fa-upload',
          CHECK: 'fas fa-check',
          INFO: 'fas fa-info-circle',
        },
      },
    }),
    NgbDropdownModule,
  ],
  providers: [
    provideConfig(defaultCartExportLayoutConfig),
    provideConfig(defaultCartImportLayoutConfig),
    provideConfig(defaultCartImportSelectionLayoutConfig),
    // provideOutlet({
    //   id: CartTotalsOutlets.ACTIONS,
    //   position: OutletPosition.REPLACE,
    //   component: CartExportComponent,
    // }),
  ]
})
export class ImportExportModule { }
