import { Config } from '@spartacus/core';

export abstract class FunctionsConfig {
  displayRequestedDeliveryDate?: boolean;
  manufacturersSelectionDefaultManufacturerGroupCode?: string;
}

export const defaultFunctionsConfig: FunctionsConfig = {
  displayRequestedDeliveryDate: false,
  manufacturersSelectionDefaultManufacturerGroupCode: 'CAR',
};

declare module '@spartacus/core' {
  interface Config extends FunctionsConfig {}
}
