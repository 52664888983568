import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';

import {of} from 'rxjs';
import {catchError, concatMap, map, withLatestFrom} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GlobalMessageType, PaginationModel, SortModel} from '@spartacus/core';
import {select, Store} from '@ngrx/store';

import {ReturnOverviewActions} from '../actions';
import {ErrorType} from '@shared/models/ErrorTypes';
import {ReturnOverviewRequest, ReturnOverviewResponse} from '../../model/return-history.model';
import {ReturnOverviewConnector} from '../../occ/connectors/return-overview.connector';
import {ReturnHistoryUiState} from '../reducers/return-history-ui.reducer';
import {selectResponse} from '../selectors/return-overview.selectors';
import {selectPagination, selectSort} from '../selectors/return-history-ui.selectors';
import {ReturnOverviewState} from '../reducers/return-overview.reducer';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class ReturnOverviewEffect {
  oldPagination: PaginationModel;
  oldSort: SortModel;
  currentDate: Date = new Date();

  loadReturnOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReturnOverviewActions.loadReturnOverview),
      withLatestFrom(
        this.store.pipe(select(selectResponse)),
        this.store.pipe(select(selectPagination)),
        this.store.pipe(select(selectSort)),
      ),
      concatMap(([action, returnOverview, pagination, sort]) => {
        this.setOldFilterData(pagination, sort);
        if (returnOverview !== undefined && !this.hasRequestChanged(action.request)) {
          return of(ReturnOverviewActions.loadReturnOverviewSuccess({
            response: returnOverview
          }));
        } else {
          return this.connector.loadOverview(action.request).pipe(
            map((returnOverviewResponse: ReturnOverviewResponse) =>
              ReturnOverviewActions.loadReturnOverviewSuccess({
                response: returnOverviewResponse
              })),
            catchError((error: ErrorType) => {
              this.globalMessageService.add(
                {
                  key: 'returnOverview.globalMessage.loadingReturnsOverviewFailure',
                },
                GlobalMessageType.MSG_TYPE_ERROR
              );
              return of(ReturnOverviewActions.loadReturnOverviewFailure({errorResponse: error}));
            })
          );
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private connector: ReturnOverviewConnector,
    private store: Store<ReturnOverviewState | ReturnHistoryUiState>,
    private datePipe: DatePipe,
    private globalMessageService: EfaGlobalMessageService,
  ) {
  }

  private setOldFilterData(pagination: PaginationModel, sort: SortModel): void {
    this.oldPagination = pagination;
    this.oldSort = sort;
  }

  private hasRequestChanged(request: ReturnOverviewRequest): boolean {
    const oldReturnOverviewRequest: ReturnOverviewRequest = {
      deliveryNoteNumber: request.deliveryNoteNumber,
      returnNumber: request.returnNumber,
      statusCode: request.statusCode,
      dateFrom: this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'),
      dateTo: this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'),
      sort: this.oldSort,
      pagination: this.oldPagination,
    };
    return JSON.stringify(request) !== JSON.stringify(oldReturnOverviewRequest);
  }

}
