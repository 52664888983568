<app-order-overview-filter (orderOverviewFilterChanges)="setOrderOverviewFilter($event)"
                           [availableOrderStates]="(orders$ | async)?.availableOrderStates"></app-order-overview-filter>

<ng-container *ngIf="{
    isLoading: isLoading$ | async,
    orderHistory: orders$ | async
  } as type">
  <ng-container *ngIf="type.orderHistory && !type.isLoading; else loading">
    <!-- BODY -->
    <div class="cx-order-history-body">
      <ng-container *ngIf="type.orderHistory.pagination.totalResults > 0; else noResult">
        <!-- Select Form and Pagination Top -->
        <div class="cx-order-history-sort top row">
          <div class="cx-order-history-form-group form-group col-sm-12 col-md-4 col-lg-4">
            <app-efa-order-sorting [sortOptions]="type.orderHistory.sorts"
                                   [sortLabels]="getSortLabels() | async"
                                   (sortListEvent)="changeSortCode($event)"
                                   [selectedOption]="type.orderHistory.pagination.sort"
                                   placeholder="{{ 'orderHistoryCustom.myAccount.sorting' | cxTranslate }}"></app-efa-order-sorting>
          </div>
          <div *ngIf="type.orderHistory.pagination.totalPages > 1"
               class="cx-order-history-pagination col-sm-12 col-md-4 col-lg-4">
            <cx-pagination [pagination]="type.orderHistory.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
        <!-- TABLE -->
        <table class="table cx-order-history-table">
          <thead class="cx-order-history-thead-mobile">
          <th scope="col">{{ 'orderHistoryCustom.myAccount.sapOrderNumber' | cxTranslate }}</th>
          <th scope="col">{{ 'orderHistoryCustom.myAccount.consignmentInfo' | cxTranslate }}</th>
          <th scope="col">{{ 'orderHistoryCustom.myAccount.statusDisplay' | cxTranslate }}</th>
          <th scope="col">{{ 'orderHistoryCustom.myAccount.originName' | cxTranslate }}</th>
          <th scope="col">{{ 'orderHistoryCustom.myAccount.placed' | cxTranslate }}</th>
          <th scope="col">{{ 'orderHistoryCustom.myAccount.orderTypeName' | cxTranslate }}</th>
          <th scope="col">{{ 'orderHistoryCustom.myAccount.receiverAddressId' | cxTranslate }}</th>
          <th scope="col">{{ 'orderHistoryCustom.myAccount.total' | cxTranslate }}</th>
          </thead>
          <tbody>
          <tr *ngFor="let order of type.orderHistory.orders">
            <td attr.data-label="{{ 'orderHistoryCustom.myAccount.sapOrderNumber' | cxTranslate }}"
                class="cx-order-history-code">
              <a
                [routerLink]="{ cxRoute: 'orderDetails', params: {code: order?.sapOrderNumber}}| cxUrl">
                {{ order?.sapOrderNumber }}
              </a>
            </td>
            <td attr.data-label="{{ 'orderHistoryCustom.myAccount.consignmentInfo' | cxTranslate }}"
                class="c-order-history-consignment-info">
              <span class="c-order-history-limited-text">{{ order?.consignmentInfo }}</span>
            </td>
            <td attr.data-label="{{ 'orderHistoryCustom.myAccount.statusDisplay' | cxTranslate }}"
                class="cx-order-history-status">
              {{ order?.statusDisplay }}
            </td>
            <td attr.data-label="{{ 'orderHistoryCustom.myAccount.originName' | cxTranslate }}"
                class="c-order-history-origin-name">
              {{ order?.originName }}
            </td>
            <td attr.data-label="{{ 'orderHistoryCustom.myAccount.placed' | cxTranslate }}"
                class="cx-order-history-placed">
              {{ order?.placed | date: 'dd.MM.yyyy' }}
            </td>
            <td attr.data-label="{{ 'orderHistoryCustom.myAccount.orderTypeName' | cxTranslate }}"
                class="c-order-history-orderType-name u-white-space-nowrap">
              <span class="c-order-history-limited-text">{{ order?.orderType?.name }}</span>
            </td>
            <td
              attr.data-label="{{ 'orderHistoryCustom.myAccount.receiverAddressId' | cxTranslate }}">
              {{ order?.receiverAddressId }}
            </td>
            <td attr.data-label="{{ 'orderHistoryCustom.myAccount.total' | cxTranslate }}"
                class="cx-order-history-total">
              {{ order?.total.formattedValue }}
            </td>
          </tr>
          </tbody>
        </table>
        <!-- Select Form and Pagination Bottom -->
        <div class="cx-order-history-sort bottom row">
          <div *ngIf="type.orderHistory.pagination.totalPages > 1"
               class="cx-order-history-pagination">
            <cx-pagination [pagination]="type.orderHistory.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
      </ng-container>

      <!-- NO RESULT CONTAINER -->
      <ng-template #noResult>
        <app-no-display-result-notice
          noticeHeadline="{{ 'filteredDocumentsResult.noResult' | cxTranslate }}"></app-no-display-result-notice>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #loading>
    <ng-container *ngIf="type.isLoading">
      <div class="cx-spinner">
        <cx-spinner></cx-spinner>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
