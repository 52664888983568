import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EfaBannerComponent } from './components/efa-banner/efa-banner.component';
import { RouterModule } from '@angular/router';
import { ImageLoadingStrategy, MediaConfig, MediaModule } from '@spartacus/storefront';
import { ConfigModule } from '@spartacus/core';
import { EfaGenericLinkComponent } from './components/efa-generic-link/efa-generic-link.component';
import { EfaMediaComponent } from './components/efa-media/efa-media.component';



@NgModule({
  declarations: [
    EfaBannerComponent,
    EfaGenericLinkComponent,
    EfaMediaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SimpleResponsiveBannerComponent: {
          component: EfaBannerComponent,
        },
        BannerComponent: {
          component: EfaBannerComponent,
        },
        SimpleBannerComponent: {
          component: EfaBannerComponent,
        },
      },
    }),
    ConfigModule.withConfig({
      imageLoadingStrategy: ImageLoadingStrategy.LAZY
    } as MediaConfig)
  ],

  exports: [EfaBannerComponent]
})
export class BannerModule { }
