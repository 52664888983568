import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { TopmotiveCatalogAdapter } from '../topmotive-catalog.adapter';

@Injectable({
  providedIn: 'root',
})
export class OccTopmotiveCatalogAdapter implements TopmotiveCatalogAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  isAuthorized(targetUrl: string): Observable<boolean> {
    const url = this.occEndpoints.buildUrl('isAuthorizedToActivateExternalCatalog', {
      urlParams: { userId: 'current', targetUrl },
    });

    return this.http.get<boolean>(url);
  }
}
