<ng-container *ngIf="{
    isLoading: isLoading$ | async,
    deliveryNoteDetails: deliveryNotesDetails$ | async
  } as type">
  <ng-container *ngIf="!!type.deliveryNoteDetails && !type.isLoading; else loading">
    <div class="c-delivery-note-details">
      <div class="delivery-header-and-table">
        <app-delivery-notes-header [deliveryNoteNumber]="type.deliveryNoteDetails?.deliveryNoteNumber"
        [creationDate]="type.deliveryNoteDetails?.creationDate"
        [warehouseName]="type.deliveryNoteDetails?.warehouseName"
        [receiverAddress]="type.deliveryNoteDetails?.receiverAddress">
        </app-delivery-notes-header>

        <div class="table-title">
          {{ 'deliveryNotesCustom.deliveryNotesTitle' | cxTranslate }}
        </div>

        <table class="c-delivery-note-details__table table table--responsive">
        <thead>
        <tr>
        <th scope="col">{{ 'deliveryNotesCustom.deliveryNotesDetails.positionNumber' | cxTranslate }}</th>
        <th scope="col">{{ 'deliveryNotesCustom.deliveryNotesDetails.oemNumber' | cxTranslate }}</th>
        <th scope="col">{{ 'deliveryNotesCustom.deliveryNotesDetails.deliveredQuantity' | cxTranslate }}</th>
        <th scope="col">{{ 'deliveryNotesCustom.deliveryNotesDetails.consignmentInfo' | cxTranslate }}</th>
        <th scope="col">{{ 'deliveryNotesCustom.deliveryNotesDetails.orderNumber' | cxTranslate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let position of type.deliveryNoteDetails?.positions">
        <td attr.data-label="{{ 'deliveryNotesCustom.deliveryNotesDetails.positionNumber' | cxTranslate }}">
        {{ position?.positionNumber }}
        </td>
        <td attr.data-label="{{ 'deliveryNotesCustom.deliveryNotesDetails.oemNumber' | cxTranslate }}">
        {{ position?.oemNumber }}
        </td>
        <td attr.data-label="{{ 'deliveryNotesCustom.deliveryNotesDetails.deliveredQuantity' | cxTranslate }}">
        {{ position?.deliveredQuantity }}
        </td>
        <td attr.data-label="{{ 'deliveryNotesCustom.deliveryNotesDetails.consignmentInfo' | cxTranslate }}">
        {{ position?.consignmentInfo ? position?.consignmentInfo :'-' }}
        </td>
        <td attr.data-label="{{ 'deliveryNotesCustom.deliveryNotesDetails.orderNumber' | cxTranslate }}">
        {{ position?.orderNumber }}
        </td>
        </tr>
        </tbody>
        </table>
      </div>

      <div class="c-delivery-note-details__bottom row justify-content-end">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <button class="btn btn-block btn-secondary" (click)="goBack()"
                [routerLink]="{  cxRoute: 'deliveryNoteOverview' } | cxUrl">
            {{ 'common.back' | cxTranslate }}
          </button>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <button class="btn btn-block btn-primary btn-margin-top"
                [routerLink]="{  cxRoute: 'deliveryNoteReturnablePositions', params: { deliveryNoteNumber: type.deliveryNoteDetails?.deliveryNoteNumber } } | cxUrl"
                [hidden]="!(isViewReturnsPermissionAllowed$ | async)">
            {{ 'deliveryNotesCustom.deliveryNotesDetails.returnProducts' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <ng-container *ngIf="type.isLoading">
      <div class="cx-spinner">
        <cx-spinner></cx-spinner>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
