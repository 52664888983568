export const orderCancel = {
  orderCancellation: {
    cancellationAndReturn: {
      article: 'Article',
      quantity: 'Quantity',
      searchedArticleNumber: 'Searched Article.Nr.',
      consignmentInfo: 'Consignment Info',
      selectItem: 'Cancel item',
      selectItems: 'Cancel ordered items',
      selectAll: 'Select All',
      cancelSelectedItems: 'Cancel selected items',
    },
    globalMessage: {
      orderCancellationFailure: 'Error when cancelling positions of order \'{{orderNumber}}\'. Please try again.',
      orderCancellationSuccess: 'Successful cancellation of positions of order \'{{orderNumber}}\'',
      notAllPositionsCouldBeCancelledWarning: 'Not all positions of the order \'{{orderNumber}}\' could be cancelled. Please check the status of the positions for successful cancellations.',
    },
    selectedHint: '*Please select at least one position'
  },
};
