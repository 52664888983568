import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveCartFacade, Cart, OrderEntry } from '@spartacus/cart/base/root';
import { TranslationService } from '@spartacus/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, combineLatest, from } from 'rxjs';
import { filter, first, map, reduce, switchMap } from 'rxjs/operators';
import { CartEntryCsv } from '../model/import-export.model';


@Injectable({
  providedIn: 'root',
})
export class CartImportExportService implements OnDestroy {
  constructor(
    protected activeCartService: ActiveCartFacade,
    protected store: Store,
    protected translationService: TranslationService
  ) {
    this.subscription.add(
      combineLatest([
        this.translationService.translate('importExport.csvHeaders.oemNumber'),
        this.translationService.translate('importExport.csvHeaders.quantity'),
        this.translationService.translate('importExport.csvHeaders.consignmentInfo')
      ]).subscribe(([key1, key2, key3]) => {
        this.csvOptions.keys = [key1 as string, key2 as string, key3 as string];
        this.csvOptionsLoaded$.next(true);
      })
    );
  }

  private subscription: Subscription = new Subscription();

  public status$ = new BehaviorSubject({
    searching: false,
    importing: false
  });

  private csvOptionsLoaded$: Subject<boolean> = new ReplaySubject();

  private exportFilename$: Subject<string> = new ReplaySubject();

  private isExporting$: Subject<boolean> = new BehaviorSubject(false);

  csvOptions = {
    emptyFieldValue: '',
    excelBOM: true,
    delimiter: {
      wrap: '"', // Double Quote (") character
      field: ';', // Comma field delimiter
      eol: '\r\n', // Newline delimiter
    },
    prependHeader: true,
    sortHeader: false,
    trimHeaderValues: true,
    trimFieldValues: true,
    expandArrayObjects: false,
    keys: ['', '', '']
  };

  csvOptionsMicrocat = {
    emptyFieldValue: '',
    excelBOM: true,
    delimiter: {
      wrap: '', // Double Quote (") character
      field: ',', // Comma field delimiter
      eol: '\r\n', // Newline delimiter
    },
    sortHeader: false,
    trimFieldValues: true,
    expandArrayObjects: false,
    keys: ['col1', 'col2', 'col3', 'col4', 'col5', 'col6', 'col7', 'col8', 'col9', 'col10', 'col11', 'col12', 'col13', 'col14', 'col15', 'col16', 'col17', 'col18', 'col19'],
  };

  json2csvAsync: any;
  csv2jsonAsync: any;

  cart$ = this.activeCartService.getActive();

  public exportCart(filename: string): Observable<string> {
    return this.csvOptionsLoaded$.asObservable().pipe(filter((csvOptionsLoaded) => csvOptionsLoaded === true),
      switchMap(() => this.cart$),
      first(),
      switchMap((cart) => this.prepareJsonToExport(cart)),
      reduce((acc, value) => {
        acc.push(value);
        return acc;
      }, []),
      switchMap((obj) =>
        this.exportFile({
          fileName: filename,
          data: obj,
        })
      )
    );
  }

  private prepareJsonToExport(cart: Cart): Observable<CartEntryCsv> {
    return from(cart.entries).pipe(
      filter((entry) => entry.subEntry === false),
      map((entry) => this.prepareSimpleCSVLine(entry))
    );
  }

  private prepareSimpleCSVLine(e: OrderEntry): any {
    return {
      [this.csvOptions.keys['0']]: e.product.manufacturerAID,
      [this.csvOptions.keys['1']]: e.quantity,
      [this.csvOptions.keys['2']]: e.consignmentInfo === undefined ? '' : e.consignmentInfo,
    };
  }

  async loadJson2csv(): Promise<void> {
    if (!this.json2csvAsync) {
      const json2cvsModule = await import('json-2-csv');
      this.json2csvAsync = json2cvsModule.json2csvAsync;
      this.csv2jsonAsync = json2cvsModule.csv2jsonAsync;
    }
  }

  private async exportFile(options: DownloadAsFileParams): Promise<string> {
    await this.loadJson2csv();
    return this.json2csvAsync(options.data, this.csvOptions);
  }

  public async readCSV(csv: string): Promise<CartEntryCsv[]> {
    await this.loadJson2csv();
    const jsonImport: Promise<CartEntryCsv[]> = this.csv2jsonAsync(csv, this.csvOptions).then((data) => {
      const dataAsArray: CartEntryCsv[] = this.mapLocalizedFieldstoCartEntriesInCsv(data);
      if (dataAsArray.length === 1) {
        return dataAsArray;
      }
      if (dataAsArray[dataAsArray.length - 1].oemNumber == null || dataAsArray[dataAsArray.length - 1].oemNumber === '') {
        return dataAsArray.splice(0, dataAsArray.length - 1);
      }
      return dataAsArray;
    });
    return jsonImport;
  }

  public async readCSVMicrocat(csv: string): Promise<CartEntryCsv[]> {
    const firstLine = csv.substr(0, csv.indexOf('\r\n'));
    const firstLineParts = firstLine.split(',');
    const consignmentInfo = firstLineParts !== undefined && firstLineParts.length >= 9 ? firstLineParts[8] : '';
    const csvContent = csv.substr(csv.indexOf('\r\n') + 2, csv.length);
    const headerLine = 'col1,col2,col3,col4,col5,col6,col7,col8,col9,col10,col11,col12,col13,col14,col15,col16,col17,col18,col19\r\n';
    const csvLines = csvContent.replace(/"/g, '');

    await this.loadJson2csv();
    const jsonImport: Promise<CartEntryCsv[]> = this.csv2jsonAsync(headerLine + csvLines, this.csvOptionsMicrocat).then((data) => {
      console.log(data);
      const dataAsArray: CartEntryCsv[] = this.mapLocalizedFieldstoCartEntriesInCsvMicrocrat(data, consignmentInfo);
      console.log(dataAsArray);
      if (dataAsArray.length <= 1) {
        return dataAsArray;
      }
      if (dataAsArray[dataAsArray.length - 1].oemNumber == null || dataAsArray[dataAsArray.length - 1].oemNumber === '') {
        return dataAsArray.splice(0, dataAsArray.length - 1);
      }
      return dataAsArray;
    });
    return jsonImport;
  }

  setSearchingStatusTo(status: boolean): void {
    this.status$.next({searching: status, importing: false});
  }

  setImportingStatusTo(status: boolean): void {
    this.status$.next({searching: false, importing: status});
  }

  private mapLocalizedFieldstoCartEntriesInCsv(data: any[]): CartEntryCsv[] {
    const cartEntriesCsv = [];
    data.forEach((entry: any) => {
      const cartEntryCsv: CartEntryCsv = {
        oemNumber: entry[this.csvOptions.keys['0']],
        quantity: entry[this.csvOptions.keys['1']],
        consignmentInfo: entry[this.csvOptions.keys['2']]
      };
      cartEntriesCsv.push(cartEntryCsv);
    });
    return cartEntriesCsv;
  }

  private mapLocalizedFieldstoCartEntriesInCsvMicrocrat(data: any[], consignmentInfo: string): CartEntryCsv[] {
    const cartEntriesCsv = [];
    data.forEach((entry: any) => {
      const cartEntryCsv: CartEntryCsv = {
        oemNumber: entry.col2,
        quantity: entry.col5,
        consignmentInfo
      };
      cartEntriesCsv.push(cartEntryCsv);
    });
    return cartEntriesCsv;
  }

  getCsvOptionsLoaded(): Observable<boolean> {
    return this.csvOptionsLoaded$.asObservable();
  }

  setExportFileName(fileName: string): void {
    this.exportFilename$.next(fileName);
  }

  getExportFilename(): Observable<string> {
    return this.exportFilename$.asObservable();
  }

  getExporting(): Observable<boolean> {
    return this.isExporting$.asObservable();
  }

  setExporting(isExporting: boolean): void {
    this.isExporting$.next(isExporting);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export interface DownloadAsFileParams {
  fileName: string;
  data: any;
}

