import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as OrderOverViewReducers from './order-overview.reducer';
import * as OrderOverViewUiReducers from './order-overview-ui.reducer';

export const ORDER_HISTORY_FEATURE_KEY = 'orderHistory';

export interface OrderHistoryState {
  [OrderOverViewReducers.ORDER_OVERVIEW_FEATURE_KEY]: OrderOverViewReducers.OrderOverviewState;
  [OrderOverViewUiReducers.ORDER_OVERVIEW_UI_FEATURE_KEY]: OrderOverViewUiReducers.OrderOverviewUiState;
}

export const reducers: ActionReducerMap<OrderHistoryState> = {
  [OrderOverViewReducers.ORDER_OVERVIEW_FEATURE_KEY]: OrderOverViewReducers.orderOverviewReducer,
  [OrderOverViewUiReducers.ORDER_OVERVIEW_UI_FEATURE_KEY]: OrderOverViewUiReducers.orderOverviewUiReducer,
};

export const getOrderHistoryState = createFeatureSelector<OrderHistoryState>(ORDER_HISTORY_FEATURE_KEY);
