import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, concatMap, map, withLatestFrom} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {GlobalMessageType, RoutingService} from '@spartacus/core';

import {ReturnCreationActions} from '../actions';
import {ErrorType} from '@shared/models/ErrorTypes';
import {ReturnCreationAdapter} from '../../occ/adapters/return-creation.adapter';
import {ReturnCreationResponse} from '../../model/return-creation.model';
import {ReturnPositionState} from '../reducers/return-position.reducer';
import {selectDocumentNumber} from '../selectors/return-position.selectors';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {ReturnPositionService} from '../../services/return-position.service';

@Injectable()
export class ReturnCreationEffect {

  returnCreation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReturnCreationActions.returnCreation),
      withLatestFrom(
        this.store.pipe(select(selectDocumentNumber)),
      ),
      concatMap(([action, documentNumber]) =>
        this.connector.doReturnCreation(action.request).pipe(
          map((returnCreationResponse: ReturnCreationResponse) => {
            if (
              returnCreationResponse != null &&
              ((returnCreationResponse.rejectedPositions &&
                  returnCreationResponse.rejectedPositions.length > 0) ||
                returnCreationResponse.returnNumbers == null ||
                returnCreationResponse.returnNumbers.length === 0)
            ) {
              this.globalMessageService.add(
                {
                  key: 'returnCreation.globalMessage.notAllPositionsCouldBeReturnedWarning'
                },
                GlobalMessageType.MSG_TYPE_WARNING
              );
            } else {
              this.globalMessageService.add(
                {
                  key: 'returnCreation.globalMessage.returnCreationSuccess',
                  params: {returnNumbers: returnCreationResponse?.returnNumbers}
                },
                GlobalMessageType.MSG_TYPE_INFO
              );
              this.returnPositionService.reset();
              if (!!returnCreationResponse) {
                this.routingService.go({
                  cxRoute: 'deliveryNoteOverview'
                }, {
                  state: {
                    messageTypesForRemoval: [GlobalMessageType.MSG_TYPE_WARNING,
                      GlobalMessageType.MSG_TYPE_CONFIRMATION,
                      GlobalMessageType.MSG_TYPE_ERROR]
                  }
                });
              }
            }
            return ReturnCreationActions.returnCreationSuccess({
              response: returnCreationResponse
            });
          }),
          catchError((error: ErrorType) => {
            this.globalMessageService.add(
              {
                key: 'returnCreation.globalMessage.returnCreationFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(ReturnCreationActions.returnCreationFailure({errorResponse: error}));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: ReturnCreationAdapter,
    private globalMessageService: EfaGlobalMessageService,
    private routingService: RoutingService,
    private store: Store<ReturnPositionState>,
    private returnPositionService: ReturnPositionService
  ) {
  }
}
