import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';

import { ReturnDetailsResponse } from '../../../model/return-history.model';
import { ReturnDetailsAdapter } from '../return-details.adapter';

@Injectable({providedIn: 'root'})
export class OccReturnDetailsAdapter implements ReturnDetailsAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  loadDetails(returnNum: string): Observable<ReturnDetailsResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'returnsDetails',
      {
        urlParams: {
          userId: 'current',
          returnNumber: returnNum
        }
      }
    );

    return this.http.get<ReturnDetailsResponse>(url, {headers});
  }
}
