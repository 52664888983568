import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalMessageService, StatePersistenceService, StorageSyncType } from '@spartacus/core';
import { LoginFormComponent } from '@spartacus/user/account/components';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { EfaLoginFormComponentService } from '../../services/efa-login-form-component.service';

@Component({
  selector: 'app-efa-login-form',
  templateUrl: './efa-login-form.component.html',
})
export class EfaLoginFormComponent extends LoginFormComponent implements OnInit, OnDestroy {

  protected subscription = new Subscription();
  protected key = 'partnerId';
  protected partnerId$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private statePersistenceService: StatePersistenceService, protected service: EfaLoginFormComponentService, private globalMessageService: GlobalMessageService) {
    super(service);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.statePersistenceService.syncWithStorage({
        key: this.key,
        state$: this.getPartnerId(),
        storageType: StorageSyncType.LOCAL_STORAGE,
        onRead: (state) => this.onRead(state),
      })
    );
  }

  protected onRead(state: string | undefined): void {
    this.service.form.patchValue({partnerId: state});
  }

  /**
   * Reads synchronously state from storage and returns it.
   */
  protected readStateFromStorage(): BehaviorSubject<string> {
    return this.statePersistenceService.readStateFromStorage<BehaviorSubject<string>>({
      key: this.key,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getPartnerId(): Observable<string> {
    return this.form.valueChanges.pipe(map(value => value.partnerId));
  }
}
