<label *ngIf="orderTypes?.length > 0"  class="form-group">
  <span class="label-content">{{ 'cartAdministration.orderTypeSelect.label' | cxTranslate }}</span>
  <div *ngIf="myName === 'loginOrderTypeSelection'" class="order-type-selection">
    <ng-select
      [searchable]="false"
      [clearable]="false"
      [readonly]="orderTypes?.length === 1"
      [(ngModel)]="selectedOrderType"
      (change)="orderTypeChange($event)">
      <ng-option *ngFor="let orderType of orderTypes" [value]="orderType">
        {{ orderType.name }}
      </ng-option>
    </ng-select>
  </div>

  <div [class.d-flex]="isLogin" *ngIf="myName === 'cartOrderTypeSelection'" [formGroup]="orderTypeSelectionForm">
    <select class="form-control"
            [name]="myName"
            formControlName="orderTypeCode"
            (change)="orderTypeInFormChange()">
      <option *ngFor="let orderType of orderTypes"
              [value]="orderType.id">
        {{ orderType.name }}
      </option>
    </select>
    <cx-icon [type]="iconTypes.CARET_DOWN" *ngIf="isLogin"></cx-icon>
  </div>
</label>
