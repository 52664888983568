import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {BreadcrumbMeta, SearchPageMetaResolver} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CustomSearchPageMetaResolver extends SearchPageMetaResolver {

  baseSite$ = this.translation.translate('categoryInformation.pageMetaResolver.baseSite.name');

  resolveTitle(): Observable<string> {
    const obs = combineLatest([this.baseSite$, super.resolveTitle()]);
    return obs.pipe(
      map(([baseSite, title]) => baseSite + " - " + title));
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {
    return of([]);
  }
}
