export const orderHistory = {
  orderHistoryCustom: {
    positionList: {
      empty: 'Der Auftrag enthält keine Auftragspositionen.'
    },
    orderOverviewFilterForm: {
      orderNumber: {
        label: 'Auftragsnummer',
        placeholder: 'Auftragsnummer'
      },
      orderState: {
        label: 'Status',
        placeholder: 'Status'
      },
      shippingAddresses: {
        label: 'Lieferadresse',
        placeholder: 'Lieferadresse'
      },
      dateFrom: {
        label: 'Datum von',
        placeholder: 'Datum von'
      },
      dateTo: {
        label: 'Datum bis',
        placeholder: 'Datum bis'
      },
      consignmentInfo: {
        label: 'Ihre zusätzliche Information',
        placeholder: 'Ihre zusätzliche Information'
      }
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Auftragshistorie sortieren',
      sapOrderNumber: 'Auftragsnummer',
      consignmentInfo: 'Zus. Information',
      statusDisplay: 'Status',
      originName: 'Herkunft',
      placed: 'Datum',
      orderTypeName: 'Auftragsart',
      receiverAddressId: 'Lieferadresse',
      total: 'Gesamt (netto)',
      orderDetails: {
        orderType: 'Auftragsart',
        totalPrice: 'Gesamt (netto)',
        consignmentInfo: 'Ihre Auftragsreferenz / Versandlabel Information',
        placedBy: 'Erstellt von',
        requestedDeliveryDate: 'Wunschlieferdatum',
        originName: 'Herkunft',
        orderInfoFields: {
          emptyFieldText: 'keine Angabe',
        },
        status: 'Status',
        placedOn: 'Erstellt am',
        orderItems: 'Bestellte Artikel',
      },
      pricesNotice: 'Preise können anteilige Frachtkosten enthalten.'
    },
    globalMessage: {
      orderDetailsLoadFailure: 'Beim Laden der Auftragsdetails ist ein Fehler aufgetreten.',
      orderHistoryLoadFailure: 'Laden der Auftragshistorie fehlgeschlagen.',
    },
    cancellation: 'Auftrag stornieren'
  }
};
