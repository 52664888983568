import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index-de';
import { en } from './en/index-en';

export const orderCancelTranslations = {
  de,
  en
};

export const orderCancelTranslationChunksConfig: TranslationChunksConfig = {
  orderCancel: [
    'orderCancellation'
  ],
};

