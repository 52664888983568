import {Component} from '@angular/core';

import {map, tap} from 'rxjs/operators';
import {CmsComponentData} from '@spartacus/storefront';
import {CmsLinkComponent, OccConfig, urlPathJoin} from '@spartacus/core';

import {RegistrationStartComponentData} from '../../model/login-register.model';
import { ICON_TYPE } from '../../model/login-register-enum.model';

@Component({
  selector: 'app-registration-start',
  templateUrl: './registration-start.component.html'
})
export class RegistrationStartComponent {
  registrationStartComponentData$ = this.component.data$;
  downloadLinksItems: CmsLinkComponent[];
  downloadLinksData$ = this.component.data$.pipe(
    tap((data: RegistrationStartComponentData) => {
      this.downloadLinksItems = [];
      (data.downloadLinksSortOrder.split(',').forEach((s) => this.downloadLinksItems.push(data.downloadLinks[s])));
    })
  );
  displayButton$ = this.component.data$.pipe(map((data: RegistrationStartComponentData) => data.displayButton));

  iconTypes = ICON_TYPE

  constructor(
    public component: CmsComponentData<RegistrationStartComponentData>, private config: OccConfig
  ) {
  }

  getUrl(url: string): string {
    console.log("baseurl, URL", this.config?.backend?.occ?.baseUrl, url);
    return urlPathJoin(this.config?.backend?.occ?.baseUrl, url);
  }

  getTarget(data: CmsLinkComponent): string | null {
    return data.target === 'true' || data.target === true ? '_blank' : null;
  }

  getExternal(data: CmsLinkComponent): boolean {
    return data.external === true || String(data.external) === 'true';
  }
}

