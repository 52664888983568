import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModules } from '@shared/shared.modules';
import { ConfigModule, I18nModule, OccConfig } from '@spartacus/core';
import {FormErrorsModule, IconModule, SpinnerModule} from '@spartacus/storefront';
import { WarrantyProcessFormularComponent } from './components/warranty-process-formular/warranty-process-formular.component';
import { OccModule } from './occ/occ.module';
import { effects } from './store/effects';
import { reducers, WARRANTY_PROCESS_INDEX_FEATURE_KEY } from './store/reducers';
import { warrantyProcessTranslationChunksConfig, warrantyProcessTranslations } from './translations/warranty-process.translations';

@NgModule({
  declarations: [
    WarrantyProcessFormularComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModules,
    OccModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    NgbModule,
    SpinnerModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      icon: {
        symbols: {
          RESET: 'fas fa-times-circle',
        }
      }
    }),
    ConfigModule.withConfig({
      i18n: {
        resources: warrantyProcessTranslations,
        chunks: warrantyProcessTranslationChunksConfig
      }
    }),
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            startWarrantyProcess: 'users/${userId}/startWarrantyProcess'
          },
        },
      },
    } as OccConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        WarrantyProcessFormularComponent: {
          component: WarrantyProcessFormularComponent,
        }
      }
    }),
    StoreModule.forFeature(WARRANTY_PROCESS_INDEX_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
  ],
  providers: []
})
export class WarrantyProcessModule { }
