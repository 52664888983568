import {Component, OnDestroy} from '@angular/core';

import {GlobalMessageType, RoutingService} from '@spartacus/core';
import {CancelOrderConfirmationComponent} from '@spartacus/order/components';
import {combineLatest, Observable, Subscription} from 'rxjs';

import {OrderCancelService} from '../../services/order-cancel.service';
import {filter} from 'rxjs/operators';
import {OrderCancelResponse} from '../../model/order-cancel.model';
import {UntypedFormGroup} from '@angular/forms';
import {OrderOverviewService} from '../../../order-history/services/order-overview.service';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import { OrderHistoryService } from '@spartacus/order/core';

@Component({
  selector: 'app-efa-cancel-order-confirmation',
  templateUrl: './efa-cancel-order-confirmation.component.html',
})
export class EfaCancelOrderConfirmationComponent extends CancelOrderConfirmationComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    private orderCancelService: OrderCancelService,
    private userOrderService: OrderHistoryService,
    private globalMessage: EfaGlobalMessageService,
    private route: RoutingService,
    private orderOverviewService: OrderOverviewService
  ) {
    super(orderCancelService);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submit(form: UntypedFormGroup): void {
    super.submit(form);
    this.subscription.add(
      combineLatest([this.orderCancelService.isSuccess(), this.getProcessedOrderCancelResponse()])
        .subscribe(([isSuccess, orderCancelResponse]) => {
          if (isSuccess) {
            if (orderCancelResponse.rejectedPositions && orderCancelResponse.rejectedPositions.length > 0) {
              this.globalMessage.add(
                {
                  key: 'orderCancellation.globalMessage.notAllPositionsCouldBeCancelledWarning',
                  params: {orderNumber: orderCancelResponse.orderNumber}
                },
                GlobalMessageType.MSG_TYPE_WARNING
              );
            } else {
              this.globalMessage.add(
                {
                  key: 'orderCancellation.globalMessage.orderCancellationSuccess',
                  params: {orderNumber: orderCancelResponse.orderNumber}
                },
                GlobalMessageType.MSG_TYPE_CONFIRMATION
              );
              this.route.go({
                cxRoute: 'orders'
              },{
                state: {
                  messageTypesForRemoval: [GlobalMessageType.MSG_TYPE_WARNING,
                    GlobalMessageType.MSG_TYPE_ERROR,
                    GlobalMessageType.MSG_TYPE_INFO]
                }
              });
              this.orderCancelService.resetOrderCancellation();
              this.orderOverviewService.resetOverview();
              this.userOrderService.clearOrderDetails();
            }
          }
        })
    );
  }

  private getProcessedOrderCancelResponse(): Observable<OrderCancelResponse> {
    return this.orderCancelService.getOrderCancelResponse().pipe(
      filter((orderCancelResponse: OrderCancelResponse) => orderCancelResponse != null)
    );
  }
}
