<ng-container *ngIf="order$ | async as order">
<div class="cx-order-summary">
  <div class="c-order-summary__container row">
    <div class="col-12 col-md-8 px-0 px-md-1">
      <div *ngIf="order.code" class="c-order-overview-header">
        <span class="c-order-overview-header-title">
          {{ 'orderDetails.orderNumber' | cxTranslate }}:
        </span>
        <span class="c-order-overview-header-label">
          {{ order.code }}
        </span>
      </div>
      <div *ngFor="let orderInfoField of order.orderInfoFields" class="c-order__summary-card c-order__summary-card--row c-order__summary-card--row--start">
        <cx-card [content]="getOrderInfoFieldCardContent(orderInfoField) | async"></cx-card>
      </div>
      <div *ngIf="order.consignmentInfo" class="c-order__summary-card c-order__summary-card--row c-order__summary-card--row--start">
        <cx-card [content]="getConsignmentInfoCardContent(order?.consignmentInfo) | async"></cx-card>
      </div>
      <div *ngIf="order.placedBy" class="c-order__summary-card c-order__summary-card--row c-order__summary-card--row--start">
        <cx-card [content]="getPlacedByOrderCardContent(order?.placedBy) | async"></cx-card>
      </div>
      <div *ngIf="order.created" class="c-order__summary-card c-order__summary-card--row c-order__summary-card--row--start">
        <cx-card [content]="getOrderCurrentDateCardContent(order?.created) | async"></cx-card>
      </div>
      <div *ngIf="order.originName" class="c-order__summary-card c-order__summary-card--row c-order__summary-card--row--start">
        <cx-card [content]="getOriginNameCardContent(order?.originName) | async"></cx-card>
      </div>
    </div>

    <div class="col-12 col-md-4 px-0 px-md-1">
      <div *ngIf="order.deliveryAddress" class="c-order__summary-card">
        <cx-card [content]="getAddressCardContent(order?.deliveryAddress) | async">
        </cx-card>
      </div>

      <div *ngIf="order.orderType" class="c-order__summary-card c-order__summary-card--row c-order__summary-card--order-type">
        <cx-card [content]="getOrderTypeCardContent(order?.orderType) | async">
        </cx-card>
      </div>

      <div *ngIf="order.requestedDeliveryDate && displayRequestedDeliveryDate" class="c-order__summary-card c-order__summary-card--row">
        <cx-card [content]="getRequestedDeliveryDateCardContent(order?.requestedDeliveryDate) | async">
        </cx-card>
      </div>

      <div *ngIf="order.deliveryMode" class="c-order__summary-card c-order__summary-card--row">
        <cx-card [content]="getDeliveryModeCardContent(order?.deliveryMode) | async">
        </cx-card>
      </div>
      <div *ngIf="order.statusDisplay" class="c-order__summary-card c-order__summary-card--row">
        <cx-card [content]="getOrderStatusCardContent(order?.statusDisplay) | async"></cx-card>
      </div>
      <div *ngIf="order.totalPrice" class="c-order__summary-card c-order__summary-card--row">
        <cx-card [content]="getTotalPriceCardContent(order?.totalPrice) | async"></cx-card>
      </div>
    </div>
  </div>
</div>
</ng-container>