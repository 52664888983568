export const returnCreation = {
  returnablePositions: {
    returnAllArticles: 'Alle Artikel retournieren',
    positionNumber: 'Positions-ID',
    oemNumber: 'OEM-Nr.',
    name: 'Bezeichner',
    deliveredQuantity: 'Menge',
    returnableQuantity: 'Retournierbare Menge',
    quantityToReturn: 'Zu retournierende Menge',
    nextButton: 'Weiter',
    quantityToReturnError: 'Diese Menge ist ungültig.',
    quantityToReturnRequiredError: 'Dieses Feld wird benötigt',
    title: 'Retournierbare Positionen',
    noReturnablePositions: 'Keine retournierbaren Positionen gefunden.',
  },
  returnCreation: {
    formMandatoryFields:'Pflichtfeld',
    returnCreationTitle:'Retournierbare Positionen',
    positionNumber: 'Positions-ID',
    oemNumber: 'OEM-Nr.',
    name: 'Bezeichner',
    quantityToReturn: 'Zu retournierende Menge',
    reason: {
      header: 'Retourengrund',
      label: 'Retourengrund',
      placeholder: 'Retourengrund',
    },
    comment:{
      header: 'Kommentar',
      label: 'Kommentar',
      placeholder: 'Kommentar hinzufügen',
    },
    hint: 'Bitte beachten Sie, das Abzüge auf den Warenwert gemäß der geltenden Retouren-Richtlinie erfolgen können.',
    sendButton: 'Retourenantrag abschicken',
    globalMessage: {
      returnCreationFailure: 'Beim Senden des Retourenantrags ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.',
      returnCreationSuccess: 'Der Retourenantrag wurde erfolgreich versendet. Ihre Antragsnummer(n): {{returnNumbers}}',
      notAllPositionsCouldBeReturnedWarning: 'Nicht alle selektierten Positionen konnten retourniert werden. Die abgelehnten Positionen sind in der Liste markiert.'
    }
  }
};
