<div class="form-check">
  <label>
    <input
      type="checkbox"
      class="form-check-input"
      (change)="onConsentChange()"
      [checked]="consentGiven"
      [name]="consentTemplate.id"
      [disabled]="isRequired(consentTemplate?.id)"
    />
    <span class="form-check-label cx-be-bold">
      <ng-container *ngIf="consentTemplate?.name">
        {{ consentTemplate?.name }}
        <br/>
      </ng-container>
    </span>
    <span class="form-check-label">
      <p [innerHTML]="consentTemplate?.description | cxSupplementHashAnchors"></p>

    </span>
  </label>
</div>
