import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ListService, UserGroupPermissionListComponent, UserGroupPermissionListService } from '@spartacus/organization/administration/components';

@Component({
  selector: 'app-efa-user-group-permission-list',
  templateUrl: './efa-user-group-permission-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'content-wrapper' },
  providers: [
    {
      provide: ListService,
      useExisting: UserGroupPermissionListService,
    },
  ],
})
export class EfaUserGroupPermissionListComponent extends UserGroupPermissionListComponent implements OnInit {


  ngOnInit(): void {
  }

}
