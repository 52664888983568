import { createReducer, on } from "@ngrx/store";
import { AllowedFileExtensionsResponse } from "@shared/models/shared.model";
import { AllowedImageExtensionsActions } from "../actions";

export const ALLOWED_IMAGE_EXTENSIONS_FEATURE_KEY = 'allowedImageExtensions';

export interface AllowedImageExtensionsState {
  contextId: string;
  response: AllowedFileExtensionsResponse;
  loading: boolean;
  success: boolean;
}

export const initialState: AllowedImageExtensionsState = {
  contextId: undefined,
  response: undefined,
  loading: false,
  success: undefined
}

export const allowedImageExtensionsReducer = createReducer(
  initialState,

  on(AllowedImageExtensionsActions.loadAllowedImageExtensions, (state, action) => ({
    ...state, 
    loading: true,
    contextId: action.contextId,
    response: undefined
  })),
  on(AllowedImageExtensionsActions.loadAllowedImageExtensionsSuccess, (state, action) => ({
    ...state,
    success: true,
    response: action.response,
    loading: false
  })),
  on(AllowedImageExtensionsActions.loadAllowedImageExtensionsFailure, (state, action) => ({
    ...state,
    success: false,
    error: action.errorResponse,
    loading: false,
  }))
);
