import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de';
import { en } from './en';

export const headerTranslations = {
  de,
  en
};

// expose all translation chunk mapping for cartAdministration feature
export const headerTranslationChunksConfig: TranslationChunksConfig = {
  header: [
    'headerInfo',
  ],
};
