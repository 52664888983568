<label *ngIf="(items$ | async)?.length > 1 && (items$ | async) as items">
  <span>{{ label$ | async }}</span>
  <cx-icon type="GLOBE" class="small"></cx-icon>
  <select (change)="active = $any($event).target.value">
    <option
      *ngFor="let item of items"
      value="{{ item.isocode }}"
      [selected]="(activeItem$ | async) === item.isocode"
    >
      {{ item.isocode }}
    </option>
  </select>
  <cx-icon [type]="iconTypes.CARET_DOWN" class="small"></cx-icon>
</label>
