import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'localizeTo',
})
export class LocalizeToPipe implements PipeTransform {
  transform(input: KeyValue<string, string>[], language: string): string {
    if (input == null) {
      return undefined;
    }
    
    const localizedValue: KeyValue<string, string> = input.find(
      (keyValue: KeyValue<string, string>) => keyValue.key === language
    );
    return localizedValue?.value;
  }
}
