<cx-view class="card">
  <div class="header">
    <div class="title-bar">
      <div class="title">
        <h3>
          {{ i18nRoot + '.title' | cxTranslate: { item: item$ | async } }}
          <button
            *ngIf="showHint"
            [cxPopover]="detailHint"
            [cxPopoverOptions]="{
              placement: 'auto',
              class: 'hint-popover',
              appendToBody: true,
              displayCloseButton: true
            }"
          >
            <cx-icon [type]="iconTypes.INFO"> </cx-icon>
          </button>
        </h3>
      </div>
      <!-- <div class="actions">
        <ng-content select="[actions]"></ng-content>
      </div> -->
    </div>
  </div>

  <div class="main">
    <div class="main-container">
      <div class="subtitle-and-actions">
        <div class="subtitle">
              {{
                subtitle ||
                  (i18nRoot + '.subtitle' | cxTranslate: { item: item$ | async })
              }}
          </div>
          <div class="action">
            <ng-content select="[actions]"></ng-content>
          </div>
          <div class="action-cancel">
            <a *ngIf="!!previous" class="link close" routerLink="../">
              {{ 'orgUserGroup.action.cancel' | cxTranslate }}
              <ng-template #prevLabel>{{ previousLabel | cxTranslate }}</ng-template>
            </a>
          </div>
      </div>
      <cx-org-message></cx-org-message>
      <ng-content select="[info]"></ng-content>
      <ng-content select="[main]"></ng-content>
    </div>
  </div>
</cx-view>

<router-outlet></router-outlet>

<ng-template #detailHint>
  <p>
    {{ i18nRoot + '.hint' | cxTranslate }}
  </p>
</ng-template>
