import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as ConfiguratorCartFeature from '../reducers/configurator-cart.reducer';
import { CONFIGURATOR_CART_FEATURE_KEY } from '../reducers/configurator-cart.reducer';
import { CartAdministrationState, getCartAdministrationState } from '../reducers';

export const selectMultiCartResponse: MemoizedSelector<ConfiguratorCartFeature.ConfiguratorCartState,
  any> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CONFIGURATOR_CART_FEATURE_KEY].response
);

export const selectLoading: MemoizedSelector<ConfiguratorCartFeature.ConfiguratorCartState,
  boolean> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[CONFIGURATOR_CART_FEATURE_KEY].loading
);
