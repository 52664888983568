import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { SetCartEntryInfosActions } from '../actions';
import { SetCartEntryInfosConnector } from '../../occ/connectors/set-cart-entry-infos.connector';

@Injectable()
export class SetCartEntryInfosEffect {
  setCartEntryInfos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetCartEntryInfosActions.setCartEntryInfos),
      concatMap((action) =>
        this.connector.setCartEntryInfos(action.cartId, action.entryNumber, action.consignmentInfo, action.orderInfoFieldValues).pipe(
          map(() => {
            return SetCartEntryInfosActions.setCartEntryInfosSuccess({
              cartId: action.cartId,
              entryNumber: action.entryNumber,
              consignmentInfo: action.consignmentInfo,
              orderInfoFieldValues: action.orderInfoFieldValues,
              entryIndex: action.entryIndex
            });
          }),
          catchError((error) => {
            return of(SetCartEntryInfosActions.setCartEntryInfosFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: SetCartEntryInfosConnector
  ) {}
}
