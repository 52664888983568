import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CmsComponentData} from '@spartacus/storefront';
import {map, Observable} from 'rxjs';
import {CarPartsPromotionComponentData} from '../model/promotion-flip-book.model';

@Component({
  selector: 'app-car-parts-promotion',
  templateUrl: './car-parts-promotion.component.html',
})
export class CarPartsPromotionComponent implements OnInit {
  iframeUrl$: Observable<string>;

  constructor(
    private component: CmsComponentData<CarPartsPromotionComponentData>,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.iframeUrl$ = this.component.data$.pipe(
      map((data) => {
        const urlParams: string =
          'd=' + data.flipBookName + '&u=' + data.userName;
        return data.url + '&' + urlParams;
      })
    );
  }

  getSanitizedIframeUrl(url: string): SafeResourceUrl {
    console.log("Flipbook-Url", url);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
