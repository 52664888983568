<ng-container *ngIf="{
    productPrice: productPriceItem$ | async,
    product: product$ | async
  } as type">
  <ng-template [cxOutlet]="outlets.PRICE"
               [cxOutletContext]="{ product: type.productPrice }">
    <ng-container *ngIf="isUserLoggedIn$ | async; else loggedOut">
      <ng-container *ngIf="type.productPrice?.listPrice">
        <ng-container *ngIf="type.productPrice.listPrice.value > 0; else showNoPriceToDisplay">
          <!-- <div *ngIf="displayCustomerPrices$ | async" class="price crossed-price"
            aria-label="product price">
            <ng-container *ngIf="type.productPrice?.customerPrice &&
                                type.productPrice?.listPrice.value > type.productPrice?.customerPrice.value">
              {{ type.productPrice?.listPrice.formattedValue }}
            </ng-container>
          </div> -->

          <ng-container *ngIf="displayCustomerPrices$ | async; else showListPrice">
            <div class="price" aria-label="Product price">
              <ng-container *ngIf="type.productPrice?.customerPrice">
                {{ type.productPrice?.customerPrice.formattedValue }}
              </ng-container>
            </div>
          </ng-container>


          <ng-template #showListPrice>
            <ng-container *ngIf="type.productPrice?.listPrice">
              <div class="price" aria-label="Product price">
                {{ type.productPrice?.listPrice.formattedValue }}
              </div>
            </ng-container>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #loggedOut>
      <ng-container *ngIf="type.productPrice?.listPrice">
        <ng-container *ngIf="type.productPrice?.listPrice.value > 0; else showNoPriceToDisplay">
          <div class="price" aria-label="Product price">
            {{ type.productPrice?.listPrice.formattedValue }}
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-template>

  <ng-template #showNoPriceToDisplay>
    <div aria-label="No price" class="no-price">
      <span class="u-font-bold">
        {{ 'noPriceToDisplayForProduct' |  cxTranslate }}
      </span>
    </div>
  </ng-template>
  <div class="action-box ">
      <app-efa-add-to-cart class=""
                          [isCalledFromDPD]="true"
                          [showQuantity]="false"
                          [product]="type.product"
                          [showQuantityHint]="false"
                          [priceAvailable]="type.productPrice?.listPrice && type.productPrice?.listPrice.value > 0"
                          [useDefaultAddToCart]="false"
                          [listPricePerUnit]="type.productPrice?.listPrice"
                          [customerPricePerUnit]="type.productPrice?.customerPrice">

      </app-efa-add-to-cart>
      <app-efa-cart-item-add-to-wish-list
                                        class=""
                                        [product]="type.product"
                                        [orderInfoRefId]="0"
                                        [IsAddedFromSearchPage]="true">
      </app-efa-cart-item-add-to-wish-list>
  </div>

  <ng-template [cxOutletContext]="{ product: type }"
               [cxOutlet]="outlets.SUMMARY">
    <!-- <p [innerHTML]="type.product?.summary" class="summary"></p> -->
    <div class="summary-box"
         [ngStyle]="{'display': !type.product?.summary ? 'none' : 'block' }">
      <p [ngClass]="{'summary-title' : !!SummaryTitle}">{{SummaryTitle }}</p>
      <p class="summary-value">{{summaryValue}}</p>
    </div>
    <ng-container *ngIf="type.productPrice?.deliveryTime">
      <div class="delivery-time-box" aria-label="Product delivery time">
        <p [ngClass]="{'delivery-time-title' : !!type.productPrice?.deliveryTime}">{{ 'eProductDetails.deliveryTimeLabel' | cxTranslate }}&nbsp;</p>
        <p class="delivery-time-value" ><span>{{ 'eProductDetails.deliveryTimeText' | cxTranslate }}</span> {{type.productPrice?.deliveryTime}}&nbsp;{{ 'eProductDetails.deliveryTimeValue' | cxTranslate }}</p>
      </div>
    </ng-container>
  </ng-template>

  <app-efa-product-details-tab>

  </app-efa-product-details-tab>

</ng-container>
