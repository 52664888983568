import { createSelector, MemoizedSelector } from '@ngrx/store'
import { getPartsLinkAccessState, PartsLinkAccessState } from '../reducers';
import * as PartsLinkAccessFeature from '../reducers/parts-link-access.reducer'

export const selectProcessing : MemoizedSelector<PartsLinkAccessFeature.PartsLinkAccessState,boolean> = createSelector(
  getPartsLinkAccessState,
(state: PartsLinkAccessState) => state[PartsLinkAccessFeature.PARTS_LINK_ACCESS_FEATURE_KEY].proccessing
)

export const selectSuccess : MemoizedSelector<PartsLinkAccessFeature.PartsLinkAccessState,boolean> = createSelector(
  getPartsLinkAccessState,
  (state: PartsLinkAccessState)=> state[PartsLinkAccessFeature.PARTS_LINK_ACCESS_FEATURE_KEY].success
);
