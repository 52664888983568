import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index-de';
import { en } from './en/index-en';

export const consentManagementTranslations = {
  de,
  en
};

// expose all translation chunk mapping for productDetails feature
export const consentManagementTranslationChunksConfig: TranslationChunksConfig = {
  consentManagement: [
    'consentManagementDialog',
  ],
};
