export const contentTiles = {
  contentTiles: {
showMore: 'mehr anzeigen',
    showLess: 'weniger anzeigen',
  }
};

export const tokenGeneration = {
  tokenGeneration: {
    globalMessage: {
      generateTokenFailure: 'Generate token failed.',
      generateTokenSuccess: 'Generate token succeeded.'
    },
  },
};
