import {Component, OnInit} from '@angular/core';
import {CmsOrderDetailItemsComponent, GlobalMessageService, GlobalMessageType} from '@spartacus/core';
import {OrderDetailItemsComponent, OrderDetailsService} from '@spartacus/order/components';
import {CmsComponentData} from '@spartacus/storefront';

@Component({
  selector: 'app-efa-order-detail-items',
  templateUrl: './efa-order-detail-items.component.html',
})
export class EfaOrderDetailItemsComponent extends OrderDetailItemsComponent implements OnInit {

  constructor(protected orderDetailsService: OrderDetailsService, protected component: CmsComponentData<CmsOrderDetailItemsComponent>, protected globalMessageService: GlobalMessageService) {
    super(orderDetailsService,component);
  }

  ngOnInit(): void {
    this.globalMessageService.add(
      {
        key: 'orderHistoryCustom.myAccount.pricesNotice', params: {hideCloseButton: true, styleClass: 'staticInfoMessage'}
      },
      GlobalMessageType.MSG_TYPE_WARNING
    );
  }
}
