export const tiresWarranty = {
  tiresWarranty: {
    globalMessage: {
      startTiresWarrantyFailure: 'Your data could not be transmitted. Please try again.',
      startTiresWarrantySuccess: 'Thanks very much! Your data has been successfully transferred. An email with all the information has been sent to the email address you provided.'
    },
    tiresWarrantyHeader: {
      title: '24 months tire guarantee!',
      description: 'Since April 2017, we have been granting a tire guarantee of 24 months from sale to the end user for the following tire makes on loose tires that you have purchased from EFA Autoteilewelt.',
      goToForm: 'Register guarantee',
      goToFooter: 'Report damage'
    },
    tiresWarrantyFooter: {
      title: 'Tire guarantee claims settlement',
      text1: 'In the event of damage, please send an email to',
      text2: 'or call',
      text3: 'Key word: tire guarantee',
      text4: 'Send the guarantee pass and the original invoice via email to',
      text5: 'or to the following address:',
      email: 'efa-reifen@autoteilewelt.de',
      phone: '0800-0001 883 47',
      address1: 'EFA Autoteilewelt GmbH',
      address2: 'Abt. Reklamation Reifen',
      address3: 'Liebstädter Straße 5',
      address4: '01277 Dresden',
      goToForm: 'BACK TO TOP'
    },
    tiresWarrantyForm: {
      formTitle: 'Register start of guarantee',
      formSubTitle: 'Register your 24-month tire guarantee quickly and easily here. All information and your tire guarantee in PDF format will then be sent to the e-mail address you provided.',
      formMandatoryFields: 'Mandatory fields are marked with *.',
      legend1: '1. Retailer',
      legend2: '2. Tire data / vehicle data',
      legend3: '3. Customer Data',
      legend4: '4. Register guarantee pass',
      dealerData: {
        companyName: {
          lable: 'Retailer name',
          placeholder: 'Name of your company'
        },
        customerNumber: {
          lable: 'EFA customer number',
          placeholder: 'customer number'
        },
        street: {
          lable: 'Street',
          placeholder: 'Street'
        },
        streetnumber: {
          lable: 'House number',
          placeholder: 'House number'
        },
        countryIsoCode: {
          lable: 'Country',
          placeholder: 'DE'
        },
        postalCode: {
          lable: 'Postcode',
          placeholder: 'Postcode'
        },
        town: {
          lable: 'Location',
          placeholder: 'Location'
        },
        phone: {
          lable: 'Phone number',
          placeholder: 'Phone number'
        },
        email: {
          lable: 'E-mail address',
          placeholder: 'E-mail address'
        },
        referenceNumber: {
          lable: 'Reference document number',
          placeholder: 'Reference number'
        },
        referenceTypeCode: {
          lable: 'Type of reference document',
          code: {
            INVOICE: 'Invoice no.',
            DELIVERY_NOTE: 'Delivery note no.',
            ORDER: 'Order reference no.'
          }
        },
        invoiceDocument: {
          lable: 'Sales/Assembly invoice',
          placeholder: 'Upload PDF file'
        },
      },
      tireCarData: {
        installatonDate: {
          lable: 'Tire installation date',
          placeholder: 'DD.MM.YYYY'
        },
        tireBrand: {
          lable: 'Tire brand',
          placeholder: 'Please choose'
        },
        quantity: {
          lable: 'Quantity',
          placeholder: 'Quantity'
        },
        tireSize: {
          lable: 'Tire size',
          placeholder: 'Tire size'
        },
        tireProfile: {
          lable: 'Tire tread',
          placeholder: 'Tire tread'
        },
        carBrand: {
          lable: 'Vehicle brand / type',
          placeholder: 'Vehicle brand / type'
        },
        numberPlate: {
          lable: 'Number plate',
          placeholder: 'Number plate'
        },
        totalKilometers: {
          lable: 'Mileage',
          placeholder: 'Mileage'
        }

      },
      customerData: {
        titleCode: {
          lable: 'Salutation',
          gender: {
            MALE: 'Mr.',
            FEMALE: 'Mrs. / Ms.',
            OTHER: 'Other',
            COMPANY: 'Company'
          }
        },
        name: {
          lable: 'First and last name / company name',
          placeholder: 'First and last name / company name'
        },
        street: {
          lable: 'Street',
          placeholder: 'Street'
        },
        streetnumber: {
          lable: 'House number',
          placeholder: 'House number'
        },
        countryIsoCode: {
          lable: 'Country',
          placeholder: 'DE'
        },
        postalCode: {
          lable: 'Postcode',
          placeholder: 'Postcode'
        },
        town: {
          lable: 'Location',
          placeholder: 'Location'
        },
        phone: {
          lable: 'Phone number',
          placeholder: 'Phone'
        },
      },
      warrantyTerms: {
        lable: 'Guarantee conditions',
        placeholder: 'The guarantee conditions of EFA Autoteilewelt GmbH were handed over to the customer.'
      },
      submit: 'Submit form'
    }
  }
};
