import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, UserIdService} from '@spartacus/core';
import {CurrentProductService, ProductSummaryComponent} from '@spartacus/storefront';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {ProductPricesService} from '../../../product-prices/service/product-prices.service';
import {PermissionCheckService} from '../../../user-profiles/service/permission-check.service';
import {CartParametersService} from '../../../cart-administration/services/cart-parameters.service';
import {PriceResponseItem} from '../../../product-prices/model/product-prices.model';

@Component({
  selector: 'app-efa-product-summary',
  templateUrl: './efa-product-summary.component.html'
})
export class EfaProductSummaryComponent extends ProductSummaryComponent implements OnInit, OnDestroy {
  isUserLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  displayCustomerPrices$: Observable<boolean>;
  productPriceItem$: Observable<PriceResponseItem>;
  subscription: Subscription = new Subscription();
  SummaryTitle: string;
  summaryValue: string;

  constructor(
    protected currentProductService: CurrentProductService,
    private productPricesService: ProductPricesService,
    private userIdService: UserIdService,
    protected authService: AuthService,
    protected permissionCheckService: PermissionCheckService,
    protected cartParametersService: CartParametersService
  ) {
    super(currentProductService);
  }

  ngOnInit(): void {
    this.subscription.add(combineLatest([this.cartParametersService.getCurrentOrderType(),
      this.userIdService.getUserId()
    ]).subscribe(([orderType, userID]) =>
      this.subscription.add(this.currentProductService.getProduct().subscribe(product => {
        const products: string[] = new Array<string>();
        products.push(product.code);
        this.productPricesService.retrievePrices(products, userID, orderType, true);
        this.productPriceItem$ = this.productPricesService.getPrice(product.code);
      }))));

    this.product$.subscribe(product => {
      this.formatSummary(product?.summary);
    });

    this.displayCustomerPrices$ = this.permissionCheckService.displayCustomerPrices();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  formatSummary(summary: string): void {
    this.SummaryTitle = summary?.split(':')[0];
    this.summaryValue = summary?.split(':')[1];
  }
}
