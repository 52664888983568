import {Component, OnInit} from '@angular/core';
import {AuthService, EventService, RoutingService, UserConsentService} from '@spartacus/core';
import {TermsConfirmationEvent} from '../../model/login-register.model';

@Component({
  selector: 'app-terms-and-conditions-confirmation-action',
  templateUrl: './terms-and-conditions-confirmation-action.component.html'
})
export class TermsAndConditionsConfirmationActionComponent implements OnInit {

  constructor(private routingService: RoutingService,
              private userConsentService: UserConsentService,
              private authService: AuthService,
              private eventService: EventService) {
  }

  ngOnInit(): void {
  }

  declineTermsAndConditions(): void {
    this.authService.logout();
    this.routingService.go({cxRoute: 'logout'});
    this.eventService.dispatch(new TermsConfirmationEvent(false));
  }

  acceptTermsAndConditions(): void {
    this.userConsentService.giveConsent('002-TERMS_CONDITIONS', 0);
    this.routingService.go({cxRoute: 'home'});
    this.eventService.dispatch(new TermsConfirmationEvent(true));
  }
}
