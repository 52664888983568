import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RegistrationConfirmationAdapter } from '../adapters/registration-confirmation.adapter';

@Injectable({ providedIn: 'root' })
export class RegistrationConfirmationConnector {
  constructor(protected adapter: RegistrationConfirmationAdapter){}

  registrationConfirmation(token: string): Observable<void> {
    return this.adapter.registrationConfirmation(token);
  }
}
