import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ICON_TYPE } from '../../model/cart-administration-enum.model';
import { ConfiguratorCartConfirmationLocalStorageService } from '../../services/configurator-cart-confirmation-local-storage.service';

@Component({
  selector: 'app-configurator-cart-confirmation-message',
  templateUrl: './configurator-cart-confirmation-message.component.html',
})
export class ConfiguratorCartConfirmationMessageComponent implements OnInit {
  readonly iconTypes = ICON_TYPE;
  isCalledFromSERP:boolean;
  isCalledFromPDP:boolean;
  isCalledFromTopmotive: boolean;
  isConfirmed$ : Observable<boolean> = this.configuratorCartConfirmationLocalStorageService.getConfiguratorCartConfirmed();
  constructor(
    private launchDialogService: LaunchDialogService,
    private configuratorCartConfirmationLocalStorageService: ConfiguratorCartConfirmationLocalStorageService,
    private routingService:RoutingService
  ) {}

  ngOnInit(): void {

  }
  confirm(): void {
    this.configuratorCartConfirmationLocalStorageService.setConfiguratorCartConfirmed(
      true
    );
    this.closeModal();
  }
  handelSERPCall(): void {
    this.configuratorCartConfirmationLocalStorageService.setConfiguratorCartConfirmedSERP(
      true
    );
    this.configuratorCartConfirmationLocalStorageService.setConfiguratorCartConfirmed(
      true
    );
    this.closeModal();
    this.routingService.go({ cxRoute: 'cart' });
  }
  handelPDPCall(): void {
    this.configuratorCartConfirmationLocalStorageService.setConfiguratorCartConfirmedPDP(
      true
    );
    this.configuratorCartConfirmationLocalStorageService.setConfiguratorCartConfirmed(
      true
    );
    this.closeModal();
    this.routingService.go({ cxRoute: 'cart' });
  }
  handelTopmotiveCall(): void {
    this.configuratorCartConfirmationLocalStorageService.setConfiguratorCartConfirmedTopmotive(
      true
    );
    this.configuratorCartConfirmationLocalStorageService.setConfiguratorCartConfirmed(
      true
    );
    this.closeModal('success');
  }


  closeModal(status: string = 'closed'): void {
    this.launchDialogService.closeDialog(status);
  }
}
