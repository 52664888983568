import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {BreadcrumbComponent, CmsComponentData} from '@spartacus/storefront';
import {BreadcrumbMeta, CmsBreadcrumbsComponent, PageMetaService, RoutingService, TranslationService} from '@spartacus/core';
import {combineLatest, Observable} from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'app-efa-breadcrumb',
  templateUrl: './efa-breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaBreadcrumbComponent extends BreadcrumbComponent implements OnInit {
  myTitle$: Observable<string>;
  private baseSite$: Observable<string>;
  addCrumb$: Observable<BreadcrumbMeta>;

  constructor(component: CmsComponentData<CmsBreadcrumbsComponent>,
              pageMetaService: PageMetaService,
              protected translationService: TranslationService, protected routingService: RoutingService) {
    super(component, pageMetaService, translationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addCrumb$ = this.routingService.getRouterState().pipe(
      withLatestFrom(this.translationService.translate('categoryInformation.pageMetaResolver.myAccount.users.name'),
        this.translationService.translate('categoryInformation.pageMetaResolver.myAccount.userGroups.name')),
      map(([rd, label1, label2]) => rd.state.url.includes('/organization/users/') ? label1 : (rd.state.url.includes('/organization/user-groups/')) ? label2 : undefined),
      map(label => label !== undefined ? ({
        label,
        link: '/',
      }) : undefined));

    this.baseSite$ = this.translationService.translate('categoryInformation.pageMetaResolver.baseSite.name');
    this.myTitle$ = combineLatest([this.baseSite$, this.title$])
    .pipe(
      map(([baseSite, title]) => title.startsWith(baseSite) ? title.replace(baseSite, '').substring(3) : title));
  }

}
