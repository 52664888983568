import { Component } from '@angular/core';
import { PDP_CALL_SOURCE } from '@shared/models/shared.model';
import { WishListItemComponent } from '@spartacus/cart/wish-list/components';
import { ProductListItemContext, ProductListItemContextSource } from '@spartacus/storefront';
import { ICON_TYPE } from '../../model/wish-list-icon.model';

@Component({
  selector: 'app-efa-wish-list-item',
  templateUrl: './efa-wish-list-item.component.html',
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class EfaWishListItemComponent extends WishListItemComponent {

  iconTypes = ICON_TYPE;

  readonly pdpCallSource: PDP_CALL_SOURCE = PDP_CALL_SOURCE.WISHLIST;
}
