<ng-container *ngIf="{ manufacturers: selectedManufacturers$ | async, wishList: wishList$ | async } as type">
  <ng-container *ngIf="type.wishList?.entries?.length > 0; else emptyWishList">
    <form *ngIf="wishListFilterForm" [formGroup]="wishListFilterForm" class="row" id="topHeader">
      <div class="col-12 d-flex d-md-none">
        <button type="button" class="btn btn-block btn-secondary" (click)="onOpenModal()">
          <cx-icon [type]="iconTypes.BROWSE" class="mr-2"></cx-icon>
          {{ 'wishListFilterForm.filter' | cxTranslate }}
        </button>
      </div>
      <label class="form-group col-12 col-md-4 d-none d-md-block">
        <div class="row">
          <span class="label-content col-12">
            {{ 'wishListFilterForm.filter' | cxTranslate }}
          </span>
          <div class="input-group col-12">
            <input class="form-control u-text-ellipsis" type="text" formControlName="manufacturerCodes"
                   placeholder="{{ 'wishListFilterForm.manufacturerCodes.placeholder' | cxTranslate }}"/>
            <div class="input-group-append">
              <button class="btn btn-primary d-flex" type="button" (click)="onOpenModal()">
                <cx-icon [type]="iconTypes.BROWSE"></cx-icon>
              </button>
            </div>
          </div>
          <cx-form-errors [control]="wishListFilterForm.get('manufacturerCodes')"></cx-form-errors>
        </div>
      </label>
      <div class="col-12 col-md-8" *ngIf="type.manufacturers.length > 0">
        <div class="row">
          <h4 class="col-12">
            {{ 'wishListFilterForm.activeFilter' | cxTranslate }}
          </h4>
          <div class="col-12">
            <div class="active-filter-items">
              <button type="button" class="btn btn-primary btn-item" *ngFor="let manufacturer of type.manufacturers" (click)="onRemoveSelectedManufacturer(manufacturer.id)">
                <span>
                  {{ manufacturer.name }}
                </span>
                <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="d-none d-md-block" *ngIf="!isWishListHeaderHidden; else notfoundWishList">
      <div class="c-wish-list-header row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-2 col-lg-2 u-font-bold">
              {{ 'wishListItem.id' | cxTranslate }}
            </div>
            <div class="col-md-4 col-lg-4 u-font-bold">
              {{ 'wishListItem.articleName' | cxTranslate }}
            </div>
            <div class="col-md-2 col-lg-2 u-font-bold">
              {{ 'wishListItem.manufacturer' | cxTranslate }}
            </div>
            <div class="col-md-3 col-lg-3"></div>
            <div class=" col-md-1 col-lg-1"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="cx-item-list-row" *ngFor="let entry of type.wishList?.entries; let index=index">
      <app-efa-wish-list-item *ngIf="isManufacturerForEntrySelected(entry)" [cartEntry]="entry"
                              [isLoading]="loading$ | async" class="cx-wish-list-items"
                              (remove)="removeEntry($event)"></app-efa-wish-list-item>
    </div>
    <div class="back-to-top-box">
      <div class="btn btn-secondery wishlist-back-to-top" (click)="backToTop()">
        <cx-icon [type]="iconTypes.ARROW_LINE_UP" class="mr-2"></cx-icon>
        <span> {{ 'wishListItem.backToTop' | cxTranslate }}</span>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyWishList>
  <div class="empty-wish-list">
    <h4 class="mb-3 text-center text-md-left">{{ 'wishlist.empty' | cxTranslate }}</h4>
  </div>
</ng-template>

<ng-template #notfoundWishList>
  <h4 class="mb-3 text-center text-md-left">{{ 'wishlist.notfound' | cxTranslate }}</h4>
</ng-template>
