<ng-container *ngIf="{
    isLoading: isLoading$ | async,
    returnDetails: returnDetails$ | async
  } as type">
  <ng-container *ngIf="type.returnDetails && !type.isLoading; else loading">
    <div class="c-return-details">
      <div class="return-header-and-table">
        <app-return-history-header [returnNumber]="type.returnDetails?.returnNumber"
        [creationDate]="type.returnDetails?.creationDate"
        [deliveryNoteNumber]="type.returnDetails?.deliveryNoteNumber"
        [statusDisplay]="type.returnDetails?.statusDisplay" >
        </app-return-history-header>
        <div class="table-title">
          {{ 'returnDetails.tableTitle' | cxTranslate }}
        </div>
        <table class="c-return-details__table table table--responsive">
          <thead>
          <tr>
            <th scope="col">{{ 'returnDetails.positionNumber' | cxTranslate }}</th>
            <th scope="col" class="col-article">{{ 'returnDetails.article' | cxTranslate }}</th>
            <th scope="col" class="col-quantity">{{ 'returnDetails.quantity' | cxTranslate }}</th>
            <th scope="col">{{ 'returnDetails.order' | cxTranslate }}</th>
            <th scope="col">{{ 'returnDetails.returnableReason' | cxTranslate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let position of type.returnDetails?.positions">
            <td attr.data-label="{{ 'returnDetails.positionNumber' | cxTranslate }}">
              {{ position?.positionNumber }}
            </td>
            <td attr.data-label="{{ 'returnDetails.article' | cxTranslate }}">
              <p style="font-weight: 700;">{{ position?.articleName }}</p>
              <p> <span>{{ 'returnDetails.oemNumber' | cxTranslate }}:</span> {{ position?.oemNumber }}</p>
            </td>
            <td attr.data-label="{{ 'returnDetails.quantity' | cxTranslate }}">
              <p><span>{{ 'returnDetails.returnedQuantity' | cxTranslate }}:</span> {{ position?.returnedQuantity }}</p>
              <p><span>{{ 'returnDetails.requestedReturnQuantity' | cxTranslate }}:</span> {{ position?.requestedReturnQuantity }}</p>
              <p> <span>{{ 'returnDetails.positionStatusDisplay' | cxTranslate }}:</span> {{ position?.statusDisplay }}</p>
            </td>
            <td attr.data-label="{{ 'returnDetails.order' | cxTranslate }}">
              <p><span>{{ 'returnDetails.orderNumber' | cxTranslate }}:</span> {{ position?.orderNumber }}</p>
              <p><span>{{ 'returnDetails.orderPositionNumber' | cxTranslate }}:</span> {{ position?.orderPositionNumber }}</p>
              <p><span>{{ 'returnDetails.consignmentInfo' | cxTranslate }}:</span> {{ position?.consignmentInfo }}</p>
            </td>
            <td attr.data-label="{{ 'returnDetails.returnableReason' | cxTranslate }}">
              <p>{{ position?.reasonName }}</p>
              <ng-container *ngIf="position?.comment">
                <p>{{ 'returnDetails.comment' | cxTranslate }}: {{ position?.comment }}</p>
              </ng-container>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="c-return-details__bottom">
        <button class="btn btn-block btn-primary" (click)="goBack()"
                [routerLink]="{  cxRoute: 'returnsOverview' } | cxUrl">
          {{ 'common.back' | cxTranslate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <ng-container *ngIf="type.isLoading">
      <div class="cx-spinner">
        <cx-spinner></cx-spinner>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
