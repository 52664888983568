import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as ReturnCreationFeature from '../reducers/return-creation.reducer';
import { RETURN_CREATION_FEATURE_KEY } from '../reducers/return-creation.reducer';
import { getReturnCreationState, ReturnCreationState } from '../reducers';
import { ReturnCreationResponse } from '../../model/return-creation.model';

export const selectProcessing: MemoizedSelector<ReturnCreationFeature.ReturnCreationState, boolean> = createSelector(
  getReturnCreationState,
  (state: ReturnCreationState) => state[RETURN_CREATION_FEATURE_KEY].processing
);

export const selectSuccess: MemoizedSelector<ReturnCreationFeature.ReturnCreationState, boolean> = createSelector(
  getReturnCreationState,
  (state: ReturnCreationState) => state[RETURN_CREATION_FEATURE_KEY].success
);

export const selectResponse: MemoizedSelector<ReturnCreationFeature.ReturnCreationState, ReturnCreationResponse> =
  createSelector(
    getReturnCreationState,
    (state: ReturnCreationState) => state[RETURN_CREATION_FEATURE_KEY].response
  );
