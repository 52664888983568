export enum ICON_TYPE {
  ADD_CIRCLE = 'ADD_CIRCLE',
  RESET = 'RESET',
  PDF = 'PDF',
  DELETE = 'DELETE',
  CARET_RIGHT = 'CARET_RIGHT',
  DOWNLOAD_BLUE = 'DOWNLOAD_BLUE',
  HINT = 'HINT',
  CHECK_CIRCLE_FILLED_SUCCESS = 'CHECK_CIRCLE_FILLED_SUCCESS',
  NUMBER_CIRCLE_OUTLINE_2 = 'NUMBER_CIRCLE_OUTLINE_2',
  NUMBER_CIRCLE_OUTLINE_3 = 'NUMBER_CIRCLE_OUTLINE_3',
  NUMBER_CIRCLE_OUTLINE_4 = 'NUMBER_CIRCLE_OUTLINE_4',
  NUMBER_CIRCLE_OUTLINE_5 = 'NUMBER_CIRCLE_OUTLINE_5',
  NUMBER_CIRCLE_FILLED_1 = 'NUMBER_CIRCLE_FILLED_1',
  NUMBER_CIRCLE_FILLED_2 = 'NUMBER_CIRCLE_FILLED_2',
  NUMBER_CIRCLE_FILLED_3 = 'NUMBER_CIRCLE_FILLED_3',
  NUMBER_CIRCLE_FILLED_4 = 'NUMBER_CIRCLE_FILLED_4',
  NUMBER_CIRCLE_FILLED_5 = 'NUMBER_CIRCLE_FILLED_5',
}

type NumberIconType = {
  circleOutline: ICON_TYPE;
  circleFilled: ICON_TYPE;
};

export const numberToIcon: Record<number, NumberIconType> = {
  1: {
    circleOutline: undefined,
    circleFilled: ICON_TYPE.NUMBER_CIRCLE_FILLED_1,
  },
  2: {
    circleOutline: ICON_TYPE.NUMBER_CIRCLE_OUTLINE_2,
    circleFilled: ICON_TYPE.NUMBER_CIRCLE_FILLED_2,
  },
  3: {
    circleOutline: ICON_TYPE.NUMBER_CIRCLE_OUTLINE_3,
    circleFilled: ICON_TYPE.NUMBER_CIRCLE_FILLED_3,
  },
  4: {
    circleOutline: ICON_TYPE.NUMBER_CIRCLE_OUTLINE_4,
    circleFilled: ICON_TYPE.NUMBER_CIRCLE_FILLED_4,
  },
  5: {
    circleOutline: ICON_TYPE.NUMBER_CIRCLE_OUTLINE_5,
    circleFilled: ICON_TYPE.NUMBER_CIRCLE_FILLED_5,
  },
};

export enum RegistrationStatus {
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
}
