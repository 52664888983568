export const orderHistory = {
  orderHistoryCustom: {
    positionsList: {
      empty: 'The order does not contain any positions.'
    },
    orderOverviewFilterForm: {
      orderNumber: {
        label: 'Order number',
        placeholder: 'Order number'
      },
      orderState: {
        label: 'Status',
        placeholder: 'Status'
      },
      shippingAddresses: {
        label: 'Shipping addresses',
        placeholder: 'shipping addresses'
      },
      dateFrom: {
        label: 'Date from',
        placeholder: 'Date from'
      },
      dateTo: {
        label: 'Date to',
        placeholder: 'Date to'
      },
      consignmentInfo: {
        label: 'Additional information',
        placeholder: 'Additional information'
      },
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Sort order history',
      sapOrderNumber: 'Order number',
      consignmentInfo: 'Additional information',
      statusDisplay: 'Status',
      originName: 'Origin',
      placed: 'Creation date',
      orderTypeName: 'Order type',
      receiverAddressId: 'Receiver address ID',
      total: 'Total (net)',
      orderDetails: {
        orderType: 'Order type',
        totalPrice: 'Total (net)',
        consignmentInfo: 'Your order reference/shipping label information',
        placedBy: 'Placed By',
        requestedDeliveryDate: 'Requested delivery date',
        originName: 'Origin',
        orderInfoFields: {
          emptyFieldText: 'Not specified',
        },
        status: 'Status',
        placedOn: 'Created at',
        orderItems: 'Ordered Items',
      },
      pricesNotice: 'Prices can contain proportional delivery costs.'
    },
    globalMessage: {
      orderDetailsLoadFailure: 'An error occurs when loading the details of the order.',
      orderHistoryLoadFailure: 'An error occurs when loading the order history.',
    },
    cancellation: 'Cancel order'
  }
};
