<div *ngIf="loading$ | async; else consentManagementForm">
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</div>

<ng-template #consentManagementForm>
  <ng-container *ngIf="templateList$ | async as templateList">
    <div class="cx-consent-toggles">
      <div class="col-sm-12 col-md-8 col-lg-6">
        <app-efa-consent-management-form #test
          *ngFor="let consentTemplate of templateList"
          [consentTemplate]="consentTemplate"
          [requiredConsents]="requiredConsents"
          (consentChanged)="onConsentChange($event)"></app-efa-consent-management-form>
      </div>
    </div>
  </ng-container>
</ng-template>
