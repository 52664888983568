import { Component } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { InvoiceCreditOverviewComponent } from '../invoice-credit-overview/invoice-credit-overview.component';

@Component({
  selector: 'app-credit-bonus-overview',
  templateUrl: './credit-bonus-overview.component.html',
})
export class CreditBonusOverviewComponent extends InvoiceCreditOverviewComponent {

  private VIEW_TYPE_BONUS = 'bonuses';

  protected getViewType(): string {
    return this.VIEW_TYPE_BONUS;
  }
  onDowloadSingleDocument(invoiceCreditDocumentNumber: string): void {
    this.selectedInvoiceCreditDocumentNumbers = []
    this.selectedInvoiceCreditDocumentNumbers.push(invoiceCreditDocumentNumber);
    this.onDownloadMultipleDocuments();
  }

  protected getDocumentFileNamesTranslations(): Observable<[string, string]> {
    return combineLatest([
      this.translation.translate('invoicesCreditsCustom.invoiceCreditOverview.downloadCreditDocumentFileNamePrefix'),
      this.translation.translate('invoicesCreditsCustom.invoiceCreditOverview.downloadCreditDocumentsCompressedFileNamePrefix')
    ]);
  }
}
