import { Component, Input, OnInit } from '@angular/core';
import {
  CmsBannerComponent,
  CmsService,
  SemanticPathService,
} from '@spartacus/core';
import { BannerComponent, CmsComponentData } from '@spartacus/storefront';
import { Observable, filter, of, tap } from 'rxjs';

@Component({
  selector: 'app-efa-banner',
  templateUrl: './efa-banner.component.html',
})
export class EfaBannerComponent extends BannerComponent implements OnInit {
  @Input()
  useInputBanner: boolean;

  @Input()
  inputBanner$: Observable<CmsBannerComponent>;

  constructor(
    protected component: CmsComponentData<CmsBannerComponent>,
    protected urlService: SemanticPathService,
    protected cmsService: CmsService
  ) {
    super(component, urlService, cmsService);
  }

  ngOnInit(): void {
    if (this.useInputBanner) {
      this.data$ = this.inputBanner$.pipe(
        filter(Boolean),
        tap((data) => {
          this.setRouterLink(data);
          this.styleClasses = data.styleClasses;
        })
      );
    }
  }
}
