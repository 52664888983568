import { createAction, props } from '@ngrx/store';

import { DeliveryNoteOverviewRequest, DeliveryNoteOverviewResponse } from '../../model/delivery-notes.model';
import { ErrorType } from '@shared/models/ErrorTypes';

export const loadDeliveryNoteOverview = createAction(
  '[DeliveryNotes] Load Delivery Note Overview',
  props<{request: DeliveryNoteOverviewRequest}>()
);

export const loadDeliveryNoteOverviewSuccess = createAction(
  '[DeliveryNotes] Load Delivery Note Overview Success',
  props<{response: DeliveryNoteOverviewResponse}>()
);

export const loadDeliveryNoteOverviewFailure = createAction(
  '[DeliveryNotes] Load Delivery Note Overview Failure',
  props<{errorResponse: ErrorType}>()
);
