<div class="top-title">
  <h3>
    {{ 'orgUser.create.title' | cxTranslate }}
  </h3>
</div>
<cx-org-form i18nRoot="orgUser">
  <ng-container main *ngIf="form" [formGroup]="form">
    <label class="full-width">
      <span class="label-content">{{
        'orgUser.title' | cxTranslate
      }}</span>
      <div class="form-group d-flex">
        <div class="col-3" *ngFor="let title of titles$ | async">
          <label class="form-check form-check-inline">
            <input type="radio"
                       formControlName="titleCode"
                       [value]="title.code"/>
                       {{ 'orgUser.gender.'+title.code | cxTranslate }}
          </label>
        </div>
      </div>
      <cx-form-errors [control]="form.get('titleCode')"></cx-form-errors>
    </label>
    <label class=" full-width required">
      <span class="label-content">{{
        'orgUser.firstName' | cxTranslate
      }}</span>
      <input
        type="text"
        class="form-control"
        required
        placeholder="{{ 'orgUser.firstName' | cxTranslate }}"
        formControlName="firstName"
      />
      <cx-form-errors [control]="form.get('firstName')"></cx-form-errors>
    </label>

    <label class=" full-width required">
      <span class="label-content">{{
        'orgUser.lastName' | cxTranslate
      }}</span>
      <input
        type="text"
        class="form-control"
        required
        placeholder="{{ 'orgUser.lastName' | cxTranslate }}"
        formControlName="lastName"
      />
      <cx-form-errors [control]="form.get('lastName')"></cx-form-errors>
    </label>

    <label class="full-width required">
      <span class="label-content">{{
        'orgUser.email' | cxTranslate
      }}</span>
      <input
        class="form-control"
        type="email"
        required
        placeholder="{{ 'orgUser.email' | cxTranslate }}"
        formControlName="email"
      />
      <cx-form-errors [control]="form.get('email')"></cx-form-errors>
    </label>

    <fieldset aria-required="true" class="full-width" formArrayName="roles" hidden>
      <legend class="label-content required">
        {{ 'orgUser.roles' | cxTranslate }}
      </legend>

      <label
        class="form-check"
        *ngFor="let role of availableRoles; let i = index"
      >
        <input
          type="checkbox"
          class="form-check-input"
          [value]="role"
          [checked]="roles?.value?.includes(role)"
          (change)="updateRoles($event)"
          [disabled]="form?.status === 'DISABLED'"
        />
        <span class="form-check-label">
          {{ 'organization.userRoles.' + role | cxTranslate }}
        </span>
      </label>
    </fieldset>

    <label aria-required="true" [formGroup]="form.get('orgUnit')">
      <span class="label-content required">{{
        'orgUser.orgUnit' | cxTranslate
      }}</span>
      <ng-select
        formControlName="uid"
        [searchable]="true"
        [clearable]="false"
        [items]="units$ | async"
        bindLabel="name"
        bindValue="id"
        appendTo="cx-org-list"
        [readonly]="true"
        [placeholder]="'orgUser.orgUnit' | cxTranslate"
      >
      </ng-select>
      <cx-form-errors [control]="form.get('orgUnit.uid')"></cx-form-errors>
    </label>

  </ng-container>
</cx-org-form>
