import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as RegistrationStartFeature from '../reducers/registration-start.reducer';
import { REGISTRATION_START_FEATURE_KEY } from '../reducers/registration-start.reducer';
import { getLoginRegistrationState, LoginRegistrationState } from '../reducers';

export const selectLoading: MemoizedSelector<RegistrationStartFeature.RegistrationStartState, boolean>
  = createSelector(
  getLoginRegistrationState,
  (state: LoginRegistrationState) => state[REGISTRATION_START_FEATURE_KEY].sending
);

export const selectSuccess: MemoizedSelector<RegistrationStartFeature.RegistrationStartState, boolean>
  = createSelector(
  getLoginRegistrationState,
  (state: LoginRegistrationState) => state[REGISTRATION_START_FEATURE_KEY].success
);
