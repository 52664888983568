import { Component, OnInit } from '@angular/core';
import { SortingComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-efa-order-sorting',
  templateUrl: './efa-order-sorting.component.html'
})
export class EfaOrderSortingComponent extends SortingComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
