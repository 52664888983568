import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { produce } from 'immer';
import { OrderInfoFieldValue } from '../../model/cart-administration.model';
import { SetCartEntryInfosActions } from '../actions';
import { setCartEntryInfosSuccess } from '../actions/set-cart-entry-infos.action';

export const CART_ENTRY_INFOS_FEATURE_KEY = 'CartEntryInfos';

export interface CartEntryInfosState {
  updating: boolean;
  entryNumber: number;
  consignmentInfo: string;
  orderInfoFieldValues: OrderInfoFieldValue[];
  cartEntryInfoType: string;
  isAllSelected: boolean;
  error: ErrorType;
}

export const initialState: CartEntryInfosState = {
  updating: false,
  entryNumber: undefined,
  consignmentInfo: null,
  orderInfoFieldValues: [],
  cartEntryInfoType: undefined,
  isAllSelected: false,
  error: null,
};

export const setCartEntryInfosReducer = createReducer(
  initialState,

  on(SetCartEntryInfosActions.setCartEntryInfos, (state, action) => ({
    ...state,
    updating: true,
    entryNumber: action.entryNumber,
    consignmentInfo: action.consignmentInfo,
    orderInfoFieldValues: action.orderInfoFieldValues,
    cartEntryInfoType: action.cartEntryInfoType,
  })),
  on(SetCartEntryInfosActions.setCartEntryInfosSuccess, (state, action) => ({
    ...state,
    updating: false,
    entryNumber: action.entryNumber,
    consignmentInfo: action.consignmentInfo,
    orderInfoFieldValues: action.orderInfoFieldValues,
  })),
  on(SetCartEntryInfosActions.setCartEntryInfosFailure, (state, action) => ({
    ...state,
    updating: false,
    error: action.error,
  })),
  on(SetCartEntryInfosActions.setIsAllManufacturerSelected, (state, action) => ({
    ...state,
    isAllSelected: action.isAllSelected
  }))
);

export function metaReducerFactory(): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const newState = updateCartState(action, state);
    return reducer(newState, action);
  };
}

/* tslint:disable:no-string-literal */
function updateCartState(action: Action, state): any {
  if (action.type === setCartEntryInfosSuccess.type) {
    const actionConsignmentInfo: string = action['consignmentInfo'];
    const actionOrderInfoFieldValues: OrderInfoFieldValue[] = action['orderInfoFieldValues'];

    if (actionConsignmentInfo !== undefined) {
      return produce(state, (draftState) => {
        const entry = draftState.cart.carts.entities[action['cartId']].value.entries.find(en => en.entryNumber === action['entryNumber']);
        if (entry !== undefined) {
          entry.consignmentInfo = actionConsignmentInfo;
        }
      });
    } else if (actionOrderInfoFieldValues.length === 1) {
      return produce(state, (draftState) => {
          const entry = draftState.cart.carts.entities[action['cartId']].value.entries.find(en => en.entryNumber === action['entryNumber']);
          if (entry !== undefined) {
            const draftItem = entry.orderInfoFields.find(
              (orderInfoFieldValue: OrderInfoFieldValue) => {
                return orderInfoFieldValue.orderInfoField.fieldId === actionOrderInfoFieldValues[0].orderInfoField.fieldId
              }
            );
            if (draftItem === undefined) {
              console.log("draftItem is undefined", action['cartId'], action['entryNumber'], actionOrderInfoFieldValues[0]);
            } else {
              draftItem.value = actionOrderInfoFieldValues[0].value;
            }
          }
        }
      );
    }
  } else {
    return state;
  }
}

/* tslint:enable:no-string-literal */
