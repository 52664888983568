import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { OccPartsLinkAccessAdapter } from "./adapters/impl/occ-parts-link-access.adapter";
import { PartsLinkAccessAdapter } from "./adapters/parts-link-access.adapter";

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers:[
    {
      provide: PartsLinkAccessAdapter,
      useClass: OccPartsLinkAccessAdapter
    }
  ]
})
export class PartsLinkAccessOccModule { }
