import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AllowedFileExtensionsResponse } from "@shared/models/shared.model";
import { AllowedFileExtensionsAdapter } from "@shared/occ/adapters/allowed-file-extensions.adapter";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { AllowedDocumentExtensionsActions } from '../actions';

@Injectable()
export class AllowedDocumentExtensionsEffect {

  loadAllowedDocumentExtensions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AllowedDocumentExtensionsActions.loadAllowedDocumentExtensions),
      concatMap((action) =>
        this.connector.loadAllowedFileExtensions(action.contextId).pipe(
          map((response: AllowedFileExtensionsResponse) => {
            return AllowedDocumentExtensionsActions.loadAllowedDocumentExtensionsSuccess({ response });;
          }),
          catchError((error) => {
            return of(AllowedDocumentExtensionsActions.loadAllowedDocumentExtensionsFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: AllowedFileExtensionsAdapter,
  ) {

  }
}