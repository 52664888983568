import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { OccRegistrationConfirmationAdapter } from './adapters/impl/occ-registration-confirmation.adapter';
import { OccRegistrationInformationMultiOptionFieldsValuesAdapter } from './adapters/impl/occ-registration-information-multi-option-fields-values.adapter';
import { OccRegistrationLoadAdapter } from './adapters/impl/occ-registration-load.adapter';
import { OccRegistrationStartAdapter } from './adapters/impl/occ-registration-start.adapter';
import { RegistrationConfirmationAdapter } from './adapters/registration-confirmation.adapter';
import { RegistrationInformationMultiOptionFieldsValuesAdapter } from './adapters/registration-information-multi-option-fields-values.adapter';
import { RegistrationLoadAdapter } from './adapters/registration-load.adapter';
import { RegistrationStartAdapter } from './adapters/registration-start.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: RegistrationStartAdapter,
      useClass: OccRegistrationStartAdapter,
    },
    {
      provide: RegistrationLoadAdapter,
      useClass: OccRegistrationLoadAdapter,
    },
    {
      provide: RegistrationConfirmationAdapter,
      useClass: OccRegistrationConfirmationAdapter,
    },
    {
      provide: RegistrationInformationMultiOptionFieldsValuesAdapter,
      useClass: OccRegistrationInformationMultiOptionFieldsValuesAdapter,
    }
  ],
})

export class OccModule {
}
