import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as ReturnPositionFeature from '../store/reducers/return-position.reducer';
import * as ReturnPositionSelectors from '../store/selectors/return-position.selectors';
import { ReturnablePosition, ReturnDocumentType } from '../model/return-creation.model';
import { ReturnPositionActions } from '../store/actions';


@Injectable({
  providedIn: 'root'
})
export class ReturnPositionService {
  constructor(protected store: Store<ReturnPositionFeature.ReturnPositionState>) {}

  saveReturnablePositions(positions: ReturnablePosition[]): void {
    this.store.dispatch(ReturnPositionActions.SetReturnablePositions({positions}));
  }

  reset(): void {
    this.store.dispatch(ReturnPositionActions.ResetReturnablePositions());
  }

  saveDocumentData(docNumber: string, docType: ReturnDocumentType, routeOrig: string): void {
    this.store.dispatch(ReturnPositionActions.SetDocumentData({
      documentNumber: docNumber,
      documentType: docType,
      routeOrigin: routeOrig,
    }));
  }

  getReturnablePositions(): Observable<ReturnablePosition[]> {
    return this.store.select(ReturnPositionSelectors.selectPositions);
  }

  getDocumentNumber(): Observable<string> {
    return this.store.select(ReturnPositionSelectors.selectDocumentNumber);
  }

  getDocumentType(): Observable<ReturnDocumentType> {
    return this.store.select(ReturnPositionSelectors.selectDocumentType);
  }

  getRouteOrigin(): Observable<string> {
    return this.store.select(ReturnPositionSelectors.selectRouteOrigin);
  }
}
