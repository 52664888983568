import { Component } from '@angular/core';

import { CancelOrderComponent, OrderAmendService } from '@spartacus/order/components';

@Component({
  selector: 'app-efa-cancel-order',
  templateUrl: './efa-cancel-order.component.html',
})
export class EfaCancelOrderComponent extends CancelOrderComponent {

  constructor(protected orderAmendService: OrderAmendService) {
    super(orderAmendService);
  }
}
