import { Component, OnDestroy, OnInit } from '@angular/core';
import { Address, GlobalMessageService, TranslationService } from '@spartacus/core';
import { Card } from '@spartacus/storefront';
import { AddressBookComponent, AddressBookComponentService } from "@spartacus/user/profile/components";
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UpdateCartArg } from '../../../cart-administration/model/update-cart.model';
import { B2BUserAccountService } from '../../../cart-administration/services/b2-b-user-account.service';
import { CartParametersService } from '../../../cart-administration/services/cart-parameters.service';
import { UpdateCartService } from '../../../cart-administration/services/update-cart.service';

@Component({
  selector: 'app-efa-address-book',
  templateUrl: './efa-address-book.component.html'
})
export class EfaAddressBookComponent extends AddressBookComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    public service: AddressBookComponentService,
    protected translation: TranslationService,
    globalMessageService: GlobalMessageService,
    private b2bUserAccountService: B2BUserAccountService,
    private cartParametersService: CartParametersService,
    private updateCartService: UpdateCartService
  ) {
    super(service, translation, globalMessageService);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getAddressCardContent(address: Address): Observable<Card> {
    return combineLatest([
      this.translation.translate('addressCard.default'),
      this.translation.translate('addressCard.setAsDefault'),
    ]).pipe(
      map(
        ([
          defaultText,
          setAsDefaultText,
        ]) => {
          const actions: { name: string; event: string }[] = [];
          if (!address.defaultAddress) {
            actions.push({ name: setAsDefaultText, event: 'default' });
          }

          return {
            text: [
              this.getEmptyStringIfUndefined(address.companyName),
              this.getEmptyStringIfUndefined(address.firstName) + ' ' + this.getEmptyStringIfUndefined(address.lastName),
              this.getEmptyStringIfUndefined(address.line1) + ' ' + this.getEmptyStringIfUndefined(address.line2),
              address.postalCode + ' ' + address.town,
              address.country?.name,
            ],
            actions,
            header: address.defaultAddress ? `${defaultText}` : '',
          };
        }
      )
    );
  }

  getEmptyStringIfUndefined(text: string): string {
    return (text === undefined ? '' : text);
  }

  override setAddressAsDefault(address: Address): void {
    this.service.setAddressAsDefault(address.id ?? '');
    this.b2bUserAccountService.shippingAddress = address;
    const cart = this.b2bUserAccountService.cart;

    if (cart && cart.entries?.length === 0) {
      const shippingAddress = this.b2bUserAccountService.shippingAddress;
      this.cartParametersService.setDeliveryAddress(shippingAddress);
      const shippingType = this.b2bUserAccountService.getDefaultShippingTypeFromAddress(shippingAddress);
      this.cartParametersService.setShippingTypeId(
        shippingType);

      const data: UpdateCartArg = {
        cartId: this.b2bUserAccountService.cartId,
        userId: this.b2bUserAccountService.userId,
        orderTypeId: cart.orderType?.id,
        shippingTypeId: shippingType?.code,
        addressId: shippingAddress?.id,
        requestedDeliveryDate: this.b2bUserAccountService.getCurrentRequestedDeliveryDateString(),
        calculate: false,
        reloadCart: true
      };
      this.updateCartService.updateCart(data);
    }
  }
}
