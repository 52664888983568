import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  AuthRedirectService,
  AuthStorageService,
  AuthToken,
  RoutingService,
  StateWithClientAuth,
  UserIdService
} from '@spartacus/core';
import { Base64 } from 'js-base64';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html'
})
export class SsoLoginComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  displayErrorMessage = false;
  termsAccepted = false;

  constructor(private route: ActivatedRoute,
              private authStorageService: AuthStorageService,
              protected authRedirectService: AuthRedirectService,
              protected store: Store<StateWithClientAuth>,
              protected userIdService: UserIdService,
              private routingService: RoutingService) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams
        .subscribe(params => {
            if (params.token != null && params.token !== '' && params.termsAccepted != null && params.termsAccepted !== '') {
              const authToken: AuthToken = JSON.parse(Base64.decode(decodeURIComponent(params.token)));
              this.termsAccepted = JSON.parse(params.termsAccepted);
              this.authStorageService.setToken(authToken);
              this.userIdService.setUserId('current');
              this.store.dispatch(new AuthActions.Login());

              if (!this.termsAccepted) {
                this.routingService.go({
                  cxRoute: 'termsAndConditionsConfirmation'
                });
              } else {
                this.routingService.go('/');
              }

            }
          }, (error: any) => {
            this.displayErrorMessage = true;
          }
        ));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
