import { createReducer, on } from "@ngrx/store";
import { DefaultManufacturers } from "../../model/default-manufacturer.model";
import { DefaultManufacturersActions } from '../actions';

export const DEFAULT_MANUFACTURERS_FEATURE_KEY = 'defaultManufacturers';

export interface DefaultManufacturersState {
  defaultManufactures: string;
  updating: boolean;
  success: boolean
}

export const initialState: DefaultManufacturersState = {
  defaultManufactures: undefined,
  updating: false,
  success: undefined
}

export const defaultManufacturersReducer = createReducer(
  initialState,

  on(DefaultManufacturersActions.setDefaultManufacturers, (state, action) => ({
    ...state,
    updating: true,
    defaultManufactures: action.defaultManufacturers
  })),
  on(DefaultManufacturersActions.setDefaultManufacturersSuccess, (state) => ({
    ...state,
    updating: false,
    success: true,
    defaultManufactures: undefined
  })),
  on(DefaultManufacturersActions.setDefaultManufacturersFailure, (state, action) => ({
    ...state,
    updating: false,
    success: false,
    error: action.errorResponse
  }))
);
