<app-efa-org-card
  *ngIf="model$ | async as model"
  i18nRoot="orgUser.details"
  [cxFocus]="{ refreshFocus: model }"
>
  <a
    actions
    class="link edit"
    [class.disabled]="!model.active || (isInEditMode$ | async)"
    [routerLink]="{ cxRoute: 'orgUserEdit', params: model } | cxUrl"
  >
    <!-- {{ 'organization.edit' | cxTranslate }} -->
    <cx-icon
    class="u-pointer"
    [type]="iconTypes.PENCIL">
    </cx-icon>
  </a>

  <app-efa-toggle-status
    actions
    key="customerId"
    i18nRoot="orgUser"
  ></app-efa-toggle-status>

  <cx-org-disable-info info i18nRoot="orgUser"></cx-org-disable-info>

  <section main class="details" cxOrgItemExists>
    <div class="property">
      <label>{{ 'orgUser.name' | cxTranslate }}</label>
      <span class="value">
        {{ model.name }}
      </span>
    </div>

    <div class="property full-width">
      <label>{{ 'orgUser.email' | cxTranslate }}</label>
      <span class="value">
        {{ model.email }}
      </span>
    </div>

    <div class="property">
      <label>{{ 'orgUser.roles' | cxTranslate }}</label>
      <ngContainer *ngIf="model.permissionGroupNames?.length>0; else noProfilesAssigned">
      <span class="value">
        {{ model.permissionGroupNames | joinBy }}
      </span>
      </ngContainer>
    </div>

    <ng-template #noProfilesAssigned>
      <div class="property full-width noProfilesAssigned">
        <span class="value"> {{ 'orgUser.noProfilesAssigned' | cxTranslate }}</span>
      </div>
    </ng-template>

    <div class="property full-width">
      <a
        *ngIf="model.customerId"
        class="link"
        [class.disabled]="!model.active"
        [routerLink]="
          { cxRoute: 'orgUserChangePassword', params: model } | cxUrl
        "
      >
        {{ 'orgUser.links.password' | cxTranslate }}
      </a>
    </div>
  </section>

  <section main class="link-list">
    <ng-container *ngIf="model.customerId">
      <a
        class="link"
        [routerLink]="{ cxRoute: 'orgUserUserGroups', params: model } | cxUrl"
        routerLinkActive="is-current"
      >
        {{ 'orgUser.links.userGroup' | cxTranslate }}
      </a>
    </ng-container>
  </section>
</app-efa-org-card>
