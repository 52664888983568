import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { UpdateCartArg } from '../../../model/update-cart.model';
import { UpdateCartAdapter } from '../update-cart.adapter';

@Injectable({providedIn: 'root'})
export class OccUpdateCartAdapter implements UpdateCartAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  updateCart(data: UpdateCartArg): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'updateCart',
      {
        urlParams: {
          userId: data.userId,
          cartId: data.cartId,
        }
      }
    );
    return this.http.post<any>(url, data, {headers});
  }
}
