import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AllowedFileExtensionsResponse} from '@shared/models/shared.model';
import {AllowedImageExtensionsService} from '@shared/services/allowed-image-extensions.service';
import {invalidFileExtension} from '@shared/utils/upload-file-extension';
import {exceedMaxFileSize} from '@shared/utils/upload-file-size';
import {validFileExtension} from '@shared/validators/file-extension';
import {Config, GlobalMessageType, Price, PriceType, TranslationService} from '@spartacus/core';
import {combineLatest, ReplaySubject, Subject, Subscription} from 'rxjs';
import {ICON_TYPE} from '../../model/transport-damage-icon.model';
import {TransportDamageImageData, TransportDamageImageType, TransportDamageRequest} from '../../model/transport-damage.model';
import {TransportDamageService} from '../../services/transport-damage.service';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

const COMMA_SPACE_SEPARATOR = ', ';
const TRANSPORT_DAMAGE_FORM_ID = 'transportDamageForm';

@Component({
  selector: 'app-transport-damage-formular',
  templateUrl: './transport-damage-formular.component.html',
})
export class TransportDamageFormularComponent implements OnInit, OnDestroy, AfterViewInit {
  isLoading$: Subject<boolean> = new ReplaySubject();
  transportDamageForm: UntypedFormGroup;
  iconTypes = ICON_TYPE;

  private repairPossible = false;
  private maxFileSize: number;
  private packagingImage: TransportDamageImageData;
  private damagedPartOfPackagingImage: TransportDamageImageData;
  private articleImage: TransportDamageImageData;
  private damagedPartOfArticleImage: TransportDamageImageData;
  private articleNumberImage: TransportDamageImageData;
  private shippingLabelImage: TransportDamageImageData;
  private additionalImage: TransportDamageImageData[] = [];
  private subscription: Subscription = new Subscription();
  private allowedImageExtensions: string[] = [];

  private readonly formProperties = {
    orderNumber: new UntypedFormControl('', Validators.required),
    deliveryNoteNumber: new UntypedFormControl('', Validators.required),
    oemNumber: new UntypedFormControl('', Validators.required),
    descriptionOfDamage: new UntypedFormControl('', Validators.required),
    additionalHints: new UntypedFormControl(''),
    repairPossible: new UntypedFormControl(undefined, Validators.required),
    repairCosts: new UntypedFormControl(undefined, [
      Validators.pattern('^[0-9]*.[0-9]{0,2}$'),
      Validators.max(10000000),
    ]),
    replacementWanted: new UntypedFormControl(undefined, Validators.required),
    packagingImage: new UntypedFormControl(undefined, Validators.required),
    damagedPartOfPackagingImage: new UntypedFormControl(
      undefined,
      Validators.required
    ),
    articleImage: new UntypedFormControl(undefined, Validators.required),
    damagedPartOfArticleImage: new UntypedFormControl(undefined, Validators.required),
    articleNumberImage: new UntypedFormControl(undefined, Validators.required),
    shippingLabelImage: new UntypedFormControl(undefined, Validators.required),
    additionalImage: new UntypedFormControl(undefined),
  };

  constructor(
    private transportDamageService: TransportDamageService,
    private allowedImageExtensionsService: AllowedImageExtensionsService,
    private globalMessageService: EfaGlobalMessageService,
    private formBuilder: UntypedFormBuilder,
    @Inject(Config) private config: any,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.maxFileSize = this.config.maxFileSize.transportDamageForm;
    this.transportDamageForm = this.formBuilder.group(this.formProperties);
    this.allowedImageExtensionsService.loadAllowedImageExtensions(TRANSPORT_DAMAGE_FORM_ID);
    this.subscription.add(
      this.allowedImageExtensionsService.getResponse().subscribe((allowedImageExtensions: AllowedFileExtensionsResponse) => {
        if (allowedImageExtensions != null && allowedImageExtensions.extensions && allowedImageExtensions.extensions.length > 0) {
          this.allowedImageExtensions.push(...allowedImageExtensions.extensions);
          this.addImageExtensionsValidators();
        }
      }));
    this.subscription.add(
      combineLatest([this.transportDamageService.isProcessing(), this.allowedImageExtensionsService.isLoading()])
        .subscribe(([isRequestProcessing, isAllowedImageExtensionsLoading]) => {
          this.isLoading$.next(isRequestProcessing || isAllowedImageExtensionsLoading);
        })
    );
    this.globalMessageService.add({
        key: 'transportDamage.notice', params: {hideCloseButton: true, styleClass: 'staticInfoMessage'}
      },
      GlobalMessageType.MSG_TYPE_WARNING
    );
  }

  ngAfterViewInit(): void {
    (document.getElementById('repairCosts') as HTMLInputElement).disabled = true;
  }

  submitData(): void {
    Object.values(this.transportDamageForm.controls).forEach((control: UntypedFormControl) => {
      control.markAsTouched();
    });

    if (this.transportDamageForm.invalid) {
      return;
    }

    if (this.repairPossible && this.transportDamageForm.get('repairCosts').value == null) {
      this.transportDamageForm.controls.repairCosts.setErrors({
        required: true
      });
      this.transportDamageForm.controls.repairCosts.markAsTouched();
      return;
    }

    const images = [];
    images.push(this.articleImage);
    images.push(this.articleNumberImage);
    images.push(this.damagedPartOfArticleImage);
    images.push(this.damagedPartOfPackagingImage);
    images.push(this.packagingImage);
    images.push(this.shippingLabelImage);
    if (this.additionalImage.length !== 0) {
      images.push(...this.additionalImage);
    }
    let repairCosts: Price;
    if (this.repairPossible) {
      repairCosts = {
        currencyIso: 'EUR',
        priceType: PriceType.BUY,
        value: this.transportDamageForm.get('repairCosts').value,
      };
    }

    const transportDamageRequest: TransportDamageRequest = {
      orderNumber:
      this.transportDamageForm.get('orderNumber').value,
      deliveryNoteNumber:
        this.transportDamageForm.get('deliveryNoteNumber').value,
      oemNumber: this.transportDamageForm.get('oemNumber').value,
      descriptionOfDamage: this.transportDamageForm.get('descriptionOfDamage')
        .value,
      additionalHints: this.transportDamageForm.get('additionalHints').value,
      repairPossible: this.transportDamageForm.get('repairPossible').value,
      replacementWanted:
        this.transportDamageForm.get('replacementWanted').value,
      images,
      repairCosts,
    };
    this.transportDamageService.adviseOfTransportDamage(transportDamageRequest);
    this.subscription.add(
      this.transportDamageService.success().subscribe((success: boolean) => {
        if (success) {
          this.resetFormFields();
          this.showAdviseOfTransportDamageSuccessMessage();
        }
      })
    );
  }

  private resetFormFields(): void {
    this.transportDamageForm.reset();
    this.additionalImage = [];
    this.packagingImage = undefined;
    this.articleImage = undefined;
    this.damagedPartOfArticleImage = undefined;
    this.damagedPartOfPackagingImage = undefined;
    this.articleNumberImage = undefined;
    this.shippingLabelImage = undefined;
    this.subscription.add(
      combineLatest([
        this.translationService.translate(
          'uploadFile.placeholder'
        ),
        this.translationService.translate(
          'uploadFiles.placeholder'
        ),
      ]).subscribe(
        ([
           singleFile,
           multipleFile
         ]) => {
          document.getElementById('packagingImage').innerHTML =
            singleFile as string;
          document.getElementById('articleImage').innerHTML =
            singleFile as string;
          document.getElementById('damagedPartOfArticleImage').innerHTML =
            singleFile as string;
          document.getElementById('damagedPartOfPackagingImage').innerHTML =
            singleFile as string;
          document.getElementById('articleNumberImage').innerHTML =
            singleFile as string;
          document.getElementById('additionalImage').innerHTML =
            multipleFile as string;
          document.getElementById('shippingLabelImage').innerHTML =
            singleFile as string;
        })
    );
    (document.getElementById('repairCosts') as HTMLInputElement).disabled = true;
  }

  private exceedMaxFileSize(file: File, imageTypeId: string): boolean {
    if (exceedMaxFileSize(file, this.maxFileSize)) {
      this.transportDamageForm.controls[imageTypeId].setErrors({
        maxFileSizeExceeded: {
          maxFileSize: this.config.maxFileSize.transportDamageForm,
        },
      });
      return true;
    }
    return false;
  }

  private readImage(
    file: File,
    imageTypeId: string,
    imageType: TransportDamageImageType
  ): void {
    const fr: FileReader = new FileReader();
    this.readImagePriv(file, imageTypeId, imageType, fr, () => {
      const self = this;
      self[imageTypeId] = this.createImage(fr, file, imageType);
      document.getElementById(imageTypeId).innerHTML = file.name;
    });
  }

  private readImages(
    file: File,
    imageTypeId: string,
    imageType: TransportDamageImageType
  ): void {
    const fr: FileReader = new FileReader();
    this.readImagePriv(file, imageTypeId, imageType, fr, () => {
      const self = this;
      self[imageTypeId].push(this.createImage(fr, file, imageType));
      document.getElementById(imageTypeId).innerHTML = self[imageTypeId]
        .map((d) => d.fileName)
        .join(COMMA_SPACE_SEPARATOR);
    });
  }

  private readImagePriv(
    file: File,
    imageTypeId: string,
    imageType: TransportDamageImageType,
    fr: FileReader,
    func: () => void
  ): void {
    fr.onloadend = func;
    fr.readAsBinaryString(file);
  }

  private createImage(
    fr: FileReader,
    file: File,
    imageType: TransportDamageImageType
  ): TransportDamageImageData {
    return {
      encodedContent: btoa(fr.result.toString()),
      fileName: file.name,
      type: imageType,
    };
  }

  private showAdviseOfTransportDamageSuccessMessage(): void {
    this.globalMessageService.add(
      {
        key: 'transportDamage.globalMessage.adviseOfTransportDamageSuccess',
      },
      GlobalMessageType.MSG_TYPE_INFO
    );
  }

  public onAdditionalImageChange(event: any, imageTypeId: string): void {
    const fileList: FileList = event.target.files;
    const fileArray: File[] = Array.from(fileList);
    for (const file of fileArray) {
      if (this.exceedMaxFileSize(file, imageTypeId) || this.invalidFileExtension(file, imageTypeId)) {
        return;
      }
    }
    fileArray.forEach((file: File) => {
      this.readImages(
        file,
        imageTypeId,
        TransportDamageImageType.ADDITIONAL
      );
    });
  }

  public onPackagingImageChange(event: any, imageTypeId: string): void {
    const fileList: FileList = event.target.files;
    if (this.exceedMaxFileSize(fileList[0], imageTypeId)) {
      return;
    }
    this.readImage(
      fileList[0],
      imageTypeId,
      TransportDamageImageType.PACKAGING
    );
  }

  public onDamagedPartOfPackagingImageChange(
    event: any,
    imageTypeId: string
  ): void {
    const fileList: FileList = event.target.files;
    if (this.exceedMaxFileSize(fileList[0], imageTypeId)) {
      return;
    }
    this.readImage(
      fileList[0],
      imageTypeId,
      TransportDamageImageType.DAMAGEDPARTOFPACKAGING
    );
  }

  public onArticleImageChange(event: any, imageTypeId: string): void {
    const fileList: FileList = event.target.files;
    if (this.exceedMaxFileSize(fileList[0], imageTypeId)) {
      return;
    }
    this.readImage(
      fileList[0],
      imageTypeId,
      TransportDamageImageType.ARTICLE
    );
  }

  public onDamagedPartOfArticleImageChange(
    event: any,
    imageTypeId: string
  ): void {
    const fileList: FileList = event.target.files;
    if (this.exceedMaxFileSize(fileList[0], imageTypeId)) {
      return;
    }
    this.readImage(
      fileList[0],
      imageTypeId,
      TransportDamageImageType.DAMAGEDPARTOFARTICLE
    );
  }

  public onArticleNumberImageChange(event: any, imageTypeId: string): void {
    const fileList: FileList = event.target.files;
    if (this.exceedMaxFileSize(fileList[0], imageTypeId)) {
      return;
    }
    this.readImage(
      fileList[0],
      imageTypeId,
      TransportDamageImageType.ARTICLENUMBER
    );
  }

  public onShippingLabelImageChange(event: any, imageTypeId: string): void {
    const fileList: FileList = event.target.files;
    if (this.exceedMaxFileSize(fileList[0], imageTypeId)) {
      return;
    }
    this.readImage(
      fileList[0],
      imageTypeId,
      TransportDamageImageType.SHIPPINGLABEL
    );
  }

  private invalidFileExtension(file: File, imageTypeId: string): boolean {
    if (file.size === 0) {
      this.transportDamageForm.controls[imageTypeId].setErrors({
        fileEmpty: true
      });
    } else if (this.allowedImageExtensions.length > 0 && invalidFileExtension(file, this.allowedImageExtensions)) {
      this.transportDamageForm.controls[imageTypeId].setErrors({
        validFileExtension: {
          fileExtensions: this.allowedImageExtensions.join(COMMA_SPACE_SEPARATOR)
        }
      });
      return true;
    }
    return false;
  }

  public removeSelectedFile(imageTypeId: string): void {
    let uploadTranslationKey: string;
    if (imageTypeId === 'additionalImage') {
      uploadTranslationKey = 'uploadFiles.placeholder';
      this.additionalImage = [];
    } else {
      uploadTranslationKey = 'uploadFile.placeholder';
      this[imageTypeId] = undefined;
    }
    this.subscription.add(
      this.translationService
        .translate(uploadTranslationKey)
        .subscribe((value: string) => {
          document.getElementById(imageTypeId).innerHTML = value;
          this.transportDamageForm.controls[imageTypeId].reset();
          this.transportDamageForm.controls[imageTypeId].markAsTouched();
        })
    );
  }

  onRepairPossibleChange(repairPossible: any): void {
    this.repairPossible = Boolean(JSON.parse(repairPossible));
    if (!this.repairPossible) {
      this.transportDamageForm.patchValue({
        repairCosts: undefined
      });
    }
    (document.getElementById('repairCosts') as HTMLInputElement).disabled = !this.repairPossible;
  }

  private addImageExtensionsValidators(): void {
    this.transportDamageForm.controls.packagingImage.addValidators(validFileExtension(this.allowedImageExtensions));
    this.transportDamageForm.controls.damagedPartOfPackagingImage.addValidators(validFileExtension(this.allowedImageExtensions));
    this.transportDamageForm.controls.articleImage.addValidators(validFileExtension(this.allowedImageExtensions));
    this.transportDamageForm.controls.damagedPartOfArticleImage.addValidators(validFileExtension(this.allowedImageExtensions));
    this.transportDamageForm.controls.articleNumberImage.addValidators(validFileExtension(this.allowedImageExtensions));
    this.transportDamageForm.controls.shippingLabelImage.addValidators(validFileExtension(this.allowedImageExtensions));
  }

  clearForm(): void {
    this.resetFormFields();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
