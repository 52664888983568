import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';

import {combineLatest, from, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {filter, first, map, switchMap, take} from 'rxjs/operators';
import {Config, GlobalMessageType, PaginationModel, SortModel, TranslationService} from '@spartacus/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import {
  InvoiceCreditDocument,
  InvoiceCreditOverviewFilter,
  InvoicesCreditOverviewRequest,
  InvoicesCreditOverviewResponse
} from '../../model/invoices-credits.model';
import {InvoiceCreditOverviewService} from '../../services/invoice-credit-overview.service';
import {InvoiceCreditUiService} from '../../services/invoice-credit-ui.service';
import {ICON_TYPE} from '../../model/invoices-credits-icon.model';
import {InvoiceCreditDetailsService} from '../../services/invoice-credit-details.service';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {getInitialDateFrom} from '@shared/utils/date-tools';

const DATE_FORMAT_FOR_DOWNLOADED_DOCUMENTS = 'yyyy-MM-dd';
const ZIP_EXTENSION = '.zip';
const PDF_EXTENSION = '.pdf';

@Component({
  selector: 'app-invoice-credit-overview',
  templateUrl: './invoice-credit-overview.component.html',
})
export class InvoiceCreditOverviewComponent implements OnInit, OnDestroy {

  constructor(
    protected translation: TranslationService,
    private invoiceCreditOverviewService: InvoiceCreditOverviewService,
    private invoiceCreditDetailsService: InvoiceCreditDetailsService,
    private invoiceCreditUiService: InvoiceCreditUiService,
    private globalMessageService: EfaGlobalMessageService,
    private datePipe: DatePipe,
    @Inject(Config) private config: any,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }
  invoicesCredits$: Observable<InvoicesCreditOverviewResponse>;
  isLoading$: Observable<boolean>;
  isDownloading$: Subject<boolean> = new ReplaySubject();
  iconTypes = ICON_TYPE;
  invoiceCreditOverviewFilter: InvoiceCreditOverviewFilter = {
    documentNumber: '',
    oemNumber: '',
    orderNumber: '',
    dateFrom: getInitialDateFrom(this.config.dateFrom.invoiceCreditOverview),
    dateTo: undefined,
    documentTypeId: '',
    deliveryNoteNumber: '',
    consignmentInfo: '',
  };
  isAllInvoiceCreditDocumentsSelected = false;
  selectedInvoiceCreditDocumentNumbers: string[] = [];

  private subscription: Subscription = new Subscription();
  private sortCode = 'creationDate';
  private currentPage = 0;
  private pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.config.pageSize.invoiceCreditOverview,
    sort: '',
  };
  private sort: SortModel = {
    code: '',
    name: '',
    selected: false
  };
  private VIEW_TYPE_INVOICE = 'invoicesCredits';
  private isMultipleDownloadClicked = false;
  private downloadClicked = false;

  private static formatFilterDate(date: NgbDateStruct): string {
    return date ? date.year + '-' + date.month + '-' + date.day : '';
  }

  ngOnInit(): void {
    this.invoiceCreditUiService.setSelectedPagination(this.pagination);
    this.invoiceCreditUiService.setSelectedSort(this.sort);
    this.isLoading$ = combineLatest([
      this.invoiceCreditOverviewService.isLoading(),
      this.invoiceCreditDetailsService.isLoading(),
    ]).pipe(
      map(
        ([isInvoiceCreditOverviewLoading, isInvoiceCreditDetailsLoading]) =>
          isInvoiceCreditOverviewLoading || isInvoiceCreditDetailsLoading
      )
    );
    this.invoicesCredits$ = this.invoiceCreditOverviewService.getResponse();
    this.subscription.add(
      combineLatest([
        this.translation.translate('invoicesCreditsCustom.invoiceCreditOverview.downloadInvoiceCreditDocumentFileNamePrefix'),
        this.invoiceCreditDetailsService.isLoading().pipe(
          filter((isLoading: boolean) => !isLoading),
          switchMap(i => this.invoiceCreditDetailsService.getResponse())
        )
      ]).subscribe(([invoiceCreditDocumentFileNamePrefixTranslation, invoicesCreditDetails]) => {
        if (invoicesCreditDetails !== undefined && this.downloadClicked && !this.isMultipleDownloadClicked) {
          this.download(
            `${invoiceCreditDocumentFileNamePrefixTranslation}_${invoicesCreditDetails.documentNumber}`,
            invoicesCreditDetails.encodedContent
          );
        }
      })
    );
    this.fetchDocuments();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected getViewType(): string {
    return this.VIEW_TYPE_INVOICE;
  }

  // tslint:disable-next-line:no-shadowed-variable
  setOrderOverviewFilter(filter: InvoiceCreditOverviewFilter): void {
    this.invoiceCreditOverviewFilter = filter;
    // make sure first page of new result is displayed
    this.currentPage = 0;
    this.fetchDocuments();
  }

  changeSortCode(sortCode: string): void {
    this.sortCode = sortCode;
    this.invoiceCreditUiService.setSelectedSort({
      code: sortCode,
      selected: true
    });
    this.fetchDocuments();
  }

  pageChange(page: number): void {
    this.currentPage = page;
    this.invoiceCreditUiService.setSelectedPagination({
      currentPage: page,
      pageSize: this.config.pageSize.invoiceCreditOverview,
    });
    this.fetchDocuments();
  }

  getSortLabels(): Observable<{ byDate: string; byOrderNumber: string, byTotal: string, byDocumentType: string }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.orderNumber'),
      this.translation.translate('sorting.total'),
      this.translation.translate('sorting.documentType'),
    ]).pipe(
      map(([textByDate, textByOrderNumber, textByTotal, textByDocumentType]) => {
        return {
          byDate: textByDate,
          byOrderNumber: textByOrderNumber,
          byTotal: textByTotal,
          byDocumentType: textByDocumentType,
        };
      })
    );
  }

  private fetchDocuments(): void {
    const invoicesCreditOverviewRequest: InvoicesCreditOverviewRequest = {
      documentNumber: this.invoiceCreditOverviewFilter.documentNumber,
      oemNumber: this.invoiceCreditOverviewFilter.oemNumber,
      orderNumber: this.invoiceCreditOverviewFilter.orderNumber,
      dateTo: InvoiceCreditOverviewComponent.formatFilterDate(this.invoiceCreditOverviewFilter.dateTo),
      dateFrom: InvoiceCreditOverviewComponent.formatFilterDate(this.invoiceCreditOverviewFilter.dateFrom),
      documentTypeId: this.invoiceCreditOverviewFilter.documentTypeId,
      deliveryNoteNumber: this.invoiceCreditOverviewFilter.deliveryNoteNumber,
      consignmentInfo: this.invoiceCreditOverviewFilter.consignmentInfo,
      viewType: this.getViewType(),
      sort: {
        code: this.sortCode,
        selected: true
      },
      pagination: {
        currentPage: this.currentPage,
        pageSize: this.config.pageSize.invoiceCreditOverview,
        sort: this.sortCode,
      }
    };
    this.invoiceCreditOverviewService.loadInvoiceOverview(invoicesCreditOverviewRequest);
  }

  getDocumentDetails(documentNumber: string): void {
    this.downloadClicked = true;
    this.invoiceCreditDetailsService.loadInvoiceDetails(documentNumber);
  }

  private download(fileName: string, data: string): void {
    if (data === undefined || data.length === 0)
    {
      this.globalMessageService.add(
        {key: 'invoicesCreditsCustom.globalMessage.downloadContentEmpty'},
        GlobalMessageType.MSG_TYPE_INFO
      );
    }
    else {

      const fileContent = 'application/pdf';
      const source = `data:${fileContent};base64,${data}`;
      const link = this.document.createElement('a');

      link.href = source;
      link.download = `${fileName}.pdf`;
      link.click();
      this.downloadClicked = false;
    }
  }

  onAllSelectedChange(invoiceCreditDocuments: InvoiceCreditDocument[]): void {
    this.selectedInvoiceCreditDocumentNumbers.splice(0, this.selectedInvoiceCreditDocumentNumbers.length);
    this.isAllInvoiceCreditDocumentsSelected = !this.isAllInvoiceCreditDocumentsSelected;
    if (this.isAllInvoiceCreditDocumentsSelected) {
      invoiceCreditDocuments.forEach((invoiceCreditDocument: InvoiceCreditDocument) =>
        this.selectedInvoiceCreditDocumentNumbers.push(invoiceCreditDocument.documentNumber));
    }
  }

  isDocumentSelected(invoiceCreditDocumentNumber: string): boolean {
    const foundSelectedInvoiceCreditDocumentNumber: string = this.selectedInvoiceCreditDocumentNumbers.find(
      (selectedInvoiceCreditDocumentNumber: string) => selectedInvoiceCreditDocumentNumber === invoiceCreditDocumentNumber
    );
    return foundSelectedInvoiceCreditDocumentNumber != null;
  }

  onDocumentSelectedChange(invoiceCreditDocumentNumber: string): void {
    const invoiceCreditDocumentNumberIndex: number = this.selectedInvoiceCreditDocumentNumbers.indexOf(invoiceCreditDocumentNumber);
    if (invoiceCreditDocumentNumberIndex !== -1) {
      this.isAllInvoiceCreditDocumentsSelected = false;
      this.selectedInvoiceCreditDocumentNumbers.splice(invoiceCreditDocumentNumberIndex, 1);
    } else {
      this.selectedInvoiceCreditDocumentNumbers.push(invoiceCreditDocumentNumber);
    }
  }

  onDowloadSingleDocument(invoiceCreditDocumentNumber: string): void {
    this.selectedInvoiceCreditDocumentNumbers = []
    this.selectedInvoiceCreditDocumentNumbers.push(invoiceCreditDocumentNumber);
    this.onDownloadMultipleDocuments();
  }

  onDownloadMultipleDocuments(): void {
    this.isMultipleDownloadClicked = true;
    this.isDownloading$.next(true);
    const currentDate: Date = new Date();
    const formattedDate: string = this.datePipe.transform(currentDate, DATE_FORMAT_FOR_DOWNLOADED_DOCUMENTS);
    this.getDocumentFileNamesTranslations().pipe(first()).subscribe(([documentFileNamePrefixTranslation, zipFileNamePrefixTranslation]) => {
        this.createZippedDocuments(this.selectedInvoiceCreditDocumentNumbers, `${documentFileNamePrefixTranslation}`, `${zipFileNamePrefixTranslation}` + '_' + formattedDate + ZIP_EXTENSION);
    });
  }

  protected getDocumentFileNamesTranslations(): Observable<[string, string]> {
    return combineLatest([
      this.translation.translate('invoicesCreditsCustom.invoiceCreditOverview.downloadInvoiceDocumentFileNamePrefix'),
      this.translation.translate('invoicesCreditsCustom.invoiceCreditOverview.downloadInvoiceDocumentsCompressedFileNamePrefix')
    ]);
  }

  async createZippedDocuments(files: string[], documentFileNamePrefix: string, zipName: string) {
    const zip = new JSZip();
    for (const file of files) {
      this.invoiceCreditDetailsService.loadInvoiceDetails(file);
      const blob: any = await this.invoiceCreditDetailsService.isLoading()
                              .pipe(filter((isLoading: boolean) => !isLoading),
                              switchMap(() => this.invoiceCreditDetailsService.getResponse()),
                              switchMap((fileData: any) => from(fetch(`data:application/pdf;base64,${fileData.encodedContent}`))),
                              switchMap((res: any) => res.blob()),
                              take(1)
                              ).toPromise();
      zip.file(documentFileNamePrefix + '_' + file.substring(file.lastIndexOf('/') + 1) + PDF_EXTENSION, blob);
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, zipName);
      }
      this.isMultipleDownloadClicked = false;
      this.isDownloading$.next(false);
    });
  }
}
