import { createReducer, on } from '@ngrx/store';
import * as UpdateCartActions from '../actions/update-cart.action';
import { ErrorType } from '@shared/models/ErrorTypes';

export const UPDATE_CART_FEATURE_KEY = 'updateCart';

export interface UpdateCartState {
  isUpdating: boolean;
  cartId: string;
  userId: string;
  orderTypeId: string;
  shippingTypeId: string;
  addressId: string;
  error: ErrorType;
  success: boolean;
}

export const initialState: UpdateCartState = {
  isUpdating: false,
  cartId: undefined,
  userId: undefined,
  orderTypeId: undefined,
  shippingTypeId: undefined,
  addressId: undefined,
  error: undefined,
  success: false
};

export const updateCartReducer = createReducer(
  initialState,
  on(UpdateCartActions.updateCart, (state, action) => ({
    ...state,
    isUpdating: true,
    cartId: action.data.cartId,
    userId: action.data.userId,
    orderTypeId: action.data.orderTypeId,
    shippingTypeId: action.data.shippingTypeId,
    addressId: action.data.addressId,
    success: false,
    error: undefined
  })),
  on(UpdateCartActions.updateCartSuccess, (state) => ({
    ...state,
    isUpdating: false,
    success: true,
    error: undefined
  })),
  on(UpdateCartActions.updateCartFailure, (state, action) => ({
    ...state,
    isUpdating: false,
    success: false,
    error: action.error
  })),
  on(UpdateCartActions.updateCartReset, (state) => (
    initialState
  )),
);
