import { Config } from '@spartacus/core';

export abstract class DateFilterConfig {
  dateFrom?: {
    orderHistoryOverview: number;
    invoiceCreditOverview: number;
    deliverNotesOverview: number;
    returnOverview: number;
    shippingBacklogOverview: number;
  };
}

export const defaultDateFilterConfig: DateFilterConfig = {
  dateFrom: {
    orderHistoryOverview: 90,
    invoiceCreditOverview: 90,
    deliverNotesOverview: 90,
    returnOverview: 90,
    shippingBacklogOverview: 90,
  },
};

declare module '@spartacus/core' {
  interface Config extends DateFilterConfig {}
}
