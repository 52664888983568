export const returnCreation = {
  returnablePositions: {
    returnAllArticles: 'Return All Articles',
    positionNumber: 'Position Number',
    oemNumber: 'OEM-Nr.',
    name: 'Identifier',
    deliveredQuantity: 'Delivered Quantity',
    returnableQuantity: 'Returnable Quantity',
    quantityToReturn: 'Quantity to Return',
    nextButton: 'Next',
    quantityToReturnError: 'this quantity is invalid.',
    quantityToReturnRequiredError: 'This field is required.',
    title: 'Returnable positions',
    noReturnablePositions: 'No returnable positions found.',
  },
  returnCreation: {
    formMandatoryFields:'Mandatory',
    returnCreationTitle:'Returnable positions',
    positionNumber: 'Position Number',
    oemNumber: 'OEM-Nr.',
    name: 'Identifier',
    quantityToReturn: 'Quantity to Return',
    reason: {
      header: 'Reason',
      label: 'Reason',
      placeholder: 'Reason',
    },
    comment:{
      header: 'Comment',
      label: 'Comment',
      placeholder: 'Add comment',
    },
    hint: 'Please note that deductions can be made on the value of the goods in accordance to the return policy.',
    sendButton: 'Submit a return request',
    globalMessage: {
      returnCreationFailure: 'There was an error when sending the return request. Please try again or contact the support.',
      returnCreationSuccess: 'The return request was sended successfully. Your return request number(s): {{returnNumbers}}',
      notAllPositionsCouldBeReturnedWarning: 'Not all selected positions could be returned. The rejected positions are highlighted in the list below.'
    }
  }
};
