import { InjectionToken } from '@angular/core';

import { Converter, Occ } from '@spartacus/core';
import OrderEntryList = Occ.OrderEntryList;

import { AddToCartParam } from '../../model/cart-administration.model';

export const MULTI_ADD_TO_CART_SERIALIZER = new InjectionToken<Converter<AddToCartParam[], OrderEntryList>>(
  'MultiAddToCartSerializer'
);
