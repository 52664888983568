import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as DeliveryNotesOverviewFeature from '../store/reducers/delivery-notes-overview.reducer';
import * as DeliveryNotesOverviewSelectors from '../store/selectors/delivery-notes-overview.selectors';
import { DeliveryNotesOverviewActions } from '../store/actions';
import { DeliveryNoteOverviewRequest, DeliveryNoteOverviewResponse } from '../model/delivery-notes.model';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNotesOverviewService {

  constructor(protected store: Store<DeliveryNotesOverviewFeature.DeliveryNotesOverviewState>) {}

  loadDeliveryNotesOverview(request: DeliveryNoteOverviewRequest): void {
    this.store.dispatch(DeliveryNotesOverviewActions.loadDeliveryNoteOverview({ request }));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(DeliveryNotesOverviewSelectors.selectLoading);
  }

  getDeliveryNotesOverviewResponse(): Observable<DeliveryNoteOverviewResponse> {
    return this.store.select(DeliveryNotesOverviewSelectors.selectResponse);
  }
}
