import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OccEndpointsService } from "@spartacus/core";
import { Observable } from "rxjs";
import { SetCartCalculationModeAdapter } from "../set-cart-calculation-mode.adapter";

@Injectable({providedIn: 'root'})
export class OccSetCartCalculationModeAdapter implements SetCartCalculationModeAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  setCartCalculationMode(cartCalculationMode: boolean, cartId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl('setCartCalculationMode',
      {
        urlParams: {
          userId: 'current',
        }, 
        queryParams: {
          automatic: cartCalculationMode,
          cartId,
        }
      }
    );
    return this.http.post<any>(url, undefined);
  }
}