import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as OrderCancelFeature from '../reducers/order-cancel.reducer';
import { ORDER_CANCEL_FEATURE_KEY } from '../reducers/order-cancel.reducer';
import { getOrderCancelState, OrderCancellationState } from '../reducers';
import { OrderCancelResponse } from '../../model/order-cancel.model';

export const selectCancelling: MemoizedSelector<OrderCancelFeature.OrderCancelState, boolean> = createSelector(
  getOrderCancelState,
  (state: OrderCancellationState) => state[ORDER_CANCEL_FEATURE_KEY].cancelling
);

export const selectSuccess: MemoizedSelector<OrderCancelFeature.OrderCancelState, boolean> = createSelector(
  getOrderCancelState,
  (state: OrderCancellationState) => state[ORDER_CANCEL_FEATURE_KEY].success
);


export const selectOrderCancelResponse: MemoizedSelector<OrderCancelFeature.OrderCancelState, OrderCancelResponse> = createSelector(
  getOrderCancelState,
  (state: OrderCancellationState) => state[ORDER_CANCEL_FEATURE_KEY].response
);
