import {HttpClient} from '@angular/common/http';
import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {Converter, ConverterService, Occ, OccEndpointsService} from '@spartacus/core';
import {Category} from '../model/category.model';

@Injectable()
export class OccCategoryAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
  }

  load(categoryId: string): Observable<Category> {
    const url = this.occEndpoints.buildUrl('category', {urlParams: {categoryId}});
    return this.http.get<Occ.CategoryHierarchy>(url).pipe(this.converter.pipeable(CATEGORY_NORMALIZER));
  }
}

export const CATEGORY_NORMALIZER = new InjectionToken<Converter<any, Category>>('CategoryNormalizer');
