import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { RegistrationData } from '../../model/login-register.model';

export const loadRegistrationData = createAction(
  '[RegistrationLoad] Load Registration Data',
  props<{ processId: string }>()
);

export const loadRegistrationDataSuccess = createAction(
  '[RegistrationLoad] Load Registration Data Success',
  props<{ response: RegistrationData }>()
);

export const loadRegistrationDataFailure = createAction(
  '[RegistrationLoad] Load Registration Data Failure',
  props<{ errorResponse: ErrorType }>()
);
