import { createReducer, on } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import { ReturnOverviewFilter } from '../../model/return-history.model';
import { ReturnHistoryUiActions } from '../actions';

export const RETURN_HISTORY_UI_FEATURE_KEY = 'returnHistoryUi';

export interface ReturnHistoryUiState {
  returningFromDetails: boolean;
  selectedFilterEntity?: ReturnOverviewFilter;
  selectedPagination?: PaginationModel;
  selectedSort?: SortModel;
}

export const initialState: ReturnHistoryUiState = {
    returningFromDetails: false,
    selectedFilterEntity: {
      deliveryNoteNumber: '',
      returnNumber: '',
      statusCode: '',
      dateFrom: undefined,
      dateTo: undefined,
    },
    selectedPagination: {
      currentPage: 0,
      pageSize: 30,
      sort: 'creationDate',
    },
    selectedSort: {
      code: 'creationDate',
      selected: true
    }
  }
;

export const returnHistoryUiReducer = createReducer(
  initialState,

  on(ReturnHistoryUiActions.setSelectedFilterEntity, (state, action) => ({
    ...state,
    selectedFilterEntity: action.selectedFilterEntity
  })),
  on(ReturnHistoryUiActions.setSelectedPagination, (state, action) => ({
    ...state,
    selectedPagination: action.selectedPagination
  })),
  on(ReturnHistoryUiActions.setSelectedSorting, (state, action) => ({
    ...state,
    selectedSort: action.selectedSort
  })),
  on(ReturnHistoryUiActions.setReturningFromDetails, (state, action) => ({
    ...state,
    returningFromDetails: action.returningFromDetails,
  }))
);
