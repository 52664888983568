import {Injectable} from '@angular/core';

import {Store} from '@ngrx/store';
import {RoutingService} from '@spartacus/core';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, tap, withLatestFrom} from 'rxjs/operators';

import * as ReturnDetailsFeature from '../store/reducers/return-details.reducer';
import * as ReturnDetailsSelectors from '../store/selectors/return-details.selectors';
import {ReturnDetailsResponse} from '../model/return-history.model';
import {ReturnDetailsActions} from '../store/actions';


@Injectable({
  providedIn: 'root'
})
export class ReturnDetailsService {
  returnNumber$: Observable<string>;

  constructor(
    protected store: Store<ReturnDetailsFeature.ReturnDetailsState>,
    private routingService: RoutingService,
  ) {
    this.returnNumber$ = this.routingService.getRouterState().pipe(
      map((routingData) => routingData.state.params.returnNumber),
      distinctUntilChanged()
    );
  }

  reset(): void {
    return this.store.dispatch(ReturnDetailsActions.resetReturnDetails());
  }

  loadDetails(returnNumber: string): void {
    this.store.dispatch(ReturnDetailsActions.loadReturnDetails({returnNumber}));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(ReturnDetailsSelectors.selectLoading);
  }

  getReturnDetailsResponse(): Observable<ReturnDetailsResponse> {
    return this.store.select(ReturnDetailsSelectors.selectResponse)
      .pipe(
        withLatestFrom(this.returnNumber$, this.store.select(ReturnDetailsSelectors.selectReturnNumber)),
        tap(([resp, returnNumber, returnNumberPrev]) => {
            if (returnNumber !== returnNumberPrev) {
              this.loadDetails(returnNumber);
            }
          }
        ),
        map(([resp, returnNumber]) => resp));
  }
}
