import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged} from 'rxjs/operators';
import {NgbDatepickerConfig, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import {ICON_TYPE} from '../../model/invoices-credits-icon.model';
import {InvoiceCreditOverviewFilter} from '../../model/invoices-credits.model';
import {InvoiceCreditUiService} from '../../services/invoice-credit-ui.service';
import {Config} from '@spartacus/core';
import {getInitialDateFrom} from '@shared/utils/date-tools';


@Component({
  selector: 'app-invoice-credit-filter',
  templateUrl: './invoice-credit-filter.component.html',
})
export class InvoiceCreditFilterComponent implements OnInit, OnDestroy {
  @Output() invoiceCreditOverviewFilterChanges: EventEmitter<InvoiceCreditOverviewFilter> = new EventEmitter<InvoiceCreditOverviewFilter>();
  @Input() availableDocumentTypes: DocumentType[];
  iconTypes = ICON_TYPE;
  invoiceCreditOverviewFilterForm: UntypedFormGroup;
  currentDate: Date = new Date();
  maxDate: NgbDateStruct;

  private subscription: Subscription = new Subscription();
  private readonly formProperties = {
    documentNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    oemNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    deliveryNoteNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    orderNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    dateFrom: new UntypedFormControl(getInitialDateFrom(this.config.dateFrom.invoiceCreditOverview)),
    dateTo: new UntypedFormControl(undefined),
    documentTypeId: new UntypedFormControl(''),
    consignmentInfo: new UntypedFormControl('', {
      updateOn: 'blur',
      validators: [Validators.maxLength(35)]
    }),
  };
  private readonly resetFormState = {
    documentNumber: '',
    oemNumber: '',
    deliveryNoteNumber: '',
    orderNumber: '',
    dateFrom: getInitialDateFrom(this.config.dateFrom.invoiceCreditOverview),
    dateTo: undefined,
    documentTypeId: '',
    consignmentInfo: '',
  };
  private wasReset = false;



  constructor(
    private fb: UntypedFormBuilder,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    private invoiceCreditUiService: InvoiceCreditUiService,
    @Inject(Config) private config: any,
  ) {
  }

  ngOnInit(): void {
    this.disableFutureDates();
    this.invoiceCreditOverviewFilterForm = this.fb.group(this.formProperties);
    this.onChanges();
    this.setSelectedFilterEntity();
  }

  onChanges(): void {
    this.subscription.add(
      this.invoiceCreditOverviewFilterForm.valueChanges
      .pipe(
        delay(1000),
        distinctUntilChanged((a, b) =>
          a.documentNumber === b.documentNumber &&
          a.oemNumber === b.oemNumber &&
          a.deliveryNoteNumber === b.deliveryNoteNumber &&
          a.orderNumber === b.orderNumber &&
          a.dateTo === b.dateTo &&
          a.dateFrom === b.dateFrom &&
          a.documentTypeId === b.documentTypeId &&
          a.consignmentInfo === b.consignmentInfo)
      )
      .subscribe((filter: InvoiceCreditOverviewFilter) => {
        if (this.invoiceCreditOverviewFilterForm.valid) {
          this.invoiceCreditOverviewFilterChanges.emit(filter);
          this.invoiceCreditUiService.setSelectedFilterEntity(filter);
        }
      })
    );
  }

  setSelectedFilterEntity(): void {
    this.subscription.add(
      this.invoiceCreditUiService.selectedFilterEntity().subscribe((filterEntity: InvoiceCreditOverviewFilter) => {
        if (this.wasReset) {
          this.invoiceCreditOverviewFilterForm.patchValue(
            {
              documentNumber: filterEntity.documentNumber,
              orderNumber: filterEntity.orderNumber,
              oemNumber: filterEntity.oemNumber,
              deliveryNoteNumber: filterEntity.deliveryNoteNumber,
              documentTypeId: filterEntity.documentTypeId,
              dateFrom: filterEntity.dateFrom,
              dateTo: filterEntity.dateTo,
              consignmentInfo: filterEntity.consignmentInfo,
            }
          );
          this.wasReset = false;
        }
      })
    );
  }

  disableFutureDates(): void {
    this.maxDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear()
    };
  }

  triggerOnChanges(field: string, event: any): void {
    const patchValue = {};
    patchValue[field] = event.target.value;
    this.invoiceCreditOverviewFilterForm.patchValue(patchValue);
  }

  clearForm(): void {
    this.wasReset = true;
    this.invoiceCreditOverviewFilterForm.reset(this.resetFormState);
  }

  getSelectPlaceholderTextClass(value: string): string {
    return value === '' ? 'placeholder-text' : '';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
