<!--If component data exists-->
<div
  *ngIf="tilesComponentData$ | async as data"
  class="container-fluid tiles-container-wrapper {{ data.styleClasses ? data.styleClasses : ''}}"
  [style.--tile-container-background-color]="data.backgroundColor ?? 'inherit'"
  [style.--tiles-container-text-color]="data.color ?? 'inherit'"
  [ngClass]="{'addMoreSpace' : ((isHelpPage | async) || (isHelpAnonymousPage | async)) }"
>
  <div *ngIf="data.active === 'false'" class="inactiveContainer">
    <div class="image-box">
      <img src="../../../../../../assets/icons/Maintenance.png" alt="">
    </div>
    <div class="inactiv-item-text-wrapper">
      <div *ngIf="(data.inActiveTitle | json) != '{}'"
           class="text-title">{{ data.inActiveTitle }}</div>
      <div *ngIf="(data.inActiveText | json) != '{}'"
           class="text-description">{{data.inActiveText}}</div>
    </div>
  </div>
  <!--if tile item exists-->
  <div *ngIf="tileItems"
       [ngStyle]="{'filter':(data.active === 'false') ? 'opacity(30%) blur(24px)' : 'none' }"
  >
    <div class="tiles-container" #tilesContainer
         [ngStyle]="{'margin-bottom': (isTiresAndMorePage | async) ? '0px' : '50px'}"
    >

      <!--Tiles title-->
      <div class="col-xs-12">
        <h2 [ngClass]="data.titleLeftAligned === 'true' ? 'title-left-aligned': ''">
          <span>{{ data.title }}</span>
        </h2>
      </div>
      <div class="row">
        <div *ngIf="!(isDownloadCustomerInfoPage|async)"
             class="d-flex align-center col-md-1 justify-content-center"
             [ngClass]="{ 'col-2' : data.numberOfTilesPerRow < tileItems.length }">
          <button type="button" class="previous" [disabled]="tilesVisibleLowIndex === 0"
                  *ngIf="data.numberOfTilesPerRow < tileItems.length" (click)="slideLeft()">
            <cx-icon [type]="iconType.CARET_LEFT" class="tiles-container-slide-icon"></cx-icon>
          </button>
        </div>
        <div class="{{colConfig}}"
             [ngClass]="{'col-8':  data.numberOfTilesPerRow < tileItems.length}">
          <div class="row">
            <!--show all the tile items-->
            <ng-container
              *ngIf="
                isShowMore ||
                  !data.enableShowMore ||
                  (tileItems.length <= data.numberOfTilesPerRow && data.numberOfTilesPerRow < 5);
                else showAllContent
              "
            >
              <!--tile items loop-->
              <ng-container *ngFor="let item of tileItems">
                <div
                  class="d-flex col-12 col-sm-6 col-lg-{{
                    12 / data.numberOfTilesPerRow
                  }}"
                >
                  <!--tile item-->
                  <ng-container
                    *ngIf="
                      item.typeCode === 'PneuConfiguratorComponent';
                      else tysysTile
                    "
                  >
                    <app-efa-pneu-configurator
                      [tileItem]="item"
                    ></app-efa-pneu-configurator>
                  </ng-container>

                  <ng-template #tysysTile>
                    <ng-container
                      *ngIf="item.typeCode === 'TysysCatalogComponent'; else genericTile">
                      <app-efa-tysys-catalog [tileItem]="item"></app-efa-tysys-catalog>
                    </ng-container>
                  </ng-template>

                  <ng-template #genericTile>
                    <app-efa-tile-cms-item
                      [tileItem]="item"
                    ></app-efa-tile-cms-item>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>

            <!--show less tile items-->
            <ng-template #showAllContent>
              <!--tile items loop-->
              <ng-container *ngFor="let item of tileItems; index as i">
                <div
                  *ngIf="i <= tilesVisibleHighIndex && i >= tilesVisibleLowIndex"
                  class="col-xs-12 col-sm-6 col-md-6 col-lg-{{
                    12 / data.numberOfTilesPerRow
                  }}"
                >
                  <!--tile item-->
                  <ng-container
                    *ngIf="
                      item.typeCode === 'PneuConfiguratorComponent';
                      else tysysTileShowLess
                    "
                  >
                    <app-efa-pneu-configurator
                      [tileItem]="item"
                    ></app-efa-pneu-configurator>
                  </ng-container>

                  <ng-template #tysysTileShowLess>
                    <ng-container
                      *ngIf="item.typeCode === 'TysysCatalogComponent'; else genericTileShowLess">
                      <app-efa-tysys-catalog [tileItem]="item"></app-efa-tysys-catalog>
                    </ng-container>
                  </ng-template>

                  <ng-template #genericTileShowLess>
                    <app-efa-tile-cms-item
                      [tileItem]="item"
                    ></app-efa-tile-cms-item>
                  </ng-template>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </div>
        <div *ngIf="!(isDownloadCustomerInfoPage|async)"
             class="d-flex align-center col-md-1 justify-content-center"
             [ngClass]="{ 'col-2' : data.numberOfTilesPerRow < tileItems.length }">
          <button type="button" *ngIf="data.numberOfTilesPerRow < tileItems.length"
                  [disabled]="tilesVisibleHighIndex >= (tileItems.length - 1)" class="next"
                  (click)="slideRight(tileItems.length)">
            <cx-icon [type]="iconType.CARET_RIGHT" class="tiles-container-slide-icon"></cx-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr *ngIf="data.displayBottomLine === 'true'">
</div>
