import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OccTopmotiveCatalogAdapter } from './adapters/impl/occ-topmotive-catalog.adapter';
import { TopmotiveCatalogAdapter } from './adapters/topmotive-catalog.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: TopmotiveCatalogAdapter,
      useClass: OccTopmotiveCatalogAdapter,
    },
  ],
})
export class OccModule {}
