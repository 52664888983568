import { createReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { RegistrationInformationMultiOptionsFieldsValuesResponse } from '../../model/login-register.model';
import { RegistrationInformationMultiOptionFieldsValuesActions } from '../actions';

export const REGISTRATION_INFORMATION_MULTI_OPTION_FIELDS_VALUES_FEATURE_KEY = 'registrationInformationMultiOptionFieldsValues';

export interface RegistrationInformationMultiOptionFieldsValuesState {
  response: RegistrationInformationMultiOptionsFieldsValuesResponse;
  loading: boolean;
  error: ErrorType;
}

export const initialStateRegistrationInformationMultiOptionFieldsValues: RegistrationInformationMultiOptionFieldsValuesState = {
  response: undefined,
  loading: false,
  error: undefined,
};

export const registrationInformationMultiOptionFieldsValuesReducer = createReducer(
  initialStateRegistrationInformationMultiOptionFieldsValues,

  on(RegistrationInformationMultiOptionFieldsValuesActions.loadRegistrationInformationMultiOptionsFieldsValues, (state) => ({
    ...state,
    loading: true,
  })),
  on(RegistrationInformationMultiOptionFieldsValuesActions.loadRegistrationInformationMultiOptionsFieldsValuesSuccess, (state, action) => ({
    ...state,
    response: action.response,
    loading: false,
  })),
  on(RegistrationInformationMultiOptionFieldsValuesActions.loadRegistrationInformationMultiOptionsFieldsValuesFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse
  })),
);

