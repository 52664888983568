import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as TokenGenerationFeature from '../reducers/token-generation.reducer';
import { TOKEN_GENERATION_FEATURE_KEY } from '../reducers/token-generation.reducer';
import { getTokenGenerationState, TokenGenerationState } from '../reducers';

export const selectLoading: MemoizedSelector<TokenGenerationFeature.TokenGenerationState, boolean> = createSelector(
  getTokenGenerationState,
  (state: TokenGenerationState) => state[TOKEN_GENERATION_FEATURE_KEY].loading
);

export const selectSuccess: MemoizedSelector<TokenGenerationFeature.TokenGenerationState, boolean> = createSelector(
  getTokenGenerationState,
  (state: TokenGenerationState) => state[TOKEN_GENERATION_FEATURE_KEY].success
);

export const selectTokenName: MemoizedSelector<TokenGenerationFeature.TokenGenerationState, string> = createSelector(
  getTokenGenerationState,
  (state: TokenGenerationState) => state[TOKEN_GENERATION_FEATURE_KEY].tokenName
);

export const selectTokenValue: MemoizedSelector<TokenGenerationFeature.TokenGenerationState, string> = createSelector(
  getTokenGenerationState,
  (state: TokenGenerationState) => state[TOKEN_GENERATION_FEATURE_KEY].tokenValue
);
