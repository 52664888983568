import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GlobalMessageType} from '@spartacus/core';
import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {DefaultManufacturersConnector} from '../../occ/connectors/default-manufacturers.connector';
import {DefaultManufacturersActions} from '../actions';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class DefaultManufacturersEffect {

  setDefaultManufacturers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DefaultManufacturersActions.setDefaultManufacturers),
      concatMap((action) =>
        this.connector.setDeafultManufacturers(action.defaultManufacturers).pipe(
          map(() => {
            return DefaultManufacturersActions.setDefaultManufacturersSuccess();
          }),
          catchError((error) => {
            this.globalMessageService.add(
              {
                key: 'defaultManufacturers.globalMessage.setDefaultManufacturersFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(DefaultManufacturersActions.setDefaultManufacturersFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: DefaultManufacturersConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {

  }
}
