import {createReducer, on} from '@ngrx/store';
import {PaginationModel, SortModel} from '@spartacus/core';

import {DeliveryNotesUiActions} from '../actions';
import {DeliveryNoteOverviewFilter} from '../../model/delivery-notes.model';

export const DELIVERY_NOTES_UI_FEATURE_KEY = 'deliveryNotesUi';

export interface DeliveryNotesUiState {
  returningFromDetails: boolean;
  selectedFilterEntity?: DeliveryNoteOverviewFilter;
  selectedPagination?: PaginationModel;
  selectedSort?: SortModel;
}

export const initialState: DeliveryNotesUiState = {
    returningFromDetails: false,
    selectedFilterEntity: {
      deliveryNoteNumber: '',
      orderNumber: '',
      oemNumber: '',
      consignmentInfo: '',
      dateFrom: undefined,
      dateTo: undefined,
    },
    selectedPagination: {
      currentPage: 0,
      pageSize: 30,
      sort: 'creationDate',
    },
    selectedSort: {
      code: 'creationDate',
      selected: true
    }
  }
;

export const deliveryNotesUiReducer = createReducer(
  initialState,

  on(DeliveryNotesUiActions.setSelectedFilterEntity, (state, action) => ({
    ...state,
    selectedFilterEntity: action.selectedFilterEntity
  })),
  on(DeliveryNotesUiActions.setSelectedPagination, (state, action) => ({
    ...state,
    selectedPagination: action.selectedPagination
  })),
  on(DeliveryNotesUiActions.setSelectedSorting, (state, action) => ({
    ...state,
    selectedSort: action.selectedSort
  })),
  on(DeliveryNotesUiActions.setReturningFromDetails, (state, action) => ({
    ...state,
    returningFromDetails: action.returningFromDetails
  })),
);
