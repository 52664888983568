import { Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslationService } from '@spartacus/core';
import { FocusConfig, ICON_TYPE, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, from } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ProductCategory } from '../../../cart-administration/model/cart-administration-enum.model';
import {
  CartProductSearchRequest,
  CartProductSearchResponse,
  CartProductSearchResult,
  Manufacturer
} from '../../../cart-administration/model/cart-administration.model';
import { B2BUserAccountService } from '../../../cart-administration/services/b2-b-user-account.service';
import { CartParametersService } from '../../../cart-administration/services/cart-parameters.service';
import { CartProductSearchService } from '../../../cart-administration/services/cart-product-search.service';
import {
  getCartProductSearchResultsNotPurchasableReason,
  isCartProductSearchResultPurchasable
} from '../../../cart-administration/shared/utils/cart-product-search-result-utils';
import { ImportMode } from '../../model/import-export-enum.model';
import { CartEntryCsv, CartImportSearchError, CartImportSearchResponse } from '../../model/import-export.model';
import { CartImportExportService } from '../../services/cart-import-export-service';
import { CartImportSelectionComponent } from '../cart-import-selection/cart-import-selection.component';
import { CartImportDialogService } from './cart-import-dialog.service';

const MANUFACTURER_NAMES_SEPARATOR = ', ';
const MANUFACTURERS_FOR_MICROCAT = ['TOY', 'FOR'];

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CART_IMPORT = 'CART_IMPORT',
  }
}

@Component({
  selector: 'app-cart-import',
  templateUrl: './cart-import.component.html'
})
export class CartImportComponent implements OnInit, OnDestroy {

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: true,
    focusOnEscape: true,
  };

  private searchResultLoaded : Subject<boolean> = new BehaviorSubject(false);
  private productSearchResultModalRef: ComponentRef<CartImportSelectionComponent>;
  private subscription: Subscription = new Subscription();
  public importFileValid = false;
  public importFileError = '';
  private importFile: File;

  private searchIsDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private searchResponses: CartImportSearchResponse[] = [];
  private searchErrors: CartImportSearchError[] = [];
  private defaultManufacturerNames: string[] = [];
  private counter = 0;
  private _importMode: ImportMode;

  manufacturerSelectionDisabled: boolean = false;
  displaySearchForAlternativeArticles = false;

  importForm: UntypedFormGroup = this.fb.group({
    manufacturerCodes: [{
      value: this.defaultManufacturerNames,
      disabled: true
    }, [Validators.required]],
    filename: [undefined, [Validators.required]],
  });
  iconTypes = ICON_TYPE;

  get importMode(): ImportMode {
    return this._importMode;
  }

  set importMode(value: ImportMode) {
    this._importMode = value;
  }

  constructor(
    private importService: CartImportExportService,
    private searchService: CartProductSearchService,
    private cartParametersService: CartParametersService,
    private b2bUserAccountService: B2BUserAccountService,
    private fb: UntypedFormBuilder,
    private translationService: TranslationService,
    private launchDialogService: LaunchDialogService,
    protected cartImportDialogService: CartImportDialogService,
    private vcr: ViewContainerRef
  ) {
  }

  ngOnInit(): void {
    this.cartImportDialogService.data$
      .pipe(take(1))
      .subscribe((data: ImportMode) => {
        this.importMode = data;
      });

    this.defaultManufacturerNames = this.b2bUserAccountService.defaultManufacturerNames;
    this.displaySearchForAlternativeArticles = this.b2bUserAccountService.alternativesSearchAllowed;
    this.setSelectedManufacturerNamesCodes();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchProducts(entries: CartEntryCsv[]): void {
    this.searchService.reset();

    this.onOpenSearchResultsModal();
    this.listenToProductSearchResult(entries);


    this.subscription.add(combineLatest(this.cartParametersService.getCurrentOrderType(),
      this.cartParametersService.getCurrentShippingType(),
      this.b2bUserAccountService.getSelectedManufacturers())
    .subscribe(([ot, st, mc]) => {
      entries.forEach(entry => {
        const request: CartProductSearchRequest = {};
        request.productCategory = ProductCategory.SPARE_PARTS;
        request.alternativeArticles = false;
        request.quantity = entry.quantity;
        request.oemNumber = entry.oemNumber;
        request.orderTypeId = ot.id;
        request.shippingTypeId = st.code;
        request.manufacturerCodes = mc.map(m => m.id);
        setTimeout(() => this.searchService.searchProducts(request), 500);
      });
    }));
  }

  fileChanged($event: Event): void {
    const target = $event.target as HTMLInputElement;
    if (target.files[0].size === 0) {
      this.importForm.controls.filename.setErrors({
        fileEmpty: true
      });
      return;
    }
    this.importFileValid = false;
    this.importFileError = '';
    this.importFile = target.files[0];
    if (this._importMode === ImportMode.MICROCAT) {
      this.validateMicrocatFileContent(target.files[0]);
    } else {
      this.validateFileContent(target.files[0]);
    }
    document.getElementById('filename').innerHTML = target.files[0].name;
  }

  validateFileContent(file: File): void {
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = () =>
      this.importService.readCSV(fileReader.result as string).then((entries) => {
        this.validateContent(entries);
      });
    fileReader.readAsText(file);
  }

  private validateContent(entries: CartEntryCsv[]) {
    if (entries.length > 0) {
      const firstEntry: CartEntryCsv = entries[0];
      const attrKeys = Object.keys(firstEntry);

      this.importFileValid = attrKeys.length >= 3 &&
        attrKeys.includes('oemNumber') &&
        attrKeys.includes('quantity') &&
        attrKeys.includes('consignmentInfo');
      if (!this.importFileValid) {
        this.translationService.translate('importExport.importFile.invalidHeader')
        .subscribe(t => this.importFileError = t)
        .unsubscribe();
      } else {
        const invalidEntry = entries.find(entry => Object.values(entry).length < 2 || !entry.oemNumber || !entry.quantity);

        if (undefined !== invalidEntry) {
          const ind = entries.indexOf(invalidEntry);
          this.translationService.translate('importExport.importFile.invalidLine', {
            ind,
            line: Object.values(invalidEntry).join(';')
          }).subscribe(t => this.importFileError = t).unsubscribe();
        }
        this.importFileValid = this.importFileValid && undefined === invalidEntry;
      }
    } else {
      this.importFileValid = false;
      this.translationService.translate('importExport.importFile.empty')
      .subscribe(t => this.importFileError = t)
      .unsubscribe();
    }
  }

  validateMicrocatFileContent(file: File): void {
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = () =>
      this.importService.readCSVMicrocat(fileReader.result as string).then((entries) => {
        this.validateContent(entries);
      });
    fileReader.readAsText(file);
  }


  startImport(): void {
    this.counter = 0;
    this.importService.setSearchingStatusTo(true);
    this.searchErrors = [];
    this.searchResponses = [];

    this.subscription.add(
      this.importService.getCsvOptionsLoaded().pipe(
        filter((csvOptionsLoaded) => csvOptionsLoaded === true),
        switchMap(() => from(this.importFile.text())),
        switchMap((value: string) => from(
          this._importMode === ImportMode.MICROCAT ? this.importService.readCSVMicrocat(value) : this.importService.readCSV(value)
        )),
        tap((val) => this.searchProducts(val)),
        tap(() => this.subscription.add(this.searchIsDone$.pipe(filter(b => b))
        .subscribe(() => {
            this.importService.setSearchingStatusTo(false);
            this.closeModal();
          }
        )))
      )
      .subscribe());
  }

  onOpenManufacturersModal(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.MANUFACTURERS_SELECTION, undefined, undefined);
  }

  setSelectedManufacturerNamesCodes(): void {
    if (this.importMode === ImportMode.CSV) {
      this.subscription.add(
        combineLatest([this.b2bUserAccountService.getSelectedManufacturers(), this.cartParametersService.getCurrentOrderType()])
        .pipe(
          filter(([_, ot]) => ot !== undefined))
        .subscribe(([manufacturers, orderType]) => {
          let selectedManufacturerNames = [];
          let selectedManufacturerCodes = [];
          if (manufacturers.length) {
            let filteredManufacturers: Manufacturer[];

            if (orderType.manufacturers != null && orderType.manufacturers.length > 0) {
              if (orderType.manufacturersIncluded) {
                filteredManufacturers = this.b2bUserAccountService.visibleManufacturers.filter(
                  (manufacturer: Manufacturer) =>
                    orderType.manufacturers?.findIndex(
                      (orderTypeManufacturer: Manufacturer) =>
                        orderTypeManufacturer.id === manufacturer.id
                    ) > -1
                );
              } else {
                filteredManufacturers = manufacturers.filter(
                  (manufacturer: Manufacturer) =>
                    orderType.manufacturers?.findIndex(
                      (orderTypeManufacturer: Manufacturer) =>
                        orderTypeManufacturer.id === manufacturer.id
                    ) < 0
                );
              }
            } else {
              filteredManufacturers = manufacturers;
            }

            filteredManufacturers.forEach((manufacturer: Manufacturer) => {
                selectedManufacturerNames.push(manufacturer.name);
                selectedManufacturerCodes.push(manufacturer.id);
              }
            );
          } else if (orderType.manufacturersIncluded && orderType.manufacturers != null && orderType.manufacturers.length > 0) {
            orderType.manufacturers.forEach((manufacturer: Manufacturer) => {
                selectedManufacturerNames.push(manufacturer.name);
                selectedManufacturerCodes.push(manufacturer.id);
              }
            );
          }

          this.importForm.patchValue({
            manufacturerCodes: selectedManufacturerNames.join(MANUFACTURER_NAMES_SEPARATOR),
          });
        })
      )
      ;
    } else {
      this.importForm.patchValue(
        {
          manufacturerCodes:
            this.b2bUserAccountService.visibleManufacturers
            .filter(manu => MANUFACTURERS_FOR_MICROCAT.includes(manu.id)).map(manu => manu.name).join(MANUFACTURER_NAMES_SEPARATOR)
        });
      this.manufacturerSelectionDisabled = true;
    }
  }

  listenToProductSearchResult(entries: CartEntryCsv[]): void {
    this.subscription.add(
      this.searchService.getSearchResponse().pipe(
        filter(response => response !== undefined)
      ).subscribe((response: CartProductSearchResponse) => {
        this.counter = this.counter + 1;
        const purchasableResults: CartProductSearchResult[] = response?.results?.filter((result: CartProductSearchResult) => isCartProductSearchResultPurchasable(result));
        if (purchasableResults?.length > 0) {
          this.searchResponses.push({
            response: {
              ...response,
              results: purchasableResults,
            },
            line: this.counter,
            consignmentInfo: entries[this.counter - 1]?.consignmentInfo,
            orderAmount: entries[this.counter - 1]?.quantity,
            substitutedOemNumber: response.oemNumber
          });
        } else {
          this.searchErrors.push({
            line: this.counter,
            entry: entries[this.counter - 1],
            reason: getCartProductSearchResultsNotPurchasableReason(response?.results)
          });
        }
        if (this.counter >= entries.length) {
           this.searchResultLoaded.next(true);
        }
      })
    );
  }

  onOpenSearchResultsModal(): void {
    const launchCartImportSelectionDialogObservable : Observable<ComponentRef<CartImportSelectionComponent>> | void = this.launchDialogService.launch(LAUNCH_CALLER.CART_IMPORT_SELECTION, this.vcr, undefined);
    const forceComplete$: Subject<void> = new Subject();

    if (launchCartImportSelectionDialogObservable) {
      combineLatest([
        launchCartImportSelectionDialogObservable,
        this.launchDialogService.dialogClose,
        this.searchResultLoaded.asObservable(),
        this.searchIsDone$.asObservable(),
      ])
      .pipe(takeUntil(forceComplete$))
      .subscribe(
        ([component, dialogClose, searchResultLoaded, searchIsDone]: [
          ComponentRef<CartImportSelectionComponent>,
          string,
          boolean,
          boolean
        ]) => {
          if (component) {
            this.productSearchResultModalRef = component;
            if (searchResultLoaded && !searchIsDone) {
              this.productSearchResultModalRef.instance.setSearchResponses(
                this.searchResponses
              );
              this.productSearchResultModalRef.instance.searchErrors =
                this.searchErrors;
              this.searchService.reset();
              this.importService.setSearchingStatusTo(false);
              this.searchIsDone$.next(true);
            }
          }

          if (dialogClose) {
            this.launchDialogService.clear(
              LAUNCH_CALLER.CART_IMPORT_SELECTION
            );

            if (component) {
              component.destroy();
              forceComplete$.next();
            }
          }
        }
      );
    }
  }

  closeModal(): void {
    this.cartImportDialogService.closeDialog('closed');
  }

  removeSelectedFile(documentTypeId: string): void {
    this.subscription.add(
      this.translationService.translate('uploadFile.placeholder').subscribe((value: string) => {
        this.importForm.patchValue({
          filename: ''
        });
        document.getElementById(documentTypeId).innerHTML = value;
        this.importFileValid = false;
      })
    );
  }
}
