import {Component, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DatePipe, DOCUMENT, KeyValue} from '@angular/common';

import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {CustomFormValidators} from '@spartacus/storefront';
import {Config, Country, GlobalMessageService, LanguageService, TranslationService, UserAddressService} from '@spartacus/core';

import {
  ContactPerson,
  RegistrationDocument,
  RegistrationInformationMultiOptionsFieldsValuesResponse,
  RegistrationData
} from '../../model/login-register.model';
import {RegistrationStartService} from '../../services/registration-start.services';
import {exceedMaxFileSize} from '@shared/utils/upload-file-size';
import {validFileExtension} from '@shared/validators/file-extension';
import {
  RegistrationInformationMultiOptionFieldsValuesService
} from '../../services/registration-information-multi-option-fields-values.service';
import { ICON_TYPE, RegistrationStatus } from '../../model/login-register-enum.model';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
})
export class RegistrationFormComponent implements OnInit, OnDestroy {
  isLoading$ = new BehaviorSubject(false);
  isSubmittingRegisterdData$ = new BehaviorSubject(false);
  countries$: Observable<Country[]>;
  titles: string[] = ['MALE', 'FEMALE', 'OTHER'];
  documents: RegistrationDocument[] = [];
  fileContent: string | ArrayBuffer | null = null;
  registrationInformationMultiOptionsFieldsValues: RegistrationInformationMultiOptionsFieldsValuesResponse;

  registrationForm: UntypedFormGroup = this.formBuilder.group({
    titleCode: ['', Validators.required],
    companyName: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    street: ['', Validators.required],
    streetNumber: ['', Validators.required],
    postalCode: ['', Validators.required],
    town: ['', Validators.required],
    countryIsoCode: ['', Validators.required],
    taxId: ['', Validators.required],
    email: ['', [Validators.required, CustomFormValidators.emailValidator]],
    newsletter: [false],
    termsAndConditions: [false, Validators.requiredTrue],
    documents: new UntypedFormArray([]),
    sepaBank: ['', [Validators.required]],
    sepaIban: ['', [Validators.required]],
    sepaBic: [''],
    sepaEmail: ['', [Validators.required, CustomFormValidators.emailValidator]],
    emailOrderConfirmation: ['', [Validators.required, CustomFormValidators.emailValidator]],
    emailDeliveryNoteForwarding: ['', [Validators.required, CustomFormValidators.emailValidator]],
    emailInvoiceForwarding: ['', [Validators.required, CustomFormValidators.emailValidator]],
    newsletterEmail: [''],
    privacyProtection: [false, [Validators.requiredTrue]],
    businessTypeCode: ['', [Validators.required]],
    specializationCodes: new UntypedFormArray([]),
    membership: [''],
    membershipId: [''],
    roleCode: ['', [Validators.required]],
    deliveryCosts: [false, [Validators.requiredTrue]],
    deliveryPerNoxNightExpress: [false, [Validators.requiredTrue]],
    noxDepotAvailable: [undefined, [Validators.required]],
    noxCustomerNumber: [''],
    attentionReasonInfos: new UntypedFormArray([]),
    contactPersons: new UntypedFormArray([]),
  });
  iconTypes = ICON_TYPE;

  // const DATE_FORMAT = 'yyyy-MM-dd';
  // fileDate: this.datePipe.transform(new Date(file.lastModified), this.DATE_FORMAT),
  private subscription: Subscription = new Subscription();
  private languageIsoCode: string;
  private attentionReasonInfosSubFormFields = {
    attentionReason: [false],
    attentionReasonInfo: [''],
  };

  private attentionReasonInfosSubFormField = {
    attentionReason: [false]
  };

  private contactPersonSubFormFields = {
    positionCode: [''],
    salutationCode: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email: ['', [Validators.required, CustomFormValidators.emailValidator]],
  };

  private registrationDocumentTypeSubFormField = {
    file: ['', [validFileExtension(this.config.fileExtension.registrationForm)]],
  };

  private registrationDocumentTypeRequiredSubFormField = {
    file: ['', [validFileExtension(this.config.fileExtension.registrationForm), Validators.required]],
  };

  constructor(
    private languageService: LanguageService,
    private userAddressService: UserAddressService,
    private formBuilder: UntypedFormBuilder,
    private globalMessageService: GlobalMessageService,
    private registrationStartService: RegistrationStartService,
    private translation: TranslationService,
    private datePipe: DatePipe,
    private registrationInformationMultiOptionFieldsValuesService: RegistrationInformationMultiOptionFieldsValuesService,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(Config) private config: any,
  ) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    const contactPersonsFormArray: UntypedFormArray = this.registrationForm.get('contactPersons') as UntypedFormArray;
    contactPersonsFormArray.push(this.formBuilder.group(this.contactPersonSubFormFields));
    this.registrationInformationMultiOptionFieldsValuesService.loadRegistrationInformationMultiOptionFieldsValues();
    this.subscription.add(this.registrationInformationMultiOptionFieldsValuesService.isLoading().subscribe((isLoading: boolean) => {
      this.isLoading$.next(isLoading);
    }));
    this.registrationInformationMultiOptionFieldsValuesService.getResponse()
    .pipe(
      filter(Boolean))
    .subscribe((response: RegistrationInformationMultiOptionsFieldsValuesResponse) => {
      this.registrationInformationMultiOptionsFieldsValues = response;
      const registrationSpecificBusinessTypeFormArray: UntypedFormArray = this.registrationForm.get('specializationCodes') as UntypedFormArray;

      if (registrationSpecificBusinessTypeFormArray.length === 0) {
        this.registrationInformationMultiOptionsFieldsValues.registrationSpecificBusinessTypes.forEach(() =>
          registrationSpecificBusinessTypeFormArray.push(new UntypedFormControl(false)));
      }

      const attentionReasonInfosFormArray: UntypedFormArray = this.registrationForm.get('attentionReasonInfos') as UntypedFormArray;
      if (attentionReasonInfosFormArray.length === 0) {
        this.registrationInformationMultiOptionsFieldsValues.attentionReasons.forEach((reason) => {
          if (reason.additionalInfo) {
            attentionReasonInfosFormArray.push(this.formBuilder.group(this.attentionReasonInfosSubFormFields));
          } else {
            attentionReasonInfosFormArray.push(this.formBuilder.group(this.attentionReasonInfosSubFormField));
          }
        });
      }

      const documentsFormArray: UntypedFormArray = this.registrationForm.get('documents') as UntypedFormArray;
      if (documentsFormArray.length === 0) {
        this.registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes.forEach((registrationDocumentType, index) => {
          if (registrationDocumentType.required) {
            documentsFormArray.push(this.formBuilder.group(this.registrationDocumentTypeRequiredSubFormField));
          } else {
            documentsFormArray.push(this.formBuilder.group(this.registrationDocumentTypeSubFormField));
          }
        });
      }
    });
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountries();
        }
      }),
    );
    const languageObservable: Observable<string> = this.languageService.getActive();
    this.subscription.add(languageObservable.subscribe(lang => this.languageIsoCode = lang));
    this.subscription.add(
      languageObservable.pipe(distinctUntilChanged()).subscribe(() =>
        this.registrationInformationMultiOptionFieldsValuesService.loadRegistrationInformationMultiOptionFieldsValues())
    );
    this.subscription.add(
      this.registrationStartService.isSending().subscribe((isProcessing: boolean) => {
        this.isSubmittingRegisterdData$.next(isProcessing);
      }),
    );
  }

  onSubmit(): void {
    const registrationSpecificationBusinessTypeCodes: string[] = [];
    this.registrationSpecificBusinessTypeFormControls.forEach((control, index) => {
      if (control.value) {
        registrationSpecificationBusinessTypeCodes.push(this.registrationInformationMultiOptionsFieldsValues.registrationSpecificBusinessTypes[index].code);
      }
    });

    const attentionReasonInfosKeyValueList: KeyValue<string, string>[] = [];
    const attentionReasonCodesList: string[] = [];
    this.attentionReasonInfosFormControls.forEach((control, index) => {
      if (control.value.attentionReason) {
        const attentionReasonCode: string = this.registrationInformationMultiOptionsFieldsValues.attentionReasons[index].code;
        attentionReasonCodesList.push(attentionReasonCode);
        attentionReasonInfosKeyValueList.push({
          key: attentionReasonCode,
          value: control.value.attentionReasonInfo
        });
      }
    });

    const contactPersonsList: ContactPerson[] = [];
    this.contactPersonsFormControls.forEach((control) => {
      const contactPerson: ContactPerson = {
        positionCode: control.value.positionCode,
        salutationCode: control.value.salutationCode,
        firstName: control.value.firstName,
        lastName: control.value.lastName,
        email: control.value.email,
        phone: control.value.phone,
      };
      contactPersonsList.push(contactPerson);
    });

    const registrationInformationRequest: RegistrationData = {
      companyName: this.registrationForm.value.companyName,
      genderCode: this.registrationForm.value.titleCode,
      firstName: this.registrationForm.value.firstName,
      lastName: this.registrationForm.value.lastName,
      street: this.registrationForm.value.street,
      streetNumber: this.registrationForm.value.streetNumber,
      postalCode: this.registrationForm.value.postalCode,
      town: this.registrationForm.value.town,
      countryIsocode: this.registrationForm.value.countryIsoCode,
      phone: '',
      incomeTaxId: this.registrationForm.value.taxId,
      email: this.registrationForm.value.email,
      newsletter: this.registrationForm.value.newsletter,
      termsAndConditions: this.registrationForm.value.termsAndConditions,
      privacyProtection: this.registrationForm.value.privacyProtection,
      deliveryCosts: this.registrationForm.value.deliveryCosts,
      deliveryPerNoxNightExpress: this.registrationForm.value.deliveryPerNoxNightExpress,
      languageIsocode: this.languageIsoCode,
      documents: this.documents,
      sepaBank: this.registrationForm.value.sepaBank,
      sepaIban: this.registrationForm.value.sepaIban.toUpperCase(),
      sepaBic: this.registrationForm.value.sepaBic,
      sepaEmail: this.registrationForm.value.sepaEmail,
      emailOrderConfirmation: this.registrationForm.value.emailOrderConfirmation,
      emailDeliveryNoteForwarding: this.registrationForm.value.emailDeliveryNoteForwarding,
      emailInvoiceForwarding: this.registrationForm.value.emailInvoiceForwarding,
      newsletterEmail: this.registrationForm.value.newsletter ? this.registrationForm.value.newsletterEmail : '',
      businessTypeCode: this.registrationForm.value.businessTypeCode,
      specializationCodes: registrationSpecificationBusinessTypeCodes,
      membership: this.registrationForm.value.membership,
      membershipId: this.registrationForm.value.membershipId,
      roleCode: this.registrationForm.value.roleCode,
      noxDepotAvailable: this.registrationForm.value.noxDepotAvailable,
      noxCustomerNumber: this.registrationForm.value.noxDepotAvailable ? this.registrationForm.value.noxCustomerNumber : '',
      attentionReasonCodes: attentionReasonCodesList,
      attentionReasonInfos: attentionReasonInfosKeyValueList,
      contactPersons: contactPersonsList,
      // TODO: Remove these dummy parameters passing once the registration flow is ready.
      currentIndex: 1,
      processId: uuid(),
      routeName: 'registrationStart',
      status: RegistrationStatus.COMPLETED,
    };

    this.registrationForm.markAllAsTouched();

    if (this.registrationForm.valid) {
      this.subscription.add(
        this.registrationStartService.success().pipe(filter(Boolean))
        .subscribe(() => this.resetForm())
      );

      this.registrationStartService.registrationStart(registrationInformationRequest);
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  fileChanged(fileList: FileList, documentTypeId: string, index: number): void {
    if (fileList[0].size === 0) {
      this.documentsFormControls[index].get('file').setErrors({
        fileEmpty: true
      });
    } else if (exceedMaxFileSize(fileList[0], this.config.maxFileSize.registrationForm)) {
      this.documentsFormControls[index].get('file').setErrors({
        maxFileSizeExceeded: {
          maxFileSize: this.config.maxFileSize.registrationForm
        }
      });
    } else {
      const file = fileList[0];
      const fileReader: FileReader = new FileReader();
      const self = this;
      fileReader.onloadend = () => {
        self.fileContent = fileReader.result;
        const doc: RegistrationDocument = {
          typeId: documentTypeId,
          lastModified: file.lastModified,
          encodedContent: btoa(this.fileContent as string),
          fileName: file.name,
        };
        const searchDocumentInd = this.documents.findIndex((document) => document.typeId === documentTypeId);
        if (searchDocumentInd > -1) {
          this.documents[searchDocumentInd] = doc;
        } else {
          this.documents.push(doc);
        }
      };
      fileReader.readAsBinaryString(file);
      document.getElementById(documentTypeId).innerHTML = file.name;
    }
  }

  removeSelectedFile(documentTypeId: string, index: number): void {
    if (this.documents.length) {
      const searchDocumentInd = this.documents.findIndex((document) => document.typeId === documentTypeId);

      if (searchDocumentInd > -1) {
        this.documents.splice(searchDocumentInd, 1);
        this.documentsFormControls[index].get('file').reset();
        this.subscription.add(
          this.translation.translate('uploadFile.placeholder').subscribe((value: string) => {
            document.getElementById(documentTypeId).innerHTML = value;
          })
        );
      }
    }
  }

  subscribeToNewsletterChange(): void {
    const newsletterEmailControl: AbstractControl = this.registrationForm.get('newsletterEmail');

    if (this.registrationForm.value.newsletter) {
      newsletterEmailControl.addValidators([Validators.required, CustomFormValidators.emailValidator]);
    } else {
      newsletterEmailControl.removeValidators([Validators.required, CustomFormValidators.emailValidator]);
    }

    newsletterEmailControl.updateValueAndValidity();
  }

  noxDepotAvailableChange(): void {
    const noxCustomerNumberControl: AbstractControl = this.registrationForm.get('noxCustomerNumber');

    if (this.registrationForm.value.noxDepotAvailable) {
      noxCustomerNumberControl.addValidators([Validators.required]);
    } else {
      noxCustomerNumberControl.removeValidators([Validators.required]);
    }

    noxCustomerNumberControl.updateValueAndValidity();
  }

  addContactPersonControl(): void {
    this.contactPersonsFormControls.push(this.formBuilder.group(this.contactPersonSubFormFields));
  }

  removeContactPersonControl(index: number): void {
    this.contactPersonsFormControls.splice(index, 1);
  }

  private resetForm(): void {
    this.registrationForm.reset();
    this.documents = [];
    this.subscription.add(
      this.translation.translate(
        'uploadFile.placeholder'
      ).subscribe(
        (placeholder) => {
          this.registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes.forEach((registrationDocumentType) => {
            document.getElementById(registrationDocumentType.code).innerHTML = placeholder as string;
          });
        }
      ),
    );
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector('form .ng-invalid');

    window.scroll({
      top: firstInvalidControl.getBoundingClientRect().top + window.scrollY - 40,
      left: 0,
      behavior: 'smooth',
    });
  }

  get registrationSpecificBusinessTypeFormControls(): AbstractControl[] {
    return (this.registrationForm.get('specializationCodes') as UntypedFormArray).controls;
  }

  get attentionReasonInfosFormControls(): AbstractControl[] {
    return (this.registrationForm.get('attentionReasonInfos') as UntypedFormArray).controls;
  }

  get contactPersonsFormControls(): AbstractControl[] {
    return (this.registrationForm.get('contactPersons') as UntypedFormArray).controls;
  }

  get documentsFormControls(): AbstractControl[] {
    return (this.registrationForm.get('documents') as UntypedFormArray).controls;
  }
}
