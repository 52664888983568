export const transportDamage = {
  transportDamage: {
    notice: 'Bitte beachten Sie unbedingt, dass Transportschäden schriftlich spätestens bis 11:00 Uhr gemeldet werden müssen.',
    globalMessage: {
      adviseOfTransportDamageFailure: 'Der Versand der Transportschadensmeldung ist fehlgeschlagen.',
      adviseOfTransportDamageSuccess: 'Die Transportschadensmeldung wurde erfolgreich versendet.'
    },
    transportDamageForm: {
      legend1: 'Geben Sie bitte Ihre Daten ein:',
      legend2: 'Beschreiben Sie bitte die Art der Beschädigung:',
      legend3: 'Laden Sie bitte Ihre Dokumente hoch:',
      orderNumber: {
        label: 'Auftragsnummer',
        placeholder: 'Auftragsnummer'
      },
      deliveryNoteNumber: {
        label: 'Lieferscheinnummer',
        placeholder: 'Lieferscheinnummer'
      },
      descriptionOfDamage: {
        label: 'Art der Beschädigung',
        placeholder: 'Art der Beschädigung'
      },
      oemNumber: {
        label: 'OEM-Nummer',
        placeholder: 'OEM-Nummer'
      },
      additionalHints: {
        label: 'Zusätzliche Anmerkungen',
        placeholder: 'Zusätzliche Anmerkungen'
      },
      repairPossible: {
        label: 'Instandsetzung möglich',
        placeholder: 'Instandsetzung möglich'
      },
      repairCosts: {
        label: 'Instandsetzungskosten',
        placeholder: 'Instandsetzungskosten'
      },
      replacementWanted: {
        label: 'Ersatzlieferung gewünscht',
        placeholder: 'Ersatzlieferung gewünscht'
      },
      packagingImage: {
        label: 'Foto der Gesamtverpackung'
      },
      damagedPartOfPackagingImage: {
        label: 'Foto des Verpackungsschadens'
      },
      articleImage: {
        label: 'Foto des Ersatzteils'
      },
      damagedPartOfArticleImage: {
        label: 'Foto des Schadens am Ersatzteil'
      },
      shippingLabelImage: {
        label: 'Foto vom Label der Versandeinheit'
      },
      articleNumberImage: {
        label: 'Foto der Artikelnummer des Ersatzteils'
      },
      additionalImage: {
        label: 'Zusätzliche Bilder'
      },
      submitTransportDamageForm: 'Meldung Absenden',
      radio: {
        yes: 'ja',
        no: 'Nein'
      },
      formMandatoryFields: 'Pflichtfelder sind mit einem * gekennzeichnet.',
      messages: {
        maxFileSizeLimitExceeded: 'Das Hochladen der Datei ist fehlgeschlagen. Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.'
      }
    }
  },
};
