import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CONSIGNMENT_INFO_ALLOWED_PATTERN, PDP_CALL_SOURCE } from '@shared/models/shared.model';
import { CartItemComponent, CartItemContextSource } from '@spartacus/cart/base/components';
import { OrderEntry } from '@spartacus/cart/base/root';
import { ICON_TYPE } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderInfoFieldsFormsValidationService } from '../../../checkout-order/service/order-info-fields-forms-validation.service';
import { PermissionCheckService } from '../../../user-profiles/service/permission-check.service';

const ENTRY_CONSIGNMENT_INFO_FORM_KEY_SUFFIX = '_CONSIGNMENT_INFO';

@Component({
  selector: 'app-efa-cart-item',
  templateUrl: './efa-cart-item.component.html',
})
export class EfaCartItemComponent extends CartItemComponent implements OnInit, OnDestroy {
  @Input() item: OrderEntry;
  @Input() isOrderDetails: boolean;
  @Input() fromConfigurator: boolean = false;
  @Output() consignmentChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() consignmentEditableChange: EventEmitter<boolean> = new EventEmitter();
  @Output() consignmentFocusedChange: EventEmitter<boolean> = new EventEmitter();
  @Output() consignmentFormCreated: EventEmitter<UntypedFormGroup> = new EventEmitter();

  iconTypes = ICON_TYPE;
  consignmentInfoEditable: boolean;
  consignmentInfoFocused = false;
  form: UntypedFormGroup;

  displayCustomerPrices$: Observable<boolean> = this.permissionCheckService.displayCustomerPrices();
  isOrderDetailsPage = new BehaviorSubject<boolean>(false);
  pdpCallSource: PDP_CALL_SOURCE;

  constructor(
    private fb: UntypedFormBuilder,
    cartItemContextSource: CartItemContextSource,
    protected permissionCheckService: PermissionCheckService,
    protected orderInfoFieldsFormsValidationService: OrderInfoFieldsFormsValidationService,
  ) {
    super(cartItemContextSource);
  }

  ngOnInit(): void {
    if (this.isOrderDetails) {
      this.pdpCallSource = PDP_CALL_SOURCE.ORDER;
    } else {
      this.pdpCallSource = PDP_CALL_SOURCE.CART;
    }

    this.consignmentInfoEditable = this.item.consignmentInfo && this.item.consignmentInfo !== '';
    this.consignmentEditableChange.emit(this.consignmentInfoEditable);
    this.createForm();
  }

  private createForm(): void {
    const consignmentInfoValue: string = this.item.consignmentInfo != null ? this.item.consignmentInfo : '';
    this.form = this.fb.group({consignmentInfo: [consignmentInfoValue, [Validators.pattern(CONSIGNMENT_INFO_ALLOWED_PATTERN)]]});
    this.orderInfoFieldsFormsValidationService.add(this.item.uniqueIdentifier + ENTRY_CONSIGNMENT_INFO_FORM_KEY_SUFFIX, this.form);
    this.consignmentFormCreated.emit(this.form);
  }

  valueChange(event: Event, formControl: AbstractControl): void {
    const target = event.target as HTMLInputElement;
    if (formControl.status === 'VALID') {
      this.consignmentChange.emit(target.value);
    }
  }

  toggleConsignmentInfo(): void {
    this.consignmentInfoEditable = !this.consignmentInfoEditable;
    this.consignmentInfoFocused = this.consignmentInfoEditable;
    this.consignmentEditableChange.emit(this.consignmentInfoEditable);
    this.consignmentFocusedChange.emit(this.consignmentInfoFocused);
  }

  ngOnDestroy(): void {
    this.orderInfoFieldsFormsValidationService.remove(this.item.uniqueIdentifier + ENTRY_CONSIGNMENT_INFO_FORM_KEY_SUFFIX);
  }
}
