import { Component, OnInit } from '@angular/core';
import { BreakpointService, ProductFacetNavigationComponent } from '@spartacus/storefront';
import { Custom_ICON_TYPE } from '../../model/product-facet-navigation-icon.model';

@Component({
  selector: 'app-efa-product-facet-navigation',
  templateUrl: './efa-product-facet-navigation.component.html'
})
export class EfaProductFacetNavigationComponent extends ProductFacetNavigationComponent {
  customIconTypes = Custom_ICON_TYPE;
  constructor(breakpointService: BreakpointService) {
    super(breakpointService)
  }

  ngOnInit(): void {
  }

}
