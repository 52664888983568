export const orderCancel = {
  orderCancellation: {
    cancellationAndReturn: {
      article: 'Artikel',
      quantity: 'Bestellte Menge',
      searchedArticleNumber: 'Gesuchte Artikelnr.',
      consignmentInfo: 'Komissionstext',
      selectItem: 'Artikel stornieren',
      selectItems: 'Bestellte Artikel stornieren',
      selectAll: 'Alle auswählen',
      cancelSelectedItems: 'Ausgewählte Artikel stornieren',
    },
    globalMessage: {
      orderCancellationFailure: 'Bei der Stornierung der ausgewählten Positionen des Auftrags \'{{orderNumber}}\' ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
      orderCancellationSuccess: 'Die Stornierung der ausgewählten Positionen des Auftrags \'{{orderNumber}}\' wurde erfolgreich ausgeführt.',
      notAllPositionsCouldBeCancelledWarning: 'Nicht alle Positionen des aktuellen Auftrags \'{{orderNumber}}\' konnten storniert werden. Bitte überprüfen Sie anhand des Status der einzelnen Positionen, wo die Stornierung erfolgreich war.',
    },
    selectedHint: '*Bitte wählen Sie mindestens eine Position aus'
  },
};
