import { createAction, props } from '@ngrx/store';

import { OrderCancelResponse } from '../../model/order-cancel.model';
import { ErrorType } from '@shared/models/ErrorTypes';
import { CancellationRequestEntryInputList } from '@spartacus/order/root';

export const orderCancellation = createAction(
  '[OrderCancel] Load Order Cancellation',
  props<{
    orderNumber: string,
    positions: CancellationRequestEntryInputList
  }>()
);

export const orderCancellationSuccess = createAction(
  '[OrderCancel] Load Order Cancellation Success',
  props<{ response: OrderCancelResponse }>()
);

export const orderCancellationFailure = createAction(
  '[OrderCancel] Load Order Cancellation Failure',
  props<{ errorResponse: ErrorType }>()
);

export const resetOrderCancellation = createAction(
  '[OrderCancel] Reset Order Cancellation',
);

