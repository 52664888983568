import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorType } from '@shared/models/ErrorTypes';
import { RegistrationInformationMultiOptionsFieldsValuesResponse } from '../../model/login-register.model';
import { RegistrationInformationMultiOptionFieldsValuesConnector } from '../../occ/connectors/registration-information-multi-option-fields-values.connector';
import { RegistrationInformationMultiOptionFieldsValuesActions } from '../actions';

@Injectable()
export class RegistrationInformationMultiOptionFieldsValuesEffect {
  loadRegistrationInformationMultiOptionFieldsValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegistrationInformationMultiOptionFieldsValuesActions.loadRegistrationInformationMultiOptionsFieldsValues),
      concatMap(() =>
        this.connector.loadRegistrationInformationMultiOptionFieldsValues().pipe(
          map((response: RegistrationInformationMultiOptionsFieldsValuesResponse) => RegistrationInformationMultiOptionFieldsValuesActions.loadRegistrationInformationMultiOptionsFieldsValuesSuccess({ response })),
          catchError((error: ErrorType) => of(RegistrationInformationMultiOptionFieldsValuesActions.loadRegistrationInformationMultiOptionsFieldsValuesFailure({ errorResponse: error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: RegistrationInformationMultiOptionFieldsValuesConnector,
  ) {}
}
