import { Component } from '@angular/core';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { CartImportDialogService } from '../cart-import/cart-import-dialog.service';
import { ImportMode } from '../../model/import-export-enum.model';

@Component({
  selector: 'app-cart-import-microcat-action',
  templateUrl: './cart-import-microcat-action.component.html',
})
export class CartImportMicrocatActionComponent {

  private modalRef: any;

  constructor(private cartImportDialogService: CartImportDialogService) {
  }

  displayImportDialog(): void {
    this.cartImportDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CART_IMPORT, undefined, ImportMode.MICROCAT);
  }

}
