import { createSelector, MemoizedSelector } from '@ngrx/store';

import { getInvoiceCreditState, InvoiceCreditState } from '../reducers';
import * as InvoiceCreditDetailsFeature from '../reducers/invoice-credit-details.reducers';
import { INVOICE_CREDIT_DETAILS_FEATURE_KEY } from '../reducers/invoice-credit-details.reducers';
import { InvoicesCreditDetailsResponse } from '../../model/invoices-credits.model';

export const selectInvoiceDetailsLoading: MemoizedSelector<InvoiceCreditDetailsFeature.InvoiceCreditDetailsState, boolean> = createSelector(
  getInvoiceCreditState,
  (state: InvoiceCreditState) => state[INVOICE_CREDIT_DETAILS_FEATURE_KEY].loading
);

export const selectInvoiceDetailsResponse: MemoizedSelector<InvoiceCreditDetailsFeature.InvoiceCreditDetailsState,
  InvoicesCreditDetailsResponse>
  = createSelector(
  getInvoiceCreditState,
  (state: InvoiceCreditState) => state[INVOICE_CREDIT_DETAILS_FEATURE_KEY].response
);
