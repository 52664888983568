import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as DefaultManufacturersFeature from '../store/reducers/default-manufacturers.reducer';
import * as DefaultManufacturersActions from '../store/actions/default-manufacturers.action';
import * as DefaultManufacturersSelectors from '../store/selectors/default-manufacturers.selectors';

@Injectable({
  providedIn: 'root',
})
export class DefaultManufacturersService {
  constructor(protected store: Store<DefaultManufacturersFeature.DefaultManufacturersState>) {}

  setDefaultManufacturers(defaultManufacturers: string): void {
    this.store.dispatch(DefaultManufacturersActions.setDefaultManufacturers({defaultManufacturers}));
  }

  isUpdating(): Observable<boolean> {
    return this.store.select(DefaultManufacturersSelectors.selectUpdating);
  }

  success(): Observable<boolean> {
    return this.store.select(DefaultManufacturersSelectors.selectSuccess);
  }
}
