import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {PaginationModel, SortModel} from '@spartacus/core';
import {Store} from '@ngrx/store';

import * as DeliveryNotesUiFeature from '../store/reducers/delivery-notes-ui.reducer';
import * as DeliveryNotesUiSelectors from '../store/selectors/delivery-notes-ui.selectors';
import {DeliveryNotesUiActions} from '../store/actions';
import {DeliveryNoteOverviewFilter} from '../model/delivery-notes.model';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNotesUiService {

  constructor(protected store: Store<DeliveryNotesUiFeature.DeliveryNotesUiState>) {
  }

  selectedFilterEntity(): Observable<DeliveryNoteOverviewFilter> {
    return this.store.select(DeliveryNotesUiSelectors.selectFilterEntity);
  }

  setSelectedFilterEntity(selectedFilterEntity: DeliveryNoteOverviewFilter): void {
    this.store.dispatch(DeliveryNotesUiActions.setSelectedFilterEntity({ selectedFilterEntity }));
  }

  selectedPagination(): Observable<PaginationModel> {
    return this.store.select(DeliveryNotesUiSelectors.selectPagination);
  }

  setSelectedPagination(selectedPagination: PaginationModel): void {
    this.store.dispatch(DeliveryNotesUiActions.setSelectedPagination({ selectedPagination }));
  }

  selectedSort(): Observable<SortModel> {
    return this.store.select(DeliveryNotesUiSelectors.selectSort);
  }

  setSelectedSort(selectedSort: SortModel): void {
    this.store.dispatch(DeliveryNotesUiActions.setSelectedSorting({ selectedSort }));
  }

  setReturningFromDetails(b: boolean): void {
    this.store.dispatch(DeliveryNotesUiActions.setReturningFromDetails({returningFromDetails: b} ));
  }

  returningFromDetails(): Observable<boolean> {
    return this.store.select(DeliveryNotesUiSelectors.returningFromDetails);
  }
}
