import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { OrderOverviewAdapter } from './adapters/order-overview.adapter';
import { OccOrderOverviewAdapter } from './adapters/impl/occ-order-overview.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: OrderOverviewAdapter,
      useClass: OccOrderOverviewAdapter,
    }
  ],
})
export class OccModule {
}
