import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { ReturnOverviewRequest, ReturnOverviewResponse } from '../../model/return-history.model';

export const loadReturnOverview = createAction(
  '[ReturnHistory] Load Return Overview',
  props<{ request: ReturnOverviewRequest }>()
);

export const loadReturnOverviewSuccess = createAction(
  '[ReturnHistory] Load Return Overview Success',
  props<{ response: ReturnOverviewResponse }>()
);

export const loadReturnOverviewFailure = createAction(
  '[ReturnHistory] Load Return Overview Failure',
  props<{ errorResponse: ErrorType }>()
);
