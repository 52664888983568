import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModules } from '@shared/shared.modules';
import {ConfigModule, I18nModule, OccConfig, UrlModule} from '@spartacus/core';
import { ShippingBacklogCancellationComponent } from './components/shipping-backlog-cancellation/shipping-backlog-cancellation.component';
import { ShippingBacklogFilterComponent } from './components/shipping-backlog-filter/shipping-backlog-filter.component';
import { ShippingBacklogOverviewComponent } from './components/shipping-backlog-overview/shipping-backlog-overview.component';
import { OccModule } from './occ/occ.module';
import { effects } from './store/effects';
import { reducers, SHIPPING_BACKLOG_FEATURE_KEY } from './store/reducers';
import {
  shippingBacklogTranslationChunksConfig,
  shippingBacklogTranslations
} from './translations/shipping-backlog.translations';
import { IconModule, ListNavigationModule, SpinnerModule } from '@spartacus/storefront';

@NgModule({
  declarations: [
    ShippingBacklogOverviewComponent,
    ShippingBacklogCancellationComponent,
    ShippingBacklogFilterComponent,
  ],
  imports: [
    CommonModule,
    SharedModules,
    IconModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    OccModule,
    NgbModule,
    ListNavigationModule,
    SpinnerModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ShippingBacklogOverviewComponent: {
          component: ShippingBacklogOverviewComponent,
        },
        ShippingBacklogCancellationComponent: {
          component: ShippingBacklogCancellationComponent,
        },
      },
    }),
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            shippingBacklogOverview:
              'users/${userId}/shippingBacklogOverview?fields=DEFAULT',
            shippingBacklogCancellation:
              'users/${userId}/shippingBacklogCancellation',
          },
        },
      },
    } as OccConfig),
    ConfigModule.withConfig({
      i18n: {
        resources: shippingBacklogTranslations,
        chunks: shippingBacklogTranslationChunksConfig,
      },
    }),
    ConfigModule.withConfig({
      icon: {
        symbols: {
          FILTER: 'fas fa-filter',
          SORTING: 'fas fa-sort',
        },
      },
    }),
    ConfigModule.withConfig({
      routing: {
        routes: {
          shippingBacklogCancellation: {
            paths: ['my-account/documents/shipping-backlog-cancellation'],
          },
          shippingBacklogOverview: {
            paths: ['my-account/documents/shipping-backlog'],
          },
        }
      }
    }),
    StoreModule.forFeature(SHIPPING_BACKLOG_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
    UrlModule,
    RouterModule,
  ],
})
export class ShippingBacklogModule {}
