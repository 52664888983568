import { Injectable } from '@angular/core';
import { SLASH, SLASH_ENCODED_URL_MARKER } from '@shared/models/shared.model';
import { CurrentProductService } from '@spartacus/storefront';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EfaCurrentProductService extends CurrentProductService {
  protected getCode(): Observable<string> {
    return super
      .getCode()
      .pipe(
        map((code: string) =>
          code?.replace(SLASH_ENCODED_URL_MARKER.substring(0, 3), SLASH)
        )
      );
  }
}
