<ng-container *ngIf="prices$ | async as prices">
  <cx-carousel
    [items]="items$ | async"
    [title]="title$ | async"
    [template]="carouselItem"
    itemWidth="285px"
  >
  </cx-carousel>

  <ng-template #carouselItem let-item="item">
    <app-product-badge *ngIf="item.productCampaign" [content]="item.productCampaign?.text" class="product-carousel-product-badge"></app-product-badge>
    <a tabindex="0" [routerLink]="{ cxRoute: 'product', params: item } | cxUrl" [queryParams]="{ source: pdpCallSource }">
      <cx-media class="cx-product-image" [container]="item.images?.PRIMARY" format="product"></cx-media>
      <h3>
        {{ item.name }}
      </h3>
      <div class="price" *ngIf="displayCustomerPrices$ | async">
        {{getCustomerPrice(prices, item.code)}}
      </div>
      <div class="price" *ngIf="!(displayCustomerPrices$ | async)">
        {{getListPrice(prices, item.code)}}
      </div>
    </a>
  </ng-template>
</ng-container>
