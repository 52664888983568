import { createAction, props } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import { DeliveryNoteOverviewFilter } from '../../model/delivery-notes.model';

export const setReturningFromDetails = createAction(
  '[DeliveryNotes] Set returningFromDetails',
  props<{ returningFromDetails: boolean }>()
);


export const setSelectedFilterEntity = createAction(
  '[DeliveryNotes] Set selected filterEntity',
  props<{ selectedFilterEntity: DeliveryNoteOverviewFilter }>()
);

export const setSelectedPagination = createAction(
  '[DeliveryNotes] Set selected Pagination',
  props<{ selectedPagination: PaginationModel }>()
);

export const setSelectedSorting = createAction(
  '[DeliveryNotes] Set selected Sort',
  props<{ selectedSort: SortModel }>()
);
