import { createAction, props } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import { InvoiceCreditOverviewFilter } from '../../model/invoices-credits.model';


export const setSelectedFilterEntity = createAction(
  '[InvoicesCredits] Set selected filterEntity',
  props<{ selectedFilterEntity: InvoiceCreditOverviewFilter }>()
);

export const setSelectedPagination = createAction(
  '[InvoicesCredits] Set selected Pagination',
  props<{ selectedPagination: PaginationModel }>()
);

export const setSelectedSorting = createAction(
  '[InvoicesCredits] Set selected Sort',
  props<{ selectedSort: SortModel }>()
);
