import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Address } from '@spartacus/core';
import { Observable } from 'rxjs';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryMode } from '@spartacus/cart/base/root';
import { ProductCategory } from '../model/cart-administration-enum.model';
import { OrderType } from '../model/cart-administration.model';
import { CartParametersActions } from '../store/actions';
import * as CartParametersFeature from '../store/reducers/cart-parameters.reducer';
import * as CartParametersSelectors from '../store/selectors/cart-parameters.selectors';

@Injectable({providedIn: 'root'})
export class CartParametersService {
  constructor(protected store: Store<CartParametersFeature.CartParametersState>) {}

  getCurrentOrderType(): Observable<OrderType> {
    return this.store.select(CartParametersSelectors.selectOrderType);
  }

  getCurrentShippingType(): Observable<DeliveryMode> {
    return this.store.select(CartParametersSelectors.selectShippingType);
  }

  getCurrentDeliveryAddress(): Observable<Address> {
    return this.store.select(CartParametersSelectors.selectDeliveryAddress);
  }

  getRequestDeliveryDate(): Observable<NgbDateStruct> {
    return this.store.select(CartParametersSelectors.selectRequestedDeliveryDate);
  }

  setCurrentOrderType(orderType: OrderType): void {
    return this.store.dispatch(CartParametersActions.setOrderType({ orderType }));
  }

  setShippingTypeId(shippingType: DeliveryMode): void {
    return this.store.dispatch(CartParametersActions.setShippingType({ shippingType }));
  }

  setDeliveryAddress(deliveryAddress: Address): void {
  return this.store.dispatch(CartParametersActions.setDeliveryAddress({ deliveryAddress }));
  }

  setRequestedDeliveryDate(requestedDeliveryDate: NgbDateStruct): void {
    return this.store.dispatch(CartParametersActions.setRequestedDeliveryDate({ requestedDeliveryDate }));
  }

  getCurrentProductCategory(): Observable<ProductCategory> {
    return this.store.select(CartParametersSelectors.selectProductCategory);
  }

  setCurrentProductCategory(productCategory: ProductCategory): void {
    return this.store.dispatch(CartParametersActions.setProductCategory({ productCategory }));
  }
}
