import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { FaqOverviewResponse } from '../../../model/faq-items.model';
import { FaqOverviewAdapter } from '../faq-overview.adapter';

@Injectable({ providedIn: 'root' })
export class OccFaqOverviewAdapter implements FaqOverviewAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  loadOverview(isUserLoggedIn: boolean): Observable<FaqOverviewResponse> {
    const url = this.occEndpoints.buildUrl('faqOverview', {
      urlParams: {
        userId: isUserLoggedIn ? 'current' : 'anonymous',
      },
    });

    return this.http.get<FaqOverviewResponse>(url);
  }
}
