import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WarrantyProcessRequest } from "../../model/warranty-process.model";
import { WarrantyProcessAdapter } from "../adapters/warranty-process.adapter";

@Injectable({
  providedIn: 'root',
})
export class WarrantyProcessConnector {
  constructor(protected adapter: WarrantyProcessAdapter) {}

  startWarrantyProcess (request: WarrantyProcessRequest):Observable<void> {
    return this.adapter.startWarrantyProcess(request);
  }
}
