import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import { AllowedFileExtensionsResponse } from '@shared/models/shared.model';


export const loadAllowedImageExtensions = createAction(
  '[AllowedImageExtensions] Load Allowed Image Extensions',
  props<{ contextId: string }>()
);

export const loadAllowedImageExtensionsSuccess = createAction(
  '[AllowedImageExtensions] Load Allowed Image Extensions Success',
  props<{ response: AllowedFileExtensionsResponse }>()
);

export const loadAllowedImageExtensionsFailure = createAction(
  '[AllowedImageExtensions] Load Allowed Image Extensions Failure',
  props<{ errorResponse: ErrorType }>()
);
