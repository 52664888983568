import { Component } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { RegistrationStatus } from '../../model/login-register-enum.model';
import { ContactPerson, RegistrationData } from '../../model/login-register.model';
import { AbstractRegistrationStepComponent } from '../abstract-registration-step/abstract-registration-step.component';

@Component({
  selector: 'app-registration-contact-persons',
  templateUrl: './registration-contact-persons.component.html',
})
export class RegistrationContactPersonsComponent extends AbstractRegistrationStepComponent {

  registrationForm: UntypedFormGroup = this.formBuilder.group({
    contactPersons: new UntypedFormArray([]),
    emailOrderConfirmation: ['', [Validators.required, CustomFormValidators.emailValidator]],
    emailDeliveryNoteForwarding: ['', [Validators.required, CustomFormValidators.emailValidator]],
    emailInvoiceForwarding: ['', [Validators.required, CustomFormValidators.emailValidator]],
  });

  private contactPersonSubFormFields = {
    positionCode: [''],
    salutationCode: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email: ['', [Validators.required, CustomFormValidators.emailValidator]],
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.addContactPersonControl();
  }

  protected fillFormDataFromResponse(
    registrationData: RegistrationData
  ): void {
    this.registrationForm.get('emailOrderConfirmation').patchValue(registrationData.emailOrderConfirmation);
    this.registrationForm.get('emailDeliveryNoteForwarding').patchValue(registrationData.emailDeliveryNoteForwarding);
    this.registrationForm.get('emailInvoiceForwarding').patchValue(registrationData.emailInvoiceForwarding);

    const contactPersonsList: ContactPerson[] = registrationData?.contactPersons;
    if (contactPersonsList != null && contactPersonsList.length > 0) {
      this.removeContactPersonControl(0);
      registrationData?.contactPersons.forEach((contactPerson, index) => {
        this.addContactPersonControl();
        this.contactPersonsFormControls[index].patchValue({
          positionCode: contactPerson.positionCode == null ? '' : contactPerson.positionCode,
          salutationCode: contactPerson.salutationCode,
          firstName: contactPerson.firstName,
          lastName: contactPerson.lastName,
          phone: contactPerson.phone,
          email: contactPerson.email,
        });
      });
    }
  }

  protected getStatus(): RegistrationStatus {
    return RegistrationStatus.INPROGRESS;
  };

  protected onSubmit(registrationData: RegistrationData): void {
    this.registrationForm.markAllAsTouched();
    if (this.registrationForm.invalid) {
      this.scrollToFirstInvalidControl();
      return;
    }

    const contactPersonsList: ContactPerson[] = [];
    this.contactPersonsFormControls.forEach((control) => {
      const contactPerson: ContactPerson = {
        positionCode: control.value.positionCode,
        salutationCode: control.value.salutationCode,
        firstName: control.value.firstName,
        lastName: control.value.lastName,
        email: control.value.email,
        phone: control.value.phone,
      };
      contactPersonsList.push(contactPerson);
    });

    const registrationInformationRequest: RegistrationData = {
      ...registrationData,
      emailOrderConfirmation: this.registrationForm.value.emailOrderConfirmation,
      emailDeliveryNoteForwarding: this.registrationForm.value.emailDeliveryNoteForwarding,
      emailInvoiceForwarding: this.registrationForm.value.emailInvoiceForwarding,
      contactPersons: contactPersonsList,
    };

    this.submit(registrationInformationRequest);
  }

  addContactPersonControl(): void {
    this.contactPersonsFormArray.push(this.formBuilder.group(this.contactPersonSubFormFields));
  }

  removeContactPersonControl(index: number): void {
    this.contactPersonsFormArray.removeAt(index);
  }

  get contactPersonsFormArray(): UntypedFormArray {
    return this.registrationForm.get('contactPersons') as UntypedFormArray;
  }

  get contactPersonsFormControls(): AbstractControl[] {
    return this.contactPersonsFormArray.controls;
  }
}
