import {
  Directive,
  ElementRef,
  AfterViewInit,
  ViewContainerRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { EfaPasswordVisibilityToggleComponent } from '@shared/components/efa-password-visibility-toggle/efa-password-visibility-toggle.component';
import { WindowRef } from '@spartacus/core';

/**
 * Directive to bind a EfaPasswordVisibilityToggleDirective to a password input field. This
 * toggle while alternate the appearance of the input between dots and plain text.
 */
@Directive({
  selector: '[efaPasswordVisibilitySwitch][type="password"]',
})
export class EfaPasswordVisibilityToggleDirective implements AfterViewInit {
  protected inputWrapper: HTMLElement | null;

  constructor(
    protected winRef: WindowRef,
    protected elementRef: ElementRef,
    protected viewContainerRef: ViewContainerRef,
    protected changeDetectorRef: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngAfterViewInit(): void {
    this.wrapInput();
    this.insertComponent();
    this.changeDetectorRef.detectChanges();
  }

  protected insertComponent(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(EfaPasswordVisibilityToggleComponent);
    const component = this.viewContainerRef.createComponent(factory);

    component.instance.inputElement = this.elementRef.nativeElement;
    this.inputWrapper?.appendChild(component.location.nativeElement);
  }

  protected wrapInput(): void {
    const input = this.elementRef.nativeElement;
    const parent = input.parentNode;

    this.inputWrapper = this.winRef.document.createElement('div');
    this.inputWrapper.setAttribute('class', 'app-password-input-wrapper');

    // set the wrapper as child (instead of the element)
    parent.replaceChild(this.inputWrapper, input);
    this.inputWrapper.appendChild(input);
  }
}
