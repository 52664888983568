export const invoicesCredits = {
  invoicesCreditsCustom: {
    globalMessage: {
      downloadContentEmpty: 'Für den ausgewählten Beleg ist kein PDF-Dokument hinterlegt.',
      loadingInvoicesCreditsOverviewFailure: 'Beim Öffnen der Rechnungsübersicht ist ein Fehler aufgetreten.',
    },
    invoiceCreditOverviewFilterForm: {
      documentNumber: {
        label: 'Belegnummer',
        placeholder: 'Belegnummer'
      },
      oemNumber: {
        label: 'Artikelnummer',
        placeholder: 'Artikelnummer',
      },
      deliveryNoteNumber: {
        label: 'Lieferscheinnummer',
        placeholder: 'Lieferscheinnummer',
      },
      orderNumber: {
        label: 'Auftragsnummer',
        placeholder: 'Auftragsnummer',
      },
      dateFrom: {
        label: 'Belegdatum von',
        placeholder: 'Belegdatum von',
      },
      dateTo: {
        label: 'Belegdatum bis',
        placeholder: 'Belegdatum bis',
      },
      documentTypeId: {
        label: 'Belegart',
        placeholder: 'Belegart',
      },
      consignmentInfo: {
        label: 'Ihre zusätzliche Auftragsinformation',
        placeholder: 'Ihre zusätzliche Auftragsinformation',
      }
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Rechnungen sortieren',
      creditBonus: {
        sorting: 'Gutschriften sortieren'
      },
      documentNumber: 'Belegnummer',
      CreationDate: 'Belegdatum',
      documentTypeId : 'Belegart id',
      documentTypeName : 'Belegart',
      total: 'Summe',
      select: 'Select',
      download: 'Herunterladen',
      selectAll: 'Alle auswählen',
    },
    viewType: {
      G2: 'Gutschrift',
      L2: 'Lastschrift',
      R2: 'Rechnung',
    },
    invoiceCreditOverview: {
      downloadInvoiceDocumentFileNamePrefix: 'Rechnung',
      downloadInvoiceDocumentsCompressedFileNamePrefix: 'Rechnungen',
      downloadCreditDocumentFileNamePrefix: 'Gutschrift',
      downloadCreditDocumentsCompressedFileNamePrefix: 'Gutschriften',
    }
  }
};
