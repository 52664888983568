import {NgModule} from '@angular/core';
import {CmsConfig, Config, provideConfig} from '@spartacus/core';
import {SMART_EDIT_FEATURE, SmartEditConfig, SmartEditRootModule} from '@spartacus/smartedit/root';

const mySmartEditConfig: SmartEditConfig = {
  smartEdit: {
    storefrontPreviewRoute: 'cx-preview',
    allowOrigin: 'localhost:9012,localhost:9002,backoffice.c6po16mjr7-efaautote1-s1-public.model-t.cc.commerce.ondemand.com:80,backoffice.c6po16mjr7-efaautote1-s1-public.model-t.cc.commerce.ondemand.com:443,backoffice.autoteilewelt.de:80,backoffice.autoteilewelt.de:443,e.issuu.com:443',
  },
};

@NgModule({
  declarations: [],
  imports: [
    SmartEditRootModule
  ],
  providers: [provideConfig({
    featureModules: {
      [SMART_EDIT_FEATURE]: {
        module: () =>
          import('@spartacus/smartedit').then((m) => m.SmartEditModule),
      },
    }
  } as CmsConfig),
    provideConfig(mySmartEditConfig)
]
})

export class SmartEditFeatureModule {
}
