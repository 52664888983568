export const loginRegister = {
  efaLoginForm: {
    title: 'Anmelden bei Ihrem Konto',
    titleNew: 'Ich bin neu hier',
    textReturningCustomer: 'Sie sind bereits Kunde und melden sich heute zum ersten Mal im Webshop an?',
    partnerId: {
      label: 'Ihre Kundennummer',
      placeholder: 'Bitte bestehende Kundennummer eingeben',
    },
    forgotPassword: 'Passwort vergessen',
    activateAccount: 'Kontoaktivierung für Webshop starten',
    activateAccountDescription: 'Aktivieren Sie bitte zunächst ihr bestehendes Konto.',
    registrationStart: 'In wenigen Schritten Ihr Kundenkonto anlegen',
    signIn: 'Anmelden',
    register: 'Registrieren als Neukunde',
    dontHaveAccount: 'Haben Sie kein Konto?',
    emailAddress: {
      label: 'E-Mail-Adresse Ihres Kundenkontos',
      placeholder: 'Bisherige Mail-Adresse Ihres Kundenkontos verwenden',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Passwort',
    },
    wrongEmailFormat: 'Das ist kein gültiges E-Mail-Format.',
    separator: 'Oder',
  },
  efaHttpHandlers: {
    tokenWasRefreshed:
      'Der Bestätigungslink zur Registrierung war abgelaufen. Daher wurde Ihnen per E-Mail ein neuer Link zugesendet. Bitte versuchen Sie es erneut.',
    registrationAlreadyConfirmed:
      'Die Registrierung wurde bereits bestätigt und Ihre Daten wurden zur Anlage des Benutzerkontos weitergeleitet.',
    tokenNotFound:
      'Der Bestätigungslink ist nicht mehr gültig. Bitte wenden Sie sich an den Support.',
    loginDeactivated:
      'Aktuell ist keine Anmeldung mit Ihren Login-Daten möglich. Bitte kontaktieren Sie unseren Kundensupport:',
    b2bUnitInactive:
      'Wir prüfen gerne gemeinsam mit Ihnen die Hintergründe für die Sperrung und helfen prompt und gerne dabei, Ihr Konto zu reaktivieren. Bitte senden Sie uns eine Email an:',
    placeOrderTimeoutError:
      'Der Auftrag wurde erfolgreich an unser System übermittelt und befindet sich in der Warteschleife. Bitte überprüfen Sie in einigen Minuten, ob in Ihrer Auftragshistorie im Mein Konto-Bereich ein neuer Auftrag aufgelistet wird.',
    placeOrderDuplicateError:
      'Für den aktuellen Warenkorb und die angegebene Kundenreferenz wurde bereits eine Auftragserstellung angestoßen. Bereinigen Sie für eine neue Bestellung den Warenkorb und ändern Sie Ihre Auftragsreferenz.',
    calculationError:
      'Bei der Warenkorbberechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie die verfügbaren Mengen und angegebenen Preise der Artikel im Warenkorb.',
  },
  efaConsentDialog: {
    labelDecline: 'Ablehnen',
    labelAccept: 'Zustimmen',
    termsAndConditions: 'Allgemeine Geschäftsbedingungen',
    noTermsConditions_line1:
      'Sie müssen den Allgemeinen Geschäftsbedingungen des Shops zustimmen. Andernfalls dürfen Sie den Webshop nicht nutzen und werden sofort abgemeldet.',
    noTermsConditions_line2:
      'Über den folgenden Link können Sie die Details prüfen: ',
  },
  efaRegistrationForm: {
    formMandatoryFields: 'Pflichtfelder sind mit einem * gekennzeichnet.',
    legend1: 'Firmen- und Kontaktdaten',
    legend2: 'Betriebsart',
    legend3: 'Spezialisierung',
    legend4: 'Zugehörigkeit',
    legend5: 'Ansprechpartner',
    legend6: 'Bankverbindung',
    legend7: 'E-Mail-Adressen',
    legend8: 'Lieferung',
    legend9: 'Unterlagen',
    legend10:
      'Möchten Sie in Zukunft immer die neusten Informationen und Angebote erhalten? Dann melden Sie sich hier für unseren Newsletter an.',
    legend11:
      'Verraten Sie uns zum Abschluss noch kurz wie Sie auf uns aufmerksam geworden sind?',
    legend12: 'Jetzt Registrierung einreichen',
    title: 'Anrede',
    selectPosition: 'Position auswählen',
    selectTitle: 'Anrede auswählen',
    companyName: {
      label: 'Firma',
      placeholder: 'Firma',
    },
    firstName: {
      label: 'Vorname',
      placeholder: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
      placeholder: 'Nachname',
    },
    yourFirstName: {
      label: 'Vorname',
      placeholder: 'Ihr Vorname',
    },
    yourLastName: {
      label: 'Nachname',
      placeholder: 'Ihr Nachname',
    },
    phone: {
      label: 'Telefonnummer',
      placeholder: 'Telefonnummer',
    },
    street: {
      label: 'Straße',
      placeholder: 'Straße',
    },
    streetNumber: {
      label: 'Hausnummer',
      placeholder: 'Hausnummer',
    },
    postalCode: {
      label: 'PLZ',
      placeholder: 'PLZ',
    },
    town: {
      label: 'Ort',
      placeholder: 'Ort',
    },
    countryIsoCode: {
      label: 'Land',
      selectCountry: 'Land auswählen',
    },
    taxId: {
      label: 'Umsatzsteuer-ID oder Steuernummer',
      placeholder: 'Ust-ID z.B. DE999999999 oder Steuernummer',
    },
    emailAddress: {
      label: 'E-Mail Adresse',
      placeholder: 'E-Mail Adresse',
    },
    sepaBank: {
      label: 'Kreditinstitut',
      placeholder: 'Kreditinstitut',
    },
    sepaIban: {
      label: 'IBAN',
      placeholder: 'IBAN',
    },
    sepaBic: {
      label: 'BIC',
      placeholder: 'BIC',
    },
    sepaEmailAddress: {
      label: 'E-Mail für Zahlungs-Avis',
      placeholder: 'E-Mail für Zahlungs-Avis',
    },
    contactPersonEmailAddress: {
      label: 'E-Mail Adresse des Ansprechpartners',
      placeholder: 'E-Mail Adresse des Ansprechpartners',
    },
    orderConfirmationEmailAddress: {
      label: 'E-Mail für Auftragsbestätigung',
      placeholder: 'E-Mail für Auftragsbestätigung',
    },
    deliveryNoteForwardingEmailAddress: {
      label: 'E-Mail für Lieferscheinversand',
      placeholder: 'E-Mail für Lieferscheinversand',
    },
    invoiceForwardingEmailAddress: {
      label: 'E-Mail für Rechnungsversand',
      placeholder: 'E-Mail für Rechnungsversand',
    },
    newsletterEmailAddress: {
      label: 'E-Mail für Newsletter',
      placeholder: 'E-Mail für Newsletter',
    },
    membership: {
      label: 'Verband',
      placeholder: 'z.B. HUK, Innovation',
    },
    membershipId: {
      label: 'Verbands-ID-Nr.',
      placeholder: 'Verbands-ID-Nr.',
    },
    deliveryCosts: {
      label: 'Versandkosten',
      confirmation:
        'Wir berechnen die Versandkostenpauschale mit 9,60 € pro Bestellung (zuzüglich gesetzlicher Mehrwertsteuer).',
    },
    deliveryPerNoxNightExpress: {
      label: 'Warenlieferung erfolgt per NOX Nachtexpress',
      confirmation:
        'Uns ist bekannt, dass die EFA Autoteilewelt GmbH bei ordnungsgemäßer Warenanlieferung in ein nicht abschließbares Depot Haftung für Ware ausschließt, die durch Verlust, Beschädigung, Vermögensschäden und sonstige Folgen (z.B. ein möglicher Verlust durch Diebstahl) entstehen.',
    },
    noxDepotAvailable: {
      label: 'Verschließbares NOX Depot vorhanden',
      option1: 'ja',
      option2: 'nein',
    },
    noxCustomerNumber: {
      label: 'Wenn ja, NOX Kunden-Nr.',
      placeholder: 'NOX Kunden-Nr.',
    },
    attentionReasonInfo: {
      label: 'AD-Name',
      placeholder: 'AD-Name',
    },
    contactPerson: '. Ansprechpartner',
    addContactPerson: 'weitere Ansprechpartner hinzufügen',
    removeContactPerson: 'Ansprechpartner entfernen',
    contactPersonPosition: 'Position',
    selectContactPersonPosition: 'Position auswählen',
    role: 'Position',
    newsletter: 'Ich möchte mich für den Newsletter anmelden.',
    confirmThatRead1: 'Ich bestätige, dass ich die',
    confirmThatRead2: 'gelesen und akzeptiert habe.',
    termsAndConditions: 'Allgemeinen Geschäftsbedingungen',
    confirmPrivacyProtection1: 'Ich bestätige,',
    confirmPrivacyProtection2: 'gelesen zu haben, und akzeptiert diese.',
    privacyProtection: 'die Datenschutzrichtlinien',
    globalMessage: {
      invalidIban: 'Bei der Prüfung der IBAN ist ein Fehler aufgetreten. Bitte überprüfen Sie die  angegebene IBAN und versuchen Sie es noch einmal.',
      registrationFailure: 'Während der Registrierung ist ein Fehler aufgetreten.',
      registrationSuccess: 'Die Registrierung wurde erfolgreich ausgeführt. Sie erhalten in Kürze eine E-Mail zur Bestätigung Ihrer E-Mail-Adresse. Bestätigen Sie Ihre E-Mail-Adresse bitte innerhalb von 30 Minuten.',
      registrationConfirmationFailure: 'Während der Bestätigung der Registrierung ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
    },
    registerButton: 'Absenden',
    gender: {
      MALE: 'Herr',
      FEMALE: 'Frau',
      OTHER: 'Divers',
    },
    formErrors: {
      companyName: {
        required: 'Bitte geben Sie Ihre vollständige Firmenbezeichnung an.',
      },
      role: {
        required:
          'Bitte geben Sie an, ob Sie Inhaber oder Geschäftsführer des Betriebes sind (Prokura = Geschäftsführer).',
      },
      title: {
        required: 'Ihre Anrede, “Herr”, “Frau”, “Divers”',
      },
      yourFirstName: {
        required: 'Ihr Vorname',
      },
      yourLastName: {
        required: 'Ihr Nachname',
      },
      taxId: {
        required: 'Bitte geben Sie Ihre Umsatzsteuer ID oder Steuernummer an',
      },
      emailAddress: {
        required: 'Die E-Mailadresse des Inhabers oder Geschäftsführers',
        cxInvalidEmail: 'Dies ist kein gültiges E-Mail-Format',
      },
      street: {
        required: 'Ihre Straße',
      },
      streetNumber: {
        required: 'Ihre Hausnummer',
      },
      postalCode: {
        required: 'Ihre Postleitzahl',
      },
      town: {
        required: 'Ihr Ort',
      },
      countryIsoCode: {
        required: 'In welchem Land befindet sich Ihre Firma?',
      },
      businessTypeCode: {
        required:
          'Um unsere Services optimal auf Sie abstimmen zu können, geben Sie bitte Ihre Betriebsart an.',
      },
      firstName: {
        required: 'Ihr Vorname',
      },
      lastName: {
        required: 'Ihr Nachname',
      },
      phone: {
        required:
          'Damit wir Sie optimal erreichen können, geben Sie bitte Ihre Telefonnummer an.',
      },
      contactPersonEmailAddress: {
        required:
          'Bitte geben Sie hier die E-Mail Ihres Hauptansprechpartners an.',
        cxInvalidEmail: 'Dies ist kein gültiges E-Mail-Format',
      },
      sepaBank: {
        required: 'Bitte geben Sie den Name Ihrer Bank an',
      },
      sepaIban: {
        required: 'Bitte geben Sie Ihre IBAN an',
      },
      sepaBic: {
        required: 'Bitte geben Sie Ihre BIC an',
      },
      sepaEmailAddress: {
        required: 'An diese E-Mail senden wir Zahlungs-Avise',
        cxInvalidEmail: 'Dies ist kein gültiges E-Mail-Format',
      },
      deliveryCosts: {
        required: 'Bitte bestätigen Sie die Versandkostenpauschale',
      },
      deliveryPerNoxNightExpress: {
        required: 'Bitte bestätigen Sie zu Ihrer Sicherheit die Bedingungen der Warenlieferung per NOX Nachtexpress',
      },
      noxDepotAvailable: {
        required: 'Geben Sie bitte an ob ein NOX Depot vorhanden ist',
      },
      uploadPdfFile: {
        invoiceSheet: {
          required: 'Bitte laden Sie Ihren Firmen/Rechnungsbogen als PDF Datei hoch',
          validFileExtension: 'Bitte laden Sie Ihren Firmen/Rechnungsbogen als PDF Datei hoch',
          maxFileSizeExceeded: 'Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.',
        },
        businessRegistration: {
          required: 'Bitte laden Sie Ihre Gewerbeanmeldung als PDF Datei hoch',
          validFileExtension: 'Bitte laden Sie Ihre Gewerbeanmeldung als PDF Datei hoch',
          maxFileSizeExceeded: 'Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.',
        },
        craftRegistration: {
          validFileExtension: 'Bitte laden Sie Ihren Eintrag in die Handwerksrolle/-karte als PDF Datei hoch',
          maxFileSizeExceeded: 'Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.',
        },
        mercedesDeclaration: {
          validFileExtension: 'Bitte laden Sie Ihre Mercedes-Erklärung als PDF Datei hoch',
          maxFileSizeExceeded: 'Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.',
        },
        volkswagenDeclaration: {
          validFileExtension: 'Bitte laden Sie Ihre VW-Erklärung als PDF Datei hoch',
          maxFileSizeExceeded: 'Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.',
        },
        bmwDeclaration: {
          validFileExtension: 'Bitte laden Sie Ihre BMW-Erklärung als PDF Datei hoch',
          maxFileSizeExceeded: 'Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.',
        },
        noxDeliveryInfo: {
          validFileExtension: 'Bitte laden Sie Ihre NOX Abstellinformationen als PDF Datei hoch',
          maxFileSizeExceeded: 'Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.',
        },
      },
      termsAndConditions: {
        required: 'Bitte bestätigen Sie unsere AGB’s, um Ihre Registrierung abzusenden',
      },
      privacyProtection: {
        required: 'Bitte bestätigen Sie die Datenschutzrichtlinien, um Ihre Registrierung abzusenden',
      },
    },
  },
  efaRegistrationConfirmation: {
    error: 'Ihre Registrierung konnte noch nicht abgeschlossen werden.',
  },
  efaRegistrationStart: {
    headline: 'Registrierung',
    continueToRegistration: 'Registrierung starten',
  },
  efaForgotPasswordForm: {
    submitActivateAccount: 'Kontoaktivierung starten',
    cancelActivateAccount: 'zurück',
    submitForgotPassword: 'E-Mail zum Zurücksetzen erhalten',
    cancelForgotPassword: 'zurück zur Anmeldung',
  },
  ssoLogin: {
    globalMessage: {
      ssoLoginFailure:
        'Die automatische Anmeldung am EFA Autoteilewelt Shop ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
    },
  },
};
