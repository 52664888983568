import { createAction, props } from '@ngrx/store';

import { ReturnablePosition, ReturnDocumentType } from '../../model/return-creation.model';

export const ResetReturnablePositions = createAction(
  '[ReturnCreation] Reset Returnable Positions'
);


export const SetReturnablePositions = createAction(
  '[ReturnCreation] Set Returnable Positions',
  props<{ positions: ReturnablePosition[] }>()
);

export const SetDocumentData = createAction(
  '[ReturnCreation] Set Document Data',
  props<{
    documentNumber: string,
    documentType: ReturnDocumentType,
    routeOrigin: string
  }>()
);
