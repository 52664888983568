import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { GlobalMessageType, StateWithProcess } from '@spartacus/core';
import { OrderSelectors, StateWithOrder } from '@spartacus/order/core';
import { OrderHistoryFacade } from '@spartacus/order/root';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-efa-account-order-details-shipping',
  templateUrl: './efa-order-details-shipping.component.html',
})
export class EfaOrderDetailsShippingComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  order$: Observable<any>;

  private subscription: Subscription = new Subscription();

  constructor(private orderHistoryFacade: OrderHistoryFacade,
              private  store: Store<StateWithOrder | StateWithProcess<void>>,
              private globalMessageService: EfaGlobalMessageService) {}

  ngOnInit(): void {
    this.orderHistoryFacade.clearOrderDetails();
    this.order$ = this.orderHistoryFacade.getOrderDetails();
    this.isLoading$ = this.orderHistoryFacade.getOrderDetailsLoading();

    this.subscription.add(this.store.select(OrderSelectors.getOrderState)
      .pipe(
        filter((o) => o.orderDetail.error))
      .subscribe(n => this.globalMessageService.add(
        {
          key: 'orderHistoryCustom.globalMessage.orderDetailsLoadFailure',
        },
        GlobalMessageType.MSG_TYPE_ERROR
      )));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
