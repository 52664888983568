import { ActionReducerMap, createFeatureSelector } from '@ngrx/store'
import * as TiresWarrantyReducers from './tires-warranty.reducer'

export const TIRES_WARRANTY_INDEX_FEATURE_KEY = 'tiresWarranty'

export interface TiresWarrantyState {
  [TiresWarrantyReducers.TIRES_WARRANTY_FEATURE_KEY]:TiresWarrantyReducers.TiresWarrantyState
}

export const reducers:ActionReducerMap<TiresWarrantyState> = {
  [TiresWarrantyReducers.TIRES_WARRANTY_FEATURE_KEY]: TiresWarrantyReducers.TiresWarrantyReducer
}
export const getTiresWarrantyState = createFeatureSelector<TiresWarrantyState>(TIRES_WARRANTY_INDEX_FEATURE_KEY)
