<section class="cx-page-section container"
         *ngIf="!(isLoading$ | async); else loading">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 px-2">
      <div class="cx-section">
        <form [formGroup]="registrationForm">
          <p>{{"efaRegistrationForm.formMandatoryFields" | cxTranslate }}</p>
          <fieldset class="register_fieldset"
                    *ngIf="registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes?.length > 0">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend9' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <ng-container formArrayName="documents">
                <div class="row form-group"
                     *ngFor="let documentFormControl of documentsFormControls; let i=index">
                  <ng-container [formGroupName]="i">
                    <span class="col-lg-4">
                      <span
                        [ngClass]="{'required': registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].required}">
                        <span
                          class="label-content">{{ registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].name }}</span>
                      </span>
                    </span>
                    <div class="col-lg-8">
                      <div class="o-import-file">
                        <div class="o-import-file__input">
                          <div class="custom-file">
                            <input type="file" class="custom-file-input"
                                   formControlName="file"
                                   (change)="fileChanged($event.target.files, registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].code, i)">
                            <label attr.data-content="{{ 'uploadPdfFile.search' | cxTranslate }}"
                                   class="custom-file-label"
                                   id="{{registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].code}}">
                              <cx-icon [type]="iconTypes.PDF"></cx-icon>
                              {{ 'uploadPdfFile.placeholder' | cxTranslate }}
                            </label>
                          </div>
                        </div>
                        <div class="o-import-file__remove">
                          <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                                   (click)="removeSelectedFile(registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].code, i)"></cx-icon>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <cx-form-errors prefix="efaRegistrationForm.formErrors.uploadPdfFile.{{ registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].code }}"
                                      [control]="documentFormControl.get('file')"></cx-form-errors>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
