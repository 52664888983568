import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CartProductSearchRequest, CartProductSearchResponse } from '../../model/cart-administration.model';
import { CartProductSearchAdapter } from '../adapters/cart-product-search.adapter';

@Injectable({providedIn: 'root'})
export class CartProductSearchConnector {
  constructor(protected adapter: CartProductSearchAdapter) {}

  searchProducts(request: CartProductSearchRequest): Observable<CartProductSearchResponse> {
    return this.adapter.searchProducts(request);
  }
}
