import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, concatMap, map, withLatestFrom} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {GlobalMessageType, PaginationModel, SortModel} from '@spartacus/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import {DeliveryNotesOverviewActions} from '../actions';
import {ErrorType} from '@shared/models/ErrorTypes';
import {DeliveryNotesOverviewConnector} from '../../occ/connectors/delivery-notes-overview.connector';
import {DeliveryNoteOverviewFilter, DeliveryNoteOverviewRequest, DeliveryNoteOverviewResponse} from '../../model/delivery-notes.model';
import {selectResponse} from '../selectors/delivery-notes-overview.selectors';
import {DeliveryNotesOverviewState} from '../reducers/delivery-notes-overview.reducer';
import {selectFilterEntity, selectPagination, selectSort} from '../selectors/delivery-notes-ui.selectors';
import {DeliveryNotesUiState} from '../reducers/delivery-notes-ui.reducer';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class DeliveryNotesOverviewEffect {
  oldFilterEntity: DeliveryNoteOverviewFilter;
  oldPagination: PaginationModel;
  oldSort: SortModel;

  loadDeliveryNotesOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeliveryNotesOverviewActions.loadDeliveryNoteOverview),
      withLatestFrom(
        this.store.pipe(select(selectResponse)),
        this.store.pipe(select(selectFilterEntity)),
        this.store.pipe(select(selectPagination)),
        this.store.pipe(select(selectSort)),
      ),
      concatMap(([action, deliveryNotesOverview, filterEntity, pagination, sort]) => {
          this.setOldFilterData(filterEntity, pagination, sort);
          if (deliveryNotesOverview !== undefined && !this.hasRequestChanged(action.request)) {
            return of(DeliveryNotesOverviewActions.loadDeliveryNoteOverviewSuccess({
              response: deliveryNotesOverview
            }));
          } else {
            return this.connector.loadOverview(action.request).pipe(
              map((deliveryNoteOverviewResponse: DeliveryNoteOverviewResponse) =>
                DeliveryNotesOverviewActions.loadDeliveryNoteOverviewSuccess({
                  response: deliveryNoteOverviewResponse
                })),
                catchError((error: ErrorType) => {
                  this.globalMessageService.add(
                    {
                      key: 'deliveryNotesCustom.globalMessage.loadingDeliveryNotesOverviewFailure',
                    },
                    GlobalMessageType.MSG_TYPE_ERROR
                  );
                  return  of(DeliveryNotesOverviewActions.loadDeliveryNoteOverviewFailure({errorResponse: error}));
                })
            );
          }
        }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: DeliveryNotesOverviewConnector,
    private store: Store<DeliveryNotesOverviewState | DeliveryNotesUiState>,
    private globalMessageService: EfaGlobalMessageService,
  ) {
  }

  private setOldFilterData(filter: DeliveryNoteOverviewFilter, pagination: PaginationModel, sort: SortModel): void {
    this.oldFilterEntity = filter;
    this.oldPagination = pagination;
    this.oldSort = sort;
  }

  private hasRequestChanged(request: DeliveryNoteOverviewRequest): boolean {
    const oldDeliveryNoteOverviewRequest: DeliveryNoteOverviewRequest = {
      deliveryNoteNumber: this.oldFilterEntity.deliveryNoteNumber,
      orderNumber: this.oldFilterEntity.orderNumber,
      oemNumber: this.oldFilterEntity.oemNumber,
      consignmentInfo: this.oldFilterEntity.consignmentInfo,
      dateTo: this.formatFilterDate(this.oldFilterEntity.dateTo),
      dateFrom: this.formatFilterDate(this.oldFilterEntity.dateFrom),
      sort: this.oldSort,
      pagination: this.oldPagination,
    };
    return JSON.stringify(request) !== JSON.stringify(oldDeliveryNoteOverviewRequest);
  }


  private formatFilterDate(date: NgbDateStruct): string {
    return date ? date.year + '-' + date.month + '-' + date.day : '';
  }
}
