export const importExport = {
  importExport: {
    cart: {
      export: 'Warenkorb exportieren',
      import: 'Warenkorb (CSV) importieren',
      importMicrocat: 'Warenkorb (Microcat) importieren'
    },
    cartImportDialog:
      {
        header: 'Bestelllistenimport',
        manufacturerCodes: {
          label: 'Marken',
          placeholder: ''
        },
        importFile: {
          label: 'Importdatei',
          userManual: {
            csv: {
            line1: {
              preFileExtension: 'Bitte laden Sie eine Datei im',
              fileExtension: 'CSV Format (UTF-8)',
              postFileExtension:' mit dem nachfolgend beschriebenen Aufbau:',
            },
            line2: 'Kopfzeile (Zeile 1): Artikelnummer;Anzahl;Kommission',
            line3: 'Eine Zeile pro zu importierendem Material (Werte durch Semikolon getrennt)',
            line4: 'Werte: Artikelnummer, Bestellmenge und Kommissionstext',
            line5: 'Artikelnummer und Bestellmenge sind Pflichtfelder. Der Kommissionstext ist optional.',
            line6: 'Beispiele:',
            line7: '1743830;5',
            line8: '1743830;5;interne Bestellinfo',
            line9: 'Bitte beachten Sie:',
            line10: 'Um eine Vorlage zu erzeugen, können Sie einen bestehenden Warenkorb exportieren.',
            line11: 'Falls Sie die Datei in Excel bearbeiten, muss die Datei im Format CSV UTF-8 (Durch Trennzeichen getrennt) (*csv) gespeichert werden.',
          },
          microcat: {
            line1: {
              preFileExtension: 'Bitte wählen Sie eine Datei im',
              fileExtension: 'Microcat Format',
              postFileExtension:'aus.',
            },
            line2: 'Bitte beachten Sie:',
            line3: 'Es werden nur Artikel der Marken Toyota und Ford berücksichtigt.',
          }},
        },
        buttonSearch: 'Ausführen',
        selectFile: 'Datei wählen'
      },
    cartImportSelectionDialog: {
      buttonImport: 'Importieren',
      buttonClose: 'Schließen',
      selectDeselectAll: 'alle aus-/abwählen',
      articleId: 'ID {{oemNumber}}',
      article: 'Artikel',
      brand: 'Marke',
      quantity: 'Menge',
      price: 'Preis',
      header: 'Bestelllistenimport: Artikelauswahl',
      importError: 'Beim Import sind Fehler aufgetreten. Die fehlerhaften Artikel können erneut selektiert und importiert werden.',
      lineHeader: 'Zeile {{index}}: Artikel: {{oemNumber}}, Bestellmenge: {{orderAmount}}, Kommission: {{consignmentInfo}}',
      lineHeaderError: {
        reason_articleNotPurchasable: 'Zeile {{index}}: Artikel {{oemNumber}}: Der gesuchte Artikel ist nicht mehr verkaufsfähig.',
        reason_articleNotFound: 'Zeile {{index}}: Artikel {{oemNumber}}: Artikel nicht gefunden.',
        reason_articleCannotBeDelivered: 'Zeile {{index}}: Artikel {{oemNumber}}: Artikel ist nicht mehr lieferbar.',
        reason_articleNotApprovedForSale: 'Zeile {{index}}: Artikel {{oemNumber}}: Artikel ist zur Zeit nicht für den Verkauf freigegeben.'
      },
      duplicateResultsAvailable: 'In der Importdatei sind Artikelnummern, zu denen mehr als ein Artikel gefunden wurde (gelbe Markierung). Bitte wählen Sie die passenden Artikel, um den Import starten zu können.'
    },
    importFile: {
      invalidLine: 'Die Zeile {{ind}} ist ungültig: {{line}}',
      invalidHeader: 'Das CSV Format ist ungültig. Folgende Spalten werden benötigt: oemNumber, quantity, consignmentInfo',
      empty: 'Die Datei enthält keine gültigen Import-Zeilen.'
    },
    csvHeaders: {
      oemNumber: 'Artikelnummer',
      quantity: 'Anzahl',
      consignmentInfo: 'Kommission'
    },
    cartExportDialog: {
      header: 'Bestelllistenexport',
      filename: {
        placeholder: 'Dateiname',
      },
      infoText: 'Bitte geben Sie einen Dateinamen für den Warenkorbexport an:',
      buttonClose: 'Schließen',
      buttonExport: 'Exportieren',
      defaultFileName: 'Warenkorb-Export'
    }
  }
};
