import {Injectable} from '@angular/core';
import {RoutingService, StateWithProcess, StateWithUser} from '@spartacus/core';
import {distinctUntilChanged, map, tap, withLatestFrom} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import * as OrderDetailsSelectors from '../store/selectors/order-details.selectors';
import { Order, OrderHistoryFacade } from '@spartacus/order/root';
import { StateWithOrder } from '@spartacus/order/core';

@Injectable({
  providedIn: 'root'
})
export class EfaOrderDetailsService {

  orderCode$: Observable<string>;

  constructor(private orderHistoryFacade: OrderHistoryFacade,
              private routingService: RoutingService,
              private  store: Store<StateWithOrder | StateWithProcess<void>>) {

    this.orderCode$ = this.routingService.getRouterState().pipe(
      tap((rd) => console.log(rd.state)),
      map((routingData) => routingData.state.params.sapOrderNumber),
      distinctUntilChanged()
    );
  }

  getOrderDetails(): Observable<Order> {
    return this.orderHistoryFacade.getOrderDetails()
      .pipe(
        withLatestFrom(this.orderCode$, this.store.select(OrderDetailsSelectors.getOrderDetailsLoading)),
        tap(([details, orderCode, isLoading]) => {
          if ((!details || Object.keys(details).length === 0) && orderCode !== undefined && !isLoading) {
            this.orderHistoryFacade.loadOrderDetails(orderCode);
          }
        }),
        map(([details, orderCode, isLoading]) => details)
      );
    return null;
  }
}
