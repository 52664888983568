import { Injectable } from '@angular/core';
import { Converter, Product } from '@spartacus/core';
import { PriceRequestItem, ProductPriceRequest } from '../model/product-prices.model';

@Injectable({
  providedIn: 'root',
})
export class ProductPricesSerializer implements Converter<string[], ProductPriceRequest> {
  constructor() {
  }

  convert(source: string[], target?: ProductPriceRequest): ProductPriceRequest {
    if (target === undefined) {
      target = {} as ProductPriceRequest;
    }
    target.products = source.map((product) => {
      const priceRequestItem = {} as PriceRequestItem;
      priceRequestItem.productCode = product;
      priceRequestItem.quantity = 1;
      return priceRequestItem;
    });
    return target;
  }
}

