import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as ShippingBacklogOverviewReducers from './shipping-backlog-overview.reducer';
import * as ShippingBacklogCancellationReducers from './shipping-backlog-cancellation.reducer';
import * as ShippingBacklogSelectedItemsReducers from './shipping-backlog-selected-items.reducer';
import * as ShippingBacklogUiReducers from './shipping-backlog-ui.reducer';

export const SHIPPING_BACKLOG_FEATURE_KEY = 'shippingBacklog';

export interface ShippingBacklogState {
  [ShippingBacklogOverviewReducers.SHIPPING_BACKLOG_OVERVIEW_FEATURE_KEY]: ShippingBacklogOverviewReducers.ShippingBacklogOverviewState;
  [ShippingBacklogCancellationReducers.SHIPPING_BACKLOG_CANCELLATION_FEATURE_KEY]: ShippingBacklogCancellationReducers.ShippingBacklogCancellationState;
  [ShippingBacklogSelectedItemsReducers.SHIPPING_BACKLOG_SELECTED_ITEMS_FEATURE_KEY]: ShippingBacklogSelectedItemsReducers.ShippingBacklogSelectedItemsState;
  [ShippingBacklogUiReducers.SHIPPING_BACKLOG_UI_FEATURE_KEY]: ShippingBacklogUiReducers.ShippingBacklogUiState;
}

export const reducers: ActionReducerMap<ShippingBacklogState> = {
  [ShippingBacklogOverviewReducers.SHIPPING_BACKLOG_OVERVIEW_FEATURE_KEY]: ShippingBacklogOverviewReducers.shippingBacklogOverviewReducer,
  [ShippingBacklogCancellationReducers.SHIPPING_BACKLOG_CANCELLATION_FEATURE_KEY]: ShippingBacklogCancellationReducers.shippingBacklogCancellationReducer,
  [ShippingBacklogSelectedItemsReducers.SHIPPING_BACKLOG_SELECTED_ITEMS_FEATURE_KEY]: ShippingBacklogSelectedItemsReducers.shippingBacklogSelectedItemsReducer,
  [ShippingBacklogUiReducers.SHIPPING_BACKLOG_UI_FEATURE_KEY]: ShippingBacklogUiReducers.shippingBacklogUiReducer,
};

export const getShippingBacklogState = createFeatureSelector<ShippingBacklogState>(SHIPPING_BACKLOG_FEATURE_KEY);