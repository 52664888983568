import { Component } from '@angular/core';
import {AnonymousConsentManagementBannerComponent} from "@spartacus/storefront";

@Component({
  selector: 'app-efa-anonymous-consent-management-banner',
  templateUrl: './efa-anonymous-consent-management-banner.component.html'
})
export class EfaAnonymousConsentManagementBannerComponent extends AnonymousConsentManagementBannerComponent{

}
