<ng-container *ngIf="downloadItem as item">
  <ng-container *ngIf="item.displayButton === 'true'; else noButton">
    <div class="download-item-container {{ styleClasses ? styleClasses : ''}}">
      <div class="download-item-image {{ styleClasses ? styleClasses : ''}}">
        <cx-media [container]="item.thumbnail ? item.thumbnail : defaultThumbnail" [alt]="item.name?item.name:''" [title]="item.name?item.name:''" ></cx-media>
      </div>
      <div *ngIf="item.name" class="download-item-text-wrapper">
        <div  class="download-item-title text-left-aligned">
          {{ item.name }}
        </div>
        <div *ngIf="item.subtitle" class="download-item-subtitle text-left-aligned">
          {{ item.subtitle }}
        </div>
      </div>
      <div class="download-item-link">
        <app-download-link
          [url]="item.downloadFile.url"
          [target]="item.downloadFile.openFirst==='true' ? '_blank' : '_self'"
          [classes]="['btn btn-block btn-primary']"
        >
        <ng-container *ngIf="item.downloadFile.openFirst==='true'; else download">
          {{ item.buttonText ? item.buttonText : 'tooltip.view' | cxTranslate }}
        </ng-container>
        <ng-template #download>
          {{ item.buttonText ? item.buttonText : 'tooltip.download' | cxTranslate }}
        </ng-template>
      </app-download-link>
      </div>
    </div>
  </ng-container>
  <ng-template #noButton>
    <div class="download-item-container">
      <a [href]="item.downloadFile.url" [target]="item.downloadFile.openFirst==='true' ? '_blank' : '_self'">
        <div class="download-item-box {{ styleClasses ? styleClasses : ''}}">
          <div class="download-item-image">
            <cx-media [container]="item.thumbnail ? item.thumbnail : defaultThumbnail" [alt]="item.name?item.name:''" [title]="item.name?item.name:''" ></cx-media>
          </div>
          <div *ngIf="item.name" class="download-item-text-wrapper">
            <div  class="download-item-title">
              {{ item.name }}
            </div>
            <div *ngIf="item.subtitle" class="download-item-brand">
              {{ item.subtitle }}
            </div>
          </div>
        </div>
      </a>
    </div>
  </ng-template>
</ng-container>

