import { createReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { RegistrationConfirmationActions } from '../actions';

export const REGISTRATION_CONFIRMATION_FEATURE_KEY = 'registrationConfirmation';

export interface RegistrationConfirmationState {
  token: string;
  sending: boolean;
  error: ErrorType;
}

export const initialStateRegistrationConfirmation: RegistrationConfirmationState = {
  token: null,
  sending: false,
  error: undefined,
};

export const registrationConfirmationReducer = createReducer(
  initialStateRegistrationConfirmation,

  on(RegistrationConfirmationActions.loadRegistrationConfirmation, (state, action) => ({
    ...state,
    token: action.token,
    sending: true,
  })),
  on(RegistrationConfirmationActions.loadRegistrationConfirmationSuccess, (state) => ({
    ...state,
    sending: false,
  })),
  on(RegistrationConfirmationActions.loadRegistrationConfirmationFailure, (state, action) => ({
    ...state,
    sending: false,
    error: action.errorResponse
  })),
);

