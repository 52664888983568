import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { ReturnDetailsResponse } from '../../model/return-history.model';

export const resetReturnDetails = createAction(
  '[ReturnHistory] Reset Return Details'
);


export const loadReturnDetails = createAction(
  '[ReturnHistory] Load Return Details',
  props<{ returnNumber: string }>()
);

export const loadReturnDetailsSuccess = createAction(
  '[ReturnHistory] Load Return Details Success',
  props<{ response: ReturnDetailsResponse }>()
);

export const loadReturnDetailsFailure = createAction(
  '[ReturnHistory] Load Return Details Failure',
  props<{ errorResponse: ErrorType }>()
);
