import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ReturnDetailsActions } from '../actions';
import { ErrorType } from '@shared/models/ErrorTypes';
import { ReturnDetailsResponse } from '../../model/return-history.model';
import { ReturnDetailsConnector } from '../../occ/connectors/return-details.connector';

@Injectable()
export class ReturnDetailsEffect {

  loadReturnDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReturnDetailsActions.loadReturnDetails),
      concatMap((action) =>
        this.connector.loadDetails(action.returnNumber).pipe(
          map((returnDetailsResponse: ReturnDetailsResponse) => {
            return ReturnDetailsActions.loadReturnDetailsSuccess({
              response: returnDetailsResponse
            });
          }),
          catchError((error: ErrorType) => {
            return of(ReturnDetailsActions.loadReturnDetailsFailure({errorResponse: error}));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: ReturnDetailsConnector,
  ) {
  }
}
