<img class="u-img-fluid"
  *ngIf="media?.src"
  [attr.src]="media.src"
  [attr.srcset]="media.srcset"
  [attr.alt]="media.alt"
  [attr.role]="media.role"
  [attr.loading]="loadingStrategy"
  (load)="loadHandler()"
  (error)="errorHandler()"
/>
