import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { SLASH } from '@shared/models/shared.model';
import { SemanticPathService } from '@spartacus/core';
import { Observable, of } from 'rxjs';

const HOME_ROUTE_NAME = 'home';
const FORCE_REDIRECTION_TO_HOME_PAGE_SUB_PATH = 'efa704';

@Injectable({
  providedIn: 'root',
})
export class PageNotFoundRedirectionGuard {
  constructor(
    private router: Router,
    private semanticPathService: SemanticPathService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (route.url.join(SLASH).includes(FORCE_REDIRECTION_TO_HOME_PAGE_SUB_PATH)) {
      return of(
        this.router.parseUrl(this.semanticPathService.get(HOME_ROUTE_NAME))
      );
    }

    return of(true);
  }
}
