import { Injectable } from '@angular/core';
import { WishListFacade } from '@spartacus/cart/wish-list/root';
import { CmsService, Page } from '@spartacus/core';
import { PageLayoutHandler } from '@spartacus/storefront';
import { Observable, combineLatest, iif } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EfaWishListPageLayoutHandler implements PageLayoutHandler {
  constructor(
    protected wishListService: WishListFacade,
    protected cmsService: CmsService
  ) {}

  handle(
    slots$: Observable<string[]>,
    pageTemplate?: string,
    section?: string
  ) {
    if (pageTemplate === 'AccountPageTemplate' && !section) {
      const wishListPageSlotsObservable$: Observable<string[]> = combineLatest([
        slots$,
        this.wishListService.getWishList(),
      ]).pipe(
        map(([slots, wishList]) => {
          const exclude = (arr, args) =>
            arr.filter((item) => args.every((arg) => arg !== item));
          return wishList?.entries?.length !== 0
            ? exclude(slots, ['SideContent'])
            : slots;
        })
      );

      return this.cmsService
        .getCurrentPage()
        .pipe(
          mergeMap((page: Page) =>
            iif(
              () => page?.pageId === 'WishListPage',
              wishListPageSlotsObservable$,
              slots$
            )
          )
        );
    }
    return slots$;
  }
}
