import { Component } from '@angular/core';
import {ConsentManagementFormComponent} from "@spartacus/storefront";

@Component({
  selector: 'app-efa-consent-management-form',
  templateUrl: './efa-consent-management-form.component.html'
})
export class EfaConsentManagementFormComponent extends ConsentManagementFormComponent {

}
