<div
  *ngIf="flyout && node?.children.length > 1"
  class="back is-open"
  (click)="back()"
>
  <span>
    <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
    {{ "common.back" | cxTranslate }}
  </span>
</div>

<ng-container *ngFor="let child of node?.children">
  <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }">
  </ng-container>
</ng-container>

<!-- we generate links in a recursive manner -->
<ng-template #nav let-node="node" let-depth="depth" let-open="open" let-parent="parent">
  <nav
    (click)="toggleOpenNode($event, node)"
    (mouseenter)="onMouseEnter($event)"
    (keydown.space)="toggleOpen($event)"
    (keydown.esc)="back()"
  >
    <ng-container
      *ngIf="
        node.url && (!node.children || node.children?.length === 0);
        then navLink;
        else navText
      "
    ></ng-container>

    <ng-template #navLink>
      <cx-generic-link *ngIf="isNodeVisible(node, parent)"
        [url]="node.url"
        [target]="node.target"
        [style]="node.styleAttributes"
        [class]="node.styleClasses"
      >
        <span class="expand-flyout" [ngClass]="getNodeLinkClass(node)" *ngIf="flyout">
          <span [ngClass]="getNodeLinkIconClass(node)">
            <cx-icon [type]="customIconType.LOGOUT" class="pl-0"></cx-icon>
          </span>
          <span>{{ node.title }}</span>
        </span>
        <span *ngIf="!flyout">{{ node.title }}</span>
        <cx-icon
          *ngIf="flyout && node.children?.length > 0"
          [type]="iconType.CARET_DOWN"
        ></cx-icon>
      </cx-generic-link>
    </ng-template>

    <ng-template #navText>
      <ng-container *ngIf="(node.children?.length || !!depth) && isNodeVisible(node, parent)">
        <span class="is-parent"
          [ngClass]="!!node.children?.length ? 'navHeading' : 'navItem'"
          [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1" *ngIf="node.title?.length > 0"
        >
          <span class="expand-flyout" [ngClass]="getBackTitleClass(node)" *ngIf="flyout">
            <span class="expand-flyout--nested" [ngClass]="getBackClass(node)">
              <cx-icon
              class="mr-2 pl-0"
              *ngIf="flyout && node.children?.length > 0"
              [type]="iconType.CARET_LEFT"
              ></cx-icon>
            </span>
            {{ node.title }}
          </span>
          <span *ngIf="!flyout">{{ node.title }}</span>
          <ng-container *ngIf="isRoot(node); else child">
            <cx-icon
              *ngIf="flyout && node.children?.length > 0"
              [type]="iconType.CARET_DOWN"
              ></cx-icon>
          </ng-container>
          <ng-template #child>
            <span [ngClass]="getExpandClass(node)">
              <cx-icon
              *ngIf="flyout && node.children?.length > 0"
              [type]="iconType.CARET_RIGHT"
              ></cx-icon>
            </span>
          </ng-template>
          
        </span>
      </ng-container>
    </ng-template>

    <!-- we add a wrapper to allow for better layout handling in CSS -->
    <div class="wrapper" *ngIf="node.children?.length > 0 && isNodeOpen(node)">
      <cx-generic-link
        *ngIf="node.url"
        [url]="node.url"
        [target]="node.target"
        class="all"
      >
        {{ "navigation.shopAll" | cxTranslate : { navNode: node.title } }}
      </cx-generic-link>
      <div
        class="childs"
        [attr.depth]="getTotalDepth(node)"
        [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
        [attr.columns]="getColumnCount(node.children?.length)"
      > 
        <ng-container *ngIf="isNodeOpen(node)">
          <ng-container *ngFor="let child of node.children">
            <ng-container
              *ngTemplateOutlet="nav; context: { parent: node, node: child, depth: depth + 1, open: isNodeOpen(child) }"
            >
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </nav>
</ng-template>
