import { Component, OnInit } from '@angular/core';
import {ResetPasswordComponent} from "@spartacus/user/profile/components";

@Component({
  selector: 'app-efa-reset-password',
  templateUrl: './efa-reset-password.component.html'
})
export class EfaResetPasswordComponent extends ResetPasswordComponent {

}
