import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as DeliveryNotesOverviewFeature from '../reducers/delivery-notes-overview.reducer';
import { DELIVERY_NOTES_OVERVIEW_FEATURE_KEY } from '../reducers/delivery-notes-overview.reducer';
import { DeliveryNotesState, getDeliveryNotesState } from '../reducers';
import { DeliveryNoteOverviewResponse } from '../../model/delivery-notes.model';


export const selectLoading: MemoizedSelector<DeliveryNotesOverviewFeature.DeliveryNotesOverviewState, boolean>
  = createSelector(
  getDeliveryNotesState,
  (state: DeliveryNotesState) => state[DELIVERY_NOTES_OVERVIEW_FEATURE_KEY].loading
);

export const selectResponse: MemoizedSelector<DeliveryNotesOverviewFeature.DeliveryNotesOverviewState, DeliveryNoteOverviewResponse>
  = createSelector(
  getDeliveryNotesState,
  (state: DeliveryNotesState) => state[DELIVERY_NOTES_OVERVIEW_FEATURE_KEY].response
);
