import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { PaginationModel, SortModel } from '@spartacus/core';
import { Store } from '@ngrx/store';

import * as OrderOverviewUiFeature from '../store/reducers/order-overview-ui.reducer';
import * as OrderOverviewUiSelectors from '../store/selectors/order-overview-ui.selectors';
import { OrderOverviewFilter } from '../model/order-history.model';
import { OrderOverViewUiActions } from '../store/actions';

@Injectable({
  providedIn: 'root'
})
export class OrderOverviewUiService {

  constructor(protected store: Store<OrderOverviewUiFeature.OrderOverviewUiState>) {
  }

  selectedFilterEntity(): Observable<OrderOverviewFilter> {
    return this.store.select(OrderOverviewUiSelectors.selectFilterEntity);
  }

  setSelectedFilterEntity(selectedFilterEntity: OrderOverviewFilter): void {
    this.store.dispatch(OrderOverViewUiActions.setSelectedFilterEntity({ selectedFilterEntity }));
  }

  selectedPagination(): Observable<PaginationModel> {
    return this.store.select(OrderOverviewUiSelectors.selectPagination);
  }

  setSelectedPagination(selectedPagination: PaginationModel): void {
    this.store.dispatch(OrderOverViewUiActions.setSelectedPagination({ selectedPagination }));
  }

  selectedSort(): Observable<SortModel> {
    return this.store.select(OrderOverviewUiSelectors.selectSort);
  }

  setSelectedSort(selectedSort: SortModel): void {
    this.store.dispatch(OrderOverViewUiActions.setSelectedSorting({ selectedSort }));
  }

  setReturningFromDetails(b: boolean): void {
    this.store.dispatch(OrderOverViewUiActions.setReturningFromDetails({returningFromDetails: b} ));
  }

  returningFromDetails(): Observable<boolean> {
    return this.store.select(OrderOverviewUiSelectors.returningFromDetails);
  }

}
