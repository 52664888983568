import { createReducer, on } from '@ngrx/store';
import { TiresWarrantyRequest } from '../../model/tires-warranty.model';
import { TiresWarrantyActions } from '../actions';

export const TIRES_WARRANTY_FEATURE_KEY = 'tiresWarranty';

export interface TiresWarrantyState {
  request: TiresWarrantyRequest;
  proccessing: boolean;
  success: boolean;
}

export const initialState: TiresWarrantyState = {
  request: undefined,
  proccessing: false,
  success: undefined
};

export const TiresWarrantyReducer = createReducer(
  initialState,
  on(TiresWarrantyActions.startTiresWarranty, (state, action) => ({
    ...state,
    proccessing: true,
    request: action.request
  })),
  on(TiresWarrantyActions.tiresWarrantySuccess, (state) => ({
    ...state,
    proccessing: false,
    success: true,
    request: undefined
  })),
  on(TiresWarrantyActions.TiresWarrantyFailer, (state, action) => ({
    ...state,
    proccessing: false,
    success: false,
    error: action.errorResponse
  }))
);
