import {Component} from '@angular/core';

import {ConsentManagementComponent, ConsentManagementComponentService, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {
  AnonymousConsentsConfig,
  AnonymousConsentsService,
  AuthService,
  ConsentTemplate,
  EventService,
  RoutingService,
  UserConsentService
} from '@spartacus/core';
import {TermsConfirmationEvent} from '../../../login-register/model/login-register.model';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CONSENT_MANAGEMENT = 'CONSENT_MANAGEMENT',
  }
}

@Component({
  selector: 'app-efa-consent-management',
  templateUrl: './efa-consent-management.component.html',
})
export class EfaConsentManagementComponent extends ConsentManagementComponent {

  constructor(
    protected userConsentService: UserConsentService,
    protected globalMessageService: EfaGlobalMessageService,
    protected anonymousConsentsConfig: AnonymousConsentsConfig,
    protected anonymousConsentsService: AnonymousConsentsService,
    protected authService: AuthService,
    protected consentManagementComponentService: ConsentManagementComponentService,
    private launchDialogService: LaunchDialogService,
    private routingService: RoutingService,
    private eventService: EventService
  ) {
    super(
      userConsentService,
      globalMessageService,
      anonymousConsentsConfig,
      anonymousConsentsService,
      authService,
      consentManagementComponentService
    );
  }

  onConsentChange({
    given,
    template,
  }: {
    given: boolean;
    template: ConsentTemplate;
  }): void {
    if (given  && template.id === '002-TERMS_CONDITIONS') {
      this.userConsentService.giveConsent(template.id, template.version);
    } else if (template.id === '002-TERMS_CONDITIONS') {
      const dialog = this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CONSENT_MANAGEMENT, undefined, undefined);
      this.launchDialogService.dialogClose.subscribe(result => this.triggerTermsAndConditions(template, result));
    } else {
      super.onConsentChange({ given, template })
    }
  }

  triggerTermsAndConditions(consentTemplate: ConsentTemplate, result: boolean): void {
    if(result === undefined) {
      return;
    }
    if (result) {
      this.userConsentService.withdrawConsent(consentTemplate.currentConsent.code);
      this.routingService.go({ cxRoute: 'logout' });
      this.eventService.dispatch(new TermsConfirmationEvent(false));
    } else {
      window.location.reload();
    }
  }
}
