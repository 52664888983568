<div class="cx-order-items px-0" *ngIf="order$ | async as order">
  <h4 class="cx-order-items-header">
    {{ 'checkoutOrderConfirmation.orderItems' | cxTranslate }}
  </h4>

  <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
    <cx-promotions [promotions]="orderPromotions"></cx-promotions>
  </ng-container>

  <app-efa-cart-item-list [items]="order.entries"
                          [readonly]="true"
                          [fromConfigurator]="order.fromConfigurator"
                          [promotionLocation]="promotionLocation"></app-efa-cart-item-list>
</div>
