import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as CheckoutOrderUiFeature from '../reducers/checkout-order-ui.reducers';
import { CHECKOUT_ORDER_UI_FEATURE_KEY } from '../reducers/checkout-order-ui.reducers';
import { CheckoutOrderState, getCheckoutOrderState } from '../reducers';


export const selectIsValidForm: MemoizedSelector<CheckoutOrderUiFeature.CheckoutOrderUiState, boolean>
  = createSelector(
  getCheckoutOrderState,
  (state: CheckoutOrderState) => state[CHECKOUT_ORDER_UI_FEATURE_KEY].isValidForm
);

export const selectPlaceOrderTriggered: MemoizedSelector<CheckoutOrderUiFeature.CheckoutOrderUiState, boolean>
  = createSelector(
  getCheckoutOrderState,
  (state: CheckoutOrderState) => state[CHECKOUT_ORDER_UI_FEATURE_KEY].placeOrderTriggered);
