import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';

export const loadConfiguratorCart = createAction(
  '[LoadConfiguratorCart] Load Configurator Cart',
  props<{ userId: string }>()
);

export const loadConfiguratorCartSuccess = createAction(
  '[LoadConfiguratorCart] Load Configurator Cart Success',
  props<{ response: any }>()
);

export const loadConfiguratorCartFailure = createAction(
  '[LoadConfiguratorCart] Load Configurator Cart Failure',
  props<{ errorResponse: ErrorType }>()
);
