import { Component } from '@angular/core';
import { AddToWishListComponent } from '@spartacus/cart/wish-list/components/add-to-wishlist';
import { AuthService, Product } from '@spartacus/core';
import {
  CurrentProductService,
  ICON_TYPE,
} from '@spartacus/storefront';
import { map, tap } from 'rxjs/operators';
import { AddToCartParam } from '../../../cart-administration/model/cart-administration.model';
import { MultiAddToCartService } from '../../../cart-administration/services/multi-add-to-cart.service';
import { OrderEntry } from '@spartacus/cart/base/root';
import { WishListFacade } from '@spartacus/cart/wish-list/root';

@Component({
  selector: 'app-efa-add-to-wish-list-base',
  templateUrl: './efa-add-to-wish-list-base.component.html',
})
export class EfaAddToWishListComponentBaseComponent extends AddToWishListComponent {

  iconTypes = ICON_TYPE;

  constructor(
    protected wishListService: WishListFacade,
    protected currentProductService: CurrentProductService,
    protected authService: AuthService,
    protected multiAddToCartService: MultiAddToCartService
  ) {
    super(wishListService, currentProductService, authService);

    }


  add(product: Product): void {
    const addToCartParams: AddToCartParam[] = [];
    const addToCartParam: AddToCartParam = {
      productCode: product.code,
      quantity: 1,
      manufacturerId: product.manufacturerId,
      materialName: product.name,
      articleNumber: product.manufacturerAID,
      requiredShippingTypeID: '',
      substitutedOEMNumber: '',
      orderInfoRefId: 0,
      consignmentInfo: '',
      unitId: '',
      customerPricePerUnit: product.customerPrice,
      listPricePerUnit: product.listPrice,
      deliveryItems: undefined,
    };
    addToCartParams.push(addToCartParam);
    this.wishListService.getWishList().pipe(map(w => w.code),
      tap(code =>
        this.multiAddToCartService.addEntriesFromParams(addToCartParams, code, true))).subscribe().unsubscribe();
  }

  getProductInWishList(product: Product, entries: OrderEntry[]): OrderEntry {
    const item = entries.find((entry) => entry.product.code === product.code);
    return item;
  }
}
