import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';

import { ReturnOverviewAdapter } from '../return-overview.adapter';
import { ReturnOverviewRequest, ReturnOverviewResponse } from '../../../model/return-history.model';

@Injectable({providedIn: 'root'})
export class OccReturnOverviewAdapter implements ReturnOverviewAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  loadOverview(request: ReturnOverviewRequest): Observable<ReturnOverviewResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'returnsOverview',
      {
        urlParams: {
          userId: 'current'
        }
      }
    );

    return this.http.post<ReturnOverviewResponse>(url, request, {headers});
  }
}
