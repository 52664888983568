import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {META_REDUCERS, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AuthGuard, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {CmsPageGuard, IconModule, ListNavigationModule, PageLayoutComponent, SpinnerModule} from '@spartacus/storefront';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {OccModule} from './occ/occ.module';
import {DELIVERY_NOTES_FEATURE_KEY, reducers} from './store/reducers';
import {effects} from './store/effects';
import {DeliveryNotesOverviewComponent} from './components/delivery-notes-overview/delivery-notes-overview.component';
import {DeliveryNotesDetailsComponent} from './components/delivery-notes-details/delivery-notes-details.component';
import {DeliveryNotesFilterComponent} from './components/delivery-notes-filter/delivery-notes-filter.component';
import {deliveryNotesTranslationChunksConfig, deliveryNotesTranslations} from './translations/delivery-notes.translations';
import {metaReducerFactoryDeliveryNoteOverview} from './store/reducers/delivery-notes-overview.reducer';
import {metaReducerFactoryDeliveryNoteDetails} from './store/reducers/delivery-notes-details.reducer';
import {SharedModules} from '../shared/shared.modules';
import {UserProfilesModule} from '../user-profiles/user-profiles.module';


@NgModule({
    declarations: [
        DeliveryNotesOverviewComponent,
        DeliveryNotesDetailsComponent,
        DeliveryNotesFilterComponent
    ],
    imports: [
        CommonModule,
        OccModule,
        UrlModule,
        I18nModule,
        IconModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        ListNavigationModule,
        SpinnerModule,
        SharedModules,
        UserProfilesModule,
        ConfigModule.withConfig({
            icon: {
                symbols: {
                    FILTER: 'fas fa-filter',
                    SORTING: 'fas fa-sort',
                    DOWNLOAD: 'fas fa-file-download'
                }
            },
            i18n: {
                resources: deliveryNotesTranslations,
                chunks: deliveryNotesTranslationChunksConfig
            },
            cmsComponents: {
                DeliveryNoteOverviewComponent: {
                    component: DeliveryNotesOverviewComponent,
                },
                DeliveryNoteDetailsComponent: {
                    component: DeliveryNotesDetailsComponent,
                },
            },
            backend: {
                occ: {
                    endpoints: {
                        // tslint:disable:max-line-length
                        deliveryNoteOverview:
                            'users/${userId}/deliveryNoteOverview?fields=DEFAULT',
                        deliveryNoteDetails:
                            'users/${userId}/deliveryNoteDetails?deliveryNumber=${deliveryNumber}&returnablePositions=${returnablePositions}&download=${download}&fields=DEFAULT',
                    }
                }
            },
            routing: {
                routes: {
                    deliveryNoteDetails: {
                        paths: ['my-account/documents/deliveryNoteDetails/:deliveryNoteNumber'],
                    },
                    deliveryNoteOverview: {
                        paths: ['my-account/documents/deliveryNotes']
                    }
                }
            }
        }),
        RouterModule.forChild([
            {
                path: null,
                canActivate: [AuthGuard, CmsPageGuard],
                component: PageLayoutComponent,
                data: {cxRoute: 'deliveryNoteDetails'},
            },
        ]),
        StoreModule.forFeature(DELIVERY_NOTES_FEATURE_KEY, reducers),
        EffectsModule.forFeature(effects),

    ],
    exports: [
        DeliveryNotesOverviewComponent
    ],
    providers: [
        {
            provide: META_REDUCERS,
            deps: [],
            useFactory: metaReducerFactoryDeliveryNoteOverview,
            multi: true,
        },
        {
            provide: META_REDUCERS,
            deps: [],
            useFactory: metaReducerFactoryDeliveryNoteDetails,
            multi: true,
        },
    ]
})
export class DeliveryNotesModule {
}
