import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigDebugComponent } from './config-debug.component';
import { ConfigModule } from '@spartacus/core';
import { DisplayConfig, Level } from './display.config';
import { defaultDisplayConfig } from './default-display.config';

@NgModule({
  declarations: [ConfigDebugComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig(defaultDisplayConfig),
  ],
  exports: [ConfigDebugComponent],
})
export class ConfigDebugModule {}
