import {ChangeDetectionStrategy, Component, OnDestroy, } from '@angular/core';
import {ItemService, UserFormComponent} from '@spartacus/organization/administration/components';
import {map, tap} from 'rxjs/operators';
import {B2BUserRole, Title} from '@spartacus/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {EfaUserItemService} from '../../service/efa-user-item.service';
import { Subscription } from 'rxjs';

const NAME_ALLOWED_PATTERN = '[A-Za-z0-9ßäüöÄÖÜ|\\s|+|\\-|#|&]*';

@Component({
  selector: 'app-efa-org-user-form',
  templateUrl: './efa-user-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ItemService,
      useExisting: EfaUserItemService,
    }
  ],

})
export class EfaUserFormComponent extends UserFormComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  ngOnInit(): void {
    super.ngOnInit();
    // order the titles
    this.subscription.add(
      this.titles$.subscribe(titles=>{
       this.sortTitles(titles);
      })
    );
    this.units$ = this.units$.pipe(
      map((units) => units!?.length > 0 ? units!.slice(0, 1) : units),
      tap((units) => {
        this.form?.get('orgUnit.uid')?.setValue(units![0]?.id);
      }));

    this.titles$.subscribe();
    this.roles.push(new UntypedFormControl(B2BUserRole.CUSTOMER));
    this.form!.addControl('uid', new UntypedFormControl());
    this.form!.addControl('portalUser', new UntypedFormControl());
    this.form!.get('firstName')!.addValidators([Validators.pattern(NAME_ALLOWED_PATTERN)]);
    this.form!.get('lastName')!.addValidators([Validators.pattern(NAME_ALLOWED_PATTERN)]);
  }
  sortTitles(titles: Title[]): void {
    titles.sort((title1,title2) => {
      return +title1.code - +title2.code
    });
    [titles[0],titles[1]]=[titles[1],titles[0]];
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
