import { Component, OnInit } from '@angular/core';
import { ActiveLinkCellComponent } from '@spartacus/organization/administration/components';

@Component({
  selector: 'app-efa-active-link-cell',
  templateUrl: './efa-active-link-cell.component.html'
})
export class EfaActiveLinkCellComponent extends ActiveLinkCellComponent implements OnInit {

  ngOnInit(): void {
    console.log(this.property)
  }

}
