import { Component, Input } from '@angular/core';
import { RedirectService } from '@shared/services/redirect.service';
import { Subscription } from 'rxjs';
import { filter, first, switchMap, withLatestFrom } from 'rxjs/operators';
import { TysysCatalogTileItem } from '../../model/content-tiles.model';
import { TokenGenerationService } from '../../services/token-generation.service';

@Component({
  selector: 'app-efa-tysys-catalog',
  templateUrl: './tysys-catalog.component.html',
})
export class TysysCatalogComponent {
  @Input() tileItem: TysysCatalogTileItem;

  private subscription: Subscription = new Subscription();
  private tokenGenerationSubscription: Subscription = new Subscription();

  constructor(
    private redirectService: RedirectService,
    private tokenGenerationService: TokenGenerationService
  ) {}

  connect(): void {
    this.tokenGenerationSubscription.unsubscribe();

    this.tokenGenerationService.reset();
    this.tokenGenerationService.createToken('tysys', false);

    this.tokenGenerationSubscription = this.tokenGenerationService
      .success()
      .pipe(
        filter((v) => v),
        withLatestFrom(this.tokenGenerationService.tokenName()),
        filter(([, tokenName]: [boolean, string]) => tokenName === 'tysys'),
        switchMap(() => this.tokenGenerationService.getTokenValue()),
        filter((token) => token !== undefined),
        first()
      )
      .subscribe((tokenValue: string) => {
        let url =
          this.tileItem.url +
          '?sessionId=' +
          encodeURIComponent(tokenValue);

        if (this.tileItem.externalUrl != null) {
          url = url + '&hookUrl=' + encodeURIComponent(this.tileItem.externalUrl);
        }
        
        this.redirectService.post(
          [],
          url,
          String(this.tileItem.openUrlInNewTab) === 'true' ? '_blank' : '_self'
        );
      });
    this.subscription.add(this.tokenGenerationSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
