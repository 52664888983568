<ng-container *ngIf="{
    sending: sending$ | async,
    error: error$ | async
  } as type">
  <ng-container *ngIf="!type.sending; else loading">
    <ng-container *ngIf="type.error==undefined; else showError">
      <div class="registration-confirmation__text" *ngIf="component.data$ | async as data"
           [innerHTML]="data.content"></div>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <ng-container *ngIf="type.sending">
      <div class="cx-spinner">
        <cx-spinner></cx-spinner>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #showError>
    <ng-container *ngIf="type.error.status==410; else showCommonError">
      <ng-container *ngIf="type.error.error.errors[0].type=='RegistrationAlreadyConfirmed'">
        <div class="registration-confirmation__text">
          <strong>{{ 'efaHttpHandlers.registrationAlreadyConfirmed' | cxTranslate }}</strong></div>
        <br/>
        <div class="registration-confirmation__text" *ngIf="component.data$ | async as data"
             [innerHTML]="data.content"></div>
      </ng-container>
      <ng-container *ngIf="type.error.error.errors[0].type=='TokenWasRefreshed'">
        <div class="registration-confirmation__text">
          {{ 'efaRegistrationConfirmation.error' | cxTranslate }}</div>
        <br/>
        <div class="registration-confirmation__text">
          <strong>{{ 'efaHttpHandlers.tokenWasRefreshed' | cxTranslate }}</strong></div>
      </ng-container>
      <ng-container *ngIf="type.error.error.errors[0].type=='TokenNotFound'">
        <div class="registration-confirmation__text">
          {{ 'efaRegistrationConfirmation.error' | cxTranslate }}</div>
        <br/>
        <div class="registration-confirmation__text">
          <strong>{{ 'efaHttpHandlers.tokenNotFound' | cxTranslate }}</strong></div>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #showCommonError>
    <strong>
      <div class="registration-confirmation__text">
        {{ 'efaRegistrationForm.globalMessage.registrationConfirmationFailure' | cxTranslate }}</div>
    </strong>
  </ng-template>
</ng-container>
