import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as ReturnCreationReducers from './return-creation.reducer';
import * as ReturnReasonReducers from './return-reason.reducer';
import * as ReturnPositionReducers from './return-position.reducer';

export const RETURN_CREATION_INDEX_FEATURE_KEY = 'returnCreation';

export interface ReturnCreationState {
  [ReturnCreationReducers.RETURN_CREATION_FEATURE_KEY]: ReturnCreationReducers.ReturnCreationState;
  [ReturnReasonReducers.RETURN_REASON_FEATURE_KEY]: ReturnReasonReducers.ReturnReasonState;
  [ReturnPositionReducers.RETURN_POSITION_FEATURE_KEY]: ReturnPositionReducers.ReturnPositionState;
}

export const reducers: ActionReducerMap<ReturnCreationState> = {
  [ReturnCreationReducers.RETURN_CREATION_FEATURE_KEY]: ReturnCreationReducers.returnCreationReducer,
  [ReturnReasonReducers.RETURN_REASON_FEATURE_KEY]: ReturnReasonReducers.returnReasonReducer,
  [ReturnPositionReducers.RETURN_POSITION_FEATURE_KEY]: ReturnPositionReducers.returnPositionReducer,
};

export const getReturnCreationState = createFeatureSelector<ReturnCreationState>(RETURN_CREATION_INDEX_FEATURE_KEY);
