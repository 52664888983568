export const contentTiles = {
  contentTiles: {
    showMore: 'show more',
    showLess: 'show less',
  }
};

export const tokenGeneration = {
  tokenGeneration: {
      globalMessage: {
        generateTokenFailure: 'Generate token failed.',
        generateTokenSuccess: 'Generate token succeeded.'
      },
    },
  };