import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MiniCartComponent, MiniCartComponentService } from '@spartacus/cart/base/components/mini-cart';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { AuthService, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionCheckService } from '../../../user-profiles/service/permission-check.service';

const CART_ROUTE = 'cart';

@Component({
  selector: 'app-efa-mini-cart',
  templateUrl: './efa-mini-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class EfaMiniCartComponent extends MiniCartComponent {

  authService: AuthService;

  constructor(
      protected miniCartComponentService: MiniCartComponentService,
      protected permissionCheckService: PermissionCheckService,
      protected activeCartService: ActiveCartFacade,
      authService: AuthService,
      private routingService: RoutingService) {
    super(miniCartComponentService);
    this.authService = authService;
  }

  displayCustomerPrices$: Observable<boolean> = this.permissionCheckService.displayCustomerPrices();
  subTotal$: Observable<string> = this.activeCartService.getActive().pipe(
    map((cart) => cart && cart.subTotal ? cart.subTotal.formattedValue : '')
  );

  count$: Observable<number> = this.activeCartService.getActive().pipe(
    map(cart => (
      !!cart && cart.entries !== undefined)
      ? (Array.from(
        cart.entries?.filter((e) => !e.subEntry),
        () => 1)
      .reduce((acc, curr) => acc + curr, 0))
      : 0));


  onClick(): void
  {
    this.activeCartService.reloadActiveCart();
    this.routingService.go({ cxRoute: CART_ROUTE });
  }
}
