<form (ngSubmit)="submitForm()"
      class="cx-place-order-form"
      [formGroup]="checkoutSubmitForm">
  <ng-container *ngFor="let orderInfoFieldItem of orderInfoFields; let index = index">
    <ng-container *ngIf="orderInfoFieldItem.orderInfoField && !readonly else paragraph">
      <label class="form-group form justify-content-between w-100" [ngClass]="orderInfoFieldItem.orderInfoField.mandatory?'label-mandatory':''">
        <span class="label-content">
          {{ orderInfoFieldItem.orderInfoField.shopText }}:
          <sup *ngIf="orderInfoFieldItem.orderInfoField.mandatory">*</sup>
        </span>
        <ng-container *ngIf="orderInfoFieldItem.orderInfoField.fieldId as fieldId">
          <input class="form-control" type="text"
                 [maxLength]="orderInfoFieldItem.orderInfoField.numeric ? '' : orderInfoFieldItem.orderInfoField.length" 
                 [formControlName]="fieldId"
                 [placeholder]="orderInfoFieldItem.orderInfoField.placeholderText?.length > 0 ? orderInfoFieldItem.orderInfoField.placeholderText : orderInfoFieldItem.orderInfoField.shopText"
                 [disableTooltip]="orderInfoFieldItem.orderInfoField.toolTipText?.length === 0"
                 ngbTooltip="{{orderInfoFieldItem.orderInfoField.toolTipText}}" placement="top"/>
          <ng-container *ngIf="orderInfoFieldItem.orderInfoField.mandatory">
            <cx-form-errors [control]="checkoutSubmitForm.get(fieldId)"></cx-form-errors>
          </ng-container>
        </ng-container>
      </label>
    </ng-container>
    <ng-template #paragraph>
      <p *ngIf="orderInfoFieldItem.value">
        <span class="label-content">
          {{ orderInfoFieldItem.orderInfoField.shopText }}:
          <sup *ngIf="orderInfoFieldItem.orderInfoField.mandatory">*</sup>
        </span>
        <ng-container *ngIf="readonly">
          <span class="ml-2">{{ orderInfoFieldItem.value }}</span>
        </ng-container>
      </p>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!readonly else paragraph2">
    <label class="form-group justify-content-between w-100 label-mandatory">
      <span class="label-content">
        {{ 'checkoutOrder.checkoutSubmitForm.YourAdditionalInformation.label' | cxTranslate }}:
        <sup>*</sup>
      </span>
      <input class="form-control"
             type="text"
             [maxLength]="consignmentInfoAllowedMaxLength"
             formControlName="consignmentInfo"
             placeholder="{{ 'checkoutOrder.checkoutSubmitForm.YourAdditionalInformation.placeholder' | cxTranslate }}"/>
      <cx-form-errors [control]="checkoutSubmitForm.get('consignmentInfo')"></cx-form-errors>
    </label>
  </ng-container>
  <ng-template #paragraph2>
    <p *ngIf="readonly && (orderDetails$ | async).consignmentInfo as consignment">
       <span class="label-content">
        {{ 'checkoutOrder.checkoutSubmitForm.YourAdditionalInformation.label' | cxTranslate }}:
         <sup>*</sup>
      </span>
      <span class="ml-2">{{ consignment }}</span>
    </p>
  </ng-template>
</form>
