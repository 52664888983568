import {Component} from '@angular/core';
import {AuthService, RoutingService} from '@spartacus/core';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {OrderType} from '../../model/cart-administration.model';
import {CartParametersService} from '../../services/cart-parameters.service';
import {LoginComponent} from '@spartacus/user/account/components';
import {UserAccountFacade} from '@spartacus/user/account/root';

@Component({
  selector: 'app-efa-login',
  templateUrl: './efa-login.component.html',
})
export class EfaLoginComponent extends LoginComponent {
  currentOrderType$: Observable<OrderType> =
    this.cartParametersService.getCurrentOrderType();
  isHomePage$: Observable<boolean> = this.routingService
    .getRouterState()
    .pipe(map((state) => state.state.url.slice(-1) === '/'));

  constructor(
    private routingService: RoutingService,
    protected cartParametersService: CartParametersService,
    auth: AuthService,
    protected userFacade: UserAccountFacade
  ) {
    super(auth, userFacade);
  }
}
