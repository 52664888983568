import { CmsComponent } from '@spartacus/core';

export enum SortModeEnum {
  ASC = 1,
  DESC = 2,
}

export interface AllowedFileExtensionsResponse {
  extensions: string[];
}

export interface ProcessNavigationComponentData extends CmsComponent {
  nextRoute?: string;
  backRoute?: string;
}

export interface ProductCampaign {
  text: string;
}

export enum ICON_TYPE {
  CARET_LEFT = 'CARET_LEFT',
  CARET_RIGHT = 'CARET_RIGHT',
  EMPTY_HEART = 'EMPTY_HEART',
  EYE = 'EYE',
  EYE_CROSSED = 'EYE_CROSSED',
  EMPTY_BLUE_HEART = 'EMPTY_BLUE_HEART',
  BLUE_HEART = 'BLUE_HEART',
}

declare module '@spartacus/core' {
  interface Product {
    manufacturerId?: string;
    productCampaign?: ProductCampaign;
  }
}

export const CONSIGNMENT_INFO_ALLOWED_PATTERN =
  '[A-Za-z0-9\\s\\h\\+\\-#\\/&€,\\.()ÄäÖöÜü]*';
export const CONSIGNMENT_INFO_ALLOWED_MAX_LENGTH = 40;

export enum PDP_CALL_SOURCE {
  CART = 'CART',
  ORDER = 'ORDER',
  WISHLIST = 'WISHLIST',
  SEARCH = 'SEARCH',
  CATEGORY = 'CATEGORY',
  CAROUSEL = 'CAROUSEL',
}

export const SLASH = '/';
export const SLASH_ENCODED_URL_MARKER = '$$$$$$';