import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PasswordInputState } from './efa-password-visibility-toggle.model';
import { ICON_TYPE } from '@shared/models/shared.model';

@Component({
  selector: 'app-password-visibility-toggle',
  templateUrl: './efa-password-visibility-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaPasswordVisibilityToggleComponent {
  protected showState: PasswordInputState = {
    hidden: false,
    inputType: 'text',
    ariaLabel: 'passwordVisibility.hidePassword',
  };
  protected hideState: PasswordInputState = {
    hidden: true,
    inputType: 'password',
    ariaLabel: 'passwordVisibility.showPassword',
  };

  @Input()
  inputElement: HTMLInputElement;

  state = this.hideState;
  iconTypes = ICON_TYPE;

  /**
   * Toggle the visibility of the text of the input field.
   */
  toggle(): void {
    this.state =
      this.state === this.hideState ? this.showState : this.hideState;
    this.inputElement.setAttribute('type', this.state.inputType);
  }
}
