import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ReturnCreationActions } from '../store/actions';
import * as ReturnCreationFeature from '../store/reducers/return-creation.reducer';
import * as ReturnCreationSelectors from '../store/selectors/return-creation.selectors';
import { ReturnCreationRequest, ReturnCreationResponse } from '../model/return-creation.model';


@Injectable({
  providedIn: 'root'
})
export class ReturnCreationService {
  constructor(protected store: Store<ReturnCreationFeature.ReturnCreationState>) {}

  doReturnCreation(request: ReturnCreationRequest): void {
    this.store.dispatch(ReturnCreationActions.returnCreation({ request }));
  }

  isProcessing(): Observable<boolean> {
    return this.store.select(ReturnCreationSelectors.selectProcessing);
  }

  isSuccess(): Observable<boolean> {
    return this.store.select(ReturnCreationSelectors.selectProcessing);
  }

  getReturnCreationResponse(): Observable<ReturnCreationResponse> {
    return this.store.select(ReturnCreationSelectors.selectResponse);
  }

  reset(): void {
    this.store.dispatch(ReturnCreationActions.resetReturnCreation());
  }
}
