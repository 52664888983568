import { Component } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { RegistrationStatus } from '../../model/login-register-enum.model';
import { RegistrationData } from '../../model/login-register.model';
import { AbstractRegistrationStepComponent } from '../abstract-registration-step/abstract-registration-step.component';

@Component({
  selector: 'app-registration-bank-and-delivery',
  templateUrl: './registration-bank-and-delivery.component.html',
})
export class RegistrationBankAndDeliveryComponent extends AbstractRegistrationStepComponent {

  registrationForm: UntypedFormGroup = this.formBuilder.group({
    sepaBank: ['', [Validators.required]],
    sepaIban: ['', [Validators.required]],
    sepaBic: [''],
    sepaEmail: ['', [Validators.required, CustomFormValidators.emailValidator]],
    deliveryCosts: [false, [Validators.requiredTrue]],
    deliveryPerNoxNightExpress: [false, [Validators.requiredTrue]],
    noxDepotAvailable: [undefined, [Validators.required]],
    noxCustomerNumber: [''],
  });

  protected getStatus(): RegistrationStatus {
    return RegistrationStatus.INPROGRESS;
  }

  protected fillFormDataFromResponse(registrationData: RegistrationData): void {
    this.registrationForm.patchValue({
      sepaBank: registrationData.sepaBank,
      sepaIban: registrationData.sepaIban,
      sepaBic: registrationData.sepaBic,
      sepaEmail: registrationData.sepaEmail,
      noxDepotAvailable: registrationData.noxDepotAvailable,
      noxCustomerNumber: registrationData.noxCustomerNumber,
      deliveryCosts: registrationData.deliveryCosts,
      deliveryPerNoxNightExpress: registrationData.deliveryPerNoxNightExpress,
    });
  }

  protected onSubmit(registrationData: RegistrationData): void {
    this.registrationForm.markAllAsTouched();
    if (this.registrationForm.invalid) {
      this.scrollToFirstInvalidControl();
      return;
    }

    const registrationInformationRequest: RegistrationData = {
      ...registrationData,
      sepaBank: this.registrationForm.value.sepaBank,
      sepaIban: this.registrationForm.value.sepaIban.toUpperCase(),
      sepaBic: this.registrationForm.value.sepaBic,
      sepaEmail: this.registrationForm.value.sepaEmail,
      noxDepotAvailable: this.registrationForm.value.noxDepotAvailable,
      noxCustomerNumber: this.registrationForm.value.noxDepotAvailable ? this.registrationForm.value.noxCustomerNumber : '',
      deliveryCosts: this.registrationForm.value.deliveryCosts,
      deliveryPerNoxNightExpress: this.registrationForm.value.deliveryPerNoxNightExpress,
    };

    this.submit(registrationInformationRequest);
  }

  noxDepotAvailableChange(): void {
    const noxCustomerNumberControl: AbstractControl = this.registrationForm.get('noxCustomerNumber');

    if (this.registrationForm.value.noxDepotAvailable) {
      noxCustomerNumberControl.addValidators([Validators.required]);
    } else {
      noxCustomerNumberControl.removeValidators([Validators.required]);
    }

    noxCustomerNumberControl.updateValueAndValidity();
  }
}
