<div class="modal-body">
  <p>{{ 'efaConsentDialog.noTermsConditions_line1' | cxTranslate }}</p>
  <p>
    {{ 'efaConsentDialog.noTermsConditions_line2' | cxTranslate }}
    <a class="u-focus-none"
       [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl"
       target="_blank">
      {{ 'efaConsentDialog.termsAndConditions' | cxTranslate }}
    </a>
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="declineTermsAndConditions()">
    {{ 'efaConsentDialog.labelDecline' | cxTranslate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="acceptTermsAndConditions()">
    {{ 'efaConsentDialog.labelAccept' | cxTranslate }}
  </button>
</div>
