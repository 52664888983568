import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { RegistrationInformationMultiOptionsFieldsValuesResponse } from '../../model/login-register.model';

import { RegistrationInformationMultiOptionFieldsValuesAdapter } from '../adapters/registration-information-multi-option-fields-values.adapter';

@Injectable({ providedIn: 'root' })
export class RegistrationInformationMultiOptionFieldsValuesConnector {
  constructor(protected adapter: RegistrationInformationMultiOptionFieldsValuesAdapter){}

  loadRegistrationInformationMultiOptionFieldsValues(): Observable<RegistrationInformationMultiOptionsFieldsValuesResponse> {
    return this.adapter.loadRegistrationInformationMultiOptionFieldsValues();
  }
}
