import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { RegistrationInformationMultiOptionsFieldsValuesResponse } from '../../model/login-register.model';

export const loadRegistrationInformationMultiOptionsFieldsValues = createAction(
  '[RegistrationInformationMultiOptionsFieldsValues] Load Registration Information Multi Option Fields Values'
);

export const loadRegistrationInformationMultiOptionsFieldsValuesSuccess = createAction(
  '[RegistrationInformationMultiOptionsFieldsValues] Load Registration Information Multi Option Fields Values Success',
  props<{ response: RegistrationInformationMultiOptionsFieldsValuesResponse }>()
);

export const loadRegistrationInformationMultiOptionsFieldsValuesFailure = createAction(
  '[RegistrationInformationMultiOptionsFieldsValues] Load Registration Information Multi Option Fields Values Failure',
  props<{ errorResponse: ErrorType }>()
);