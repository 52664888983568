import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { GlobalMessageType, PaginationModel, SortModel } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ErrorType } from '@shared/models/ErrorTypes';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { OrderHistoryList } from '@spartacus/order';
import { OrderOverviewFilter, OrderOverviewRequest } from '../../model/order-history.model';
import { OrderOverviewConnector } from '../../occ/connectors/order-overview.connector';
import { OrderOverviewActions } from '../actions';
import { OrderOverviewUiState } from '../reducers/order-overview-ui.reducer';
import { OrderOverviewState } from '../reducers/order-overview.reducer';
import { selectFilterEntity, selectPagination, selectSort } from '../selectors/order-overview-ui.selectors';
import { selectResponse } from '../selectors/order-overview.selectors';

@Injectable()
export class OrderOverviewEffect {
  oldFilterEntity: OrderOverviewFilter;
  oldPagination: PaginationModel;
  oldSort: SortModel;

  loadOrderOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.loadOrderOverview),
      withLatestFrom(
        this.store.pipe(select(selectResponse)),
        this.store.pipe(select(selectFilterEntity)),
        this.store.pipe(select(selectPagination)),
        this.store.pipe(select(selectSort)),
      ),
      concatMap(([action, orderListFromStore, filterEntity, pagination, sort]) => {
          this.setOldFilterData(filterEntity, pagination, sort);
          if (orderListFromStore !== undefined && !this.hasRequestChanged(action.request)) {
            return of(OrderOverviewActions.loadOrderOverviewSuccess({response: orderListFromStore}));
          } else {
            return this.connector.loadOverview(action.request).pipe(
              map((orderHistoryList: OrderHistoryList) =>
                OrderOverviewActions.loadOrderOverviewSuccess({response: orderHistoryList})
              ),
              catchError((error: ErrorType) => {
                this.globalMessageService.add(
                  {
                    key: 'orderHistoryCustom.globalMessage.orderHistoryLoadFailure',
                  },
                  GlobalMessageType.MSG_TYPE_ERROR
                );
                return of(OrderOverviewActions.loadOrderOverviewFailure({errorResponse: error}));
              })
            );
          }
        }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: OrderOverviewConnector,
    private store: Store<OrderOverviewState | OrderOverviewUiState>,
    private globalMessageService: EfaGlobalMessageService,
  ) {
  }

  private setOldFilterData(filter: OrderOverviewFilter, pagination: PaginationModel, sort: SortModel): void {
    this.oldFilterEntity = filter;
    this.oldPagination = pagination;
    this.oldSort = sort;
  }

  private hasRequestChanged(request: OrderOverviewRequest): boolean {
    const oldOrderOverviewRequest: OrderOverviewRequest = {
      orderNumber: this.oldFilterEntity.orderNumber,
      statusCode: this.oldFilterEntity.orderStateId,
      receiverId: this.oldFilterEntity.receiverAddressId,
      dateTo: this.formatFilterDate(this.oldFilterEntity.dateTo),
      dateFrom: this.formatFilterDate(this.oldFilterEntity.dateFrom),
      consignmentInfo: this.oldFilterEntity.consignmentInfo,
      sort: this.oldSort,
      pagination: this.oldPagination,
    };
    return JSON.stringify(request) !== JSON.stringify(oldOrderOverviewRequest);
  }

  private formatFilterDate(date: NgbDateStruct ): string {
    return date ? date.year + '-' + date.month + '-' + date.day : '';
  }
}
