import {TranslationChunksConfig} from '@spartacus/core';

import {de} from './de/index-de';
import {en} from './en/index-en';

export const personalDetailsTranslations = {
  de,
  en
};

// expose all translation chunk mapping for productDetails feature
export const personalDetailsTranslationChunksConfig: TranslationChunksConfig = {
  efaPersonalDetails: [
    'efaPersonalDetails',
  ],
};
