import { CartProductSearchEffect } from './cart-product-search.effect';
import { SetCartEntryInfosEffect } from './set-cart-entry-infos.effect';
import { MultiAddToCartEffect } from './multi-add-to-cart.effect';
import { UpdateCartEffect } from './update-cart.effect';
import { ConfiguratorCartEffect } from './configurator-cart.effect';
import { SetCartCalculationModeEffect } from './set-cart-calculation-mode.effect';

export const effects: any[] = [
  CartProductSearchEffect,
  SetCartEntryInfosEffect,
  SetCartCalculationModeEffect,
  MultiAddToCartEffect,
  UpdateCartEffect,
  ConfiguratorCartEffect,
];
