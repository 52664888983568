import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OccEndpointsService } from "@spartacus/core";
import { Observable } from "rxjs";
import { PartsLinkAccessRequest } from "../../../model/parts-links-access.model";
import { PartsLinkAccessAdapter } from "../parts-link-access.adapter";

@Injectable({
  providedIn: 'root'
})
export class OccPartsLinkAccessAdapter implements PartsLinkAccessAdapter {

  constructor (
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  startPartsLinkAccess(request: PartsLinkAccessRequest): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const url = this.occEndpoints.buildUrl('startPartsLinkAccess', {
      urlParams: { userId: 'current' }
    });
    return this.http.post<void>(url, request, { headers });
  }
}
