import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import {
  ShippingBacklogOverviewRequest,
  ShippingBacklogOverviewResponse,
} from '../../model/shipping-backlog.model';

export const loadShippingBacklogOverview = createAction(
  '[ShippingBacklog] Load Shipping Backlog Overview',
  props<{ request: ShippingBacklogOverviewRequest }>()
);

export const loadShippingBacklogOverviewSuccess = createAction(
  '[ShippingBacklog] Load Shipping Backlog Overview Success',
  props<{ response: ShippingBacklogOverviewResponse }>()
);

export const loadShippingBacklogOverviewFailure = createAction(
  '[ShippingBacklog] Load Shipping Backlog Overview Failure',
  props<{ errorResponse: ErrorType }>()
);
