import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

import { EntitiesModel, PaginationModel } from '@spartacus/core';
import { Table, TableStructure } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrganizationTableType } from '@spartacus/organization/administration/components';
import { ICON_TYPE } from '@spartacus/storefront';
import {ListComponent} from '@spartacus/organization/administration/components';
@Component({
  selector: 'app-efa-manage-user-groups-list',
  templateUrl: './efa-manage-user-groups-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaManageUserGroupsListComponent extends ListComponent {
  @HostBinding('class.ghost') override hasGhostData = false;
  @HostBinding('class')
  override viewType: OrganizationTableType = this.service.viewType;

  override domainType = this.service.domainType;

  override sortCode: string | undefined;

  override iconTypes = ICON_TYPE;

  /**
   * The current key represents the current selected item from the dataset.
   * This key is used to load the item details as well as highlight the item in
   * a list of items.
   */
  override readonly currentKey$ = this.organizationItemService.key$;

  override readonly structure$: Observable<TableStructure> = this.service.getStructure();

  override readonly listData$: Observable<
    EntitiesModel<any>
    > = this.service.getData().pipe(
    tap((data) => {
      this.sortCode = data.pagination?.sort;
      this.hasGhostData = this.service.hasGhostData(data);
    })
  );

  @Input() override key = this.service.key();

  /**
   * Returns the total number of items.
   */
  override getListCount(dataTable: Table): number {
    return dataTable.pagination?.totalResults!;
  }

  /**
   * Browses to the given page number
   */
  override browse(pagination: any, pageNumber: number): void {
    this.service.view(pagination, pageNumber);
  }

  /**
   * Navigates to the detailed view of the selected list item.
   */
  override launchItem(event: any): void {
    this.organizationItemService.launchDetails(event);
  }

  /**
   * Sorts the list.
   */
  override sort(pagination: any): void {
    this.service.sort({
      ...pagination,
      ...({ sort: this.sortCode } as PaginationModel),
    });
  }

}
