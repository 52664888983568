import { Component, Input, OnInit } from '@angular/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { CheckoutOrderSummaryComponent } from '@spartacus/checkout/base/components';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PlaceOrderComponentData } from '../../model/checkout-order.model';
import { CheckoutOrderUiService } from '../../service/checkout-order-ui.service';

@Component({
  selector: 'app-efa-checkout-order-summary',
  templateUrl: './efa-checkout-order-summary.component.html',
})
export class EfaCheckoutOrderSummaryComponent extends CheckoutOrderSummaryComponent implements OnInit {
  @Input() cart: Cart;
  cartRoute: string = 'cart';
  deactivatePlaceOrder: boolean;
  isValidForm$: Observable<boolean>;
  private subscription: Subscription = new Subscription();

  constructor(
    public component: CmsComponentData<PlaceOrderComponentData>, 
    protected activeCartService: ActiveCartFacade,
    private checkoutOrderUiService: CheckoutOrderUiService,
  ) {
    super(activeCartService);
  }

  ngOnInit(): void {
    this.isValidForm$ = this.checkoutOrderUiService.selectedIsValidForm();
    this.setDeactivatePlaceOrder();
  }

  placeOrder(): void {
    this.checkoutOrderUiService.triggerPlaceOrder();
  }

  private setDeactivatePlaceOrder(): void {
    this.subscription.add(this.component.data$.pipe(tap((data: PlaceOrderComponentData) => {
      this.deactivatePlaceOrder = data.deactivatePlaceOrder === true || String(data.deactivatePlaceOrder) === 'true';
    })).subscribe());
  }
}
