import { Component, Input, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Address } from '@spartacus/core';
import { OrderType } from '../../model/cart-administration.model';
import { DeliveryMode } from '@spartacus/cart/base/root';

@Component({
  selector: 'app-cart-header-summary',
  templateUrl: './cart-header-summary.component.html',
})
export class CartHeaderSummaryComponent implements OnInit {

  @Input() selectedOrderType: OrderType;
  @Input() selectedShippingAddress: Address;
  @Input() selectedShippingType: DeliveryMode;
  @Input() deliveryDate: NgbDateStruct;
  @Input() displayRequestedDeliveryDate: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  get nativeDeliveryDate(): Date {
    return this.deliveryDate ? new Date(this.deliveryDate.year, this.deliveryDate.month - 1, this.deliveryDate.day) : undefined
  }
}
