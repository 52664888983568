import {Component, Inject, OnInit} from '@angular/core';
import {
  ManufacturersBaseComponent
} from '@shared/components/manufacturers-base/manufacturers-base.component';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {Config, LanguageService} from '@spartacus/core';
import {
  B2BUserAccountService
} from '../../../cart-administration/services/b2-b-user-account.service';
import {
  SetCartEntryInfosService
} from '../../../cart-administration/services/set-cart-entry-infos.service';
import {Manufacturer} from '../../model/default-manufacturer.model';
import {DefaultManufacturersService} from '../../services/default-manufacturers.service';


@Component({
  selector: 'app-default-manufacturers',
  templateUrl: './default-manufacturers.component.html',
})
export class DefaultManufacturersComponent
  extends ManufacturersBaseComponent
  implements OnInit
{

  constructor(
    protected b2bUserAccountService: B2BUserAccountService,
    protected setCartEntryInfosService: SetCartEntryInfosService,
    protected languageService: LanguageService,
    @Inject(Config) protected config: any,
    defaultManufacturersService: DefaultManufacturersService,
    globalMessageService: EfaGlobalMessageService,
  ) {
    super(b2bUserAccountService, setCartEntryInfosService, languageService, defaultManufacturersService, globalMessageService,config);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.b2bUserAccountService.getB2bOrgUnitLoaded().subscribe(() => {
        super.ngOnInit();
      }));
  }

  setFinalVisibleManufacturers(): void {
    this.visibleManufacturers.forEach((vm: Manufacturer) => vm.isChecked = false);
    this.finalVisibleManufacturers = this.visibleManufacturers;
    this.finalVisibleManufacturers.filter((vm: Manufacturer) => {
      return this.defaultManufacturers.find((dm: Manufacturer) => {
        return dm.id === vm.id ? vm.isChecked = true : vm.isChecked = false;
      });
    });
  }
}
