import { Category } from '../model/category.model';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';

export const CATEGORY_FEATURE = 'Category';

export interface StateWithCategory {
  CATEGORY_FEATURE: CategoryState;
}

export interface CategoryState extends EntityState<Category> {
  isLoading: boolean;
  error: Error;
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  selectId: (category) => category.code,
});

export const selectCategoryState: MemoizedSelector<StateWithCategory, CategoryState> = createFeatureSelector<
  CategoryState
>(CATEGORY_FEATURE);

const {selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const selectIsLoading: MemoizedSelector<StateWithCategory, boolean> = createSelector(
  selectCategoryState,
  (state: CategoryState) => state.isLoading
);

export const selectCategories: MemoizedSelector<StateWithCategory, Dictionary<Category>> = createSelector(
  selectCategoryState,
  selectEntities
);

export const selectCategory: MemoizedSelectorWithProps<StateWithCategory, Category, { code: any }> = createSelector(
  selectCategories,
  (state, props) => state[props.code]
);
