import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ItemService, UserDetailsComponent, UserItemService} from '@spartacus/organization/administration/components';
import { ICON_TYPE } from '../../model/user-profiles-icon.model';


@Component({
  selector: 'app-efa-org-user-details',
  templateUrl: './efa-user-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ItemService,
      useExisting: UserItemService,
    },
  ],
  host: { class: 'content-wrapper' },
})
export class EfaUserDetailsComponent extends UserDetailsComponent{
  iconTypes = ICON_TYPE;
}
