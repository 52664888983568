import { createAction, props } from "@ngrx/store";
import { ErrorType } from "../../model/ErrorTypes";
import { WarrantyProcessRequest } from "../../model/warranty-process.model";

export const startWarrantyProcess = createAction(
  '[WarrantyProcess] Start Warranty Process',
  props<{ request: WarrantyProcessRequest }>()
);

export const startWarrantyProcessSuccess = createAction(
  '[WarrantyProcess] Start Warranty Process Success'
);

export const startWarrantyProcessFailure = createAction(
  '[WarrantyProcess] Start Warranty Process Failure',
  props<{ errorResponse: ErrorType }>()
);

