<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
      <div class="cx-total row cart-name">
        {{ 'cartDetails.cartName' | cxTranslate: {code: cart.code} }}
      </div>
      <div class="cx-total row">
        <div class="col-12 col-lg-8 pl-0 mb-4">
          <app-cart-actions></app-cart-actions>
        </div>
        <ng-container *ngIf="canPlaceOrders$ | async">
          <div class="col-12 col-md-4 mb-4">
            <ng-container
              *ngIf="cart.skipAutomaticCalculation && !cart.calculatedForCheckout && !cart.fromConfigurator; else calculate">
                <button *ngIf="entries.length"
                        class="btn btn-primary btn-block"
                        type="button" (click)="calculateCart()">
                  {{ 'cartAdministration.cart.calculate' | cxTranslate }}
                </button>
            </ng-container>
            <ng-template #calculate>
                <button [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
                        *ngIf="entries.length"
                        class="btn btn-primary btn-block"
                        type="button" (click)="publishFieldMarks()">
                  {{ 'cartDetails.proceedToCheckout' | cxTranslate }}
                </button>
            </ng-template>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
        <cx-promotions [promotions]="orderPromotions"></cx-promotions>
      </ng-container>

      <app-efa-cart-item-list [items]="entries"
                              [cartIsLoading]="isLoading$ | async"
                              [promotionLocation]="promotionLocation"
                              [showLoadingSpinner]="false"
                              [fromConfigurator]="cart.fromConfigurator"
                              [options]="{
                                isSaveForLater: false,
                                optionalBtn: saveForLaterBtn
                              }"></app-efa-cart-item-list>
    </div>
  </ng-container>
</ng-container>

<ng-template let-ctx #saveForLaterBtn>
  <div *ngIf="selectiveCartEnabled"
       class="col-md-3 col-lg-3 col-xl-3 cx-sfl-btn">
    <button class="link cx-action-link"
            [disabled]="ctx.loading"
            (click)="saveForLater(ctx.item)"
            type="button">
      {{ 'saveForLaterItems.saveForLater' | cxTranslate }}
    </button>
  </div>
</ng-template>
