import { Component } from '@angular/core';
import { OrderSummaryComponent } from '@spartacus/cart/base/components';
import { Observable } from "rxjs";
import { PermissionCheckService } from "../../../user-profiles/service/permission-check.service";

@Component({
  selector: 'app-efa-order-summary',
  templateUrl: './efa-order-summary.component.html'
})
export class EfaOrderSummaryComponent extends OrderSummaryComponent {

  constructor(protected permissionCheckService: PermissionCheckService) {
    super();
  }

  displayCustomerPrices$: Observable<boolean> = this.permissionCheckService.displayCustomerPrices();

}
