export const shippingBacklog = {
  shippingBacklog: {
    globalMessage: {
      loadingShippingBacklogOverviewFailure: 'Loading shipping backlog overview failed.',
      noSelectedShippingBacklogItems: 'There is no cancellation items selected.',
      cancellingShippingBacklogItemsSuccess: 'Request for cancellation was sent successfully.',
      cancellingShippingBacklogItemsFailure: 'Request for cancellation could not be sent, Try again.',
    },
    shippingBacklogFilterForm: {
      oemNumber: {
        label: 'Article number',
        placeholder: 'Article number',
      },
      orderNumber: {
        label: 'Order number',
        placeholder: 'Order number',
      },
      consignmentInfo: {
        label: 'Additional information',
        placeholder: 'Additional information',
      },
      dateFrom: {
        label: 'Order date from',
        placeholder: 'Order date from',
      },
      dateTo: {
        label: 'Order date to',
        placeholder: 'Order date to',
      },
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Sort backlog indicator',
      selectAll:'Sellect All',
    },
    shippingBacklogItem: {
      orderNumber: 'Order Number',
      orderPositionNumber: 'Positionsnr.',
      oemNumber: 'Article',
      requestedQuantity: 'Order Quantity',
      alreadyDeliveredQuantity: 'Delivered Quantity',
      expectedDeliveryDate: 'Delivery',
      status: 'Status',
      quantityToCancel: 'Quantity to Cancel',
    },
    requestCancellationButton: 'Request Cancellation',
  },
};
