import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, LanguageService, RoutingService } from '@spartacus/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ICON_TYPE } from '../../model/faq-items-icon.model';
import { FaqItem } from '../../model/faq-items.model';
import { FaqItemService } from '../../services/faq-item.service';

@Component({
  selector: 'app-faq-details',
  templateUrl: './faq-details.component.html',
})
export class FaqDetailsComponent implements OnInit, OnDestroy {
  faqItem$: Observable<FaqItem>;
  readonly iconType = ICON_TYPE;
  
  private readonly faqItemsOverviewRoute = 'faqItemsOverview';
  private readonly faqItemsOverviewAnonymousRoute = 'faqAnonymous';
  private subscription: Subscription = new Subscription();
  private isUserLoggedIn: boolean;

  constructor(
    protected faqItemService: FaqItemService,
    protected routingService: RoutingService,
    protected authService: AuthService,
    protected languageSevice: LanguageService
  ) {}

  ngOnInit(): void {
    this.faqItemService.resetFaqItem();
    this.subscription.add(
      combineLatest([
        this.faqItemService.getFaqItemCode(),
        this.authService.isUserLoggedIn(),
        this.languageSevice.getActive().pipe(distinctUntilChanged())
      ]).subscribe(([faqItemCode, isUserLoggedIn]: [string, boolean, string]) => {
        this.isUserLoggedIn = isUserLoggedIn;
        this.faqItemService.loadFaqItem(faqItemCode, isUserLoggedIn);
      })
    );
    this.faqItem$ = this.faqItemService.getFaqItemResponse();
  }

  getBackwardRoute(): string {
    return this.isUserLoggedIn
      ? this.faqItemsOverviewRoute
      : this.faqItemsOverviewAnonymousRoute;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
