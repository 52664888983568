<ng-container *ngIf="componentData$ | async as data">
    <div class="container">
        <div class="row justify-content-md-around nav-control-btn">
            <div class="col-12 col-md-3" *ngIf="data?.backRoute">
                <button type="button" class="btn btn-secondary btn-block" [routerLink]="{ cxRoute: data?.backRoute } | cxUrl">
                    <div class="btn-text-wrapper">
                      <i class="fas fa-chevron-left"></i>
                      {{ 'common.back' | cxTranslate }}
                    </div>

                </button>
            </div>
            <div class="col-12 col-md-3" *ngIf="data?.nextRoute">
                <button type="button" class="btn btn-primary btn-block" (click)="onNext()">
                    <div class="btn-text-wrapper">
                      <i class="fas fa-chevron-right"></i>
                      {{ 'common.continue' | cxTranslate }}
                    </div>
                </button>
            </div>
            <div class="col-12 col-md-3" *ngIf="!data?.nextRoute">
                <button type="button" class="btn btn-primary btn-block" (click)="onFinalize()">
                    <div class="btn-text-wrapper">
                      <i class="fas fa-chevron-right"></i>
                    {{ 'processNavigation.submit' | cxTranslate }}
                    </div>

                </button>
            </div>
        </div>
    </div>
</ng-container>
