export const externalCatalogs = {
  externalCatalogs: {
    topmotiveConfirmationDialog: {
      buttonClose: 'Abbrechen',
      buttonConfirm: 'Ok',
      confirmationMessageTitle: 'Haftungsausschluss EFAKAT+',
      line1:
        'Bei der Erstellung von Daten und EDV-technischen Abläufen können falsche Informationen oder Ergebnisse trotz sorgfältigster Arbeiten nicht in jedem Falle ausgeschlossen werden. Die Erstellung und Lieferung der Daten erfolgt daher nach bestmöglichem Wissen und unter Voraussetzung der Richtigkeit der Quelldaten wie z.B. Informationen der Hersteller. Die EFA Autoteilewelt GmbH, Cannstatter-Str. 46, 70190 Stuttgart, schließt deshalb jede Haftung aufgrund falscher Angaben oder Ergebnisse aus, die auf Fehlerhaftigkeit der von Dritten im EFAKAT+ beruhen.',
      line2:
        'Die Haftung der EFA Autoteilewelt GmbH, im Falle einer vertragswidrigen Nutzung des EFAKAT+ durch den Nutzer, wird ausgeschlossen.',
      line3:
        'Preise sind freibleibend und unverbindlich. Irrtümer vorbehalten.',
    },
    topmotiveCatalog: {
      unauthorizedAccessRequestMessage: {
        title: 'Zugriff verweigert',
        text: 'Es tut uns leid, aber Sie haben derzeit keinen Zugriff auf diese Seite. Bitte wenden Sie sich an den Account-Administrator für dieses Konto, um die notwendigen Berechtigungen zu erhalten.',
      }
    }
  },
};
