<!--<ng-container *ngIf="isSearching$ | async as isSearching">-->
<div
  class="cx-modal-container modal-dialog-centered cart-import-selection-modal"
  [cxFocus]="focusConfig"
  (esc)="closeModal()"
>

  <div class="cx-modal-content">

    <div class="cx-dialog-header modal-header">
      <div>
        <h3>{{ 'importExport.cartImportSelectionDialog.header' | cxTranslate }}</h3>
        <h4 *ngIf="importErrorOccurs" class="u-color-danger h6" >{{ 'importExport.cartImportSelectionDialog.importError' | cxTranslate }}</h4>
      </div>

      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
    </div>

    <div class="cx-dialog-content modal-body">
      <ng-container *ngIf="!(isSearchingOrImporting$ | async); else searchingOrImporting">
        <div class="outer-container">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">{{ 'importExport.cartImportSelectionDialog.article' | cxTranslate }}</th>
              <th scope="col">{{ 'importExport.cartImportSelectionDialog.brand' | cxTranslate }}</th>
              <th scope="col">{{ 'importExport.cartImportSelectionDialog.quantity' | cxTranslate }}</th>
              <th scope="col">{{ 'importExport.cartImportSelectionDialog.price' | cxTranslate }}</th>
            </tr>
            </thead>
            <ng-container *ngFor="let searchResponse of searchResponses, let responseInd = index">
              <tbody>
              <tr appEfaAutoScroll [scroll]="getScroll(responseInd, searchResponse.substitutedOemNumber)">
                <td colspan="5">{{ 'importExport.cartImportSelectionDialog.lineHeader' | cxTranslate :
                  {
                    index: searchResponse.line,
                    oemNumber: searchResponse.substitutedOemNumber,
                    orderAmount: searchResponse.orderAmount,
                    consignmentInfo: searchResponse.consignmentInfo
                  } }}
                </td>
              </tr>
              <ng-container *ngFor="let result of searchResponse.response?.results">
                <ng-container *ngFor="let item of result.resultItems; let i = index">
                  <tr [ngClass]='getBackgroundColor(responseInd, searchResponse, result, item)'>
                    <th scope="row">
                      <div class="form-check" *ngIf="i==0 && !importDone">
                        <input class="form-check-input"
                              name="item.materialNumber"
                              type="checkbox"
                              [checked]="searchResponse.response?.results.length==1"
                              [value]="item.materialNumbmer"
                              (change)="changeProductSelection(searchResponse, result, responseInd, $event)"/>
                      </div>
                    </th>
                    <td>
                      <div class="text-uppercase pb-2 u-font-bold ">{{ item.materialName }}</div>
                      <div>{{ 'importExport.cartImportSelectionDialog.articleId' | cxTranslate :
                        {oemNumber: item.oemNumber} }}</div>
                    </td>
                    <td>{{ item.manufacturerName }}</td>
                    <td>
                      <div>{{ item.quantity }}</div>
                    </td>
                    <td>{{ item.customerPricePerUnit.formattedValue }}</td>
                  </tr>
                </ng-container>
              </ng-container>
              </tbody>
            </ng-container>
            <ng-container *ngFor="let searchError of searchErrors">
              <tbody>
              <tr class="u-bg-danger">
                <td colspan="5">{{ 'importExport.cartImportSelectionDialog.lineHeaderError.reason' | cxTranslate :
                  {
                    index: searchError.line,
                    oemNumber: searchError.entry?.oemNumber,
                    context: searchError.reason
                  } }}
                </td>
              </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </ng-container>
      <ng-template #searchingOrImporting>
        <cx-spinner class="cx-spinner"></cx-spinner>
      </ng-template>
    </div>

    <div class="cx-dialog-footer modal-footer row m-1">
      <div class="col-12 col-lg-7 duplicate-results-hint" *ngIf="!nonUniqueResultItemsConstraintFulfilled && !importDone">
        {{ 'importExport.cartImportSelectionDialog.duplicateResultsAvailable' | cxTranslate }}
      </div>


      <button class="btn btn-secondary btn-close" type="submit"
              (click)="closeModal()">
        {{ 'importExport.cartImportSelectionDialog.buttonClose' | cxTranslate }}
      </button>
      <button *ngIf="!importDone" class="btn btn-primary btn-import" type="submit"
              (click)="startImport()"
              [disabled]="selectedResultsMap.size===0 || !nonUniqueResultItemsConstraintFulfilled">
        {{ 'importExport.cartImportSelectionDialog.buttonImport' | cxTranslate }}
      </button>
    </div>

  </div>
</div>
