import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import {
  LanguageService,
  RoutingService,
  User
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import {
  BehaviorSubject,
  Observable,
  ObservableInput,
  Subject,
  combineLatest,
  throwError,
} from 'rxjs';
import { catchError, filter, first, map, tap } from 'rxjs/operators';
import { ExternalCatalogComponentData } from '../../model/external-catalogs.model';
import { TopmotiveCatalogService } from '../../services/topmotive-catalog.service';

const CART_ROUTE = 'cart';
const TOPMOTIVE_SEND_ORDER_EVENT_ID = 'SendOrder';
const LEADING_ZEROS_REGEX_PATTERN = '0+';
const ENGLISH_SID = '593004';
const GERMAN_SID = '593001';

@Component({
  selector: 'app-topmotive-catalog',
  templateUrl: './topmotive-catalog.component.html',
})
export class TopmotiveCatalogComponent implements OnInit {
  iframeUrl$: Observable<string>;

  public catalogCanBeOpened$: Subject<boolean> = new BehaviorSubject(true);

  private readonly leadingZerosRegex: RegExp = new RegExp(
    LEADING_ZEROS_REGEX_PATTERN
  );

  constructor(
    private component: CmsComponentData<ExternalCatalogComponentData>,
    private domSanitizer: DomSanitizer,
    private activeCartService: ActiveCartFacade,
    private userFacade: UserAccountFacade,
    private routingService: RoutingService,
    private languageService: LanguageService,
    private topmotiveCatalogService: TopmotiveCatalogService,
    private location: Location
  ) {}

  ngOnInit(): void {
    window.addEventListener('message', (event) => {
      if (event.data?.eventId === TOPMOTIVE_SEND_ORDER_EVENT_ID) {
        console.log('SendOrder', event);
        this.activeCartService.reloadActiveCart();
        this.routingService.go({ cxRoute: CART_ROUTE });
      }
    });

    this.iframeUrl$ = combineLatest([
      this.component.data$,
      this.activeCartService.getActive(),
      this.userFacade.get(),
      this.languageService.getActive(),
    ]).pipe(
      filter(
        ([, activeCart, currentUser]: [
          ExternalCatalogComponentData,
          Cart,
          User,
          String
        ]) => activeCart?.code != null && currentUser?.uid != null
      ),
      map(
        ([componentData, activeCart, currentUser, language]: [
          ExternalCatalogComponentData,
          Cart,
          User,
          string
        ]) => {
          const sid: string = language === 'en' ? ENGLISH_SID : GERMAN_SID;
          const urlParams: string =
            'sid=' +
            sid +
            '&user=' +
            this.removeLeadingZeros(currentUser.uid) +
            '&' +
            componentData.urlParameters.split(' ').join('&') +
            '&esid=' +
            currentUser.uid +
            '_' +
            activeCart.code;

          console.log('iframeUrl', componentData.url + '?' + urlParams);
          return componentData.url + '?' + urlParams;
        }
      ),
      first(),
      tap((targetUrl: string) => {
        this.topmotiveCatalogService
          .isAuthorized(targetUrl)
          .pipe(catchError(() => this.handleError()))
          .subscribe((isAuthorized: boolean) => {
            if (!isAuthorized) {
              this.catalogCanBeOpened$.next(false);
            }
          });
      })
    );
  }

  private handleError(): ObservableInput<any> {
    this.catalogCanBeOpened$.next(false);
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

  private removeLeadingZeros(uid: string): string {
    if (uid?.startsWith('0')) {
      return uid.replace(this.leadingZerosRegex, '');
    }

    return uid;
  }
  
  getSanitizedIframeUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  goBack(): void {
    this.location.back();
  }
}
