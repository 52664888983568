import { createAction, props } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import { OrderOverviewFilter } from '../../model/order-history.model';


export const setSelectedFilterEntity = createAction(
  '[OrderHistory] Set selected filterEntity',
  props<{ selectedFilterEntity: OrderOverviewFilter }>()
);

export const setSelectedPagination = createAction(
  '[OrderHistory] Set selected Pagination',
  props<{ selectedPagination: PaginationModel }>()
);

export const setSelectedSorting = createAction(
  '[OrderHistory] Set selected Sort',
  props<{ selectedSort: SortModel }>()
);

export const setReturningFromDetails = createAction(
  '[OrderHistory] Set Returning Fom Details',
  props<{ returningFromDetails: boolean }>()
);
