import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TokenGenerationActions } from '../store/actions';
import * as TokenGenerationFeature from '../store/reducers/token-generation.reducer';
import * as TokenGenerationSelector from '../store/selectors/token-generation.selector';

@Injectable({
  providedIn: 'root',
})
export class TokenGenerationService {
  constructor(
    protected store: Store<TokenGenerationFeature.TokenGenerationState>
  ) {}

  createToken(tokenName: string, saveToken: boolean = true): void {
    this.store.dispatch(TokenGenerationActions.generateToken({ tokenName, saveToken }));
  }

  reset(): void {
    this.store.dispatch(TokenGenerationActions.reset());
  }
  IsLoading(): Observable<boolean> {
    return this.store.select(TokenGenerationSelector.selectLoading);
  }

  success(): Observable<boolean> {
    return this.store.select(TokenGenerationSelector.selectSuccess);
  }

  tokenName(): Observable<string> {
    return this.store.select(TokenGenerationSelector.selectTokenName);
  }

  getTokenValue(): Observable<string> {
    return this.store.select(TokenGenerationSelector.selectTokenValue);
  }
}
