<a [attr.aria-label]="
    'miniCart.item' | cxTranslate: { count: count$ | async }
  " (click)="onClick()">
  <span class="cart-icon">
    <cx-icon type="CART_BLUE"></cx-icon>
    <ng-container *ngIf="authService.isUserLoggedIn() | async">
      <span *ngIf="count$ | async as count" class="count">{{ 'miniCart.count' | cxTranslate: {count: count} }}</span>
    </ng-container>
  </span>

  <ng-container *ngIf="displayCustomerPrices$ | async">
    <span class="mini-cart-second-row">
      <span class="total">{{ 'miniCart.total' | cxTranslate: {total: subTotal$ | async} }}</span>
    </span>
  </ng-container>
</a>
