import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { RegistrationData } from '../../model/login-register.model';

export const loadRegistrationStart = createAction(
  '[RegistrationStart] Load Registration Start',
  props<{ request: RegistrationData }>()
);

export const loadRegistrationStartSuccess = createAction(
  '[RegistrationStart] Load Registration Start Success',
);

export const loadRegistrationStartFailure = createAction(
  '[RegistrationStart] Load Registration Start Failure',
  props<{ errorResponse: ErrorType }>()
);
