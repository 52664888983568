import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_SEPARATOR = ', ';
const FORMATTED_SEPARATOR = ' ';

@Pipe({
  name: 'formatUsername',
})
export class FormatUsernamePipe implements PipeTransform {
  transform(input: string, separator: string = DEFAULT_SEPARATOR): string {
    if (input == null) {
      return undefined;
    }
    const separatorIndex = input.lastIndexOf(separator);
    if (separatorIndex != null && separatorIndex >= 0) {
        return input.substring(separatorIndex + separator.length) + FORMATTED_SEPARATOR + input.substring(0, separatorIndex);
    }
    return input;
  }
}
