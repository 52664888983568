<div class="c-review">
  <!-- TITLE -->
  <h2 class="c-review__title d-block">
    {{ 'checkoutOrder.checkoutReview.headline' | cxTranslate }}
  </h2>

  <div class="c-review__container row justify-content-between">
    <div class="c-review__place-order col-12 col-md-7">
      <h3 class="cx-page-title">{{ 'checkoutOrder.orderInfoFields.heading' | cxTranslate }}</h3>

      <div class="row">
        <div class="container d-flex flex-row align-items-center mb-4">
          <cx-icon class="fa fa-exclamation-circle text-danger pr-2"></cx-icon>
          <div class="c-mandatory-text">
            <span>{{'checkoutOrder.orderInfoFields.mandatoryMessage1' | cxTranslate}}&nbsp;</span>
              <span><strong>{{'checkoutOrder.orderInfoFields.mandatoryMessage2' | cxTranslate}}</strong>&nbsp;</span>
            <span>{{'checkoutOrder.orderInfoFields.mandatoryMessage3' | cxTranslate}}</span>
          </div>
        </div>
      </div>

      <!-- MANDATORY ORDER INFO FIELDS SECTION  -->
      <div class="c-review__summary-card c-review__summary-card-mandatory-hint ">
      </div>

      <app-efa-place-order [orderInfoFields]="activeCart.orderInfoFields"
      [orderType]="activeCart.orderType"
      [deliveryAddress]="activeCart.deliveryAddress"
      [deliveryMode]="activeCart.deliveryMode"
      [requestedDeliveryDate]="activeCart.requestedDeliveryDate"
      [readonly]="false"
      [deactivatePlaceOrder]="deactivatePlaceOrder"></app-efa-place-order>


    </div>

    <div class="col-12 col-md-4 px-0">
      <div class="c-review__summary-card">
        <!-- SHIPPING ADDRESS SECTION -->
        <cx-card *ngIf="deliveryAddress"
                  [content]="getShippingAddressCard(deliveryAddress, countryName$ | async) | async">
        </cx-card>
      </div>

      <!-- ORDER TYPE SECTION -->
      <div class="c-review__summary-card c-review__summary-card--row">
        <cx-card *ngIf="orderType"
                  [content]="getOrderType(orderType) | async">
        </cx-card>
      </div>

      <!-- DELIVERY MODE SECTION -->
      <div class="c-review__summary-card c-review__summary-card--row">
        <cx-card *ngIf="deliveryMode"
                  [content]="getDeliveryModeCard(deliveryMode) | async">
        </cx-card>
      </div>

      <!-- REQUESTED DELIVERY DATE SECTION -->
      <div class="c-review__summary-card c-review__summary-card--row" *ngIf="displayRequestedDeliveryDate">
        <cx-card *ngIf="requestedDeliveryDate"
                  [content]="getRequestedDeliveryDate(requestedDeliveryDate) | async">
        </cx-card>
      </div>

      <app-efa-order-summary [cart]="activeCart"></app-efa-order-summary>

      <div *ngIf="activeCart?.fromConfigurator" class="c-configurator-order-tax-notice-container">
        <p class="c-configurator-order-tax-notice">
          <sup>*</sup> {{ 'checkoutOrder.orderCost.noticeConfiguratorTaxAndDeliveryCosts' | cxTranslate }}
        </p>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column-reverse flex-md-row justify-content-between m-0">
    <div class="col-12 col-md-2 my-2 p-0">
      <button type="button"
            class="btn btn-block btn-secondary"
            [routerLink]="{ cxRoute: cartRoute } | cxUrl">
        {{ 'common.back' | cxTranslate }}
      </button>
    </div>

    <div class="col-12 col-md-4 my-2 p-0">
      <button type="submit"
              class="btn btn-block btn-primary"
              [disabled]="!(isValidForm$ | async) || deactivatePlaceOrder"
              (click) = "placeOrder()">
              {{ 'checkoutOrder.checkoutReview.placeOrder' | cxTranslate }}
      </button>
    </div>
  </div>

  <!-- CART ITEM SECTION -->
  <ng-container *ngIf="cart$ | async as cart">
    <div class="c-review__cart-item col-md-12"
         *ngIf="entries$ | async as entries">
      <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
        <cx-promotions [promotions]="orderPromotions"></cx-promotions>
      </ng-container>

      <app-efa-cart-item-list [items]="entries"
                              [readonly]="true"
                              [fromConfigurator]="cart.fromConfigurator"
                              [promotionLocation]="promotionLocation">
      </app-efa-cart-item-list>
    </div>
  </ng-container>
</div>
