import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CartProductSearchResultComponent } from './components/cart-product-search-result/cart-product-search-result.component';

export const defaultCartProductSearchResultLayoutConfig: LayoutConfig = {
   launch: {
      CART_PRODUCT_SEARCH_RESULT: {
         inlineRoot: true,
         component: CartProductSearchResultComponent,
         dialogType: DIALOG_TYPE.DIALOG,
      },
   },
};