import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LanguageService, Price, Product, UserIdService,} from '@spartacus/core';

import {LaunchDialogService} from '@spartacus/storefront';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AddToCartParam} from '../../../cart-administration/model/cart-administration.model';
import {CartParametersService} from '../../../cart-administration/services/cart-parameters.service';
import {MultiAddToCartService} from '../../../cart-administration/services/multi-add-to-cart.service';
import {PriceResponseItem} from '../../../product-prices/model/product-prices.model';
import {ProductPricesService} from '../../../product-prices/service/product-prices.service';
import {ActiveCartFacade, Cart, OrderEntry} from '@spartacus/cart/base/root';
import { ADD_TO_CART_DIALOG_CLOSE_STATUS, ICON_TYPE } from '../../model/add-to-cart-enum.model';

@Component({
  selector: 'app-add-to-cart-dialog',
  templateUrl: './add-to-cart-dialog.component.html',
})
export class AddToCartDialogComponent implements OnInit, OnDestroy {
  useDefault: boolean;
  productCode: string;
  addToCartForm: FormGroup;
  orderInfoRefId: number;
  product: Product;
  listPricePerUnit: Price;
  customerPricePerUnit: Price;
  totalPrice: Price;
  cart: Cart;
  language: string;
  productPriceItem$: Observable<PriceResponseItem>;
  loaded$: Observable<boolean> = of(false);

  readonly iconType = ICON_TYPE;
  readonly dialogCloseStatus = ADD_TO_CART_DIALOG_CLOSE_STATUS;

  private subscription: Subscription = new Subscription();
  private numberOfEntriesBeforeAdd: number;

  constructor(
    private launchDialogService: LaunchDialogService,
    private activeCartFacade: ActiveCartFacade,
    private multiAddToCartService: MultiAddToCartService,
    private languageService: LanguageService,
    private productPricesService: ProductPricesService,
    private cartParametersService: CartParametersService,
    private userIdService: UserIdService
  ) {
  }

  ngOnInit(): void {
    if (this.customerPricePerUnit == null || this.listPricePerUnit == null) {
      this.subscription.add(
        combineLatest([
          this.cartParametersService.getCurrentOrderType(),
          this.userIdService.getUserId(),
        ]).subscribe(([orderType, userID]) => {
          const products: string[] = new Array<string>();
          if (this.productCode != null) {
            products.push(this.productCode);
          } else {
            products.push(this.product.code);
          }
          this.productPricesService.retrievePrices(
            products,
            userID,
            orderType,
            true
          );
          this.productPriceItem$ = this.productPricesService.getPrice(
            products[0]
          );

          this.loaded$ = this.productPricesService
          .isLoading()
          .pipe(map((isLoading: boolean) => !isLoading));
        })
      );

      this.subscription.add(
        combineLatest([
          this.productPriceItem$,
          this.productPricesService.isLoading(),
        ])
        .pipe(
          filter(
            ([priceResponseItem, isLoading]: [PriceResponseItem, boolean]) =>
              priceResponseItem != null && !isLoading
          )
        )
        .subscribe(([priceResponseItem]: [PriceResponseItem, boolean]) => {
          this.listPricePerUnit = priceResponseItem.listPrice;
          this.customerPricePerUnit = priceResponseItem.customerPrice;
          this.totalPrice = this.customerPricePerUnit;
        })
      );
    } else {
      this.totalPrice = this.customerPricePerUnit;
      this.loaded$ = of(true);
    }

    this.subscription.add(
      this.languageService
      .getActive()
      .subscribe((language: string) => (this.language = language))
    );

    this.subscription.add(
      this.activeCartFacade.getActive().subscribe((cart: Cart) => {
        this.cart = cart;
        if (this.numberOfEntriesBeforeAdd == null) {
          this.numberOfEntriesBeforeAdd = this.cart.entries?.length;
        }
      })
    );

    this.subscription.add(
      this.addToCartForm.valueChanges.subscribe((formValue: any) => {
        const newPriceValue: number =
          formValue.quantity * this.customerPricePerUnit.value;
        this.totalPrice = {
          ...this.totalPrice,
          value: newPriceValue,
          formattedValue: parseFloat(newPriceValue.toString()).toFixed(2),
        };
      })
    );
  }

  addToCart(dialogCloseStatus: ADD_TO_CART_DIALOG_CLOSE_STATUS): void {
    const quantity = this.addToCartForm.get('quantity').value;
    if (quantity <= 0) {
      return;
    }

    if (this.useDefault) {
      if (!this.productCode) {
        return;
      }
      this.activeCartFacade.addEntry(this.productCode, quantity);
      this.loaded$ = this.activeCartFacade.isStable();
    } else {
      const addToCartParams: AddToCartParam[] = [];
      const addToCartParam: AddToCartParam = {
        productCode: this.product.code,
        quantity,
        manufacturerId: this.product.manufacturerId,
        materialName: this.product.name,
        articleNumber: this.product.manufacturerAID,
        requiredShippingTypeID: '',
        substitutedOEMNumber: '',
        orderInfoRefId: this.orderInfoRefId,
        consignmentInfo: '',
        unitId: 'PCE',
        customerPricePerUnit: this.customerPricePerUnit
          ? this.customerPricePerUnit
          : this.product.customerPrice,
        listPricePerUnit: this.listPricePerUnit
          ? this.listPricePerUnit
          : this.product.listPrice,
        deliveryItems: undefined,
      };
      addToCartParams.push(addToCartParam);
      this.multiAddToCartService.addEntriesFromParams(
        addToCartParams,
        this.cart.code,
        this.useDefault
      );
      this.loaded$ = this.multiAddToCartService.hasFinished();
    }

    this.subscription.add(
      this.activeCartFacade
        .getEntries()
        .pipe(
          filter(
            (entries: OrderEntry[]) =>
              entries?.length > this.numberOfEntriesBeforeAdd
          )
        )
        .subscribe(() => {
          this.closeModal(dialogCloseStatus);
        })
    );
  }

  closeModal(dialogCloseStatus: string): void {
    this.launchDialogService.closeDialog(dialogCloseStatus);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
