import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from '@spartacus/assets';
import { FeaturesConfig, I18nConfig, OccConfig, provideConfig, SiteContextConfig } from '@spartacus/core';
import { defaultB2bOccConfig } from '@spartacus/setup';
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from '@spartacus/storefront';
import {translationOverwrites} from '../custom/translation-overwrites';
import {environment} from '../../environments/environment';
import { defaultB2BCheckoutConfig } from '@spartacus/checkout/b2b/root';

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [provideConfig(layoutConfig), provideConfig(mediaConfig), ...defaultCmsContentProviders, provideConfig({
    backend: {
      occ: {
        ...environment.occ,
      }
    },
  } as OccConfig), provideConfig({
    context: {
      currency: ['EUR'],
      language: ['de', 'en'],
      urlParameters: ['language'],
      baseSite: ['efa-spa'],
    },
  } as SiteContextConfig), provideConfig({
    i18n: {
      resources: translations,
      chunks: translationChunksConfig,
      fallbackLang: 'en'
    },
  } as I18nConfig), provideConfig({
    i18n: {
      resources: translationOverwrites
    },
  } as I18nConfig), provideConfig({
    features: {
      level: '4.2'
    }
  } as FeaturesConfig), provideConfig(defaultB2bOccConfig), provideConfig(defaultB2BCheckoutConfig)]
})
export class SpartacusConfigurationModule {
}
