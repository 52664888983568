export const customWishList = {
  wishListItem: {
    id: 'Art.-Nr.',
    articleName: 'Artikelname',
    manufacturer: 'Marke',
    backToTop: 'zurück nach oben',
    actions: {
      remove: 'Artikel von meiner Merkliste löschen',
    }
  },
  wishListFilterForm: {
    manufacturerCodes: {
      placeholder: 'Marke'
    },
    filter: 'Filtern nach',
    activeFilter: 'Aktueller Filter',
    products: 'Artikel'
  },
};
