import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getShippingBacklogState, ShippingBacklogState } from '../reducers';
import * as ShippingBacklogCancellationFeature from '../reducers/shipping-backlog-cancellation.reducer';
import { SHIPPING_BACKLOG_CANCELLATION_FEATURE_KEY } from '../reducers/shipping-backlog-cancellation.reducer';

export const selectLoading: MemoizedSelector<ShippingBacklogCancellationFeature.ShippingBacklogCancellationState, boolean>
  = createSelector(
  getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_CANCELLATION_FEATURE_KEY].loading
);

export const selectSuccess: MemoizedSelector<ShippingBacklogCancellationFeature.ShippingBacklogCancellationState, boolean>
  = createSelector(
    getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_CANCELLATION_FEATURE_KEY].success
);