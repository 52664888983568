import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as RegistrationStartReducers from './registration-start.reducer';
import * as RegistrationLoadReducers from './registration-load.reducer';
import * as RegistrationConfirmationReducers from './registration-confirmation.reducer';
import * as RegistrationInformationMultiOptionFieldsValuesReducers from './registration-information-multi-option-fields-values.reducer';

export const LOGIN_REGISTRATION_FEATURE_KEY = 'loginRegistration';

export interface LoginRegistrationState {
  [RegistrationStartReducers.REGISTRATION_START_FEATURE_KEY]: RegistrationStartReducers.RegistrationStartState;
  [RegistrationLoadReducers.REGISTRATION_LOAD_FEATURE_KEY]: RegistrationLoadReducers.RegistrationLoadState;
  [RegistrationConfirmationReducers.REGISTRATION_CONFIRMATION_FEATURE_KEY]:
    RegistrationConfirmationReducers.RegistrationConfirmationState;
  [RegistrationInformationMultiOptionFieldsValuesReducers.REGISTRATION_INFORMATION_MULTI_OPTION_FIELDS_VALUES_FEATURE_KEY]:
  RegistrationInformationMultiOptionFieldsValuesReducers.RegistrationInformationMultiOptionFieldsValuesState;
}

export const reducers: ActionReducerMap<LoginRegistrationState> = {
  [RegistrationStartReducers.REGISTRATION_START_FEATURE_KEY]: RegistrationStartReducers.registrationStartReducer,
  [RegistrationLoadReducers.REGISTRATION_LOAD_FEATURE_KEY]: RegistrationLoadReducers.registrationLoadReducer,
  [RegistrationConfirmationReducers.REGISTRATION_CONFIRMATION_FEATURE_KEY]:
  RegistrationConfirmationReducers.registrationConfirmationReducer,
  [RegistrationInformationMultiOptionFieldsValuesReducers.REGISTRATION_INFORMATION_MULTI_OPTION_FIELDS_VALUES_FEATURE_KEY]:
  RegistrationInformationMultiOptionFieldsValuesReducers.registrationInformationMultiOptionFieldsValuesReducer,
};

export const getLoginRegistrationState = createFeatureSelector<LoginRegistrationState>(LOGIN_REGISTRATION_FEATURE_KEY);
