import { ComponentRef, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartAndCheckoutLoadingService {

  private isCartItemListLoading$: Subject<boolean> = new BehaviorSubject(false);
  private searchingOrAdding$: Subject<boolean> = new BehaviorSubject(false);
  private cartDeleting$: Subject<boolean> = new BehaviorSubject(false);
  private _cartAndCheckoutRef: void | Observable<ComponentRef<any> | undefined>;

  get cartAndCheckoutRef(): void | Observable<ComponentRef<any> | undefined> {
    return this._cartAndCheckoutRef;
  }

  set cartAndCheckoutRef(value: void | Observable<ComponentRef<any> | undefined>) {
    this._cartAndCheckoutRef = value;
  }

  getCombinedLoading(): Observable<boolean> {
    return combineLatest([
      this.searchingOrAdding$,
      this.isCartItemListLoading$,
      this.cartDeleting$
    ]).pipe(
      map(
        ([searchingOrAdding, isCartItemListLoading, cartDeleting]) =>
          searchingOrAdding || isCartItemListLoading || cartDeleting
      )
    );
  }

  setCartItemListLoading(value: boolean): void {
    this.isCartItemListLoading$.next(value);
  }

  setSearchingOrAdding(value: boolean): void {
    this.searchingOrAdding$.next(value);
  }

  setCartDeleting(value: boolean): void {
    this.cartDeleting$.next(value);
  }
}
