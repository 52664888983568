import { Config } from '@spartacus/core';

export abstract class DisplayConfig {
  displayLevel?: Level;
}

export enum Level {
  NONE = 'none',
  SCREEN = 'screen',
  CONSOLE = 'console',
}

declare module '@spartacus/core' {
  // tslint:disable-next-line:no-empty-interface
  interface Config extends DisplayConfig {}
}
