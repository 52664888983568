import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Config } from '@spartacus/core';
import { ConfigModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { EfaSiteContextSelectorComponent } from './components/efa-site-context-selector/efa-site-context-selector.component';

@NgModule({
  declarations: [EfaSiteContextSelectorComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSSiteContextComponent: {
          component: EfaSiteContextSelectorComponent,
        },
      },
    } as Config),
    IconModule,
  ],
})
export class SiteContextSelectorModule {}
