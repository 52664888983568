import { DIALOG_TYPE, LayoutConfig, SpinnerComponent, } from '@spartacus/storefront';

export const defaultCartSpinnerLayoutConfig: LayoutConfig = {
  launch: {
    CART_SPINNER: {
      inline: true,
      component: SpinnerComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
      multi: false
    },
  },
};

export const CART_SPINNER = 'CART_SPINNER';
