import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProductSearchModule} from './product-search/product-search.module';
import {ProductDetailsModule} from './product-details/product-details.module';
import {ConfigDebugModule} from './config-debug/config-debug.module';
import {ProductPricesModule} from './product-prices/product-prices.module';
import {CartAdministrationModule} from './cart-administration/cart-administration.module';
import {UserProfilesModule} from './user-profiles/user-profiles.module';
import {LoginRegisterModule} from './login-register/login-register.module';
import {CheckoutOrderModule} from './checkout-order/checkout-order.module';
import {OrderHistoryModule} from './order-history/order-history.module';
import {ContentTilesModule} from './content-tiles/content-tiles.module';
import {InvoicesCreditsModule} from './invoices-credits/invoices-credits.module';
import {DeliveryNotesModule} from './delivery-notes/delivery-notes.module';
import {ConsentManagementCustomModule} from './consent-management/consent-management-custom.module';
import {ReturnCreationModule} from './return-creation/return-creation.module';
import {ReturnHistoryModule} from './return-history/return-history.module';
import {AddressBookModule} from './address-book/address-book.module';
import {WarrantyProcessModule} from './warranty-process/warranty-process.module';
import {TransportDamageModule} from './transport-damage/transport-damage.module';
import {ManufacturersSelectionModule} from './manufacturers-selection/manufacturers-selection.module';
import {WishListModule} from './wish-list/wish-list.module';
import {OrderCancelModule} from './order-cancel/order-cancel.module';
import {UpdatePasswordModule} from './update-password/update-password.module';
import {PersonalDetailsModule} from './personal-details/personal-details.module';
import {BannerModule} from './banner/banner.module';
import {ShippingBacklogModule} from './shipping-backlog/shipping-backlog.module';
import {BreadcrumbModule} from './breadcrumb/breadcrumb.module';
import {LinkModule} from './link/link.module';
import {MiniCartModule} from './mini-cart/mini-cart.module';
import {EfaCartCheckoutSpinnerComponent} from './shared/components/efa-cart-checkout-spinner/efa-cart-checkout-spinner.component';
import {DownloadFilesModule} from './download-files/download-files.module';
import {HeaderModule} from './header/header.module';
import {SiteContextSelectorModule} from './site-context-selector/site-context-selector.module';
import {HeaderTelephoneModule} from './header-telephone/header-telephone.module';
import {FooterNavigationModule} from './footer-navigation/footer-navigation.module';
import {GreetingModule} from './greeting/greeting.module';
import {TiresWarrantyModule} from './tires-warranty/tires-warranty.module';
import {ProductFacetNavigationModule} from './product-facet-navigation/product-facet-navigation.module';
import {FaqItemsModule} from './faq-items/faq-items.module';
import {PartsLinkAccessModule} from './parts-link-access/parts-link-access.module';
import { ExternalCatalogsModule } from './external-catalogs/external-catalogs.module';
import { GoogleAnalyticsModule } from './google-analytics/google-analytics.module';
import { PromotionFlipBookModule } from './promotion-flip-book/promotion-flip-book.module';

@NgModule({
  imports: [
    CommonModule,
    ConfigDebugModule,
    ProductPricesModule,
    ProductSearchModule,
    ProductDetailsModule,
    LoginRegisterModule,
    UserProfilesModule,
    ProductDetailsModule,
    CartAdministrationModule,
    CheckoutOrderModule,
    ContentTilesModule,
    OrderHistoryModule,
    InvoicesCreditsModule,
    // ImportExportModule,
    DeliveryNotesModule,
    ConsentManagementCustomModule,
    ReturnCreationModule,
    ReturnHistoryModule,
    AddressBookModule,
    WarrantyProcessModule,
    TransportDamageModule,
    ManufacturersSelectionModule,
    WishListModule,
    OrderCancelModule,
    UpdatePasswordModule,
    PersonalDetailsModule,
    BannerModule,
    ShippingBacklogModule,
    BreadcrumbModule,
    LinkModule,
    MiniCartModule,
    DownloadFilesModule,
    HeaderModule,
    SiteContextSelectorModule,
    HeaderTelephoneModule,
    FooterNavigationModule,
    GreetingModule,
    TiresWarrantyModule,
    ProductFacetNavigationModule,
    FaqItemsModule,
    PartsLinkAccessModule,
    ExternalCatalogsModule,
    GoogleAnalyticsModule,
    PromotionFlipBookModule
  ],
  declarations: [EfaCartCheckoutSpinnerComponent],
})
export class CustomFunctionsModule {}
