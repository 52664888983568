import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { B2BUserAccountService } from '../../../cart-administration/services/b2-b-user-account.service';

@Component({
  selector: 'app-b2b-unit-summary',
  templateUrl: './b2b-unit-summary.component.html',
})
export class B2bUnitSummaryComponent implements OnInit {

  b2bUnitId: string;
  private subscription: Subscription = new Subscription();

  constructor(private b2BUserAccountService: B2BUserAccountService) { }

  ngOnInit(): void {
    this.subscription.add(this.b2BUserAccountService.getB2bOrgUnitLoaded().subscribe(() => {
      this.b2bUnitId = this.b2BUserAccountService.uid.replace(/^0+/, '');
    }));
  }

}
