import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GenericLinkComponent, GenericLinkComponentService } from '@spartacus/storefront';

@Component({
  selector: 'app-efa-generic-link',
  templateUrl: './efa-generic-link.component.html'
})
export class EfaGenericLinkComponent extends GenericLinkComponent {

  constructor(
    protected router: Router,
    protected service: GenericLinkComponentService
  ) {
    super(router, service);
  }
}
