import { Component } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { ICON_TYPE } from '../../model/external-catalogs-enum.model';

@Component({
  selector: 'app-topmotive-confirmation-dialog',
  templateUrl: './topmotive-confirmation-dialog.component.html',
})
export class TopmotiveConfirmationDialogComponent {

  iconTypes = ICON_TYPE;

  constructor(private launchDialogService: LaunchDialogService) {}

  confirm(): void {
    this.closeModal('confirmed');
  }
  
  closeModal(status: string = 'closed'): void {
    this.launchDialogService.closeDialog(status);
  }
}
