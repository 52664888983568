import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ShippingBacklogOverviewRequest,
  ShippingBacklogOverviewResponse,
} from '../../model/shipping-backlog.model';
import { ShippingBacklogOverviewAdapter } from '../adapters/shipping-backlog-overview.adapter';

@Injectable({
  providedIn: 'root',
})
export class ShippingBacklogOverviewConnector {
  constructor(protected adapter: ShippingBacklogOverviewAdapter) {}

  loadOverview(
    request: ShippingBacklogOverviewRequest
  ): Observable<ShippingBacklogOverviewResponse> {
    return this.adapter.loadOverview(request);
  }
}
