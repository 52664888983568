<div ngbAccordion #acc="ngbAccordion">
  <div ngbAccordionItem="invoiceCreditsFilterPanelOne" #invoiceCreditsFilterPanelOne="ngbAccordionItem">
    <div ngbAccordionHeader>
      <div class="justify-content-between">
        <h5 class="mb-0">
          <button ngbAccordionToggle class="btn btn-block btn-filter">
            <cx-icon [type]="iconTypes.BROWSE" class="mr-2">
              <span class="btn-filter-text">{{ 'invoicesCreditsCustom.myAccount.filter' | cxTranslate }}</span>
            </cx-icon>
          </button>
        </h5>
      </div>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <form [formGroup]="invoiceCreditOverviewFilterForm" class="row">
            <div class="col-xs-12 col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <label class="form-group">
                    <span class="label-content">
                      {{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.documentNumber.label' | cxTranslate }}
                    </span>
                    <input class="form-control"
                           type="text"
                           formControlName="documentNumber"
                           (keydown.enter)="triggerOnChanges('documentNumber', $event)"
                           placeholder="{{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.documentNumber.placeholder' | cxTranslate }}"/>
                  </label>
                </div>
    
                <div class="col-md-6">
                  <label class="form-group">
                    <span class="label-content">
                      {{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.deliveryNoteNumber.label' | cxTranslate }}
                    </span>
                    <input class="form-control"
                           type="text"
                           formControlName="deliveryNoteNumber"
                           (keydown.enter)="triggerOnChanges('deliveryNoteNumber', $event)"
                           placeholder="{{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.deliveryNoteNumber.placeholder' | cxTranslate }}"/>
                  </label>
                </div>
    
                <div class="col-md-6">
                  <label class="form-group">
                    <span class="label-content">
                      {{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.oemNumber.label' | cxTranslate }}
                    </span>
                    <input class="form-control"
                           type="text"
                           formControlName="oemNumber"
                           (keydown.enter)="triggerOnChanges('oemNumber', $event)"
                           placeholder="{{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.oemNumber.placeholder' | cxTranslate }}"/>
                  </label>
                </div>
    
                <div class="col-md-6">
                  <label class="form-group">
                    <span class="label-content">
                      {{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.orderNumber.label' | cxTranslate }}
                    </span>
                    <input class="form-control"
                           type="text"
                           formControlName="orderNumber"
                           (keydown.enter)="triggerOnChanges('orderNumber', $event)"
                           placeholder="{{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.orderNumber.placeholder' | cxTranslate }}"/>
                  </label>
                </div>
    
                <div class="col-md-6">
                  <label class="form-group">
                  <span class="label-content">
                    {{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.dateFrom.placeholder' | cxTranslate }}
                  </span>
                    <div class="input-group">
                      <input class="form-control" ngbDatepicker #df="ngbDatepicker"
                             formControlName="dateFrom"
                             [maxDate]="maxDate"
                             [readonly]="true"
                             efaNgbDatepickerNavigationTooltipsRemove
                             placeholder="{{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.dateFrom.placeholder' | cxTranslate }}"
                             container="body"/>
                      <div class="input-group-append">
                        <button class="btn btn-primary d-flex" (click)="df.toggle()" type="button">
                          <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                        </button>
                      </div>
                    </div>
                  </label>
                </div>
    
                <div class="col-md-6">
                  <label class="form-group">
                  <span class="label-content">
                    {{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.dateTo.placeholder' | cxTranslate }}
                  </span>
                    <div class="input-group">
                      <input class="form-control" ngbDatepicker #dt="ngbDatepicker"
                             formControlName="dateTo"
                             [maxDate]="maxDate"
                             [readonly]="true"
                             efaNgbDatepickerNavigationTooltipsRemove
                             placeholder="{{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.dateTo.placeholder' | cxTranslate }}"
                             container="body"/>
                      <div class="input-group-append">
                        <button class="btn btn-primary d-flex" (click)="dt.toggle()" type="button">
                          <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                        </button>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
    
            <div class="col-xs-12 col-md-4">
              <label class="form-group">
                <span class="label-content">
                  {{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.documentTypeId.label' | cxTranslate }}
                </span>
                <select class="form-control" [ngClass]="getSelectPlaceholderTextClass(invoiceCreditOverviewFilterForm.get('documentTypeId').value)"
                        formControlName="documentTypeId">
                  <option value="" selected>
                    {{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.documentTypeId.placeholder' | cxTranslate }}
                  </option>
                  <option *ngFor="let documentType of availableDocumentTypes"
                          [value]="documentType.id">
                    {{ documentType.name }}
                  </option>
                </select>
              </label>
              <label class="form-group">
                <span class="label-content">
                      {{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.consignmentInfo.label' | cxTranslate }}
                    </span>
                <input class="form-control"
                       type="text"
                       formControlName="consignmentInfo"
                       (keydown.enter)="triggerOnChanges('consignmentInfo', $event)"
                       placeholder="{{ 'invoicesCreditsCustom.invoiceCreditOverviewFilterForm.consignmentInfo.placeholder' | cxTranslate }}"/>
                <cx-form-errors [control]="invoiceCreditOverviewFilterForm.get('consignmentInfo')"></cx-form-errors>
              </label>
            </div>
          </form>
          <div class="row justify-content-end">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <button type="button" (click)="clearForm()"   style="color: #003899;" class="btn btn-block btn-secondery">{{ 'clearForm' | cxTranslate }}</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
