import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { EfaOccProductReferencesAdapter } from './efa-occ-product-references.adapter';
import { ProductReferencesAdapter } from '@spartacus/core';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: ProductReferencesAdapter,
      useClass: EfaOccProductReferencesAdapter,
    },
  ],
})
export class OccModule {}
