<form [formGroup]="warrantyProcessForm" class="row justify-content-center"
      (ngSubmit)="submitData()">
  <fieldset class="col-12  col-md-9">
    <div class="row">
      <legend class="col-12">
          <span class="warranty-process_fieldset-legend ">
              {{ 'warrantyProcess.warrantyProcessForm.legend1' | cxTranslate }}
          </span>
      </legend>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-group">
            <span class="label-content">
              {{ 'warrantyProcess.warrantyProcessForm.registrationDate.label' | cxTranslate }}
            </span>
          <div class="input-group">
            <input class="form-control" ngbDatepicker #registrationDateDatePicker="ngbDatepicker"
                   formControlName="registrationDate" [maxDate]="maxDate" [readonly]="true"
                   efaNgbDatepickerNavigationTooltipsRemove
                   formControlName="registrationDate" [maxDate]="maxDate"
                   placeholder="{{ 'warrantyProcess.warrantyProcessForm.registrationDate.placeholder' | cxTranslate }}"/>
            <div class="input-group-append">
              <button class="btn btn-primary d-flex" (click)="registrationDateDatePicker.toggle()"
                      type="button">
                <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
              </button>
            </div>
          </div>
        </label>
      </div>
      <div class="col-md-6">
        <label class="form-group required">
          <span
            class="label-content">{{ 'warrantyProcess.warrantyProcessForm.mileage.label' | cxTranslate }}</span>
          <input class="form-control" type="number" formControlName="mileage" step="1" min="0"
                 placeholder="{{ 'warrantyProcess.warrantyProcessForm.mileage.placeholder' | cxTranslate }}*"/>
          <cx-form-errors [control]="warrantyProcessForm.get('mileage')"></cx-form-errors>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 ">
        <label class="form-group required">
          <span
            class="label-content">{{ 'warrantyProcess.warrantyProcessForm.oemNumber.label' | cxTranslate }}</span>
          <input class="form-control"
                 type="text"
                 formControlName="oemNumber"
                 placeholder="{{ 'warrantyProcess.warrantyProcessForm.oemNumber.placeholder' | cxTranslate }}*"/>
          <cx-form-errors [control]="warrantyProcessForm.get('oemNumber')"></cx-form-errors>
        </label>
      </div>
      <div class="col-md-6">
        <label class="form-group required">
              <span class="label-content">
                {{ 'warrantyProcess.warrantyProcessForm.manufacturer.label' | cxTranslate }}
              </span>
          <input class="form-control"
                 type="text"
                 formControlName="manufacturer"
                 placeholder="{{ 'warrantyProcess.warrantyProcessForm.manufacturer.placeholder' | cxTranslate }}*"/>
          <cx-form-errors [control]="warrantyProcessForm.get('manufacturer')"></cx-form-errors>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-group required">
              <span class="label-content">
                {{ 'warrantyProcess.warrantyProcessForm.articleName.label' | cxTranslate }}
              </span>
          <input class="form-control"
                 type="text"
                 formControlName="articleName"
                 placeholder="{{ 'warrantyProcess.warrantyProcessForm.articleName.placeholder' | cxTranslate }}*"
                 required/>
          <cx-form-errors [control]="warrantyProcessForm.get('articleName')"></cx-form-errors>
        </label>
      </div>
      <div class="col-md-6">
        <label class="form-group required">
                <span class="label-content">
                  {{ 'warrantyProcess.warrantyProcessForm.customerComplaint.label' | cxTranslate }}
                </span>
          <input class="form-control"
                 type="text"
                 formControlName="customerComplaint"
                 placeholder="{{ 'warrantyProcess.warrantyProcessForm.customerComplaint.placeholder' | cxTranslate }}*"/>
          <cx-form-errors [control]="warrantyProcessForm.get('customerComplaint')"></cx-form-errors>
        </label>
      </div>
    </div>
  </fieldset>

  <fieldset class="col-12 col-md-9">
    <div class="row">
      <legend class="col-12">
          <span class="warranty-process_fieldset-legend ">
              {{ 'warrantyProcess.warrantyProcessForm.legend2' | cxTranslate }}
          </span>
      </legend>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-group required">
                <span class="label-content">
                  {{ 'warrantyProcess.warrantyProcessForm.installationDate.label' | cxTranslate }}
                </span>
          <div class="input-group">
            <input class="form-control" ngbDatepicker #installationDateDatePicker="ngbDatepicker"
                   formControlName="installationDate" [maxDate]="maxDate" [readonly]="true"
                   efaNgbDatepickerNavigationTooltipsRemove
                   formControlName="installationDate" [maxDate]="maxDate"
                   placeholder="{{ 'warrantyProcess.warrantyProcessForm.installationDate.placeholder' | cxTranslate }}*"/>
            <div class="input-group-append">
              <button class="btn btn-primary d-flex" (click)="installationDateDatePicker.toggle()"
                      type="button">
                <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
              </button>
            </div>
          </div>
          <cx-form-errors [control]="warrantyProcessForm.get('installationDate')"></cx-form-errors>
        </label>
      </div>
      <div class="col-md-6">
        <label class="form-group required">
                <span class="label-content">
                  {{ 'warrantyProcess.warrantyProcessForm.installationMileage.label' | cxTranslate }}
                </span>
          <input class="form-control" type="number" formControlName="installationMileage" step="1"
                 min="0"
                 placeholder="{{ 'warrantyProcess.warrantyProcessForm.installationMileage.placeholder' | cxTranslate }}*"/>
          <cx-form-errors
            [control]="warrantyProcessForm.get('installationMileage')"></cx-form-errors>
        </label>
      </div>
    </div>
  </fieldset>

  <fieldset class="col-12 col-md-9">
    <div class="row">
      <legend class="col-12">
          <span class="warranty-process_fieldset-legend ">
              {{ 'warrantyProcess.warrantyProcessForm.legend3' | cxTranslate }}
          </span>
      </legend>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label class="form-group required">
                <span class="label-content">
                  {{ 'warrantyProcess.warrantyProcessForm.descriptionOfCause.label' | cxTranslate }}
                </span>
          <textarea class="form-control"
                    rows="1"
                    formControlName="descriptionOfCause"
                    placeholder="{{ 'warrantyProcess.warrantyProcessForm.descriptionOfCause.placeholder' | cxTranslate }}*"></textarea>
          <cx-form-errors
            [control]="warrantyProcessForm.get('descriptionOfCause')"></cx-form-errors>
        </label>
      </div>
    </div>
  </fieldset>

  <fieldset class="col-12 col-md-9">
    <div class="row">
      <legend class="col-12">
          <span class="warranty-process_fieldset-legend ">
              {{ 'warrantyProcess.warrantyProcessForm.legend4' | cxTranslate }}
          </span>
      </legend>
    </div>
    <div class="row form-group required">
      <span class="label-content col-md-4">
        {{ 'warrantyProcess.warrantyProcessForm.installationInvoiceDocument.label' | cxTranslate }}
      </span>
      <div class="o-import-file col-md-8">
        <div class="o-import-file__input">
          <div class="custom-file">
            <input class="custom-file-input" type="file"
                   formControlName="installationInvoiceDocument"
                   (change)="onInstallationInvoiceDocumentChange($event, 'installationInvoiceDocument')"/>
            <label attr.data-content="{{ 'uploadFile.search' | cxTranslate }}"
                   id="installationInvoiceDocument"
                   class="custom-file-label">
              <cx-icon [type]="iconTypes.PDF"></cx-icon>
              {{ 'uploadFile.placeholder' | cxTranslate }}
            </label>
          </div>
        </div>
        <div class="o-import-file__remove">
          <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                   (click)="removeSelectedFile('installationInvoiceDocument')"></cx-icon>
        </div>
      </div>
      <cx-form-errors
        [control]="warrantyProcessForm.get('installationInvoiceDocument')"></cx-form-errors>
    </div>
    <div class="row form-group required">
      <span class="label-content col-md-4">
        {{ 'warrantyProcess.warrantyProcessForm.deliveryInvoiceDocument.label' | cxTranslate }}
      </span>
      <div class="o-import-file col-md-8">
        <div class="o-import-file__input">
          <div class="custom-file">
            <input class="custom-file-input" type="file" formControlName="deliveryInvoiceDocument"
                   (change)="onDeliveryInvoiceDocumentChange($event, 'deliveryInvoiceDocument')"/>
            <label attr.data-content="{{ 'uploadFile.search' | cxTranslate }}"
                   id="deliveryInvoiceDocument"
                   class="custom-file-label">
              <cx-icon [type]="iconTypes.PDF"></cx-icon>
              {{ 'uploadFile.placeholder' | cxTranslate }}
            </label>
          </div>
        </div>
        <div class="o-import-file__remove">
          <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                   (click)="removeSelectedFile('deliveryInvoiceDocument')"></cx-icon>
        </div>
      </div>

      <cx-form-errors
        [control]="warrantyProcessForm.get('deliveryInvoiceDocument')"></cx-form-errors>
    </div>
    <div class="row form-group">
      <span class="label-content col-md-4">
        {{ 'warrantyProcess.warrantyProcessForm.additionalDocument.label' | cxTranslate }}
      </span>
      <div class="o-import-file col-md-8">
        <div class="o-import-file__input">
          <div class="custom-file">
            <input class="custom-file-input" type="file" formControlName="additionalDocument"
                   (change)="onAdditionalDocumentChange($event, 'additionalDocument')" multiple/>
            <label attr.data-content="{{ 'uploadFile.search' | cxTranslate }}"
                   id="additionalDocument"
                   class="custom-file-label">
              <cx-icon [type]="iconTypes.PDF"></cx-icon>
              {{ 'uploadFiles.placeholder' | cxTranslate }}
            </label>
          </div>
        </div>
        <div class="o-import-file__remove">
          <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                   (click)="removeSelectedFile('additionalDocument')"></cx-icon>
        </div>
      </div>
      <cx-form-errors [control]="warrantyProcessForm.get('additionalDocument')"></cx-form-errors>
    </div>
  </fieldset>

  <div class="col-12 col-md-9">
    <div class="justify-content-center" *ngIf="isLoading$ | async;else buttons">
      <div class="cx-spinner">
        <cx-spinner></cx-spinner>
      </div>
    </div>
    <ng-template #buttons>
      <div class="row">
        <div class="col-md-8 ojustify-content-center offset-md-2">
          <button class="btn btn-block btn-primary"
                  [disabled]="!warrantyProcessForm.valid">
            {{ 'warrantyProcess.warrantyProcessForm.submitWarrantyProcessForm' | cxTranslate }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 justify-content-center offset-md-2 pt-3">
          <button type="button" class="btn btn-link btn-block btn-hover-transform"
                  (click)="clearForm()">
            {{ 'warrantyProcess.warrantyProcessForm.resetForm' | cxTranslate }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-3">
        <span class="small">
            {{ 'warrantyProcess.warrantyProcessForm.formMandatoryFields' | cxTranslate }}
        </span>
        </div>
      </div>
    </ng-template>
  </div>
</form>
