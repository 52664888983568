import { createAction, props } from "@ngrx/store";
import { ErrorType } from "../../model/errorTypes";
import { PartsLinkAccessRequest } from "../../model/parts-links-access.model";

export const startPartsLinkAccess = createAction(
  '[PartsLinkAccess] Start Parts Links Access',
  props<{request: PartsLinkAccessRequest}>()
)

export const partsLinksAccessSuccess = createAction(
  '[PartsLinkAccess] Start Parts Links Access Success',
)

export const partsLinkAccessFailer = createAction(
  '[PartsLinkAccess] Start Parts Link Access Failer',
  props<{errorResponse : ErrorType}>()
)
