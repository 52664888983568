<ng-container *ngIf="{
    steps: steps$ | async,
    activeStepIndex: activeStepIndex$ | async
  } as type">
    <div class="container">
        <div class="row justify-content-center">
            <div class="d-flex justify-content-center px-0 mx-0 col-2 c-col-2" *ngFor="let step of type.steps, let i = index">
                <div class="registration-step-index-divider" [ngClass]="getStepIndexLeftDividerClass(step.index)"></div>
                <ng-container *ngIf="step.index === type.activeStepIndex; else checkSuccessor">
                    <cx-icon [type]="numberToIconRecord[step.index].circleFilled" [ngClass]="getStepHighlightClass(step.index, type.activeStepIndex)"></cx-icon>
                </ng-container>
                <ng-template #checkSuccessor>
                    <ng-container *ngIf="step.index > type.activeStepIndex; else predecessor">
                        <cx-icon [type]="numberToIconRecord[step.index].circleOutline" [ngClass]="getStepHighlightClass(step.index, type.activeStepIndex)"></cx-icon>
                    </ng-container>
                    <ng-template #predecessor>
                        <cx-icon [type]="iconType.CHECK_CIRCLE_FILLED_SUCCESS" [ngClass]="getStepHighlightClass(step.index, type.activeStepIndex)"></cx-icon>
                    </ng-template>
                </ng-template>
                <div class="registration-step-index-divider" [ngClass]="getStepIndexRightDividerClass(step.index, type.steps.length)"></div>
            </div>
        </div>
        <div class="d-none d-md-flex row justify-content-center">
            <div class="d-flex justify-content-center text-center col-2 c-col-2" *ngFor="let step of type.steps">
                <span class="registration-step-title u-font-bold" [ngClass]="getStepHighlightClass(step.index, type.activeStepIndex)">
                    {{ step.title }}
                </span>
            </div>
        </div>
    </div>
</ng-container>
