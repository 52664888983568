import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CellComponent } from '@spartacus/organization/administration/components';

@Component({
  selector: 'app-efa-list-cell',
  templateUrl: './efa-list-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaListCellComponent extends CellComponent {
}
