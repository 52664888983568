import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AmendOrderType, OrderAmendService, OrderDetailsService } from '@spartacus/order/components';

import { OrderCancelActions } from '../store/actions';
import * as orderCancelFeature from '../store/reducers/order-cancel.reducer';
import * as orderCancelSelectors from '../store/selectors/order-cancel.selectors';
import { OrderCancelResponse } from '../model/order-cancel.model';
import { ValidateCheckboxOrderCancel } from '@shared/validators/order-cancel';
import { CancelOrReturnRequestEntryInput, CancellationRequestEntryInputList, Order } from '@spartacus/order/root';
import { OrderEntry } from '@spartacus/cart/base/root';

@Injectable({providedIn: 'root'})
export class OrderCancelService extends OrderAmendService {
  amendType = AmendOrderType.CANCEL;
  formModel: Map<number, boolean> = new Map<number, boolean>();

  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected store: Store<orderCancelFeature.OrderCancelState>
  ) {
    super(orderDetailsService);
  }

  doOrderCancellation(orderNumber: string, positions: CancellationRequestEntryInputList): void {
    return this.store.dispatch(OrderCancelActions.orderCancellation({orderNumber, positions}));
  }

  isCancelling(): Observable<boolean> {
    return this.store.select(orderCancelSelectors.selectCancelling);
  }

  isSuccess(): Observable<boolean> {
    return this.store.select(orderCancelSelectors.selectSuccess);
  }

  getOrderCancelResponse(): Observable<OrderCancelResponse> {
    return this.store.select(orderCancelSelectors.selectOrderCancelResponse);
  }

  getEntries(): Observable<OrderEntry[]> {
    return this.getOrder().pipe(
      filter((order) => !!order?.entries),
      map((order) =>
        order.entries.filter(
          (entry) => entry.entryNumber !== -1 && entry.cancellableQuantity > 0
        )
      )
    );
  }

  getAmendedEntries(): Observable<OrderEntry[]> {
    return this.getForm().pipe(
      switchMap((form) => {
        return this.getEntries().pipe(
          map((entries) =>
            entries.filter(
              (entry) => this.getFormControl(form, entry).value === true
            )
          )
        );
      })
    );
  }

  protected getFormControl(form: UntypedFormGroup, entry: OrderEntry): UntypedFormControl {
    const key = `${entry.entryNumber}_${entry.product.manufacturerAID}_${entry.quantity}`;
    return form.get('entries').get(key) as UntypedFormControl;
  }

  getForm(): Observable<UntypedFormGroup> {
    return this.getOrder().pipe(
      filter(order => order && Object.keys(order).length > 0),
      tap((order) => {
        if (!this.form || this.form.get('orderCode').value !== order.code) {
          this.buildFormData(order);
        }
      }),
      map(() => this.form)
    );
  }

  protected buildFormData(order: Order): void {
    this.form = new UntypedFormGroup({});
    this.form.addControl('orderCode', new UntypedFormControl(order.code));

    const entryGroup = new UntypedFormGroup(
      {},
      {validators: ValidateCheckboxOrderCancel}
    );
    this.form.addControl('entries', entryGroup);
    this.formModel.clear();

    (order.entries || []).forEach((entry) => {
      // create key as from entryNumber, product.code and quantity, e.g. 10_FOR0000002_2
      const key = entry.entryNumber.toString() + '_' + entry.product.manufacturerAID.toString() + '_' + entry.quantity.toString();
      entryGroup.addControl(key, new UntypedFormControl(false));
      this.formModel.set(entry.entryNumber, false);
    });
  }


  save(): void {
    const orderCode = this.form.value.orderCode;
    const entries = this.form.value.entries;
    const inputs: CancelOrReturnRequestEntryInput[] = Object.keys(entries)
      .filter((entryKey) => entries[entryKey])
      .map((entryKey) =>
        ({
          orderEntryNumber: parseInt(entryKey.split('_')[0], 10),
          productCode: entryKey.split('_')[1],
          quantity: parseInt(entryKey.split('_')[2], 10),

        } as CancelOrReturnRequestEntryInput));

    this.form.reset();
    this.doOrderCancellation(orderCode, {cancellationRequestEntryInputs: inputs});
  }

  changeFormModel(entry: OrderEntry, value: boolean): void {
    this.formModel.set(entry.entryNumber, value);
  }

  getFormModel(entry: OrderEntry): boolean {
    return this.formModel.get(entry.entryNumber);
  }

  resetForm(): void {
    this.form = undefined;
  }

  resetOrderCancellation(): void {
    return this.store.dispatch(OrderCancelActions.resetOrderCancellation());
  }
}
