export const customWishList = {
  wishListItem: {
    id: 'Item No.',
    articleName: 'Product Name',
    manufacturer: 'Brand',
    actions: {
      remove: 'Delete item from my watchlist',
    },
    backToTop: 'BACK TO TOP'
  },
  wishListFilterForm: {
    manufacturerCodes: {
      placeholder: 'brand'
    },
    filter: 'Filter by',
    activeFilter: 'Current filter',
    products: 'Articles'
  },
};
