import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfiguratorCartActions } from '../store/actions';
import * as ConfiguratorCartFeature from '../store/reducers/configurator-cart.reducer';
import * as ConfiguratorCartSelectors from '../store/selectors/configurator-cart.selector';

@Injectable({ providedIn: 'root' })
export class ConfiguratorCartService {
  constructor(
    private store: Store<ConfiguratorCartFeature.ConfiguratorCartState>
  ) {}

  loadConfiguratorCart(userId: string): void {
    this.store.dispatch(ConfiguratorCartActions.loadConfiguratorCart({ userId }));
  }

  getConfiguratorCart(): Observable<any> {
    return this.store.select(
      ConfiguratorCartSelectors.selectMultiCartResponse
    );
  }

  isLoading(): Observable<boolean> {
    return this.store.select(ConfiguratorCartSelectors.selectLoading);
  }
}
