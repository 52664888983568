import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as InvoiceCreditOverviewFeature from '../reducers/invoice-credit-overview.reducers';
import { INVOICE_CREDIT_OVERVIEW_FEATURE_KEY } from '../reducers/invoice-credit-overview.reducers';
import { getInvoiceCreditState, InvoiceCreditState } from '../reducers';
import { InvoicesCreditOverviewResponse } from '../../model/invoices-credits.model';

export const selectInvoiceOverviewLoading: MemoizedSelector<InvoiceCreditOverviewFeature.InvoiceCreditOverviewState, boolean>
  = createSelector(
  getInvoiceCreditState,
  (state: InvoiceCreditState) => state[INVOICE_CREDIT_OVERVIEW_FEATURE_KEY].loading
);

export const selectInvoiceOverviewResponse: MemoizedSelector<InvoiceCreditOverviewFeature.InvoiceCreditOverviewState,
  InvoicesCreditOverviewResponse>
  = createSelector(
  getInvoiceCreditState,
  (state: InvoiceCreditState) => state[INVOICE_CREDIT_OVERVIEW_FEATURE_KEY].response
);
