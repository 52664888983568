import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { EfaOccProductAdapter } from './adapters/impl/efa-occ-product.adapter';
import { ProductAdapter } from '@spartacus/core';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: ProductAdapter,
      useClass: EfaOccProductAdapter,
    },
  ],
})
export class OccModule {}
