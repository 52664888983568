import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { InterceptorUtil, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';

import { RegistrationConfirmationAdapter } from '../registration-confirmation.adapter';

@Injectable({ providedIn: 'root' })
export class OccRegistrationConfirmationAdapter implements RegistrationConfirmationAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  protected getHeaders(userId: string): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    return headers;
  }

  public registrationConfirmation(token: string): Observable<void> {
    const url = this.occEndpoints.buildUrl(
      'registrationConfirmation',
      {
        urlParams: {
          userId: 'current'
        },
        queryParams: {
          token
        }
      }
    );
    const toAdd = JSON.stringify({});
    const headers = this.getHeaders('current');

    return this.http.post<void>(url, toAdd, { headers});
  }
}
