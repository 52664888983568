import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { DownloadItemComponent } from './components/download-item/download-item.component';
import { DownloadItemsComponent } from './components/download-items/download-items.component';
import { DownloadLinkComponent } from './components/download-link/download-link.component';

@NgModule({
  declarations: [
    DownloadItemsComponent,
    DownloadItemComponent,
    DownloadLinkComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    MediaModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        DownloadItemsComponent: {
          component: DownloadItemsComponent,
        },
      },
    } as CmsConfig),
  ]
})
export class DownloadFilesModule { }
