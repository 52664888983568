import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index-de';
import { en } from './en/index-en';

export const returnCreationTranslations = {
  de,
  en
};

// expose all translation chunk mapping for cartAdministration feature
export const returnCreationTranslationChunksConfig: TranslationChunksConfig = {
  returnCreation: [
    'returnablePositions',
    'returnCreation',
  ],
};
