import { Directive } from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

const EMPTY_STRING = '';
const TITLE = 'title';
const ARIA_LABEL = 'aria-label';
const C_REF = '_cRef';

@Directive({
  selector: '[efaNgbDatepickerNavigationTooltipsRemove]',
})
export class EfaNgbDatepickerNavigationRemoveTooltipsDirective {
  constructor(private datepicker: NgbInputDatepicker) {
    const previousToggle = this.datepicker.toggle;
    this.datepicker.toggle = () => {
      previousToggle.bind(this.datepicker)();
      if (this.datepicker.isOpen()) {
        this.removeTooltips();
      }
    };
  }

  removeTooltips(): void {
    const selectMonth = this.datepicker[
      C_REF
    ].location.nativeElement.querySelector('select[title="Select month"]');
    if (selectMonth) {
      selectMonth.setAttribute(TITLE, EMPTY_STRING);
      selectMonth.setAttribute(ARIA_LABEL, EMPTY_STRING);
    }

    const selectYear = this.datepicker[
      C_REF
    ].location.nativeElement.querySelector('select[title="Select year"]');
    if (selectYear) {
      selectYear.setAttribute(TITLE, EMPTY_STRING);
      selectYear.setAttribute(ARIA_LABEL, EMPTY_STRING);
    }

    const prevMonth = this.datepicker[
      C_REF
    ].location.nativeElement.querySelector('button[title="Previous month"]');
    if (prevMonth) {
      prevMonth.setAttribute(TITLE, EMPTY_STRING);
      prevMonth.setAttribute(ARIA_LABEL, EMPTY_STRING);
    }

    const nextMonth = this.datepicker[
      C_REF
    ].location.nativeElement.querySelector('button[title="Next month"]');
    if (nextMonth) {
      nextMonth.setAttribute(TITLE, EMPTY_STRING);
      nextMonth.setAttribute(ARIA_LABEL, EMPTY_STRING);
    }
  }
}
