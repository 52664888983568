import * as RegistrationStartActions from './registration-start.actions';
import * as RegistrationLoadActions from './registration-load.actions';
import * as RegistrationConfirmationActions from './registration-confirmation.actions';
import * as RegistrationInformationMultiOptionFieldsValuesActions from './registration-information-multi-option-fields-values.actions';

export {
  RegistrationStartActions,
  RegistrationLoadActions,
  RegistrationConfirmationActions,
  RegistrationInformationMultiOptionFieldsValuesActions,
};
