import {DatePipe, DOCUMENT} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbDatepickerConfig, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Config, GlobalMessageType, TranslationService} from '@spartacus/core';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {ICON_TYPE} from '../../model/warranty-process-icon.model';
import {WarrantyProcessDocument, WarrantyProcessDocumentType, WarrantyProcessRequest} from '../../model/warranty-process.model';
import {WarrantyProcessService} from '../../services/warranty-process.service';
import {exceedMaxFileSize} from '@shared/utils/upload-file-size';
import {invalidFileExtension} from '@shared/utils/upload-file-extension';
import {validFileExtension} from '@shared/validators/file-extension';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

const DATE_FORMAT = 'yyyy-MM-dd';
const FILE_NAMES_SEPARATOR = ', ';

@Component({
  selector: 'app-warranty-process-formular',
  templateUrl: './warranty-process-formular.component.html',
})
export class WarrantyProcessFormularComponent implements OnInit, OnDestroy {
  warrantyProcessForm: UntypedFormGroup;
  iconTypes = ICON_TYPE;
  isLoading$: Subject<boolean> = new Subject();
  maxDate: NgbDateStruct;

  private readonly formProperties = {
    mileage: new UntypedFormControl(undefined, [Validators.required, Validators.max(10000000)]),
    oemNumber: new UntypedFormControl('', Validators.required),
    manufacturer: new UntypedFormControl('', Validators.required),
    articleName: new UntypedFormControl('', Validators.required),
    customerComplaint: new UntypedFormControl('', Validators.required),
    installationMileage: new UntypedFormControl(undefined, [Validators.required, Validators.max(10000000)]),
    descriptionOfCause: new UntypedFormControl('', Validators.required),
    registrationDate: new UntypedFormControl(undefined),
    installationDate: new UntypedFormControl(undefined, Validators.required),
    deliveryInvoiceDocument: new UntypedFormControl(undefined,
      [Validators.required, validFileExtension(this.config.fileExtension.warrantyProcessForm)]),
    installationInvoiceDocument: new UntypedFormControl(
      undefined,
      [Validators.required, validFileExtension(this.config.fileExtension.warrantyProcessForm)]
    ),
    additionalDocument: new UntypedFormControl(undefined),
  };
  private deliveryInvoiceDocument: WarrantyProcessDocument;
  private installationInvoiceDocument: WarrantyProcessDocument;
  private additionalDocument: WarrantyProcessDocument[] = [];
  private subscription: Subscription = new Subscription();
  private currentDate = new Date();
  private maxFileSize: number;


  constructor(
    private warrantyProcessService: WarrantyProcessService,
    private globalMessageService: EfaGlobalMessageService,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    @Inject(Config) private config: any,
    @Inject(DOCUMENT) private document: Document,
    private translationService: TranslationService
  ) {
  }

  ngOnInit(): void {
    this.warrantyProcessForm = this.formBuilder.group(this.formProperties);
    this.maxFileSize = this.config.maxFileSize.warrantyProcessForm;
    this.subscription.add(
      this.warrantyProcessService.isProcessing().subscribe((isProcessing: boolean) => {
        this.isLoading$.next(isProcessing);
      }),
    );
    this.disableFutureDates();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submitData(): void {
    Object.values(this.warrantyProcessForm.controls).forEach((control: UntypedFormControl) => {
      control.markAsTouched();
    });
    if (this.warrantyProcessForm.invalid) {
      return;
    }
    let dateStruct = this.warrantyProcessForm.get('registrationDate').value;
    const registrationDate: Date = dateStruct ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : undefined;
    dateStruct = this.warrantyProcessForm.get('installationDate').value;
    const installationDate: Date = dateStruct ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : undefined;

    const documents = [];
    documents.push(this.installationInvoiceDocument);
    documents.push(this.deliveryInvoiceDocument);
    if (this.additionalDocument.length !== 0) {
      documents.push(...this.additionalDocument);
    }
    const warrantyProcessRequest: WarrantyProcessRequest = {
      oemNumber: this.warrantyProcessForm.get('oemNumber').value,
      articleName: this.warrantyProcessForm.get('articleName').value,
      manufacturer: this.warrantyProcessForm.get('manufacturer').value,
      registrationDate: this.datePipe.transform(registrationDate, DATE_FORMAT),
      mileage: this.warrantyProcessForm.get('mileage').value,
      installationDate: this.datePipe.transform(installationDate, DATE_FORMAT),
      installationMileage: this.warrantyProcessForm.get('installationMileage').value,
      customerComplaint: this.warrantyProcessForm.get('customerComplaint').value,
      descriptionOfCause: this.warrantyProcessForm.get('descriptionOfCause').value,
      documents,
    };
    this.warrantyProcessService.startWarrantyProcess(warrantyProcessRequest);
    this.subscription.add(this.warrantyProcessService
      .success()
      .subscribe((success: boolean) => {
        if (success) {
          this.resetFormFields();
          this.showWarrantyProcessSuccessMessage();
        }
      }));
  }
  clearForm() {
    this.resetFormFields();
  }

  private resetFormFields(): void {
    this.warrantyProcessForm.reset();
    this.additionalDocument = [];
    this.deliveryInvoiceDocument = undefined;
    this.installationInvoiceDocument = undefined;
    this.subscription.add(
      combineLatest([
        this.translationService.translate(
          'uploadFile.placeholder'
        ),
        this.translationService.translate(
          'uploadFiles.placeholder'
        ),
      ]).subscribe(
        ([
           singleFile,
           multipleFile
         ]) => {
          document.getElementById('installationInvoiceDocument').innerHTML = singleFile as string;
          document.getElementById('deliveryInvoiceDocument').innerHTML = singleFile as string;
          document.getElementById('additionalDocument').innerHTML = multipleFile as string;
        }
      ),
    );
  }

  private exceedMaxFileSize(file: File, documentTypeId: string): boolean {
    if (file.size === 0) {
      this.warrantyProcessForm.controls[documentTypeId].setErrors({
        fileEmpty: true
      });
    } else if (exceedMaxFileSize(file, this.maxFileSize)) {
      this.warrantyProcessForm.controls[documentTypeId].setErrors({
        maxFileSizeExceeded: {
          maxFileSize: this.config.maxFileSize.warrantyProcessForm
        }
      });
      return true;
    }
    return false;
  }

  private invalidFileExtension(file: File, documentTypeId: string): boolean {
    if (file.size === 0) {
      this.warrantyProcessForm.controls[documentTypeId].setErrors({
        fileEmpty: true
      });
    } else if (invalidFileExtension(file, this.config.fileExtension.warrantyProcessForm)) {
      this.warrantyProcessForm.controls[documentTypeId].setErrors({
        validFileExtension: {
          fileExtensions: this.config.fileExtension.warrantyProcessForm
        }
      });
      return true;
    }
    return false;
  }

  private readDocument(file: File, documentTypeId: string, documentType: WarrantyProcessDocumentType): void {
    const fr: FileReader = new FileReader();
    this.readDocumentPriv(file, documentTypeId, documentType, fr, () => {
      const self = this;
      self[documentTypeId] = this.createDocument(fr, file, documentType);
      document.getElementById(documentTypeId).innerHTML =
        file.name;
    });
  }

  private readDocuments(file: File, documentTypeId: string, documentType: WarrantyProcessDocumentType): void {
    const fr: FileReader = new FileReader();
    this.readDocumentPriv(file, documentTypeId, documentType, fr, () => {
      const self = this;
      self[documentTypeId].push(this.createDocument(fr, file, documentType));
      document.getElementById(documentTypeId).innerHTML =
        self[documentTypeId].map(d => d.fileName).join(FILE_NAMES_SEPARATOR);
    });
  }

  private readDocumentPriv(file: File,
                           documentTypeId: string,
                           documentType: WarrantyProcessDocumentType,
                           fr: FileReader,
                           func: () => void): void {
    fr.onloadend = func;
    fr.readAsBinaryString(file);
  }

  private createDocument(fr: FileReader, file: File, documentType: WarrantyProcessDocumentType): WarrantyProcessDocument {
    return {
      encodedContent: btoa(fr.result.toString()),
      fileName: file.name,
      type: documentType,
    };
  }

  public onAdditionalDocumentChange(event: any, documentTypeId: string): void {
    const fileList: FileList = event.target.files;
    const fileArray: File[] = Array.from(fileList);
    for (const file of fileArray) {
      if (this.exceedMaxFileSize(file, documentTypeId) || this.invalidFileExtension(file, documentTypeId)) {
        return;
      }
    }
    fileArray.forEach((file: File) => {
      this.readDocuments(
        file,
        documentTypeId,
        WarrantyProcessDocumentType.ADDITIONAL
      );
    });
  }

  public onInstallationInvoiceDocumentChange(
    event: any,
    documentTypeId: string
  ): void {
    const fileList: FileList = event.target.files;
    if (this.exceedMaxFileSize(fileList[0], documentTypeId)) {
      return;
    }

    this.readDocument(fileList[0], documentTypeId, WarrantyProcessDocumentType.INSTALLATIONINVOICE);

  }

  public onDeliveryInvoiceDocumentChange(
    event: any,
    documentTypeId: string
  ): void {
    const fileList: FileList = event.target.files;
    if (this.exceedMaxFileSize(fileList[0], documentTypeId)) {
      return;
    }

    this.readDocument(fileList[0], documentTypeId, WarrantyProcessDocumentType.DELIVERYINVOICE);
  }

  public removeSelectedFile(documentTypeId: string): void {
    let uploadTranslationKey: string;
    if (documentTypeId === 'additionalDocument') {
      uploadTranslationKey = 'uploadFiles.placeholder';
      this.additionalDocument = [];
    } else {
      uploadTranslationKey = 'uploadFile.placeholder';
      this[documentTypeId] = undefined;
    }
    this.subscription.add(
      this.translationService.translate(uploadTranslationKey).subscribe((value: string) => {
        document.getElementById(documentTypeId).innerHTML = value;
        this.warrantyProcessForm.controls[documentTypeId].reset();
        this.warrantyProcessForm.controls[documentTypeId].markAsTouched();
      })
    );
  }

  private showWarrantyProcessSuccessMessage(): void {
    this.globalMessageService.add(
      {
        key: 'warrantyProcess.globalMessage.startWarrantyProcessSuccess',
      },
      GlobalMessageType.MSG_TYPE_INFO
    );
  }

  disableFutureDates(): void {
    this.maxDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear(),
    };
  }
}
