import {AbstractControl, ValidatorFn} from '@angular/forms';

const FILE_SIZE_IN_MB_NORMALIZATION_FACTOR = 1024 * 1024;

export function exceedMaxFileSize(file: File, maxFileSize: number): boolean {
  if (Math.ceil(file.size / FILE_SIZE_IN_MB_NORMALIZATION_FACTOR) > maxFileSize) {
    return true;
  }
  return false;
}
