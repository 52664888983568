import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PneuConfiguratorToken } from '../../model/content-tiles.model';
import { TokenGenerationAdapter } from '../adapters/token-generation.adapter';

@Injectable({
  providedIn: 'root',
})
export class TokenGenerationConnector {
  constructor(protected adapter: TokenGenerationAdapter) {}

  generateToken(tokenName: string, saveToken: boolean): Observable<PneuConfiguratorToken> {
    return this.adapter.generateToken(tokenName, saveToken);
  }
}
