import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

import {UserIdService} from '@spartacus/core';
import {Observable} from 'rxjs';

import {CartAndCheckoutLoadingService} from '@shared/services/cart-and-checkout-loading.service';
import {CartItemListComponent} from '@spartacus/cart/base/components';
import {SelectiveCartService} from '@spartacus/cart/base/core';
import {ActiveCartFacade, MultiCartFacade, OrderEntry} from '@spartacus/cart/base/root';
import {distinctUntilChanged, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {OrderInfoFieldValue} from '../../../cart-administration/model/cart-administration.model';
import {B2BUserAccountService} from '../../../cart-administration/services/b2-b-user-account.service';
import {SetCartEntryInfosService} from '../../../cart-administration/services/set-cart-entry-infos.service';
import {OrderInfoFieldsFormsValidationService} from '../../../checkout-order/service/order-info-fields-forms-validation.service';
import { CONSIGNMENT_INFO_ALLOWED_MAX_LENGTH } from '@shared/models/shared.model';

@Component({
  selector: 'app-efa-cart-item-list',
  templateUrl: './efa-cart-item-list.component.html',
})
export class EfaCartItemListComponent extends CartItemListComponent implements OnInit {
  @Input() isOrderDetails: boolean;
  @Input() showLoadingSpinner = true;
  @Input() fromConfigurator:boolean = false;
  readonly consignmentInfoAllowedMaxLength: number = CONSIGNMENT_INFO_ALLOWED_MAX_LENGTH;

  consignmentEditable: Map<string, boolean> = new Map();
  consignmentFocused: Map<string, boolean> = new Map();
  consignmentForm: Map<string, UntypedFormGroup> = new Map();
  cartEntryInfosUpdating$: Observable<boolean> = this.setCartEntryInfosService.getIsUpdating();
  currentCartEntryNumberUpdating$: Observable<number> = this.setCartEntryInfosService.getEntryNumber();
  currentCartEntryInfoTypeUpdating$: Observable<string> = this.setCartEntryInfosService.getCartEntryInfoType();

  private cartEntriesCount: number = 0;


  constructor(
    activeCartService: ActiveCartFacade,
    selectiveCartService: SelectiveCartService,
    userIdService: UserIdService,
    multiCartService: MultiCartFacade,
    protected cd: ChangeDetectorRef,
    private setCartEntryInfosService: SetCartEntryInfosService,
    private b2BUserAccountService: B2BUserAccountService,
    private cartAndCheckoutLoadingService: CartAndCheckoutLoadingService,
    private orderInfoFieldsFormsValidationService: OrderInfoFieldsFormsValidationService,
  ) {
    super(activeCartService, selectiveCartService, userIdService, multiCartService, cd);
  }


  ngOnInit(): void {
    super.ngOnInit();
    this.orderInfoFieldsFormsValidationService.reset();
    this.activeCartService.getEntries().subscribe(entries => {
      this.cartEntriesCount = entries?.length
    });
  }

  getValue(value: any, entryNumber: number, type: string, entryIndex: number): void {
    if (type === 'consignmentInfo') {
      this.setCartEntryInfosService.setCartEntryInfos(
        this.b2BUserAccountService.cartId,
        entryNumber,
        value,
        [],
        type,
        entryIndex
      );
    } else if (type === 'orderInfoFields') {
      this.setCartEntryInfosService.setCartEntryInfos(
        this.b2BUserAccountService.cartId,
        entryNumber,
        undefined,
        [value],
        type,
        entryIndex
      );
    }
  }

  isMandatoryFields(orderInfoFields: OrderInfoFieldValue[]): boolean {
    if (!!orderInfoFields) {
      return orderInfoFields.filter((item: OrderInfoFieldValue) => item.orderInfoField.mandatory === true).length > 0;
    }
  }

  protected resolveItems(items: OrderEntry[]): void {
    if (!items) {
      this._items = [];
      return;
    } else {
      this._items = items.filter(item => !item.subEntry);
    }

    items.forEach((item) => {
      const controlName = this.getControlName(item);

      const control = this.form.get(controlName);
      if (control) {
        control.patchValue({entryNumber: item.entryNumber, quantity: item.quantity});
      }
    });
  }

  getControl(item: OrderEntry): Observable<UntypedFormGroup> {
    const controlName = this.getControlName(item);

    const control = this.form.get(controlName);

    return this.cartAndCheckoutLoadingService.getCombinedLoading().pipe(
      filter((value) => !value && control?.valueChanges !== undefined),
      switchMap(() => control?.valueChanges),
      // eslint-disable-next-line import/no-deprecated
      startWith(null),
      distinctUntilChanged((prev, next) => prev?.quantity === next?.quantity),
      tap(value => {
        if (value && value?.quantity === 0) {
          this.removeEntry(item);
        } else if (value && value.quantity !== item.quantity) {
          if (item.updateable && !this.readonly) {
            if (this.selectiveCartService && this.options.isSaveForLater) {
              this.selectiveCartService.updateEntry(
                value.entryNumber,
                value.quantity
              );
            } else if (this.cartId && this.userId) {
              this.multiCartService?.updateEntry(
                this.userId,
                this.cartId,
                value.entryNumber,
                value.quantity
              );
            } else {
              this.activeCartService.updateEntry(
                value.entryNumber,
                value.quantity
              );
            }
          }
        }
      }),
      map(() => control as UntypedFormGroup)
    );
  }

  trackItemByFn(index: number, _value: any): number {
    return _value.uniqueIdentifier;
  }

  removeEntry(item: OrderEntry): void {
    super.removeEntry(item);

    // if the last entry was deleted, we do not display a spinner
    if (this.cartEntriesCount === 1) {
      this.cartAndCheckoutLoadingService.setCartItemListLoading(false);
    }
  }

  onConsignmentEditableChange(value: boolean, uniqueIdentifier: string): void {
    this.consignmentEditable.set(uniqueIdentifier, value);
  }

  onConsignmentFocusedChange(value: boolean, uniqueIdentifier: string): void {
    this.consignmentFocused.set(uniqueIdentifier, value);
  }

  onConsignmentFormCreated(formValue: UntypedFormGroup, entryNumber: number, entryIndex: number, uniqueIdentifier: string): void {
    this.consignmentForm.set(uniqueIdentifier, formValue);
    formValue.valueChanges.subscribe((value: any) => {
      if (this.consignmentForm.get(uniqueIdentifier).valid) {
        this.getValue(value.consignmentInfo, entryNumber, 'consignmentInfo', entryIndex)
      }
    });
  }
}
