import { OrderInfoFieldValue } from '../../model/cart-administration.model';
import { Observable } from 'rxjs';

export abstract class SetCartEntryInfosAdapter {
  abstract setCartEntryInfos(
    cartId: string,
    entryNumber: number,
    consignmentInfo: string,
    orderInfoFieldValues: OrderInfoFieldValue[]
  ): Observable<any>;
}
