import { Directive, Inject } from '@angular/core';
import {Config, GlobalMessageType, LanguageService} from '@spartacus/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {
  B2BUserAccountService
} from '../../../cart-administration/services/b2-b-user-account.service';
import {
  SetCartEntryInfosService
} from '../../../cart-administration/services/set-cart-entry-infos.service';
import { Manufacturer, ManufacturerGroup } from '../../../manufacturers-selection/model/default-manufacturer.model';
import {
  DefaultManufacturersService
} from "../../../manufacturers-selection/services/default-manufacturers.service";
import {EfaGlobalMessageService} from "@shared/services/efa-global-message.service";


const DEFAULT_MANUFACTURERS_CODES_SEPARATOR = ',';

@Directive()
export abstract class ManufacturersBaseComponent {
  selectedManufacturers: Manufacturer[];
  selectedManufacturerGroup: ManufacturerGroup;
  visibleManufacturers: Manufacturer[];
  visibleManufacturerGroups: ManufacturerGroup[];
  defaultManufacturers: Manufacturer[];
  isAllManufacturerSelected: boolean;
  finalVisibleManufacturers: Manufacturer[];
  subscription: Subscription = new Subscription();
  currentLanguage$: Observable<string>;

  isLoading$: Subject<boolean> = new Subject();

  displaySuccessMessage=true;

  constructor(
    protected b2bUserAccountService: B2BUserAccountService,
    protected setCartEntryInfosService: SetCartEntryInfosService,
    protected languageService: LanguageService,
    private defaultManufacturersService: DefaultManufacturersService,
    private globalMessageService: EfaGlobalMessageService,
    @Inject(Config) protected config: any,
  ) {
  }

  ngOnInit(): void {
    this.visibleManufacturers = this.b2bUserAccountService.visibleManufacturers;
    this.visibleManufacturers = this.visibleManufacturers.sort((a, b) => a.name.localeCompare(b.name));
    this.defaultManufacturers = this.b2bUserAccountService.defaultManufacturers;
    this.visibleManufacturerGroups = this.b2bUserAccountService.visibleManufacturerGroups;
    this.setFinalVisibleManufacturers();
    this.setSelectedManufacturerGroup(this.visibleManufacturerGroups.find((manufacturerGroup: ManufacturerGroup) => manufacturerGroup.code === this.config.manufacturersSelectionDefaultManufacturerGroupCode));
    this.setIsAllManufacturerSelected();
    this.currentLanguage$ = this.languageService.getActive();

    this.subscription.add(
      this.defaultManufacturersService.isUpdating().subscribe((isUpdating: boolean) => {
        this.isLoading$.next(isUpdating);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeSelection($event): void {
    this.selectedManufacturers = [];
    this.visibleManufacturers.forEach((manufacturer: Manufacturer) => {
      if (manufacturer.isChecked) {
        this.selectedManufacturers.push(manufacturer);
      }
    });

    if (this.selectedManufacturers.length === this.visibleManufacturers.length) {
      this.setCartEntryInfosService.setIsAllManufacturerSelected(true);
    } else {
      this.setCartEntryInfosService.setIsAllManufacturerSelected(false);
    }
  }

  isAllSelected($event): void {
    const checked = $event.target.checked;
    this.visibleManufacturers.forEach((vm: Manufacturer) => vm.isChecked = checked);
    if (checked) {
      this.selectedManufacturers = this.visibleManufacturers;
      this.setCartEntryInfosService.setIsAllManufacturerSelected(true);
    } else {
      this.selectedManufacturers = [];
      this.setCartEntryInfosService.setIsAllManufacturerSelected(false);
    }
  }

  setFinalVisibleManufacturers(): void {
    this.visibleManufacturers.forEach((vm: Manufacturer) => vm.isChecked = false);
    this.finalVisibleManufacturers = this.visibleManufacturers;
    this.finalVisibleManufacturers.filter((vm: Manufacturer) => {
      this.subscription.add(
        this.b2bUserAccountService.getSelectedManufacturers().subscribe((selectedManufacturers: Manufacturer[]) => {
          const selected: boolean = !selectedManufacturers.find(({id}) => {
            return (vm.id === id) ? vm.isChecked = true : vm.isChecked = false;
          });
          if (selectedManufacturers.filter((sm: Manufacturer) => sm.isChecked).length === this.finalVisibleManufacturers.length) {
            this.setCartEntryInfosService.setIsAllManufacturerSelected(true);
          } else {
            this.setCartEntryInfosService.setIsAllManufacturerSelected(false);
          }
          return selected;
        })
      );
    });
  }

  setIsAllManufacturerSelected(): void {
    this.setCartEntryInfosService.getIsAllManufacturerSelected().subscribe((isAllSelected: boolean) => {
      this.isAllManufacturerSelected = isAllSelected;
    });
  }

  setSelectedManufacturerGroup(manufacturerGroup: ManufacturerGroup) {
    if (manufacturerGroup != null) {
      this.selectedManufacturerGroup = manufacturerGroup;
    }
  }

  submitData(): void {
    if (this.selectedManufacturers == null) {
      return;
    }
    const defaultManufacturersAsString = this.selectedManufacturers
    .map((manufacture: Manufacturer) => manufacture.id)
    .join(DEFAULT_MANUFACTURERS_CODES_SEPARATOR);
    this.defaultManufacturersService.setDefaultManufacturers(
      defaultManufacturersAsString
    );
    this.isLoading$.next(true);
    this.subscription.add(
      this.defaultManufacturersService
      .success()
      .subscribe((success: boolean) => {
        if (success) {
          this.b2bUserAccountService.defaultManufacturers.splice(0, this.defaultManufacturers.length);
          this.b2bUserAccountService.defaultManufacturers.push(...this.selectedManufacturers);
          this.b2bUserAccountService.setSelectedManufacturer(this.selectedManufacturers);
          if (this.displaySuccessMessage) this.showDefaultManufacturersSuccessMessage();
        }
      })
    );
  }


  private showDefaultManufacturersSuccessMessage(): void {
    this.globalMessageService.add(
      {
        key: 'defaultManufacturers.globalMessage.setDefaultManufacturersSuccess',
      },
      GlobalMessageType.MSG_TYPE_INFO
    );
    this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR)
  }
}
