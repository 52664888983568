import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartAndCheckoutLoadingService } from '@shared/services/cart-and-checkout-loading.service';
import { CartDetailsComponent } from '@spartacus/cart/base/components';
import { CartConfigService, SelectiveCartService } from '@spartacus/cart/base/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  AuthService,
  RoutingService
} from '@spartacus/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  OrderInfoFieldsFormsValidationService
} from '../../../checkout-order/service/order-info-fields-forms-validation.service';
import { PermissionCheckService } from '../../../user-profiles/service/permission-check.service';
import { ICON_TYPE } from '../../model/cart-administration-enum.model';
import { B2BUserAccountService } from "../../services/b2-b-user-account.service";
import { UpdateCartService } from '../../services/update-cart.service';

@Component({
  selector: 'app-efa-cart-details',
  templateUrl: './efa-cart-details.component.html',
})
export class EfaCartDetailsComponent extends CartDetailsComponent implements OnInit, OnDestroy {


  canPlaceOrders$: Observable<boolean> = this.permissionCheckService.canPlaceOrders();
  isLoading$: Observable<boolean>;
  iconTypes = ICON_TYPE;

  private subscription: Subscription = new Subscription();

  constructor(activeCartService: ActiveCartFacade,
              selectiveCartService: SelectiveCartService,
              authService: AuthService,
              routingService: RoutingService,
              cartConfig: CartConfigService,
              private b2BUserAccountService: B2BUserAccountService,
              protected permissionCheckService: PermissionCheckService,
              private orderInfoFieldsFormsValidationService: OrderInfoFieldsFormsValidationService,
              private updateCartService: UpdateCartService,
              private cartAndCheckoutLoadingService: CartAndCheckoutLoadingService) {
    super(activeCartService, selectiveCartService, authService, routingService, cartConfig);
  }

  publishFieldMarks(): void {
    this.orderInfoFieldsFormsValidationService.markAllAsTouched();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isLoading$ = combineLatest([
      this.cartLoaded$,
      this.updateCartService.isUpdating(),
    ]).pipe(
      map(([isCartLoaded, isCartUpdating]) => !isCartLoaded || isCartUpdating)
    );

    this.subscription.add(this.isLoading$.pipe(distinctUntilChanged()).subscribe(
      (isLoading: boolean) => {
        this.cartAndCheckoutLoadingService.setCartItemListLoading(isLoading);
      }
    ));
  }

  calculateCart(): void {
    this.updateCartService.calculateCart(this.b2BUserAccountService.userId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
