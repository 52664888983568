import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { WarrantyProcessRequest } from '../../../model/warranty-process.model';
import { WarrantyProcessAdapter } from '../warranty-process.adapter';

@Injectable({
  providedIn: 'root'
})
export class OccWarrantyProcessAdapter implements WarrantyProcessAdapter {
  constructor(
    protected http: HttpClient, 
    protected occEndpoints: OccEndpointsService
  ) {
    
  }

  startWarrantyProcess(request: WarrantyProcessRequest): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = this.occEndpoints.buildUrl('startWarrantyProcess', {
      urlParams: { userId: 'current' }
    });

    return this.http.post<void>(url, request, { headers });
  }
}
