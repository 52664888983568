import { LayoutConfig } from '@spartacus/storefront';

export const headerLayoutConfig: LayoutConfig = {
  layoutSlots: {
    header: {
      lg: {
        slots: [
          'PreHeader',
          'SiteLinks',
          'SiteContext',
          'MiniCart',
          'BreakSlot',
          'SiteLogo',
          'NavigationBar',
          'SiteLogin',
          'SearchBox',
        ],
      },
      slots: ['PreHeader', 'SiteLogo', 'SearchBox', 'MiniCart'],
    },
    navigation: {
      lg: { slots: [] },
      slots: ['SiteLogin', 'NavigationBar', 'SiteLinks', 'SiteContext'],
    },
  },
};
