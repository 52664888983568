import { createReducer, on } from '@ngrx/store';

import { ReturnReasonActions } from '../actions';
import { ErrorType } from '@shared/models/ErrorTypes';
import { ReturnReasonResponse } from '../../model/return-creation.model';

export const RETURN_REASON_FEATURE_KEY = 'returnReason';

export interface ReturnReasonState {
  response: ReturnReasonResponse;
  loading: boolean;
  error: ErrorType;
}

export const initialState: ReturnReasonState = {
  response: undefined,
  loading: false,
  error: undefined
};

export const returnReasonReducer = createReducer(
  initialState,

  on(ReturnReasonActions.loadReturnReasons, (state) => ({
    ...state,
    loading: true,
  })),
  on(ReturnReasonActions.loadReturnReasonsSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.response,
    request: undefined
  })),
  on(ReturnReasonActions.loadReturnReasonsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse
  }))
);
