import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CartImportExportService } from '../../services/cart-import-export-service';
import { TranslationService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { FocusConfig, LaunchDialogService } from '@spartacus/storefront';

const CSV_EXTENSION = '.csv';
const FILENAME_PATTERN = '[A-Za-z_\\-\\s0-9\\.]+';
const DEFAULT_FILENAME = 'cart-export';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CART_EXPORT = 'CART_EXPORT',
  }
}

@Component({
  selector: 'app-cart-export-dialog',
  templateUrl: './cart-export-dialog.component.html',
})
export class CartExportDialogComponent implements OnInit, OnDestroy {

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: true,
    focusOnEscape: true,
  };

  exportForm: UntypedFormGroup;
  filenameHtmlLabel = CSV_EXTENSION;
  private subscription: Subscription = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private exportService: CartImportExportService,
    private translationService: TranslationService,
    private launchDialogService: LaunchDialogService
  ) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.translationService.translate(
        'importExport.cartExportDialog.defaultFileName'
      ).subscribe(defFileName =>

        this.exportForm = this.formBuilder.group({
          filename: [defFileName, [Validators.required, Validators.pattern(FILENAME_PATTERN)]],
        })));
  }

  setFilename(): void {
    this.exportForm.markAllAsTouched();
    if (this.exportForm.invalid) {
      return;
    }

    let filename: string = this.exportForm.get('filename').value;
    if (filename.lastIndexOf('.') === -1) {
      filename = filename + CSV_EXTENSION;
    } else if (filename.lastIndexOf('.') === (filename.length - 1)) {
      filename = filename.substring(0, filename.lastIndexOf('.')) + CSV_EXTENSION;
    }

    this.exportService.setExporting(true);
    this.exportService.setExportFileName(filename);
    this.closeModal();
  }

  closeModal(): void {
    this.launchDialogService.closeDialog(null);
  }
}
