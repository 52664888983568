import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DeliveryNotesDetailsActions } from '../actions';
import { ErrorType } from '@shared/models/ErrorTypes';
import { DeliveryNoteDetailsResponse } from '../../model/delivery-notes.model';
import { DeliveryNotesDetailsConnector } from '../../occ/connectors/delivery-notes-details.connector';

@Injectable()
export class DeliveryNotesDetailsEffect {
  loadDeliveryNotesDetails$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(DeliveryNotesDetailsActions.loadDeliveryNoteDetails),
      concatMap((action) =>
        this.connector.loadDetails(action.deliveryNoteNumber, action.returnablePositions, action.download).pipe(
          map((deliveryNoteDetailsResponse: DeliveryNoteDetailsResponse) => {
            return DeliveryNotesDetailsActions.loadDeliveryNoteDetailsSuccess({
              response: deliveryNoteDetailsResponse
            });
          }),
          catchError((error: ErrorType) => {
            return of(DeliveryNotesDetailsActions.loadDeliveryNoteDetailsFailure({ errorResponse: error }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: DeliveryNotesDetailsConnector,
  ) {
  }
}
