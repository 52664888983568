<section class="cx-page-section">
    <form [formGroup]="transportDamageForm" class="row justify-content-center" (ngSubmit)="submitData()">
      <fieldset class="transport-damage_fieldset col-12 col-md-9">
        <div class="row">
            <legend class="col-12">
                <span class="transport-damage_fieldset-legend numbered">
                    {{ 'transportDamage.transportDamageForm.legend1' | cxTranslate }}
                </span>
            </legend>
        </div>
        <div class="row transport-damage_fieldset-content">
            <div class="col-md-6">
                <label class="form-group required">
                  <span class="label-content">{{ 'transportDamage.transportDamageForm.orderNumber.label' | cxTranslate }}</span>
                  <input class="form-control" type="text" formControlName="orderNumber"
                         placeholder="{{ 'transportDamage.transportDamageForm.orderNumber.placeholder' | cxTranslate }}" />
                  <cx-form-errors [control]="transportDamageForm.get('orderNumber')"></cx-form-errors>
                </label>
            </div>
            <div class="col-md-6">
                <label class="form-group required">
                    <span class="label-content">{{ 'transportDamage.transportDamageForm.deliveryNoteNumber.label' | cxTranslate }}</span>
                    <input class="form-control" type="text" formControlName="deliveryNoteNumber"
                        placeholder="{{ 'transportDamage.transportDamageForm.deliveryNoteNumber.placeholder' | cxTranslate }}" />
                    <cx-form-errors [control]="transportDamageForm.get('deliveryNoteNumber')"></cx-form-errors>
                </label>
            </div>
            <div class="col-md-6">
                <label class="form-group required">
                    <span class="label-content">{{ 'transportDamage.transportDamageForm.oemNumber.label' | cxTranslate }}</span>
                    <input class="form-control" type="text" formControlName="oemNumber"
                        placeholder="{{ 'transportDamage.transportDamageForm.oemNumber.placeholder' | cxTranslate }}" />
                    <cx-form-errors [control]="transportDamageForm.get('oemNumber')"></cx-form-errors>
                </label>
            </div>
            <div class="col-md-6">
                <label class="form-group required">
                    <span class="label-content">{{ 'transportDamage.transportDamageForm.repairPossible.label' | cxTranslate
                    }}</span>
                    <div class="form-check form-check-inline">
                        <input type="radio"
                                formControlName="repairPossible"
                                value="true"
                                (change)="onRepairPossibleChange($event.target.value)"/>
                        <label class="mt-1">{{ 'transportDamage.transportDamageForm.radio.yes' | cxTranslate }}</label>
                    </div>
                    <div class="form-check form-check-inline ml-5">
                        <input type="radio"
                                formControlName="repairPossible"
                                value="false"
                                (change)="onRepairPossibleChange($event.target.value)" />
                        <label class="mt-1">{{ 'transportDamage.transportDamageForm.radio.no' | cxTranslate }}</label>
                    </div>
                    <cx-form-errors [control]="transportDamageForm.get('repairPossible')"></cx-form-errors>
                </label>
            </div>
            <div class="col-md-6">
                <label class="form-group">
                    <span class="label-content">{{ 'transportDamage.transportDamageForm.repairCosts.label' | cxTranslate
                        }}</span>
                    <input class="form-control" type="number" id="repairCosts" formControlName="repairCosts" min="0" step="0.01"
                        placeholder="{{ 'transportDamage.transportDamageForm.repairCosts.placeholder' | cxTranslate }}" id="repairCosts" />
                </label>
                <cx-form-errors [control]="transportDamageForm.get('repairCosts')"></cx-form-errors>
            </div>

            <div class="col-md-6">
                <label class="form-group required">
                    <span class="label-content">{{ 'transportDamage.transportDamageForm.replacementWanted.label' | cxTranslate
                    }}</span>
                    <div class="form-check form-check-inline">
                        <input type="radio" formControlName="replacementWanted" value="true"/>
                        <label>{{ 'transportDamage.transportDamageForm.radio.yes' | cxTranslate }}</label>
                    </div>
                    <div class="form-check form-check-inline ml-5">
                        <input type="radio" formControlName="replacementWanted" value="false"/>
                        <label>{{ 'transportDamage.transportDamageForm.radio.no' | cxTranslate }}</label>
                    </div>
                    <cx-form-errors [control]="transportDamageForm.get('replacementWanted')"></cx-form-errors>
                </label>
            </div>
        </div>
      </fieldset>

      <fieldset class="transport-damage_fieldset col-12 col-md-9">
        <div class="row">
            <legend class="col-12">
                <span class="transport-damage_fieldset-legend numbered">
                    {{ 'transportDamage.transportDamageForm.legend2' | cxTranslate }}
                </span>
            </legend>
        </div>
        <div class="row transport-damage_fieldset-content">
            <div class="col-md-6">
                <label class="form-group required">
                    <span class="label-content">{{ 'transportDamage.transportDamageForm.descriptionOfDamage.label' | cxTranslate
                        }}</span>
                    <input class="form-control" type="text" formControlName="descriptionOfDamage"
                        placeholder="{{ 'transportDamage.transportDamageForm.descriptionOfDamage.placeholder' | cxTranslate }}" />
                    <cx-form-errors [control]="transportDamageForm.get('descriptionOfDamage')"></cx-form-errors>
                </label>
            </div>

            <div class="col-md-6">
                <label class="form-group">
                    <span class="label-content">{{ 'transportDamage.transportDamageForm.additionalHints.label' | cxTranslate
                        }}</span>
                    <input class="form-control" type="text" formControlName="additionalHints"
                        placeholder="{{ 'transportDamage.transportDamageForm.additionalHints.placeholder' | cxTranslate }}" />
                </label>
            </div>
        </div>
      </fieldset>

      <fieldset class="transport-damage_fieldset col-12 col-md-9">
        <div class="row">
            <legend class="col-12">
                <span class="transport-damage_fieldset-legend numbered">
                    {{ 'transportDamage.transportDamageForm.legend3' | cxTranslate }}
                </span>
            </legend>
        </div>
        <div class="row transport-damage_fieldset-content">
            <div class="col-12">
                <div class="form-group required row">
                    <span class="label-content col-lg-6">
                        {{ 'transportDamage.transportDamageForm.packagingImage.label' | cxTranslate }}
                    </span>
                    <div class="o-import-file col-lg-6">
                        <div class="o-import-file__input">
                            <div class="custom-file">
                                <input class="custom-file-input" type="file" formControlName="packagingImage"
                                    (change)="onPackagingImageChange($event, 'packagingImage')" />
                                <label attr.data-content="{{ 'uploadImage.search' | cxTranslate }}" id="packagingImage"
                                    class="custom-file-label">
                                    <cx-icon [type]="iconTypes.IMAGE"></cx-icon>
                                    {{ 'uploadImage.placeholder' | cxTranslate }}
                                </label>
                            </div>
                        </div>
                        <div class="o-import-file__remove">
                            <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                                    (click)="removeSelectedFile('packagingImage')"></cx-icon>
                        </div>
                    </div>
                    <div class="col-12">
                        <cx-form-errors [control]="transportDamageForm.get('packagingImage')"></cx-form-errors>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group required row">
                    <span class="label-content col-lg-6">
                        {{ 'transportDamage.transportDamageForm.damagedPartOfPackagingImage.label' | cxTranslate }}
                    </span>
                    <div class="o-import-file col-lg-6">
                        <div class="o-import-file__input">
                            <div class="custom-file">
                                <input class="custom-file-input" type="file" formControlName="damagedPartOfPackagingImage"
                                    (change)="onDamagedPartOfPackagingImageChange($event, 'damagedPartOfPackagingImage')" />
                                <label attr.data-content="{{ 'uploadImage.search' | cxTranslate }}" id="damagedPartOfPackagingImage"
                                    class="custom-file-label">
                                    <cx-icon [type]="iconTypes.IMAGE"></cx-icon>
                                    {{ 'uploadImage.placeholder' | cxTranslate }}
                                </label>
                            </div>
                        </div>
                        <div class="o-import-file__remove">
                            <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                                    (click)="removeSelectedFile('damagedPartOfPackagingImage')"></cx-icon>
                        </div>
                    </div>
                    <div class="col-12">
                        <cx-form-errors [control]="transportDamageForm.get('damagedPartOfPackagingImage')"></cx-form-errors>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group required row">
                    <span class="label-content col-lg-6">
                        {{ 'transportDamage.transportDamageForm.articleImage.label' | cxTranslate }}
                    </span>
                    <div class="o-import-file col-lg-6">
                        <div class="o-import-file__input">
                            <div class="custom-file">
                                <input class="custom-file-input" type="file" formControlName="articleImage"
                                    (change)="onArticleImageChange($event, 'articleImage')" />
                                <label attr.data-content="{{ 'uploadImage.search' | cxTranslate }}" id="articleImage"
                                    class="custom-file-label">
                                    <cx-icon [type]="iconTypes.IMAGE"></cx-icon>
                                    {{ 'uploadImage.placeholder' | cxTranslate }}
                                </label>
                            </div>
                        </div>
                        <div class="o-import-file__remove">
                            <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                                    (click)="removeSelectedFile('articleImage')"></cx-icon>
                        </div>
                    </div>
                    <div class="col-12">
                        <cx-form-errors [control]="transportDamageForm.get('articleImage')"></cx-form-errors>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group required row">
                    <span class="label-content col-lg-6">
                        {{ 'transportDamage.transportDamageForm.damagedPartOfArticleImage.label' | cxTranslate }}
                    </span>
                    <div class="o-import-file col-lg-6">
                        <div class="o-import-file__input">
                            <div class="custom-file">
                                <input class="custom-file-input" type="file" formControlName="damagedPartOfArticleImage"
                                    (change)="onDamagedPartOfArticleImageChange($event, 'damagedPartOfArticleImage')" />
                                <label attr.data-content="{{ 'uploadImage.search' | cxTranslate }}" id="damagedPartOfArticleImage"
                                    class="custom-file-label">
                                    <cx-icon [type]="iconTypes.IMAGE"></cx-icon>
                                    {{ 'uploadImage.placeholder' | cxTranslate }}
                                </label>
                            </div>
                        </div>
                        <div class="o-import-file__remove">
                            <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                                    (click)="removeSelectedFile('damagedPartOfArticleImage')"></cx-icon>
                        </div>
                    </div>
                    <div class="col-12">
                        <cx-form-errors [control]="transportDamageForm.get('damagedPartOfArticleImage')"></cx-form-errors>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group required row">
                    <span class="label-content col-lg-6">
                        {{ 'transportDamage.transportDamageForm.shippingLabelImage.label' | cxTranslate }}
                    </span>
                    <div class="o-import-file col-lg-6">
                        <div class="o-import-file__input">
                            <div class="custom-file">
                                <input class="custom-file-input" type="file" formControlName="shippingLabelImage"
                                    (change)="onShippingLabelImageChange($event, 'shippingLabelImage')" />
                                <label attr.data-content="{{ 'uploadImage.search' | cxTranslate }}" id="shippingLabelImage"
                                    class="custom-file-label">
                                    <cx-icon [type]="iconTypes.IMAGE"></cx-icon>
                                    {{ 'uploadImage.placeholder' | cxTranslate }}
                                </label>
                            </div>
                        </div>
                        <div class="o-import-file__remove">
                            <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                                     (click)="removeSelectedFile('shippingLabelImage')"></cx-icon>
                        </div>
                    </div>
                    <div class="col-12">
                        <cx-form-errors [control]="transportDamageForm.get('shippingLabelImage')"></cx-form-errors>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group required row">
                    <span class="label-content col-lg-6">
                        {{ 'transportDamage.transportDamageForm.articleNumberImage.label' | cxTranslate }}
                    </span>
                    <div class="o-import-file col-lg-6">
                        <div class="o-import-file__input">
                            <div class="custom-file">
                                <input class="custom-file-input" type="file" formControlName="articleNumberImage"
                                    (change)="onArticleNumberImageChange($event, 'articleNumberImage')" />
                                <label attr.data-content="{{ 'uploadImage.search' | cxTranslate }}" id="articleNumberImage"
                                    class="custom-file-label">
                                    <cx-icon [type]="iconTypes.IMAGE"></cx-icon>
                                    {{ 'uploadImage.placeholder' | cxTranslate }}
                                </label>
                            </div>
                        </div>
                        <div class="o-import-file__remove">
                            <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                                     (click)="removeSelectedFile('articleNumberImage')"></cx-icon>
                        </div>
                    </div>
                    <div class="col-12">
                        <cx-form-errors [control]="transportDamageForm.get('articleNumberImage')"></cx-form-errors>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group row">
                    <span class="label-content col-lg-6">
                        {{ 'transportDamage.transportDamageForm.additionalImage.label' | cxTranslate }}
                    </span>
                    <div class="o-import-file col-lg-6">
                        <div class="o-import-file__input">
                            <div class="custom-file">
                                <input class="custom-file-input" type="file" formControlName="additionalImage"
                                    (change)="onAdditionalImageChange($event, 'additionalImage')" multiple />
                                <label attr.data-content="{{ 'uploadImage.search' | cxTranslate }}" id="additionalImage"
                                    class="custom-file-label">
                                    <cx-icon [type]="iconTypes.IMAGE"></cx-icon>
                                    {{ 'uploadImage.placeholder' | cxTranslate }}
                                </label>
                            </div>
                        </div>
                        <div class="o-import-file__remove">
                            <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                                     (click)="removeSelectedFile('additionalImage')"></cx-icon>
                        </div>
                    </div>
                    <div class="col-12">
                        <cx-form-errors [control]="transportDamageForm.get('additionalImage')"></cx-form-errors>
                    </div>
                </div>
            </div>
        </div>
      </fieldset>
      <div class="col-12 col-md-9 justify-content-center" *ngIf="isLoading$ | async;else buttons">
        <div class="cx-spinner" >
          <cx-spinner></cx-spinner>
        </div>
      </div>
      <ng-template #buttons>
        <div class="col-md-6 justify-content-center">
          <button class="btn btn-block btn-primary" [disabled]="!transportDamageForm.valid">
              {{ 'transportDamage.transportDamageForm.submitTransportDamageForm' | cxTranslate }}
          </button>
        </div>
        <div class="col-12 justify-content-center d-flex pt-3">
          <button type="button" class="btn btn-link btn-block btn-hover-transform w-50"
                  (click)="clearForm()">
            {{ 'resetForm' | cxTranslate }}
          </button>
        </div>
        <div class="col-md-9 pt-3">
          <span class="small">
              {{ 'transportDamage.transportDamageForm.formMandatoryFields' | cxTranslate }}
          </span>
        </div>
      </ng-template>
    </form>
</section>

