export const ENGLISH_TRANSLATIONS = {
  en: {
    customCommon: {
      uploadFile: {
        search2: 'Search',
        search: 'Browse',
        placeholder: 'Upload file',
      },
      uploadPdfFile: {
        search: 'Search',
        placeholder: 'Upload PDF file',
      },
      uploadImage: {
        search: 'Search',
        placeholder: 'Upload Image file',
      },
      uploadFiles: {
        placeholder: 'Upload files',
      },
      resetForm: 'Reset',
      clearForm: 'Clear',
      tooltip: {
        download: 'Download',
        view: 'View'
      },
      processNavigation: {
        submit: 'Submit'
      },
      filteredDocumentsResult: {
        noResult: 'No documents available.'
      },
      noPriceToDisplayForProduct: 'No price available',
      globalMessage: {
        incompatibleArticleAndOrderType: 'Error: Article + Order Type don\'t match - Please change!'
      }
    }
  }
};

export const GERMAN_TRANSLATIONS = {
  de: {
    customCommon: {
      uploadFile: {
        search2: 'Durchsuchen',
        search: 'Durchsuchen',
        placeholder: 'Datei hochladen',
      },
      uploadPdfFile: {
        search: 'Durchsuchen',
        placeholder: 'PDF Datei hochladen',
      },
      uploadImage: {
        search: 'Durchsuchen',
        placeholder: 'Bild hochladen',
      },
      uploadFiles: {
        placeholder: 'Dateien hochladen',
      },
      resetForm: 'Zurücksetzen',
      clearForm: 'Zurücksetzen',
      tooltip: {
        download: 'Herunterladen',
        view: 'Anzeigen'
      },
      processNavigation: {
        submit: 'Absenden'
      },
      filteredDocumentsResult: {
        noResult: 'Keine Belege vorhanden.'
      },
      noPriceToDisplayForProduct: 'Kein Preis verfügbar',
      globalMessage: {
        incompatibleArticleAndOrderType: 'Fehler: Artikel + Auftragsart - Kombination nicht möglich - Bitte ändern!'
      }
    }
  }
};
