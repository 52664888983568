import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {AuthGuard, B2BUser, EventService, ProtectedRoutesService, RoutingService} from '@spartacus/core';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {TermsConfirmationEvent} from '../model/login-register.model';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class EfaProtectedRoutesGuard  {

  subscription: Subscription = new Subscription();
  termsAccepted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    protected service: ProtectedRoutesService,
    protected authGuard: AuthGuard,
    private userFacade: UserAccountFacade,
    protected routingService: RoutingService,
    private eventService: EventService,
    private deviceService: DeviceDetectorService,
    private router: Router
  ) {
    this.userFacade.get().pipe(filter(Boolean)).subscribe(user => {
        this.termsAccepted$.next((user as B2BUser).termsAccepted);
      }
    );

    this.eventService.get(TermsConfirmationEvent).subscribe((event) => {
      this.termsAccepted$.next(event.termsAccepted);
    });
  }

  /**
   * When the anticipated url is protected, it switches to the AuthGuard. Otherwise emits true.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if(route.url.toString() === 'unsupported-browser') {
      return of(true);
    }
    if (this.deviceService.browser.toLowerCase() === 'ie') {
      return of(this.router.createUrlTree(['/unsupported-browser']));
    }
    let urlSegments: string[] = route.url.map((seg) => seg.path);
    // For the root path `/` ActivatedRoute contains an empty array of segments:
    urlSegments = urlSegments.length ? urlSegments : [''];

    if (this.service.isUrlProtected(urlSegments)) {
      return this.authGuard.canActivate()
        .pipe(
          withLatestFrom(this.termsAccepted$),
          map(([canBeActivated, termsAccepted]) => {
              if (canBeActivated instanceof UrlTree) {
                return canBeActivated;
              } else {
                return termsAccepted;
              }
            }
          ));
    }
    return of(true);
  }
}
