import { ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {  ListService, SubListComponent, UserGroupAssignedPermissionsListService } from '@spartacus/organization/administration/components';


@Component({
  selector: 'app-efa-sub-list',
  templateUrl: './efa-sub-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'content-wrapper' },

})
export class EfaSubListComponent extends SubListComponent implements OnInit {


  ngOnInit(): void {
  }

}
