export function invalidFileExtension(file: File, fileExtensions: string[]): boolean {
    const filename: string = file.name;
    const fileExtensionIndex: number = file.name.lastIndexOf('.');
    if (fileExtensionIndex === -1) {
        return true;
    }
    const fileExtension: string = filename.substring(fileExtensionIndex);
    const foundFileExtension: string = fileExtensions.find((validFileExtension: string) => fileExtension === validFileExtension);
    return foundFileExtension == null ;
}
