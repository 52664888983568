import { Component, ComponentRef, Input, OnChanges, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { Address,RoutingService} from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { CONSIGNMENT_INFO_ALLOWED_PATTERN } from '@shared/models/shared.model';
import { CartAndCheckoutLoadingService } from '@shared/services/cart-and-checkout-loading.service';
import { DeliveryMode, MultiCartFacade } from '@spartacus/cart/base/root';
import { CheckoutPlaceOrderComponent } from '@spartacus/checkout/base/components';
import { Order, OrderFacade, ReplenishmentOrder } from '@spartacus/order/root';
import { ProductCategory } from '../../../cart-administration/model/cart-administration-enum.model';
import { OrderInfoFieldValue, OrderType } from '../../../cart-administration/model/cart-administration.model';
import { UpdateCartArg } from '../../../cart-administration/model/update-cart.model';
import { B2BUserAccountService } from '../../../cart-administration/services/b2-b-user-account.service';
import { CartParametersService } from '../../../cart-administration/services/cart-parameters.service';
import { UpdateCartService } from '../../../cart-administration/services/update-cart.service';
import { OrderOverviewService } from '../../../order-history/services/order-overview.service';
import { CheckoutOrderUiService } from '../../service/checkout-order-ui.service';
import { OrderInfoFieldsFormsValidationService } from '../../service/order-info-fields-forms-validation.service';
import { OrderDetailsService } from '@spartacus/order/components';

const LEFT_PARENTHESIS = '(';
const RIGHT_PARENTHESIS = ')';
const OR_CONJUNCTION = '|';
const ORDER_SUMMARY_ORDER_INFO_FIELDS_FORM_KEY = 'ORDER_SUMMARY_ORDER_INFO_FIELDS';

@Component({
  selector: 'app-efa-place-order',
  templateUrl: './efa-place-order.component.html',
})
export class EfaPlaceOrderComponent extends CheckoutPlaceOrderComponent implements OnInit, OnChanges, OnDestroy {
  private mySubscription: Subscription = new Subscription();

  @Input() orderInfoFields: OrderInfoFieldValue[];
  @Input() orderType: OrderType;
  @Input() deliveryMode: DeliveryMode;
  @Input() deliveryAddress: Address;
  @Input() requestedDeliveryDate: string;
  @Input() readonly: boolean;
  @Input() deactivatePlaceOrder: boolean;

  checkoutSubmitForm: UntypedFormGroup = this.fb.group({
    consignmentInfo: ['', [Validators.pattern(CONSIGNMENT_INFO_ALLOWED_PATTERN), Validators.required]]
  });
  orderDetails$: Observable<Order | ReplenishmentOrder> = this.orderDetailsService.getOrderDetails();
  placeOrderRunning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly consignmentInfoAllowedMaxLength: number = 20;

  constructor(
    protected orderFacade: OrderFacade,
    protected routingService: RoutingService,
    protected fb: UntypedFormBuilder,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected b2bUserAccountService: B2BUserAccountService,
    private updateCartService: UpdateCartService,
    private checkoutOrderUiService: CheckoutOrderUiService,
    private orderOverviewService: OrderOverviewService,
    private cartAndCheckoutLoadingService: CartAndCheckoutLoadingService,
    private orderInfoFieldsFormsValidationService: OrderInfoFieldsFormsValidationService,
    private multiCartService: MultiCartFacade,
    private cartParametersService: CartParametersService,
    protected orderDetailsService: OrderDetailsService
  ) {
    super(orderFacade, routingService, fb, launchDialogService, vcr);
  }

  ngOnChanges(): void {
    if (!!this.orderInfoFields) {
      this.orderInfoFields.forEach((orderInfoFields: OrderInfoFieldValue) => {
        if (orderInfoFields.orderInfoField) {
          const formControl: UntypedFormControl = new UntypedFormControl('');
          this.addOrderInfoFieldFormControlValidators(orderInfoFields, formControl);
          this.checkoutSubmitForm.addControl(orderInfoFields.orderInfoField.fieldId, formControl);
        }
      });
    }
  }

  ngOnInit(): void {
    this.orderInfoFieldsFormsValidationService.reset();
    this.orderInfoFieldsFormsValidationService.add(ORDER_SUMMARY_ORDER_INFO_FIELDS_FORM_KEY, this.checkoutSubmitForm);

    if (this.checkoutSubmitForm.valid) {
      this.checkoutOrderUiService.setSelectedIsValidForm(true);
    }

    this.checkoutSubmitForm.statusChanges.subscribe((status: string) => {
      if (status === 'VALID') {
        this.checkoutOrderUiService.setSelectedIsValidForm(true);
      } else {
        this.checkoutOrderUiService.setSelectedIsValidForm(false);
      }
    });

    this.mySubscription.add(
      this.checkoutOrderUiService.selectedPlaceOrderTriggered().pipe(filter(Boolean)).subscribe(value => this.submitForm()
      ));

    this.mySubscription.add(
      this.updateCartService.success().pipe(withLatestFrom(this.placeOrderRunning$),
        distinctUntilChanged(([a, b]) => (!a[1] && b[1])),
        filter(([a, b]) => a && b))
      .subscribe(b => {
        this.placedOrder = this.launchDialogService.launch(
          LAUNCH_CALLER.PLACE_ORDER_SPINNER,
          this.vcr
        );

        this.orderFacade.placeOrder(true).subscribe({
          error: () => {
            if (!this.placedOrder) {
              return;
            }

            this.placedOrder
              .subscribe((component: ComponentRef<any>) => {
                this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
                if (component) {
                  component.destroy();
                }
              })
              .unsubscribe();
          },
          next: () => this.onSuccess(),
        });

        this.checkoutOrderUiService.resetTriggerPlaceOrder();
        this.placeOrderRunning$.next(false);
        this.orderOverviewService.resetOverview();
      }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.orderInfoFieldsFormsValidationService.remove(ORDER_SUMMARY_ORDER_INFO_FIELDS_FORM_KEY);
    this.cartAndCheckoutLoadingService.setCartItemListLoading(false);
    this.mySubscription.unsubscribe();
  }

  submitForm(): void {
    if (this.checkoutSubmitForm.valid) {
      const requestedDeliveryDate: Date = new Date(this.requestedDeliveryDate);
      const data: UpdateCartArg = {
        cartId: this.b2bUserAccountService.cartId,
        userId: this.b2bUserAccountService.userId,
        orderTypeId: this.orderType.id,
        shippingTypeId: this.deliveryMode.code,
        addressId: this.deliveryAddress.id,
        calculate: false,
        requestedDeliveryDate: requestedDeliveryDate.getFullYear() + '-'
          + (requestedDeliveryDate.getMonth() + 1) + '-'
          + requestedDeliveryDate.getDate(),
        consignmentInfo: this.checkoutSubmitForm.value.consignmentInfo,
        orderInfoFields: this.getOrderInfoField()
      };
      this.updateCartService.reset();
      this.placeOrderRunning$.next(true);
      this.updateCartService.updateCart(data);
    }
  }

  getOrderInfoField(): OrderInfoFieldValue[] {
    const orderInfoFieldValue: OrderInfoFieldValue[] = [];
    this.orderInfoFields.forEach((orderInfoFields: OrderInfoFieldValue) => {
      const object = {
        orderInfoField: orderInfoFields.orderInfoField,
        value: this.checkoutSubmitForm.controls[orderInfoFields.orderInfoField.fieldId].value
      };
      orderInfoFieldValue.push(object);
    });
    return orderInfoFieldValue;
  }

  private addOrderInfoFieldFormControlValidators(orderInfoField: OrderInfoFieldValue, formControl: UntypedFormControl): void {
    if (orderInfoField.orderInfoField.mandatory) {
      formControl.addValidators(Validators.required);
    } else {
      formControl.addValidators(Validators.nullValidator);
    }

    const validOrderInfoFieldLengthRange: boolean = this.validOrderInfoFieldLengthRange(orderInfoField);

    if (validOrderInfoFieldLengthRange) {
      if (orderInfoField.orderInfoField.numeric) {
        this.addNumericOrderInfoFieldFormControlValidators(orderInfoField, formControl);
      } else {
        this.addTextOrderInfoFieldFormControlValidators(orderInfoField, formControl);
      }
    }
  }

  private addTextOrderInfoFieldFormControlValidators(orderInfoField: OrderInfoFieldValue, formControl: UntypedFormControl): void {
    if (orderInfoField.orderInfoField.exactLength) {
      formControl.addValidators(
        [Validators.minLength(orderInfoField.orderInfoField.length)]);
    } else {
      formControl.addValidators(Validators.maxLength(orderInfoField.orderInfoField.length));
    }

    if (orderInfoField.orderInfoField.patterns?.length > 0) {
      const patternsInConjuctionForm: string = orderInfoField.orderInfoField.patterns.map(
        (pattern: string) => LEFT_PARENTHESIS + pattern + RIGHT_PARENTHESIS).join(OR_CONJUNCTION);
      formControl.addValidators(Validators.pattern(patternsInConjuctionForm));
    }
  }

  private addNumericOrderInfoFieldFormControlValidators(orderInfoField: OrderInfoFieldValue, formControl: UntypedFormControl): void {
    if (orderInfoField.orderInfoField.exactLength) {
      formControl.addValidators(
        [Validators.max(this.getMaxNumberForNumericLength(orderInfoField.orderInfoField.length)),
          Validators.min(this.getMinNumberForNumericLength(orderInfoField.orderInfoField.length))]);
    } else {
      formControl.addValidators(
        [Validators.max(this.getMaxNumberForNumericLength(orderInfoField.orderInfoField.length)),
          Validators.min(0)]);
    }
  }

  private validOrderInfoFieldLengthRange(orderInfoField: OrderInfoFieldValue): boolean {
    return orderInfoField.orderInfoField.length != null && orderInfoField.orderInfoField.length > 0;
  }

  private getMaxNumberForNumericLength(length: number): number {
    let nom = '9';
    for (let i = 0; i < length - 1; i++) {
      nom += '9';
    }
    return Number.parseInt(nom, 10);
  }

  private getMinNumberForNumericLength(length: number): number {
    let nom = '1';
    for (let i = 0; i < length - 1; i++) {
      nom += '0';
    }
    return Number.parseInt(nom, 10);
  }

  onSuccess(): void {
    super.onSuccess();

    console.log('create new cart after placing an order');
    this.multiCartService.createCart(
      {
        userId: this.b2bUserAccountService.userId,
        extraData: {active: true}
      });
    this.cartParametersService.setCurrentProductCategory(ProductCategory.SPARE_PARTS);
  }
}
