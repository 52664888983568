<!--If component data exists-->
<!--{{ registrationStartComponentData$ | async | json }}-->
<ng-container *ngIf="registrationStartComponentData$ | async as data" class="registration-start">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10 px-2">
<!--  <h2 class="registration-start__title text-center">{{ 'efaRegistrationStart.headline' | cxTranslate }}</h2>-->
      <ng-container *ngIf="data.text1">
        <div [innerHTML]="data.text1" class="registration-start__text1"></div>
      </ng-container>

      <ng-container *ngIf="downloadLinksData$ | async">
        <div class="registration-start__links container">
          <ul class="row p-0">
            <li class="col-12 col-md-6 mb-1 p-0" *ngFor="let link of downloadLinksItems">
              <cx-generic-link
                [url]="getExternal(link) ? link.url : getUrl(link.url)"
                [style]="link.styleAttributes"
                [target]="getTarget(link)">
                <cx-icon [type]="iconTypes.DOWNLOAD_BLUE"></cx-icon>
                {{ link.linkName }}
              </cx-generic-link>
            </li>
          </ul>
        </div>
      </ng-container>

      <hr>

      <ng-container *ngIf="data.text2">
        <div [innerHTML]="data.text2" class="registration-start__text2"></div>
      </ng-container>

      <ng-container *ngIf="(displayButton$ | async) === 'true'">
        <div class="row">
          <div class="col-md-4">
            <button [routerLink]="{ cxRoute: 'registration' } | cxUrl"
                    type="button"
                    class="btn btn-block btn-primary">
              {{ 'efaRegistrationStart.continueToRegistration' | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
