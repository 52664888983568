import { Observable } from 'rxjs';

import { OrderCancelResponse } from '../../model/order-cancel.model';
import { CancellationRequestEntryInputList } from '@spartacus/order/root';

export abstract class OrderCancelAdapter {
  abstract doOrderCancellation(
    orderNumber: string,
    positions: CancellationRequestEntryInputList
  ): Observable<OrderCancelResponse>;
}
