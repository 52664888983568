import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { InvoiceCreditDetailsAdapter } from '../adapters/invoice-credit-details.adapter';
import { InvoicesCreditDetailsResponse } from '../../model/invoices-credits.model';

@Injectable({ providedIn: 'root' })
export class InvoiceCreditDetailsConnector {
  constructor(protected  adapter: InvoiceCreditDetailsAdapter) {}

  loadInvoiceDetails(documentNumber: string): Observable<InvoicesCreditDetailsResponse> {
    return this.adapter.loadInvoiceDetails(documentNumber);
  }
}
