<section class="cx-page-section container"
         *ngIf="!(isLoading$ | async); else loading">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 px-2">
      <div class="cx-section">
        <form [formGroup]="registrationForm">
          <p>{{"efaRegistrationForm.formMandatoryFields" | cxTranslate }}</p>
          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend5' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <div class="form-group" formArrayName="contactPersons">
                <div class="container px-0">
                  <ng-container
                    *ngFor="let contactPersonsFormControl of contactPersonsFormControls; let i=index">
                    <ng-container [formGroupName]="i">
                      <div class="d-flex flex-row justify-content-between w-100">
                        <span class="label-content u-font-bold">{{i + 1}}{{ 'efaRegistrationForm.contactPerson' | cxTranslate }}</span>
                        <div class="col-4 d-flex justify-content-end px-0">
                          <cx-icon *ngIf="contactPersonsFormControls.length > 1" class="u-pointer"
                                   [type]="iconTypes.RESET"
                                   (click)="removeContactPersonControl(i)"
                                   ngbTooltip="{{ 'efaRegistrationForm.removeContactPerson' | cxTranslate }}"></cx-icon>
                        </div>
                      </div>

                      <label class="form-group w-100 select-arrow">
                        <span
                          class="label-content">{{'efaRegistrationForm.contactPersonPosition' | cxTranslate}}</span>
                        <select formControlName="positionCode" class="form-control" [ngClass]="getSelectPlaceholderTextClass(contactPersonsFormControl.get('positionCode').value)">
                          <option selected value="" disabled>
                            {{ 'efaRegistrationForm.selectContactPersonPosition' | cxTranslate }}
                          </option>
                          <option
                            *ngFor="let contactPersonPosition of registrationInformationMultiOptionsFieldsValues.contactPersonPositions"
                            [value]="contactPersonPosition.code">
                            {{ contactPersonPosition.name }}
                          </option>
                        </select>
                        <cx-form-errors
                          [control]="contactPersonsFormControl.get('positionCode')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span class="label-content">{{ 'efaRegistrationForm.title' | cxTranslate }}
                          *</span>
                        <div class="d-inline-flex flex-row flex-wrap">
                          <ng-container
                            *ngFor="let salutation of registrationInformationMultiOptionsFieldsValues.salutations">
                            <label class="form-check form-check-inline m-0 mr-4">
                              <input type="radio"
                                     formControlName="salutationCode"
                                     [value]="salutation.code"/>
                              {{ salutation.name }}
                            </label>
                          </ng-container>
                        </div>
                        <cx-form-errors prefix="efaRegistrationForm.formErrors.title"
                                        [control]="contactPersonsFormControl.get('salutationCode')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span
                          class="label-content">{{ 'efaRegistrationForm.firstName.label' | cxTranslate }}
                          *</span>
                        <input type="text"
                               class="form-control"
                               formControlName="firstName"
                               placeholder="{{ 'efaRegistrationForm.firstName.placeholder' | cxTranslate }}"/>
                        <cx-form-errors prefix="efaRegistrationForm.formErrors.firstName"
                                        [control]="contactPersonsFormControl.get('firstName')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span
                          class="label-content">{{ 'efaRegistrationForm.lastName.label' | cxTranslate }}
                          *</span>
                        <input type="text"
                               class="form-control"
                               formControlName="lastName"
                               placeholder="{{ 'efaRegistrationForm.lastName.placeholder' | cxTranslate }}"/>
                        <cx-form-errors prefix="efaRegistrationForm.formErrors.lastName"
                                        [control]="contactPersonsFormControl.get('lastName')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span
                          class="label-content">{{ 'efaRegistrationForm.phone.label' | cxTranslate }}
                          *</span>
                        <input type="text"
                               class="form-control"
                               autocomplete="tel"
                               formControlName="phone"
                               placeholder="{{ 'efaRegistrationForm.phone.placeholder' | cxTranslate }}"/>
                        <cx-form-errors prefix="efaRegistrationForm.formErrors.phone"
                                        [control]="contactPersonsFormControl.get('phone')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span
                          class="label-content">{{ 'efaRegistrationForm.contactPersonEmailAddress.label' | cxTranslate }}
                          *</span>
                        <input type="email"
                               class="form-control"
                               autocomplete="email"
                               formControlName="email"
                               placeholder="{{ 'efaRegistrationForm.contactPersonEmailAddress.placeholder' | cxTranslate }}"/>
                        <cx-form-errors
                          prefix="efaRegistrationForm.formErrors.contactPersonEmailAddress"
                          [control]="contactPersonsFormControl.get('email')"></cx-form-errors>
                      </label>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="d-flex add-contact-person">
                  <button type="button" (click)="addContactPersonControl()"
                          class="btn btn-block btn-secondary add-contact-person">
                    <cx-icon [type]="iconTypes.ADD_CIRCLE"></cx-icon>
                    {{ 'efaRegistrationForm.addContactPerson' | cxTranslate }}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend7' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.orderConfirmationEmailAddress.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.orderConfirmationEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="emailOrderConfirmation"/>
                <cx-form-errors
                  [control]="registrationForm.get('emailOrderConfirmation')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.deliveryNoteForwardingEmailAddress.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.deliveryNoteForwardingEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="emailDeliveryNoteForwarding"/>
                <cx-form-errors
                  [control]="registrationForm.get('emailDeliveryNoteForwarding')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.invoiceForwardingEmailAddress.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.invoiceForwardingEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="emailInvoiceForwarding"/>
                <cx-form-errors
                  [control]="registrationForm.get('emailInvoiceForwarding')"></cx-form-errors>
              </label>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
