<section class="cx-page-section container"
         *ngIf="!(isLoading$ | async); else loading">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 px-2">
      <div class="cx-section">
        <cx-spinner class="overlay" *ngIf="isSubmittingRegisterdData$ | async"></cx-spinner>
        <form (ngSubmit)="onSubmit()" [formGroup]="registrationForm">
          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend1' | cxTranslate}}</legend>

            <div class="register_fieldset-content">
              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.companyName.label' | cxTranslate }}
                  *</span>
                <input type="text"
                       class="form-control"
                       autocomplete="organization"
                       formControlName="companyName"
                       placeholder="{{ 'efaRegistrationForm.companyName.placeholder' | cxTranslate }}"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.companyName"
                                [control]="registrationForm.get('companyName')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.role' | cxTranslate }}*</span>
                <select formControlName="roleCode" class="form-control">
                  <option selected value="" disabled>
                    {{ 'efaRegistrationForm.selectPosition' | cxTranslate }}
                  </option>
                  <option
                    *ngFor="let registrationRole of registrationInformationMultiOptionsFieldsValues.registrationRoles"
                    [value]="registrationRole.code">
                    {{ registrationRole.name }}
                  </option>
                </select>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.role"
                                [control]="registrationForm.get('roleCode')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.title' | cxTranslate }}*</span>
                <div class="d-inline-flex flex-row flex-wrap">
                  <ng-container *ngFor="let title of titles">
                    <label class="form-check form-check-inline m-0 mr-4">
                      <input type="radio"
                             formControlName="titleCode"
                             name="titleCode"
                             [value]="title"/>
                      {{ 'efaRegistrationForm.gender.' + title | cxTranslate }}
                    </label>
                  </ng-container>
                </div>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.title"
                                [control]="registrationForm.get('titleCode')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.yourFirstName.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="text"
                       name="firstName"
                       autocomplete="given-name"
                       placeholder="{{ 'efaRegistrationForm.yourFirstName.placeholder' | cxTranslate }}"
                       formControlName="firstName"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.yourFirstName"
                                [control]="registrationForm.get('firstName')"></cx-form-errors>
              </label>
              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.yourLastName.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="text"
                       name="lastName"
                       autocomplete="family-name"
                       placeholder="{{ 'efaRegistrationForm.yourLastName.placeholder' | cxTranslate }}"
                       formControlName="lastName"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.yourLastName"
                                [control]="registrationForm.get('lastName')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.taxId.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="text"
                       placeholder="{{ 'efaRegistrationForm.taxId.placeholder' | cxTranslate }}"
                       formControlName="taxId"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.taxId"
                                [control]="registrationForm.get('taxId')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.emailAddress.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="email"
                       autocomplete="email"
                       placeholder="{{ 'efaRegistrationForm.emailAddress.placeholder' | cxTranslate }}"
                       formControlName="email"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.emailAddress"
                                [control]="registrationForm.get('email')"></cx-form-errors>
              </label>

              <div class="form-group row">
                <label class="col-md-9">
                  <span class="label-content">{{ 'efaRegistrationForm.street.label' | cxTranslate }}
                    *</span>
                  <input class="form-control"
                         type="text"
                         name="street"
                         autocomplete="address-line1"
                         placeholder="{{ 'efaRegistrationForm.street.placeholder' | cxTranslate }}"
                         formControlName="street"/>
                  <cx-form-errors prefix="efaRegistrationForm.formErrors.street"
                                  [control]="registrationForm.get('street')"></cx-form-errors>
                </label>
                <label class="col-md-3">
                  <span
                    class="label-content">{{ 'efaRegistrationForm.streetNumber.label' | cxTranslate }}
                    *</span>
                  <input class="form-control"
                         type="text"
                         name="streetNumber"
                         autocomplete="address-line2"
                         placeholder="{{ 'efaRegistrationForm.streetNumber.placeholder' | cxTranslate }}"
                         formControlName="streetNumber"/>
                  <cx-form-errors prefix="efaRegistrationForm.formErrors.streetNumber"
                                  [control]="registrationForm.get('streetNumber')"></cx-form-errors>
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-4">
                  <span
                    class="label-content">{{ 'efaRegistrationForm.postalCode.label' | cxTranslate }}
                    *</span>
                  <input class="form-control"
                         type="text"
                         name="postalCode"
                         autocomplete="postal-code"
                         placeholder="{{ 'efaRegistrationForm.postalCode.placeholder' | cxTranslate}}"
                         formControlName="postalCode"/>
                  <cx-form-errors prefix="efaRegistrationForm.formErrors.postalCode"
                                  [control]="registrationForm.get('postalCode')"></cx-form-errors>
                </label>

                <label class="col-md-8">
                  <span class="label-content">{{ 'efaRegistrationForm.town.label' | cxTranslate }}
                    *</span>
                  <input class="form-control"
                         type="text"
                         placeholder="{{ 'efaRegistrationForm.town.placeholder' | cxTranslate}}"
                         formControlName="town"/>
                  <cx-form-errors prefix="efaRegistrationForm.formErrors.town"
                                  [control]="registrationForm.get('town')"></cx-form-errors>
                </label>
              </div>

              <label class="form-group">
                <span
                  class="label-content">{{'efaRegistrationForm.countryIsoCode.label' | cxTranslate}}
                  *</span>
                <select formControlName="countryIsoCode" class="form-control">
                  <option selected value="" disabled>
                    {{ 'efaRegistrationForm.countryIsoCode.selectCountry' | cxTranslate }}
                  </option>
                  <option *ngFor="let country of countries$ | async"
                          [value]="country.isocode">
                    {{ country.name }}
                  </option>
                </select>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.countryIsoCode"
                                [control]="registrationForm.get('countryIsoCode')"></cx-form-errors>
              </label>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend2' | cxTranslate}}
              *
            </legend>
            <div class="register_fieldset-content">
              <div class="form-group">
                <div class="d-inline-flex flex-row flex-wrap">
                  <ng-container
                    *ngFor="let businessType of registrationInformationMultiOptionsFieldsValues.businessTypes">
                    <label class="form-check form-check-inline m-0 mr-4">
                      <input type="radio"
                             formControlName="businessTypeCode"
                             name="businessTypeCode"
                             [value]="businessType.code"/>
                      {{ businessType.name }}
                    </label>
                  </ng-container>
                </div>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.businessTypeCode"
                                [control]="registrationForm.get('businessTypeCode')"></cx-form-errors>
              </div>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend3' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <div class="form-group" formArrayName="specializationCodes">
                <div class="d-inline-flex flex-column flex-md-row flex-wrap">
                  <ng-container
                    *ngFor="let registrationSpecificBusinessTypeControl of registrationSpecificBusinessTypeFormControls; let i=index">
                    <label class="form-check form-check-inline m-0 mr-4">
                      <input type="checkbox" [formControlName]="i"/>
                      {{ registrationInformationMultiOptionsFieldsValues.registrationSpecificBusinessTypes[i].name }}
                    </label>
                  </ng-container>
                </div>
                <cx-form-errors
                  [control]="registrationForm.get('specializationCodes')"></cx-form-errors>
              </div>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend4' | cxTranslate}}</legend>

            <div class="register_fieldset-content">
              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.membership.label' | cxTranslate }}</span>
                <input type="text"
                       class="form-control"
                       formControlName="membership"
                       placeholder="{{ 'efaRegistrationForm.membership.placeholder' | cxTranslate }}"/>
                <cx-form-errors [control]="registrationForm.get('membership')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.membershipId.label' | cxTranslate }}</span>
                <input type="text"
                       class="form-control"
                       formControlName="membershipId"
                       placeholder="{{ 'efaRegistrationForm.membershipId.placeholder' | cxTranslate }}"/>
                <cx-form-errors [control]="registrationForm.get('membershipId')"></cx-form-errors>
              </label>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend5' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <div class="form-group" formArrayName="contactPersons">
                <div class="container px-0">
                  <ng-container
                    *ngFor="let contactPersonsFormControl of contactPersonsFormControls; let i=index">
                    <ng-container [formGroupName]="i">
                      <div class="d-flex flex-row justify-content-between w-100">
                        <span class="label-content u-font-bold">{{i + 1}}
                          . {{ 'efaRegistrationForm.contactPerson' | cxTranslate }}</span>
                        <div class="col-4 d-flex justify-content-end px-0">
                          <cx-icon *ngIf="contactPersonsFormControls.length > 1" class="u-pointer"
                                   [type]="iconTypes.RESET"
                                   (click)="removeContactPersonControl(i)"
                                   ngbTooltip="{{ 'efaRegistrationForm.removeContactPerson' | cxTranslate }}"></cx-icon>
                        </div>
                      </div>

                      <label class="form-group w-100">
                        <span
                          class="label-content">{{'efaRegistrationForm.contactPersonPosition' | cxTranslate}}</span>
                        <select formControlName="positionCode" class="form-control">
                          <option selected value="" disabled>
                            {{ 'efaRegistrationForm.selectContactPersonPosition' | cxTranslate }}
                          </option>
                          <option
                            *ngFor="let contactPersonPosition of registrationInformationMultiOptionsFieldsValues.contactPersonPositions"
                            [value]="contactPersonPosition.code">
                            {{ contactPersonPosition.name }}
                          </option>
                        </select>
                        <cx-form-errors
                          [control]="contactPersonsFormControl.get('positionCode')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span class="label-content">{{ 'efaRegistrationForm.title' | cxTranslate }}
                          *</span>
                        <div class="d-inline-flex flex-row flex-wrap">
                          <ng-container
                            *ngFor="let salutation of registrationInformationMultiOptionsFieldsValues.salutations">
                            <label class="form-check form-check-inline m-0 mr-4">
                              <input type="radio"
                                     formControlName="salutationCode"
                                     name="salutationCode"
                                     [value]="salutation.code"/>
                              {{ salutation.name }}
                            </label>
                          </ng-container>
                        </div>
                        <cx-form-errors prefix="efaRegistrationForm.formErrors.title"
                                        [control]="contactPersonsFormControl.get('salutationCode')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span
                          class="label-content">{{ 'efaRegistrationForm.firstName.label' | cxTranslate }}
                          *</span>
                        <input type="text"
                               class="form-control"
                               formControlName="firstName"
                               placeholder="{{ 'efaRegistrationForm.firstName.placeholder' | cxTranslate }}"/>
                        <cx-form-errors prefix="efaRegistrationForm.formErrors.firstName"
                                        [control]="contactPersonsFormControl.get('firstName')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span
                          class="label-content">{{ 'efaRegistrationForm.lastName.label' | cxTranslate }}
                          *</span>
                        <input type="text"
                               class="form-control"
                               formControlName="lastName"
                               placeholder="{{ 'efaRegistrationForm.lastName.placeholder' | cxTranslate }}"/>
                        <cx-form-errors prefix="efaRegistrationForm.formErrors.lastName"
                                        [control]="contactPersonsFormControl.get('lastName')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span
                          class="label-content">{{ 'efaRegistrationForm.phone.label' | cxTranslate }}
                          *</span>
                        <input type="text"
                               class="form-control"
                               autocomplete="tel"
                               formControlName="phone"
                               placeholder="{{ 'efaRegistrationForm.phone.placeholder' | cxTranslate }}"/>
                        <cx-form-errors prefix="efaRegistrationForm.formErrors.phone"
                                        [control]="contactPersonsFormControl.get('phone')"></cx-form-errors>
                      </label>

                      <label class="form-group w-100">
                        <span
                          class="label-content">{{ 'efaRegistrationForm.contactPersonEmailAddress.label' | cxTranslate }}
                          *</span>
                        <input type="email"
                               class="form-control"
                               autocomplete="email"
                               formControlName="email"
                               placeholder="{{ 'efaRegistrationForm.contactPersonEmailAddress.placeholder' | cxTranslate }}"/>
                        <cx-form-errors
                          prefix="efaRegistrationForm.formErrors.contactPersonEmailAddress"
                          [control]="contactPersonsFormControl.get('email')"></cx-form-errors>
                      </label>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="d-flex">
                  <button type="button" (click)="addContactPersonControl()"
                          class="btn btn-block btn-secondary">
                    <cx-icon [type]="iconTypes.ADD_CIRCLE"></cx-icon>
                    {{ 'efaRegistrationForm.addContactPerson' | cxTranslate }}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend7' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.orderConfirmationEmailAddress.label' | cxTranslate }}*</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.orderConfirmationEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="emailOrderConfirmation"/>
                <cx-form-errors [control]="registrationForm.get('emailOrderConfirmation')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.deliveryNoteForwardingEmailAddress.label' | cxTranslate }}*</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.deliveryNoteForwardingEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="emailDeliveryNoteForwarding"/>
                <cx-form-errors [control]="registrationForm.get('emailDeliveryNoteForwarding')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.invoiceForwardingEmailAddress.label' | cxTranslate }}*</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.invoiceForwardingEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="emailInvoiceForwarding"/>
                <cx-form-errors [control]="registrationForm.get('emailInvoiceForwarding')"></cx-form-errors>
              </label>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend6' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.sepaBank.label' | cxTranslate }}
                  *</span>
                <input type="text"
                       class="form-control"
                       formControlName="sepaBank"
                       placeholder="{{ 'efaRegistrationForm.sepaBank.placeholder' | cxTranslate }}"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.sepaBank"
                                [control]="registrationForm.get('sepaBank')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.sepaIban.label' | cxTranslate }}
                  *</span>
                <input type="text"
                       class="form-control"
                       formControlName="sepaIban"
                       placeholder="{{ 'efaRegistrationForm.sepaIban.placeholder' | cxTranslate }}"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.sepaIban"
                                [control]="registrationForm.get('sepaIban')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.sepaBic.label' | cxTranslate }}</span>
                <input type="text"
                       class="form-control"
                       formControlName="sepaBic"
                       placeholder="{{ 'efaRegistrationForm.sepaBic.placeholder' | cxTranslate }}"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.sepaBic"
                                [control]="registrationForm.get('sepaBic')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.sepaEmailAddress.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.sepaEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="sepaEmail"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.sepaEmailAddress"
                                [control]="registrationForm.get('sepaEmail')"></cx-form-errors>
              </label>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend8' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <div class="form-group mb-2 pb-2">
                <span
                  class="label-content">{{'efaRegistrationForm.deliveryCosts.label' | cxTranslate}}
                  *</span>
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="deliveryCosts"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.deliveryCosts.confirmation' | cxTranslate }}
                  </span>
                </label>

                <cx-form-errors prefix="efaRegistrationForm.formErrors.deliveryCosts"
                                [control]="registrationForm.get('deliveryCosts')"></cx-form-errors>
              </div>

              <div class="form-group mb-2 pb-2">
                <span
                  class="label-content">{{'efaRegistrationForm.deliveryPerNoxNightExpress.label' | cxTranslate}}
                  *</span>
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="deliveryPerNoxNightExpress"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.deliveryPerNoxNightExpress.confirmation' | cxTranslate }}
                  </span>
                </label>

                <cx-form-errors prefix="efaRegistrationForm.formErrors.deliveryPerNoxNightExpress"
                                [control]="registrationForm.get('deliveryPerNoxNightExpress')"></cx-form-errors>
              </div>

              <div class="form-group mb-2 pb-2">
                <span
                  class="label-content">{{ 'efaRegistrationForm.noxDepotAvailable.label' | cxTranslate }}
                  *</span>
                <div class="d-inline-flex flex-row flex-wrap mb-3">
                  <label class="form-check form-check-inline m-0 mr-4">
                    <input type="radio"
                           formControlName="noxDepotAvailable"
                           name="noxDepotAvailable"
                           [value]="true" (change)="noxDepotAvailableChange()"/>
                    {{ 'efaRegistrationForm.noxDepotAvailable.option1' | cxTranslate }}
                  </label>
                  <label class="form-check form-check-inline m-0 mr-4">
                    <input type="radio"
                           formControlName="noxDepotAvailable"
                           name="noxDepotAvailable"
                           [value]="false" (change)="noxDepotAvailableChange()"/>
                    {{ 'efaRegistrationForm.noxDepotAvailable.option2' | cxTranslate }}
                  </label>
                </div>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.noxDepotAvailable"
                                [control]="registrationForm.get('noxDepotAvailable')"></cx-form-errors>
              </div>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.noxCustomerNumber.label' | cxTranslate }}</span>
                <input class="form-control"
                       type="text"
                       placeholder="{{ 'efaRegistrationForm.noxCustomerNumber.placeholder' | cxTranslate }}"
                       formControlName="noxCustomerNumber"/>
                <cx-form-errors
                  [control]="registrationForm.get('noxCustomerNumber')"></cx-form-errors>
              </label>
            </div>
          </fieldset>

          <fieldset class="register_fieldset"
                    *ngIf="registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes?.length > 0">
            <legend
              class="register_fieldset-legend numbered">{{'efaRegistrationForm.legend9' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <ng-container formArrayName="documents">
                <div class="row form-group"
                     *ngFor="let documentFormControl of documentsFormControls; let i=index">
                  <ng-container [formGroupName]="i">
                    <span class="col-lg-4">
                      <span
                        [ngClass]="{'required': registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].required}">
                        <span
                          class="label-content">{{ registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].name }}</span>
                      </span>
                    </span>
                    <div class="col-lg-8">
                      <div class="o-import-file">
                        <div class="o-import-file__input">
                          <div class="custom-file">
                            <input type="file" class="custom-file-input"
                                   formControlName="file"
                                   (change)="fileChanged($event.target.files, registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].code, i)">
                            <label attr.data-content="{{ 'uploadPdfFile.search' | cxTranslate }}"
                                   class="custom-file-label"
                                   id="{{registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].code}}">
                              <cx-icon [type]="iconTypes.PDF"></cx-icon>
                              {{ 'uploadPdfFile.placeholder' | cxTranslate }}
                            </label>
                          </div>
                        </div>
                        <div class="o-import-file__remove">
                          <cx-icon class="u-pointer" [type]="iconTypes.DELETE"
                                   (click)="removeSelectedFile(registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].code, i)"></cx-icon>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <cx-form-errors prefix="efaRegistrationForm.formErrors.uploadPdfFile.{{ registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[i].code }}"
                                      [control]="documentFormControl.get('file')"></cx-form-errors>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend small">{{'efaRegistrationForm.legend10' | cxTranslate}}</legend>

            <div class="register_fieldset-content">
              <div class="form-group">
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="newsletter" (change)="subscribeToNewsletterChange()"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.newsletter' | cxTranslate }}
                  </span>
                  <cx-form-errors [control]="registrationForm.get('newsletter')"></cx-form-errors>
                </label>
              </div>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.newsletterEmailAddress.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.newsletterEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="newsletterEmail"/>
                <cx-form-errors
                  [control]="registrationForm.get('newsletterEmail')"></cx-form-errors>
              </label>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend small">{{'efaRegistrationForm.legend11' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <div class="form-group" formArrayName="attentionReasonInfos">
                <div class="d-flex flex-column">
                  <ng-container
                    *ngFor="let attentionReasonInfosFormControl of attentionReasonInfosFormControls; let i=index">
                    <ng-container [formGroupName]="i">
                      <div class="row">
                        <label class="form-check form-check-inline col-5 col-sm-2 ml-3 mb-2 mt-2 text-nowrap">
                          <input type="checkbox" formControlName="attentionReason"/>
                          {{ registrationInformationMultiOptionsFieldsValues.attentionReasons[i].name }}
                        </label>
                        <input *ngIf="attentionReasonInfosFormControl.get('attentionReasonInfo')"
                               type="text"
                               class="form-control col-5 col-sm-7 mb-0 pt-1 pb-1"
                               formControlName="attentionReasonInfo"
                               placeholder="{{registrationInformationMultiOptionsFieldsValues.attentionReasons[i].additionalInfoName}}"/>
                        <cx-form-errors
                          [control]="attentionReasonInfosFormControl.get('attentionReason')"></cx-form-errors>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend">{{'efaRegistrationForm.legend12' | cxTranslate}}</legend>

            <div class="register_fieldset-content">
              <div class="form-group">
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="termsAndConditions"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.confirmThatRead1' | cxTranslate }}&nbsp;
                      <a [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl"
                         target="_blank">
                      {{ 'efaRegistrationForm.termsAndConditions' | cxTranslate }}
                    </a>
                      &nbsp;{{ 'efaRegistrationForm.confirmThatRead2' | cxTranslate }}
                  </span>
                </label>

                <cx-form-errors prefix="efaRegistrationForm.formErrors.termsAndConditions"
                                [control]="registrationForm.get('termsAndConditions')"></cx-form-errors>
              </div>

              <div class="form-group">
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="privacyProtection"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.confirmPrivacyProtection1' | cxTranslate }}&nbsp;
                      <a [routerLink]="{ cxRoute: 'privacyProtection' } | cxUrl"
                         target="_blank">
                      {{ 'efaRegistrationForm.privacyProtection' | cxTranslate }}
                    </a>
                      &nbsp;{{ 'efaRegistrationForm.confirmPrivacyProtection2' | cxTranslate }}
                  </span>
                </label>

                <cx-form-errors prefix="efaRegistrationForm.formErrors.privacyProtection"
                                [control]="registrationForm.get('privacyProtection')"></cx-form-errors>
              </div>
            </div>
          </fieldset>

          <div class="d-flex flex-row justify-content-end mb-5">
            <div class="col-12 col-md-3">
              <button type="submit" class="btn btn-block btn-primary">
                <cx-icon [type]="iconTypes.CARET_RIGHT"></cx-icon>
                {{ 'efaRegistrationForm.registerButton' | cxTranslate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
