import { Component } from '@angular/core';
import {ConsentManagementCustomModule} from "../../consent-management-custom.module";
import {
  AnonymousConsentDialogComponent,
  IconModule,
  KeyboardFocusModule,
  SpinnerModule
} from "@spartacus/storefront";
import {AsyncPipe} from "@angular/common";
import {I18nModule} from "@spartacus/core";

@Component({
  selector: 'app-efa-anonymous-consent-dialog',
  templateUrl: './efa-anonymous-consent-dialog.component.html'
})
export class EfaAnonymousConsentDialogComponent extends AnonymousConsentDialogComponent{

}
