import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RoutingService } from '@spartacus/core';

import * as DeliveryNotesDetailsFeature from '../../delivery-notes/store/reducers/delivery-notes-details.reducer';
import * as DeliveryNotesDetailsSelectors from '../../delivery-notes/store/selectors/delivery-notes-details.selectors';
import { DeliveryNotesDetailsActions } from '../../delivery-notes/store/actions';
import { DeliveryNoteDetailsResponse } from '../../delivery-notes/model/delivery-notes.model';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNotesDetailsService {
  private deliveryNoteNumber$: Observable<string>;

  constructor(
    protected store: Store<DeliveryNotesDetailsFeature.DeliveryNotesDetailsState>,
    protected routingService: RoutingService,
  ) {
    this.deliveryNoteNumber$ = this.routingService.getRouterState().pipe(
      map((routingData) => routingData.state.params.deliveryNoteNumber),
      distinctUntilChanged()
    );
  }

  loadDeliveryNotesDetails(deliveryNoteNumber: string, returnablePositions: boolean, download: boolean): void {
    this.store.dispatch(DeliveryNotesDetailsActions.loadDeliveryNoteDetails({deliveryNoteNumber, returnablePositions, download}));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(DeliveryNotesDetailsSelectors.selectLoading);
  }


  reset(): void {
    return this.store.dispatch(DeliveryNotesDetailsActions.resetDeliveryNoteDetails());
  }

  getDeliveryNotesDetailsResponse(returnablePositions: boolean, download: boolean): Observable<DeliveryNoteDetailsResponse> {
    return this.store.select(DeliveryNotesDetailsSelectors.selectResponse)
      .pipe(
        withLatestFrom(this.deliveryNoteNumber$),
        tap(([resp, dnNumber]) => {
          if (resp === undefined && dnNumber !== undefined) {
            this.loadDeliveryNotesDetails(dnNumber, returnablePositions, download);
          } else if (dnNumber === undefined && resp === undefined) {
            this.reset();
          }
        }),
        map(([resp, dnNumber]) => resp));
  }

   getDeliveryNoteNumber(): Observable<string> {
    return this.deliveryNoteNumber$;
  }
}
