import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RegistrationData } from '../../model/login-register.model';
import { RegistrationStartAdapter } from '../adapters/registration-start.adapter';

@Injectable({ providedIn: 'root' })
export class RegistrationStartConnector {
  constructor(protected adapter: RegistrationStartAdapter){}

  registrationStart(request: RegistrationData): Observable<void> {
    return this.adapter.loadRegistrationStart(request);
  }
}
