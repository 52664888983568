<table *ngIf="hasHeader" class="table table--responsive mb-0">
  <thead class="c-table-head-text">
  <tr>
    <th scope="col" class="text-left">{{ 'cartItems.item' | cxTranslate }}</th>
    <th *ngIf="isOrderDetails">{{ 'cartAdministration.cartItems.status' | cxTranslate }}</th>
    <th scope="col">{{ 'cartItems.quantity' | cxTranslate }}</th>
    <th scope="col">{{ 'cartAdministration.cartItems.available' | cxTranslate }}</th>
    <th scope="col">{{ 'cartAdministration.cartItems.deliveryDate' | cxTranslate }}</th>
    <th scope="col">{{ 'cartAdministration.cartItems.warehouse' | cxTranslate }}</th>
    <th *ngIf="!isOrderDetails">
      <span *ngIf="!fromConfigurator; else itemPriceHeader">{{ 'cartItems.itemPriceRrp' | cxTranslate }}</span>
      <ng-template #itemPriceHeader>
        <span>{{ 'cartItems.itemPrice' | cxTranslate }}</span>
      </ng-template>
    </th>
    <th *ngIf="isOrderDetails">{{ 'cartItems.itemPrice' | cxTranslate }}</th>
    <ng-container *ngIf="options.isSaveForLater; else totalHeader">
      <th scope="col">
        {{ 'saveForLaterItems.stock' | cxTranslate }}
      </th>
    </ng-container>
    <ng-template #totalHeader>
      <th scope="col">
        {{ 'cartItems.total' | cxTranslate }}
      </th>
    </ng-template>
    <th scope="col" colspan="2" *ngIf="!readonly"></th>
  </tr>
  </thead>

  <tbody>
  <ng-container class="cx-item-list-row" *ngFor="let item of items; let i = index; trackBy: trackItemByFn">
    <ng-container *ngIf="getControl(item) | async as control"
                  class="cx-item-list-items"
                  [class.is-changed]="
          featureConfigService?.isLevel('3.1')
            ? control.get('quantity').disabled
            : control.get('quantity').dirty">
      <ng-container *ngIf="!item.subEntry">
        <app-efa-cart-item [ngClass]="{'has-subentries' : !isOrderDetails && item.parent}"
                           [item]="item"
                           [quantityControl]="control.get('quantity')"
                           [readonly]="readonly"
                           [fromConfigurator]="fromConfigurator"
                           [promotionLocation]="promotionLocation"
                           [options]="options"
                           [isOrderDetails]="isOrderDetails"
                           (consignmentChange)="getValue($event, item.entryNumber, 'consignmentInfo', i)"
                           (consignmentEditableChange)="onConsignmentEditableChange($event, item.uniqueIdentifier)"
                           (consignmentFocusedChange)="onConsignmentFocusedChange($event, item.uniqueIdentifier)"
                           (consignmentFormCreated)="onConsignmentFormCreated($event, item.entryNumber, i, item.uniqueIdentifier)"
                           ></app-efa-cart-item>
      </ng-container>

      <ng-container *ngIf="item.subEntries?.length > 0">
        <app-cart-sub-entry [subEntries]="item.subEntries"></app-cart-sub-entry>
      </ng-container>

      <!-- Item Consignment -->
      <div class="consignment-info ml-lg-2"
          *ngIf="(!readonly || options.isSaveForLater) && item.updateable && consignmentEditable.get(item.uniqueIdentifier) && consignmentForm.get(item.uniqueIdentifier)" [formGroup]="consignmentForm.get(item.uniqueIdentifier)">
        <div class="input-block d-flex">
          <input type="text"
                [maxLength]="consignmentInfoAllowedMaxLength"
                formControlName="consignmentInfo"
                appEfaAutoFocus [focus]="consignmentFocused.get(item.uniqueIdentifier)"
                class="form-control mr-2"
                placeholder="{{ 'cartAdministration.cartProductSearchForm.consignmentInfo.placeholder' | cxTranslate }}">
          <cx-icon
            *ngIf="(cartEntryInfosUpdating$| async) && (currentCartEntryNumberUpdating$ | async) === item.entryNumber && (currentCartEntryInfoTypeUpdating$ | async) === 'consignmentInfo'"
            type="SPINNER"></cx-icon>
        </div>
        <cx-form-errors [control]="consignmentForm.get(item.uniqueIdentifier).get('consignmentInfo')"></cx-form-errors>
      </div>

      <div *ngIf="item.consignmentInfo && readonly" class="ml-lg-2">
        <span class="u-font-bold">{{ 'cartAdministration.cartItems.commission' | cxTranslate }}:</span><br>
        <span>{{ item.consignmentInfo }}</span>
      </div>

      <ng-container *ngIf="item.orderInfoFields?.length > 2; else orderInfoFieldsSingle">
        <app-order-info-fields [orderInfoFields]="item.orderInfoFields.slice(0,2)"
                               [readonly]="readonly"
                               [entryNumber]="item.entryNumber"
                               [entryKey]="item.uniqueIdentifier"
                               (OrderInfoFieldValueChange)="getValue($event, item.entryNumber, 'orderInfoFields', i)">
        </app-order-info-fields>
        <app-order-info-fields [orderInfoFields]="item.orderInfoFields.slice(2,item.orderInfoFields.length)"
                               [readonly]="readonly"
                               [entryNumber]="item.entryNumber"
                               [entryKey]="item.uniqueIdentifier"
                               (OrderInfoFieldValueChange)="getValue($event, item.entryNumber, 'orderInfoFields', i)">
        </app-order-info-fields>
        <tr *ngIf="isMandatoryFields(item.orderInfoFields) && !readonly" class="mandatory-message">
          <td colspan="8" class="fields-required">
            {{ 'cartAdministration.orderInfoFields.mandatoryMessage' | cxTranslate }}
          </td>
        </tr>
      </ng-container>
      <ng-template #orderInfoFieldsSingle>
        <app-order-info-fields [orderInfoFields]="item.orderInfoFields"
                               [readonly]="readonly"
                               [entryNumber]="item.entryNumber"
                               [entryKey]="item.uniqueIdentifier"
                               (OrderInfoFieldValueChange)="getValue($event, item.entryNumber, 'orderInfoFields', i)">
        </app-order-info-fields>
        <tr *ngIf="isMandatoryFields(item.orderInfoFields) && !readonly" class="mandatory-message">
          <td colspan="8" class="fields-required">
            {{ 'cartAdministration.orderInfoFields.mandatoryMessage' | cxTranslate }}
          </td>
        </tr>
      </ng-template>
    </ng-container>
  </ng-container>
  </tbody>
</table>

