import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { PDP_CALL_SOURCE } from '@shared/models/shared.model';
import { CmsSearchBoxComponent, CmsService, Page, RoutingService, WindowRef } from '@spartacus/core';
import { CmsComponentData, SearchBoxComponent, SearchBoxComponentService, } from '@spartacus/storefront';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-efa-searchbox',
  templateUrl: './efa-search-box.component.html',
})
export class EfaSearchBoxComponent extends SearchBoxComponent implements OnInit {
  savedQuery = '';
  isSearchboxNotActive$ = new BehaviorSubject<boolean>(true);
  // close input on click out of the component
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.isSearchboxNotActive$.next(true);
    }
  }
  @ViewChild('searchInput') searchInputEl:ElementRef;

  pdpCallSource: PDP_CALL_SOURCE;

  private mySubscription: Subscription = new Subscription();

  constructor(
    searchBoxComponentService: SearchBoxComponentService,
    componentData: CmsComponentData<CmsSearchBoxComponent>,
    winRef: WindowRef,
    routingService: RoutingService,
    private eRef: ElementRef,
    private cmsService: CmsService
  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);
  }
 ngOnInit(): void {
  this.mySubscription.add(
    this.cmsService.getCurrentPage().subscribe((page: Page) => {
      if (page?.pageId === 'search') {
        this.pdpCallSource = PDP_CALL_SOURCE.SEARCH;
      } else {
        this.pdpCallSource = PDP_CALL_SOURCE.CATEGORY;
      }
    })
  );
 }
  saveQuery(query: string): void {
    this.savedQuery = query;
  }
  launchSearchResult(event: UIEvent, query: string): void {
    const squery: string = this.savedQuery;
    if (!squery || squery.trim().length === 0) {
      return;
    }
    this.close(event);
    this.searchBoxComponentService.launchSearchPage(squery);
    this.savedQuery = '';
    this.isSearchboxNotActive$.next(true);
  }
  onCustomSubmit(event: UIEvent): void  {
    this.close(event, true);
    this.launchSearchResult(event, this.savedQuery);
    this.updateChosenWord(this.searchInputEl.nativeElement.value);
    this.searchBoxComponentService.clearResults();
    this.savedQuery = '';
    this.isSearchboxNotActive$.next(true);
  }
  onCommonSubmit(event: UIEvent): void  {
    if (this.savedQuery === '') {
       this.open();
       this.isSearchboxNotActive$.next(!this.isSearchboxNotActive$.value)
       return;
    }

    this.close(event, true);
    this.launchSearchResult(event, this.savedQuery);
    this.updateChosenWord(this.savedQuery);
    this.savedQuery = '';
  }
  getSearchIconClass(searchInputValue: string): string {
    return searchInputValue != null && searchInputValue !== '' ? 'searchbox-filled' : 'searchbox-empty';
  }
}
