import { Component, Input } from '@angular/core';
import { OrderEntry } from '@spartacus/cart/base/root';

@Component({
  selector: 'app-cart-sub-entry',
  templateUrl: './cart-sub-entry.component.html',
})
export class CartSubEntryComponent {
  @Input() subEntries: OrderEntry[];
}
