<div
  class="cx-modal-container modal-dialog-centered"
  [cxFocus]="focusConfig"
  (esc)="closeModal()"
>

  <div class="cx-modal-content">

    <div class="cx-dialog-header modal-header">
      <h3>{{ 'importExport.cartImportDialog.header' | cxTranslate }}</h3>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
    </div>

    <div class="cx-dialog-content modal-body">

      <form [formGroup]="importForm" class="row">
        <label class="form-group col-12">
            <span class="label-content">
              {{ 'importExport.cartImportDialog.manufacturerCodes.label' | cxTranslate }}
            </span>
          <div class="input-group">
            <input class="form-control u-text-ellipsis"
                  type="text"
                  formControlName="manufacturerCodes"
                  placeholder="{{ 'importExport.cartImportDialog.manufacturerCodes.placeholder' | cxTranslate }}"/>
            <div class="input-group-append">
              <button class="btn btn-primary d-flex" type="button" (click)="onOpenManufacturersModal()" [disabled] = "manufacturerSelectionDisabled" >
                <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
              </button>
            </div>
          </div>
          <cx-form-errors [control]="importForm.get('manufacturerCodes')"></cx-form-errors>
        </label>

        <div class="form-group col-12">
          <span class="label-content">
            {{ 'importExport.cartImportDialog.importFile.label' | cxTranslate }}
          </span>
          <div class="o-import-file">
            <div class="o-import-file__input">
              <div class="custom-file">
                <input type="file" formControlName="filename" class="custom-file-input"
                      (change)="fileChanged($event)"/>
                <label attr.data-content="{{ 'uploadFile.search' | cxTranslate }}"
                      class="custom-file-label"
                      id="filename">{{ 'uploadFile.placeholder' | cxTranslate }}</label>
              </div>
            </div>
            <div class="o-import-file__remove">
              <cx-icon class="u-pointer"
                      [type]="iconTypes.RESET"
                      (click)="removeSelectedFile('filename')"></cx-icon>
            </div>
          </div>
          <cx-form-errors [control]="importForm.get('filename')"></cx-form-errors>
          <div class="c-form-errors" *ngIf="importFileError">
            <p>{{importFileError}}</p>
          </div>
        </div>
      </form>

      <app-cart-import-user-manual [importMode]="importMode"></app-cart-import-user-manual>

    </div>

    <div class="cx-dialog-footer modal-footer">
      <button class="btn btn-primary" type="submit"
              (click)="startImport()"
              [disabled]="!importFileValid">
        {{ 'importExport.cartImportDialog.buttonSearch' | cxTranslate }}
      </button>
    </div>

  </div>

</div>
