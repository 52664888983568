<div ngbAccordion #acc="ngbAccordion">
  <div ngbAccordionItem="shippingBacklogFilterPanelOne" #shippingBacklogFilterPanelOne="ngbAccordionItem">
    <div ngbAccordionHeader>
      <div class="justify-content-between">
        <h5 class="mb-0">
          <button ngbAccordionToggle class="btn btn-block btn-filter">
            <cx-icon [type]="iconTypes.BROWSE" class="mr-2">
              <span class="btn-filter-text">{{ 'shippingBacklog.myAccount.filter' | cxTranslate }}</span>
            </cx-icon>
          </button>
        </h5>
      </div>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <form [formGroup]="shippingBacklogOverviewFilterForm" class="">
            <div class="row">
  
              <div class="col-md-4">
                <label class="form-group">
                    <span class="label-content">
                        {{ 'shippingBacklog.shippingBacklogFilterForm.orderNumber.label' | cxTranslate }}
                    </span>
                    <input class="form-control" type="text" formControlName="orderNumber"
                        (keydown.enter)="triggerOnChanges('orderNumber', $event)"
                        placeholder="{{ 'shippingBacklog.shippingBacklogFilterForm.orderNumber.placeholder' | cxTranslate }}" />
                </label>
            </div>
  
            <div class="col-md-4">
                <label class="form-group">
                    <span class="label-content">
                        {{ 'shippingBacklog.shippingBacklogFilterForm.oemNumber.label' | cxTranslate }}
                    </span>
                    <input class="form-control" type="text" formControlName="oemNumber"
                        (keydown.enter)="triggerOnChanges('oemNumber', $event)"
                        placeholder="{{ 'shippingBacklog.shippingBacklogFilterForm.oemNumber.placeholder' | cxTranslate }}" />
                </label>
            </div>
  
            <div class="col-md-4">
                <label class="form-group">
                    <span class="label-content">
                        {{ 'shippingBacklog.shippingBacklogFilterForm.consignmentInfo.label' | cxTranslate }}
                    </span>
                    <input class="form-control" type="text" formControlName="consignmentInfo"
                        (keydown.enter)="triggerOnChanges('consignmentInfo', $event)"
                        placeholder="{{ 'shippingBacklog.shippingBacklogFilterForm.consignmentInfo.placeholder' | cxTranslate }}" />
                </label>
            </div>
            </div>
            <div class="row align-items-end">
              <div class="col-md-4">
                <label class="form-group">
                    <span class="label-content">
                        {{ 'shippingBacklog.shippingBacklogFilterForm.dateFrom.placeholder' | cxTranslate }}
                    </span>
                    <div class="input-group">
                        <input class="form-control" ngbDatepicker #df="ngbDatepicker" formControlName="dateFrom"
                            [maxDate]="maxDate"
                            [readonly]="true"
                            efaNgbDatepickerNavigationTooltipsRemove
                            placeholder="{{ 'shippingBacklog.shippingBacklogFilterForm.dateFrom.placeholder' | cxTranslate }}"
                            container="body"/>
                        <div class="input-group-append">
                            <button class="btn btn-primary d-flex" (click)="df.toggle()" type="button">
                                <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                            </button>
                        </div>
                    </div>
                </label>
            </div>
  
            <div class="col-md-4">
                <label class="form-group">
                    <span class="label-content">
                        {{ 'shippingBacklog.shippingBacklogFilterForm.dateTo.placeholder' | cxTranslate }}
                    </span>
                    <div class="input-group">
                        <input class="form-control" ngbDatepicker #dt="ngbDatepicker" formControlName="dateTo"
                            [maxDate]="maxDate"
                            [readonly]="true"
                            efaNgbDatepickerNavigationTooltipsRemove
                            placeholder="{{ 'shippingBacklog.shippingBacklogFilterForm.dateTo.placeholder' | cxTranslate }}"
                            container="body"/>
                        <div class="input-group-append">
                            <button class="btn btn-primary d-flex" (click)="dt.toggle()" type="button">
                                <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                            </button>
                        </div>
                    </div>
                </label>
            </div>
            <div class="form-group col-md-4 col-sm-12 col-xs-12 ">
              <button type="button" (click)="clearForm()"  style="color: #003899;" class="btn btn-block btn-secondery">{{ 'clearForm' | cxTranslate }}</button>
            </div>
            </div>
          </form>
          <!-- <div class="row justify-content-end">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <button type="button" (click)="clearForm()"  style="color: #003899;" class="btn btn-block btn-secondery">{{ 'clearForm' | cxTranslate }}</button>
              </div>
          </div> -->
        </ng-template>
      </div>
    </div>
  </div>
</div>
