import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GlobalMessageType} from '@spartacus/core';
import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {WarrantyProcessConnector} from '../../occ/connectors/warranty-process.connector';
import {WarrantyProcessActions} from '../actions';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class WarrantyProcessEffect {

  startWarrantyProcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WarrantyProcessActions.startWarrantyProcess),
      concatMap((action) =>
        this.connector.startWarrantyProcess(action.request).pipe(
          map(() => {
            return WarrantyProcessActions.startWarrantyProcessSuccess();
          }),
          catchError((error) => {
            this.globalMessageService.add(
              {
                key: 'warrantyProcess.globalMessage.startWarrantyProcessFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(WarrantyProcessActions.startWarrantyProcessFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: WarrantyProcessConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {

  }
}
