import { Injectable } from '@angular/core';
import { CheckoutDeliveryModesService } from '@spartacus/checkout/base/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EfaCheckoutDeliveryService extends CheckoutDeliveryModesService {

  override clearCheckoutDeliveryMode(): Observable<unknown>
  {
   console.log('Do not reset delivery parameters in cart after changing default delivery address'); // do nothing
   return null;
  }
}
