<ng-container *ngIf="tileItem as item">
  <div *ngIf="item.active === 'false'" class="inactiveItemContainer">
    <div class="image-box">
      <img src="../../../../../../assets/icons/Maintenance.png" alt="">
    </div>
    <div class="inactiv-item-text-wrapper">
      <div *ngIf="(item.inActiveTitle | json) != '{}'"
           class="text-title">{{ item.inActiveTitle }}</div>
      <div *ngIf="(item.inActiveText | json) != '{}'"
           class="text-description">{{item.inActiveText}}</div>
    </div>
  </div>
  <div class="tile-item-container {{ item.hideBorder === 'true' ? 'no-border' : '' }} {{ item.styleClasses ? item.styleClasses : ''}}"
       [ngStyle]="{'filter':(item.active === 'false') ? 'opacity(20%) blur(12px)' : 'none' }">
    <div class="tile-item-image  {{ item.styleClasses ? item.styleClasses : ''}}">
      <cx-media [container]="item.medias"></cx-media>
    </div>

    <div class="tile-item-text-wrapper">
      <div class="tile-item-title" [ngClass]="{'title-has-subtitle': item.subtitle}">
        {{ item.title }}
      </div>
      <div *ngIf="item.subtitle" class="tile-item-subtitle" [innerHTML]="item.subtitle">
      </div>
      <div class="tile-item-description">
        {{ item.description }}
      </div>
    </div>

    <div class="tile-item-link">
      <button class="btn btn-primary btn-block {{item.buttonStyle ?? ''}}" (click)="connect()">
        {{ item.buttonText }}
      </button>
    </div>
  </div>
</ng-container>
