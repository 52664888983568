import {Injectable, OnDestroy} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {GlobalMessageService, GlobalMessageType, StateWithGlobalMessage} from '@spartacus/core';
import {Translatable} from '@spartacus/core/src/i18n/translatable';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EfaGlobalMessageService extends GlobalMessageService implements OnDestroy {
  protected subscription: Subscription = new Subscription();

  constructor(protected store: Store<StateWithGlobalMessage>, protected router: Router) {
    super(store);
    this.subscription.add(this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.getCurrentNavigation().extras.state?.messageTypesForRemoval !== undefined) {
          this.removeByTypes(this.router.getCurrentNavigation().extras.state?.messageTypesForRemoval);
        } else {
          this.removeAll();
        }
      }
    }));
  }

  add(text: string | Translatable, type: GlobalMessageType, timeout?: number): void {
    super.remove(type);
    super.add(text, type, timeout);
  }


  removeByTypes(types: GlobalMessageType[]): void {
    types.forEach(type => this.remove(type));
  }

  removeAll(): void {
    this.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);
    this.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.remove(GlobalMessageType.MSG_TYPE_INFO);
    this.remove(GlobalMessageType.MSG_TYPE_WARNING);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
