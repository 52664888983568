import {Injectable} from '@angular/core';
import {TranslationService} from '@spartacus/core';
import {OrderType} from '../model/cart-administration.model';
import {DeliveryMode} from '@spartacus/cart/base/root';


const ORDER_TYPE_ZTA_CODE = 'ZTA';
const ORDER_TYPE_ZTA = {id: ORDER_TYPE_ZTA_CODE, name: '---'};

const SHIPPING_TYPE_PARCEL_SERVICE_CODE = '2';
const SHIPPING_TYPE_PARCEL_SERVICE = {code: SHIPPING_TYPE_PARCEL_SERVICE_CODE, name: '---'};

@Injectable({
  providedIn: 'root'
})
export class CartHeaderSupportService {

  constructor(private translationService: TranslationService) {
  }

  getForwardOrderType(): OrderType {
    this.translationService.translate('cartAdministration.orderTypeSelect.forwardOrder').subscribe(val =>
      ORDER_TYPE_ZTA.name = val).unsubscribe();
    return ORDER_TYPE_ZTA;
  }

  getForwardOrderTypeCode(): string {
    return ORDER_TYPE_ZTA_CODE;
  }

  getParcelServiceShippingType(): DeliveryMode {
    this.translationService.translate('cartAdministration.orderDataForm.shippingType.parcelService').subscribe(val =>
      SHIPPING_TYPE_PARCEL_SERVICE.name = val).unsubscribe();
    return SHIPPING_TYPE_PARCEL_SERVICE;
  }

  getParcelServiceShippingTypeCode(): string {
    return SHIPPING_TYPE_PARCEL_SERVICE_CODE;
  }
}
