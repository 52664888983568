import {Injectable} from '@angular/core';

import {Address, B2BUser} from '@spartacus/core';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from 'rxjs';

import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActiveCartFacade, Cart, DeliveryMode} from '@spartacus/cart/base/root';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {Manufacturer, ManufacturerGroup, OrderType} from '../model/cart-administration.model';
import {UpdateCartArg} from '../model/update-cart.model';
import {CartParametersService} from './cart-parameters.service';
import {UpdateCartService} from './update-cart.service';
import {WishListFacade} from '@spartacus/cart/wish-list/root';

@Injectable({providedIn: 'root'})
export class B2BUserAccountService {

  private _cartId: string;
  private _uid: string;
  private _orderTypes: OrderType[];
  private _defaultOrderType: OrderType;
  private _billingAddress: Address;
  private _shippingAddress: Address;
  private _shippingAddresses: Address[];
  private _shippingTypes: DeliveryMode[];
  private _defaultShippingType: DeliveryMode;
  private _alternativesSearchAllowed: boolean;
  private _visibleManufacturers: Manufacturer[] = [];
  private _visibleManufacturerGroups: ManufacturerGroup[] = [];
  private _defaultManufacturers: Manufacturer[] = [];
  private _cart: Cart;
  private selectedManufacturers$$: BehaviorSubject<Manufacturer[]>;
  private _userId: string;
  private _returnsDeduction: boolean;
  private b2bOrgUnitLoaded$$: ReplaySubject<void> = new ReplaySubject();
  private activeCartIdChanged = false;
  private subscription: Subscription = new Subscription();
  private _customerId: string;

  constructor(
    private activeCartService: ActiveCartFacade,
    private userAccountFacade: UserAccountFacade,
    private cartParametersService: CartParametersService,
    private updateCartService: UpdateCartService,
    private wishListService: WishListFacade
  ) {

    this._loadCartId();
    this._loadB2BOrgUnit();
    this._loadWishlist();
    this._loadCart();
    this.selectedManufacturers$$ = new BehaviorSubject(undefined);
  }

  private _loadCartId(): void {
    this.activeCartService.getActiveCartId()
    .pipe(
      distinctUntilChanged((c1, c2) => c1 === c2),
      filter(Boolean))
    .subscribe((cartId: string) => {
      this._cartId = cartId;
      console.log('new cart id', cartId);
      this.activeCartIdChanged = true;
    });
  }

  private updateCartWithDefaultValues(cartId: string): void {
    const ot = this.getDefaultOrderType();
    const addr = this.getDefaultShippingAddress();
    const st = this.getDefaultShippingType();
    console.log('default shipping type', st);
    const data: UpdateCartArg = {
      cartId,
      userId: this.userId,
      orderTypeId: ot?.id,
      shippingTypeId: st?.code,
      addressId: addr?.id,
      requestedDeliveryDate: this.getRequestedDeliveryDateStringFromToday(),
      calculate: false,
      reloadCart: true
    };
    this.updateCartService.updateCart(data);
  }

  getRequestedDeliveryDateStringFromToday(): string {
    const today = new Date();
    return today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  }

  private _loadCart(): void {
    this.activeCartService.getActive()
    .subscribe((cart: Cart) => {
      this._cart = cart;
      if (cart && Object.keys(cart).length > 0) {
        if (this.activeCartIdChanged && this._uid !== undefined && cart.entries?.length === 0) {
          this.updateCartWithDefaultValues(cart.code);
        } else {
          this.cartParametersService.setRequestedDeliveryDate(this.getCurrentRequestedDeliveryDateStruct());
          this.cartParametersService.setCurrentOrderType(this.getCurrentOrderType());
          this.cartParametersService.setDeliveryAddress(this.getCurrentShippingAddress());
          this.cartParametersService.setShippingTypeId(this.getCurrentShippingType());
        }
        this.activeCartIdChanged = false;
      }
    });
  }

  private _loadWishlist(): void {
    console.log('wishlist check');
    this.wishListService.getWishList()
    .subscribe();
  }

  private _loadB2BOrgUnit(): void {
    this.userAccountFacade.get().subscribe((b2bUser: B2BUser) => {
      if (b2bUser && b2bUser.orgUnit) {
        const validCart = this.cartId && this.cart && Object.keys(this.cart).length > 0;
        this._uid = b2bUser.orgUnit.uid;
        this._userId = b2bUser.uid;
        this._customerId = b2bUser.customerId;
        this._billingAddress = b2bUser.orgUnit.billingAddress;
        this._visibleManufacturers = b2bUser.orgUnit.visibleManufacturers;
        this._visibleManufacturerGroups = b2bUser.orgUnit.visibleManufacturerGroups;
        if (b2bUser.defaultManufacturers) {
          this._defaultManufacturers = b2bUser.defaultManufacturers;
          this._defaultManufacturers = this._defaultManufacturers.sort((a, b) => a.name.localeCompare(b.name));
        }
        this._alternativesSearchAllowed = b2bUser.orgUnit.alternativesSearchAllowed;
        this._returnsDeduction = b2bUser.orgUnit.returnsDeduction;
        this.setOrderTypes(b2bUser, validCart);
        this.setShippingAddresses(b2bUser, validCart);
        this.setShippingTypes(b2bUser, validCart);
        if (!validCart) {
          this.subscription.add(this.activeCartService.requireLoadedCart()
          .subscribe(() => console.log('load b2bunit: require loaded cart')));
        } else {
          this.subscription.unsubscribe();
        }
        this.b2bOrgUnitLoaded$$.next();
      }
    });
  }

  private setShippingTypes(b2bUser: B2BUser, validCart: boolean): void {
    this._defaultShippingType = b2bUser.orgUnit.defaultShippingType;
    this._shippingTypes = b2bUser.orgUnit.shippingTypes;
    if (!validCart) {
      this.cartParametersService.setShippingTypeId(this.getDefaultShippingType());
    }
  }

  private setOrderTypes(b2bUser: B2BUser, validCart: boolean): void {
    this._defaultOrderType = b2bUser.orgUnit.defaultOrderType;
    this._orderTypes = b2bUser.orgUnit.orderTypes;
    if (!validCart) {
      this.cartParametersService.setCurrentOrderType(this.getDefaultOrderType());
    }
  }

  private setShippingAddresses(b2bUser: B2BUser, validCart: boolean): void {
    this._shippingAddress = b2bUser.defaultAddress;
    this._shippingAddresses = b2bUser.orgUnit.shippingAddresses;
    if (!validCart) {
      this.cartParametersService.setDeliveryAddress(this.getDefaultShippingAddress());
    }
  }

  getDefaultShippingTypeFromAddress(shippingAddress: Address): DeliveryMode {
    if (shippingAddress && shippingAddress.defaultShippingType !== undefined) {
      return shippingAddress.defaultShippingType;
    } else if (shippingAddress && shippingAddress.shippingTypes?.length > 0) {
      return shippingAddress.shippingTypes[0];
    } else if (this.defaultShippingType !== undefined) {
      return this.defaultShippingType;
    } else if (this.shippingTypes?.length > 0) {
      return this.shippingTypes[0];
    }
    return null;
  }

  getDefaultShippingType(): DeliveryMode {
    const shippingAddress = this.getDefaultShippingAddress();
    return this.getDefaultShippingTypeFromAddress(shippingAddress);
  }

  getDefaultShippingAddress(): Address {
    if (this.shippingAddress) {
      return this.shippingAddress;
    } else if (this.shippingAddresses?.length > 0) {
      return this.shippingAddresses[0];
    }
    return null;
  }

  getCurrentOrderType(): OrderType {
    if (this.cart?.orderType !== undefined) {
      return this.cart?.orderType;
    } else {
      return this.getDefaultOrderType();
    }
  }

  getCurrentShippingAddress(): Address {
    if (this.cart?.deliveryAddress !== undefined) {
      return this.cart?.deliveryAddress;
    } else {
      return this.getDefaultShippingAddress();
    }
  }

  getCurrentShippingType(): DeliveryMode {
    const shippingAddress = this.getCurrentShippingAddress();
    if (this.cart?.deliveryMode !== undefined) {
      return this.cart?.deliveryMode;
    } else {
      return this.getDefaultShippingTypeFromAddress(shippingAddress);
    }
  }

  getDefaultOrderType(): OrderType {
    if (this.defaultOrderType !== undefined) {
      return this.defaultOrderType;
    } else if (this.orderTypes?.length > 0) {
      return this.orderTypes[0];
    }
    return null;
  }


  getCurrentRequestedDeliveryDateString(): string {
    if (this.cart?.requestedDeliveryDate) {
      return this.cart?.requestedDeliveryDate;
    } else {
      return this.getRequestedDeliveryDateStringFromToday();
    }
  }

  getCurrentRequestedDeliveryDate(): Date {
    return new Date(this.getCurrentRequestedDeliveryDateString());
  }

  getCurrentRequestedDeliveryDateStruct(): NgbDateStruct {
    const d = this.getCurrentRequestedDeliveryDate();
    return {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};

  }

  get cartId(): string {
    return this._cartId;
  }

  get cart(): Cart {
    return this._cart;
  }

  get uid(): string {
    return this._uid;
  }

  get customerId(): string {
    return this._customerId;
  }

  get userId(): string {
    return this._userId;
  }

  get defaultOrderType(): OrderType {
    return this._defaultOrderType;
  }

  get orderTypes(): OrderType[] {
    return this._orderTypes;
  }

  get billingAddress(): Address {
    return this._billingAddress;
  }

  get shippingAddress(): Address {
    return this._shippingAddress;
  }

  set shippingAddress(value: Address) {
    this._shippingAddress = value;
  }

  get shippingAddresses(): Address[] {
    return this._shippingAddresses;
  }

  get shippingTypes(): DeliveryMode[] {
    return this._shippingTypes;
  }

  get alternativesSearchAllowed(): boolean {
    return this._alternativesSearchAllowed;
  }

  get defaultManufacturers(): Manufacturer[] {
    return this._defaultManufacturers;
  }

  get visibleManufacturers(): Manufacturer[] {
    return this._visibleManufacturers;
  }

  get visibleManufacturerGroups(): ManufacturerGroup[] {
    return this._visibleManufacturerGroups;
  }

  get defaultManufacturerNames(): string [] {
    if (this.defaultManufacturers === undefined) {
      return [];
    }
    return this.defaultManufacturers.map(item => item.name);
  }

  get returnsDeduction(): boolean {
    return this._returnsDeduction;
  }

  get defaultShippingType(): DeliveryMode {
    return this._defaultShippingType;
  }

  setSelectedManufacturer(manufacturers: Manufacturer[]): void {
    this.selectedManufacturers$$.next(manufacturers || []);
  }

  getSelectedManufacturers(): Observable<Manufacturer[]> {
    return this.selectedManufacturers$$.asObservable();
  }

  getB2bOrgUnitLoaded(): Observable<void> {
    return this.b2bOrgUnitLoaded$$.asObservable();
  }
}
