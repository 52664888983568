import {Injectable} from '@angular/core';
import {BasePageMetaResolver, BreadcrumbMeta, ContentPageMetaResolver, TranslationService, } from '@spartacus/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CustomContentPageMetaResolver extends ContentPageMetaResolver {
  constructor(
    protected basePageMetaResolver: BasePageMetaResolver,
    protected translationService: TranslationService,
    protected location: Location
  ) {
    super(basePageMetaResolver);
  }

  baseSite$ = this.translationService.translate('categoryInformation.pageMetaResolver.baseSite.name');


  resolveTitle(): Observable<string> {
    const obs = combineLatest([this.baseSite$, this.basePageMetaResolver.resolveTitle()]);
    return obs.pipe(
      map(([baseSite, title]) => baseSite + ' - ' + title));
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {

    console.log(this.location.path());
    const breadcrumbs: BreadcrumbMeta[] = [];
    if (this.location.path().includes('/help/')) {
      this.translationService.translate('categoryInformation.pageMetaResolver.help.name').subscribe(label =>
        breadcrumbs.push({
          label,
          link: '/',
        }));
    } else if (this.location.path().includes('/download-customerinfo/')) {
      this.translationService.translate('categoryInformation.pageMetaResolver.customerInfo.name').subscribe(label =>
        breadcrumbs.push({
          label,
          link: '/',
        }));
    } else if (this.location.path().includes('/faq/')) {
      this.translationService.translate('categoryInformation.pageMetaResolver.customerInfo.name').subscribe(label =>
        breadcrumbs.push({
          label,
          link: '/',
        }));
    } else if (this.location.path().includes('/organization/')) {
      this.translationService.translate('categoryInformation.pageMetaResolver.myAccount.name').subscribe(label =>
        breadcrumbs.push({
          label,
          link: '/',
        }));
      if (this.location.path().includes('/users') || this.location.path().includes('/user-groups')) {
        this.translationService.translate('categoryInformation.pageMetaResolver.myAccount.userManagement.name').subscribe(label =>
          breadcrumbs.push({
            label,
            link: '/',
          }));
      }
    } else if (this.location.path().includes('/my-account/')) {
      this.translationService.translate('categoryInformation.pageMetaResolver.myAccount.name').subscribe(label =>
        breadcrumbs.push({
          label,
          link: '/',
        }));
      if (this.location.path().includes('/profile/')) {
        this.translationService.translate('categoryInformation.pageMetaResolver.myAccount.profile.name').subscribe(label =>
          breadcrumbs.push({
            label,
            link: '/',
          }));
      } else if (this.location.path().includes('/favorites/')) {
        this.translationService.translate('categoryInformation.pageMetaResolver.myAccount.favorites.name').subscribe(label =>
          breadcrumbs.push({
            label,
            link: '/',
          }));
      } else if (this.location.path().includes('/documents/')) {
        this.translationService.translate('categoryInformation.pageMetaResolver.myAccount.documents.name').subscribe(label =>
          breadcrumbs.push({
            label,
            link: '/',
          }));
      } else if (this.location.path().includes('/forms/')) {
        this.translationService.translate('categoryInformation.pageMetaResolver.myAccount.forms.name').subscribe(label =>
          breadcrumbs.push({
            label,
            link: '/',
          }));
      }

    }

    return of(breadcrumbs);
  }
}
