<ng-container *ngFor="let subEntry of subEntries">
  <!-- subEntry Description -->
  <td attr.data-label="{{ 'cartItems.item' | cxTranslate }}">
    <div *ngIf="subEntry.product.name" class="cx-name">
      {{ subEntry.product.name }}
    </div>
  </td>
  
  <td class="col-empty"></td>
  <td class="col-empty"></td>
  <td class="col-empty"></td>
  <td class="col-empty"></td>

  <!-- subEntry Price -->
  <td *ngIf="subEntry.customerBasePrice" attr.data-label="{{ 'cartItems.itemPrice' | cxTranslate }}" class="align-center">
    <div *ngIf="subEntry.customerBasePrice" class="cx-value">
      {{ subEntry.customerBasePrice?.formattedValue }}
    </div>
  </td>
  <!-- subEntry Total -->
  <td *ngIf="subEntry.customerTotalPrice" attr.data-label="{{ 'cartItems.total' | cxTranslate }}" class="align-center">
    <div class="cx-value">
      {{ subEntry.customerTotalPrice?.formattedValue }}
    </div>
  </td>
  <td class="col-empty"></td>
</ng-container>
