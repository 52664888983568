import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ShippingBacklogOverviewRequest, ShippingBacklogOverviewResponse } from '../model/shipping-backlog.model';
import * as ShippingBacklogOverviewFeature from '../store/reducers/shipping-backlog-overview.reducer';
import * as ShippingBacklogOverviewSelectors from '../store/selectors/shipping-backlog-overview.selectors';
import * as ShippingBacklogOverviewActions from '../store/actions/shipping-backlog-overview.actions';

@Injectable({
  providedIn: 'root',
})
export class ShippingBacklogOverviewService {
  constructor(
    protected store: Store<ShippingBacklogOverviewFeature.ShippingBacklogOverviewState>
  ) {}

  loadOverview(request: ShippingBacklogOverviewRequest): void {
    this.store.dispatch(ShippingBacklogOverviewActions.loadShippingBacklogOverview({ request }));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(
      ShippingBacklogOverviewSelectors.selectLoading
    );
  }

  success(): Observable<boolean> {
    return this.store.select(ShippingBacklogOverviewSelectors.selectSuccess);
  }

  getResponse(): Observable<ShippingBacklogOverviewResponse> {
    return this.store.select(ShippingBacklogOverviewSelectors.selectResponse);
  }

  getRequest(): Observable<ShippingBacklogOverviewRequest> {
    return this.store.select(ShippingBacklogOverviewSelectors.selectRequest);
  }
}
