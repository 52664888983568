import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ICON_TYPE } from '@spartacus/storefront';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {OrderType} from '../../model/cart-administration.model';
import {B2BUserAccountService} from '../../services/b2-b-user-account.service';
import {CartParametersService} from '../../services/cart-parameters.service';
import {CartHeaderSupportService} from '../../services/cart-header-support.service';

@Component({
  selector: 'app-order-type-selection',
  templateUrl: './order-type-selection.component.html',
})
export class OrderTypeSelectionComponent implements OnInit, OnDestroy {
  @Input() myName: string;
  @Input() isLogin: boolean;
  @Input() selectedOrderType: OrderType;
  @Input() fromConfigurator: boolean;
  @Output() orderTypeChanges: EventEmitter<OrderType> = new EventEmitter<OrderType>();

  orderTypes: OrderType[];
  iconTypes = ICON_TYPE;
  orderTypeSelectionForm: UntypedFormGroup = this.fb.group({
    orderTypeCode: ['']
  });

  private subscription: Subscription = new Subscription();

  constructor(
    protected cartParametersService: CartParametersService,
    protected b2bUserAccountService: B2BUserAccountService,
    protected activeCartService: ActiveCartFacade,
    protected fb: UntypedFormBuilder,
    protected cartHeaderSupportService: CartHeaderSupportService
  ) {
  }

  ngOnInit(): void {
    this.orderTypes = [];
    this.b2bUserAccountService.orderTypes.forEach(val => this.orderTypes.push(val));

    if (!this.orderTypes.find(ot => ot.id === this.cartHeaderSupportService.getForwardOrderTypeCode()) && this.fromConfigurator)
    {
      this.orderTypes.push(this.cartHeaderSupportService.getForwardOrderType());
    }

    this.subscription.add(
      this.cartParametersService.getCurrentOrderType()
      .pipe(
        filter(Boolean))
      .subscribe((ot: OrderType) => this.orderTypeSelectionForm.patchValue({
      orderTypeCode: ot.id,
    })));

    if (this.fromConfigurator || this.orderTypes?.length === 1) {
      this.orderTypeSelectionForm.get('orderTypeCode').disable();
    }
  }

  orderTypeChange(ot: OrderType): void {
    this.cartParametersService.setCurrentOrderType(ot);
  }

  orderTypeInFormChange(): void {
    const selectedOrderTypeCode: string = this.orderTypeSelectionForm.get('orderTypeCode').value;
    const orderType: OrderType = this.orderTypes.find((ot: OrderType) => ot.id === selectedOrderTypeCode);
    this.cartParametersService.setCurrentOrderType(orderType);
    this.orderTypeChanges.emit(orderType);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

