import { Component, OnInit } from '@angular/core';
import { Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-efa-product-details-tab',
  templateUrl: './efa-product-details-tab.component.html',
})
export class EfaProductDetailsTabComponent implements OnInit {
  product$: Observable<Product>;
  constructor(protected currentProductService: CurrentProductService){ }

  ngOnInit(): void {
    this.product$ = this.currentProductService.getProduct();
  }
}
