import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FaqOverviewResponse } from '../../model/faq-items.model';
import { FaqOverviewAdapter } from '../adapters/faq-overview.adapter';

@Injectable({
  providedIn: 'root',
})
export class FaqOverviewConnector {
  constructor(protected adapter: FaqOverviewAdapter) {}

  loadOverview(isUserLoggedIn: boolean): Observable<FaqOverviewResponse> {
    return this.adapter.loadOverview(isUserLoggedIn);
  }
}
