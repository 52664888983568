import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SetCartEntryInfosActions } from '../store/actions';
import * as SetCartEntryInfosFeature from '../store/reducers/set-cart-entry-infos.reducer';
import * as SetCartEntryInfosSelectors from '../store/selectors/set-cart-entry-infos.selectors';
import { OrderInfoFieldValue } from '../model/cart-administration.model';

@Injectable({providedIn: 'root'})
export class SetCartEntryInfosService {

  constructor(protected store: Store<SetCartEntryInfosFeature.CartEntryInfosState>) {}

  setCartEntryInfos(
    cartId: string,
    entryNumber: number,
    consignmentInfo: string,
    orderInfoFieldValues: OrderInfoFieldValue[],
    cartEntryInfoType: string,
    entryIndex: number
  ): void {
    // set consignmentInfo from the parameter
    return this.store.dispatch(SetCartEntryInfosActions.setCartEntryInfos({
      cartId,
      entryNumber,
      consignmentInfo,
      orderInfoFieldValues,
      cartEntryInfoType,
      entryIndex: entryIndex
    }))
    ;
  }

  setIsAllManufacturerSelected(isAllSelected: boolean): void {
    return this.store.dispatch(SetCartEntryInfosActions.setIsAllManufacturerSelected({ isAllSelected }));
  }

  getIsUpdating(): Observable<boolean> {
    return this.store.select(SetCartEntryInfosSelectors.selectUpdating);
  }

  getEntryNumber(): Observable<number> {
    return this.store.select(SetCartEntryInfosSelectors.selectEntryNumber);
  }

  getConsignmentInfo(): Observable<string> {
    return this.store.select(SetCartEntryInfosSelectors.selectConsignmentInfo);
  }

  getOrderInfoFieldValues(): Observable<OrderInfoFieldValue[]> {
    return this.store.select(SetCartEntryInfosSelectors.selectOrderInfoFieldValues);
  }

  getIsAllManufacturerSelected(): Observable<boolean> {
    return this.store.select(SetCartEntryInfosSelectors.selectIsAllManufacturerSelected);
  }

  getCartEntryInfoType(): Observable<string> {
    return this.store.select(SetCartEntryInfosSelectors.selectCartEntryInfoType);
  }
}
