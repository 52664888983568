import {TranslationChunksConfig} from '@spartacus/assets';

import { de } from './de/index';
import { en } from './en/index';


export const warrantyProcessTranslations = {
  de,
  en
};

export const warrantyProcessTranslationChunksConfig: TranslationChunksConfig = {
  warrantyProcess: [
    'warrantyProcess',
  ],
};
