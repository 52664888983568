<div class="d-flex flex-column flex-md-row justify-content-end mb-3">
  <div class="d-flex flex-column col-12 col-md-4 p-0">
    <app-efa-order-summary [cart]="cart$ | async"></app-efa-order-summary>
    <div *ngIf="(cart$ | async).fromConfigurator" class="c-configurator-order-tax-notice-container">
      <p class="c-configurator-order-tax-notice">
        <sup>*</sup> {{ 'checkoutOrder.orderCost.noticeConfiguratorTaxAndDeliveryCosts' | cxTranslate }}
      </p>
    </div>
  </div>
</div>

<div class="d-flex flex-column-reverse flex-md-row justify-content-between m-0">
  <div class="col-12 col-md-2 my-2 p-0">
    <button type="button"
          class="btn btn-block btn-secondary btn-margin-top"
          [routerLink]="{ cxRoute: cartRoute } | cxUrl">
      {{ 'common.back' | cxTranslate }}
    </button>
  </div>

  <div class="col-12 col-md-4 my-2 p-0">
    <button type="submit"
            class="btn btn-block btn-primary"
            [disabled]="!(isValidForm$ | async) || deactivatePlaceOrder"
            (click) = "placeOrder()">
      {{ 'checkoutOrder.checkoutReview.placeOrder' | cxTranslate }}
    </button>
  </div>
</div>
