import { AuthActions, SiteContextActions } from '@spartacus/core';

import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { OrderOverviewRequest } from '../../model/order-history.model';

import { ErrorType } from '@shared/models/ErrorTypes';
import { OrderHistoryList } from '@spartacus/order';
import { produce } from 'immer';
import { OrderOverviewActions } from '../actions';

export const ORDER_OVERVIEW_FEATURE_KEY = 'orderOverview';

export interface OrderOverviewState {
  request: OrderOverviewRequest;
  response: OrderHistoryList;
  loading: boolean;
  error: ErrorType;
}

export const initialStateOverview: OrderOverviewState = {
  request: undefined,
  response: undefined,
  loading: false,
  error: undefined
};

export const orderOverviewReducer = createReducer(
  initialStateOverview,

  on(OrderOverviewActions.loadOrderOverview, (state, action) => ({
    ...state,
    loading: true,
    request: action.request,
    response: undefined,
  })),
  on(OrderOverviewActions.loadOrderOverviewSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.response,
    request: undefined
  })),
  on(OrderOverviewActions.loadOrderOverviewFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse
  })),
  on(OrderOverviewActions.resetOrderOverview, (state, action) => (
    initialStateOverview
  ))
);

function authenticationChangedOverview(action: Action, state): any {
  if (action.type === AuthActions.LOGIN ||
    action.type === AuthActions.LOGOUT
  ) {
    return produce(state, (draftState) => {
      draftState.orderHistory.orderOverview = initialStateOverview;
    });
  }
  return state;
}

export function metaReducerFactoryOrderOverview(): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const newState = authenticationChangedOverview(action, state);
    return reducer(newState, action);
  };
}


