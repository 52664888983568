import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GlobalMessageType} from '@spartacus/core';

import {OrderCancelActions} from '../actions';
import {OrderCancelConnector} from '../../occ/connectors/order-cancel.connector';
import {OrderCancelResponse} from '../../model/order-cancel.model';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class OrderCancelEffect {

  doOrderCancellation$ = createEffect(() => {
    return this.action$.pipe(
      ofType(OrderCancelActions.orderCancellation),
      concatMap((action) =>
        this.connector.doOrderCancellation(action.orderNumber, action.positions).pipe(
          map((orderCancelResponse: OrderCancelResponse) => {
            return OrderCancelActions.orderCancellationSuccess({
              response: orderCancelResponse
            });
          }),
          catchError((error) => {
            this.globalMessageService.add(
              {
                key: 'orderCancellation.globalMessage.orderCancellationFailure',
                params: {orderNumber: action.orderNumber}
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(OrderCancelActions.orderCancellationFailure({errorResponse: error}));
          })
        )
      )
    );
  });

  constructor(
    private action$: Actions,
    private connector: OrderCancelConnector,
    private globalMessageService: EfaGlobalMessageService
  ) {
  }
}
