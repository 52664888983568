import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { GenericLinkModule, IconModule, NavigationService } from '@spartacus/storefront';
import { EfaNavigationUIComponent } from './components/efa-navigation-ui/efa-navigation-ui.component';
import { EfaNavigationComponent } from './components/efa-navigation/efa-navigation.component';
import { MyAccountNavigationUiComponent } from './components/my-account-navigation-ui/my-account-navigation-ui.component';
import { EfaNavigationService } from './services/efa-navigation.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        NavigationComponent: {
          component: EfaNavigationComponent,
        },
      },
    }),

    {
      provide: NavigationService,
      useClass: EfaNavigationService
    }
  ],
  declarations: [EfaNavigationUIComponent, EfaNavigationComponent, MyAccountNavigationUiComponent],
  exports: [EfaNavigationUIComponent],
})
export class NavigationModule {}
