import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as CheckoutOrderUiReducers from './checkout-order-ui.reducers';

export const checkoutOrderFeatureKey = 'checkoutOrder';

export interface CheckoutOrderState {
  [CheckoutOrderUiReducers.CHECKOUT_ORDER_UI_FEATURE_KEY]: CheckoutOrderUiReducers.CheckoutOrderUiState;
}

export const reducers: ActionReducerMap<CheckoutOrderState> = {
  [CheckoutOrderUiReducers.CHECKOUT_ORDER_UI_FEATURE_KEY]: CheckoutOrderUiReducers.checkoutOrderUiReducer,
};

export const getCheckoutOrderState = createFeatureSelector<CheckoutOrderState>(checkoutOrderFeatureKey);
