import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';

import {Config, GlobalMessageType, PaginationModel, SortModel, TranslationService} from '@spartacus/core';
import {combineLatest, from, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {filter, first, map, switchMap, take} from 'rxjs/operators';

import {
  DeliveryNoteDocument,
  DeliveryNoteOverviewFilter,
  DeliveryNoteOverviewRequest,
  DeliveryNoteOverviewResponse
} from '../../model/delivery-notes.model';
import {DeliveryNotesOverviewService} from '../../services/delivery-notes-overview.service';
import {DeliveryNotesDetailsService} from '@core/delivery-notes/delivery-notes-details.service';
import {DeliveryNotesUiService} from '../../services/delivery-notes-ui.service';
import {ICON_TYPE} from '../../model/delivery-notes-icon.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

const DATE_FORMAT_FOR_DOWNLOADED_DOCUMENTS = 'yyyy-MM-dd';
const ZIP_EXTENSION = '.zip';
const PDF_EXTENSION = '.pdf';

@Component({
  selector: 'app-delivery-notes-overview',
  templateUrl: './delivery-notes-overview.component.html',
})
export class DeliveryNotesOverviewComponent implements OnInit, OnDestroy {

  constructor(
    protected translation: TranslationService,
    private deliveryNotesOverviewService: DeliveryNotesOverviewService,
    private deliveryNotesDetailsService: DeliveryNotesDetailsService,
    private deliveryNotesUiService: DeliveryNotesUiService,
    private globalMessageService: EfaGlobalMessageService,
    private datePipe: DatePipe,
    @Inject(Config) private config: any,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  deliveryNotes$: Observable<DeliveryNoteOverviewResponse>;
  isLoading$: Observable<boolean>;
  isDownloading$: Subject<boolean> = new ReplaySubject();
  iconTypes = ICON_TYPE;
  deliveryNotesOverviewFilter: DeliveryNoteOverviewFilter = {
    deliveryNoteNumber: '',
    oemNumber: '',
    orderNumber: '',
    consignmentInfo: '',
    dateFrom: undefined,
    dateTo: undefined,
  };
  isAllDeliveryNotesSelected = false;
  selectedDeliveryNoteDocumentNumbers: string[] = [];

  private downloadClicked = false;
  private sortCode = 'deliveryNoteNumber';
  private returningFromDetails = false;
  private currentPage = 0;

  private pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.config.pageSize.deliverNotesOverview,
    sort: 'creationDate',
  };
  private sort: SortModel = {
    code: 'deliveryNoteNumber',
    selected: true
  };
  private subscription: Subscription = new Subscription();
  private isMultipleDownloadClicked = false;

  private static formatFilterDate(date: NgbDateStruct): string {
    return date ? date.year + '-' + date.month + '-' + date.day : '';
  }

  ngOnInit(): void {
    this.isLoading$ = combineLatest([
      this.deliveryNotesOverviewService.isLoading(),
      this.deliveryNotesDetailsService.isLoading(),
    ]).pipe(
      map(
        ([isDeliveryNotesOverviewLoading, isDeliveryNotesDetailsLoading]) =>
          isDeliveryNotesOverviewLoading || isDeliveryNotesDetailsLoading
      )
    );
    this.deliveryNotes$ = this.deliveryNotesOverviewService.getDeliveryNotesOverviewResponse();
    this.subscription.add(
      combineLatest([
          this.translation.translate('deliveryNotesCustom.deliveryNotesOverview.downloadDeliveryNoteDocumentFileNamePrefix'),
          this.deliveryNotesDetailsService.isLoading().pipe(
            filter((isLoading: boolean) => !isLoading),
            switchMap(() => this.deliveryNotesDetailsService.getDeliveryNotesDetailsResponse(false, true))
          )
        ]
      ).subscribe(([deliveryNoteDocumentFileNamePrefixTranslation, deliveryNoteDetails]) => {
        if (!!deliveryNoteDetails && this.downloadClicked && !this.isMultipleDownloadClicked) {
          this.download(
            `${deliveryNoteDocumentFileNamePrefixTranslation}_${deliveryNoteDetails.deliveryNoteNumber}`,
            deliveryNoteDetails.encodedContent
          );
        }
      })
    );
    this.subscription.add(
      combineLatest([this.deliveryNotesUiService.selectedSort(),
        this.deliveryNotesUiService.selectedPagination(),
        this.deliveryNotesUiService.selectedFilterEntity(),
        this.deliveryNotesUiService.returningFromDetails()])
      .subscribe(([selectedSort, selectedPagination, _filter, returningFromDetails]:
                    [SortModel, PaginationModel, DeliveryNoteOverviewFilter, boolean]) => {
        this.returningFromDetails = returningFromDetails;
        if (selectedSort) {
          this.sortCode = selectedSort.code;
        }

        if (selectedPagination) {
          this.currentPage = selectedPagination.currentPage;
        } else {
          this.currentPage = 0;
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setDeliveryNoteOverviewFilter(overviewFilter: DeliveryNoteOverviewFilter): void {
    this.deliveryNotesOverviewFilter = overviewFilter;
    // make sure first page of new result is displayed
    if (!this.returningFromDetails) {
      this.pageChange(0);
    } else {
      this.fetchDeliveryNotes();
      this.deliveryNotesUiService.setReturningFromDetails(false);
    }
  }

  changeSortCode(sortCode: string): void {
    this.sortCode = sortCode;
    this.fetchDeliveryNotes();
    this.deliveryNotesUiService.setSelectedSort({
      code: sortCode,
      selected: true
    });
    this.pageChange(0);
  }

  pageChange(page: number): void {
    this.currentPage = page;
    this.deliveryNotesUiService.setSelectedPagination({
      currentPage: page,
      pageSize: this.config.pageSize.deliverNotesOverview,
    });
    this.fetchDeliveryNotes();
  }

  getSortLabels(): Observable<{ byDate: string; byOrderNumber: string }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.orderNumber'),
    ]).pipe(
      map(([textByDate, textByOrderNumber]) => {
        return {
          byDate: textByDate,
          byOrderNumber: textByOrderNumber,
        };
      })
    );
  }

  private fetchDeliveryNotes(): void {
    const deliveryNoteOverviewRequest: DeliveryNoteOverviewRequest = {
      deliveryNoteNumber: this.deliveryNotesOverviewFilter.deliveryNoteNumber,
      orderNumber: this.deliveryNotesOverviewFilter.orderNumber,
      oemNumber: this.deliveryNotesOverviewFilter.oemNumber,
      consignmentInfo: this.deliveryNotesOverviewFilter.consignmentInfo,
      dateTo: DeliveryNotesOverviewComponent.formatFilterDate(this.deliveryNotesOverviewFilter.dateTo),
      dateFrom: DeliveryNotesOverviewComponent.formatFilterDate(this.deliveryNotesOverviewFilter.dateFrom),
      sort: {
        code: this.sortCode,
        selected: true
      },
      pagination: {
        currentPage: this.currentPage,
        pageSize: this.config.pageSize.deliverNotesOverview,
        sort: this.sortCode,
      }
    };
    this.deliveryNotesOverviewService.loadDeliveryNotesOverview(deliveryNoteOverviewRequest);
  }

  private download(fileName: string, data: string): void {
    if (data === undefined || data.length === 0) {
      this.globalMessageService.add(
        {key: 'deliveryNotesCustom.globalMessage.downloadContentEmpty'},
        GlobalMessageType.MSG_TYPE_INFO
      );
    } else {
      const fileContent = 'application/pdf';
      const source = `data:${fileContent};base64,${data}`;
      const link = this.document.createElement('a');

      link.href = source;
      link.download = `${fileName}.pdf`;
      link.click();
      this.downloadClicked = false;
    }
  }

  onAllSelectedChange(deliveryNotes: DeliveryNoteDocument[]): void {
    this.selectedDeliveryNoteDocumentNumbers.splice(0, this.selectedDeliveryNoteDocumentNumbers.length);
    this.isAllDeliveryNotesSelected = !this.isAllDeliveryNotesSelected;
    if (this.isAllDeliveryNotesSelected) {
      deliveryNotes.forEach((deliveryNoteDocument: DeliveryNoteDocument) =>
        this.selectedDeliveryNoteDocumentNumbers.push(deliveryNoteDocument.deliveryNoteNumber));
    }
  }

  isDocumentSelected(deliveryNoteDocumentNumber: string): boolean {
    const foundSelectedDeliveryNoteDocumentNumber: string = this.selectedDeliveryNoteDocumentNumbers.find(
      (selectedDeliveryNoteDocumentNumber: string) => selectedDeliveryNoteDocumentNumber === deliveryNoteDocumentNumber
    );
    return foundSelectedDeliveryNoteDocumentNumber != null;
  }

  onDocumentSelectedChange(deliveryNoteDocumentNumber: string): void {
    const deliveryNoteNumberIndex: number = this.selectedDeliveryNoteDocumentNumbers.indexOf(deliveryNoteDocumentNumber);
    if (deliveryNoteNumberIndex !== -1) {
      this.isAllDeliveryNotesSelected = false;
      this.selectedDeliveryNoteDocumentNumbers.splice(deliveryNoteNumberIndex, 1);
    } else {
      this.selectedDeliveryNoteDocumentNumbers.push(deliveryNoteDocumentNumber);
    }
  }
  onDowloadSingleDocument(deliveryNoteDocumentNumber: string): void {
    this.selectedDeliveryNoteDocumentNumbers = []
    this.selectedDeliveryNoteDocumentNumbers.push(deliveryNoteDocumentNumber);
    this.onDownloadMultipleDocuments();
  }

  onDownloadMultipleDocuments(): void {
    this.isMultipleDownloadClicked = true;
    this.isDownloading$.next(true);
    const currentDate: Date = new Date();
    const formattedDate: string = this.datePipe.transform(currentDate, DATE_FORMAT_FOR_DOWNLOADED_DOCUMENTS);
    combineLatest([
      this.translation.translate('deliveryNotesCustom.deliveryNotesOverview.downloadDeliveryNoteDocumentFileNamePrefix'),
      this.translation.translate('deliveryNotesCustom.deliveryNotesOverview.downloadDeliveryNoteDocumentsCompressedFileNamePrefix')
    ]).pipe(first()).subscribe(([documentFileNamePrefixTranslation, zipFileNamePrefixTranslation]) => {
      this.createZippedDocuments(this.selectedDeliveryNoteDocumentNumbers, `${documentFileNamePrefixTranslation}`, `${zipFileNamePrefixTranslation}` + '_' + formattedDate + ZIP_EXTENSION);
    });
  }

  async createZippedDocuments(files: string[], documentFileNamePrefix: string, zipName: string) {
    const zip = new JSZip();
    for (const file of files) {
      this.deliveryNotesDetailsService.loadDeliveryNotesDetails(file, false, true);
      const blob: any = await this.deliveryNotesDetailsService.isLoading()
      .pipe(filter((isLoading: boolean) => !isLoading),
        switchMap(() => this.deliveryNotesDetailsService.getDeliveryNotesDetailsResponse(false, true)),
        switchMap((fileData: any) => from(fetch(`data:application/pdf;base64,${fileData.encodedContent}`))),
        switchMap((res: any) => res.blob()),
        take(1)
      ).toPromise();
      zip.file(documentFileNamePrefix + '_' + file.substring(file.lastIndexOf('/') + 1) + PDF_EXTENSION, blob);
    }
    zip.generateAsync({type: 'blob'}).then((content) => {
      if (content) {
        FileSaver.saveAs(content, zipName);
      }
      this.isMultipleDownloadClicked = false;
      this.isDownloading$.next(false);
    });
  }
}
