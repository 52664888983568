<div class="modal-container">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <cx-icon [type]="iconTypes.CLOSE_LIGHT"></cx-icon>
        </button>
    </div>
    <div class="modal-body">
        <div class="outer-container">
            <span class="configurator-cart-confirmation-message-icon">
                <cx-icon [type]="iconTypes.CART_BLUE"></cx-icon>
            </span>
            <p class="configurator-cart-confirmation-message-title">
                {{ 'cartAdministration.configuratorCartConfirmationDialog.confirmationMessageTitle' | cxTranslate }}
            </p>
            <p class="configurator-cart-confirmation-message-text">
                {{ 'cartAdministration.configuratorCartConfirmationDialog.confirmationMessageText' | cxTranslate }}
            </p>
        </div>
    </div>

    <div class="modal-footer">
      <button *ngIf="isCalledFromSERP; else checkPDPContent" class="btn btn-primary btn-confirm" type="button" (click)="handelSERPCall()">
        {{ 'cartAdministration.configuratorCartConfirmationDialog.openCart' | cxTranslate }}
      </button>
      <ng-template #checkPDPContent>
        <button *ngIf="isCalledFromPDP; else checkTopmotiveContent" class="btn btn-primary btn-confirm" type="button" (click)="handelPDPCall()">
          {{ 'cartAdministration.configuratorCartConfirmationDialog.openCart' | cxTranslate }}
        </button>
      </ng-template>
      <ng-template #checkTopmotiveContent>
        <button *ngIf="isCalledFromTopmotive; else checkConfirmContent" class="btn btn-primary btn-confirm" type="button" (click)="handelTopmotiveCall()">
          {{ 'cartAdministration.configuratorCartConfirmationDialog.openCart' | cxTranslate }}
        </button>
      </ng-template>
      <ng-template #checkConfirmContent>
        <button class="btn btn-primary btn-confirm" type="button" (click)="confirm()">
          {{ 'cartAdministration.configuratorCartConfirmationDialog.confirm' | cxTranslate }}
        </button>
      </ng-template>
    </div>
</div>
