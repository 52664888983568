import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DefaultManufacturersAdapter } from './adapters/default-manufacturers.adapter';
import { OccDefaultManufacturersAdapter } from './adapters/impl/occ-default-manufacturers.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: DefaultManufacturersAdapter,
      useClass: OccDefaultManufacturersAdapter,
    }
  ],
})
export class OccModule { }
