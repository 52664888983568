import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as CartParametersReducers from './cart-parameters.reducer';
import * as CartProductSearchReducers from './cart-product-search.reducer';
import * as ConfiguratorCartReducers from './configurator-cart.reducer';
import * as MultiAddToCartReducers from './multi-add-to-cart.reducer';
import * as CartEntryInfosReducers from './set-cart-entry-infos.reducer';
import * as SetCartCalculationModeReducers from './set-cart-calculation-mode.reducer';
import * as UpdateCartReducers from './update-cart.reducer';

export const cartAdministrationFeatureKey = 'cartAdministration';

export interface CartAdministrationState {
  [CartParametersReducers.CART_PARAMETERS_FEATURE_KEY]: CartParametersReducers.CartParametersState;
  [CartProductSearchReducers.CART_PRODUCT_SEARCH_FEATURE_KEY]: CartProductSearchReducers.CartProductSearchState;
  [MultiAddToCartReducers.MULTI_ADD_TO_CART_FEATURE_KEY]: MultiAddToCartReducers.MultiAddToCartState;
  [CartEntryInfosReducers.CART_ENTRY_INFOS_FEATURE_KEY]: CartEntryInfosReducers.CartEntryInfosState;
  [SetCartCalculationModeReducers.SET_CART_CALCULATION_MODE]: SetCartCalculationModeReducers.SetCartCalculationModeState;
  [UpdateCartReducers.UPDATE_CART_FEATURE_KEY]: UpdateCartReducers.UpdateCartState;
  [ConfiguratorCartReducers.CONFIGURATOR_CART_FEATURE_KEY]: ConfiguratorCartReducers.ConfiguratorCartState;
}

export const reducers: ActionReducerMap<CartAdministrationState> = {
  [CartParametersReducers.CART_PARAMETERS_FEATURE_KEY]: CartParametersReducers.cartParametersReducer,
  [CartProductSearchReducers.CART_PRODUCT_SEARCH_FEATURE_KEY]: CartProductSearchReducers.cartProductSearchReducer,
  [MultiAddToCartReducers.MULTI_ADD_TO_CART_FEATURE_KEY]: MultiAddToCartReducers.multiAddToCartReducer,
  [CartEntryInfosReducers.CART_ENTRY_INFOS_FEATURE_KEY]: CartEntryInfosReducers.setCartEntryInfosReducer,
  [SetCartCalculationModeReducers.SET_CART_CALCULATION_MODE]: SetCartCalculationModeReducers.setCartCalculationModeReducer,
  [UpdateCartReducers.UPDATE_CART_FEATURE_KEY]: UpdateCartReducers.updateCartReducer,
  [ConfiguratorCartReducers.CONFIGURATOR_CART_FEATURE_KEY]: ConfiguratorCartReducers.configuratorCartReducer,
};

export const getCartAdministrationState = createFeatureSelector<CartAdministrationState>(cartAdministrationFeatureKey);
