import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PartsLinkAccessFormComponent} from './components/parts-link-access-form/parts-link-access-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfigModule, I18nModule, OccConfig} from '@spartacus/core';
import {FormErrorsModule, IconModule} from '@spartacus/storefront';
import {partsLinkAccessTranslationChunksConfig, partsLinkAccessTranslations} from './translations/parts-links-access.translations';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {effects} from './store/effects';
import {PARTS_LINK_ACCESS_INDEX_FEATURE_KEY, reducers} from './store/reducers';
import {PartsLinkAccessOccModule} from './occ/occ.module';


@NgModule({
  declarations: [
    PartsLinkAccessFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
    PartsLinkAccessOccModule,
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            startPartsLinkAccess: '/users/${userId}/partsLinkAccess'
          },
        },
      },
    } as OccConfig),
    ConfigModule.withConfig({
      i18n: {
        resources: partsLinkAccessTranslations,
        chunks: partsLinkAccessTranslationChunksConfig
      },
    }),
    ConfigModule.withConfig({
      cmsComponents: {
        PartsLinkAccessFormComponent: {
          component: PartsLinkAccessFormComponent,
        }
      }
    }),
    StoreModule.forFeature(PARTS_LINK_ACCESS_INDEX_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [
    PartsLinkAccessFormComponent
  ]
})
export class PartsLinkAccessModule { }
