import { createSelector, MemoizedSelector } from "@ngrx/store";
import * as TransportDamageFeature from '../reducers/transport-damage.reducer';
import { TRANSPORT_DAMAGE_FEATURE_KEY } from '../reducers/transport-damage.reducer';;
import { getTransportDamageState, TransportDamageState } from '../reducers';

export const selectProcessing: MemoizedSelector<TransportDamageFeature.TransportDamageState, boolean> = createSelector(
  getTransportDamageState,
  (state: TransportDamageState) => state[TRANSPORT_DAMAGE_FEATURE_KEY].processing
);

export const selectSuccess: MemoizedSelector<TransportDamageFeature.TransportDamageState, boolean> = createSelector(
  getTransportDamageState,
  (state: TransportDamageState) => state[TRANSPORT_DAMAGE_FEATURE_KEY].success
);