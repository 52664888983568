<ng-container *ngIf="!(isLoading$ | async); else loading">
  <ng-container *ngIf="(deliveryNotesDetails$ | async) as deliveryNoteDetails">
    <div class="return-creation-container">
      <app-delivery-notes-header [deliveryNoteNumber]="deliveryNoteDetails?.deliveryNoteNumber"
      [creationDate]="deliveryNoteDetails?.creationDate"
      [warehouseName]="deliveryNoteDetails?.warehouseName"
      [receiverAddress]="deliveryNoteDetails?.receiverAddress">
      </app-delivery-notes-header>

      <div class="table-title">
        {{ 'returnCreation.returnCreationTitle' | cxTranslate }}
      </div>
      <!-- Table -->
      <form (ngSubmit)="onSubmit()" [formGroup]="returnCreationForm">
        <table class="c-delivery-note-details__table table table--responsive">
          <thead>
          <tr>
            <th scope="col">{{ 'returnCreation.positionNumber' | cxTranslate }}</th>
            <th scope="col">{{ 'returnCreation.oemNumber' | cxTranslate }}</th>
            <th scope="col">{{ 'returnCreation.name' | cxTranslate }}</th>
            <th scope="col">{{ 'returnCreation.quantityToReturn' | cxTranslate }}</th>
            <th scope="col">{{ 'returnCreation.reason.header' | cxTranslate }}* </th>
            <th scope="col">{{ 'returnCreation.comment.header' | cxTranslate }} </th>
          </tr>
          </thead>
          <tbody formArrayName="items">
          <tr *ngFor="let position of (returnablePositions$ | async), let i = index" [ngClass]="getReturnPositionHighlightClass(position.positionNumber)">
            <td attr.data-label="{{ 'returnCreation.positionNumber' | cxTranslate }}">
              <p>{{position.positionNumber}}</p>
            </td>
            <td attr.data-label="{{ 'returnCreation.oemNumber' | cxTranslate }}">
              <p>{{position.oemNumber}}</p>
            </td>
            <td attr.data-label="{{ 'returnCreation.name' | cxTranslate }}">
              <p>{{position.name}}</p>
            </td>
            <td attr.data-label="{{ 'returnCreation.quantityToReturn' | cxTranslate }}">
              <p>{{position.quantityToReturn}}</p>
            </td>
            <td attr.data-label="{{ 'returnCreation.reason.placeholder' | cxTranslate }}*" [formGroupName]="i" class="reason-col">
              <p class="table-line-column select-arrow">
                <select class="form-control" [ngClass]="getSelectPlaceholderTextClass(items.controls[i].get('reasonCode').value)"
                        formControlName="reasonCode"
                        (ngModelChange)="returnCreationReasonChange($event, i)">
                  <option value="" selected>
                    {{ 'returnCreation.reason.placeholder' | cxTranslate }}*
                  </option>
                  <ng-container *ngIf="!position.containsDeposit, else reasonForDeposits">
                    <option *ngFor="let reason of reasonCodesNoDeposits$ | async"
                            [ngValue]="reason">
                      {{ reason.name }}
                    </option>
                  </ng-container>
                  <ng-template #reasonForDeposits>
                    <ng-container *ngIf="position.containsDeposit && position.depositOnly, else allReason">
                      <option *ngFor="let reason of reasonCodesDeposits$ | async"
                              [ngValue]="reason">
                        {{ reason.name }}
                      </option>
                    </ng-container>
                    <ng-template #allReason>
                      <option *ngFor="let reason of allReasonCode$ | async"
                              [ngValue]="reason">
                        {{ reason.name }}
                      </option>
                    </ng-template>
                  </ng-template>
                </select>
                <cx-form-errors [control]="items.controls[i].get('reasonCode')"></cx-form-errors>
              </p>

            </td>
            <td attr.data-label="{{ 'returnCreation.comment.placeholder' | cxTranslate }}" [formGroupName]="i">
              <p class="table-line-column">
                <textarea class="form-control"
                          formControlName="comment"
                          rows="1"
                          placeholder="{{ 'returnCreation.comment.placeholder' | cxTranslate }}">
                </textarea>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </form>
      <div class="mandatory-text">
        <span>*{{ 'returnCreation.formMandatoryFields' | cxTranslate }}</span>
      </div>
    </div>
    <!--Action Buttons -->
    <div class="form-footer">
      <div class="form-footer__btn-container row justify-content-end">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <button type="button" class="btn btn-block btn-secondary"
                [routerLink]="{ cxRoute: deliveryNoteReturnablePositionsRoute, params: { deliveryNoteNumber: deliveryNoteDetails?.deliveryNoteNumber } } | cxUrl">
            {{ 'common.back' | cxTranslate }}
          </button>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <button type="button"
                  class="btn btn-block btn-primary"
                  (click)="onSubmit()">
            {{ 'returnCreation.sendButton' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>

  </ng-container>
</ng-container>
<ng-container>
  <ng-template #loading>
    <div *ngIf="isLoading$ | async" class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</ng-container>
