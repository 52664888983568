import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {ConverterService, Occ, OccEndpointsService} from '@spartacus/core';

import {MultiAddToCartAdapter} from '../multi-add-to-cart.adapter';
import {AddToCartParam, CartModificationList} from '../../../model/cart-administration.model';
import {MULTI_ADD_TO_CART_SERIALIZER} from '../../converters/converters';
import OrderEntryList = Occ.OrderEntryList;

@Injectable({providedIn: 'root'})
export class OccMultiAddToCartAdapter implements MultiAddToCartAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
  ) {
  }

  addEntries(params: AddToCartParam[], uId: string, cId: string): Observable<CartModificationList> {
    const entries: OrderEntryList = this.converter.convert(params, MULTI_ADD_TO_CART_SERIALIZER);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'multiAddEntries',
      {
        urlParams: {
          userId: uId,
          cartId: cId,
        }
      }
    );
    return this.http.post<CartModificationList>(url, entries, {headers});
  }
}
