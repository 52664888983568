import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

import {CancelOrReturnItemsComponent} from '@spartacus/order/components';

import {Subscription} from 'rxjs';
import {OrderCancelService} from '../../services/order-cancel.service';
import {OrderEntry} from '@spartacus/cart/base/root';

@Component({
  selector: 'app-efa-cancel-or-return-items',
  templateUrl: './efa-cancel-or-return-items.component.html',
})
export class EfaCancelOrReturnItemsComponent extends CancelOrReturnItemsComponent implements OnInit, OnDestroy {

  checkAll: boolean;

  private subscription: Subscription = new Subscription();

  constructor(protected orderAmendService: OrderCancelService) {
    super(orderAmendService);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.form$.subscribe((form: FormGroup) => this.setCheckAll(form))
    );
  }

  setAll(form: UntypedFormGroup): void {
    this.entries.forEach((entry) => {
      this.getControl(form, entry).setValue(true);
      this.orderAmendService.changeFormModel(entry, true);
    });
  }

  getControl(form: UntypedFormGroup, entry: OrderEntry): UntypedFormControl {
    const key = `${entry.entryNumber}_${entry.product.manufacturerAID}_${entry.quantity}`;
    return (form.get('entries').get(key)) as UntypedFormControl;
  }

  onCheckChange(form: UntypedFormGroup, entry: OrderEntry, event): void {
    this.orderAmendService.changeFormModel(entry, event.target.checked);
    this.getControl(form, entry).setValue(event.target.checked);
    this.setCheckAll(form);
  }

  getFormModel(entry: OrderEntry): boolean {
    return this.orderAmendService.getFormModel(entry);
  }

  onCheckAllChange(form: FormGroup, event: any): void {
    this.checkAll = event.target.checked;
    this.entries.forEach((entry) => {
      this.getControl(form, entry).setValue(event.target.checked);
      this.orderAmendService.changeFormModel(entry, event.target.checked);
    });
  }

  private setCheckAll(form: FormGroup) {
    this.checkAll =
    this.entries.filter(
      (entry) => this.getControl(form, entry).value === true
    ).length === this.entries.length;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
