import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TopmotiveCatalogAdapter } from '../adapters/topmotive-catalog.adapter';

@Injectable({
  providedIn: 'root',
})
export class TopmotiveCatalogConnector {
  constructor(protected adapter: TopmotiveCatalogAdapter) {}

  isAuthorized(targetUrl: string): Observable<boolean> {
    return this.adapter.isAuthorized(targetUrl);
  }
}
