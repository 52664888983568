import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListComponent } from '@spartacus/organization/administration/components';

enum ICON_TYPE {
  PLUS = 'PLUS'
}

@Component({
  selector: 'app-efa-manage-users-list',
  templateUrl: './efa-manage-users-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaManageUsersListComponent extends ListComponent {
 readonly customIconTypes = ICON_TYPE;
}
