<div ngbAccordion #acc="ngbAccordion">
  <div ngbAccordionItem="orderOverviewFilterPanelOne" #orderOverviewFilterPanelOne="ngbAccordionItem">
    <div ngbAccordionHeader >
      <div class="justify-content-between">
        <h5 class="mb-0">
          <button ngbAccordionToggle class="btn btn-block btn-filter">
            <cx-icon [type]="iconTypes.BROWSE" class="mr-2">
              <span class="btn-filter-text">{{ 'orderHistoryCustom.myAccount.filter' | cxTranslate }}</span>
            </cx-icon>
          </button>
        </h5>
      </div>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <form [formGroup]="orderOverviewFilterForm" class="row">
            <div class="col-md-4">
              <label class="form-group">
                <span class="label-content">
                  {{ 'orderHistoryCustom.orderOverviewFilterForm.orderNumber.label' | cxTranslate }}
                </span>
                <input class="form-control"
                       type="text"
                       formControlName="orderNumber"
                       (keydown.enter)="triggerOnChanges($event)"
                       placeholder="{{ 'orderHistoryCustom.orderOverviewFilterForm.orderNumber.placeholder' | cxTranslate }}"/>
              </label>
            </div>
    
            <div class="col-md-4">
              <label class="form-group select-arrow">
                <span class="label-content">{{ 'orderHistoryCustom.orderOverviewFilterForm.orderState.label' | cxTranslate }}</span>
                <select class="form-control" [ngClass]="getSelectPlaceholderTextClass(orderOverviewFilterForm.get('orderStateId').value)"
                        formControlName="orderStateId">
                  <option value="" selected>
                    {{ 'orderHistoryCustom.orderOverviewFilterForm.orderState.placeholder' | cxTranslate }}
                  </option>
                  <option *ngFor="let orderState of availableOrderStates"
                          [value]="orderState.id">
                    {{ orderState.name }}
                  </option>
                </select>
              </label>
            </div>
    
            <div class="col-md-4">
              <label class="form-group select-arrow">
                <span class="label-content">{{ 'orderHistoryCustom.orderOverviewFilterForm.shippingAddresses.label' | cxTranslate }}</span>
                <select class="form-control" [ngClass]="getSelectPlaceholderTextClass(orderOverviewFilterForm.get('receiverAddressId').value)"
                        formControlName="receiverAddressId">
                  <option value="" selected>
                    {{ 'orderHistoryCustom.orderOverviewFilterForm.shippingAddresses.placeholder' | cxTranslate }}
                  </option>
                  <option *ngFor="let address of shippingAddresses"
                          [value]="address.sapCustomerId">
                    {{ address.line1 }}, {{ address.postalCode }} {{ address.town }} ({{ address.sapCustomerId }})
                  </option>
                </select>
              </label>
            </div>
    
            <div class="col-md-4">
              <label class="form-group">
                  <span class="label-content">
                    {{ 'orderHistoryCustom.orderOverviewFilterForm.dateFrom.label' | cxTranslate }}
                  </span>
                <div class="input-group">
                  <input class="form-control" ngbDatepicker #df="ngbDatepicker"
                         formControlName="dateFrom"
                         [maxDate]="maxDate"
                         [readonly]="true"
                         efaNgbDatepickerNavigationTooltipsRemove
                         placeholder="{{ 'orderHistoryCustom.orderOverviewFilterForm.dateFrom.placeholder' | cxTranslate }}"
                         container="body"/>
                  <div class="input-group-append">
                    <button class="btn btn-primary d-flex" (click)="df.toggle()" type="button">
                      <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                    </button>
                  </div>
                </div>
              </label>
            </div>
            <div class="col-md-4">
              <label class="form-group">
                  <span class="label-content">
                    {{ 'orderHistoryCustom.orderOverviewFilterForm.dateTo.label' | cxTranslate }}
                  </span>
                <div class="input-group">
                  <input class="form-control" ngbDatepicker #dt="ngbDatepicker"
                         formControlName="dateTo"
                         [maxDate]="maxDate"
                         [readonly]="true"
                         efaNgbDatepickerNavigationTooltipsRemove
                         placeholder="{{ 'orderHistoryCustom.orderOverviewFilterForm.dateTo.placeholder' | cxTranslate }}"
                         container="body"/>
                  <div class="input-group-append">
                    <button class="btn btn-primary d-flex" (click)="dt.toggle()" type="button">
                      <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                    </button>
                  </div>
                </div>
              </label>
            </div>
    
            <div class="col-md-4">
              <label class="form-group">
                    <span class="label-content">
                      {{ 'orderHistoryCustom.orderOverviewFilterForm.consignmentInfo.label' | cxTranslate }}
                    </span>
                <input class="form-control"
                       type="text"
                       formControlName="consignmentInfo"
                       (keydown.enter)="triggerOnChanges('consignmentInfo', $event)"
                       placeholder="{{ 'orderHistoryCustom.orderOverviewFilterForm.consignmentInfo.placeholder' | cxTranslate }}"/>
                <cx-form-errors [control]="orderOverviewFilterForm.get('consignmentInfo')"></cx-form-errors>
              </label>
            </div>
          </form>
          <div class="row justify-content-end">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <button type="button" (click)="clearForm()" style="color: #003899;" class="btn btn-block btn-secondery">{{ 'clearForm' | cxTranslate }}</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
