import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ShippingBacklogOverviewFilter } from '../model/shipping-backlog.model';
import { ShippingBacklogUiActions } from '../store/actions';
import * as ShippingBacklogUiFeature from '../store/reducers/shipping-backlog-ui.reducer';
import * as ShippingBacklogUiSelectors from '../store/selectors/shipping-backlog-ui.selectors';

@Injectable({
  providedIn: 'root',
})
export class ShippingBacklogUiService {
  constructor(
    protected store: Store<ShippingBacklogUiFeature.ShippingBacklogUiState>
  ) {}

  selectedFilterEntity(): Observable<ShippingBacklogOverviewFilter> {
    return this.store.select(ShippingBacklogUiSelectors.selectFilterEntity);
  }

  setSelectedFilterEntity(
    selectedFilterEntity: ShippingBacklogOverviewFilter
  ): void {
    this.store.dispatch(
      ShippingBacklogUiActions.setSelectedFilterEntity({ selectedFilterEntity })
    );
  }

  selectedPagination(): Observable<PaginationModel> {
    return this.store.select(ShippingBacklogUiSelectors.selectPagination);
  }

  setSelectedPagination(selectedPagination: PaginationModel): void {
    this.store.dispatch(
      ShippingBacklogUiActions.setSelectedPagination({ selectedPagination })
    );
  }

  selectedSort(): Observable<SortModel> {
    return this.store.select(ShippingBacklogUiSelectors.selectSort);
  }

  setSelectedSort(selectedSort: SortModel): void {
    this.store.dispatch(
      ShippingBacklogUiActions.setSelectedSorting({ selectedSort })
    );
  }

  setReturningFromDetails(b: boolean): void {
    this.store.dispatch(ShippingBacklogUiActions.setReturningFromDetails({returningFromDetails: b} ));
  }

  returningFromDetails(): Observable<boolean> {
    return this.store.select(ShippingBacklogUiSelectors.returningFromDetails);
  }
}
