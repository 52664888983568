import {
  formatDate,
  FormStyle,
  getLocaleDayNames,
  getLocaleMonthNames,
  TranslationWidth,
} from '@angular/common';
import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '@spartacus/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EfaNgbDatepickerI18n
  extends NgbDatepickerI18n
  implements OnDestroy
{
  private subscription: Subscription = new Subscription();
  private _locale: string;
  private _monthsShort: readonly string[];
  private _monthsFull: readonly string[];

  constructor(
    @Inject(LOCALE_ID) private _defaultLocale,
    private languageService: LanguageService
  ) {
    super();
    this._locale = this._defaultLocale;
    this._monthsShort = getLocaleMonthNames(
      this._locale,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated
    );
    this._monthsFull = getLocaleMonthNames(
      this._locale,
      FormStyle.Standalone,
      TranslationWidth.Wide
    );
    this.subscription.add(
      this.languageService.getActive().subscribe((lang: string) => {
        this._locale = lang;
        this._monthsShort = getLocaleMonthNames(
          this._locale,
          FormStyle.Standalone,
          TranslationWidth.Abbreviated
        );
        this._monthsFull = getLocaleMonthNames(
          this._locale,
          FormStyle.Standalone,
          TranslationWidth.Wide
        );
      })
    );
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    const weekdaysStartingOnSunday = getLocaleDayNames(
      this._locale,
      FormStyle.Standalone,
      width === undefined ? TranslationWidth.Short : width
    );
    const weekdays = weekdaysStartingOnSunday.map(
      (day, index) => weekdaysStartingOnSunday[(index + 1) % 7]
    );
    return weekdays[weekday - 1] || '';
  }

  getMonthShortName(month: number): string {
    return this._monthsShort[month - 1] || '';
  }

  getMonthFullName(month: number): string {
    return this._monthsFull[month - 1] || '';
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return formatDate(jsDate, 'fullDate', this._locale);
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }
}
