import { ActionReducerMap, createFeatureSelector } from '@ngrx/store'
import * as PartsLinkAccessReducers from './parts-link-access.reducer'

export const PARTS_LINK_ACCESS_INDEX_FEATURE_KEY = 'partsLinkAccess'

export interface PartsLinkAccessState {
  [PartsLinkAccessReducers.PARTS_LINK_ACCESS_FEATURE_KEY]:PartsLinkAccessReducers.PartsLinkAccessState
}

export const reducers:ActionReducerMap<PartsLinkAccessState> = {
  [PartsLinkAccessReducers.PARTS_LINK_ACCESS_FEATURE_KEY]: PartsLinkAccessReducers.PartsLinkAccesReducer
}
export const getPartsLinkAccessState = createFeatureSelector<PartsLinkAccessState>(PARTS_LINK_ACCESS_INDEX_FEATURE_KEY)
