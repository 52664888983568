import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index-de';
import { en } from './en/index-en';

export const customWishListTranslations = {
  de,
  en
};

export const customWishListTranslationChunksConfig: TranslationChunksConfig = {
  customWishList: [
    'wishListItem', 'wishListFilterForm'
  ],
};
