<ng-container *ngIf="orderInfoFields?.length > 0" [formGroup]="form">
  <ng-container *ngFor="let formControl of customFormControls; let index = index; trackBy: trackByFn">
    <td colspan="3" class="table-col" *ngIf="index === 0 || index === 2 || index === 4; else oddTemplate">
      <label class="c-form-group-inline form-group" [ngClass]="formControl.orderInfoFieldValue.orderInfoField.mandatory ? 'required' : 'optional'">
        <span class="label-content bold">{{
            formControl.shopText
            }}</span>
        <span class="label-value" *ngIf="readonly">
            {{ formControl.orderInfoFieldValue.value }}
        </span>
      <div *ngIf="!readonly && formControl.key" class="text-left">
        <div class="input-block">
          <input class="form-control mr-2" type="{{formControl.inputType}}" placeholder="{{formControl.placeholderText}}" formControlName="{{formControl.key}}"
          [maxLength]="formControl.inputType === 'text' ? formControl.orderInfoFieldValue.orderInfoField.length : ''"
          (change)="orderEntryFieldsChange($event, formControl.orderInfoFieldValue.orderInfoField, form.get(formControl.key))" [disableTooltip]="formControl.toolTipText.length === 0" ngbTooltip="{{formControl.toolTipText}}" placement="top"/>
          <cx-icon *ngIf="(cartEntryInfosUpdating$| async) && (currentCartEntryNumberUpdating$ | async) === entryNumber && (currentCartEntryOrderInfoFieldIdUpdating$| async) === formControl.orderInfoFieldValue.orderInfoField.fieldId && (currentCartEntryInfoTypeUpdating$ | async) === 'orderInfoFields'"
                    type="SPINNER"></cx-icon>
        </div>
        <cx-form-errors [control]="form.get(formControl.key)"></cx-form-errors>
      </div>
      </label>
    </td>
    <ng-template #oddTemplate>
      <td colspan="5" class="table-col">
        <label class="c-form-group-inline form-group" [ngClass]="formControl.orderInfoFieldValue.orderInfoField.mandatory ? 'required' : 'optional'">
          <span class="label-content bold">{{
              formControl.shopText
              }}</span>
          <span class="label-value" *ngIf="readonly">
                {{ formControl.orderInfoFieldValue.value }}
          </span>
          <div *ngIf="!readonly && formControl.key" class="text-left">
            <div class="input-block">
              <input class="form-control mr-2" type="{{formControl.inputType}}" placeholder="{{formControl.placeholderText}}" formControlName="{{formControl.key}}"
              [maxLength]="formControl.inputType === 'text' ? formControl.orderInfoFieldValue.orderInfoField.length : ''"
              (change)="orderEntryFieldsChange($event, formControl.orderInfoFieldValue.orderInfoField, form.get(formControl.key))" [disableTooltip]="formControl.toolTipText.length === 0" ngbTooltip="{{formControl.toolTipText}}" placement="top"/>
              <cx-icon *ngIf="(cartEntryInfosUpdating$| async) && (currentCartEntryNumberUpdating$ | async) === entryNumber && (currentCartEntryOrderInfoFieldIdUpdating$| async) === formControl.orderInfoFieldValue.orderInfoField.fieldId && (currentCartEntryInfoTypeUpdating$ | async) === 'orderInfoFields'"
                    type="SPINNER"></cx-icon>
            </div>
            <cx-form-errors [control]="form.get(formControl.key)"></cx-form-errors>
          </div>
        </label>
      </td>
    </ng-template>
  </ng-container>
</ng-container>
