import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { AuthActions, SiteContextActions } from '@spartacus/core';
import { produce } from 'immer';
import { DeliveryNoteOverviewRequest, DeliveryNoteOverviewResponse } from '../../model/delivery-notes.model';
import { DeliveryNotesOverviewActions } from '../actions';

export const DELIVERY_NOTES_OVERVIEW_FEATURE_KEY = 'deliveryNotesOverview';

export interface DeliveryNotesOverviewState {
  request: DeliveryNoteOverviewRequest;
  response: DeliveryNoteOverviewResponse;
  loading: boolean;
  error: ErrorType;
}

export const initialStateOverview: DeliveryNotesOverviewState = {
  request: undefined,
  response: undefined,
  loading: false,
  error: undefined
};

export const deliveryNotesOverviewReducer = createReducer(
  initialStateOverview,

  on(DeliveryNotesOverviewActions.loadDeliveryNoteOverview, (state, action) => ({
    ...state,
    loading: true,
    request: action.request,
    response: undefined
  })),
  on(DeliveryNotesOverviewActions.loadDeliveryNoteOverviewSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.response,
    request: undefined
  })),
  on(DeliveryNotesOverviewActions.loadDeliveryNoteOverviewFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse,
  })),
);

function authenticationChangedOverview(action: Action, state): any {
  if (action.type === AuthActions.LOGIN ||
    action.type === AuthActions.LOGOUT
  ) {
    return produce(state, (draftState) => {
      draftState.deliveryNotes.deliveryNotesOverview = initialStateOverview;
    });
  }
  return state;
}

export function metaReducerFactoryDeliveryNoteOverview(): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const newState = authenticationChangedOverview(action, state);
    return reducer(newState, action);
  };
}

