import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EfaLinkComponent } from './components/efa-link/efa-link.component';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [EfaLinkComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    GenericLinkModule,
    ConfigModule.withConfig({
      icon: {
        symbols: {
          TELEPHONE: 'fas fa-phone',
        }
      }
    }),
    ConfigModule.withConfig({
      cmsComponents: {
        CMSLinkComponent: { 
          component: EfaLinkComponent, 
        },
      },
    }),
  ],
})
export class LinkModule {}
