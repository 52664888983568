import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { CarPartsPromotionComponent } from './car-parts-promotion/car-parts-promotion.component';

@NgModule({
  declarations: [CarPartsPromotionComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CarPartsPromotionComponent: {
          component: CarPartsPromotionComponent,
        },
      },
    }),
  ],
})
export class PromotionFlipBookModule {}
