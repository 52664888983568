<ng-container *ngIf="crumbs$ | async as crumbs">
    <span class="text-center">
        <span *ngFor="let crumb of crumbs; let i = index" class="crumb-label">
            <span *ngIf="i>0" class="crumb-label-divider">/</span>
            <span>{{crumb.label}}</span>
        </span>
      <span *ngIf="addCrumb$ | async as addCrumb" class="crumb-label">
          <span class="crumb-label-divider">/</span>
          <span>{{addCrumb.label}}</span>
        </span>
    </span>
  </ng-container>

  <h1>{{ myTitle$ | async }}</h1>
