import { createReducer, on } from "@ngrx/store";
import { ShippingBacklogCancellationRequest } from "../../model/shipping-backlog.model";
import { ShippingBacklogCancellationActions } from "../actions";

export const SHIPPING_BACKLOG_CANCELLATION_FEATURE_KEY = 'shippingBacklogCancellation';

export interface ShippingBacklogCancellationState {
  request: ShippingBacklogCancellationRequest;
  loading: boolean;
  success: boolean;
}

export const initialShippingBacklogCancellation: ShippingBacklogCancellationState = {
  request: undefined,
  loading: false,
  success: undefined,
};

export const shippingBacklogCancellationReducer = createReducer(
  initialShippingBacklogCancellation,

  on(
    ShippingBacklogCancellationActions.sendShippingBacklogCancellation,
    (state, action) => ({
      ...state,
      request: action.request,
      loading: true,
      success: false,
    })
  ),
  on(
    ShippingBacklogCancellationActions.sendShippingBacklogCancellationSuccess,
    (state) => ({
      ...state,
      loading: false,
      success: true,
    })
  ),
  on(
    ShippingBacklogCancellationActions.sendShippingBacklogCancellationFailure,
    (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.errorResponse,
    })
  )
);
