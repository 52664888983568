import { AfterViewChecked, Component } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { exceedMaxFileSize } from '@shared/utils/upload-file-size';
import { validFileExtension } from '@shared/validators/file-extension';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { RegistrationStatus } from '../../model/login-register-enum.model';
import { RegistrationData, RegistrationDocument } from '../../model/login-register.model';
import { AbstractRegistrationStepComponent } from '../abstract-registration-step/abstract-registration-step.component';

interface ViewElementText {
  elementId: string;
  elementText: string;
}

@Component({
  selector: 'app-registration-documents',
  templateUrl: './registration-documents.component.html',
})
export class RegistrationDocumentsComponent extends AbstractRegistrationStepComponent implements AfterViewChecked {

  documents: RegistrationDocument[] = [];
  fileContent: string | ArrayBuffer | null = null;

  registrationForm: UntypedFormGroup = this.formBuilder.group({
    documents: new UntypedFormArray([]),
  });

  private registrationDocumentTypeSubFormField = {
    file: ['', [validFileExtension(this.config.fileExtension.registrationForm)]],
  };

  private registrationDocumentTypeRequiredSubFormField = {
    file: ['', [validFileExtension(this.config.fileExtension.registrationForm), Validators.required]],
  };

  private viewElementText$: Subject<ViewElementText> = new ReplaySubject();

  protected fillFormDataFromResponse(
    registrationData: RegistrationData
  ): void {
    const registrationDocumentsList: RegistrationDocument[] = registrationData?.documents;
    if (registrationDocumentsList != null) {
      registrationDocumentsList.forEach((registrationDocument) => {
        const documentIndex: number = this.registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes.findIndex((regDoc => regDoc.code === registrationDocument.typeId));
        this.documentsFormControls[documentIndex].get('file').clearValidators();
        this.documentsFormControls[documentIndex].get('file').updateValueAndValidity();
        this.documents.push(registrationDocument);
        this.viewElementText$.next({
          elementId: registrationDocument.typeId,
          elementText: registrationDocument.fileName,
        });
      });
    }
  }

  protected getStatus(): RegistrationStatus {
    return RegistrationStatus.INPROGRESS;
  }

  protected onSubmit(registrationData: RegistrationData): void {
    this.registrationForm.markAllAsTouched();

    if (this.registrationForm.invalid) {
      this.scrollToFirstInvalidControl();
      return;
    }

    const registrationInformationRequest: RegistrationData = {
      ...registrationData,
      documents: this.documents,
    };

    this.submit(registrationInformationRequest);
  }

  protected initializeMultiOptionFields(): Observable<boolean> {
    const documentsFormArray: UntypedFormArray = this.registrationForm.get('documents') as UntypedFormArray;
    if (documentsFormArray.length === 0) {
      this.registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes.forEach((registrationDocumentType, index) => {
        if (registrationDocumentType.required) {
          documentsFormArray.push(this.formBuilder.group(this.registrationDocumentTypeRequiredSubFormField));
        } else {
          documentsFormArray.push(this.formBuilder.group(this.registrationDocumentTypeSubFormField));
        }
      });
    }

    return of(true);
  }

  fileChanged(fileList: FileList, documentTypeId: string, index: number): void {
    if (fileList[0].size === 0) {
      this.documentsFormControls[index].get('file').setErrors({
        fileEmpty: true
      });
    } else if (exceedMaxFileSize(fileList[0], this.config.maxFileSize.registrationForm)) {
      this.documentsFormControls[index].get('file').setErrors({
        maxFileSizeExceeded: {
          maxFileSize: this.config.maxFileSize.registrationForm
        }
      });
    } else {
      const file = fileList[0];
      const fileReader: FileReader = new FileReader();
      const self = this;
      fileReader.onloadend = () => {
        self.fileContent = fileReader.result;
        const doc: RegistrationDocument = {
          typeId: documentTypeId,
          lastModified: file.lastModified,
          encodedContent: btoa(this.fileContent as string),
          fileName: file.name,
        };
        const searchDocumentInd = this.documents.findIndex((document) => document.typeId === documentTypeId);
        if (searchDocumentInd > -1) {
          this.documents[searchDocumentInd] = doc;
        } else {
          this.documents.push(doc);
        }
      };
      fileReader.readAsBinaryString(file);
      this.viewElementText$.next({
        elementId: documentTypeId,
        elementText: file.name,
      });
    }
  }

  removeSelectedFile(documentTypeId: string, index: number): void {
    if (this.documents.length) {
      const searchDocumentInd = this.documents.findIndex((document) => document.typeId === documentTypeId);
      const documentTypeInd = this.registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes.findIndex((regDocType) => regDocType.code === documentTypeId);
      if (searchDocumentInd > -1) {
        this.documents.splice(searchDocumentInd, 1);
        this.documentsFormControls[documentTypeInd].get('file').reset('');
        if (this.registrationInformationMultiOptionsFieldsValues.registrationDocumentTypes[documentTypeInd].required) {
          this.documentsFormControls[documentTypeInd].get('file').addValidators( [validFileExtension(this.config.fileExtension.registrationForm), Validators.required]);
        } else {
          this.documentsFormControls[documentTypeInd].get('file').addValidators( [validFileExtension(this.config.fileExtension.registrationForm)]);
        }
        this.documentsFormControls[documentTypeInd].get('file').updateValueAndValidity();

        this.subscription.add(
          this.translationService.translate('uploadPdfFile.placeholder').subscribe((value: string) => {
            this.viewElementText$.next({
              elementId: documentTypeId,
              elementText: value,
            });
          })
        );
      }
    }
  }

  get documentsFormControls(): AbstractControl[] {
    return (this.registrationForm.get('documents') as UntypedFormArray).controls;
  }

  ngAfterViewChecked(): void {
    this.subscription.add(
      this.viewElementText$.asObservable().subscribe((viewElementText: ViewElementText) => {
        const htmlElement: HTMLElement = this.document.getElementById(viewElementText.elementId);
        if (htmlElement != null) {
          htmlElement.innerHTML = viewElementText.elementText;
        }
      })
    );
  }
}
