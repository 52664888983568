import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { TransportDamageRequest } from '../../../model/transport-damage.model';
import { TransportDamageAdapter } from '../transport-damage.adapter';

@Injectable({
  providedIn: 'root',
})
export class OccTransportDamageAdapter implements TransportDamageAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  adviseOfTransportDamage(request: TransportDamageRequest): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl('adviseOfTransportDamage', {
      urlParams: { userId: 'current' },
    });

    return this.http.post<void>(url, request, { headers });
  }
}
