import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RedirectService } from '@shared/services/redirect.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { filter, first, switchMap, withLatestFrom } from 'rxjs/operators';
import { PneuConfiguratorTileItem } from '../../model/content-tiles.model';
import { TokenGenerationService } from '../../services/token-generation.service';

@Component({
  selector: 'app-efa-pneu-configurator',
  templateUrl: './pneu-configurator.component.html'
})
export class PneuConfiguratorComponent implements OnInit, OnDestroy {
  @Input() tileItem: PneuConfiguratorTileItem;

  private subscription: Subscription = new Subscription();
  private tokenGenerationSubscription: Subscription = new Subscription();
  private pneuCalculationSchemaXml: string;

  constructor(
    private redirectService: RedirectService,
    private tokenGenerationService: TokenGenerationService,
    private userFacade: UserAccountFacade
  ) {
  }

  ngOnInit(): void {

    this.subscription.add(this.userFacade.get().subscribe((user: any) => {
      if (user != null) {
        this.pneuCalculationSchemaXml = user.orgUnit.pneuCalculationSchemaXml;
      }
    }));
  }

  connect(): void {
    this.tokenGenerationSubscription.unsubscribe();

    this.tokenGenerationService.reset();
    this.tokenGenerationService.createToken('pneuConfigurator');

    this.tokenGenerationSubscription = this.tokenGenerationService.success().pipe(
      filter(v => v),
      withLatestFrom(this.tokenGenerationService.tokenName()),
      filter(([, tokenName]: [boolean, string]) => tokenName === 'pneuConfigurator'),
      switchMap(() => this.tokenGenerationService.getTokenValue()),
      filter(token => token !== undefined), first())
    .subscribe((tokenValue: string) => {
      const urlParams: string = this.tileItem.urlParameters.split(' ').join('&');
      const url = this.tileItem.externalUrl
        + '?sid=' + encodeURIComponent(tokenValue)
        + '&g=' + encodeURIComponent(this.tileItem.url)
        + '&' + urlParams;

      console.log ('Redirect to Frontend URL', url);
      console.log('token length', tokenValue.length);
      // console.log('encodeURIComponent', encodeURIComponent(tokenValue));
      this.redirectService.post({xml: this.pneuCalculationSchemaXml}, url, String(this.tileItem.openUrlInNewTab) === 'true' ? '_blank' : '_self');
    });
    this.subscription.add(this.tokenGenerationSubscription);

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
