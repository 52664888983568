import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProcessStepSubmitService {
  private submission$: Subject<boolean> = new Subject<boolean>();
  private successfulSubmission$: Subject<boolean> = new Subject<boolean>();
  private activeStepIndex$: Subject<number> = new ReplaySubject<number>();

  constructor() {}

  submit(): void {
    this.submission$.next(true);
  }

  success(): void {
    this.successfulSubmission$.next(true);
  }

  onSubmission(): Observable<boolean> {
    return this.submission$.asObservable();
  }

  onSuccessfulSubmission(): Observable<boolean> {
    return this.successfulSubmission$.asObservable();
  }

  setActiveStepIndex(index: number): void {
    this.activeStepIndex$.next(index)
  }

  getActiveStepIndex(): Observable<number> {
    return this.activeStepIndex$.asObservable();
  }
}
