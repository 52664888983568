import { Component, ElementRef, OnInit } from '@angular/core';
import { AddedToCartDialogComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { map } from 'rxjs/operators';

const LAST_ADDED_CART_ENTRY_INDEX = 0;

@Component({
  selector: 'app-efa-added-to-cart-dialog',
  templateUrl: './efa-added-to-cart-dialog.component.html',
})
export class EfaAddedToCartDialogComponent extends AddedToCartDialogComponent implements OnInit {

  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected launchDialogService: LaunchDialogService,
    protected routingService: RoutingService,
    protected el: ElementRef
  ) {
    super(activeCartFacade, launchDialogService, routingService, el);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.entry$ = this.activeCartFacade.getEntries().pipe(map(entries => entries.filter(entry => !entry.subEntry)[LAST_ADDED_CART_ENTRY_INDEX]));
  }
}

