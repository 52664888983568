import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as MultiAddToCartFeature from '../reducers/multi-add-to-cart.reducer';
import { MULTI_ADD_TO_CART_FEATURE_KEY } from '../reducers/multi-add-to-cart.reducer';
import { CartModificationList } from '../../model/cart-administration.model';
import { CartAdministrationState, getCartAdministrationState } from '../reducers';

export const selectAdding: MemoizedSelector<MultiAddToCartFeature.MultiAddToCartState, boolean> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[MULTI_ADD_TO_CART_FEATURE_KEY].adding
);

export const selectModifications: MemoizedSelector<MultiAddToCartFeature.MultiAddToCartState, CartModificationList> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[MULTI_ADD_TO_CART_FEATURE_KEY].modifications
);
