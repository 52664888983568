import { createSelector, MemoizedSelector } from "@ngrx/store";
import * as AllowedImageExtensionsFeature from '../reducers/allowed-image-extensions.reducer';
import { ALLOWED_IMAGE_EXTENSIONS_FEATURE_KEY } from '../reducers/allowed-image-extensions.reducer';;
import { getSharedState, SharedState } from '../reducers';
import { AllowedFileExtensionsResponse } from "@shared/models/shared.model";

export const selectLoading: MemoizedSelector<AllowedImageExtensionsFeature.AllowedImageExtensionsState, boolean> = createSelector(
  getSharedState,
  (state: SharedState) => state[ALLOWED_IMAGE_EXTENSIONS_FEATURE_KEY].loading
);

export const selectSuccess: MemoizedSelector<AllowedImageExtensionsFeature.AllowedImageExtensionsState, boolean> = createSelector(
  getSharedState,
  (state: SharedState) => state[ALLOWED_IMAGE_EXTENSIONS_FEATURE_KEY].success
);

export const selectResponse: MemoizedSelector<AllowedImageExtensionsFeature.AllowedImageExtensionsState, AllowedFileExtensionsResponse>
  = createSelector(
    getSharedState,
  (state: SharedState) => state[ALLOWED_IMAGE_EXTENSIONS_FEATURE_KEY].response
);