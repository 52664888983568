<div class="filter-title">
  <div class="filter-box">
    <p>Filter nach</p>
    <div>
      <cx-icon [type]="customIconTypes.BROWSE"></cx-icon>
    </div>
  </div>


</div>
<button
  #trigger
  class="btn btn-action btn-block dialog-trigger"
  (click)="launch()"
>
  <cx-icon [type]="iconTypes.FILTER"></cx-icon>
  {{ 'productList.filterBy.label' | cxTranslate }}
</button>

<cx-active-facets></cx-active-facets>

<cx-facet-list
  *ngIf="isOpen$ | async"
  [isDialog]="hasTrigger"
  (closeList)="close()"
  [class.active]="isActive$ | async"
  [class.dialog]="hasTrigger"
></cx-facet-list>
