import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ErrorType} from '@shared/models/ErrorTypes';
import {RegistrationConfirmationActions} from '../actions';
import {RegistrationConfirmationConnector} from '../../occ/connectors/registration-confirmation.connector';
import {GlobalMessageType} from '@spartacus/core';
import {HttpErrorResponse} from '@angular/common/http';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class RegistrationConfirmationEffect {
  registrationConfirmation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegistrationConfirmationActions.loadRegistrationConfirmation),
      concatMap((action) =>
        this.connector.registrationConfirmation(action.token).pipe(
          map(() => {
            return RegistrationConfirmationActions.loadRegistrationConfirmationSuccess();
          }),
          catchError((error: ErrorType) => {
            // error 410 is handled by error handler
            console.log('confirmation error', error);
            if (error instanceof HttpErrorResponse && (error as HttpErrorResponse).status !== 410) {
              this.globalMessageService.add(
                {
                  key: 'efaRegistrationForm.globalMessage.registrationConfirmationFailure',
                },
                GlobalMessageType.MSG_TYPE_ERROR
              );
            }
            return of(RegistrationConfirmationActions.loadRegistrationConfirmationFailure({errorResponse: error}));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: RegistrationConfirmationConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {
  }
}
