<ng-container *ngIf="order$ | async as order">
<div class="c-review px-0 mt-4">
  <h1 class="c-review__title">{{ 'checkoutOrderConfirmation.thankYou' | cxTranslate }}</h1>
  <div class="c-review__container row justify-content-between">
    <div class="c-review__place-order col-sm-12 col-md-7">
      <app-efa-order-confirmation-thank-you-message></app-efa-order-confirmation-thank-you-message>

      <app-efa-place-order [orderInfoFields]="orderOverview.orderInfoFields"
                           [orderType]="orderOverview.orderType"
                           [deliveryAddress]="orderOverview.deliveryAddress"
                           [deliveryMode]="orderOverview.deliveryMode"
                           [requestedDeliveryDate]="orderOverview.requestedDeliveryDate"
                           [readonly]="true"
                           [deactivatePlaceOrder]="deactivatePlaceOrder"></app-efa-place-order>
    </div>

    <div class="col-sm-12 col-md-4 px-0">
      <div class="c-review__summary-card">
        <!-- SHIPPING ADDRESS SECTION -->
        <cx-card *ngIf="deliveryAddress"
                  [content]="getShippingAddressCard(deliveryAddress, countryName$ | async) | async">
        </cx-card>
      </div>

      <!-- ORDER TYPE SECTION -->
      <div class="c-review__summary-card c-review__summary-card--row">
        <div *ngIf="orderType">
          <cx-card *ngIf="deliveryMode"
                    [content]="getOrderType(orderType) | async">
          </cx-card>
        </div>
      </div>

      <!-- DELIVERY MODE SECTION -->
      <div class="c-review__summary-card c-review__summary-card--row">
        <cx-card *ngIf="deliveryMode"
                  [content]="getDeliveryModeCard(deliveryMode) | async">
        </cx-card>
      </div>

      <!-- REQUESTED DELIVERY DATE SECTION -->
      <div class="c-review__summary-card c-review__summary-card--row" *ngIf="displayRequestedDeliveryDate">
        <cx-card *ngIf="requestedDeliveryDate"
                  [content]="getRequestedDeliveryDate(requestedDeliveryDate) | async">
        </cx-card>
      </div>
      <app-efa-order-summary [cart]="order"></app-efa-order-summary>
      <div *ngIf="order.fromConfigurator" class="c-configurator-order-tax-notice-container">
        <p class="c-configurator-order-tax-notice">
          <sup>*</sup> {{ 'checkoutOrder.orderCost.noticeConfiguratorTaxAndDeliveryCosts' | cxTranslate }}
        </p>
      </div>
    </div>

  </div>
</div>
</ng-container>