export const defaultManufacturers = {
  defaultManufacturers: {
    globalMessage: {
      setDefaultManufacturersFailure: 'Das Speichern der Standardmarken schlug fehl. Bitte versuchen Sie es erneut.',
      setDefaultManufacturersSuccess: 'Die Standardmarken wurden erfolgreich gespeichert.',
      defaultManufacturersNotice: 'Bitte wählen Sie die Marken aus, die in anderen Dialogen vorselektiert werden sollen (z.B. in der Markenauswahl im Warenkorb):'
    },
    saveButton: 'Speichern'
  }
};
