<ng-template #details>
  <div class="popover-details">
    <div class="property">
       {{'userProfiles.' + model['identifier'] | cxTranslate}}
    </div>
  </div>
</ng-template>

<!--<p>{{model | json}}</p>-->
<button
  class="button text">
  {{ model.name }}
</button>
<button
      class="info-btn"
      [cxPopover]="details"
      [cxPopoverOptions]="{
        placement: 'auto',
        class: 'my-company-popover',
        appendToBody: true,
        displayCloseButton: true
      }">
</button>

<!--<div class="text">  {{ model.name }}</div>-->

