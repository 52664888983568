<div class="cart-calculation-switch custom-control custom-switch">
  <input type="checkbox" class="custom-control-input" id="flexSwitchAutomaticCartCalculation"
    [(ngModel)]="automaticCalculation" (change)="toggleCartCalculationMode(automaticCalculation)" />
  <label class="custom-control-label"
    for="flexSwitchAutomaticCartCalculation">{{'cartAdministration.cart.calculateModeAutomatic' | cxTranslate }}</label>
</div>
<div class="cart-calculation-mode-hint">
  <cx-icon [type]="iconTypes.HINT"></cx-icon>
  <p
    *ngIf="skipAutomaticCalculation; else manualHint"
    class="cart-calculation-mode-automatic-text">{{ 'cartAdministration.cart.calculateModeAutomaticHint' | cxTranslate }}</p>
  <ng-template #manualHint>
    <p
      class="cart-calculation-mode-automatic-text">{{ 'cartAdministration.cart.calculateModeManualHint' | cxTranslate }}</p>
  </ng-template>
</div>