import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EfaProductFacetNavigationComponent } from './components/efa-product-facet-navigation/efa-product-facet-navigation.component';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { ActiveFacetsModule, FacetListModule, IconModule } from '@spartacus/storefront';



@NgModule({
  declarations: [
    EfaProductFacetNavigationComponent
  ],
  imports: [
    CommonModule,
    FacetListModule,
    ActiveFacetsModule,
    IconModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductRefinementComponent: {
          component: EfaProductFacetNavigationComponent,
        },
      },
    })
  ],
  exports: [EfaProductFacetNavigationComponent]
})
export class ProductFacetNavigationModule { }
