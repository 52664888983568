import { Observable } from 'rxjs';
import {
  ShippingBacklogOverviewRequest,
  ShippingBacklogOverviewResponse,
} from '../../model/shipping-backlog.model';

export abstract class ShippingBacklogOverviewAdapter {
  abstract loadOverview(
    request: ShippingBacklogOverviewRequest
  ): Observable<ShippingBacklogOverviewResponse>;
}
