import {Action, ActionReducer, createReducer, MetaReducer, on} from '@ngrx/store';

import {DeliveryNotesDetailsActions} from '../actions';
import {ErrorType} from '@shared/models/ErrorTypes';
import {DeliveryNoteDetailsResponse} from '../../model/delivery-notes.model';
import {AuthActions, SiteContextActions} from '@spartacus/core';
import { produce } from 'immer';

export const DELIVERY_NOTES_DETAILS_FEATURE_KEY = 'deliveryNotesDetails';

export interface DeliveryNotesDetailsState {
  deliveryNoteNumber: string;
  response: DeliveryNoteDetailsResponse;
  loading: boolean;
  error: ErrorType;
}

export const initialStateDetails: DeliveryNotesDetailsState = {
  deliveryNoteNumber: undefined,
  response: undefined,
  loading: false,
  error: undefined
};

export const deliveryNotesDetailsReducer = createReducer(
  initialStateDetails,

  on(DeliveryNotesDetailsActions.loadDeliveryNoteDetails, (state, action) => ({
    ...state,
    loading: true,
    request: action.deliveryNoteNumber
  })),
  on(DeliveryNotesDetailsActions.loadDeliveryNoteDetailsSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.response,
    request: undefined
  })),
  on(DeliveryNotesDetailsActions.loadDeliveryNoteDetailsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse
  })),
  on(DeliveryNotesDetailsActions.resetDeliveryNoteDetails, (state, action) => ({
    ...initialStateDetails
  })),
);

function authenticationOrLanguageChangedDetails(action: Action, state): any {
  if (action.type === AuthActions.LOGIN ||
    action.type === AuthActions.LOGOUT ||
    action.type === SiteContextActions.LANGUAGE_CHANGE
  ) {
    return produce(state, (draftState) => {
      draftState.deliveryNotes.deliveryNotesDetails = initialStateDetails;
    });
  }
  return state;
}

export function metaReducerFactoryDeliveryNoteDetails(): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const newState = authenticationOrLanguageChangedDetails(action, state);
    return reducer(newState, action);
  };
}
