<ng-container *ngIf="positions?.length > 0; else noReturnablePositions">
  <div class="row table-header">
    <div class="col-lg-8 cols-sm-12 table-title">
      <h5>
        {{ 'returnablePositions.title' | cxTranslate }}
      </h5>
    </div>
    <div class="col-lg-4 cols-sm-12 table-selectAll">
      <button (click)="setAllReturnableQuantity()"
          class="btn btn-link cx-action-link float-left">
        {{'returnablePositions.returnAllArticles' | cxTranslate}}
      </button>
    </div>
  </div>
  <!-- Table -->
  <form (ngSubmit)="onSubmit()" [formGroup]="returnablePositionsForm">
    <table class="c-delivery-note-details__table table table--responsive">
      <thead>
        <tr>
          <th scope="col">{{ 'returnablePositions.positionNumber' | cxTranslate }}</th>
          <th scope="col">{{ 'returnablePositions.oemNumber' | cxTranslate }}</th>
          <th scope="col">{{ 'returnablePositions.deliveredQuantity' | cxTranslate }}</th>
          <th scope="col">{{ 'returnablePositions.name' | cxTranslate }}</th>
          <th scope="col">{{ 'returnablePositions.returnableQuantity' | cxTranslate }}</th>
          <th scope="col">{{ 'returnablePositions.quantityToReturn' | cxTranslate }}</th>
        </tr>
      </thead>
      <tbody formArrayName="items">
        <tr *ngFor="let position of positions, let i = index">
          <td attr.data-label="{{ 'returnablePositions.positionNumber' | cxTranslate }}">
            {{position.positionNumber}}
          </td>
          <td attr.data-label="{{ 'returnablePositions.oemNumber' | cxTranslate }}">
            {{position.oemNumber}}
          </td>
          <td attr.data-label="{{ 'returnablePositions.deliveredQuantity' | cxTranslate }}">
            {{position.deliveredQuantity}}
          </td>
          <td attr.data-label="{{ 'returnablePositions.name' | cxTranslate }}">
            {{position.name}}
          </td>
          <td attr.data-label="{{ 'returnablePositions.returnableQuantity' | cxTranslate }}">
            {{position.returnableQuantity}}
          </td>
          <td attr.data-label="{{ 'returnablePositions.quantityToReturn' | cxTranslate }}" [formGroupName]="i">
            <app-efa-item-counter
                            [max]="position.returnableQuantity"
                            [min]="0"
                            [allowZero]="true"
                            [control]="items.controls[i].get('quantityToReturn')"
                            (isReturnPositionQuantityToReturnChanged)="returnPositionQuantityToReturnChange()">
            </app-efa-item-counter>
            <div *ngIf="items.controls[i].get('quantityToReturn').invalid &&
                 items.controls[i].get('quantityToReturn').errors &&
                 items.controls[i].get('quantityToReturn').dirty || items.controls[i].get('quantityToReturn').touched">
              <small class="text-danger"
                     *ngIf="items.controls[i].get('quantityToReturn').hasError('required')">
                {{ 'returnablePositions.quantityToReturnRequiredError' | cxTranslate }}
              </small>
              <small class="text-danger"
                     *ngIf="items.controls[i].get('quantityToReturn').errors &&
                     items.controls[i].get('quantityToReturn').errors.returnableQuantity">
                {{ 'returnablePositions.quantityToReturnError' | cxTranslate }}
              </small>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Button -->
  </form>
</ng-container>
<ng-template #noReturnablePositions>
  <div class="row">
    <div class="col-12 noPositions">
      <div class="horizontal-line"></div>
      <h2>{{ 'returnablePositions.noReturnablePositions' | cxTranslate }}</h2>
      <!-- <app-no-display-result-notice noticeHeadline="{{ 'returnablePositions.noReturnablePositions' | cxTranslate }}"></app-no-display-result-notice> -->
    </div>
  </div>
</ng-template>
