import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorType } from '@shared/models/ErrorTypes';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { FaqItem } from '../../model/faq-items.model';
import { FaqItemConnector } from '../../occ/connectors/faq-item.connector';
import { FaqItemActions } from '../actions';

@Injectable()
export class FaqItemEffect {
  loadFaqItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FaqItemActions.loadFaqItem),
      concatMap((action) =>
        this.connector
          .getDetails(action.faqItemCode, action.isUserLoggedIn)
          .pipe(
            map((faqItemResponse: FaqItem) => {
              return FaqItemActions.loadFaqItemSuccess({
                response: faqItemResponse,
              });
            }),
            catchError((error: ErrorType) => {
              return of(
                FaqItemActions.loadFaqItemFailure({ errorResponse: error })
              );
            })
          )
      )
    );
  });

  constructor(private actions$: Actions, private connector: FaqItemConnector) {}
}
