import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { SetCartCalculationModeConnector } from "../../occ/connectors/set-cart-calculation-mode.connector";
import { SetCartCalculationModeActions } from "../actions";

@Injectable()
export class SetCartCalculationModeEffect {
  setCartEntryInfos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetCartCalculationModeActions.setCartCalculationMode),
      concatMap((action) =>
        this.connector.setCartCalculationMode(action.cartCalculationMode, action.cartId).pipe(
          map(() => {
            return SetCartCalculationModeActions.setCartCalculationModeSuccess();
          }),
          catchError((error) => {
            return of(SetCartCalculationModeActions.setCartCalculationModeFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: SetCartCalculationModeConnector
  ) {}
}