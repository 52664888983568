import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import * as CategoryActions from './category.actions';
import {Category} from '../model/category.model';
import {OccCategoryAdapter} from '../occ/category.adapter';

@Injectable()
export class CategoryEffects {
  constructor(private actions$: Actions, private categoryAdapter: OccCategoryAdapter) {
  }

  loadCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.LOAD_CATEGORY),
      map((action) => action.payload),
      mergeMap((categoryCodes) =>
        this.categoryAdapter.load(categoryCodes).pipe(
          map((data: Category) => {
            return CategoryActions.LOAD_CATEGORY_SUCCESS({payload: data});
          }),
          catchError((error: Error) => of(CategoryActions.LOAD_CATEGORY_FAIL(error)))
        )
      )
    )
  );
}
