import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as ReturnOverviewFeature from '../reducers/return-overview.reducer';
import { RETURN_OVERVIEW_FEATURE_KEY } from '../reducers/return-overview.reducer';
import { getReturnHistoryState, ReturnHistoryState } from '../reducers';
import { ReturnOverviewResponse } from '../../model/return-history.model';

export const selectLoading: MemoizedSelector<ReturnOverviewFeature.ReturnOverviewState, boolean> = createSelector(
  getReturnHistoryState,
  (state: ReturnHistoryState) => state[RETURN_OVERVIEW_FEATURE_KEY].loading
);

export const selectResponse: MemoizedSelector<ReturnOverviewFeature.ReturnOverviewState, ReturnOverviewResponse> = createSelector(
  getReturnHistoryState,
  (state: ReturnHistoryState) => state[RETURN_OVERVIEW_FEATURE_KEY].response
);
