import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DefaultManufacturersAdapter } from "../adapters/default-manufacturers.adapter";

@Injectable({
  providedIn: 'root',
})
export class DefaultManufacturersConnector {
  constructor(protected adapter: DefaultManufacturersAdapter) {}

  setDeafultManufacturers (defaultManufacturers: string):Observable<void> {
    return this.adapter.setDefaultManufacturers(defaultManufacturers);
  }
}
