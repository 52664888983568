import { Component, Input, OnInit } from '@angular/core';
import { DownloadItem } from '../../model/download-files.model';
import { Media } from '@spartacus/storefront';

@Component({
  selector: 'app-download-item',
  templateUrl: './download-item.component.html',
})
export class DownloadItemComponent implements OnInit {

  @Input() downloadItem: DownloadItem;
  @Input() defaultThumbnail: Media;
  @Input() styleClasses: string;

  constructor() { }

  ngOnInit(): void { }

}
