<div class="modal-container">

    <div class="modal-header">
        <span class="modal-header-title">
            {{ 'eAddToCart.addToCartDialog.title' | cxTranslate }}
        </span>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal(dialogCloseStatus.CLOSED_ROUTE_TO_SAME_PAGE)">
            <cx-icon [type]="iconType.CLOSE_LIGHT"></cx-icon>
            <span class="c-btn-close-text">{{ 'eAddToCart.addToCartDialog.actions.close' | cxTranslate }}</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="outer-container">
            <table class="table table--responsive mb-0" *ngIf="(loaded$| async); else loading">
                <thead>
                    <tr>
                        <th scope="col" class="text-left cartItems-item">{{ 'cartItems.item' | cxTranslate }}</th>
                        <th scope="col" class="text-left cartItems-quantity">{{ 'cartItems.quantity' | cxTranslate }}</th>
                        <th scope="col" class="text-left cartItems-itemPriceRrp">{{ 'cartItems.itemPriceRrp' | cxTranslate }}</th>
                        <th scope="col" class="text-left cartItems-total">{{ 'cartItems.total' | cxTranslate }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td attr.data-label="{{ 'cartItems.item' | cxTranslate }}">
                            <!-- Item Name -->
                            <div *ngIf="product.name" class="article-name">
                                <span>{{ product.name }}</span>
                            </div>
                            <!-- Item Code -->
                            <div *ngIf="product.manufacturerAID" class="article-code">
                                {{ product.manufacturerAID }}
                                <span *ngIf="product.manufacturer; else displayManufacturerId">&nbsp;/&nbsp;{{
                                    product.manufacturer }}</span>
                                <ng-template #displayManufacturerId>
                                    <span *ngIf="product.manufacturerId">&nbsp;/&nbsp;{{ product.manufacturerId
                                        }}</span>
                                </ng-template>
                            </div>
                        </td>
                        <!-- Item Quantity -->
                        <td attr.data-label="{{ 'cartItems.quantity' | cxTranslate }}">
                            <app-efa-item-counter class="quantity-selector" [control]="addToCartForm.get('quantity')" [readonly]="false"
                                [allowZero]="true">
                            </app-efa-item-counter>
                        </td>
                        <!-- Item Price -->
                        <td attr.data-label="{{ 'cartItems.itemPriceRrp' | cxTranslate }}"><span
                                *ngIf="listPricePerUnit" class="price-dialog">{{ listPricePerUnit.formattedValue }}</span></td>
                        <!-- Total Price -->
                        <td attr.data-label="{{ 'cartItems.total' | cxTranslate }}"><span *ngIf="totalPrice"
                                class="price-dialog">{{ totalPrice.value |
                                currency:totalPrice.currencyIso:'symbol':'1.2-2':language }}</span></td>
                    </tr>

                </tbody>
            </table>

            <ng-template #loading>
                <cx-spinner></cx-spinner>
            </ng-template>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-secondary c-btn-add-to-cart" type="button" (click)="addToCart(dialogCloseStatus.SUCCESS_ROUTE_TO_CONFIGURED_PAGE)"
            [disabled]="!(loaded$ | async)">
            {{ 'eAddToCart.addToCartDialog.actions.goToCart' | cxTranslate }}
        </button>
        <button class="btn btn-primary c-btn-add-to-cart" type="button" (click)="addToCart(dialogCloseStatus.SUCCESS_ROUTE_TO_SAME_PAGE)"
            [disabled]="!(loaded$ | async)">
            {{ 'eAddToCart.addToCartDialog.actions.returnToCurrentPage' | cxTranslate }}
        </button>
    </div>
</div>
