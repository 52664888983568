import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as ReturnOverViewReducers from './return-overview.reducer';
import * as ReturnDetailsReducers from './return-details.reducer';
import * as ReturnHistoryUiReducers from './return-history-ui.reducer';

export const RETURN_HISTORY_FEATURE_KEY = 'returnHistory';

export interface ReturnHistoryState {
  [ReturnOverViewReducers.RETURN_OVERVIEW_FEATURE_KEY]: ReturnOverViewReducers.ReturnOverviewState;
  [ReturnDetailsReducers.RETURN_DETAILS_FEATURE_KEY]: ReturnDetailsReducers.ReturnDetailsState;
  [ReturnHistoryUiReducers.RETURN_HISTORY_UI_FEATURE_KEY]: ReturnHistoryUiReducers.ReturnHistoryUiState;
}

export const reducers: ActionReducerMap<ReturnHistoryState> = {
  [ReturnOverViewReducers.RETURN_OVERVIEW_FEATURE_KEY]: ReturnOverViewReducers.returnOverviewReducer,
  [ReturnDetailsReducers.RETURN_DETAILS_FEATURE_KEY]: ReturnDetailsReducers.returnDetailsReducer,
  [ReturnHistoryUiReducers.RETURN_HISTORY_UI_FEATURE_KEY]: ReturnHistoryUiReducers.returnHistoryUiReducer,
};

export const getReturnHistoryState = createFeatureSelector<ReturnHistoryState>(RETURN_HISTORY_FEATURE_KEY);
