import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {ErrorModel, GlobalMessageType, HttpErrorHandler, Priority} from '@spartacus/core';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationErrorHandler extends HttpErrorHandler {
  constructor(protected globalMessageService: EfaGlobalMessageService) {
    super(globalMessageService);
  }

  responseStatus = 410;

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handleTokenWasRefreshedError(request, response);
  }

  protected handleTokenWasRefreshedError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.getErrors(response)
      .filter((e) => this.tokenWasRefreshed(e) || this.registrationAlreadyConfirmed(e) ||  this.tokenNotFound(e))
      .forEach((e) => {
        if (this.tokenWasRefreshed(e)){
        this.globalMessageService.add({key: 'efaHttpHandlers.tokenWasRefreshed'}, GlobalMessageType.MSG_TYPE_WARNING);
        }
        else if (this.registrationAlreadyConfirmed(e)) {
          this.globalMessageService.add({key: 'efaHttpHandlers.registrationAlreadyConfirmed'}, GlobalMessageType.MSG_TYPE_INFO);
        }
        else {
          this.globalMessageService.add({key: 'efaHttpHandlers.tokenNotFound'}, GlobalMessageType.MSG_TYPE_ERROR);
        }
      });
  }

  tokenWasRefreshed(error: ErrorModel): boolean {
    return error.type === 'TokenWasRefreshed';
  }

  tokenNotFound(error: ErrorModel): boolean {
    return error.type === 'TokenNotFound';
  }

  registrationAlreadyConfirmed(error: ErrorModel): boolean {
    return error.type === 'RegistrationAlreadyConfirmed';
  }
  protected getErrors(response: HttpErrorResponse): ErrorModel[] {
    return (response.error?.errors || []);
  }

  getPriority(): Priority {
    return Priority.LOW;
  }
}
