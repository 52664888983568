import { createAction, props } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import { ShippingBacklogOverviewFilter } from '../../model/shipping-backlog.model';

export const setReturningFromDetails = createAction(
  '[ShippingBacklog] Set Returning From Details',
  props<{ returningFromDetails: boolean }>()
);

export const setSelectedFilterEntity = createAction(
  '[ShippingBacklog] Set Selected Filter Entity',
  props<{ selectedFilterEntity: ShippingBacklogOverviewFilter }>()
);

export const setSelectedPagination = createAction(
  '[ShippingBacklog] Set Selected Pagination',
  props<{ selectedPagination: PaginationModel }>()
);

export const setSelectedSorting = createAction(
  '[ShippingBacklog] Set Selected Sort',
  props<{ selectedSort: SortModel }>()
);
