import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Country } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RegistrationStatus } from '../../model/login-register-enum.model';
import { RegistrationData } from '../../model/login-register.model';
import { AbstractRegistrationStepComponent } from '../abstract-registration-step/abstract-registration-step.component';

@Component({
  selector: 'app-registration-company',
  templateUrl: './registration-company.component.html',
})
export class RegistrationCompanyComponent extends AbstractRegistrationStepComponent implements OnInit {
  countries$: Observable<Country[]>;
  titles: string[] = ['MALE', 'FEMALE', 'OTHER'];

  registrationCompanyForm: UntypedFormGroup = this.formBuilder.group({
    companyName: ['', Validators.required],
    roleCode: ['', [Validators.required]],
    titleCode: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    taxId: ['', Validators.required],
    phone: ['', Validators.required],
    email: ['', [Validators.required, CustomFormValidators.emailValidator]],
    street: ['', Validators.required],
    streetNumber: ['', Validators.required],
    postalCode: ['', Validators.required],
    town: ['', Validators.required],
    countryIsoCode: ['', Validators.required],
    businessTypeCode: ['', [Validators.required]],
    specializationCodes: new UntypedFormArray([]),
    membership: [''],
    membershipId: [''],
  });
  private languageIsoCode: string;

  ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(this.languageService.getActive().subscribe(lang => this.languageIsoCode = lang));
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountries();
        }
      }),
    );
  }

  protected getStatus(): RegistrationStatus {
    return RegistrationStatus.INPROGRESS;
  }

  protected onSubmit(registrationData: RegistrationData): void {
    this.registrationCompanyForm.markAllAsTouched();
    if (this.registrationCompanyForm.invalid) {
      this.scrollToFirstInvalidControl();
      return;
    }
    const registrationSpecificationBusinessTypeCodes: string[] = [];
    this.registrationSpecificBusinessTypeFormControls.forEach((control, index) => {
      if (control.value) {
        registrationSpecificationBusinessTypeCodes.push(
          this.registrationInformationMultiOptionsFieldsValues.registrationSpecificBusinessTypes[index].code);
      }
    });

    const registrationInformationRequest: RegistrationData = {
      ...registrationData,
      companyName: this.registrationCompanyForm.value.companyName,
      roleCode: this.registrationCompanyForm.value.roleCode,
      genderCode: this.registrationCompanyForm.value.titleCode,
      firstName: this.registrationCompanyForm.value.firstName,
      lastName: this.registrationCompanyForm.value.lastName,
      incomeTaxId: this.registrationCompanyForm.value.taxId,
      phone: this.registrationCompanyForm.value.phone,
      email: this.registrationCompanyForm.value.email,
      street: this.registrationCompanyForm.value.street,
      streetNumber: this.registrationCompanyForm.value.streetNumber,
      postalCode: this.registrationCompanyForm.value.postalCode,
      town: this.registrationCompanyForm.value.town,
      countryIsocode: this.registrationCompanyForm.value.countryIsoCode,
      businessTypeCode: this.registrationCompanyForm.value.businessTypeCode,
      specializationCodes: registrationSpecificationBusinessTypeCodes,
      membership: this.registrationCompanyForm.value.membership,
      membershipId: this.registrationCompanyForm.value.membershipId,
      languageIsocode: this.languageIsoCode
    };
    this.submit(registrationInformationRequest);

  }

  get registrationSpecificBusinessTypeFormControls(): AbstractControl[] {
    return (this.registrationCompanyForm.get('specializationCodes') as UntypedFormArray).controls;
  }

  protected fillFormDataFromResponse(registrationData: RegistrationData): void {
    this.registrationCompanyForm.get('companyName').patchValue(registrationData.companyName);
    this.registrationCompanyForm.get('roleCode').patchValue(registrationData.roleCode);
    this.registrationCompanyForm.get('titleCode').patchValue(registrationData.genderCode);
    this.registrationCompanyForm.get('firstName').patchValue(registrationData.firstName);
    this.registrationCompanyForm.get('lastName').patchValue(registrationData.lastName);
    this.registrationCompanyForm.get('taxId').patchValue(registrationData.incomeTaxId);
    this.registrationCompanyForm.get('email').patchValue(registrationData.email);
    this.registrationCompanyForm.get('phone').patchValue(registrationData.phone);
    this.registrationCompanyForm.get('street').patchValue(registrationData.street);
    this.registrationCompanyForm.get('streetNumber').patchValue(registrationData.streetNumber);
    this.registrationCompanyForm.get('postalCode').patchValue(registrationData.postalCode);
    this.registrationCompanyForm.get('countryIsoCode').patchValue(registrationData.countryIsocode);
    this.registrationCompanyForm.get('businessTypeCode').patchValue(registrationData.businessTypeCode);
    this.registrationCompanyForm.get('town').patchValue(registrationData.town);
    this.registrationCompanyForm.get('membership').patchValue(registrationData.membership);
    this.registrationCompanyForm.get('membershipId').patchValue(registrationData.membershipId);

    const specializationCodes : string [] = registrationData.specializationCodes;
    const registrationSpecificBusinessTypeFormArray: UntypedFormArray = this.registrationCompanyForm.get('specializationCodes') as UntypedFormArray;
    this.registrationInformationMultiOptionsFieldsValues.registrationSpecificBusinessTypes.forEach((businessType, index) => {
        if (specializationCodes?.includes(businessType.code)) {
          return (registrationSpecificBusinessTypeFormArray.controls[index] as UntypedFormControl).patchValue(true);
        }
    });
  }

  protected initializeMultiOptionFields(): Observable<boolean> {
    const registrationSpecificBusinessTypeFormArray: UntypedFormArray = this.registrationCompanyForm.get('specializationCodes') as UntypedFormArray;
    if (registrationSpecificBusinessTypeFormArray.length === 0) {
      this.registrationInformationMultiOptionsFieldsValues.registrationSpecificBusinessTypes.forEach(() => {
          return  registrationSpecificBusinessTypeFormArray.push(new UntypedFormControl(false))
      });
    }

    return of(true);
  }
}
