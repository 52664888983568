import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as InvoiceCreditOverviewReducers from './invoice-credit-overview.reducers';
import * as InvoiceCreditDetailsReducers from './invoice-credit-details.reducers';
import * as InvoiceCreditUiReducers from './invoice-credit-ui.reducers';

export const invoiceCreditFeatureKey = 'invoiceCredit';

export interface InvoiceCreditState {
  [InvoiceCreditOverviewReducers.INVOICE_CREDIT_OVERVIEW_FEATURE_KEY]: InvoiceCreditOverviewReducers.InvoiceCreditOverviewState;
  [InvoiceCreditDetailsReducers.INVOICE_CREDIT_DETAILS_FEATURE_KEY]: InvoiceCreditDetailsReducers.InvoiceCreditDetailsState;
  [InvoiceCreditUiReducers.INVOICE_CREDIT_UI_FEATURE_KEY]: InvoiceCreditUiReducers.InvoiceCreditUiState;
}

export const reducers: ActionReducerMap<InvoiceCreditState> = {
  [InvoiceCreditOverviewReducers.INVOICE_CREDIT_OVERVIEW_FEATURE_KEY]: InvoiceCreditOverviewReducers.invoiceCreditOverviewReducer,
  [InvoiceCreditDetailsReducers.INVOICE_CREDIT_DETAILS_FEATURE_KEY]: InvoiceCreditDetailsReducers.invoiceCreditDetailsReducer,
  [InvoiceCreditUiReducers.INVOICE_CREDIT_UI_FEATURE_KEY]: InvoiceCreditUiReducers.invoiceCreditUiReducer
};

export const getInvoiceCreditState = createFeatureSelector<InvoiceCreditState>(invoiceCreditFeatureKey);
