import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export function  getInitialDateFrom(offset: number): NgbDateStruct {
  const initialDate = new Date(Date.now() - offset * 24 * 60 * 60 * 1000);
  return {
    day: initialDate.getDate(),
    month: initialDate.getMonth() + 1,
    year: initialDate.getFullYear()
  };
}
