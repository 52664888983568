import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ConfiguratorCartActions } from '../actions';
import { CartConnector } from '@spartacus/cart/base/core';

@Injectable()
export class ConfiguratorCartEffect {
  
  loadCarts$ = createEffect(() => {
     return this.actions$.pipe(
       ofType(ConfiguratorCartActions.loadConfiguratorCart),
       concatMap((action) =>
         this.connector.loadAll(action.userId).pipe(
           map((response) => {
             const configuratorCarts = [];
             const configuratorCart = response.find((cart => cart != null && cart.fromConfigurator === true));
             if (configuratorCart != null) {
               configuratorCarts.push(configuratorCart);
             }
             return ConfiguratorCartActions.loadConfiguratorCartSuccess({ response: configuratorCarts });
           }),
           catchError((error) => {
             return of(ConfiguratorCartActions.loadConfiguratorCartFailure(error));
           })
         )
       )
     );
   });

   constructor(private actions$: Actions, private connector: CartConnector) {}
}
