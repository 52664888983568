import { Injectable } from '@angular/core';

import { Converter, Occ } from '@spartacus/core';
import OrderEntryList = Occ.OrderEntryList;

import { AddToCartParam } from '../../model/cart-administration.model';

@Injectable({ providedIn: 'root' })
export class MultiAddToCartSerializer implements Converter<AddToCartParam[], OrderEntryList> {

  convert(source: AddToCartParam[], target?: OrderEntryList): OrderEntryList {
    if (target === undefined) {
      target = {} as OrderEntryList;
      target.orderEntries = [];
    }

    // for each element in AddToCartParam[]
    // entryNumber of orderEntry can be empty
    source.forEach((addToCartParam: AddToCartParam) => {
      target.orderEntries.push({
        substitutedOemNumber: addToCartParam.substitutedOEMNumber,
        consignmentInfo: addToCartParam.consignmentInfo,
        quantity: addToCartParam.quantity,
        product: {
          code: addToCartParam.productCode,
          manufacturerAID: addToCartParam.articleNumber,
          manufacturerId: addToCartParam.manufacturerId,
          orderInfoRefId: addToCartParam.orderInfoRefId,
          requiredDeliveryMode: {
            code: addToCartParam.requiredShippingTypeID
          }
        },
        info: addToCartParam.materialName,
        productName: addToCartParam.materialName,
        unitId: addToCartParam.unitId,
        customerBasePrice: addToCartParam.customerPricePerUnit,
        basePrice: addToCartParam.listPricePerUnit,
        deliveryItems: addToCartParam.deliveryItems,
      });
    });
    return target;
  }
}
