import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { AuthActions } from '@spartacus/core';
import { produce } from 'immer';
import { InvoicesCreditDetailsResponse } from '../../model/invoices-credits.model';
import { InvoiceCreditDetailsActions } from '../actions';

export const INVOICE_CREDIT_DETAILS_FEATURE_KEY = 'invoiceCreditDetails';

export interface InvoiceCreditDetailsState {
  documentNumber: string;
  response: InvoicesCreditDetailsResponse;
  loading: boolean;
  error: ErrorType;
}

export const initialStateDetails: InvoiceCreditDetailsState = {
  documentNumber: undefined,
  response: undefined,
  loading: false,
  error: undefined
};

export const invoiceCreditDetailsReducer = createReducer(
  initialStateDetails,

  on(InvoiceCreditDetailsActions.loadInvoiceCreditDetails, (state, action) => ({
    ...state,
    loading: true,
    request: action.documentNumber,
  })),
  on(InvoiceCreditDetailsActions.loadInvoiceCreditDetailsSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.invoiceDetailsResponse,
  })),
  on(InvoiceCreditDetailsActions.loadInvoiceCreditDetailsFaillure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse,
  })),
);

function authenticationChangedDetails(action: Action, state): any {
  if (action.type === AuthActions.LOGIN || action.type === AuthActions.LOGOUT) {
    const nextState = produce(state, (draftState) => {
      draftState.invoiceCredit.invoiceCreditDetails = initialStateDetails;
    });
    return nextState;
  }
  return state;
}

export function metaReducerFactoryInvoiceDetails(): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const newState = authenticationChangedDetails(action, state);
    return reducer(newState, action);
  };
}
