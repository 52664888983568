import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Observable, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RoutingService} from '@spartacus/core';

import {ReturnablePosition, ReturnDocumentType} from '../../model/return-creation.model';
import {DeliveryNotesDetailsService} from '@core/delivery-notes/delivery-notes-details.service';
import {DeliveryNoteDetailsPosition, DeliveryNoteDetailsResponse} from '../../../delivery-notes/model/delivery-notes.model';
import { ReturnablePositionsComponent } from '../returnable-positions/returnable-positions.component';

@Component({
  selector: 'app-delivery-note-returnable-positions',
  templateUrl: './delivery-note-returnable-positions.component.html',
})
export class DeliveryNoteReturnablePositionsComponent implements OnInit, OnDestroy {
  deliveryNotesDetails$: Observable<DeliveryNoteDetailsResponse>;
  returnablePositions: ReturnablePosition[];
  documentNumber: string;
  documentType: string = ReturnDocumentType.DeliveryNote;
  deliveryNoteReturnCreationRoute = 'deliveryNoteReturnCreation';
  deliveryNoteDetailsRoute = 'deliveryNoteDetails';
  routeOrigin: string;
  isSubmitButtonEnabled = false;
  @ViewChild(ReturnablePositionsComponent) childcmp:ReturnablePositionsComponent;

  private subscription: Subscription = new Subscription();

  constructor(
    protected deliveryNotesDetailsService: DeliveryNotesDetailsService,
    private routingService: RoutingService,
  ) {
  }

  ngOnInit(): void {
    this.deliveryNotesDetailsService.reset();
    this.deliveryNotesDetails$ = this.deliveryNotesDetailsService.getDeliveryNotesDetailsResponse(true, false).pipe(tap((response: DeliveryNoteDetailsResponse) => {
      this.documentNumber = (response?.deliveryNoteNumber) ? response.deliveryNoteNumber : '';
      this.setReturnablePosition(response?.positions);
    }));
    
    this.subscription.add(
      this.routingService.getRouterState().pipe(
        map((state) => this.routeOrigin = state.state.url)
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setReturnablePosition(deliveryNoteDetailsPosition: DeliveryNoteDetailsPosition[]): void {
    this.returnablePositions = deliveryNoteDetailsPosition?.map((item) => ({
      positionNumber: item.positionNumber,
      name: item.positionText,
      oemNumber: item.oemNumber,
      deliveredQuantity: item.deliveredQuantity,
      returnableQuantity: item.returnableQuantity,
      quantityToReturn: null,
      reasonCode: null,
      comment: null,
      containsDeposit: item.isDeposit,
      materialNumber: item.materialNumber,
      depositOnly: item.depositOnly,
    }));
  }

  submit() {
    this.childcmp.onSubmit();
  }
  setSubmitActiveStatus (isActive: boolean): void {
    this.isSubmitButtonEnabled = isActive
  }

}
