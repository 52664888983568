<div ngbAccordion #acc="ngbAccordion">
    <div ngbAccordionItem="returnOverviewFilterPanelOne" #returnOverviewFilterPanelOne="ngbAccordionItem">
        <div ngbAccordionHeader>
            <div class="justify-content-between">
                <h5 class="mb-0">
                    <button ngbAccordionToggle class="btn btn-block btn-filter">
                        <cx-icon [type]="iconTypes.BROWSE" class="mr-2">
                          <span class="btn-filter-text">{{ 'returnOverview.myAccount.filter' | cxTranslate }}</span>
                        </cx-icon>
                    </button>
                </h5>
            </div>
        </div>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <form [formGroup]="returnOverviewFilterForm" class="row">
                        <div class="col-md-4">
                            <label class="form-group">
                                <span class="label-content">
                                    {{ 'returnOverview.returnOverviewFilterForm.returnNumber.label' | cxTranslate }}
                                </span>
                                <input class="form-control" type="text" formControlName="returnNumber"
                                    (keydown.enter)="triggerOnChanges('returnNumber', $event)"
                                    placeholder="{{ 'returnOverview.returnOverviewFilterForm.returnNumber.placeholder' | cxTranslate }}" />
                            </label>
                        </div>
        
                        <div class="col-md-4">
                            <label class="form-group">
                                <span class="label-content">
                                    {{ 'returnOverview.returnOverviewFilterForm.deliveryNoteNumber.label' | cxTranslate }}
                                </span>
                                <input class="form-control" type="text" formControlName="deliveryNoteNumber"
                                    (keydown.enter)="triggerOnChanges('deliveryNoteNumber', $event)"
                                    placeholder="{{ 'returnOverview.returnOverviewFilterForm.deliveryNoteNumber.placeholder' | cxTranslate }}" />
                            </label>
                        </div>
        
                        <div class="col-md-4">
                            <label class="form-group select-arrow">
                              <span class="label-content">{{ 'returnOverview.returnOverviewFilterForm.status.label' | cxTranslate }}</span>
                              <select class="form-control" formControlName="statusCode" [ngClass]="getSelectPlaceholderTextClass(returnOverviewFilterForm.get('statusCode').value)">
                                <option value="" selected>
                                  {{ 'returnOverview.returnOverviewFilterForm.status.placeholder' | cxTranslate }}
                                </option>
                                <option *ngFor="let returnState of availableReturnStates"
                                        [value]="returnState.id">
                                  {{ returnState.name }}
                                </option>
                              </select>
                            </label>
                          </div>
        
                        <div class="col-md-4">
                            <label class="form-group">
                                <span class="label-content">
                                    {{ 'returnOverview.returnOverviewFilterForm.dateFrom.placeholder' | cxTranslate }}
                                </span>
                                <div class="input-group">
                                    <input class="form-control" ngbDatepicker #df="ngbDatepicker" formControlName="dateFrom"
                                        [maxDate]="maxDate" [readonly]="true" efaNgbDatepickerNavigationTooltipsRemove
                                        placeholder="{{ 'returnOverview.returnOverviewFilterForm.dateFrom.placeholder' | cxTranslate }}"
                                        container="body" />
                                    <div class="input-group-append">
                                        <button class="btn btn-primary d-flex" (click)="df.toggle()" type="button">
                                            <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                                        </button>
                                    </div>
                                </div>
                            </label>
                        </div>
        
                        <div class="col-md-4">
                            <label class="form-group">
                                <span class="label-content">
                                    {{ 'returnOverview.returnOverviewFilterForm.dateTo.placeholder' | cxTranslate }}
                                </span>
                                <div class="input-group">
                                    <input class="form-control" ngbDatepicker #dt="ngbDatepicker" formControlName="dateTo"
                                        [maxDate]="maxDate" [readonly]="true" efaNgbDatepickerNavigationTooltipsRemove
                                        placeholder="{{ 'returnOverview.returnOverviewFilterForm.dateTo.placeholder' | cxTranslate }}"
                                        container="body" />
                                    <div class="input-group-append">
                                        <button class="btn btn-primary d-flex" (click)="dt.toggle()" type="button">
                                            <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                                        </button>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </form>
                    <div class="row justify-content-end">
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <button type="button" (click)="clearForm()" style="color: #003899;"
                                class="btn btn-block btn-secondery">{{ 'clearForm' | cxTranslate }}</button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
