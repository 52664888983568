export const tiresWarranty = {
  tiresWarranty: {
    globalMessage: {
      startTiresWarrantyFailure: 'Die Daten zur Reifen Garantie konnten nicht übertragen werden. Bitte versuchen Sie es erneut.',
      startTiresWarrantySuccess: 'Vielen Dank! Ihre Daten wurden erfolgreich übertragen. Eine E-Mail mit allen Informationen wurde an die von Ihnen angegebene E-Mail-Adresse versendet.'
    },
    tiresWarrantyHeader: {
      title: '24 Monate Reifengarantie!',
      description: 'Seit April 2017 gewähren wir auf lose Reifen, die Sie über die EFA Autoteilewelt bezogen haben, eine Reifengarantie von 24 Monaten ab Verkauf an den Endverbraucher für nachfolgende Reifenfabrikate.',
      goToForm: 'Garantie registrieren',
      goToFooter: 'Schadensfall melden'
    },
    tiresWarrantyFooter: {
      title: 'Reifengarantie Schadensabwicklung',
      text1: 'Im Schadensfall wenden Sie sich bitte per E-Mail an',
      text2: 'oder telefonisch an',
      text3: 'Stichwort: Reifengarantie.',
      text4: 'Garantiepass und Originalrechnung bitte eingescannt an',
      text5: 'senden oder alternativ gerne auch via Post an die folgende Anschrift:',
      email: 'efa-reifen@autoteilewelt.de',
      phone: '0800-0001 883 47',
      address1: 'EFA Autoteilewelt GmbH',
      address2: 'Abt. Reklamation Reifen',
      address3: 'Liebstädter Straße 5',
      address4: '01277 Dresden',
      goToForm: 'Zurück nach oben'
    },
    tiresWarrantyForm: {
      formTitle: 'Garantiebeginn registrieren',
      formSubTitle: 'Registrieren Sie hier schnell und einfach Ihre 24 Monate Reifengarantie. Alle Informationen und Ihre Reifengarantie im PDF Format werden im Anschluss an Ihre angegebene E-Mail Adresse gesendet.',
      formMandatoryFields: 'Pflichtfelder sind mit einem * gekennzeichnet.',
      legend1: '1. Fachhändler',
      legend2: '2. Reifendaten / KFZ Daten',
      legend3: '3. Kundendaten',
      legend4: '4. Garantiepass registrieren',
      dealerData: {
        companyName: {
          lable: 'Fachhändler Name',
          placeholder: 'Name Ihrer Firma'
        },
        customerNumber: {
          lable: 'EFA Kundennummer',
          placeholder: 'Kundennummer'
        },
        street: {
          lable: 'Straße',
          placeholder: 'Straße'
        },
        streetnumber: {
          lable: 'Hausnummer',
          placeholder: 'Hausnummer'
        },
        countryIsoCode: {
          lable: 'Land',
          placeholder: 'Bitte wählen'
        },
        postalCode: {
          lable: 'PLZ',
          placeholder: 'PLZ'
        },
        town: {
          lable: 'Ort',
          placeholder: 'Ort'
        },
        phone: {
          lable: 'Telefonnummer',
          placeholder: 'Telefon'
        },
        email: {
          lable: 'E-Mail Adresse',
          placeholder: 'E-Mail Adresse'
        },
        referenceNumber: {
          lable: 'Bezugsnachweisnummer',
          placeholder: 'Bezugsnachweisnummer'
        },
        referenceTypeCode: {
          lable: 'Bezugsnachweistyp',
          code: {
            INVOICE: 'Rechnungs Nr.',
            DELIVERY_NOTE: 'Lieferschein Nr.',
            ORDER: 'Bestellreferenz Nr.'
          }
        },
        invoiceDocument: {
          lable: 'Verkaufs-/Montagerechnung',
          placeholder: 'PDF Datei hochladen'
        },
      },
      tireCarData: {
        installatonDate: {
          lable: 'Montagedatum der Reifen',
          placeholder: 'TT.MM.JJJJ'
        },
        tireBrand: {
          lable: 'Marke der Reifen',
          placeholder: 'Bitte wählen'
        },
        quantity: {
          lable: 'Menge',
          placeholder: 'Menge'
        },
        tireSize: {
          lable: 'Reifengröße',
          placeholder: 'Reifengröße'
        },
        tireProfile: {
          lable: 'Profil',
          placeholder: 'Profil'
        },
        carBrand: {
          lable: 'KFZ Marke / Typ',
          placeholder: 'KFZ Marke / Typ'
        },
        numberPlate: {
          lable: 'Kennzeichen',
          placeholder: 'Kennzeichen'
        },
        totalKilometers: {
          lable: 'km-Stand',
          placeholder: 'km-Stand'
        }

      },
      customerData: {
        titleCode: {
          lable: 'Anrede',
          gender: {
            MALE: 'Herr',
            FEMALE: 'Frau',
            OTHER: 'Divers',
            COMPANY: 'Firma'
          }
        },
        name: {
          lable: 'Vor- und Nachname / Firmenname',
          placeholder: 'Ihr Vor- und Nachname oder Firmenname'
        },
        street: {
          lable: 'Straße',
          placeholder: 'Straße'
        },
        streetnumber: {
          lable: 'Hausnummer',
          placeholder: 'Hausnummer'
        },
        countryIsoCode: {
          lable: 'Land',
          placeholder: 'Bitte wählen'
        },
        postalCode: {
          lable: 'PLZ',
          placeholder: 'PLZ'
        },
        town: {
          lable: 'Ort',
          placeholder: 'Ort'
        },
        phone: {
          lable: 'Telefonnummer',
          placeholder: 'Telefon'
        }

      },
      warrantyTerms : {
        lable: 'Garantiebedingungen',
        placeholder: 'Die Garantiebedingungen der EFA Autoteilewelt GmbH wurden dem Kunden überreicht.'
      },
      submit: 'Formular absenden'
    }
  }
};
