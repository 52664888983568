import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';

import { ReturnCreationRequest, ReturnCreationResponse } from '../../../model/return-creation.model';
import { ReturnCreationAdapter } from '../return-creation.adapter';

@Injectable({providedIn: 'root'})
export class OccReturnCreationAdapter implements ReturnCreationAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  doReturnCreation(request: ReturnCreationRequest): Observable<ReturnCreationResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'returnCreation',
      {
        urlParams: {
          userId: 'current'
        }
      }
    );

    return this.http.post<ReturnCreationResponse>(url, request, {headers});
  }
}
