import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICON_TYPE, ProcessNavigationComponentData } from '@shared/models/shared.model';
import { ProcessStepSubmitService } from '@shared/services/process-step-submit.service';
import { RoutingService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-process-navigation',
  templateUrl: './process-navigation.component.html',
})
export class ProcessNavigationComponent implements OnInit, OnDestroy {
  componentData$: Observable<ProcessNavigationComponentData> = this.component.data$;
  readonly iconType = ICON_TYPE;

  private subscription: Subscription = new Subscription();
  
  constructor(
    protected component: CmsComponentData<ProcessNavigationComponentData>,
    protected submitService: ProcessStepSubmitService,
    protected routingService: RoutingService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.submitService
      .onSuccessfulSubmission()
      .pipe(withLatestFrom(this.componentData$))
      .subscribe(([isSuccessful, componentData]: [boolean, ProcessNavigationComponentData]) => {
        const nextRoute: string = componentData?.nextRoute;
        if (isSuccessful && nextRoute != null) {
          this.routingService.go({
            cxRoute: nextRoute
          });
        }
      }));
  }

  onFinalize(): void {
    this.submitService.submit();
  }

  onNext(): void {
    this.submitService.submit();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
