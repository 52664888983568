import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CartAndCheckoutLoadingService } from '@shared/services/cart-and-checkout-loading.service';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { ActiveCartFacade, Cart, DeliveryMode } from '@spartacus/cart/base/root';
import { Address, GlobalMessageType } from '@spartacus/core';
import { Observable, combineLatest } from 'rxjs';
import { OrderType } from '../../cart-administration/model/cart-administration.model';
import { UpdateCartArg } from '../../cart-administration/model/update-cart.model';
import { B2BUserAccountService } from '../../cart-administration/services/b2-b-user-account.service';
import { CartParametersService } from '../../cart-administration/services/cart-parameters.service';
import { UpdateCartService } from '../../cart-administration/services/update-cart.service';
import { OrderInfoFieldsFormsValidationService } from './order-info-fields-forms-validation.service';

@Injectable({
  providedIn: 'root'
})
export class EfaValidateCartGuard  {
  private ot: OrderType;
  private dm: DeliveryMode;
  private rd: NgbDateStruct;
  private ra: Address;
  private ac: Cart;
  private startCheckout = false;

  constructor(protected b2bUserAccountService: B2BUserAccountService,
              protected cartParametersService: CartParametersService,
              protected updateCartService: UpdateCartService,
              protected messageService: EfaGlobalMessageService,
              protected orderInfoFieldsFormsValidationService: OrderInfoFieldsFormsValidationService,
              protected activeCartService: ActiveCartFacade,
              protected cartAndCheckoutLoadingService: CartAndCheckoutLoadingService) {

    combineLatest([this.cartParametersService.getCurrentOrderType(),
      this.cartParametersService.getCurrentShippingType(),
      this.cartParametersService.getCurrentDeliveryAddress(),
      this.cartParametersService.getRequestDeliveryDate(),
      this.activeCartService.getActive()])
      .subscribe(([ot, dm, ra, rd, ac]) => {
        this.ot = ot;
        this.dm = dm;
        this.ra = ra;
        this.rd = rd;
        this.ac = ac;
      });

    this.updateCartService.isUpdating().subscribe(next => {
      if (this.startCheckout && !next) {
        this.cartAndCheckoutLoadingService.setCartItemListLoading(false);
        this.startCheckout = false;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.orderInfoFieldsFormsValidationService.getAllValid()) {
      this.orderInfoFieldsFormsValidationService.markAllAsTouched();
      this.messageService.add({key: 'checkoutOrder.globalMessage.mandatoryOrderInfoFieldsMissing'},
        GlobalMessageType.MSG_TYPE_ERROR);
      return false;
    }

    let ret = true;
    if (this.ot === undefined || this.dm === undefined || this.ra === undefined) {
      this.orderInfoFieldsFormsValidationService.markAllAsTouched();
      this.messageService.add({key: 'checkoutOrder.globalMessage.mandatoryHeaderFieldsMissing'},
        GlobalMessageType.MSG_TYPE_ERROR);
      ret = false;
    } else {
      if (this.rd === undefined) {

        const currentDate = new Date();
        this.rd = {
          day: currentDate.getDate(),
          month: currentDate.getMonth() + 1,
          year: currentDate.getFullYear()
        };
      }
      const arg: UpdateCartArg = {
        cartId: this.b2bUserAccountService.cartId,
        userId: this.b2bUserAccountService.userId,
        calculate: !this.ac.fromConfigurator,
        orderTypeId: this.ot.id,
        shippingTypeId: this.dm.code,
        addressId: this.ra.id,
        requestedDeliveryDate: this.rd.year + '-' + this.rd.month + '-' + this.rd.day,
        reloadCart: true
      };

      this.startCheckout = true;
      this.updateCartService.updateCart(arg);

    }
    return ret;
  }
}
