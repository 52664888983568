import { createReducer, on } from "@ngrx/store";
import { PartsLinkAccessRequest } from "../../model/parts-links-access.model";
import { PartsLinkAccessActions } from "../actions";

export const PARTS_LINK_ACCESS_FEATURE_KEY = 'partsLinkAccess';

export interface PartsLinkAccessState {
  request: PartsLinkAccessRequest,
  proccessing: boolean,
  success: boolean
}

export const initialState: PartsLinkAccessState = {
  request: undefined,
  proccessing: false,
  success: undefined
}

export const PartsLinkAccesReducer = createReducer(
  initialState,
  on(PartsLinkAccessActions.startPartsLinkAccess,(state,action) => ({
    ...state,
    proccessing: true,
    request: action.request
  })),
  on(PartsLinkAccessActions.partsLinksAccessSuccess,(state) => ({
    ...state,
    proccessing: false,
    success: true,
    request: undefined
  })),
  on(PartsLinkAccessActions.partsLinkAccessFailer,(state,action) => ({
    ...state,
    proccessing: false,
    success: false,
    request: action.errorResponse
  }))
)
