export const checkoutOrder = {
  checkoutOrder: {
    globalMessage: {
      mandatoryOrderInfoFieldsMissing: 'Some fields regarding order or consignment information aren\'t filled correctly.',
      mandatoryHeaderFieldsMissing: 'Order type, Shipping type and delivery address must be specified.',
      placeOrderFailed: 'An error occurs when creating the order and checking the status. Please open the order history and check if'
    },
    checkoutReview: {
      headline: 'Order Summary',
      orderType: 'Order type',
      requestedDeliveryDate: 'Desired delivery date',
      placeOrder: 'Order for a fee',
    },
    checkoutSubmitForm: {
      YourAdditionalInformation: {
        label: 'Your additional information',
        placeholder: '(max. 20 chars)'
      }
    },
    orderInfoFields: {
      heading: 'Additional information',
      mandatoryMessage1: 'Please fill out',
      mandatoryMessage2: 'all the following mandatory fields*',
      mandatoryMessage3: 'to complete the order. If you are unable to provide any information, please enter "no information" in the relevant field.'
    },
    checkoutShipping: {
      shippingMethod: 'Delivery Method',
      standardDelivery: 'Standard Delivery',
      premiumDelivery: 'Premium Delivery',
    },
    orderCost: {
      noticeConfiguratorTaxAndDeliveryCosts: 'Sales prices incl. VAT, plus shipping costs if applicable, plus assembly costs if applicable',
    },
  }
};
