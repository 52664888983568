import {Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {tap} from 'rxjs/operators';
import {DownloadItem, DownloadItemsComponentData} from '../../model/download-files.model';

@Component({
  selector: 'app-download-items',
  templateUrl: './download-items.component.html',
})
export class DownloadItemsComponent {

  downloadItems: DownloadItem[];
  columnsPerRow: number;

  downloadsComponentData$ = this.component.data$.pipe(
    tap((data) => {
      this.downloadItems = [];
      data.config?.downloadItemsSortOrder.split(',').forEach((s) => this.downloadItems.push(data.config?.downloadItems[s]));
      this.columnsPerRow = Math.floor(12 / data.numberPerRow);
    }),
  );

  constructor(private component: CmsComponentData<DownloadItemsComponentData>) { }

}
