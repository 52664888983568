import { createSelector, MemoizedSelector } from '@ngrx/store';
import { ShippingBacklogOverviewRequest, ShippingBacklogOverviewResponse } from '../../model/shipping-backlog.model';
import { getShippingBacklogState, ShippingBacklogState } from '../reducers';
import * as ShippingBacklogOverviewFeature from '../reducers/shipping-backlog-overview.reducer';
import { SHIPPING_BACKLOG_OVERVIEW_FEATURE_KEY } from '../reducers/shipping-backlog-overview.reducer';

export const selectLoading: MemoizedSelector<ShippingBacklogOverviewFeature.ShippingBacklogOverviewState, boolean>
  = createSelector(
    getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_OVERVIEW_FEATURE_KEY].loading
);

export const selectSuccess: MemoizedSelector<ShippingBacklogOverviewFeature.ShippingBacklogOverviewState, boolean> = createSelector(
    getShippingBacklogState,
    (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_OVERVIEW_FEATURE_KEY].success
  );

export const selectResponse: MemoizedSelector<ShippingBacklogOverviewFeature.ShippingBacklogOverviewState,
  ShippingBacklogOverviewResponse>
  = createSelector(
  getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_OVERVIEW_FEATURE_KEY].response
);

export const selectRequest: MemoizedSelector<ShippingBacklogOverviewFeature.ShippingBacklogOverviewState,
  ShippingBacklogOverviewRequest>
  = createSelector(
  getShippingBacklogState,
  (state: ShippingBacklogState) => state[SHIPPING_BACKLOG_OVERVIEW_FEATURE_KEY].request
);