/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { SLASH, SLASH_ENCODED_URL_MARKER } from '@shared/models/shared.model';
import {
  OccCmsPageAdapter,
  OccCmsPageRequest,
  PageContext,
  PageType,
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class EfaOccCmsPageAdapter extends OccCmsPageAdapter {
  protected getPagesRequestParams(context: PageContext): OccCmsPageRequest {
    let httpParams = super.getPagesRequestParams(context);

    if (context.type === PageType.PRODUCT_PAGE) {
      httpParams = {
        ...httpParams,
        code: context.id?.replace(
          SLASH_ENCODED_URL_MARKER.substring(0, 3),
          SLASH
        ),
      };
    }

    return httpParams;
  }
}
