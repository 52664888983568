import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {OccEndpointsService} from '@spartacus/core';

import {InvoicesCreditDetailsResponse} from '../../../model/invoices-credits.model';
import {Observable} from 'rxjs';
import {InvoiceCreditDetailsAdapter} from '../invoice-credit-details.adapter';

@Injectable({providedIn: 'root'})
export class OccInvoiceCreditDetailsAdapter implements InvoiceCreditDetailsAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  loadInvoiceDetails(docNumber: string): Observable<InvoicesCreditDetailsResponse> {
    const url = this.occEndpoints.buildUrl(
      'invoiceCreditsDetails',
      {
        urlParams: {
          userId: 'current',
          documentNumber: docNumber,
        }
      }
    );

    return this.http.get<InvoicesCreditDetailsResponse>(url);
  }
}
