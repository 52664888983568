import { Injectable } from '@angular/core';

import { OrderOverviewRequest } from '../../model/order-history.model';
import { OrderOverviewAdapter } from '../adapters/order-overview.adapter';
import { Observable } from 'rxjs';
import { OrderHistoryList } from '@spartacus/order';

@Injectable({
  providedIn: 'root',
})
export class OrderOverviewConnector {
  constructor(protected adapter: OrderOverviewAdapter) {}

  loadOverview(request: OrderOverviewRequest): Observable<OrderHistoryList> {
    return this.adapter.loadOverview(request);
  }
}
