import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { map, skipWhile, tap, withLatestFrom } from 'rxjs/operators';
import { CartImportItem } from '../../import-export/model/import-export.model';
import {
  AddToCartParam,
  CartModificationList,
  CartProductSearchResult,
  CartProductSearchResultItem
} from '../model/cart-administration.model';
import { UpdateCartArg } from '../model/update-cart.model';
import { MultiAddToCartActions } from '../store/actions';
import * as MultiAddToCartFeature from '../store/reducers/multi-add-to-cart.reducer';
import * as MultiAddToCartSelectors from '../store/selectors/multi-add-to-cart.selectors';
import { B2BUserAccountService } from './b2-b-user-account.service';
import { CartParametersService } from './cart-parameters.service';
import { UpdateCartService } from './update-cart.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';

@Injectable({providedIn: 'root'})
export class MultiAddToCartService {

  constructor(
    private store: Store<MultiAddToCartFeature.MultiAddToCartState>,
    private b2BUserAccountService: B2BUserAccountService,
    private activeCartService: ActiveCartFacade,
    private cartParametersService: CartParametersService,
    private updateCartService: UpdateCartService
  ) {
  }

  reset(): void {
    this.store.dispatch(MultiAddToCartActions.loadMultiAddToCartReset());
  }

  addEntries(results: CartProductSearchResult[], substitutedOemNumber: string, cConsignmentInfo: string): void {
    const addToCartParams: AddToCartParam[] = [];
    results.forEach((result: CartProductSearchResult) => {
      result.resultItems.forEach((item: CartProductSearchResultItem) => {
        const addToCartParam: AddToCartParam = {
          productCode: item.materialNumber,
          quantity: item.quantity,
          substitutedOEMNumber: substitutedOemNumber,
          consignmentInfo: cConsignmentInfo,
          unitId: item.unitID,
          manufacturerId: item.manufacturerID,
          materialName: item.materialName,
          articleNumber: item.oemNumber,
          orderInfoRefId: item.orderInfoRefId,
          requiredShippingTypeID: item.requiredShippingTypeID,
          customerPricePerUnit: item.customerPricePerUnit,
          listPricePerUnit: item.listPricePerUnit,
          deliveryItems: item.deliveryItems,
        };
        addToCartParams.push(addToCartParam);
      });
    });
    this.addEntriesFromParams(addToCartParams, this.b2BUserAccountService.cartId, false);
  }

  addEntriesFromImport(imports: CartImportItem[]): void {
    const addToCartParams = [];
    imports.forEach((item) => {
      item.result.resultItems.forEach((resultItem: CartProductSearchResultItem) =>
        addToCartParams.push({
          productCode: resultItem.materialNumber,
          quantity: resultItem.quantity,
          substitutedOEMNumber: item.substitutedOemNumber,
          materialName: resultItem.materialName,
          unitId: resultItem.unitID,
          manufacturerId: resultItem.manufacturerID,
          articleNumber: resultItem.oemNumber,
          consignmentInfo: item.consignmentInfo,
          orderInfoRefId: resultItem.orderInfoRefId,
          customerPricePerUnit: resultItem.customerPricePerUnit,
          listPricePerUnit: resultItem.listPricePerUnit,
          deliveryItems: resultItem.deliveryItems,
          requiredShippingTypeID: resultItem.requiredShippingTypeID,
        })
      );
    });
    this.addEntriesFromParams(addToCartParams, this.b2BUserAccountService.cartId, false);
  }

  public addEntriesFromParams(addToCartParams: AddToCartParam[], cartId: string, isWishlist: boolean): void {
    if (isWishlist) {
      this.store.dispatch(MultiAddToCartActions.loadMultiAddToCart({
        entries: addToCartParams,
        cartId,
        userId: 'current'
      }));
    } else {
      this.updateCartService.reset();
      this.activeCartService.requireLoadedCart().pipe(
        withLatestFrom(this.cartParametersService.getCurrentOrderType(),
          this.cartParametersService.getCurrentDeliveryAddress(),
          this.cartParametersService.getCurrentShippingType(),
          this.cartParametersService.getRequestDeliveryDate()),
        tap(([cart, ot, da, st, dd]) => {
          if (!cart.skipAutomaticCalculation) {
            // there isn't any cart calculation in the backend. Therefore, we must not ensure that all cart parameters are set
            if (dd === undefined) {
              const currentDate = new Date();
              dd = {
                day: currentDate.getDate(),
                month: currentDate.getMonth() + 1,
                year: currentDate.getFullYear()
              };
            }
            const data: UpdateCartArg = {
              cartId: cart.code,
              userId: this.b2BUserAccountService.userId,
              orderTypeId: ot?.id,
              shippingTypeId: st?.code,
              addressId: da?.id,
              calculate: false,
              requestedDeliveryDate: dd?.year + '-' + dd?.month + '-' + dd?.day,
              reloadCart: false
            };
            this.updateCartService.updateCart(data);
          }
        }),
        map(([cart, ot, da, st, dd]) => {
          return {
            cart,
            skipped: cart.skipAutomaticCalculation || cart.deliveryMode?.code === st?.code
          }
        }))
      .subscribe((params) => {
        setTimeout(() => this.store.dispatch(MultiAddToCartActions.loadMultiAddToCart({
          entries: addToCartParams,
          cartId: params.cart.code,
          userId: 'current'
        })), params.skipped ? 0 : 2000);
      });
    }
  }

  isAdding(): Observable<boolean> {
    return this.store.select(MultiAddToCartSelectors.selectAdding);
  }

  hasFinished(): Observable<boolean> {
    return this.isAdding().pipe(skipWhile(v => !v), map(v => !v));
  }

  getModifications(): Observable<CartModificationList> {
    return this.store.select(MultiAddToCartSelectors.selectModifications);
  }
}
