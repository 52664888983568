import { Injectable } from '@angular/core';
import { OccProductAdapter } from '@spartacus/core';

@Injectable()
export class EfaOccProductAdapter extends OccProductAdapter {
  protected getEndpoint(code: string, scope?: string): string {
    return this.occEndpoints.buildUrl('product', {
      urlParams: {
        productCode: encodeURIComponent(code),
      },
      scope,
    });
  }
}
