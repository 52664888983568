<form *ngIf="form$ | async as form"
      [formGroup]="form"
      (ngSubmit)="submit(form)">
  <app-efa-cancel-or-return-items *ngIf="entries$ | async as entries"
                                  [entries]="entries"
                                  [isConfirmation]="true">
  </app-efa-cancel-or-return-items>

  <ng-container *ngTemplateOutlet="actions"></ng-container>

  <ng-template #actions>
    <app-efa-amend-order-actions *ngIf="orderCode"
                            class="row justify-content-end"
                            [orderCode]="orderCode"
                            [amendOrderForm]="form"
                            backRoute="orderCancel"></app-efa-amend-order-actions>
  </ng-template>
</form>
