import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {AuthGuard, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {AmendOrderActionsModule, AmendOrderItemsModule, OrderAmendService} from '@spartacus/order/components';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {orderCancelTranslationChunksConfig, orderCancelTranslations} from './translations/order-cancel.translations';
import {orderCancellationFeatureKey, reducers} from './store/reducers';
import {OccModule} from './occ/occ.module';
import {effects} from './store/effects';
import {EfaCancelOrderComponent} from './components/efa-cancel-order/efa-cancel-order.component';
import {OrderCancelService} from './services/order-cancel.service';
import {EfaCancelOrderConfirmationComponent} from './components/efa-cancel-order-confirmation/efa-cancel-order-confirmation.component';
import {EfaCancelOrReturnItemsComponent} from './components/efa-cancel-or-return-items/efa-cancel-or-return-items.component';
import {EfaAmendOrderActionsComponent} from './components/efa-amend-order-actions/efa-amend-order-actions.component';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    EfaCancelOrderComponent,
    EfaCancelOrderConfirmationComponent,
    EfaCancelOrReturnItemsComponent,
    EfaAmendOrderActionsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    OccModule,
    I18nModule,
    ReactiveFormsModule,
    AmendOrderItemsModule,
    AmendOrderActionsModule,
    UrlModule,
    ConfigModule.withConfig({
      i18n: {
        resources: orderCancelTranslations,
        chunks: orderCancelTranslationChunksConfig
      },
      backend: {
        occ: {
          endpoints: {
            orderCancellation: 'users/${userId}/orders/${code}/cancelPositions'
          }
        }

      },
      cmsComponents: {
        CancelOrderConfirmationComponent: {
          component: EfaCancelOrderConfirmationComponent,
          guards: [AuthGuard],
          providers: [
            {
              provide: OrderAmendService,
              useExisting: OrderCancelService,
            },
          ],
        },
        CancelOrderComponent: {
          component: EfaCancelOrderComponent,
          guards: [AuthGuard],
          providers: [
            {
              provide: OrderAmendService,
              useExisting: OrderCancelService,
            },
          ],
        },
      },
      routing: {
        routes: {
          orderCancel: {
            paths: ['my-account/documents/order/cancel/:sapOrderNumber'],
            paramsMapping: {sapOrderNumber: 'code'},
          },
          orderCancelConfirmation: {
            paths: ['my-account/documents/order/cancel/confirmation/:sapOrderNumber'],
            paramsMapping: {sapOrderNumber: 'code'},
          },
        }
      }
    }),
    StoreModule.forFeature(orderCancellationFeatureKey, reducers),
    EffectsModule.forFeature(effects),
  ]
})
export class OrderCancelModule { }
