import { Component } from '@angular/core';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { ImportMode } from '../../model/import-export-enum.model';
import { CartImportDialogService } from '../cart-import/cart-import-dialog.service';

@Component({
  selector: 'app-cart-import-action',
  templateUrl: './cart-import-action.component.html'
})
export class CartImportActionComponent {
  private modalRef: any;

  constructor(private cartImportDialogService: CartImportDialogService) {
  }

  displayImportDialog(): void {
    this.cartImportDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CART_IMPORT, undefined, ImportMode.CSV);
  }
}
