import { Injectable } from '@angular/core';
import { ProductSearchService } from '@spartacus/core';
import { PageLayoutHandler } from '@spartacus/storefront';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EfaAbstractSearchResultsLayoutHandler } from './efa-abstract-search-results-page-layout-handler';

@Injectable({
  providedIn: 'root',
})
export class EfaSearchResultsGridPageLayoutHandler extends EfaAbstractSearchResultsLayoutHandler {
  handle(slots$: Observable<string[]>, pageTemplate?: string, section?: string): Observable<any> {
    if (pageTemplate === 'SearchResultsGridPageTemplate') {
      return super.handle(slots$, section);
    }
    return slots$;
  }
}
