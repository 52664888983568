import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EfaGlobalMessageService } from "@shared/services/efa-global-message.service";
import { GlobalMessageType } from "@spartacus/core";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { PartsLinkAccessConnector } from "../../occ/connectors/parts-link-access.connector";
import { PartsLinkAccessActions } from "../actions";

@Injectable()

export class PartsLinkAccessEffect {

  startTiresWarranty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PartsLinkAccessActions.startPartsLinkAccess),
      concatMap((action) =>
        this.connector.startPartsLinkAccess(action.request).pipe(
          map(() => {
            return PartsLinkAccessActions.partsLinksAccessSuccess();
          }),
          catchError((error) => {
            this.globalMessageService.add(
              {
                key: 'partsLinkAccess.globalMessage.startPartsLinkAccessFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(PartsLinkAccessActions.partsLinkAccessFailer(error));
          })
        )
      )
    )
  })
  constructor(
    private actions$: Actions,
    private connector:PartsLinkAccessConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {}
}
