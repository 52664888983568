export enum ICON_TYPE {
  CALENDAR = 'CALENDAR',
  SEARCH = 'SEARCH',
  BROWSE = 'BROWSE',
  HINT = 'HINT',
  CLOSE_LIGHT = 'CLOSE_LIGHT',
  CART_BLUE = 'CART_BLUE',
}

export enum ProductCategory {
  OIL = 'OIL',
  SPARE_PARTS = 'SPARE_PARTS',
  ACCESSORIES = 'ACCESSORIES',
  TIRES = 'TIRES',
  TOOLS = 'TOOLS',
}
