export const productSearch = {
  productSearch: {
    item: {
      actions: {
        goToProductDetails: 'Produktdetails'
      }
    },
    productFacetNavigation: {
      header: 'Filtern nach'
    }
  }
};
