import { Config } from '@spartacus/core';

export abstract class FileSizeConfig {
  maxFileSize?: {
    warrantyProcessForm: number;
    registrationForm: number,
    transportDamageForm: number,
    tiresWarrantyForm: number
  };
}

export const defaultFileSizeConfig: FileSizeConfig = {
  maxFileSize: {
    warrantyProcessForm: 5,
    registrationForm: 5,
    transportDamageForm: 5,
    tiresWarrantyForm: 5
  },
};

declare module '@spartacus/core' {
  interface Config extends FileSizeConfig {}
}
