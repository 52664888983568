import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import * as RegistrationConfirmationFeature from '../store/reducers/registration-confirmation.reducer';
import * as RegistrationConfirmationSelectors from '../store/selectors/registration-confirmation.selectors';
import { RegistrationConfirmationActions } from '../store/actions';
import { ErrorType } from '@shared/models/ErrorTypes';

@Injectable({ providedIn: 'root' })
export class RegistrationConfirmationService {

  constructor(protected store: Store<RegistrationConfirmationFeature.RegistrationConfirmationState>) {}

  registrationConfirmation(token: string): void {
    return this.store.dispatch(RegistrationConfirmationActions.loadRegistrationConfirmation({ token }));
  }

  isSending(): Observable<boolean> {
    return this.store.select(RegistrationConfirmationSelectors.selectLoading);
  }

  getError(): Observable<ErrorType> {
    return this.store.select(RegistrationConfirmationSelectors.selectError);
  }
}
