import { createSelector, MemoizedSelector } from '@ngrx/store';
import { FaqOverviewResponse } from '../../model/faq-items.model';
import { FaqItemsState, getFaqItemsState } from '../reducers';
import * as FaqOverviewFeature from '../reducers/faq-overview.reducer';
import { FAQ_OVERVIEW_FEATURE_KEY } from '../reducers/faq-overview.reducer';


export const selectLoading: MemoizedSelector<FaqOverviewFeature.FaqOverviewState, boolean>
  = createSelector(
  getFaqItemsState,
  (state: FaqItemsState) => state[FAQ_OVERVIEW_FEATURE_KEY].loading
);

export const selectResponse: MemoizedSelector<FaqOverviewFeature.FaqOverviewState, FaqOverviewResponse>
  = createSelector(
  getFaqItemsState,
  (state: FaqItemsState) => state[FAQ_OVERVIEW_FEATURE_KEY].response
);
