import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ShippingBacklogOverviewAdapter } from './adapters/shipping-backlog-overview.adapter';
import { OccShippingBacklogOverviewAdapter } from './adapters/impl/occ-shipping-backlog-overview.adapter';
import { ShippingBacklogCancellationAdapter } from './adapters/shipping-backlog-cancellation.adapter';
import { OccShippingBacklogCancellationAdapter } from './adapters/impl/occ-shipping-backlog-cancellation.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: ShippingBacklogOverviewAdapter,
      useClass: OccShippingBacklogOverviewAdapter,
    },
    {
      provide: ShippingBacklogCancellationAdapter,
      useClass: OccShippingBacklogCancellationAdapter,
    },
  ],
})
export class OccModule {}
