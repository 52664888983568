<button
  type="button"
  (click)="toggle()"
  [attr.aria-label]="state.ariaLabel | cxTranslate"
>
  <span aria-hidden="true">
    <cx-icon [type]="iconTypes.EYE_CROSSED" [style.display]="state.hidden ? 'none': 'inline'"></cx-icon>
    <cx-icon [type]="iconTypes.EYE" [style.display]="!state.hidden ? 'none': 'inline'"></cx-icon>
  </span>
</button>
