import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OccTransportDamageAdapter } from './adapters/impl/occ-transport-damage.adapter';
import { TransportDamageAdapter } from './adapters/transport-damage.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: TransportDamageAdapter,
      useClass: OccTransportDamageAdapter,
    },
  ],
})
export class OccModule {}
