<div
  class="cx-modal-container"
  [cxFocus]="focusConfig"
  (esc)="closeModal()"
>

    <div class="cx-modal-content">

        <div class="cx-dialog-header modal-header">
            <h3>{{ 'importExport.cartExportDialog.header' | cxTranslate }}</h3>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true"><i class="fa fa-times"></i></span>
            </button>
        </div>

        <div class="cx-dialog-content modal-body">
            <div class="row">
                <p class="col-12">
                    <span class="filename-info-icon">
                        <cx-icon type="INFO"></cx-icon>
                    </span>
                    {{ 'importExport.cartExportDialog.infoText' | cxTranslate }}
                </p>
            </div>
            <form [formGroup]="exportForm">
                <div class="form-group row">
                    <div class="col-10">
                        <input type="text" class="form-control u-text-ellipsis" id="filename" formControlName="filename"
                            placeholder="{{ 'importExport.cartExportDialog.filename.placeholder' | cxTranslate }}" />
                    </div>
                    <label for="filename" class="col-2 col-form-label">{{ filenameHtmlLabel }}</label>
                </div>
                <div class="row">
                    <div class="col-12">
                        <cx-form-errors [control]="exportForm.get('filename')"></cx-form-errors>
                    </div>
                </div>
            </form>
        </div>

        <div class="cx-dialog-footer modal-footer">
            <button class="btn btn-secondary" type="submit" (click)="closeModal()">
                {{ 'importExport.cartExportDialog.buttonClose' | cxTranslate }}
            </button>
            <button class="btn btn-primary" type="submit" (click)="setFilename()">
                {{ 'importExport.cartExportDialog.buttonExport' | cxTranslate }}
            </button>
        </div>

    </div>
</div>