import { Component, Input } from '@angular/core';
import { formatDate } from '@angular/common';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Card } from '@spartacus/storefront';
import { Address, TranslationService } from '@spartacus/core';

@Component({
  selector: 'app-delivery-notes-header',
  templateUrl: './delivery-notes-header.component.html',
})
export class DeliveryNotesHeaderComponent {
  @Input() deliveryNoteNumber: string;
  @Input() creationDate: Date;
  @Input() warehouseName: string;
  @Input() receiverAddress: Address;

  constructor(protected translation: TranslationService) {}

  getDeliveryNoteNumber(): Observable<Card> {
    return combineLatest([
      this.translation.translate('deliveryNotesCustom.deliveryNoteNumber'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: this.deliveryNoteNumber,
        };
      })
    );
  }

  getCreationDate(): Observable<Card> {
    return combineLatest([
      this.translation.translate('deliveryNotesCustom.creationDate'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: formatDate(this.creationDate, 'dd.MM.yyyy', 'en-US'),
        };
      })
    );
  }

  getWarehouseName(): Observable<Card> {
    return combineLatest([
      this.translation.translate('deliveryNotesCustom.warehouseName'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: this.warehouseName,
        };
      })
    );
  }

  getReceiverAddress(): Observable<Card> {
    return combineLatest([
      this.translation.translate('addressCard.shipTo'),
    ]).pipe(
      map(([textTitle]) => {

        let country = '';
        if (
          this.receiverAddress &&
          this.receiverAddress.country &&
          this.receiverAddress.country.name
        ) {
          country = this.receiverAddress.country.name;
        }

        return {
          title: textTitle,
          textBold: this.receiverAddress.companyName,
          text: [
            this.receiverAddress.firstName + ' ' + this.receiverAddress.lastName,
            this.receiverAddress.line1,
            this.receiverAddress.postalCode + ' ' + this.receiverAddress.town,
            country
          ],
        };
      })
    );
  }
}
