import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';

export const setCartCalculationMode = createAction(
  '[SetCartCalculationMode] Set Cart Calculation Mode',
  props<{ cartCalculationMode: boolean, cartId: string }>()
);

export const setCartCalculationModeSuccess = createAction(
  '[SetCartCalculationMode] Set Cart Calculation Mode Success'
);

export const setCartCalculationModeFailure = createAction(
  '[SetCartCalculationMode] Set Cart Calculation Mode Failure',
  props<{ errorResponse: ErrorType }>()
);

export const resetCartCalculationMode = createAction(
  '[SetCartCalculationMode] Reset'
);
