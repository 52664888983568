import {Directive} from '@angular/core';
import {ProductSearchService} from '@spartacus/core';
import {PageLayoutHandler} from '@spartacus/storefront';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Directive()
export abstract class EfaAbstractSearchResultsLayoutHandler implements PageLayoutHandler {
  constructor(
    protected productSearchService: ProductSearchService,
  ) {}

  handle(
    slots$: Observable<string[]>,
    section?: string
  ) {
    if (!section) {
      return combineLatest([
        slots$,
        this.productSearchService.getResults(),
      ]).pipe(
        map(([slots, results]) => {
          const exclude = (arr, args) =>
            arr.filter((item) => args.every((arg) => arg !== item));
          return results?.products?.length !== 0 ? exclude(slots, [
                'Section4',
          ]) : exclude(slots, [
            'ProductLeftRefinements', 'SearchResultsListSlot'
          ]);
        })
      );
    }
    return slots$;
  }
}
