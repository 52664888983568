import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

import { Config, PaginationModel, SortModel, TranslationService } from '@spartacus/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ICON_TYPE } from '../../model/return-history-icon.model';
import { ReturnOverviewFilter, ReturnOverviewRequest, ReturnOverviewResponse } from '../../model/return-history.model';
import { ReturnHistoryUiService } from '../../services/return-history-ui.service';
import { ReturnOverviewService } from '../../services/return-overview.service';

@Component({
  selector: 'app-return-overview',
  templateUrl: './return-overview.component.html',
})
export class ReturnOverviewComponent implements OnInit {
  returnOverview$: Observable<ReturnOverviewResponse>;
  isLoading$: Observable<boolean>;
  iconTypes = ICON_TYPE;
  returnOverviewFilter: ReturnOverviewFilter = {
    returnNumber: '',
    deliveryNoteNumber: '',
    statusCode: '',
    dateFrom: undefined,
    dateTo: undefined,
  };

  private subscription: Subscription = new Subscription();
  private returningFromDetails = false;

  private sortCode = 'creationDate';
  private currentPage = 0;
  private pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.config.pageSize.returnOverview,
    sort: 'creationDate',
  };
  private sort: SortModel = {
    code: 'creationDate',
    selected: true
  };

  constructor(
    protected translation: TranslationService,
    private returnOverviewService: ReturnOverviewService,
    private returnHistoryUiService: ReturnHistoryUiService,
    private datePipe: DatePipe,
    @Inject(Config) public config: any,
  ) {
  }

  ngOnInit(): void {
    this.returnHistoryUiService.setSelectedPagination(this.pagination);
    this.returnHistoryUiService.setSelectedSort(this.sort);
    this.isLoading$ = this.returnOverviewService.isLoading();
    this.returnOverview$ = this.returnOverviewService.getReturnOverviewResponse().pipe(tap(resp => {
        if (resp === undefined) {
          this.fetchReturnOverview();
        }
      }
    ));

    this.subscription.add(combineLatest([this.returnHistoryUiService.selectedSort(), this.returnHistoryUiService.selectedPagination(), this.returnHistoryUiService.selectedFilterEntity(), this.returnHistoryUiService.returningFromDetails()])
    .subscribe(([selectedSort, selectedPagination, _filter, returningFromDetails]: [SortModel, PaginationModel, ReturnOverviewFilter, boolean]) => {
      this.returningFromDetails = returningFromDetails;
      if (selectedSort) {
        this.sortCode = selectedSort.code;
      }

      if (selectedPagination) {
        this.currentPage = selectedPagination.currentPage;
      } else {
        this.currentPage = 0;
      }
    }));
  }

  changeSortCode(sortCode: string): void {
    this.sortCode = sortCode;
    this.fetchReturnOverview();
    this.returnHistoryUiService.setSelectedSort({
      code: sortCode,
      selected: true,
    });
  }

  pageChange(page: number): void {
    this.currentPage = page;
    this.returnHistoryUiService.setSelectedPagination({
      currentPage: page,
      pageSize: this.config.pageSize.returnOverview,
    });
    this.fetchReturnOverview();
  }

  getSortLabels(): Observable<{
    byCreationDate: string;
    byReturnNumber: string;
    byDeliveryNoteNumber: string;
    byStatusCode: string
  }> {
    return combineLatest([
      this.translation.translate('returnOverview.sorting.creationDate'),
      this.translation.translate('returnOverview.sorting.returnNumber'),
      this.translation.translate('returnOverview.sorting.deliveryNoteNumber'),
      this.translation.translate('returnOverview.sorting.statusCode'),
    ]).pipe(
      map(([textByCreationDate, textByReturnNumber, textByDeliveryNoteNumber, textByStatusCode]) => {
        return {
          byCreationDate: textByCreationDate,
          byReturnNumber: textByReturnNumber,
          byDeliveryNoteNumber: textByDeliveryNoteNumber,
          byStatusCode: textByStatusCode,
        };
      })
    );
  }

  setReturnOverviewFilter(overviewFilter: ReturnOverviewFilter): void {
    this.returnOverviewFilter = overviewFilter;
    // make sure first page of new result is displayed
    if (!this.returningFromDetails) {
      this.pageChange(0);
    } else {
      this.fetchReturnOverview();
      this.returnHistoryUiService.setReturningFromDetails(false);
    }
  }

  private fetchReturnOverview(): void {
    const returnOverviewRequest: ReturnOverviewRequest = {
      deliveryNoteNumber: this.returnOverviewFilter.deliveryNoteNumber,
      returnNumber: this.returnOverviewFilter.returnNumber,
      statusCode: this.returnOverviewFilter.statusCode,
      dateFrom: ReturnOverviewComponent.formatFilterDate(this.returnOverviewFilter.dateFrom),
      dateTo: ReturnOverviewComponent.formatFilterDate(this.returnOverviewFilter.dateTo),
      sort: {
        code: this.sortCode,
        selected: true
      },
      pagination: {
        currentPage: this.currentPage,
        pageSize: this.config.pageSize.returnOverview,
        sort: this.sortCode,
      }
    };
    this.returnOverviewService.loadOverview(returnOverviewRequest);
  }

  private static formatFilterDate(date: NgbDateStruct): string {
    return date ? date.year + '-' + date.month + '-' + date.day : '';
  }
}
