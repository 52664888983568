import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


import {Observable} from 'rxjs';
import {OccEndpointsService} from '@spartacus/core';

import {CartProductSearchRequest, CartProductSearchResponse} from '../../../model/cart-administration.model';
import {CartProductSearchAdapter} from '../cart-product-search.adapter';

@Injectable({providedIn: 'root'})
export class OccCartProductSearchAdapter implements CartProductSearchAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  searchProducts(request: CartProductSearchRequest): Observable<CartProductSearchResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'cartProductSearch',
      {
        urlParams: {userId: 'current'}
      }
    );

    return this.http.post<CartProductSearchResponse>(url, request, {headers});
  }
}
