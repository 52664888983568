import { Injectable } from '@angular/core';
import * as TiresWarrantyFeature from '../store/reducers/tires-warranty.reducer';
import * as TiresWarrantyActions from '../store/actions/tires-warranty.action';
import * as TiresWarrantySelectors from '../store/selectors/tires-warranty.selectors';
import { Store } from '@ngrx/store';
import { TiresWarrantyRequest } from '../model/tires-warranty.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TiresWarrantyService {

  constructor(protected store: Store<TiresWarrantyFeature.TiresWarrantyState>){

  }
  startTiresWarranty(request: TiresWarrantyRequest): void {
    this.store.dispatch(TiresWarrantyActions.startTiresWarranty({request}));
  }
  isProcessing(): Observable<boolean> {
    return this.store.select(TiresWarrantySelectors.selectProcessing);
  }
  sucess(): Observable<boolean>{
    return this.store.select(TiresWarrantySelectors.selectSuccess);
  }
}
