import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
  AuthGuard,
  ConfigModule,
  DeferLoadingStrategy,
  I18nModule,
  provideDefaultConfig
} from '@spartacus/core';
import {
  ConsentManagementModule,
  IconModule,
  KeyboardFocusModule,
  SpinnerModule,
  SupplementHashAnchorsModule
} from '@spartacus/storefront';

import {
  ConsentManagementDialogComponent
} from './components/consent-management-dialog/consent-management-dialog.component';
import {
  EfaConsentManagementComponent
} from './components/efa-consent-management/efa-consent-management.component';
import {
  defaultAnonymousConsentLayoutConfig,
  defaultConsentManagementLayoutConfig
} from './config/consent-management-layout-config';
import {
  consentManagementTranslationChunksConfig,
  consentManagementTranslations
} from './translations/consent-management.translation';

import {
  EfaAnonymousConsentManagementBannerComponent
} from "./components/efa-anonymous-consent-management-banner/efa-anonymous-consent-management-banner.component";
import {
  EfaConsentManagementFormComponent
} from "./components/efa-consent-management-form/efa-consent-management-form.component";
import {
  EfaAnonymousConsentDialogComponent
} from "./components/efa-anonymous-consent-dialog/efa-anonymous-consent-dialog.component";

@NgModule({
  declarations: [EfaConsentManagementComponent, ConsentManagementDialogComponent, EfaAnonymousConsentManagementBannerComponent, EfaConsentManagementFormComponent, EfaAnonymousConsentDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    IconModule,
    ConsentManagementModule,
    KeyboardFocusModule,
    ConfigModule.withConfig({
      i18n: {
        resources: consentManagementTranslations,
        chunks: consentManagementTranslationChunksConfig
      },
      cmsComponents: {
        ConsentManagementComponent: {
          component: EfaConsentManagementComponent,
          guards: [AuthGuard],
        },
        AnonymousConsentManagementBannerComponent: {
          component: EfaAnonymousConsentManagementBannerComponent,
          deferLoading: DeferLoadingStrategy.INSTANT,
        },
      },
      anonymousConsents: {
        requiredConsents: ['001-MANDATORY'],
        showLegalDescriptionInDialog: false,
      }
    }),
    SupplementHashAnchorsModule
  ],
  providers: [
    provideDefaultConfig(defaultConsentManagementLayoutConfig),
    provideDefaultConfig(defaultAnonymousConsentLayoutConfig),

  ],
  exports: [
    EfaConsentManagementFormComponent
  ]
})
export class ConsentManagementCustomModule {
}
