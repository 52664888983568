<ng-container *ngIf="!hideAll">
  <div ngbAccordion #acc="ngbAccordion">
    <div ngbAccordionItem="cartProductSearchPanelOne" #cartProductSearchPanelOne="ngbAccordionItem" [collapsed]="false">
      <div ngbAccordionHeader>
        <div class="justify-content-between">
          <h5 class="mb-0">
            <button ngbAccordionToggle
                    class="btn btn-block">{{ 'cartAdministration.cartProductSearchForm.formHeadline' | cxTranslate }}</button>
          </h5>
        </div>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <form (ngSubmit)="onSubmit()"
                  [formGroup]="cartProductSearchForm">
              <div class="row manufacturer-type-selection">
                <div class="manufacturer-type-selection-label">{{ 'cartAdministration.cartProductSearchForm.productCategories.label' | cxTranslate }}</div>
                <div class="form-check manufacturer-type-selection-check">
                  <input formControlName="productCategory" type="radio" class="form-check-input" id="radioSpareParts" name="productCategory" value="SPARE_PARTS" (change)="productCategoryChanged($event)">
                  <label class="form-check-label" for="radioSpareParts">{{ 'cartAdministration.cartProductSearchForm.productCategories.spareParts' | cxTranslate }}</label>
                </div>
                <div *ngIf="!(isExternalPortalUser$ | async)" class="form-check manufacturer-type-selection-check">
                  <input formControlName="productCategory" type="radio" class="form-check-input" id="radioOil" name="productCategory" value="OIL" (change)="productCategoryChanged($event)">
                  <label class="form-check-label" for="radioOil">{{ 'cartAdministration.cartProductSearchForm.productCategories.oil' | cxTranslate }}</label>
                </div>
                <div *ngIf="!(isExternalPortalUser$ | async)" class="form-check manufacturer-type-selection-check">
                  <input formControlName="productCategory" type="radio" class="form-check-input" id="radioAccessories" name="productCategory" value="ACCESSORIES" (change)="productCategoryChanged($event)">
                  <label class="form-check-label" for="radioAccessories">{{ 'cartAdministration.cartProductSearchForm.productCategories.accessories' | cxTranslate }}</label>
                </div>
                <div class="form-check manufacturer-type-selection-check">
                  <input formControlName="productCategory" type="radio" class="form-check-input" id="radioTools" name="productCategory" value="TOOLS" (change)="productCategoryChanged($event)">
                  <label class="form-check-label" for="radioTools">{{ 'cartAdministration.cartProductSearchForm.productCategories.tools' | cxTranslate }}</label>
                </div>
              </div>
              <div class="c-product-search-container row">
                <label class="form-group">
                    <span class="label-content">
                      {{ 'cartAdministration.cartProductSearchForm.manufacturerCodes.label' | cxTranslate }}
                    </span>
                  <div class="input-group">
                    <input class="form-control u-text-ellipsis"
                           type="text"
                           formControlName="manufacturerCodes"
                           placeholder="{{ 'cartAdministration.cartProductSearchForm.manufacturerCodes.placeholder' | cxTranslate }}"/>
                    <div class="input-group-append">
                      <button class="btn btn-primary d-flex" type="button"
                              (click)="onOpenModal()" [disabled]="isOrderTypeManufacturersWhiteList || manufacturerSelectionDisabled">
                        <cx-icon [type]="iconTypes.BROWSE"></cx-icon>
                      </button>
                    </div>
                  </div>
                  <cx-form-errors
                    [control]="cartProductSearchForm.get('manufacturerCodes')"></cx-form-errors>
                </label>
                <label class="form-group">
                    <span class="label-content">
                      {{ 'cartAdministration.cartProductSearchForm.oemNumber.label' | cxTranslate }}
                    </span>
                  <input class="form-control"
                         #oemNumberInput
                         appEfaAutoFocus [focus]="true"
                         type="text"
                         formControlName="oemNumber"
                         placeholder="{{ 'cartAdministration.cartProductSearchForm.oemNumber.placeholder' | cxTranslate }}"
                         (focusout)="testAndUntouch(cartProductSearchForm.get('oemNumber'))"/>
                  <cx-form-errors [control]="cartProductSearchForm.get('oemNumber')"></cx-form-errors>
                </label>
                <label class="c-product-search__input-85 form-group">
                    <span class="label-content">
                      {{ 'cartAdministration.cartProductSearchForm.quantity.label' | cxTranslate }}
                    </span>
                  <input class="form-control"
                         type="text"
                         formControlName="quantity"
                         placeholder="{{ 'cartAdministration.cartProductSearchForm.quantity.placeholder' | cxTranslate }}"/>
                  <cx-form-errors [control]="cartProductSearchForm.get('quantity')"></cx-form-errors>
                </label>
                <label class="form-group">
                    <span class="label-content">
                      {{ 'cartAdministration.cartProductSearchForm.consignmentInfo.label' | cxTranslate }}
                    </span>
                  <input class="form-control"
                         type="text"
                         [maxLength]="consignmentInfoAllowedMaxLength"
                         formControlName="consignmentInfo"
                         placeholder="{{ 'cartAdministration.cartProductSearchForm.consignmentInfo.placeholderSimple' | cxTranslate }}"/>
                  <cx-form-errors
                    [control]="cartProductSearchForm.get('consignmentInfo')"></cx-form-errors>
                </label>
                <div class="form-check" *ngIf="displaySearchForAlternativeArticles">
                  <input type="checkbox" class="form-check-input"
                         formControlName="searchForAlternativeArticles">
                  <label
                    class="form-check-label">{{ 'cartAdministration.cartProductSearchForm.searchForAlternativeArticles' | cxTranslate }}</label>
                </div>
                <div class="c-product-search__submit">
                  <button type="submit" class="btn btn-primary"
                          [disabled]="!cartProductSearchForm.valid || (isCartLoading$ | async)">
                    {{ 'cartAdministration.cartProductSearchForm.submit' | cxTranslate }}
                  </button>
                </div>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <cx-spinner class="cx-spinner"></cx-spinner>
  </ng-template>
</ng-container>
