import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output,} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, withLatestFrom} from 'rxjs/operators';
import {ICON_TYPE} from '../../model/shipping-backlog-enum.model';
import {ShippingBacklogOverviewFilter} from '../../model/shipping-backlog.model';
import {ShippingBacklogUiService} from '../../services/shipping-backlog-ui.service';
import {Config} from '@spartacus/core';
import {getInitialDateFrom} from '@shared/utils/date-tools';

@Component({
  selector: 'app-shipping-backlog-filter',
  templateUrl: './shipping-backlog-filter.component.html',
})
export class ShippingBacklogFilterComponent implements OnInit, OnDestroy {
  @Output()
  shippingBacklogOverviewFilterChanges: EventEmitter<ShippingBacklogOverviewFilter> =
    new EventEmitter<ShippingBacklogOverviewFilter>();

  shippingBacklogOverviewFilterForm: UntypedFormGroup;
  iconTypes = ICON_TYPE;
  currentDate: Date = new Date();
  maxDate: NgbDateStruct;

  private readonly formProperties = {
    oemNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    orderNumber: new UntypedFormControl('', {updateOn: 'blur'}),
    consignmentInfo: new UntypedFormControl('', {updateOn: 'blur'}),
    dateFrom: new UntypedFormControl(getInitialDateFrom(this.config.dateFrom.shippingBacklogOverview)),
    dateTo: new UntypedFormControl(undefined),
  };
  private readonly resetFormState = {
    oemNumber: '',
    orderNumber: '',
    consignmentInfo: '',
    dateFrom: getInitialDateFrom(this.config.dateFrom.shippingBacklogOverview),
    dateTo: undefined,
  };
  private subscription: Subscription = new Subscription();
  private wasReset = false;
  private filterHasChanged = false;

  constructor(
    private fb: UntypedFormBuilder,
    private shippingBacklogUiService: ShippingBacklogUiService,
    @Inject(Config) private config: any,
  ) {
  }

  ngOnInit(): void {
    this.disableFutureDates();
    this.shippingBacklogOverviewFilterForm = this.fb.group(this.formProperties);
    this.onChanges();
    this.setSelectedFilterEntity();
  }

  onChanges(): void {
    this.subscription.add(
      this.shippingBacklogOverviewFilterForm.valueChanges
      .pipe(
        delay(1000),
        distinctUntilChanged(
          (a, b) =>
            a.oemNumber === b.oemNumber &&
            a.orderNumber === b.orderNumber &&
            a.consignmentInfo === b.consignmentInfo &&
            a.dateTo === b.dateTo &&
            a.dateFrom === b.dateFrom
        )
      )
      .subscribe((filter: ShippingBacklogOverviewFilter) => {
        this.shippingBacklogOverviewFilterChanges.emit(filter);
        this.filterHasChanged = true;
        this.shippingBacklogUiService.setSelectedFilterEntity(filter);
      })
    );
  }

  setSelectedFilterEntity(): void {
    this.subscription.add(
      this.shippingBacklogUiService.selectedFilterEntity()
      .pipe(
        withLatestFrom(this.shippingBacklogUiService.returningFromDetails()))
      .subscribe(([filterEntity, fromDetails]: [ShippingBacklogOverviewFilter, boolean]) => {
        let filterToEmit = filterEntity;
        if (this.filterHasChanged) {
          this.filterHasChanged = false;
        } else {
          if (fromDetails) {
            this.shippingBacklogOverviewFilterForm.patchValue({
                orderNumber: filterEntity.orderNumber,
                oemNumber: filterEntity.oemNumber,
                consignmentInfo: filterEntity.consignmentInfo,
                dateFrom: filterEntity.dateFrom,
                dateTo: filterEntity.dateTo,
              },
              {emitEvent: false}
            );
          } else if (!this.wasReset) {
            this.wasReset = false;
          } else {
            filterToEmit = this.resetFormState;
          }
        }
        this.shippingBacklogOverviewFilterChanges.emit(filterToEmit);
      })
    );
  }

  disableFutureDates(): void {
    this.maxDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear(),
    };
  }

  triggerOnChanges(field: string, event: any): void {
    const patchValue = {};
    patchValue[field] = event.target.value;
    this.shippingBacklogOverviewFilterForm.patchValue(patchValue);
  }

  clearForm(): void {
    this.wasReset = true;
    this.shippingBacklogOverviewFilterForm.reset(this.resetFormState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
