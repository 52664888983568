import {StateUtils} from '@spartacus/core';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import { OrderSelectors, OrderState, StateWithOrder } from '@spartacus/order/core';

export const getOrderDetailsLoading: MemoizedSelector<
  StateWithOrder,
  boolean
  > = createSelector(OrderSelectors.getOrderState, (state: OrderState) =>
  StateUtils.loaderLoadingSelector(state.orderDetail)
);

export const getOrderDetailsFailed: MemoizedSelector<
  StateWithOrder,
  boolean
  > = createSelector(OrderSelectors.getOrderState, (state: OrderState) =>
  StateUtils.loaderErrorSelector(state.orderDetail)
);


export const getOrderDetailsLoaded: MemoizedSelector<
  StateWithOrder,
  boolean
  > = createSelector(OrderSelectors.getOrderState, (state: OrderState) =>
  StateUtils.loaderSuccessSelector(state.orderDetail)
);