import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { CartProductSearchAdapter } from '../occ/adapters/cart-product-search.adapter';
import { MultiAddToCartAdapter } from '../occ/adapters/multi-add-to-cart.adapter';
import { SetCartEntryInfosAdapter } from '../occ/adapters/set-cart-entry-infos.adapter';
import { UpdateCartAdapter } from '../occ/adapters/update-cart.adapter';

import { OccCartProductSearchAdapter } from './adapters/impl/occ-cart-product-search.adapter';
import { OccMultiAddToCartAdapter } from './adapters/impl/occ-multi-add-to-cart.adapter';
import { OccSetCartEntryInfosAdapter } from './adapters/impl/occ-set-cart-entry-infos.adapter';
import { OccUpdateCartAdapter } from './adapters/impl/occ-update-cart.adapter';
import { MultiAddToCartSerializer } from './converters/multi-add-to-cart.serializer';
import { MULTI_ADD_TO_CART_SERIALIZER } from './converters/converters';
import { SetCartCalculationModeAdapter } from './adapters/set-cart-calculation-mode.adapter';
import { OccSetCartCalculationModeAdapter } from './adapters/impl/occ-set-cart-calculation-mode.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: CartProductSearchAdapter,
      useClass: OccCartProductSearchAdapter,
    },
    {
      provide: MultiAddToCartAdapter,
      useClass: OccMultiAddToCartAdapter,
    },
    {
      provide: SetCartEntryInfosAdapter,
      useClass: OccSetCartEntryInfosAdapter,
    },
    {
      provide: UpdateCartAdapter,
      useClass: OccUpdateCartAdapter,
    },
    {
      provide: SetCartCalculationModeAdapter,
      useClass: OccSetCartCalculationModeAdapter,
    },
    {
      provide: MULTI_ADD_TO_CART_SERIALIZER,
      useExisting: MultiAddToCartSerializer,
      multi: true,
    }
  ],
})
export class OccModule {
}
