import { createSelector, MemoizedSelector } from '@ngrx/store';

import { getLoginRegistrationState, LoginRegistrationState } from '../reducers';
import * as RegistrationConfirmationFeature from '../reducers/registration-confirmation.reducer';
import { REGISTRATION_CONFIRMATION_FEATURE_KEY } from '../reducers/registration-confirmation.reducer';

export const selectLoading: MemoizedSelector<RegistrationConfirmationFeature.RegistrationConfirmationState, boolean>
  = createSelector(
  getLoginRegistrationState,
  (state: LoginRegistrationState) => state[REGISTRATION_CONFIRMATION_FEATURE_KEY].sending
);

export const selectError: MemoizedSelector<RegistrationConfirmationFeature.RegistrationConfirmationState, boolean>
  = createSelector(
  getLoginRegistrationState,
  (state: LoginRegistrationState) => state[REGISTRATION_CONFIRMATION_FEATURE_KEY].error
);
