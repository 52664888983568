import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import * as CategoryActions from './category.actions';
import { adapter, CategoryState } from './category.state';
import { SiteContextActions } from '@spartacus/core';

export const initialState = adapter.getInitialState({
  error: undefined,
  isLoading: false,
});

const reducer = createReducer(
  initialState,
  on(CategoryActions.LOAD_CATEGORY, (state) => {
    return { ...state, isLoading: true };
  }),
  on(CategoryActions.LOAD_CATEGORY_SUCCESS, (state: CategoryState, { payload }) => {
    return adapter.addOne(payload, { ...state, isLoading: false });
  }),
  on(CategoryActions.LOAD_CATEGORY_FAIL, (state: CategoryState, error: Error) => {
    console.log(error);
    return { ...state, error: error, isLoading: false };
  })
);

export function categoryReducer(state: CategoryState | undefined, action: Action) {
  return reducer(state, action);
}

export function clearCategoryState(actionReducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === SiteContextActions.CURRENCY_CHANGE || action.type === SiteContextActions.LANGUAGE_CHANGE) {
      state = initialState;
    }
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer<any>[] = [clearCategoryState];
