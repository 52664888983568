export const warrantyProcess = {
  warrantyProcess: {
    globalMessage: {
      startWarrantyProcessFailure: 'Die Daten zur Garantieabwicklung konnten nicht übertragen werden. Bitte versuchen Sie es erneut.',
      startWarrantyProcessSuccess: 'Die Daten zur Garantieabwicklung wurden erfolgreich übertragen.'
    },
    warrantyProcessForm: {
      legend1: '1. Geben Sie bitte Artikel und KFZ Daten ein:',
      legend2: '2. Informationen zum Einbau:',
      legend3: '3. Beschreiben Sie bitte den Defekt und Fehlercode:',
      legend4: '4. Laden Sie bitte Ihre Dokumente hoch:',
      formMandatoryFields:'Pflichtfelder sind mit einem * gekennzeichnet.',
      registrationDate: {
        label: 'Datum der Erstzulassung',
        placeholder: 'Datum der Erstzulassung'
      },
      mileage: {
        label: 'Kilometerstand',
        placeholder: 'Kilometerstand'
      },
      oemNumber: {
        label: 'Artikelnummer',
        placeholder: 'Artikelnummer'
      },
      manufacturer: {
        label: 'Marke',
        placeholder: 'Marke'
      },
      articleName: {
        label: 'Artikelbezeichnung',
        placeholder: 'Artikelbezeichnung'
      },
      customerComplaint: {
        label: 'Kundenbeanstandung',
        placeholder: 'Kundenbeanstandung'
      },
      installationDate: {
        label: 'Datum des Einbaus',
        placeholder: 'Datum des Einbaus'
      },
      installationMileage: {
        label: 'Kilometerstand bei Einbau',
        placeholder: 'Kilometerstand bei Einbau'
      },
      installationInvoiceDocument: {
        label: 'Einbaurechnung',
      },
      deliveryInvoiceDocument: {
        label: 'Lieferrechnung',
      },
      additionalDocument: {
        label: 'Zusätzliche Dokumente',
      },
      descriptionOfCause: {
        label: 'Ursachenbeschreibung (Defekt und Fehlercode)',
        placeholder: 'Ursachenbeschreibung (Defekt und Fehlercode)'
      },
      submitWarrantyProcessForm: 'Daten Absenden',
      resetForm: 'Zurücksetzen',
      messages: {
        maxFileSizeLimitExceeded: 'Das Hochladen der Datei ist fehlgeschlagen. Die maximale Dateigröße ({{maxFileSize}} MB) wurde überschritten.'
      }
    }
  },
};
