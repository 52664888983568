import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { CartAndCheckoutLoadingService } from '@shared/services/cart-and-checkout-loading.service';
import { LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { CART_SPINNER } from '../../../../config/default-cart-spinner-layout.config';

@Component({
  selector: 'app-efa-cart-checkout-spinner',
  template: ''
})
export class EfaCartCheckoutSpinnerComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  constructor(private cartAndCheckoutLoadingService: CartAndCheckoutLoadingService,
              private vcr: ViewContainerRef,
              private launchDialogService: LaunchDialogService) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.cartAndCheckoutLoadingService.getCombinedLoading()
        .subscribe((loading) => {
          if (loading && !this.cartAndCheckoutLoadingService.cartAndCheckoutRef) {
            this.cartAndCheckoutLoadingService.cartAndCheckoutRef = this.launchDialogService.launch(
              CART_SPINNER,
              this.vcr
            );
          } else {
            if (!loading && this.cartAndCheckoutLoadingService.cartAndCheckoutRef) {
              this.cartAndCheckoutLoadingService.cartAndCheckoutRef
                .subscribe((component) => {
                  this.launchDialogService.clear(
                    CART_SPINNER
                  );
                  if (component) {
                    component.destroy();
                  }
                  this.cartAndCheckoutLoadingService.cartAndCheckoutRef = undefined;
                })
                .unsubscribe();
            }
          }
        }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.cartAndCheckoutLoadingService.cartAndCheckoutRef = undefined;
    this.launchDialogService.clear(CART_SPINNER);
  }
}
