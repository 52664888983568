export const categoryInformation = {
  categoryInformation: {
    pageMetaResolver: {
      baseSite: {
        name: 'Emil Frey Autoteilewelt Webshop',
      },
      help: {
        name: 'Hilfe & Kontakt'
      },
      customerInfo: {
        name: 'Kundeninformationen'
      },
      myAccount: {
        name: 'Mein Konto',
        profile: {
          name: 'Profil'
        },
        favorites: {
          name: 'Favoriten'
        },
        documents: {
          name: 'Dokumentenverwaltung'
        },
        userManagement: {
          name: 'Benutzer & Rechteprofile'
        },
        forms: {
          name: 'Garantie & Schadensmeldung'
        },
        users: {
          name: 'Benutzer verwalten'
        },
        userGroups: {
          name: 'Rechteprofile definieren'
        },
      }
    }
  }
};
