import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ItemCounterComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-efa-item-counter',
  templateUrl: './efa-item-counter.component.html',
})
export class EfaItemCounterComponent extends ItemCounterComponent implements OnInit {

  @Output() isReturnPositionQuantityToReturnChanged = new EventEmitter<boolean>(false);
  ngOnInit(): void {
    // overriding ngOnInit because the super method sets wrongs values to the control if new items are created by cart simulation
  }

  onReturnPositionQuantityToReturnChange(){
  this.isReturnPositionQuantityToReturnChanged.emit(true);
  }
  updateItemQuantityOnEnter(event): void {
    this.control.setValue(event.target.value);
    this.control.markAsDirty();
  }
}
