import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { ReturnReasonResponse } from '../../model/return-creation.model';

export const loadReturnReasons = createAction(
  '[ReturnCreation] Load Return Reasons',
);

export const loadReturnReasonsSuccess = createAction(
  '[ReturnCreation] Load Return Reasons Success',
  props<{ response: ReturnReasonResponse }>()
);

export const loadReturnReasonsFailure = createAction(
  '[ReturnCreation] Load Return Reasons Failure',
  props<{ errorResponse: ErrorType }>()
);
