import { createReducer, on } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import { InvoiceCreditUiActions } from '../actions';
import { InvoiceCreditOverviewFilter } from '../../model/invoices-credits.model';

export const INVOICE_CREDIT_UI_FEATURE_KEY = 'invoiceCreditUi';

export interface InvoiceCreditUiState {
  selectedFilterEntity?: InvoiceCreditOverviewFilter;
  selectedPagination?: PaginationModel;
  selectedSort?: SortModel;
}

export const initialState: InvoiceCreditUiState = {
    selectedFilterEntity: {
      documentNumber: '',
      oemNumber: '',
      orderNumber: '',
      dateFrom: undefined,
      dateTo: undefined,
      documentTypeId: '',
      deliveryNoteNumber: '',
      consignmentInfo: '',
    },
  selectedPagination: {
      currentPage: 0,
      pageSize: 30,
      sort: '',
    },
  selectedSort: {
    code: '',
    name: '',
    selected: true
    }
  }
;

export const invoiceCreditUiReducer = createReducer(
  initialState,

  on(InvoiceCreditUiActions.setSelectedFilterEntity, (state, action) => ({
    ...state,
    selectedFilterEntity: action.selectedFilterEntity
  })),
  on(InvoiceCreditUiActions.setSelectedPagination, (state, action) => ({
    ...state,
    selectedPagination: action.selectedPagination
  })),
  on(InvoiceCreditUiActions.setSelectedSorting, (state, action) => ({
    ...state,
    selectedSort: action.selectedSort
  })),
);
