import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { CartProductSearchActions } from '../store/actions';
import * as CartProductSearchFeature from '../store/reducers/cart-product-search.reducer';
import * as CartProductSearchSelectors from '../store/selectors/cart-product-search.selectors';
import { CartProductSearchRequest, CartProductSearchResponse } from '../model/cart-administration.model';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({providedIn: 'root'})
export class CartProductSearchService {

  private cartProductSearchForm$: BehaviorSubject<UntypedFormGroup> = new BehaviorSubject(undefined);

  constructor(protected store: Store<CartProductSearchFeature.CartProductSearchState>) {}

  // create CartProductSearchRequest from the function parameter
  searchProducts(request: CartProductSearchRequest): void {
    return this.store.dispatch(CartProductSearchActions.loadCartProductSearch({ request }));
  }

  getSearchResponse(): Observable<CartProductSearchResponse> {
    return this.store.select(CartProductSearchSelectors.selectProductSearchResponse);
  }

  getLoading(): Observable<boolean> {
    return this.store.select(CartProductSearchSelectors.selectLoading);
  }

  reset(): void {
    return this.store.dispatch(CartProductSearchActions.resetCartProductSearch());
  }
}
