import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import { AllowedFileExtensionsResponse } from '@shared/models/shared.model';


export const loadAllowedDocumentExtensions = createAction(
  '[AllowedDocumentExtensions] Load Allowed Document Extensions',
  props<{ contextId: string }>()
);

export const loadAllowedDocumentExtensionsSuccess = createAction(
  '[AllowedDocumentExtensions] Load Allowed Document Extensions Success',
  props<{ response: AllowedFileExtensionsResponse }>()
);

export const loadAllowedDocumentExtensionsFailure = createAction(
  '[AllowedDocumentExtensions] Load Allowed Document Extensions Failure',
  props<{ errorResponse: ErrorType }>()
);
