import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appEfaAutoScroll]',
})
export class EfaAutoScroll implements AfterViewInit {
  @Input() scroll: boolean = false;
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.scroll) {
      this.elementRef.nativeElement.scrollIntoView({behavior: 'smooth'});
    }
  }
}
