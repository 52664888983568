<cx-generic-link
  *ngIf="data$ | async as data"
  [url]="data.url"
  [target]="getTarget(data)"
>
  <cx-icon
    *ngIf="data.styleAttributes"
    [type]="'HELP'"
    class="mr-2 small"
  ></cx-icon
  >{{ data.linkName }}
</cx-generic-link>
