import { Component, OnInit } from '@angular/core';
import { CmsBannerComponent, GlobalMessageService } from '@spartacus/core';
import {
  CmsComponentData,
  PageLayoutService,
  ProductListComponent,
  ProductListComponentService,
  ViewConfig,
  ViewModes
} from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { CmsProductListComponent } from '../../model/product-search.model';

@Component({
  selector: 'app-efa-product-list',
  templateUrl: './efa-product-list.component.html'
})
export class EfaProductListComponent extends ProductListComponent implements OnInit {
  banner$: Observable<CmsBannerComponent>;

  constructor(
    pageLayoutService: PageLayoutService,
    productListComponentService: ProductListComponentService,
    globalMessageService: GlobalMessageService,
    public scrollConfig: ViewConfig,
    private component: CmsComponentData<CmsProductListComponent>,
  ) {
    super(pageLayoutService, productListComponentService, globalMessageService, scrollConfig);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.viewMode$.next(ViewModes.Grid);
    this.banner$ = this.component.data$.pipe(map((data: CmsProductListComponent) => data.bannerComponent));
  }
}
