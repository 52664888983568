import { createAction, props } from '@ngrx/store';
import { ErrorType } from '../../model/error-type.model';

export const generateToken = createAction(
  '[TokenGeneration] Generate Token',
  props<{ tokenName: string, saveToken: boolean }>()
);

export const reset = createAction(
  '[TokenGeneration] Reset Token',
);

export const generateTokenSuccess = createAction(
  '[TokenGeneration] Generate Token Success',
  props<{ tokenValue: string }>()
);

export const generateTokenFailure = createAction(
  '[TokenGeneration] Generate Token Failure',
  props<{ errorResponse: ErrorType }>()
);
