import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { OrderHistoryList } from '@spartacus/order';
import { OrderOverviewRequest } from '../../../model/order-history.model';
import { OrderOverviewAdapter } from '../order-overview.adapter';

@Injectable({providedIn: 'root'})
export class OccOrderOverviewAdapter implements OrderOverviewAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  loadOverview(request: OrderOverviewRequest): Observable<OrderHistoryList> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'orderOverview',
      {
        urlParams: {
          userId: 'current'
        }
      }
    );

    return this.http.post<OrderHistoryList>(url, request, {headers});
  }
}
