<ng-container *ngIf="{
    isLoading: isLoading$ | async,
    shippingBacklogCancellationItems: shippingBacklogCancellationItems$ | async,
    dateFormat: dateFormat$ | async
  } as type">
    <ng-container *ngIf="type.shippingBacklogCancellationItems && !type.isLoading && type.dateFormat; else loading">
        <div class="c-shipping-backlog-cancellation">
            <table class="c-shipping-backlog-cancellation__table table table--responsive">
                <thead>
                    <tr>
                        <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.orderNumber' | cxTranslate }}</th>
                        <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.orderPositionNumber' | cxTranslate }}
                        </th>
                        <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.oemNumber' | cxTranslate }}</th>
                        <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.requestedQuantity' | cxTranslate }}</th>
                        <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.alreadyDeliveredQuantity' | cxTranslate }}
                        </th>
                        <th scope="col">{{ 'shippingBacklog.shippingBacklogItem.quantityToCancel' | cxTranslate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let shippingBacklogCancellationItem of type.shippingBacklogCancellationItems">
                        <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.orderNumber' | cxTranslate }}"
                            class="cx-order-history-number">
                            {{ shippingBacklogCancellationItem?.orderNumber }}
                        </td>
                        <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.orderPositionNumber' | cxTranslate }}"
                            class="cx-order-history-number">
                            {{ shippingBacklogCancellationItem?.orderPositionNumber }}
                        </td>
                        <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.oemNumber' | cxTranslate }}"
                            class="cx-order-history-number">
                            {{ shippingBacklogCancellationItem?.oemNumber }}
                        </td>
                        <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.requestedQuantity' | cxTranslate }}"
                            class="cx-order-history-number">
                            {{ shippingBacklogCancellationItem?.requestedQuantity }}
                        </td>
                        <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.alreadyDeliveredQuantity' | cxTranslate }}"
                            class="cx-order-history-number">
                            {{ shippingBacklogCancellationItem?.alreadyDeliveredQuantity }}
                        </td>
                        <td attr.data-label="{{ 'shippingBacklog.shippingBacklogItem.quantityToCancel' | cxTranslate }}"
                            class="cx-order-history-number">
                            {{ shippingBacklogCancellationItem?.quantityToCancel }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="c-shipping-backlog-cancellation__bottom row justify-content-end">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                <button class="btn btn-block btn-secondary" (click)="goBack()"
                        [routerLink]="{  cxRoute: 'shippingBacklogOverview' } | cxUrl">
                  {{ 'common.back' | cxTranslate }}
                </button>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                <button class="btn btn-block btn-primary" (click)="sendShippingBacklogCancellation(type.shippingBacklogCancellationItems)">
                    {{ 'shippingBacklog.requestCancellationButton' | cxTranslate }}
                </button>
              </div>
            </div>
        </div>
    </ng-container>
    <ng-template #loading>
        <ng-container *ngIf="type.isLoading">
            <div class="cx-spinner">
                <cx-spinner></cx-spinner>
            </div>
        </ng-container>
    </ng-template>
</ng-container>
