import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ProductPricesActions from './product-prices.actions';
import { ProductPricesReponse } from '../model/product-prices.model';
import { ProductPricesConnector } from '../occ/product-prices.connector';


@Injectable()
export class ProductPricesEffects {

  loadProductPrices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductPricesActions.loadProductPrices),
      concatMap((action) =>
        this.connector.retrievePrices(action.data.products, action.data.orderType, action.data.userId, action.data.returnDeliveryTime).pipe(
          map((productPricesReponse: ProductPricesReponse) => {
            return ProductPricesActions.loadProductPricesSuccess({
              data: {
                productPricesReponse: productPricesReponse,
                returnDeliveryTime: action.data.returnDeliveryTime,
              },
            });
          }),
          catchError((error) => {
            return of(ProductPricesActions.loadProductPricesFailure(error));
          })
        )
      )
    );
  });


  constructor(private actions$: Actions, private connector: ProductPricesConnector) {
  }

}
