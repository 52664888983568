<div #dialog>
  <!-- Modal Header -->
  <ng-container *ngIf="(loaded$ | async); else loading">
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        {{'addToCart.itemsAddedToYourCart'| cxTranslate}}
      </div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        cxModal="dismiss"
        cxModalReason="Cross click"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>
    <!-- Modal Body -->
    <div class="cx-dialog-body add-to-cart-modal-body" *ngIf="entry$ | async as entry">
      <div class="cx-dialog-row">
        <div class="cx-dialog-item col-sm-12 col-md-6">
          <app-efa-cart-item
            [compact]="true"
            [item]="entry"
            [readonly]="true"
          ></app-efa-cart-item>
        </div>
        <!-- Separator -->
        <div
          class="cx-dialog-separator col-sm-12 d-xs-block d-sm-block d-md-none"
        ></div>
        <!-- Total container -->
        <div class="cx-dialog-actions col-sm-12 col-md-6">
          <div class="cx-dialog-total" *ngIf="cart$ | async as cart">
            <div>
              {{
                'cartItems.cartTotal'
                  | cxTranslate: { count: cart.deliveryItemsQuantity }
              }}
            </div>

            <div>{{ cart.subTotal?.formattedValue }}</div>
          </div>

          <!-- Actions -->
          <div class="cx-dialog-buttons">
            <a
              [class.disabled]="form.dirty"
              [routerLink]="{ cxRoute: 'cart' } | cxUrl"
              cxModal="dismiss"
              cxModalReason="View Cart click"
              class="btn btn-primary"
              autofocus
              >{{ 'addToCart.viewCart' | cxTranslate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        {{ 'addToCart.updatingCart' | cxTranslate }}
      </div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        cxModal="dismiss"
        cxModalReason="Cross click"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>
    <!-- Modal Body -->
    <div class="cx-dialog-body add-to-cart-modal-body">
      <div class="cx-dialog-row">
        <div class="col-sm-12"><cx-spinner></cx-spinner></div>
      </div>
    </div>
  </ng-template>
</div>
