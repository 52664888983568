import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { DeliveryNotesOverviewAdapter } from './adapters/delivery-notes-overview.adapter';
import { OccDeliveryNotesOverviewAdapter } from './adapters/impl/occ-delivery-notes-overview.adapter';
import { DeliveryNotesDetailsAdapter } from './adapters/delivery-notes-details.adapter';
import { OccDeliveryNotesDetailsAdapter } from './adapters/impl/occ-delivery-notes-details.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: DeliveryNotesOverviewAdapter,
      useClass: OccDeliveryNotesOverviewAdapter,
    },
    {
      provide: DeliveryNotesDetailsAdapter,
      useClass: OccDeliveryNotesDetailsAdapter,
    }
  ],
})
export class OccModule {
}
