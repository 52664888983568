import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import * as DeliveryNotesUiFeature from '../reducers/delivery-notes-ui.reducer';
import { DELIVERY_NOTES_UI_FEATURE_KEY } from '../reducers/delivery-notes-ui.reducer';
import { DeliveryNotesState, getDeliveryNotesState } from '../reducers';
import { DeliveryNoteOverviewFilter } from '../../model/delivery-notes.model';


export const selectFilterEntity: MemoizedSelector<DeliveryNotesUiFeature.DeliveryNotesUiState, DeliveryNoteOverviewFilter>
  = createSelector(
  getDeliveryNotesState,
  (state: DeliveryNotesState) => state[DELIVERY_NOTES_UI_FEATURE_KEY].selectedFilterEntity
);

export const selectPagination: MemoizedSelector<DeliveryNotesUiFeature.DeliveryNotesUiState, PaginationModel> = createSelector(
  getDeliveryNotesState,
  (state: DeliveryNotesState) => state[DELIVERY_NOTES_UI_FEATURE_KEY].selectedPagination
);


export const selectSort: MemoizedSelector<DeliveryNotesUiFeature.DeliveryNotesUiState, SortModel> = createSelector(
  getDeliveryNotesState,
  (state: DeliveryNotesState) => state[DELIVERY_NOTES_UI_FEATURE_KEY].selectedSort
);


export const returningFromDetails: MemoizedSelector<DeliveryNotesUiFeature.DeliveryNotesUiState, boolean> = createSelector(
  getDeliveryNotesState,
  (state: DeliveryNotesState) => state[DELIVERY_NOTES_UI_FEATURE_KEY].returningFromDetails);
