import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {categoryReducer, metaReducers} from './store/category.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CategoryEffects} from './store/category.effects';

import {CATEGORY_NORMALIZER, OccCategoryAdapter} from './occ/category.adapter';
import {OccCategoryNormalizer} from './occ/category.normalizer';
import {CATEGORY_FEATURE} from './store/category.state';
import {ConfigModule, OccConfig, PageMetaResolver} from '@spartacus/core';
import {CustomCategoryPageMetaResolver} from './meta-resolver/custom-category-page-meta-resolver';
import {
  categoryInformationTranslationChunksConfig,
  categoryInformationTranslations
} from "./translations/category-information.translations";
import {CustomContentPageMetaResolver} from "./meta-resolver/custom-content-page-meta-resolver";
import {CustomProductPageMetaResolver} from "./meta-resolver/custom-product-page-meta-resolver";
import {CustomSearchPageMetaResolver} from "./meta-resolver/custom-search-page-meta-resolver";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(CATEGORY_FEATURE, categoryReducer, {metaReducers}),
    EffectsModule.forFeature([CategoryEffects]),
    ConfigModule.withConfig({
      i18n: {
        resources: categoryInformationTranslations,
        chunks: categoryInformationTranslationChunksConfig
      },
      backend: {
        occ: {
          endpoints: {
            category: '/categories/${categoryId}?fields=DEFAULT',
          },
        },
      },
    } as OccConfig),
  ],
  providers: [
    {
      provide: CATEGORY_NORMALIZER,
      useExisting: OccCategoryNormalizer,
      multi: true,
    },
    {
      provide: OccCategoryAdapter,
      useClass: OccCategoryAdapter,
    },
    {
      provide: PageMetaResolver,
      useExisting: CustomCategoryPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: CustomProductPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: CustomContentPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: CustomSearchPageMetaResolver,
      multi: true,
    },
  ],
})
export class CategoryInformationModule {
}
