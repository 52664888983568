import { createSelector, MemoizedSelector } from "@ngrx/store";
import * as DefaultManufacturersFeature from '../reducers/default-manufacturers.reducer';
import { DEFAULT_MANUFACTURERS_FEATURE_KEY } from '../reducers/default-manufacturers.reducer';;
import { getDefaultManufacturersState, DefaultManufacturersState } from '../reducers';

export const selectUpdating: MemoizedSelector<DefaultManufacturersFeature.DefaultManufacturersState, boolean> = createSelector(
  getDefaultManufacturersState,
  (state: DefaultManufacturersState) => state[DEFAULT_MANUFACTURERS_FEATURE_KEY].updating
);

export const selectSuccess: MemoizedSelector<DefaultManufacturersFeature.DefaultManufacturersState, boolean> = createSelector(
  getDefaultManufacturersState,
  (state: DefaultManufacturersState) => state[DEFAULT_MANUFACTURERS_FEATURE_KEY].success
);