import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {CartProductSearchActions} from '../actions';
import {CartProductSearchConnector} from '../../occ/connectors/cart-product-search.connector';
import {CartProductSearchResponse} from '../../model/cart-administration.model';
import {GlobalMessageType} from '@spartacus/core';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class CartProductSearchEffect {
  loadCartProductSearchs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartProductSearchActions.loadCartProductSearch),
      concatMap((action) =>
        this.connector.searchProducts(action.request).pipe(
          map((cartProductSearchResponse: CartProductSearchResponse) => {
            return CartProductSearchActions.loadCartProductSearchSuccess({
              response: cartProductSearchResponse,
              oemNumber: action.request.oemNumber
            });
          }),
          catchError((error) => {
            this.globalMessageService.add(
              {
                key: 'cartAdministration.globalMessage.cartProductSearchFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(CartProductSearchActions.loadCartProductSearchFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: CartProductSearchConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {}
}
