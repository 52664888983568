<ng-container *ngIf="order$ | async as order">
    <div class="row justify-content-end">
        <div class="col-12 col-md-2" *ngIf="order?.entries">
            <button type="button" class="btn btn-block btn-secondary" (click) = goBack()>
                {{ 'common.back' | cxTranslate }}
            </button>
        </div>
        <div class="col-12 col-md-5 col-cancel-btn" *ngIf="order?.entries">
            <button type="button" class="btn btn-block btn-primary" (click)="startCancellation()" [disabled]="order?.status === 'D' || order?.entries.length==0">
                {{ 'orderHistoryCustom.cancellation' | cxTranslate }}
            </button>
        </div>
    </div>
</ng-container>
