import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { OrderInfoFieldValue } from '../../model/cart-administration.model';

export const setCartEntryInfos = createAction(
  '[SetCartEntryInfos] SetCartEntryInfos',
  props<{
    cartId: string,
    entryNumber: number,
    consignmentInfo: string,
    orderInfoFieldValues: OrderInfoFieldValue[],
    cartEntryInfoType: string,
    entryIndex: number
  }>()
);

export const setCartEntryInfosSuccess = createAction(
  '[SetCartEntryInfos] SetCartEntryInfos Success',
  props<{
    cartId: string,
    entryNumber: number,
    consignmentInfo: string,
    orderInfoFieldValues: OrderInfoFieldValue[],
    entryIndex: number
  }>()
);

export const setCartEntryInfosFailure = createAction(
  '[SetCartEntryInfos] SetCartEntryInfos Failure',
  props<{ error: ErrorType }>()
);

export const setIsAllManufacturerSelected = createAction(
  '[SetCartEntryInfos] Set Is All Manufacturer Selected',
  props<{ isAllSelected: boolean }>()
);
