import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  InterceptorUtil,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { Observable } from 'rxjs';

import { RegistrationInformationMultiOptionsFieldsValuesResponse } from '../../../model/login-register.model';
import { RegistrationInformationMultiOptionFieldsValuesAdapter } from '../registration-information-multi-option-fields-values.adapter';

@Injectable({ providedIn: 'root' })
export class OccRegistrationInformationMultiOptionFieldsValuesAdapter
  implements RegistrationInformationMultiOptionFieldsValuesAdapter
{
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  protected getHeaders(userId: string): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    return headers;
  }

  public loadRegistrationInformationMultiOptionFieldsValues(): Observable<RegistrationInformationMultiOptionsFieldsValuesResponse> {
    const url = this.occEndpoints.buildUrl(
      'registrationInformationMultiOptionsFieldsValues',
      {
        urlParams: {
          userId: 'current',
        },
      }
    );

    const headers = this.getHeaders('current');

    return this.http.get<RegistrationInformationMultiOptionsFieldsValuesResponse>(url, {
      headers,
    });
  }
}
