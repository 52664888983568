<span *ngIf="selectedOrderType && selectedShippingAddress && selectedShippingType && nativeDeliveryDate" class="small d-none d-md-inline header-summary-text">(
    {{ selectedOrderType.name }}
    /
    {{ selectedShippingAddress.line1 }} {{ selectedShippingAddress.line2 }}, {{ selectedShippingAddress.postalCode }} {{ selectedShippingAddress.town }}
    /
    {{ selectedShippingType.name }}
    <ng-container *ngIf="displayRequestedDeliveryDate">
        /
        {{ nativeDeliveryDate | cxDate: 'dd.MM.yyyy' }}
    </ng-container>
)</span>
