import { Injectable, OnDestroy } from '@angular/core';
import { StatePersistenceService, StorageSyncType } from '@spartacus/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfiguratorCartConfirmationLocalStorageService
  implements OnDestroy
{
  protected subscription: Subscription = new Subscription();
  protected configuratorCartConfirmed$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  protected configuratorCartConfirmedSERP$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  protected configuratorCartConfirmedPDP$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  protected configuratorCartConfirmedTopmotive$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  protected readonly keyPrefix: string = 'configuratorCartConfirmation:';
  protected readonly keyPrefixSERP: string = 'configuratorCartConfirmationSERP:';
  protected readonly keyPrefixPDP: string = 'configuratorCartConfirmationPDP:';
  protected readonly keyPrefixTopmotive: string = 'configuratorCartConfirmationTopmotive:';

  constructor(protected statePersistenceService: StatePersistenceService) {}

  initSync(cartId: string): void {
    this.subscription.add(
      this.statePersistenceService.syncWithStorage({
        key: this.keyPrefix + cartId,
        state$: this.getConfiguratorCartConfirmed(),
        storageType: StorageSyncType.LOCAL_STORAGE,
        onRead: (state: boolean) => this.onRead(state),
      })
    );
  }
  initSyncSERP(cartId: string): void {
    this.subscription.add(
      this.statePersistenceService.syncWithStorage({
        key: this.keyPrefixSERP + cartId,
        state$: this.getConfiguratorCartConfirmedSERP(),
        storageType: StorageSyncType.LOCAL_STORAGE,
        onRead: (state: boolean) => this.onReadSERP(state),
      })
    );
  }
  initSyncPDP(cartId: string): void {
    this.subscription.add(
      this.statePersistenceService.syncWithStorage({
        key: this.keyPrefixPDP + cartId,
        state$: this.getConfiguratorCartConfirmedPDP(),
        storageType: StorageSyncType.LOCAL_STORAGE,
        onRead: (state: boolean) => this.onReadPDP(state),
      })
    );
  }
  initSyncTopmotive(cartId: string): void {
    this.subscription.add(
      this.statePersistenceService.syncWithStorage({
        key: this.keyPrefixTopmotive + cartId,
        state$: this.getConfiguratorCartConfirmedTopmotive(),
        storageType: StorageSyncType.LOCAL_STORAGE,
        onRead: (state: boolean) => this.onReadTopmotive(state),
      })
    );
  }

  setConfiguratorCartConfirmed(confirmed: boolean): void {
    this.configuratorCartConfirmed$.next(confirmed);
  }
  setConfiguratorCartConfirmedSERP(confirmed: boolean): void {
    this.configuratorCartConfirmedSERP$.next(confirmed);
  }
  setConfiguratorCartConfirmedPDP(confirmed: boolean): void {
    this.configuratorCartConfirmedPDP$.next(confirmed);
  }
  setConfiguratorCartConfirmedTopmotive(confirmed: boolean): void {
    this.configuratorCartConfirmedTopmotive$.next(confirmed);
  }

  getConfiguratorCartConfirmed(): Observable<boolean> {
    return this.configuratorCartConfirmed$.asObservable();
  }
  getConfiguratorCartConfirmedSERP(): Observable<boolean> {
    return this.configuratorCartConfirmedSERP$.asObservable();
  }
  getConfiguratorCartConfirmedPDP(): Observable<boolean> {
    return this.configuratorCartConfirmedPDP$.asObservable();
  }
  getConfiguratorCartConfirmedTopmotive(): Observable<boolean> {
    return this.configuratorCartConfirmedTopmotive$.asObservable();
  }

  protected onRead(state: boolean | undefined): void {
    this.setConfiguratorCartConfirmed(state);
  }
  protected onReadSERP(state: boolean | undefined): void {
    this.setConfiguratorCartConfirmedSERP(state);
  }
  protected onReadPDP(state: boolean | undefined): void {
    this.setConfiguratorCartConfirmedPDP(state);
  }
  protected onReadTopmotive(state: boolean | undefined): void {
    this.setConfiguratorCartConfirmedTopmotive(state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
