import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as UpdateCartFeature from '../reducers/update-cart.reducer';
import { UPDATE_CART_FEATURE_KEY } from '../reducers/update-cart.reducer';
import { CartAdministrationState, getCartAdministrationState } from '../reducers';

export const selectIsUpdating: MemoizedSelector<UpdateCartFeature.UpdateCartState, boolean> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[UPDATE_CART_FEATURE_KEY].isUpdating
);

export const selectSuccess: MemoizedSelector<UpdateCartFeature.UpdateCartState, boolean> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[UPDATE_CART_FEATURE_KEY].success
);
