import { createAction, props } from '@ngrx/store';
import { InvoicesCreditOverviewRequest, InvoicesCreditOverviewResponse } from '../../model/invoices-credits.model';
import { ErrorType } from '@shared/models/ErrorTypes';

export const loadInvoiceCreditOverview = createAction(
  '[InvoicesCredits] Load Invoice Credit Overview',
  props<{ invoiceRequest: InvoicesCreditOverviewRequest }>()
);

export const loadInvoiceCreditOverviewSuccess = createAction(
  '[InvoicesCredits] Load Invoice Credit Overview Success',
  props<{ invoiceResponse: InvoicesCreditOverviewResponse }>()
);

export const loadInvoiceCreditOverviewFaillure = createAction(
  '[InvoicesCredits] Load Invoice Credit Overview Success',
  props<{ errorResponse: ErrorType }>()
);
