export const returnHistory = {
  returnOverview: {
    returnOverviewFilterForm: {
      returnNumber: {
        label: 'Retourennummer',
        placeholder: 'Retourennummer'
      },
      deliveryNoteNumber: {
        label: 'Lieferscheinnummer',
        placeholder: 'Lieferscheinnummer'
      },
      status: {
        label: 'Status',
        placeholder: 'Status'
      },
      dateFrom: {
        label: 'Datum von',
        placeholder: 'Datum von'
      },
      dateTo: {
        label: 'Datum bis',
        placeholder: 'Datum bis'
      }
    },
    sorting: {
      returnNumber: 'Retourennummer',
      creationDate: 'Datum',
      deliveryNoteNumber: 'Lieferscheinnummer',
      statusCode: 'code',
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Retouren sortieren',
      returnNumber: 'Retourenummer',
      deliveryNoteNumber: 'Lieferscheinnummer',
      statusCode: 'Statuscode',
      statusDisplay: 'Status',
      creationDate: 'Erstellungsdatum',
      positionCount: 'Positionsanzahl',
    },
    globalMessage: {
      loadingReturnsOverviewFailure: 'Beim Öffnen der Retourenübersicht ist ein Fehler aufgetreten.'
    }
  },
  returnDetails: {
    tableTitle: 'Retoure',
    creationDate: 'Erstellungsdatum',
    returnNumber: 'Ihre Retourennummer',
    deliveryNoteNumber: 'Lieferscheinnummer',
    statusDisplay: 'Status',
    article: 'Artikel',
    quantity: 'Menge / Status',
    order: 'Auftrag',
    returnableReason: 'Retouregrund',
    positionNumber: 'Lieferschein-Position',
    orderNumber: 'Auftragsnummer',
    orderPositionNumber: 'Nr. Auftragsposition',
    deliveryNotePositionNumber: '',
    oemNumber: 'Art.-Nr',
    articleName: 'Artikelname',
    consignmentInfo: 'zusätzliche Information',
    reasonCode: 'Grundcode',
    reasonName: 'Grundname',
    comment: 'Kommentar',
    statusCode: 'Statuscode',
    requestedReturnQuantity: 'Retourniert',
    returnedQuantity: 'Genehmigt',
    positionStatusDisplay: 'Positionsstatus',
  }
};
