<button
  type="button"
  (click)="decrement()"
  [disabled]="control.disabled || control.value <= min"
  [tabindex]="control.disabled || control.value <= min ? -1 : 0"
  attr.aria-label="{{ 'itemCounter.removeOne' | cxTranslate }}"
>
  -
</button>
<input
  #qty
  type="number"
  [min]="min"
  [max]="max"
  [step]="step"
  [readonly]="readonly"
  [tabindex]="readonly ? -1 : 0"
  [formControl]="control"
  (keyup.enter)="updateItemQuantityOnEnter($event)"
  (ngModelChange)="onReturnPositionQuantityToReturnChange()"
  attr.aria-label="{{ 'itemCounter.quantity' | cxTranslate }}"
/>
<button
  type="button"
  (click)="increment()"
  [disabled]="control.disabled || control.value >= max"
  tabindex="0"
  attr.aria-label="{{ 'itemCounter.addOneMore' | cxTranslate }}"
>
  +
</button>
