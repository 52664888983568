<div class="c-review">
  <div class="c-review__container row">
    <div class="c-review__summary-card col-xs-12 col-sm-12 col-md-6">
      <!-- RETURN NUMBER SECTION -->
      <div class="return-number">
        <cx-card
               [content]="getReturnNumber() | async">
        </cx-card>
      </div>
      <!-- CREATION DATE SECTION -->
      <div class="date-section">
        <cx-card
        [content]="getCreationDate() | async">
        </cx-card>
      </div>
      <!-- DELIVERY NOTE NUMBER SECTION -->
      <div class="delivery-note-number-section">
        <cx-card
        [content]="getDeliveryNoteNumber() | async">
        </cx-card>
      </div>
    </div>
    <!-- STATUS SECTION -->
    <div class="c-review__summary-card col-xs-12 col-sm-12 col-md-6 status-section">
      <cx-card
               [content]="getStatusDisplay() | async">
      </cx-card>
    </div>
  </div>
</div>
