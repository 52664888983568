import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {ConfigModule, I18nModule, OccConfig, UrlModule} from '@spartacus/core';
import {CmsPageGuard, IconModule, PageLayoutComponent, SupplementHashAnchorsModule,} from '@spartacus/storefront';
import {FaqDetailsComponent} from './components/faq-details/faq-details.component';
import {FaqOverviewComponent} from './components/faq-overview/faq-overview.component';
import {OccModule} from './occ/occ.module';
import {effects} from './store/effects';
import {FAQ_ITEMS_FEATURE_KEY, reducers} from './store/reducers';
import {faqItemsTranslationChunksConfig, faqItemsTranslations,} from './translations/faq-items.translations';

@NgModule({
  declarations: [FaqOverviewComponent, FaqDetailsComponent],
  imports: [
    CommonModule,
    OccModule,
    IconModule,
    I18nModule,
    UrlModule,
    SupplementHashAnchorsModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FaqOverviewComponent: {
          component: FaqOverviewComponent,
        },
        FaqDetailsComponent: {
          component: FaqDetailsComponent,
        },
      },
      i18n: {
        resources: faqItemsTranslations,
        chunks: faqItemsTranslationChunksConfig,
      },
      routing: {
        routes: {
          faqItemDetails: {
            paths: ['help/faq-details/:faqItemCode'],
          },
          faqItemDetailsAnonymous: {
            paths: ['help/faq-details-anonymous/:faqItemCode'],
            protected: false,
          },
          faqItemsOverview: {
            paths: ['help/faq'],
          },
        },
      },
    }),
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            faqItemDetails: 'users/${userId}/faqItems/${faqItemCode}',
            faqOverview: 'users/${userId}/faqOverview',
          },
        },
      },
    } as OccConfig),
    RouterModule.forChild([
      {
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'faqItemDetails' },
      },
      {
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'faqItemDetailsAnonymous' },
      },
    ]),
    StoreModule.forFeature(FAQ_ITEMS_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
  ],
})
export class FaqItemsModule {}
