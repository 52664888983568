import { Component, OnInit } from '@angular/core';

import { TranslationService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';

import { formatDate } from '@angular/common';
import { Card } from '@spartacus/storefront';
import { map } from 'rxjs/operators';
import { ReturnDetailsResponse } from '../../model/return-history.model';
import { ReturnDetailsService } from '../../services/return-details.service';
import { ReturnHistoryUiService } from '../../services/return-history-ui.service';

@Component({
  selector: 'app-return-details',
  templateUrl: './return-details.component.html',
})
export class ReturnDetailsComponent implements OnInit {
  returnDetails$: Observable<ReturnDetailsResponse>;
  isLoading$: Observable<boolean>;

  constructor(
    private translation: TranslationService,
    private returnDetailsService: ReturnDetailsService,
    private returnHistoryUiService: ReturnHistoryUiService,
  ) {
  }

  ngOnInit(): void {
    this.returnDetails$ = this.returnDetailsService.getReturnDetailsResponse();
    this.isLoading$ = this.returnDetailsService.isLoading();
  }

  getDeliveryNoteNumber(deliveryNoteNumber: string): Observable<Card> {
    return combineLatest([
      this.translation.translate('returnDetails.deliveryNoteNumber'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: deliveryNoteNumber,
        };
      })
    );
  }

  getCreationDate(creationDate: Date): Observable<Card> {
    return combineLatest([
      this.translation.translate('returnDetails.creationDate'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: formatDate(creationDate, 'dd.MM.yyyy', 'en-US'),
        };
      })
    );
  }

  getReturnNumber(returnNumber: string): Observable<Card> {
    return combineLatest([
      this.translation.translate('returnDetails.returnNumber'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: returnNumber,
        };
      })
    );
  }

  getStatusDisplay(statusDisplay: string): Observable<Card> {
    return combineLatest([
      this.translation.translate('returnDetails.statusDisplay'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: statusDisplay,
        };
      })
    );
  }

  goBack(): void {
    this.returnHistoryUiService.setReturningFromDetails(true);
  }
}
