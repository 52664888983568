import { Injectable } from '@angular/core';
import { SetCartEntryInfosAdapter } from '../adapters/set-cart-entry-infos.adapter';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetCartEntryInfosConnector {

  constructor(protected adapter: SetCartEntryInfosAdapter) {}

  setCartEntryInfos(cartId,  entryNumber, consignmentInfo, orderInfoFieldValues): Observable<any> {
    return this.adapter.setCartEntryInfos(cartId,  entryNumber, consignmentInfo, orderInfoFieldValues);
  }
}
