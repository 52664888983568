import { createReducer, on } from "@ngrx/store";
import { TransportDamageRequest } from "../../model/transport-damage.model";
import { TransportDamageActions } from "../actions";

export const TRANSPORT_DAMAGE_FEATURE_KEY = 'transportDamage';

export interface TransportDamageState {
  request: TransportDamageRequest;
  processing: boolean;
  success: boolean;
}

export const initialState: TransportDamageState = {
  request: undefined,
  processing: false,
  success: undefined
}

export const transportDamageReducer = createReducer(
  initialState,

  on(TransportDamageActions.adviseOfTransportDamage, (state, action) => ({
    ...state,
    processing: true,
    request: action.request
  })),
  on(TransportDamageActions.adviseOfTransportDamageSuccess, (state) => ({
    ...state,
    processing: false,
    success: true,
    request: undefined
  })),
  on(TransportDamageActions.adviseOfTransportDamageFailure, (state, action) => ({
    ...state,
    processing: false,
    success: false,
    error: action.errorResponse
  }))
);
