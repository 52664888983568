<div
  class="cx-modal-container modal-dialog-centered"
>

  <div class="cx-modal-content">

    <div class="cx-dialog-content modal-body">
      <div class="cx-dialog-row">
        <div class="cx-dialog-item">
          <p>{{ 'consentManagementDialog.noTermsConditions_line1' | cxTranslate }}</p>
          <p>{{ 'consentManagementDialog.noTermsConditions_line2' | cxTranslate }}</p>
        </div>
      </div>
    </div>

    <div class="cx-dialog-footer modal-footer">
      <button type="button" class="btn btn-secondary" (click)="withdraw()">
        {{ 'consentManagementDialog.labelDecline' | cxTranslate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="cancelWithdraw()">
        {{ 'consentManagementDialog.labelCancel' | cxTranslate }}
      </button>
    </div>

  </div>

</div>
