import { createReducer, on } from '@ngrx/store';

import { CartProductSearchActions } from '../actions';
import { CartProductSearchRequest, CartProductSearchResponse, CartProductSearchResult } from '../../model/cart-administration.model';
import { ErrorType } from '@shared/models/ErrorTypes';

export const CART_PRODUCT_SEARCH_FEATURE_KEY = 'cartProductSearch';

export interface CartProductSearchState {
  request: CartProductSearchRequest;
  loading: boolean;
  response: CartProductSearchResponse;
  error: ErrorType;
}

export const initialState: CartProductSearchState = {
  request: undefined,
  loading: false,
  response: undefined,
  error: null,
};

export const cartProductSearchReducer = createReducer(
  initialState,
  on(CartProductSearchActions.resetCartProductSearch, (state, action) => (
    initialState
  )),
  on(CartProductSearchActions.loadCartProductSearch, (state, action) => ({
    ...state,
    loading: true,
    request: action.request
  })),
  on(CartProductSearchActions.loadCartProductSearchSuccess, (state, action) => {
    const resp: CartProductSearchResponse = {...action.response};
    resp.oemNumber = action.oemNumber;
    return {
      ...state,
      loading: false,
      response: resp
    };
  }),
  on(CartProductSearchActions.loadCartProductSearchFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    response: {results: []}
  })),
);
