<ng-container *ngIf="order$ | async as order">
  <!-- unconsigned entries OR any entries -->
  <ng-container *ngIf="order?.entries">
    <div class="frame">
      <div class="cx-list row">
        <div class="col-12 c-list-items-header">
          <p class="c-list-items-header-text">
            {{ 'orderHistoryCustom.myAccount.orderDetails.orderItems' | cxTranslate }}
          </p>
        </div>
        <div class="cx-list-item col-12">
          <ng-container *ngIf="order.entries.length>0;else noPositions">
            <app-efa-cart-item-list [items]="order.entries"
                                    [readonly]="true"
                                    [isOrderDetails]="true"
                                    [promotionLocation]="promotionLocation"></app-efa-cart-item-list>
          </ng-container>
          <ng-template #noPositions>
            <app-no-display-result-notice
              noticeHeadline="{{ 'orderHistoryCustom.positionList.empty' | cxTranslate }}"></app-no-display-result-notice>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
