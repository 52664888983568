import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import { ReturnOverviewFilter } from '../../model/return-history.model';
import { getReturnHistoryState, ReturnHistoryState } from '../reducers';
import * as ReturnHistoryUiFeature from '../reducers/return-history-ui.reducer';
import { RETURN_HISTORY_UI_FEATURE_KEY } from '../reducers/return-history-ui.reducer';

export const selectFilterEntity: MemoizedSelector<ReturnHistoryUiFeature.ReturnHistoryUiState, ReturnOverviewFilter>
  = createSelector(
  getReturnHistoryState,
  (state: ReturnHistoryState) => state[RETURN_HISTORY_UI_FEATURE_KEY].selectedFilterEntity
);

export const selectPagination: MemoizedSelector<ReturnHistoryUiFeature.ReturnHistoryUiState, PaginationModel> = createSelector(
  getReturnHistoryState,
  (state: ReturnHistoryState) => state[RETURN_HISTORY_UI_FEATURE_KEY].selectedPagination
);


export const selectSort: MemoizedSelector<ReturnHistoryUiFeature.ReturnHistoryUiState, SortModel> = createSelector(
  getReturnHistoryState,
  (state: ReturnHistoryState) => state[RETURN_HISTORY_UI_FEATURE_KEY].selectedSort
);

export const returningFromDetails: MemoizedSelector<ReturnHistoryUiFeature.ReturnHistoryUiState, boolean>
  = createSelector(
  getReturnHistoryState,
  (state: ReturnHistoryState) => state[RETURN_HISTORY_UI_FEATURE_KEY].returningFromDetails
);