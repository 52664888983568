import { Component } from '@angular/core';
import { ImageLoadingStrategy, MediaComponent, MediaService } from '@spartacus/storefront';

@Component({
  selector: 'app-efa-media',
  templateUrl: './efa-media.component.html'
})
export class EfaMediaComponent extends MediaComponent {

  constructor(protected mediaService: MediaService) {
    super(mediaService);
   }

  /**
   * Indicates whether the browser should lazy load the image.
   */
  get loadingStrategy(): ImageLoadingStrategy | null {
    return ImageLoadingStrategy.LAZY;
  }
}
