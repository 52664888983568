<app-efa-org-card
  *ngIf="model$ | async as model"
  i18nRoot="orgUserGroup.details"
  [cxFocus]="{ refreshFocus: model }"
>
  <section main class="details" cxOrgItemExists>
    <div class="property">
      <label>{{ 'orgUserGroup.name' | cxTranslate }}</label>
      <span class="value">
        {{ model.name }}
      </span>
    </div>

    <div class="property">
      <label>{{ 'orgUserGroup.uid' | cxTranslate }}</label>
      <span class="value">
        {{ model.uid }}
      </span>
    </div>

  </section>

  <section main class="link-list">
    <ng-container *ngIf="model.uid">
      <a
        class="link"
        [routerLink]="{ cxRoute: 'orgUserGroupUsers', params: model } | cxUrl"
        routerLinkActive="is-current"
      >
        {{ 'orgUserGroup.links.user' | cxTranslate }}
      </a>
      <a
        class="link"
        [routerLink]="
          { cxRoute: 'orgUserGroupPermissions', params: model } | cxUrl
        "
        routerLinkActive="is-current"
      >
        {{ 'orgUserGroup.links.permission' | cxTranslate }}
      </a>
    </ng-container>
  </section>
</app-efa-org-card>
