import { Component } from '@angular/core';

import { AuthService, RoutingService, UserConsentService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'app-consent-management-dialog',
  templateUrl: './consent-management-dialog.component.html',
})
export class ConsentManagementDialogComponent {

  constructor(
    private userConsentService: UserConsentService,
    private authService: AuthService,
    private routingService: RoutingService,
    private launchDialogService: LaunchDialogService
  ) {
  }

  declineTermsAndConditions(): void {
    this.authService.logout();
    this.routingService.go({ cxRoute: 'logout' });
    this.launchDialogService.closeDialog(null);
  }

  acceptTermsAndConditions(): void {
    this.userConsentService.giveConsent('002-TERMS_CONDITIONS', 0);
    this.launchDialogService.closeDialog(null);
  }
}
