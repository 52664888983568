import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index-de';
import { en } from './en/index-en';

export const deliveryNotesTranslations = {
  de,
  en
};

// expose all translation chunk mapping for cartAdministration feature
export const deliveryNotesTranslationChunksConfig: TranslationChunksConfig = {
  deliveryNotes: [
    'deliveryNotesCustom',
  ],
};
