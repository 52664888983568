import {TranslationChunksConfig} from '@spartacus/assets';

import { de } from './de/index';
import { en } from './en/index';


export const transportDamageTranslations = {
  de,
  en
};

export const transportDamageTranslationChunksConfig: TranslationChunksConfig = {
  transportDamage: [
    'transportDamage',
  ],
};