import { Component, OnDestroy } from '@angular/core';
import { CartAndCheckoutLoadingService } from '@shared/services/cart-and-checkout-loading.service';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, first, switchMap } from 'rxjs/operators';
import { ProductCategory } from '../../model/cart-administration-enum.model';
import { CartParametersService } from '../../services/cart-parameters.service';
import { FocusConfig, LaunchDialogService } from '@spartacus/storefront';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CART_DELETE = 'CART_DELETE',
  }
}

@Component({
  selector: 'app-cart-delete-dialog',
  templateUrl: './cart-delete-dialog.component.html'
})
export class CartDeleteDialogComponent implements OnDestroy {

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: true,
    focusOnEscape: true,
  };

  private subscription: Subscription = new Subscription();

  constructor(private multiCartService: MultiCartFacade,
              private activeCartService: ActiveCartFacade,
              private userFacade: UserAccountFacade,
              private cartAndCheckoutLoadingService: CartAndCheckoutLoadingService,
              private cartParametersService: CartParametersService,
              private launchDialogService: LaunchDialogService) {
  }

  delete(): void {
    this.subscription.add(
      combineLatest([
        this.userFacade.get(),
        this.activeCartService.getActiveCartId(),
      ]).pipe(
        filter(([user, cartId]) => cartId !== undefined),
        distinctUntilChanged((prev, next) => prev[0].uid === next[0].uid && prev[1] === next[1]),
        switchMap(([user, cartId]) => {
          this.cartAndCheckoutLoadingService.setCartDeleting(true);
          this.multiCartService.deleteCart(cartId, user.uid);
          this.cartParametersService.setCurrentProductCategory(ProductCategory.SPARE_PARTS);
          return combineLatest([of(user), of(cartId), this.multiCartService.isStable(cartId).pipe(filter((stable) => stable), first())]);
        }))
      .subscribe(([user, cartId, stable]) => {
        this.multiCartService.loadCart({cartId, userId: user.uid, extraData: {active: true}});
        this.closeModal('deleted');
      })
    );
  }

  closeModal(res: string): void {
    this.launchDialogService.closeDialog(res);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
