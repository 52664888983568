import { Component, Input, OnInit } from '@angular/core';
import { SemanticPathService } from '@spartacus/core';
import { TileItem } from '../../model/content-tiles.model';

const TOPMOTIVE_ROUTE_NAME = 'topmotiveCatalog';

@Component({
  selector: 'app-efa-tile-cms-item',
  templateUrl: './tile-cms-item.component.html',
})
export class TileCmsItemComponent implements OnInit {
  @Input() tileItem: TileItem;

  constructor(protected semanticPathService: SemanticPathService) {}

  ngOnInit(): void {}

  get target(): string {
    return this.tileItem.openUrlInNewTab === true || String(this.tileItem.openUrlInNewTab) === 'true' ? '_blank': '_self';
  }

  get overridenLocalUrl(): string {
    if (this.tileItem.uid === 'HomepageExternalCatalogsTileItemTopmotive') {
      return this.semanticPathService.get(TOPMOTIVE_ROUTE_NAME);
    } else {
      return undefined;
    }
  }
}
