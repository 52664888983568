import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModules } from '@shared/shared.modules';
import { ConfigModule, I18nModule, OccConfig } from '@spartacus/core';
import { FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { TransportDamageFormularComponent } from './components/transport-damage-formular/transport-damage-formular.component';
import { OccModule } from './occ/occ.module';
import { effects } from './store/effects';
import { reducers, TRANSPORT_DAMAGE_INDEX_FEATURE_KEY } from './store/reducers';
import { transportDamageTranslationChunksConfig, transportDamageTranslations } from './translations/transport-damage.translations';


@NgModule({
  declarations: [
    TransportDamageFormularComponent
  ],
  imports: [
    CommonModule,
    SharedModules,
    OccModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      i18n: {
        resources: transportDamageTranslations,
        chunks: transportDamageTranslationChunksConfig
      }
    }),
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            adviseOfTransportDamage: 'users/${userId}/adviseOfTransportDamage'
          },
        },
      },
    } as OccConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        TransportDamageFormularComponent: {
          component: TransportDamageFormularComponent,
        }
      }
    }),
    StoreModule.forFeature(TRANSPORT_DAMAGE_INDEX_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects)
  ]
})
export class TransportDamageModule { }
