export const externalCatalogs = {
  externalCatalogs: {
    topmotiveConfirmationDialog: {
      buttonClose: 'Cancel',
      buttonConfirm: 'Ok',
      confirmationMessageTitle: 'Disclaimer EFAKAT+',
      line1:
        'When creating data and computerised processes, incorrect information or results cannot be ruled out in every case despite the most careful work. The creation and delivery of data is therefore carried out to the best of our knowledge and subject to the correctness of the source data, e.g. information from the manufacturer. EFA Autoteilewelt GmbH, Cannstatter-Str. 46, 70190 Stuttgart, therefore excludes any liability for incorrect information or results based on errors in the data provided by third parties in the EFAKAT+.',
      line2:
        'The liability of EFA Autoteilewelt GmbH is exluded in the event of use of the EFAKAT+ by the user in breach of contract.',
      line3:
        'Prices are subject to change and non-binding. Errors excepted.',
    },
    topmotiveCatalog: {
      unauthorizedAccessRequestMessage: {
        title: 'Access denied',
        text: 'We are sorry, but you currently do not have access to this page. Please contact your account administrator to request the necessary permissions.',
      }
    }
  },
};
