import {Component} from '@angular/core';
import {ParagraphComponent} from '@spartacus/storefront';

@Component({
  selector: 'app-efa-paragraph',
  templateUrl: './efa-paragraph.component.html'
})

export class EfaParagraphComponent extends ParagraphComponent {
}
