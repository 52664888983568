import { Injectable } from "@angular/core";
import * as PartsLinkAccessFeature from '../store/reducers/parts-link-access.reducer'
import * as PartsLinkAccessActions from '../store/actions/parts-link-access.action'
import * as PartsLinkAccessSelectors from '../store/selectors/parts-link-access.selector'
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { PartsLinkAccessRequest } from "../model/parts-links-access.model";

@Injectable({
  providedIn: 'root'
})

export class PartsLinkAccessService {

  constructor (protected store: Store<PartsLinkAccessFeature.PartsLinkAccessState>){

  }
  startTiresWarranty(request: PartsLinkAccessRequest):void {
    this.store.dispatch(PartsLinkAccessActions.startPartsLinkAccess({request}))
  }
  isProcessing(): Observable<boolean> {
    return this.store.select(PartsLinkAccessSelectors.selectProcessing)
  }
  sucess():Observable<boolean>{
    return this.store.select(PartsLinkAccessSelectors.selectSuccess)
  }
}
