import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShippingBacklogCancellationRequest } from '../../model/shipping-backlog.model';
import { ShippingBacklogCancellationAdapter } from '../adapters/shipping-backlog-cancellation.adapter';

@Injectable({
  providedIn: 'root',
})
export class ShippingBacklogCancellationConnector {
  constructor(protected adapter: ShippingBacklogCancellationAdapter) {}

  sendBacklogCancellationRequest(request: ShippingBacklogCancellationRequest): Observable<void> {
    return this.adapter.sendCancellationRequest(request);
  }
}
