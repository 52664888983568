import { Injectable } from '@angular/core';

import { GlobalMessageType } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorType } from '@shared/models/ErrorTypes';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { RegistrationData } from '../../model/login-register.model';
import { RegistrationLoadConnector } from '../../occ/connectors/registration-load.connector';
import { RegistrationLoadActions } from '../actions';

@Injectable()
export class RegistrationLoadEffect {
  loadRegistrationStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegistrationLoadActions.loadRegistrationData),
      concatMap((action) =>
        this.connector.loadRegistrationData(action.processId).pipe(
            map((registrationDataResponse: RegistrationData) => {
                return RegistrationLoadActions.loadRegistrationDataSuccess({
                  response: registrationDataResponse
            })
          }),
          catchError((error: ErrorType) => {
            return of(RegistrationLoadActions.loadRegistrationDataFailure({ errorResponse: error }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: RegistrationLoadConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {}
}
