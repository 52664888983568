import { createAction, props } from '@ngrx/store';

import { DeliveryNoteDetailsResponse } from '../../model/delivery-notes.model';
import { ErrorType } from '@shared/models/ErrorTypes';

export const loadDeliveryNoteDetails = createAction(
  '[DeliveryNotes] Load Delivery Note Details',
  props<{ deliveryNoteNumber: string, returnablePositions: boolean, download: boolean }>()
);

export const loadDeliveryNoteDetailsSuccess = createAction(
  '[DeliveryNotes] Load Delivery Note Details Success',
  props<{ response: DeliveryNoteDetailsResponse }>()
);

export const loadDeliveryNoteDetailsFailure = createAction(
  '[DeliveryNotes] Load Delivery Note Details Failure',
  props<{ errorResponse: ErrorType }>()
);

export const resetDeliveryNoteDetails = createAction(
  '[DeliveryNotes] Reset Delivery Note Details'
);
