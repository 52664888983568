export const importExport = {
  importExport: {
    cart: {
      export: 'Export cart',
      import: 'Import cart (CSV)',
      importMicrocat: 'Import cart (Microcat)'
    },
    cartImportDialog:
      {
        header: 'Cart import',
        manufacturerCodes: {
          label: 'Brands',
          placeholder: ''
        },
        importFile: {
          label: 'Import file',
          userManual: {
            csv: {
              line1: {
                preFileExtension: 'Please load a file in',
                fileExtension: 'CSV Format (UTF-8)',
                postFileExtension: 'with the following structure',
              },
              line2: 'Header line(Line 1): oemNumber;quantity;consignmentInfo',
              line3: 'One line per material (values are separated by semicolon)',
              line4: 'Values: article number, order amount and consignment text',
              line5: 'Article number and order amount are mandatory fields.',
              line6: 'Samples:',
              line7: '1743830;5',
              line8: '1743830;5;internal order info',
              line9: 'Note:',
              line10: 'To create a template, please export an existing shopping cart.',
              line11: 'If you save the file from Excel, please choose the format CSV UTF-8.',
            },
            microcat: {
              line1: {
                preFileExtension: 'Please load a file in',
                fileExtension: 'Microcat Format',
                postFileExtension: '.',
              },
              line2: 'Note:',
              line3: 'This import includes articles of brands Toyota and Ford only.',
            }
          }
        },
        buttonSearch: 'Execute',
        selectFile: 'File select'
      },
    cartImportSelectionDialog: {
      buttonImport: 'Import',
      buttonClose: 'Close',
      selectDeselectAll: 'select / deselect all',
      articleId: 'ID {{oemNumber}}',
      article: 'Article',
      brand: 'Brand',
      quantity: 'Quantity',
      price: 'Price',
      header: 'Cart import: Article selection',
      importError: 'Error when importing articles. These articles can be selected and imported again.',
      lineHeader: 'Line {{index}}: Article: {{oemNumber}}, Order amount: {{orderAmount}}, Consignment Information: {{consignmentInfo}}',
      lineHeaderError: {
        reason_articleNotPurchasable: 'Line {{index}}: Article {{oemNumber}}: The article you are searching for is not purchasable any more.',
        reason_articleNotFound: 'Line {{index}}: Article {{oemNumber}}: Article not found.',
        reason_articleCannotBeDelivered: 'Line {{index}}: Article {{oemNumber}}: Article cannot be delivered anymore.',
        reason_articleNotApprovedForSale: 'Line {{index}}: Article {{oemNumber}}: Article is currently not approved for sale.'
      },
      duplicateResultsAvailable: 'The import file contains article numbers which are not unique (highlighted yellow). Please select at least one of the found articles to be able to start the import.'
    },
    importFile: {
      invalidLine: 'Line {{ind}} is invalid: {{line}}',
      invalidHeader: 'The CSV format is invalid. The following columns are necessary: oemNumber, quantity, consignmentInfo',
      empty: 'The file doesn\'t contain any valid lines to import.'
    },
    csvHeaders: {
      oemNumber: 'oemNumber',
      quantity: 'quantity',
      consignmentInfo: 'consignmentInfo'
    },
    cartExportDialog: {
      header: 'Cart export',
      filename: {
        placeholder: 'filename',
      },
      infoText: 'Please specify a file name for your cart export',
      buttonClose: 'Close',
      buttonExport: 'Export',
      defaultFileName: 'cart-export'
    }
  }
};
