import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { SLASH, SLASH_ENCODED_URL_MARKER } from '@shared/models/shared.model';
import { SemanticPathService } from '@spartacus/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EfaProductUrlEncodingGuard {
  constructor(
    private router: Router,
    private semanticPathService: SemanticPathService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    const routeProductCode: string = route.params.productCode;
    const originalEncodedURIProductCode: string = encodeURIComponent(
      routeProductCode
    );
    const slashEncodedProductCode: string = routeProductCode.replace(
      SLASH,
      SLASH_ENCODED_URL_MARKER
    );

    if (
      routeProductCode.includes(SLASH) &&
      originalEncodedURIProductCode !== slashEncodedProductCode &&
      state.url.includes(originalEncodedURIProductCode)
    ) {
      return of(
        this.router.createUrlTree(
          this.semanticPathService.transform({
            cxRoute: 'product',
            params: {
              ...route.params,
              code: slashEncodedProductCode,
            },
          }),
          {
            queryParams: route.queryParams,
          }
        )
      );
    }
    return of(true);
  }
}
