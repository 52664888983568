import { Config } from '@spartacus/core';

const PDF_FILE_EXTENSION = '.pdf';

export abstract class FileExtensionConfig {
    fileExtension?: {
      registrationForm: string[],
      warrantyProcessForm: string[],
      tiresWarrantyForm:string[]
    };
  }

export const defaultFileExtensionConfig: FileExtensionConfig = {
    fileExtension: {
      registrationForm: [PDF_FILE_EXTENSION],
      warrantyProcessForm: [PDF_FILE_EXTENSION],
      tiresWarrantyForm: [PDF_FILE_EXTENSION]
    },
  };

declare module '@spartacus/core' {
    interface Config extends FileExtensionConfig {}
  }
