import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ShippingBacklogCancellationRequest } from '../../../model/shipping-backlog.model';
import { ShippingBacklogCancellationAdapter } from '../shipping-backlog-cancellation.adapter';

@Injectable({
  providedIn: 'root',
})
export class OccShippingBacklogCancellationAdapter
  implements ShippingBacklogCancellationAdapter
{
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  sendCancellationRequest(
    request: ShippingBacklogCancellationRequest
  ): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl('shippingBacklogCancellation', {
      urlParams: { userId: 'current' },
    });

    return this.http.post<void>(url, request, { headers });
  }
}
