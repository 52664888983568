import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {UpdateProfileComponent, UpdateProfileComponentService} from '@spartacus/user/profile/components';
import {GlobalMessageService, GlobalMessageType, Title} from '@spartacus/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-efa-update-profile',
  templateUrl: './efa-update-profile.component.html'
})
export class EfaUpdateProfileComponent extends UpdateProfileComponent implements OnInit, OnDestroy{

  private subscription: Subscription = new Subscription();

  constructor(protected service: UpdateProfileComponentService, protected globalMessageService: GlobalMessageService) {
    super(service);
  }

  ngOnInit(): void {
    this.form.addControl('locName', new UntypedFormControl(''));
    this.form.addControl('b2bUnitId', new UntypedFormControl(''));
    // order the titles
    this.subscription.add(
      this.titles$.subscribe(titles=>{
        this.sortTitles(titles);
      })
    );

    this.globalMessageService.add({
        key: 'efaPersonalDetails.infoMsg', params: {href: 'mailTo:kundencenter@autoteilewelt.de', anchorText: 'kundencenter@autoteilewelt.de', hideCloseButton: true, styleClass: 'staticInfoMessage'}
      },
      GlobalMessageType.MSG_TYPE_WARNING
    );
  }

  sortTitles(titles: Title[]): void {
    titles.sort((title1,title2) => {
      return +title1.code - +title2.code
    });
    [titles[0],titles[1]]=[titles[1],titles[0]];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
