import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { RegistrationData } from '../model/login-register.model';
import { RegistrationLoadActions } from '../store/actions';
import * as RegistrationLoadFeature from '../store/reducers/registration-load.reducer';
import * as RegistrationLoadSelectors from '../store/selectors/registration-load.selectors';

@Injectable({ providedIn: 'root' })
export class RegistrationLoadService {

  constructor(protected store: Store<RegistrationLoadFeature.RegistrationLoadState>) {}

  loadRegistrationData(processId: string): void {
    return this.store.dispatch(RegistrationLoadActions.loadRegistrationData({ processId }));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(RegistrationLoadSelectors.selectLoading);
  }

  success(): Observable<boolean> {
    return this.store.select(RegistrationLoadSelectors.selectSuccess);
  }

  getRegistrationDataResponse(): Observable<RegistrationData> {
    return this.store.select(RegistrationLoadSelectors.selectResponse);
  }
}
