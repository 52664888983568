<!--If component data exists-->
<div *ngIf="downloadsComponentData$ | async as data" class="container-fluid">
  <!--if download item exists-->
  <ng-container *ngIf="downloadItems">
    <div class="downloads-container {{ data.styleClasses ? data.styleClasses : ''}}" #tilesContainer>
      <!--Downloads title-->
      <div class="col-xs-12" *ngIf="data.config?.name">
        <h3 class="downloads-title">
          <span>{{ data.config.name }}</span>
        </h3>
      </div>
      <div class="row">
        <!--show all the download items-->
        <ng-container
          *ngIf="
            downloadItems.length <= data.numberPerRow;
              else showAllContent
            "
        >
          <!--download items loop-->
          <ng-container *ngFor="let item of downloadItems">
            <div
              class="d-flex col-xs-6 col-sm-6 col-md-{{ columnsPerRow }} col-lg-{{ columnsPerRow }}">
              <!--download item-->
              <app-download-item [downloadItem]="item"
                                 [defaultThumbnail]="data.config?.defaultThumbnail"></app-download-item>
            </div>
          </ng-container>
        </ng-container>

        <!--show less download items-->
        <ng-template #showAllContent>
          <!--download items loop-->
          <ng-container *ngFor="let item of downloadItems; index as i">
            <div
              class="col-xs-6 col-sm-6 col-md-{{ columnsPerRow }} col-lg-{{ columnsPerRow }}"
            >
              <!--download item-->
              <app-download-item [downloadItem]="item"
                                 [defaultThumbnail]="data.config?.defaultThumbnail"
                                 [styleClasses]="data.styleClasses"></app-download-item>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <hr *ngIf="data.displayBottomLine === 'true'">
</div>
