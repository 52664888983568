<app-delivery-notes-filter
  (deliveryNotesOverviewFilterChanges)="setDeliveryNoteOverviewFilter($event)"></app-delivery-notes-filter>
<ng-container *ngIf="{
    isLoading: isLoading$ | async,
    deliveryNotes: deliveryNotes$ | async,
    isDownloading: isDownloading$ | async
  } as type">
  <ng-container *ngIf="type.deliveryNotes && !type.isLoading && !type.isDownloading; else loading">
    <!-- BODY -->
    <div class="cx-order-history-body">
      <ng-container *ngIf="type.deliveryNotes.pagination.totalResults > 0; else noResult">
        <!-- Select Form and Pagination Top -->
        <div class="cx-order-history-sort top row">
          <div class="cx-order-history-form-group form-group col-sm-12 col-md-4 col-lg-4">
            <cx-sorting
              [sortOptions]="type.deliveryNotes.sorts"
              [sortLabels]="getSortLabels() | async"
              (sortListEvent)="changeSortCode($event)"
              [selectedOption]="type.deliveryNotes.pagination.sort"
              placeholder="{{ 'deliveryNotesCustom.myAccount.sorting' | cxTranslate }}"></cx-sorting>
          </div>
          <div *ngIf="type.deliveryNotes.pagination.totalPages > 1"
               class="cx-order-history-pagination col-sm-12 col-md-4 col-lg-4">
            <cx-pagination [pagination]="type.deliveryNotes.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
        <div class="row mb-2">
          <div class="d-none col-md-6">
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" (change)="onAllSelectedChange(type.deliveryNotes.deliveryNotes)"
                      [checked]="isAllDeliveryNotesSelected"/>
                {{ 'deliveryNotesCustom.myAccount.selectAll' | cxTranslate}}
              </label>
            </div>
          </div>
          <div class="d-none offset-md-2 col-md-4">
            <button class="btn btn-block btn-primary" (click)="onDownloadMultipleDocuments()" [disabled]="selectedDeliveryNoteDocumentNumbers.length == 0">
              {{ 'tooltip.download' | cxTranslate }}
            </button>
          </div>
        </div>
        <!-- Download Button Box -->
        <div class="row download-box">
          <div class="d-none d-lg-block offset-lg-9 pl-2  col-md-3">
            <button class="btn btn-link cx-action-link text-decoration-none mb-0"
            (click)="onDownloadMultipleDocuments()"
            [disabled]="selectedDeliveryNoteDocumentNumbers.length == 0">
              <cx-icon [type]="iconTypes.DOWNLOAD" class="mr-0 o-download-file__icon-table-header"></cx-icon>
              <span class="u-font-bold">{{ 'tooltip.download' | cxTranslate }}</span>
            </button>
          </div>
        </div>
        <!-- TABLE -->
        <table class="table cx-order-history-table">
          <thead class="cx-order-history-thead-mobile">
            <th scope="col" class="align-middle">{{ 'deliveryNotesCustom.myAccount.deliveryNoteNumber' | cxTranslate }}</th>
            <th scope="col" class="align-middle">{{ 'deliveryNotesCustom.myAccount.warehouseName' | cxTranslate }}</th>
            <th scope="col" class="align-middle">{{ 'deliveryNotesCustom.myAccount.creationDate' | cxTranslate }}</th>
            <th scope="col" class="align-middle d-flex">
              <div style="font-weight: 400;"  class="form-check mb-1 o-check-all_table-header">
                <input class="form-check-input mt-0" type="checkbox" (change)="onAllSelectedChange(type.deliveryNotes.deliveryNotes)"
                       [checked]="isAllDeliveryNotesSelected"/>
                       {{ 'deliveryNotesCustom.myAccount.selectAll' | cxTranslate}}
              </div>

            </th>
          </thead>
          <tbody>
          <tr *ngFor="let deliveryNote of type.deliveryNotes.deliveryNotes">
            <td attr.data-label="{{ 'deliveryNotesCustom.myAccount.deliveryNoteNumber' | cxTranslate }}"
                class="cx-order-history-number">
              <a [routerLink]="{
                  cxRoute: 'deliveryNoteDetails',
                  params: deliveryNote
                } | cxUrl">{{ deliveryNote?.deliveryNoteNumber }}</a>
            </td>
            <td attr.data-label="{{ 'deliveryNotesCustom.myAccount.warehouseName' | cxTranslate }}"
                class="cx-order-history-type-name">
              {{ deliveryNote?.warehouseName }}
            </td>
            <td attr.data-label="{{ 'deliveryNotesCustom.myAccount.creationDate' | cxTranslate }}"
                class="cx-order-history-date">
              {{ deliveryNote?.creationDate | date: 'dd.MM.yyyy'}}
            </td>
            <td attr.data-label="{{ 'deliveryNotesCustom.myAccount.download' | cxTranslate }}">
              <div class="form-check d-none d-lg-block">
                <input class="form-check-input mt-0" type="checkbox" (change)="onDocumentSelectedChange(deliveryNote.deliveryNoteNumber)"
                [checked]="isDocumentSelected(deliveryNote.deliveryNoteNumber)"/>
              </div>
              <button style="color: #7E8490; height: auto;" class="btn btn-link cx-action-link text-decoration-none mb-0 p-0 d-lg-none"
              (click)="onDowloadSingleDocument(deliveryNote.deliveryNoteNumber)">
                 <cx-icon [type]="iconTypes.DOWNLOAD" class="mr-2 o-download-file__icon-table-header"></cx-icon>
               </button>
            </td>
          </tbody>
        </table>
        <!-- Select Form and Pagination Bottom -->
        <div class="cx-order-history-sort bottom row">
          <div *ngIf="type.deliveryNotes.pagination.totalPages > 1"
               class="cx-order-history-pagination">
            <cx-pagination [pagination]="type.deliveryNotes.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
      </ng-container>
      <!-- NO RESULT CONTAINER -->
      <ng-template #noResult>
        <app-no-display-result-notice noticeHeadline="{{ 'filteredDocumentsResult.noResult' | cxTranslate }}"></app-no-display-result-notice>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #loading>
    <div *ngIf="type.isLoading || type.isDownloading" class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</ng-container>
