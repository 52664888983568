<div class="cx-modal-container modal-dialog-centered">
  <div class="cx-modal-content">
<div class="modal-header">
  <label class="header-actions">
    <div class="form-check mb-0">
      <input class="form-check-input" type="checkbox" (change)="isAllSelected($event)" [checked]="isAllManufacturerSelected"/>
      <span
        class="header-actions-text">{{ 'cartAdministration.modalDialog.selectDeselectAll' | cxTranslate }}</span>
    </div>
  </label>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <cx-icon [type]="iconType.CLOSE_LIGHT"></cx-icon>
  </button>
</div>
<div class="modal-body">
  <div class="outer-container">
    <div class="row manufactures-box" *ngIf="currentLanguage$ | async as currentLanguage">
      <div class="col-12  manufactures-group-box">
        <div class="row d-flex justify-space-around">
            <div class="col-{{ 12 / visibleManufacturerGroups.length }} manufacturer-group-tab" *ngFor="let manufacturerGroup of visibleManufacturerGroups | orderBy: 'code'">
                <btn type="button" class="btn btn-primary btn-block btn-tab" [ngClass]="{ 'btn-tab-active' : manufacturerGroup.code === selectedManufacturerGroup.code }" (click)="setSelectedManufacturerGroup(manufacturerGroup)">
                    {{ manufacturerGroup.name | localizeTo : currentLanguage }}
                </btn>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
    <ng-container *ngFor="let item of finalVisibleManufacturers | orderBy:'name'">
      <div class="col-12 col-md-6" *ngIf="item.manufacturerGroup?.code === selectedManufacturerGroup.code">
        <label>
          <div class="form-check check-item">
            <input class="form-check-input mr-2" type="checkbox" [(ngModel)]="item.isChecked" [value]="item.id"
                 (change)="changeSelection($event)"/>
                 <span class="check-item-text">{{item.name}}</span>
          </div>
        </label>
      </div>
    </ng-container>
    </div>
  </div>
</div>

    <div class="modal-footer">
      <button class="btn btn-primary btn-select-all" (click)="submitData()" type="submit">
        {{ 'cartAdministration.modalDialog.modalDialogBtn' | cxTranslate }}
      </button>
    </div>

  </div>
</div>
