import {
  ApplicationRef,
  ComponentRef,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { SemanticPathService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable, combineLatest, iif, of } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { CONFIGURATOR_CART_CONFIRMATION } from 'src/app/custom/config/default-configurator-cart-confirmation-layout.config';
import { TOPMOTIVE_CONFIRMATION } from 'src/app/custom/config/default-topmotive-confirmation-layout.config';
import { ConfiguratorCartConfirmationMessageComponent } from '../../cart-administration/components/configurator-cart-confirmation-message/configurator-cart-confirmation-message.component';
import { ConfiguratorCartConfirmationLocalStorageService } from '../../cart-administration/services/configurator-cart-confirmation-local-storage.service';

const CART_ROUTE_NAME = 'cart';

@Injectable({
  providedIn: 'root',
})
export class TopmotiveCatalogGuard {
  constructor(
    private applicationRef: ApplicationRef,
    private activeCartService: ActiveCartFacade,
    private router: Router,
    private semanticPathService: SemanticPathService,
    private launchDialogService: LaunchDialogService,
    private configuratorCartConfirmationLocalStorageService: ConfiguratorCartConfirmationLocalStorageService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const configuratorCart$: Observable<boolean | UrlTree> =
      this.configuratorCartConfirmationLocalStorageService
        .getConfiguratorCartConfirmedTopmotive()
        .pipe(
          first(),
          switchMap((isConfirmed: boolean) => {
            if (isConfirmed) {
              return of(
                this.router.parseUrl(
                  this.semanticPathService.get(CART_ROUTE_NAME)
                )
              );
            }

            return this.myOpenConfiguratorModal();
          })
        );

    let disclaimerClose$: Observable<boolean> = of(false);
    if (!this.router.navigated) {
      disclaimerClose$ = of(true);
    } else {
      const disclaimer$ = this.launchDialogService.openDialog(
        TOPMOTIVE_CONFIRMATION
      );

      if (disclaimer$) {
        disclaimer$.pipe(take(1)).subscribe();
        disclaimerClose$ = this.launchDialogService.dialogClose.pipe(
          filter(Boolean),
          map((status: string) => status === 'confirmed')
        );
      }
    }

    return this.activeCartService.getActive().pipe(
      tap((cart: Cart) => {
        this.configuratorCartConfirmationLocalStorageService.initSyncTopmotive(
          cart.code
        );
        this.configuratorCartConfirmationLocalStorageService.initSync(
          cart.code
        );
      }),
      mergeMap((cart: Cart) =>
        iif(() => cart.fromConfigurator, configuratorCart$, disclaimerClose$)
      )
    );
  }

  private myOpenConfiguratorModal(): Observable<boolean | UrlTree> {
    const componentRef$: Observable<ComponentRef<any>> | void =
      this.launchDialogService.launch(
        CONFIGURATOR_CART_CONFIRMATION,
        this.applicationRef.components[0].injector.get(ViewContainerRef)
      );

    if (componentRef$) {
      return combineLatest([
        componentRef$,
        this.launchDialogService.dialogClose,
      ]).pipe(
        tap(([componentRef]) => {
          const componentInstance: ConfiguratorCartConfirmationMessageComponent =
            (
              componentRef as ComponentRef<ConfiguratorCartConfirmationMessageComponent>
            ).instance;
          componentInstance.isCalledFromTopmotive = true;
        }),
        filter(
          ([componentRef, dialogClose]) =>
            componentRef != null && dialogClose != null
        ),
        first(),
        map(
          ([componentRef, dialogClose]: [
            ComponentRef<ConfiguratorCartConfirmationMessageComponent>,
            string
          ]) => {
            if (dialogClose) {
              this.launchDialogService.clear(CONFIGURATOR_CART_CONFIRMATION);
              if (componentRef) {
                componentRef.destroy();
              }

              if (dialogClose === 'success') {
                return this.router.parseUrl(
                  this.semanticPathService.get(CART_ROUTE_NAME)
                );
              } else {
                return false;
              }
            }
          }
        )
      );
    }
  }
}
