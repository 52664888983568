import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';

import {
  Address,
  CmsOrderDetailOverviewComponent,
  Config,
  Country,
  TranslationService,
  UserAddressService
} from '@spartacus/core';
import { Card, CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { DeliveryMode } from '@spartacus/cart/base/root';
import { CheckoutDeliveryAddressFacade, CheckoutQueryFacade } from '@spartacus/checkout/base/root';
import { OrderDetailsService, OrderOverviewComponent } from '@spartacus/order/components';
import { Order } from '@spartacus/order/root';
import { OrderInfoFieldValue, OrderType } from '../../../cart-administration/model/cart-administration.model';
import { PlaceOrderComponentData } from '../../model/checkout-order.model';


@Component({
  selector: 'app-efa-order-overview',
  templateUrl: './efa-order-overview.component.html',
})
export class EfaOrderOverviewComponent extends OrderOverviewComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  deliveryAddress: Address;
  orderType: OrderType;
  requestedDeliveryDate: string;
  deliveryMode: DeliveryMode;
  orderInfoFields: OrderInfoFieldValue[];
  orderOverview: Order;
  deactivatePlaceOrder: boolean;
  @Input() readonly: boolean;

  readonly displayRequestedDeliveryDate: boolean = this.config.displayRequestedDeliveryDate;

  constructor(
    protected translation: TranslationService,
    protected orderDetailsService: OrderDetailsService,
    protected component: CmsComponentData<CmsOrderDetailOverviewComponent>,
    protected userAddressService: UserAddressService,
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    @Inject(Config) protected config: any,
  ) {
    super(translation, orderDetailsService, component);
  }

  ngOnInit(): void {
    this.setDeactivatePlaceOrder();
    this.setOrderReviewData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setOrderReviewData(): void {
    this.subscription.add(
      this.orderDetailsService.getOrderDetails().subscribe((order: Order) => {
        this.orderOverview = order;
        this.deliveryAddress = order.deliveryAddress;
        this.orderType = order.orderType;
        this.requestedDeliveryDate = order.requestedDeliveryDate;
        this.deliveryMode = order.deliveryMode;
        this.orderInfoFields = order.orderInfoFields;
      })
    );
  }

  get deliveryAddress$(): Observable<Address> {
    return this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
      filter((state) => !state.loading && !state.error),
      map((state) => state.data)
    );
  }

  getShippingAddressCard(
    deliveryAddress: Address,
    countryName: string
  ): Observable<Card> {
    return combineLatest([
      this.translation.translate('addressCard.shipTo'),
    ]).pipe(
      map(([textTitle]) => {
        if (!countryName) {
          countryName = deliveryAddress?.country?.isocode;
        }

        let region = '';
        if (
          deliveryAddress &&
          deliveryAddress.region &&
          deliveryAddress.region.isocode
        ) {
          region = deliveryAddress.region.isocode + ', ';
        }

        return {
          title: textTitle,
          textBold: deliveryAddress.companyName,
          text: [
            deliveryAddress.line1,
            deliveryAddress.postalCode + ' ' + deliveryAddress.town + ', ' + region + countryName,
          ],
        };
      })
    );
  }

  getDeliveryModeCard(deliveryMode: DeliveryMode): Observable<Card> {
    return combineLatest([
      this.translation.translate('checkoutOrder.checkoutShipping.shippingMethod'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: deliveryMode.name,
          text: [
            deliveryMode.description,
          ],
        };
      })
    );
  }

  getOrderType(orderType: OrderType): Observable<Card> {
    return combineLatest([
      this.translation.translate('checkoutOrder.checkoutReview.orderType'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: orderType.name,
        };
      })
    );
  }

  getRequestedDeliveryDate(requestedDeliveryDate: string): Observable<Card> {
    return combineLatest([
      this.translation.translate('checkoutOrder.checkoutReview.requestedDeliveryDate'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: formatDate(requestedDeliveryDate, 'dd.MM.yyyy', 'en-US'),
        };
      })
    );
  }

  get countryName$(): Observable<string> {
    return this.deliveryAddress$.pipe(
      switchMap((address: Address) =>
        this.userAddressService.getCountry(address?.country?.isocode)
      ),
      tap((country: Country) => {
        if (country === null) {
          this.userAddressService.loadDeliveryCountries();
        }
      }),
      map((country: Country) => country && country.name)
    );
  }

  private setDeactivatePlaceOrder(): void {
    this.subscription.add(this.component.data$.pipe(tap((data: PlaceOrderComponentData) => {
      this.deactivatePlaceOrder = data.deactivatePlaceOrder === true || String(data.deactivatePlaceOrder) === 'true';
    })).subscribe());
  }
}
