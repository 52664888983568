import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index';
import { en } from './en/index';

export const shippingBacklogTranslations = {
  de,
  en
};

export const shippingBacklogTranslationChunksConfig: TranslationChunksConfig = {
  shippingBacklog: [
    'shippingBacklog',
  ],
};