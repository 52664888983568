import {Injectable} from '@angular/core';
import {ForgotPasswordComponentService} from '@spartacus/user/profile/components';
import {UserPasswordFacade} from '@spartacus/user/profile/root';
import {
  AuthConfigService,
  GlobalMessageService,
  GlobalMessageType,
  OAuthFlow,
  RoutingService
} from '@spartacus/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {padStart} from '../../login-register/utils/login-register.utils';

@Injectable({
  providedIn: 'root'
})
export class EfaForgotPasswordComponentService extends ForgotPasswordComponentService {
  constructor(userPasswordService: UserPasswordFacade, routingService: RoutingService, authConfigService: AuthConfigService, globalMessage: GlobalMessageService) {
    super(userPasswordService, routingService, authConfigService, globalMessage);
    this.form.addControl('partnerId', new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(10)]));
  }

  override requestEmail(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    this.userPasswordService
    .requestForgotPasswordEmail(padStart(this.form.value.partnerId) + '_' + this.form.value.userEmail)
    .subscribe({
      next: () => this.onSuccess(),
      error: (error: Error) => this.onError(error),
    });
  }

  protected override redirect(): void {
    if (
      this.authConfigService.getOAuthFlow() ===
      OAuthFlow.ResourceOwnerPasswordFlow
    ) {
      this.routingService.go({cxRoute: 'login'}, {
        state: {
          messageTypesForRemoval: [GlobalMessageType.MSG_TYPE_WARNING,
            GlobalMessageType.MSG_TYPE_ERROR,
            GlobalMessageType.MSG_TYPE_INFO]
        }
      });
    }
  }
}
