import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ROUTER_CONFIGURATION } from '@angular/router';
import {
  ConfigModule,
  GlobalMessageType,
  provideConfig,
} from '@spartacus/core';
import { defaultDateFilterConfig } from './date-filter.config';
import { defaultAddToCartLayoutConfig } from './default-add-to-cart-layout.config';
import { defaultCartSpinnerLayoutConfig } from './default-cart-spinner-layout.config';
import { defaultConfiguratorCartConfirmationLayoutConfig } from './default-configurator-cart-confirmation-layout.config';
import { defaultTopmotiveConfirmationLayoutConfig } from './default-topmotive-confirmation-layout.config';
import { defaultFileExtensionConfig } from './file-extension.config';
import { defaultFileSizeConfig } from './file-size.config';
import { defaultFunctionsConfig } from './functions.config';
import { headerLayoutConfig } from './header-layout.config';
import { defaultPaginationConfig } from './pagination.config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      anonymousConsents: {
        requiredConsents: ['001-MANDATORY'],
        showLegalDescriptionInDialog: false,
      },
      view: {
        infiniteScroll: {
          active: true,
          productLimit: 500,
          showMoreButton: false,
        },
      },
      routing: {
        protected: true,
      },
      globalMessages: {
        [GlobalMessageType.MSG_TYPE_CONFIRMATION]: {
          timeout: 3600000,
        },
        [GlobalMessageType.MSG_TYPE_INFO]: {
          timeout: 3600000,
        },
        [GlobalMessageType.MSG_TYPE_ERROR]: {
          timeout: 3600000,
        },
        [GlobalMessageType.MSG_TYPE_WARNING]: {
          timeout: 3600000,
        },
      },
    }),
    ConfigModule.withConfig(defaultFileSizeConfig),
    ConfigModule.withConfig(defaultPaginationConfig),
    ConfigModule.withConfig(defaultFileExtensionConfig),
    ConfigModule.withConfig(defaultDateFilterConfig),
  ],
  providers: [
    {
      provide: ROUTER_CONFIGURATION,
      useValue: {
        scrollPositionRestoration: 'enabled',
      },
    },
    provideConfig(defaultCartSpinnerLayoutConfig),
    provideConfig(headerLayoutConfig),
    provideConfig(defaultConfiguratorCartConfirmationLayoutConfig),
    provideConfig(defaultAddToCartLayoutConfig),
    provideConfig(defaultTopmotiveConfirmationLayoutConfig),
    provideConfig(defaultFunctionsConfig),
  ],
})
export class CustomConfigurationModule {}
