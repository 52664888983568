import { createSelector, MemoizedSelector } from '@ngrx/store';
import { FaqItem } from '../../model/faq-items.model';
import { FaqItemsState, getFaqItemsState } from '../reducers';
import * as FaqItemFeature from '../reducers/faq-item.reducer';
import { FAQ_ITEM_FEATURE_KEY } from '../reducers/faq-item.reducer';


export const selectLoading: MemoizedSelector<FaqItemFeature.FaqItemState, boolean>
  = createSelector(
  getFaqItemsState,
  (state: FaqItemsState) => state[FAQ_ITEM_FEATURE_KEY].loading
);

export const selectResponse: MemoizedSelector<FaqItemFeature.FaqItemState, FaqItem>
  = createSelector(
  getFaqItemsState,
  (state: FaqItemsState) => state[FAQ_ITEM_FEATURE_KEY].response
);
