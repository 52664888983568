export const efaAddToCart = {
  eAddToCart: {
    showQuantityHint: 'Die Menge kann im Warenkorb eingegeben werden.',
    showQuantityHintForConfiguratorEntry: 'Sie befinden sich aktuell im Bestellprozess des Räderkonfigurators. Sobald Sie Ihre Bestellung abgeschlossen haben, können Sie neue Artikel dem Warenkorb hinzufügen.',
    addToCartDialog: {
      title: 'Dem Warenkorb hinzufügen',
      actions: {
        goToCart: 'Zum Warenkorb gehen',
        returnToCurrentPage: 'Weiter einkaufen',
        close: 'Abbrechen',
      }
    }
  }
};
