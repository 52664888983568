import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ListService,UserGroupAssignedUserListComponent, UserGroupAssignedUserListService } from '@spartacus/organization/administration/components';

@Component({
  selector: 'app-efa-user-group-assigned-user-list',
  templateUrl: './efa-user-group-assigned-user-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'content-wrapper' },
  providers: [
    {
      provide: ListService,
      useExisting: UserGroupAssignedUserListService,
    },
  ],
})
export class EfaUserGroupAssignedUserListComponent extends UserGroupAssignedUserListComponent  implements OnInit {

  ngOnInit(): void {
  }

}
