import { Component } from '@angular/core';
import { B2BUser } from '@spartacus/core';
import { BaseItem, ToggleStatusComponent } from '@spartacus/organization/administration/components';
import { ICON_TYPE } from '../../model/user-profiles-icon.model';

@Component({
  selector: 'app-efa-toggle-status',
  templateUrl: './efa-toggle-status.component.html',
  host: { class: 'content-wrapper' },
})
export class EfaToggleStatusComponent<T extends BaseItem> extends ToggleStatusComponent<T> {
  iconTypes = ICON_TYPE;
  protected getPatchedItem(item: T): T {
    const patch: BaseItem = {};
    patch[this.key] = item[this.key];
    patch.orgUnit = item.orgUnit;
    patch['email'] = (item as B2BUser).email;
    patch.active = !item.active;
    patch['portalUser'] = (item as B2BUser).portalUser;
    patch['uid'] = item.uid;
    return patch as T;
  }
}
