import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ReturnDetailsResponse } from '../../model/return-history.model';
import { ReturnDetailsAdapter } from '../adapters/return-details.adapter';

@Injectable({
  providedIn: 'root',
})
export class ReturnDetailsConnector {
  constructor(protected adapter: ReturnDetailsAdapter) {}

  loadDetails(returnNumber: string): Observable<ReturnDetailsResponse> {
    return this.adapter.loadDetails(returnNumber);
  }
}
