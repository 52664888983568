<section class="cx-page-section container"
         *ngIf="!(isLoading$ | async); else loading">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 px-2">
      <div class="cx-section">
        <cx-spinner class="overlay" *ngIf="isSubmittingRegisterdData$ | async"></cx-spinner>
        <form [formGroup]="registrationForm">
          <p>{{"efaRegistrationForm.formMandatoryFields" | cxTranslate }}</p>
          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend">{{'efaRegistrationForm.legend6' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.sepaBank.label' | cxTranslate }}
                  *</span>
                <input type="text"
                       class="form-control"
                       formControlName="sepaBank"
                       placeholder="{{ 'efaRegistrationForm.sepaBank.placeholder' | cxTranslate }}"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.sepaBank"
                                [control]="registrationForm.get('sepaBank')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span class="label-content">{{ 'efaRegistrationForm.sepaIban.label' | cxTranslate }}
                  *</span>
                <input type="text"
                       class="form-control"
                       formControlName="sepaIban"
                       placeholder="{{ 'efaRegistrationForm.sepaIban.placeholder' | cxTranslate }}"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.sepaIban"
                                [control]="registrationForm.get('sepaIban')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.sepaBic.label' | cxTranslate }}</span>
                <input type="text"
                       class="form-control"
                       formControlName="sepaBic"
                       placeholder="{{ 'efaRegistrationForm.sepaBic.placeholder' | cxTranslate }}"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.sepaBic"
                                [control]="registrationForm.get('sepaBic')"></cx-form-errors>
              </label>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.sepaEmailAddress.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.sepaEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="sepaEmail"/>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.sepaEmailAddress"
                                [control]="registrationForm.get('sepaEmail')"></cx-form-errors>
              </label>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend">{{'efaRegistrationForm.legend8' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <div class="form-group mb-2 pb-2">
                <span
                  class="label-content">{{'efaRegistrationForm.deliveryCosts.label' | cxTranslate}}
                  *</span>
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="deliveryCosts"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.deliveryCosts.confirmation' | cxTranslate }}
                  </span>
                </label>

                <cx-form-errors prefix="efaRegistrationForm.formErrors.deliveryCosts"
                                [control]="registrationForm.get('deliveryCosts')"></cx-form-errors>
              </div>

              <div class="form-group mb-2 pb-2">
                <span
                  class="label-content">{{'efaRegistrationForm.deliveryPerNoxNightExpress.label' | cxTranslate}}
                  *</span>
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="deliveryPerNoxNightExpress"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.deliveryPerNoxNightExpress.confirmation' | cxTranslate }}
                  </span>
                </label>

                <cx-form-errors prefix="efaRegistrationForm.formErrors.deliveryPerNoxNightExpress"
                                [control]="registrationForm.get('deliveryPerNoxNightExpress')"></cx-form-errors>
              </div>

              <div class="form-group mb-2 pb-2">
                <span
                  class="label-content">{{ 'efaRegistrationForm.noxDepotAvailable.label' | cxTranslate }}
                  *</span>
                <div class="d-inline-flex flex-row flex-wrap mb-3">
                  <label class="form-check form-check-inline m-0 mr-4">
                    <input type="radio"
                           formControlName="noxDepotAvailable"
                           name="noxDepotAvailable"
                           [value]="true" (change)="noxDepotAvailableChange()"/>
                    {{ 'efaRegistrationForm.noxDepotAvailable.option1' | cxTranslate }}
                  </label>
                  <label class="form-check form-check-inline m-0 mr-4">
                    <input type="radio"
                           formControlName="noxDepotAvailable"
                           name="noxDepotAvailable"
                           [value]="false" (change)="noxDepotAvailableChange()"/>
                    {{ 'efaRegistrationForm.noxDepotAvailable.option2' | cxTranslate }}
                  </label>
                </div>
                <cx-form-errors prefix="efaRegistrationForm.formErrors.noxDepotAvailable"
                                [control]="registrationForm.get('noxDepotAvailable')"></cx-form-errors>
              </div>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.noxCustomerNumber.label' | cxTranslate }}</span>
                <input class="form-control"
                       type="text"
                       placeholder="{{ 'efaRegistrationForm.noxCustomerNumber.placeholder' | cxTranslate }}"
                       formControlName="noxCustomerNumber"/>
                <cx-form-errors
                  [control]="registrationForm.get('noxCustomerNumber')"></cx-form-errors>
              </label>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
