import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CmsComponentAdapter, CmsPageAdapter } from '@spartacus/core';
import { AllowedFileExtensionsAdapter } from './adapters/allowed-file-extensions.adapter';
import { EfaOccCmsComponentAdapter } from './adapters/impl/efa-occ-cms-component.adapter';
import { EfaOccCmsPageAdapter } from './adapters/impl/efa-occ-cms-page.adapter';
import { OccAllowedFileExtensionsAdapter } from './adapters/impl/occ-allowed-file-extensions.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: AllowedFileExtensionsAdapter,
      useClass: OccAllowedFileExtensionsAdapter,
    },
    {
      provide: CmsPageAdapter,
      useClass: EfaOccCmsPageAdapter,
    },
    {
      provide: CmsComponentAdapter,
      useClass: EfaOccCmsComponentAdapter,
    },
  ],
})
export class OccModule {}
