import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ProcessStepSubmitService } from '@shared/services/process-step-submit.service';
import {
  Config,
  LanguageService,
  RoutingService,
  TranslationService,
  UserAddressService
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import {
  ICON_TYPE,
  RegistrationStatus,
} from '../../model/login-register-enum.model';
import {
  RegistrationData,
  RegistrationInformationMultiOptionsFieldsValuesResponse,
  RegistrationStepData,
} from '../../model/login-register.model';
import {
  RegistrationInformationMultiOptionFieldsValuesService
} from '../../services/registration-information-multi-option-fields-values.service';
import {RegistrationLoadService} from '../../services/registration-load.service';
import {RegistrationProcessLocalStorageService} from '../../services/registration-process-local-storage.service';
import {RegistrationStartService} from '../../services/registration-start.services';

@Directive()
export abstract class AbstractRegistrationStepComponent
  implements OnInit, OnDestroy
{
  isLoading$: Subject<boolean> = new BehaviorSubject(false);
  isSubmittingRegisteredData$: Subject<boolean> = new BehaviorSubject(false);
  registrationInformationMultiOptionsFieldsValues: RegistrationInformationMultiOptionsFieldsValuesResponse;

  readonly iconTypes = ICON_TYPE;

  protected subscription: Subscription = new Subscription();
  protected step$: Observable<RegistrationStepData>;

  protected resetForm$: Subject<void> = new ReplaySubject();

  private processId: string;
  private stepIndex: number;
  private registrationResponse: RegistrationData;
  private dataFilled = false;

  constructor(
    protected component: CmsComponentData<RegistrationStepData>,
    protected localStorageService: RegistrationProcessLocalStorageService,
    protected stepSubmitService: ProcessStepSubmitService,
    protected registrationLoadService: RegistrationLoadService,
    protected registrationStartService: RegistrationStartService,
    protected userAddressService: UserAddressService,
    protected fieldOptionsService: RegistrationInformationMultiOptionFieldsValuesService,
    protected translationService: TranslationService,
    protected languageService: LanguageService,
    protected elementRef: ElementRef,
    protected formBuilder: UntypedFormBuilder,
    protected routingService: RoutingService,
    @Inject(DOCUMENT) protected document: any,
    @Inject(Config) protected config: any
  ) {}

  ngOnInit(): void {
    this.step$ = this.component.data$;
    this.localStorageService.initSync();

    this.subscription.add(
      this.fieldOptionsService.isLoading().subscribe((isLoading: boolean) => {
        this.isLoading$.next(isLoading);
      })
    );

    this.subscription.add(
      this.localStorageService.getProcessId().subscribe((processId) => {
        this.processId = processId;
        this.registrationLoadService.loadRegistrationData(processId);
      })
    );

    this.subscription.add(
      combineLatest([
        this.registrationLoadService.getRegistrationDataResponse(),
        this.fieldOptionsService.getResponse().pipe(
          filter(Boolean),
          switchMap(
            (
              multiOptionFieldsResponse: RegistrationInformationMultiOptionsFieldsValuesResponse
            ) => {
              this.setMultiOptionFields(multiOptionFieldsResponse);
              return this.initializeMultiOptionFields();
            }
          )
        ),
      ]).subscribe(([response, optionsLoaded]: [RegistrationData, boolean]) => {
        if (response != null && optionsLoaded && !this.dataFilled) {
          this.registrationResponse = response;
          this.fillFormDataFromResponse(response);
          this.dataFilled = true;
        }
      })
    );

    this.subscription.add(
      this.step$.subscribe((step: RegistrationStepData) => {
        this.stepIndex = +step.stepIndex;
        this.stepSubmitService.setActiveStepIndex(this.stepIndex);
      })
    );

    this.subscription.add(
      this.stepSubmitService
        .onSubmission()
        .subscribe(() => this.onSubmit(this.registrationResponse))
    );

    this.subscription.add(
      this.registrationStartService
        .isSending()
        .subscribe((isProcessing: boolean) => {
          this.isSubmittingRegisteredData$.next(isProcessing);
        })
    );

    this.subscription.add(
      this.languageService.getActive().pipe(distinctUntilChanged()).subscribe(() =>
        this.fieldOptionsService.loadRegistrationInformationMultiOptionFieldsValues())
    );
  }

  protected abstract fillFormDataFromResponse(
    registrationData: RegistrationData
  ): void;

  protected abstract getStatus(): RegistrationStatus;

  protected abstract onSubmit(registrationData: RegistrationData): void;

  protected scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement =
      this.elementRef.nativeElement.querySelector('form .ng-invalid');

    window.scroll({
      top:
        firstInvalidControl.getBoundingClientRect().top + window.scrollY - 40,
      left: 0,
      behavior: 'smooth',
    });
  }

  protected submit(registrationData: RegistrationData): void {
    registrationData = this.addProcessData(registrationData);
    this.registrationStartService.registrationStart(registrationData);
    this.subscription.add(
      this.registrationStartService
        .success()
        .subscribe((isSuccessful: boolean) => {
          if (isSuccessful) {
            this.success();

            if (RegistrationStatus.COMPLETED === this.getStatus()) {
              this.resetForm$.next();
            }
          }
        })
    );
  }

  protected success(): void {
    this.stepSubmitService.success();
  }

  private addProcessData(registrationData: RegistrationData): RegistrationData {
    const status = this.getStatus();
    return {
      ...registrationData,
      status,
      processId: this.processId,
      currentIndex:
        RegistrationStatus.INPROGRESS === status
          ? this.stepIndex + 1
          : this.stepIndex,
    };
  }

  private setMultiOptionFields(
    multiOptionFieldsResponse: RegistrationInformationMultiOptionsFieldsValuesResponse
  ): void {
    this.registrationInformationMultiOptionsFieldsValues =
      multiOptionFieldsResponse;
  }

  protected initializeMultiOptionFields(): Observable<boolean> {
    return of(true);
  }

  getSelectPlaceholderTextClass(value: string): string {
    return value === '' ? 'placeholder-text' : '';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
