import { Injectable, OnDestroy } from '@angular/core';
import { StatePersistenceService, StorageSyncType } from '@spartacus/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class RegistrationProcessLocalStorageService implements OnDestroy {

  protected subscription: Subscription = new Subscription();
  protected processId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  protected readonly key: string = 'registrationProcessId';

  constructor(protected statePersistenceService: StatePersistenceService) {
  }

  initSync(): void {
    this.subscription.add(
      this.statePersistenceService.syncWithStorage({
        key: this.key,
        state$: this.getProcessId(),
        storageType: StorageSyncType.LOCAL_STORAGE,
        onRead: (state: string) => this.onRead(state),
      })
    );
  }

  createProcessId(): string {
    const processId: string = uuid();
    this.processId$.next(processId);
    return processId;
  }

  getProcessId(): Observable<string> {
    return this.processId$.asObservable();
  }

  protected onRead(state: string | undefined): void {
    const processId: string = state;

    if (processId == null) {
      this.createProcessId();
    } else {
      this.processId$.next(processId);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
