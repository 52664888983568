import { createAction, props } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';
import { ReturnOverviewFilter } from '../../model/return-history.model';

export const setReturningFromDetails = createAction(
  '[ReturnHistory] Set returningFromDetails',
  props<{ returningFromDetails: boolean }>()
);

export const setSelectedFilterEntity = createAction(
  '[ReturnHistory] Set selected filterEntity',
  props<{ selectedFilterEntity: ReturnOverviewFilter }>()
);

export const setSelectedPagination = createAction(
  '[ReturnHistory] Set selected Pagination',
  props<{ selectedPagination: PaginationModel }>()
);

export const setSelectedSorting = createAction(
  '[ReturnHistory] Set selected Sort',
  props<{ selectedSort: SortModel }>()
);
