import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as ReturnDetailsFeature from '../reducers/return-details.reducer';
import { RETURN_DETAILS_FEATURE_KEY } from '../reducers/return-details.reducer';
import { getReturnHistoryState, ReturnHistoryState } from '../reducers';
import { ReturnDetailsResponse } from '../../model/return-history.model';

export const selectLoading: MemoizedSelector<ReturnDetailsFeature.ReturnDetailsState, boolean> = createSelector(
  getReturnHistoryState,
  (state: ReturnHistoryState) => state[RETURN_DETAILS_FEATURE_KEY].loading
);

export const selectResponse: MemoizedSelector<ReturnDetailsFeature.ReturnDetailsState, ReturnDetailsResponse> = createSelector(
  getReturnHistoryState,
  (state: ReturnHistoryState) => state[RETURN_DETAILS_FEATURE_KEY].response
);

export const selectReturnNumber: MemoizedSelector<ReturnDetailsFeature.ReturnDetailsState, string> = createSelector(
  getReturnHistoryState,
  (state: ReturnHistoryState) => state[RETURN_DETAILS_FEATURE_KEY].returnNumber
);
