import { Injectable } from '@angular/core';

import { UpdateCartAdapter } from '../adapters/update-cart.adapter';
import { UpdateCartArg } from '../../model/update-cart.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateCartConnector {
  constructor(protected adapter: UpdateCartAdapter) {}

  updateCart(data: UpdateCartArg): Observable<any> {
    return this.adapter.updateCart(data);
  }
}
