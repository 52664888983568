<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form [formGroup]="form">
    <div class="row">
      <div class="col-12 pb-3">
        <span class="label-content d-inline-block  pb-2">{{'updateProfileForm.title'| cxTranslate }}</span>
        <div style="margin-left: 20px;" class="row title-container">
          <div class="col-3 " *ngFor="let title of titles$ | async" >
            <label class="form-check form-control form-check-inline">
              <input type="radio"
                         formControlName="titleName"
                         disabled
                         [value]="title.code" />
                         {{'updateProfileForm.gender.' + title.code | cxTranslate }}
            </label>
          </div>
        </div>
        <cx-form-errors [control]="form.get('titleName')"></cx-form-errors>
      </div>
      <div class="col-12 pb-3">
        <div class="row">
          <div class="col-sm-12 col-md-5">
            <span class="label-content d-inline-block  pb-2">{{
                'updateProfileForm.firstName.label' | cxTranslate
                }}</span>
            <input type="text" class="form-control" name="firstName" placeholder="{{
            'updateProfileForm.firstName.placeholder' | cxTranslate
          }}" formControlName="firstName" readonly/>
            <cx-form-errors [control]="form.get('firstName')"></cx-form-errors>
        </div>

        <div class="col-sm-12 col-md-7">
            <span class="label-content d-inline-block  pb-2">{{
                'updateProfileForm.lastName.label' | cxTranslate
                }}</span>
            <input type="text" class="form-control" name="lastName"
                placeholder="{{ 'updateProfileForm.lastName.placeholder' | cxTranslate }}" formControlName="lastName" readonly/>
            <cx-form-errors [control]="form.get('lastName')"></cx-form-errors>
        </div>
        </div>
      </div>
      <div class="col-12 pb-3">
          <span class="label-content d-inline-block  pb-2">{{
              'updateProfileForm.b2bUnitId' | cxTranslate
              }}</span>
          <input type="text" class="form-control" name="b2bUnitId"
              placeholder="{{ 'updateProfileForm.none' | cxTranslate }}" formControlName="b2bUnitId" readonly/>
      </div>

      <div class="col-12 pb-3">
          <span class="label-content d-inline-block  pb-2">{{
              'updateProfileForm.locName' | cxTranslate
              }}</span>
          <input type="text" class="form-control" name="locName"
              placeholder="{{ 'updateProfileForm.none' | cxTranslate }}" formControlName="locName" readonly/>
      </div>
    </div>

</form>
