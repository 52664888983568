import {createReducer, on} from '@ngrx/store';
import {ErrorType} from '@shared/models/ErrorTypes';
import {SetCartCalculationModeActions} from '../actions';

export const SET_CART_CALCULATION_MODE = 'setCartCalculationMode';

export interface SetCartCalculationModeState {
  cartCalculationMode: boolean;
  cartId: string;
  updating: boolean;
  success: boolean;
  error: ErrorType;
}

export const initialState: SetCartCalculationModeState = {
  cartCalculationMode: false,
  cartId: '',
  updating: false,
  success: false,
  error: null,
};

export const setCartCalculationModeReducer = createReducer(
  initialState,
  on(SetCartCalculationModeActions.resetCartCalculationMode, (state, action) => (
    initialState
  )),
  on(SetCartCalculationModeActions.setCartCalculationMode, (state, action) => ({
    ...state,
    updating: true,
    cartCalculationMode: action.cartCalculationMode,
    cartId: action.cartId,
    success: false
  })),
  on(SetCartCalculationModeActions.setCartCalculationModeSuccess, (state, action) => ({
    ...state,
    updating: false,
    success: true,
  })),
  on(SetCartCalculationModeActions.setCartCalculationModeFailure, (state, action) => ({
    ...state,
    updating: false,
    error: action.errorResponse,
  }))
);
