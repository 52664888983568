export const warrantyProcess = {
  warrantyProcess: {
    globalMessage: {
      startWarrantyProcessFailure: 'Warranty process failed',
      startWarrantyProcessSuccess: 'Warranty process succeeded'
    },
    warrantyProcessForm: {
      legend1: '1. Please enter article and vehicle data:',
      legend2: '2. Installation information:',
      legend3: '3. Please describe the defect and error code:',
      legend4: '4. Please upload your documents:',
      formMandatoryFields:'Mandatory fields are marked with *.',
      registrationDate: {
        label: 'Date of first registration',
        placeholder: 'Date of first registration'
      },
      mileage: {
        label: 'Mileage',
        placeholder: 'Mileage'
      },
      oemNumber: {
        label: 'Article',
        placeholder: 'Article'
      },
      manufacturer: {
        label: 'Brand',
        placeholder: 'Brand'
      },
      articleName: {
        label: 'Article description',
        placeholder: 'Article description'
      },
      customerComplaint: {
        label: 'Customer complaint',
        placeholder: 'Customer complaint'
      },
      installationDate: {
        label: 'Date of installation',
        placeholder: 'Date of installation'
      },
      installationMileage: {
        label: 'Mileage at installation',
        placeholder: 'Mileage at installation'
      },
      installationInvoiceDocument: {
        label: 'Installation Invoice',
      },
      deliveryInvoiceDocument: {
        label: 'Delivery Invoice',
      },
      additionalDocument: {
        label: 'Additional Documents',
      },
      descriptionOfCause: {
        label: 'Description of the cause (defect and error code)',
        placeholder: 'Description of the cause (defect and error code)'
      },
      submitWarrantyProcessForm: 'Submit data',
      resetForm: 'Reset',
      messages: {
        maxFileSizeLimitExceeded: 'File upload failed as file size limit ({{maxFileSize}} MB) exceeded'
      }
    }
  }
};
