import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AuthGuard, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {returnCreationTranslationChunksConfig, returnCreationTranslations} from './translations/return-creation.translations';
import {OccModule} from './occ/occ.module';
import {SharedModules} from '@shared/shared.modules';
import {
  DeliveryNoteReturnablePositionsComponent
} from './components/delivery-note-returnable-positions/delivery-note-returnable-positions.component';
import {ReturnablePositionsComponent} from './components/returnable-positions/returnable-positions.component';
import {ReturnCreationComponent} from './components/return-creation/return-creation.component';
import {effects} from '../return-creation/store/effects';
import {reducers, RETURN_CREATION_INDEX_FEATURE_KEY} from './store/reducers';
import {CmsPageGuard, FormErrorsModule, PageLayoutComponent, SpinnerModule} from '@spartacus/storefront';


@NgModule({
  declarations: [
    DeliveryNoteReturnablePositionsComponent,
    ReturnablePositionsComponent,
    ReturnCreationComponent
  ],
  imports: [
    CommonModule,
    OccModule,
    SharedModules,
    I18nModule,
    UrlModule,
    SpinnerModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      i18n: {
        resources: returnCreationTranslations,
        chunks: returnCreationTranslationChunksConfig,
      }
    }),
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            returnReason: 'returnReasons',
            returnCreation: 'users/${userId}/createReturnRequest',
          },
        },
      },
    }),
    ConfigModule.withConfig({
      cmsComponents: {
        DeliveryNoteReturnablePositionsComponent: {
          component: DeliveryNoteReturnablePositionsComponent,
        },
        ReturnCreationComponent: {
          component: ReturnCreationComponent
        }
      }
    }),
    ConfigModule.withConfig({
      routing: {
        routes: {
          deliveryNoteReturnablePositions: {
            paths: ['my-account/documents/delivery-note-returnable-positions/:deliveryNoteNumber'],
          },
          deliveryNoteReturnCreation: {
            paths: ['my-account/documents/delivery-note-return-creation'],
          },
        }
      }
    }),
    RouterModule.forChild([
      {
          path: null,
          canActivate: [AuthGuard, CmsPageGuard],
          component: PageLayoutComponent,
          data: {cxRoute: 'deliveryNoteReturnablePositions'},
      },
  ]),
    StoreModule.forFeature(RETURN_CREATION_INDEX_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
  ]
})
export class ReturnCreationModule {
}
