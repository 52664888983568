import { createReducer, on } from '@ngrx/store';

import { ReturnCreationActions } from '../actions';
import { ErrorType } from '@shared/models/ErrorTypes';
import { ReturnCreationRequest, ReturnCreationResponse } from '../../model/return-creation.model';

export const RETURN_CREATION_FEATURE_KEY = 'returnCreation';

export interface ReturnCreationState {
  request: ReturnCreationRequest;
  response: ReturnCreationResponse;
  processing: boolean;
  success: boolean;
  error: ErrorType;
}

export const initialState: ReturnCreationState = {
  request: undefined,
  response: undefined,
  processing: false,
  success: false,
  error: undefined
};

export const returnCreationReducer = createReducer(
  initialState,

  on(ReturnCreationActions.returnCreation, (state, action) => ({
    ...state,
    processing: true,
    success: false,
    request: action.request
  })),
  on(ReturnCreationActions.returnCreationSuccess, (state, action) => ({
    ...state,
    processing: false,
    response: action.response,
    success: true,
    request: undefined
  })),
  on(ReturnCreationActions.returnCreationFailure, (state, action) => ({
    ...state,
    processing: false,
    success: false,
    error: action.errorResponse
  })),
  on(ReturnCreationActions.resetReturnCreation, (state, action) => ({
    ...initialState,
  }))
);
