import {createReducer, on} from '@ngrx/store';

import {ReturnPositionActions} from '../actions';
import {ReturnablePosition, ReturnDocumentType} from '../../model/return-creation.model';

export const RETURN_POSITION_FEATURE_KEY = 'returnPosition';

export interface ReturnPositionState {
  positions: ReturnablePosition[];
  documentNumber: string;
  documentType: ReturnDocumentType;
  routeOrigin: string;
}

export const initialState: ReturnPositionState = {
  positions: undefined,
  documentNumber: null,
  documentType: undefined,
  routeOrigin: null,
};

export const returnPositionReducer = createReducer(
  initialState,

  on(ReturnPositionActions.SetReturnablePositions, (state, action) => ({
    ...state,
    positions: action.positions
  })),
  on(ReturnPositionActions.SetDocumentData, (state, action) => ({
    ...state,
    documentNumber: action.documentNumber,
    documentType: action.documentType,
    routeOrigin: action.routeOrigin
  })),
  on(ReturnPositionActions.ResetReturnablePositions, (state, action) => ({
      ...initialState
    }
  )),
);
