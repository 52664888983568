import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, ItemCounterModule } from '@spartacus/storefront';

import { EfaAddToCartComponent } from './components/efa-add-to-cart/efa-add-to-cart.component';
import { addToCartTranslationChunksConfig, addToCartTranslations } from './translation/add-to-cart.translation';
import { EfaAddedToCartDialogComponent } from './components/efa-added-to-cart-dialog/efa-added-to-cart-dialog.component';
import { SharedModules } from '@shared/shared.modules';
import { SpinnerModule } from '@spartacus/storefront';
import { AddToCartDialogComponent } from './components/add-to-cart-dialog/add-to-cart-dialog.component';


@NgModule({
  declarations: [EfaAddToCartComponent, EfaAddedToCartDialogComponent, AddToCartDialogComponent],
  imports: [
    CommonModule,
    ItemCounterModule,
    I18nModule,
    RouterModule,
    UrlModule,
    ReactiveFormsModule,
    ConfigModule.withConfig({
      i18n: {
        resources: addToCartTranslations,
        chunks: addToCartTranslationChunksConfig
      },
      cmsComponents: {
        ProductAddToCartComponent: {
          component: EfaAddToCartComponent,
        }
      }
    }),
    SharedModules,
    IconModule,
    SpinnerModule
  ],
  exports: [EfaAddToCartComponent, EfaAddedToCartDialogComponent]
})
export class AddToCartModule {
}
