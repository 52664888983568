import { createReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { ConfiguratorCartActions } from '../actions';

export const CONFIGURATOR_CART_FEATURE_KEY = 'configuratorCart';

export interface ConfiguratorCartState {
  userId: string;
  loading: boolean;
  response: any;
  error: ErrorType;
}

export const initialState: ConfiguratorCartState = {
  userId: undefined,
  loading: false,
  response: undefined,
  error: null,
};

export const configuratorCartReducer = createReducer(
  initialState,
  on(ConfiguratorCartActions.loadConfiguratorCart, (state, action) => ({
    ...state,
    loading: true,
    userId: action.userId,
  })),
  on(ConfiguratorCartActions.loadConfiguratorCartSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.response,
  })),
  on(ConfiguratorCartActions.loadConfiguratorCartFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse,
  }))
);
