import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ReturnReasonActions } from '../actions';
import { ErrorType } from '@shared/models/ErrorTypes';
import { ReturnReasonResponse } from '../../model/return-creation.model';
import { ReturnReasonAdapter } from '../../occ/adapters/return-reason.adapter';

@Injectable()
export class ReturnReasonEffect {

  returnReason$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReturnReasonActions.loadReturnReasons),
      concatMap(() =>
        this.connector.loadReasons().pipe(
          map((returnReasonResponse: ReturnReasonResponse) => {
            return ReturnReasonActions.loadReturnReasonsSuccess({
              response: returnReasonResponse
            });
          }),
          catchError((error: ErrorType) => {
            return of(ReturnReasonActions.loadReturnReasonsFailure({errorResponse: error}));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: ReturnReasonAdapter,
  ) {
  }
}
