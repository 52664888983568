<section class="cx-page-section container"
         *ngIf="!(isLoading$ | async); else loading">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 px-2">
      <div class="cx-section">
        <form [formGroup]="registrationForm">
          <p>{{"efaRegistrationForm.formMandatoryFields" | cxTranslate }}</p>
          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend small">{{'efaRegistrationForm.legend10' | cxTranslate}}</legend>

            <div class="register_fieldset-content">
              <div class="form-group">
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="newsletter" (change)="subscribeToNewsletterChange()"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.newsletter' | cxTranslate }}
                  </span>
                  <cx-form-errors [control]="registrationForm.get('newsletter')"></cx-form-errors>
                </label>
              </div>

              <label class="form-group">
                <span
                  class="label-content">{{ 'efaRegistrationForm.newsletterEmailAddress.label' | cxTranslate }}
                  *</span>
                <input class="form-control"
                       type="email"
                       placeholder="{{ 'efaRegistrationForm.newsletterEmailAddress.placeholder' | cxTranslate }}"
                       formControlName="newsletterEmail"/>
                <cx-form-errors
                  [control]="registrationForm.get('newsletterEmail')"></cx-form-errors>
              </label>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend small">{{'efaRegistrationForm.legend11' | cxTranslate}}</legend>
            <div class="register_fieldset-content">
              <div class="form-group" formArrayName="attentionReasonInfos">
                <div class="d-flex flex-column">
                  <ng-container
                    *ngFor="let attentionReasonInfosFormControl of attentionReasonInfosFormControls; let i=index">
                    <ng-container [formGroupName]="i">
                      <div class="row attentionReasonInfos">
                        <label class="form-check form-check-inline col-4 col-sm-2 ml-3 mb-2 mt-2 text-nowrap">
                          <input type="checkbox" formControlName="attentionReason"/>
                          {{ registrationInformationMultiOptionsFieldsValues.attentionReasons[i].name }}
                        </label>
                        <input *ngIf="attentionReasonInfosFormControl.get('attentionReasonInfo')"
                               type="text"
                               style="margin-left: 52px;"
                               class="form-control col-10 col-md-11 col-lg-8 mb-0 pt-1 pb-1 mr-3"
                               formControlName="attentionReasonInfo"
                               placeholder="{{registrationInformationMultiOptionsFieldsValues.attentionReasons[i].additionalInfoName}}"/>
                        <cx-form-errors
                          [control]="attentionReasonInfosFormControl.get('attentionReason')"></cx-form-errors>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="register_fieldset">
            <legend
              class="register_fieldset-legend">{{'efaRegistrationForm.legend12' | cxTranslate}}</legend>

            <div class="register_fieldset-content">
              <div class="form-group">
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="termsAndConditions"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.confirmThatRead1' | cxTranslate }}&nbsp;
                      <a [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl"
                         target="_blank">
                      {{ 'efaRegistrationForm.termsAndConditions' | cxTranslate }}
                      </a>
                      {{ 'efaRegistrationForm.confirmThatRead2' | cxTranslate }} *
                  </span>
                </label>

                <cx-form-errors prefix="efaRegistrationForm.formErrors.termsAndConditions"
                                [control]="registrationForm.get('termsAndConditions')"></cx-form-errors>
              </div>

              <div class="form-group">
                <label class="form-check">
                  <input type="checkbox"
                         formControlName="privacyProtection"/>
                  <span class="form-check-label">
                    {{ 'efaRegistrationForm.confirmPrivacyProtection1' | cxTranslate }}&nbsp;
                      <a [routerLink]="{ cxRoute: 'privacyProtection' } | cxUrl"
                         target="_blank">
                      {{ 'efaRegistrationForm.privacyProtection' | cxTranslate }}
                    </a>
                      {{ 'efaRegistrationForm.confirmPrivacyProtection2' | cxTranslate }} *
                  </span>
                </label>

                <cx-form-errors prefix="efaRegistrationForm.formErrors.privacyProtection"
                                [control]="registrationForm.get('privacyProtection')"></cx-form-errors>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
