import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as AllowedDocumentExtensionsReducers from './allowed-document-extensions.reducer';
import * as AllowedImageExtensionsReducers from './allowed-image-extensions.reducer';

export const SHARED_INDEX_FEATURE_KEY = 'shared';

export interface SharedState {
  [AllowedImageExtensionsReducers.ALLOWED_IMAGE_EXTENSIONS_FEATURE_KEY]: AllowedImageExtensionsReducers.AllowedImageExtensionsState;
  [AllowedDocumentExtensionsReducers.ALLOWED_DOCUMENT_EXTENSIONS_FEATURE_KEY]: AllowedDocumentExtensionsReducers.AllowedDocumentExtensionsState;
}

export const reducers: ActionReducerMap<SharedState> = {
  [AllowedImageExtensionsReducers.ALLOWED_IMAGE_EXTENSIONS_FEATURE_KEY]: AllowedImageExtensionsReducers.allowedImageExtensionsReducer,
  [AllowedDocumentExtensionsReducers.ALLOWED_DOCUMENT_EXTENSIONS_FEATURE_KEY]: AllowedDocumentExtensionsReducers.allowedDocumentExtensionsReducer
};

export const getSharedState = createFeatureSelector<SharedState>(SHARED_INDEX_FEATURE_KEY);