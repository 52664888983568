import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CardModule, FormErrorsModule, IconModule, PromotionsModule } from '@spartacus/storefront';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModules } from '@shared/shared.modules';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { CheckoutModule } from '@spartacus/checkout/base';
import { CartNotEmptyGuard, CheckoutAuthGuard, CheckoutGuard, CheckoutOrchestratorComponent } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryModesFacade, CheckoutStepType } from '@spartacus/checkout/base/root';
import { OrderConfirmationGuard } from '@spartacus/order/components';
import { reducers } from '../checkout-order/store/reducers';
import { EfaCheckoutOrderSummaryComponent } from './components/efa-checkout-order-summary/efa-checkout-order-summary.component';
import { EfaOrderConfirmationItemsComponent } from './components/efa-order-confirmation-items/efa-order-confirmation-items.component';
import {
  EfaOrderConfirmationOverviewComponent
} from './components/efa-order-confirmation-overview/efa-order-confirmation-overview.component';
import {
  EfaOrderConfirmationThankYouMessageComponent
} from './components/efa-order-confirmation-thank-you-message/efa-order-confirmation-thank-you-message.component';
import { EfaOrderConfirmationTotalsComponent } from './components/efa-order-confirmation-totals/efa-order-confirmation-totals.component';
import { EfaOrderOverviewComponent } from './components/efa-order-overview/efa-order-overview.component';
import { EfaPlaceOrderComponent } from './components/efa-place-order/efa-place-order.component';
import { EfaReviewSubmitComponent } from './components/efa-review-submit/efa-review-submit.component';
import { EfaCheckoutDeliveryService } from './service/efa-checkout-delivery.service';
import { EfaValidateCartGuard } from './service/efa-validate-cart.guard';
import { checkoutOrderFeatureKey } from './store/reducers';
import { checkoutOrderTranslationChunksConfig, checkoutOrderTranslations } from './translations/checkout-order.translations';


@NgModule({
  declarations: [
    EfaCheckoutOrderSummaryComponent,
    EfaReviewSubmitComponent,
    EfaPlaceOrderComponent,
    EfaOrderConfirmationThankYouMessageComponent,
    EfaOrderConfirmationItemsComponent,
    EfaOrderConfirmationTotalsComponent,
    EfaOrderConfirmationOverviewComponent,
    EfaOrderOverviewComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    CardModule,
    CartSharedModule,
    UrlModule,
    RouterModule,
    PromotionsModule,
    IconModule,
    SharedModules,
    ReactiveFormsModule,
    FormErrorsModule,
    CheckoutModule,
    ConfigModule.withConfig({
      i18n: {
        resources: checkoutOrderTranslations,
        chunks: checkoutOrderTranslationChunksConfig
      },
      routing: {
        routes: {
          checkout: {
            paths: ['checkout'],
          },
          checkoutShippingAddress: {
            disabled: true
          },
          checkoutDeliveryMode: {
            disabled: true
          },
          checkoutPaymentDetails: {
            disabled: true
          },
          checkoutReviewOrder: {
            paths: ['checkout/review-order']
          }
        },
      },
      checkout: {
        steps: [
          {
            id: 'reviewOrder',
            name: 'checkoutProgress.reviewOrder',
            routeName: 'checkoutReviewOrder',
            type: [CheckoutStepType.REVIEW_ORDER],
          },
        ],
      },
      cmsComponents: {
        CheckoutOrchestrator: {
          component: CheckoutOrchestratorComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, EfaValidateCartGuard, CheckoutGuard],
        },
        CheckoutOrderSummary: {
          component: EfaCheckoutOrderSummaryComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
        CheckoutReviewOrder: {
          component: EfaReviewSubmitComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
        OrderConfirmationThankMessageComponent: {
          component: EfaOrderConfirmationThankYouMessageComponent,
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationItemsComponent: {
          component: EfaOrderConfirmationItemsComponent,
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationTotalsComponent: {
          component: EfaOrderConfirmationTotalsComponent,
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationOverviewComponent: {
          component: EfaOrderConfirmationOverviewComponent,
          guards: [OrderConfirmationGuard],
        },
        OrderOverviewComponent: {
          component: EfaOrderOverviewComponent,
          guards: [OrderConfirmationGuard],
        },
      },
      backend: {
        occ: {
          endpoints: {
            updateCart: 'users/${userId}/carts/${cartId}/updateCart',
            placeOrder: 'users/${userId}/placeOrder?fields=orderType(DEFAULT),requestedDeliveryDate,consignmentInfo,sapOrderNumber,status,created,statusDisplay,calculated,totalDiscounts(DEFAULT),subTotal(DEFAULT),totalPrice(DEFAULT),store,site,deliveryMode(DEFAULT),code,guid,paymentInfo(DEFAULT),totalPriceWithTax(DEFAULT),totalTax(DEFAULT),user(DEFAULT),deliveryAddress(DEFAULT),deliveryCost(DEFAULT),totalExchangeTax(formattedValue),orderInfoFields(DEFAULT),entries(DEFAULT,totalPrice(formattedValue),quantity,product(images(FULL),stock(FULL),manufacturerAID,manufacturer,code,name),basePrice(formattedValue,value),updateable,deliveryItems(DEFAULT),positionTypeName,exchangeTaxRequired,customerTotalPrice(formattedValue),customerBasePrice(formattedValue),consignmentInfo,parent,substitutedOemNumber,info,orderInfoFields(DEFAULT),entryNumber,subEntries(DEFAULT,entryNumber,positionTypeName,exchangeTaxRequired,customerTotalPrice(formattedValue),customerBasePrice(formattedValue))),fromConfigurator',
          }
        }
      }
    }),
    StoreModule.forFeature(checkoutOrderFeatureKey, reducers),
    NgbTooltipModule,
  ],
  providers: [
    {
      provide: CheckoutDeliveryModesFacade,
      useClass: EfaCheckoutDeliveryService
    }
    ]
})
export class CheckoutOrderModule {
}
