import { Injectable } from '@angular/core';
import { SLASH, SLASH_ENCODED_URL_MARKER } from '@shared/models/shared.model';
import { BreadcrumbMeta, isNotUndefined, PageHeadingResolver, Product, ProductPageMetaResolver, ProductScope } from '@spartacus/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomProductPageMetaResolver extends ProductPageMetaResolver
  implements PageHeadingResolver {

  protected product$: Observable<Product> = this.routingService
  .getRouterState()
  .pipe(
    map((state) => state.state.params['productCode']?.replace(SLASH_ENCODED_URL_MARKER.substring(0, 3), SLASH)),
    filter((code) => !!code),
    switchMap((code) => this.productService.get(code, ProductScope.DETAILS)),
    filter(isNotUndefined)
  );

  baseSite$ = this.translation.translate('categoryInformation.pageMetaResolver.baseSite.name');

  resolveTitle(): Observable<string> {
    const obs = combineLatest([this.baseSite$, super.resolveTitle()]);
    return obs.pipe(
      map(([baseSite, title]) => baseSite + ' - ' + title));
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {
    return of([]);
  }
}
