import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { InvoiceCreditDetailsActions } from '../store/actions';
import * as InvoiceCreditDetailsFeature from '../store/reducers/invoice-credit-details.reducers';
import * as InvoiceCreditDetailsSelectors from '../store/selectors/invoice-credit-details.selectors';
import { InvoicesCreditDetailsResponse } from '../model/invoices-credits.model';

@Injectable({providedIn: 'root'})
export class InvoiceCreditDetailsService {
  constructor(protected store: Store<InvoiceCreditDetailsFeature.InvoiceCreditDetailsState>) {}

  loadInvoiceDetails(documentNumber: string): void {
    return this.store.dispatch(InvoiceCreditDetailsActions.loadInvoiceCreditDetails({ documentNumber }));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(InvoiceCreditDetailsSelectors.selectInvoiceDetailsLoading);
  }

  getResponse(): Observable<InvoicesCreditDetailsResponse> {
    return this.store.select(InvoiceCreditDetailsSelectors.selectInvoiceDetailsResponse);
  }
}
