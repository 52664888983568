<ng-container *ngIf="faqOverviewResponse$ | async as faqOverview">
    <div class="container">
        <div class="row" *ngFor="let faqRubric of faqOverview?.faqRubrics; let i=index">
            <div class="col-12 col-md-3" [ngClass]="getFaqRubricClass(i)">
                <div class="u-font-bold">{{ faqRubric?.name }}</div>
            </div>
            <div class="col-12 col-md-9">
                <div class="row" *ngFor="let faqItem of faqRubric?.faqItems; let j=index">
                    <div class="col-12">
                        <div class="faq-item" [ngClass]="getFaqItemClass(j)">
                            <a [routerLink]="
                                {
                                    cxRoute: getForwardRoute(), params: { faqItemCode: faqItem.code }
                                } | cxUrl" class="btn-link">
                                {{ faqItem?.title }}
                                <cx-icon [type]="iconType.CARET_RIGHT"></cx-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-md-block d-none">
                <div class="o-divider-horizontal o-divider-spacing-large"></div>
            </div>
        </div>
    </div>
</ng-container>