import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as RegistrationInformationMultiOptionFieldsValuesFeature from '../reducers/registration-information-multi-option-fields-values.reducer';
import { REGISTRATION_INFORMATION_MULTI_OPTION_FIELDS_VALUES_FEATURE_KEY } from '../reducers/registration-information-multi-option-fields-values.reducer';
import { getLoginRegistrationState, LoginRegistrationState } from '../reducers';
import { RegistrationInformationMultiOptionsFieldsValuesResponse } from '../../model/login-register.model';

export const selectLoading: MemoizedSelector<RegistrationInformationMultiOptionFieldsValuesFeature.RegistrationInformationMultiOptionFieldsValuesState, boolean>
  = createSelector(
  getLoginRegistrationState,
  (state: LoginRegistrationState) => state[REGISTRATION_INFORMATION_MULTI_OPTION_FIELDS_VALUES_FEATURE_KEY].loading
);

export const selectResponse: MemoizedSelector<RegistrationInformationMultiOptionFieldsValuesFeature.RegistrationInformationMultiOptionFieldsValuesState, RegistrationInformationMultiOptionsFieldsValuesResponse>
  = createSelector(
  getLoginRegistrationState,
  (state: LoginRegistrationState) => state[REGISTRATION_INFORMATION_MULTI_OPTION_FIELDS_VALUES_FEATURE_KEY].response
);
