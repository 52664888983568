import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import { ReturnCreationRequest, ReturnCreationResponse } from '../../model/return-creation.model';

export const returnCreation = createAction(
  '[ReturnCreation] Return Creation',
  props<{ request: ReturnCreationRequest }>()
);

export const returnCreationSuccess = createAction(
  '[ReturnCreation] Return Creation Success',
  props<{ response: ReturnCreationResponse }>()
);

export const returnCreationFailure = createAction(
  '[ReturnCreation] Return Creation Failure',
  props<{ errorResponse: ErrorType }>()
);

export const resetReturnCreation = createAction(
  '[ReturnCreation] Reset Return Creation'
);
