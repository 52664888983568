<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>

<div class="user-form-wrapper">
  <h3>{{ 'efaLoginForm.title' | cxTranslate }}</h3>
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="user-form">
    <label>
      <span class="label-content"
        >{{ "efaLoginForm.partnerId.label" | cxTranslate }}*</span
      >
      <input
        type="text"
        class="form-control"
        formControlName="partnerId"
        placeholder="{{ 'efaLoginForm.partnerId.placeholder' | cxTranslate }}"
      />
      <cx-form-errors [control]="form.get('partnerId')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content"
        >{{ "efaLoginForm.emailAddress.label" | cxTranslate }}*</span
      >
      <input
        type="email"
        class="form-control"
        formControlName="userId"
        placeholder="{{
          'efaLoginForm.emailAddress.placeholder' | cxTranslate
        }}"
      />
      <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content"
        >{{ "efaLoginForm.password.label" | cxTranslate }}*</span
      >
      <input
        type="password"
        class="form-control"
        placeholder="{{ 'efaLoginForm.password.placeholder' | cxTranslate }}"
        formControlName="password"
        efaPasswordVisibilitySwitch
        />

      <cx-form-errors [control]="form.get('password')"></cx-form-errors>
    </label>

    <a
      [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"
      aria-controls="reset-password"
      class="btn-link"
      >{{ "efaLoginForm.forgotPassword" | cxTranslate }}</a
    >

    <button
      type="submit"
      class="btn btn-block btn-primary"
      [disabled]="form.disabled"
    >
      {{ "efaLoginForm.signIn" | cxTranslate }}
    </button>
  </form>
</div>

<div class="register">
  <h3>{{ 'efaLoginForm.titleNew' | cxTranslate }}</h3>
  <p>{{ "efaLoginForm.registrationStart" | cxTranslate }}</p>

  <button
    [routerLink]="{ cxRoute: 'registrationStart' } | cxUrl"
    type="button"
    class="btn btn-block btn-secondary"
  >
    {{ "efaLoginForm.register" | cxTranslate }}
  </button>
  <p class="first-login">
    {{ 'efaLoginForm.textReturningCustomer' | cxTranslate }}
  </p>
  <p>{{ "efaLoginForm.activateAccountDescription" | cxTranslate }}</p>
  <a [routerLink]="{ cxRoute: 'activateAccount' } | cxUrl" class="btn-link">
    {{ "efaLoginForm.activateAccount" | cxTranslate }}
  </a>
</div>

<div class="separator">
  <div class="line"></div>
  <span>{{ "efaLoginForm.separator" | cxTranslate }}</span>
</div>
