import {Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef,} from '@angular/core';
import {LaunchDialogService} from '@spartacus/storefront';
import {combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {filter, mergeMap, switchMap} from 'rxjs/operators';
import {CONFIGURATOR_CART_CONFIRMATION} from 'src/app/custom/config/default-configurator-cart-confirmation-layout.config';
import {ConfiguratorCartConfirmationLocalStorageService} from '../../services/configurator-cart-confirmation-local-storage.service';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {ActiveCartFacade, Cart} from '@spartacus/cart/base/root';

@Component({
  selector: 'app-configurator-cart-confirmation',
  template: '',
})
export class ConfiguratorCartConfirmationComponent
  implements OnInit, OnDestroy
{
  private subscription: Subscription = new Subscription();
  private confirmationMessageSubscription: Subscription;
  private forceConfirmationMessageUnsubcribe: Subject<void> = new Subject();

  constructor(
    private vcr: ViewContainerRef,
    private launchDialogService: LaunchDialogService,
    private activeCartService: ActiveCartFacade,
    private configuratorCartConfirmationLocalStorageService: ConfiguratorCartConfirmationLocalStorageService
  ) {}

  ngOnInit(): void {
    this.confirmationMessageSubscription = this.activeCartService
      .getActive()
      .pipe(
        filter((cart: Cart) => cart.fromConfigurator),
        switchMap((cart: Cart) => {
          this.configuratorCartConfirmationLocalStorageService.initSync(
            cart.code
          );
          return this.configuratorCartConfirmationLocalStorageService.getConfiguratorCartConfirmed();
        }),
        filter((confirmed: boolean) => !confirmed),
        mergeMap(() => {
          const componentRef: Observable<ComponentRef<any>> | void =
            this.launchDialogService.launch(
              CONFIGURATOR_CART_CONFIRMATION,
              this.vcr
            );

          return componentRef ? componentRef : of(undefined);
        }),
        filter(Boolean),
        switchMap((componentRef: ComponentRef<any>) =>
          combineLatest([
            of(componentRef),
            this.launchDialogService.dialogClose,
          ])
        )
      )
      .subscribe(([componentRef, dialogClose]: [ComponentRef<any>, string]) => {
        if (dialogClose) {
          this.launchDialogService.clear(CONFIGURATOR_CART_CONFIRMATION);

          if (componentRef) {
            componentRef.destroy();
            this.forceConfirmationMessageUnsubcribe.next();
          }
        }
      });

    this.subscription.add(
      this.forceConfirmationMessageUnsubcribe
        .asObservable()
        .subscribe(() => this.confirmationMessageSubscription.unsubscribe())
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
