import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {OccEndpointsService} from '@spartacus/core';

import {InvoicesCreditOverviewRequest, InvoicesCreditOverviewResponse} from '../../../model/invoices-credits.model';
import {InvoiceCreditOverviewAdapter} from '../invoice-credit-overview.adapter';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class OccInvoiceCreditOverviewAdapter implements InvoiceCreditOverviewAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  loadInvoiceOverview(request: InvoicesCreditOverviewRequest): Observable<InvoicesCreditOverviewResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'invoiceCreditsOverview',
      {
        urlParams: {
          userId: 'current'
        }
      }
    );

    return this.http.post<InvoicesCreditOverviewResponse>(url, request, {headers});
  }
}
