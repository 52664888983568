import { Injectable } from '@angular/core';
import { B2BUser } from '@spartacus/core';
import { UserFormService } from '@spartacus/organization/administration/components';

@Injectable({
  providedIn: 'root',
})
export class EfaUserFormService extends UserFormService {
  protected patchData(item: B2BUser) {
    super.patchData(item);
    if (item) {
      const emailFormControl = this.form?.get('email');
      if (item.email && emailFormControl) {
        emailFormControl.setValue(item.email);
      }
    }
  }
}
