export const cartAdministration = {
  cartAdministration: {
    orderTypeSelect: {
      label: 'Auftragsart',
      placeholder: 'Auftragsart',
      forwardOrder: 'Terminauftrag'
    },
    orderDataForm: {
      formHeadline: 'Auftragsdaten',
      deliveryAddress: {
        label: 'Lieferadresse',
        placeholder: 'Lieferadresse'
      },
      deliveryDate: {
        label: 'Wunschliefertermin',
        placeholder: 'Wunschliefertermin'
      },
      shippingType: {
        label: 'Versandart',
        placeholder: 'Versandart auswählen',
        hint: '(Bitte wählen Sie den gewünschten Wert aus)',
        parcelService: 'Paketdienst'
      },
      submit: 'Auswählen'
    },
    cartProductSearchForm: {
      formHeadline: 'Direktbestellung mit Artikelnummer',
      productCategories: {
        label: 'Suche in Kategorie:',
        spareParts: 'OE-Teile und Identteile',
        oil: 'Öl',
        accessories: 'Zubehör',
        tools: 'Werkzeug'
      },
      manufacturerCodes: {
        label: 'Ersatzteilmarken',
        placeholder: 'Marke'
      },
      oemNumber: {
        label: 'Artikelnummer',
        placeholder: 'Artikelnummer'
      },
      quantity: {
        label: 'Menge',
        placeholder: 'Menge'
      },
      consignmentInfo: {
        label: 'Kommission',
        placeholder: 'Kommission (max. 40 Zeichen)',
        placeholderSimple: '(max. 40 Zeichen)'
      },
      searchForAlternativeArticles: 'Alternativartikel',
      submit: 'Hinzufügen'
    },
    modalDialog: {
      modalDialogBtn: 'Auswahl übernehmen',
      selectDeselectAll: 'Alle auswählen'
    },
    cartProductSearchResult: {
      article: 'Artikel',
      articleId: 'ID {{oemNumber}}',
      brand: 'Marke',
      quantity: 'Verfüg. Menge',
      warehouse: 'Lager',
      price: 'Preis'
    },
    globalMessage: {
      productSearchNoResult: 'Zur Artikelnummer \'{{articleNumber}}\' konnten in der Kategorie \'{{category}}\' keine Artikel gefunden werden. Bitte ändern Sie ggfs. die Marken- oder Kategorieauswahl.',
      multiAddToCartFailure: 'Beim Hinzufügen zum Warenkorb ist ein Fehler aufgetreten. Versuchen Sie es erneut.',
      cartProductSearchFailure: 'Bei der Artikelsuche ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.',
      updateCartFailure: 'Bei der Aktualisierung des Warenkorbs ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.',
      articleAlreadyInCart: 'Der angeforderte Artikel {{requestedArticle}} ist bereits mit maximaler Menge im Warenkorb und hat Nachfolgeartikel. Bitte erhöhen Sie die Anzahl des Nachfolgeartikels.',
      productSearchNotPurchasableArticle: {
        reason_articleNotPurchasable: 'Der gesuchte Artikel ist nicht mehr verkaufsfähig.',
        reason_articleNotFound: 'Artikel {{oemNumber}} nicht gefunden.',
        reason_articleCannotBeDelivered: 'Artikel {{oemNumber}} ist nicht mehr lieferbar.',
        reason_articleNotApprovedForSale: 'Artikel {{oemNumber}} ist zur Zeit nicht für den Verkauf freigegeben.',
      }
    },
    cartItems: {
      available: 'Verfügbar',
      status: 'Status',
      deliveryDate: 'Voraus. Liefertermin',
      warehouse: 'Lager',
      action: 'Aktion',
      brand: 'Marke',
      commission: 'Kommission',
      wishlist: 'Merkliste',
      searchedArticleNumber: 'Gesuchte Artikelnr.',
      substitutInfoMessage:'Das ursprünglich gesuchte Teil ist nicht verfügbar und wurde deshalb durch das nun angezeigte ersetzt.',
      defectiveSubstitutInfoMessage:'Der Artikel ist aktuell nicht bestellbar.',
      actions: {
        editCommission: 'Kommission eingeben',
        hideCommission: 'Kommission ausblenden',
        delete: 'Löschen'
      }
    },
    cart: {
      actions: 'Warenkorb organisieren',
      delete: 'Warenkorb löschen',
      deleteDialog: {
        header: 'Warenkorb löschen',
        infoText: 'Wollen Sie den aktuellen Warenkorb wirklich löschen?',
        buttonClose: 'Abbrechen',
        buttonConfirm: 'Ok'
      },
      calculateModeAutomatic: 'Warenkorb automatisch berechnen',
      calculateModeAutomaticHint: 'Eine Beschleunigung der Eingabe erfolgt wenn die automatische Berechnung des Warenkorbs deaktiviert ist.',
      calculateModeManualHint: 'Zur Beschleunigung der Eingabe deaktivieren Sie die automatische Berechnung des Warenkorbs.',
      calculate: 'Warenkorb berechnen'
    },
    orderCost: {
      atTax: 'AT-Steuer',
      noticeATTax: 'Für diese Position fällt Altteilsteuer an.',
      noticeConfiguratorTaxAndDeliveryCosts: 'Einkaufspreise ohne MwSt., ohne Versandkosten, ohne Montagekosten.',
    },
    orderInfoFields: {
      mandatoryMessage: 'Pflichtfelder sind mit einem * gekennzeichnet.',
      requiredFieldMessage: 'Dieses Feld wird benötigt.'
    },
    miniLogin: {
      userGreeting: 'Hallo, {{name}}',
      signInRegister: 'Anmelden / Registrieren',
    },
    configuratorCartConfirmationDialog: {
      confirmationMessageTitle: 'Warenkorb des Räderkonfigurators',
      confirmationMessageText: 'Sie befinden sich aktuell im Warenkorb des Räderkonfigurators. Nach Abschluss der Bestellung werden Sie zurück in Ihren ursprünglichen Warenkorb geleitet.',
      confirm: 'Verstanden',
      openCart:'Zum Warenkorb',
    }
  }
};
