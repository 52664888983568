import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CartDeleteDialogComponent } from './components/cart-delete-dialog/cart-delete-dialog.component';

export const defaultCartDeleteLayoutConfig: LayoutConfig = {
   launch: {
      CART_DELETE: {
         inlineRoot: true,
         component: CartDeleteDialogComponent,
         dialogType: DIALOG_TYPE.DIALOG,
      },
   },
};