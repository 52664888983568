import { Component, OnInit } from '@angular/core';
import { AssignCellComponent } from '@spartacus/organization/administration/components';
import { ICON_TYPE } from '../../model/user-profiles-icon.model';

@Component({
  selector: 'app-efa-remove-assigned-cell',
  templateUrl: './efa-remove-assigned-cell.component.html'
})
export class EfaRemoveAssignedCellComponent<T> extends AssignCellComponent<T> implements OnInit {
  iconTypes = ICON_TYPE;
  ngOnInit(): void {
  }

}
