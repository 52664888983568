import { createAction, props } from '@ngrx/store';

import { Address } from '@spartacus/core';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryMode } from '@spartacus/cart/base/root';
import { ProductCategory } from '../../model/cart-administration-enum.model';
import { OrderType } from '../../model/cart-administration.model';

export const setOrderType = createAction(
  '[CartAdministration] Set Order Type',
  props<{ orderType: OrderType }>()
);

export const setShippingType = createAction(
  '[CartAdministration] Set Shipping Type',
  props<{ shippingType: DeliveryMode }>()
);

export const setDeliveryAddress = createAction(
  '[CartAdministration] Set Delivery Address',
  props<{ deliveryAddress: Address }>()
);

export const setRequestedDeliveryDate = createAction(
  '[CartAdministration] Set Requested Delivery Data',
  props<{ requestedDeliveryDate: NgbDateStruct }>()
);

export const setProductCategory = createAction(
  '[CartAdministration] Set Manufacturer Type',
  props<{ productCategory: ProductCategory }>()
);
