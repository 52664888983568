import { Component, OnInit } from '@angular/core';

import { CartProductSearchResult } from '../../model/cart-administration.model';
import { FocusConfig, LaunchDialogService } from '@spartacus/storefront';
import { take } from 'rxjs/operators';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CART_PRODUCT_SEARCH_RESULT = 'CART_PRODUCT_SEARCH_RESULT',
  }
}

@Component({
  selector: 'app-cart-product-search-result',
  templateUrl: './cart-product-search-result.component.html',
})
export class CartProductSearchResultComponent implements OnInit {

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: true,
    focusOnEscape: true,
  };

  productSearchResults: CartProductSearchResult[];
  selectedProductSearchResult: CartProductSearchResult[] = [];

  constructor(
    private launchDialogService: LaunchDialogService
  ) {
  }

  ngOnInit(): void {
    this.launchDialogService.data$.pipe(take(1)).subscribe((data: CartProductSearchResult[]) => {
      this.productSearchResults = data;
      this.setProductSearchResults();
    });
  }

  setProductSearchResults(): void {

    this.productSearchResults = this.productSearchResults.slice().sort((a, b) => {
      return a.id < b.id || (a.alternativeReferenceId && a.alternativeReferenceId < b.id) ? -1 : 1;
    });
  }

  submitData(): void {
    if (this.selectedProductSearchResult.length > 0) {
      this.launchDialogService.closeDialog(this.selectedProductSearchResult);
    } else {
      this.launchDialogService.closeDialog('closed');
    }
  }

  closeModal(): void {
    this.launchDialogService.closeDialog('closed');
  }

  changeProductSelection(id: number, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      const searchItem = this.productSearchResults.find((result) => result.id === id);
      this.selectedProductSearchResult.push(searchItem);
    } else {
      const searchItemIndex = this.selectedProductSearchResult.findIndex((result) => result.id === id);
      this.selectedProductSearchResult.splice(searchItemIndex, 1);
    }
  }
}
