import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GlobalMessageType} from '@spartacus/core';
import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {PneuConfiguratorToken} from '../../model/content-tiles.model';
import {TokenGenerationConnector} from '../../occ/connectors/token-generation.connector';
import {TokenGenerationActions} from '../actions';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable()
export class TokenGenerationEffect {

  generateToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TokenGenerationActions.generateToken),
      concatMap((action) =>
        this.connector.generateToken(action.tokenName, action.saveToken).pipe(
          map((token: PneuConfiguratorToken) => {
            return TokenGenerationActions.generateTokenSuccess(token);
          }),
          catchError((error) => {
            this.globalMessageService.add(
              {
                key: 'tokenGeneration.globalMessage.generateTokenFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(TokenGenerationActions.generateTokenFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: TokenGenerationConnector,
    private globalMessageService: EfaGlobalMessageService,
  ) {

  }
}
