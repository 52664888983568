import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { DefaultManufacturersAdapter } from '../default-manufacturers.adapter';

@Injectable({
  providedIn: 'root'
})
export class OccDefaultManufacturersAdapter implements DefaultManufacturersAdapter {
  constructor(
    protected http: HttpClient, 
    protected occEndpoints: OccEndpointsService
  ) {
    
  }

  setDefaultManufacturers(defaultManufacturers: string): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = this.occEndpoints.buildUrl('defaultManufacturers', {
      urlParams: { 
        userId: 'current',
        defaultManufacturers
      }
    });

    return this.http.post<void>(url, null, { headers });
  }
}
