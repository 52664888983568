export const deliveryNotes = {
  deliveryNotesCustom: {
    globalMessage: {
      downloadContentEmpty: 'There isn\'t any PDF document available at the selected delivery note.',
      loadingDeliveryNotesOverviewFailure: 'Loading delivery notes failed.',
    },
    deliveryNotesFilterForm: {
      deliveryNoteNumber: {
        label: 'Delivery note number',
        placeholder: 'Delivery note number'
      },
      consignmentInfo: {
        label: 'Additional information',
        placeholder: 'Additional information'
      },
      oemNumber: {
        label: 'Article number',
        placeholder: 'Article number'
      },
      orderNumber: {
        label: 'Order number',
        placeholder: 'Order number'
      },
      dateFrom: {
        label: 'Date from',
        placeholder: 'Date from'
      },
      dateTo: {
        label: 'Date to',
        placeholder: 'Date to'
      }
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Sorting delivery notes',
      deliveryNoteNumber: 'Delivery note number',
      warehouseName: 'Warehouse name',
      creationDate: 'Creation date',
      select: 'Select',
      download:'Download',
      selectAll: 'Select All',
    },
    deliveryNotesDetails: {
      positionNumber: 'Position Id',
      oemNumber: 'OEM-Nr.',
      deliveredQuantity: 'Delivered quantity',
      consignmentInfo: 'Consignment info',
      deliveryDate: 'Delivery date',
      orderNumber: 'Order number',
      returnableQuantity: 'Returnable quantity',
      returnProducts: 'Return product'
    },
    deliveryNotesTitle:'Delivery notes',
    deliveryNoteNumber: 'Your document number',
    creationDate: 'Creation date',
    warehouseName: 'Warehouse name',
    receiverAddress: 'Receiver address',
    deliveryNotesOverview: {
      downloadDeliveryNoteDocumentFileNamePrefix: 'deliverynote',
      downloadDeliveryNoteDocumentsCompressedFileNamePrefix: 'deliverynotes',
    }
  }
};
