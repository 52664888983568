<div class="modal-container">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <cx-icon [type]="iconTypes.CLOSE_LIGHT"></cx-icon>
        </button>
    </div>
    <div class="modal-body">
        <div class="outer-container">
            <p class="topmotive-confirmation-message-title">
                {{ 'externalCatalogs.topmotiveConfirmationDialog.confirmationMessageTitle' | cxTranslate }}
            </p>
            <p class="topmotive-confirmation-message-text">
                {{ 'externalCatalogs.topmotiveConfirmationDialog.line1' | cxTranslate }}
            </p>
            <p class="topmotive-confirmation-message-text">
                {{ 'externalCatalogs.topmotiveConfirmationDialog.line2' | cxTranslate }}
            </p>
            <p class="topmotive-confirmation-message-text">
                {{ 'externalCatalogs.topmotiveConfirmationDialog.line3' | cxTranslate }}
            </p>
        </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-primary btn-confirm" type="button" (click)="confirm()">
        {{ 'externalCatalogs.topmotiveConfirmationDialog.buttonConfirm' | cxTranslate }}
      </button>
      <button class="btn btn-secondary btn-cancel" type="button" (click)="closeModal()">
        {{ 'externalCatalogs.topmotiveConfirmationDialog.buttonClose' | cxTranslate }}
      </button>
    </div>
</div>
