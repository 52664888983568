import { createReducer, on } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import { FaqItem } from '../../model/faq-items.model';
import { FaqItemActions } from '../actions';

export const FAQ_ITEM_FEATURE_KEY = 'faqItem';

export interface FaqItemState {
  faqItemCode: string;
  isUserLoggedIn: boolean;
  response: FaqItem;
  loading: boolean;
  error: ErrorType;
}

export const initialStateItem: FaqItemState = {
  faqItemCode: undefined,
  isUserLoggedIn: undefined,
  response: undefined,
  loading: false,
  error: undefined,
};

export const faqItemReducer = createReducer(
  initialStateItem,

  on(FaqItemActions.loadFaqItem, (state, action) => ({
    ...state,
    response: undefined,
    loading: true,
    faqItemCode: action.faqItemCode,
    isUserLoggedIn: action.isUserLoggedIn,
  })),
  on(FaqItemActions.loadFaqItemSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.response,
    faqItemCode: undefined,
    isUserLoggedIn: undefined,
  })),
  on(FaqItemActions.loadFaqItemFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse,
  })),
  on(FaqItemActions.resetFaqItem, (state, action) => ({
    ...initialStateItem,
  }))
);
