<div class="c-wish-list row">
  <!-- Item Information -->
  <div class="col-12">
    <div class="c-wish-list__info-container row">
      <!-- Item oemNumber -->
      <div *ngIf="cartEntry.product" class="col-md-2 data-row">
        <div class="cx-label d-block d-md-none d-lg-none d-xl-none u-font-bold">
          {{ 'cartItems.id' | cxTranslate }}:
        </div>
        <div *ngIf="cartEntry.product?.code" class="cx-value">
          <a *ngIf="cartEntry.productAvailable; else dummy"
             [routerLink]="{ cxRoute: 'product', params: cartEntry.product } | cxUrl"
             [queryParams]="{ source: pdpCallSource }">
            {{ cartEntry.product?.manufacturerAID }}
          </a>
          <ng-template #dummy>
            <span class="cx-value">{{ cartEntry.product?.manufacturerAID }}</span>
          </ng-template>
        </div>
      </div>
      <!-- Item name -->
      <div *ngIf="cartEntry.product" class="col-md-4 data-row">
        <div class="cx-label d-block d-md-none d-lg-none d-xl-none u-font-bold">
          {{ 'wishListItem.articleName' | cxTranslate }}:
        </div>
        <div *ngIf="cartEntry.product" class="cx-value">
          <a *ngIf="cartEntry.productAvailable; else dummy"
             [routerLink]="{ cxRoute: 'product', params: cartEntry.product } | cxUrl"
             [queryParams]="{ source: pdpCallSource }">
            {{ cartEntry.product?.name }}
          </a>
          <ng-template #dummy>
            <span class="cx-value">{{ cartEntry.product?.name }}</span>
          </ng-template>
        </div>
      </div>
      <!-- Item manufacturer -->
      <div *ngIf="cartEntry.product" class="col-md-2 data-row data-row-last">
        <div class="cx-label d-block d-md-none d-lg-none d-xl-none u-font-bold">
          {{ 'wishListItem.manufacturer' | cxTranslate }}:
        </div>
        <div *ngIf="cartEntry.product.manufacturer" class="cx-value">
          {{ cartEntry.product?.manufacturer }}
        </div>
      </div>
      <!-- add to cart -->
      <div class="col-12 col-md-3">
        <app-efa-add-to-cart *ngIf="cartEntry.product.stock.stockLevelStatus !== 'outOfStock'"
                             [showQuantity]="false"
                             [product]="cartEntry.product"
                             [showQuantityHint]="false"
                             [useDefaultAddToCart]="false"
                             [orderInfoRefId]="cartEntry.orderInfoRefId"></app-efa-add-to-cart>
        <ng-template #outOfStock>
                    <span class="cx-out-of-stock">
                        {{ 'addToCart.outOfStock' | cxTranslate }}
                    </span>
        </ng-template>
      </div>
      <div class="col-12 col-md-1">
        <button type="button" class="btn btn-link btn-block btn-remove" (click)="removeEntry(cartEntry)">
            <span class="mr-2 d-flex d-md-none">{{ 'wishListItem.actions.remove' | cxTranslate }}</span>
            <cx-icon [type]="iconTypes.DELETE"></cx-icon>
        </button>
      </div>
    </div>
  </div>
</div>
