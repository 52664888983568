import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {
  AuthConfigService,
  AuthGuard,
  AuthRedirectService,
  AuthService,
  BadRequestHandler,
  ConfigModule,
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorHandler,
  I18nModule,
  NotAuthGuard,
  ProtectedRoutesGuard,
  RoutingService,
  UrlModule,
  WindowRef,
} from '@spartacus/core';
import {FormModule, UserFormModule} from '@spartacus/organization/administration/components';
import {B2B_USER_NORMALIZER} from '@spartacus/organization/administration/core';
import {
  CmsPageGuard,
  FormErrorsModule,
  GenericLinkModule,
  IconModule,
  PageLayoutComponent,
  SpinnerModule
} from '@spartacus/storefront';
import {LoginFormComponentService} from '@spartacus/user/account/components';
import {ForgotPasswordComponentService} from '@spartacus/user/profile/components';
import {UserPasswordFacade} from '@spartacus/user/profile/root';

import {UserAccountEventListener, UserAccountFacade} from '@spartacus/user/account/root';
import {
  ConsentManagementDialogComponent
} from './components/consent-management-dialog/consent-management-dialog.component';
import {
  EfaForgotPasswordComponent
} from '../update-password/components/efa-forgot-password/efa-forgot-password.component';
import {EfaLoginFormComponent} from './components/efa-login-form/efa-login-form.component';
import {
  RegistrationBankAndDeliveryComponent
} from './components/registration-bank-and-delivery/registration-bank-and-delivery.component';
import {
  RegistrationCompanyComponent
} from './components/registration-company/registration-company.component';
import {
  RegistrationConfirmationComponent
} from './components/registration-confirmation/registration-confirmation.component';
import {
  RegistrationContactPersonsComponent
} from './components/registration-contact-persons/registration-contact-persons.component';
import {
  RegistrationDocumentsComponent
} from './components/registration-documents/registration-documents.component';
import {
  RegistrationFormComponent
} from './components/registration-form/registration-form.component';
import {
  RegistrationOrchestratorComponent
} from './components/registration-orchestrator/registration-orchestrator.component';
import {
  RegistrationProgressComponent
} from './components/registration-progress/registration-progress.component';
import {
  RegistrationStartComponent
} from './components/registration-start/registration-start.component';
import {SsoLoginComponent} from './components/sso-login/sso-login.component';
import {
  TermsAndConditionsConfirmationActionComponent
} from './components/terms-and-conditions-confirmation-action/terms-and-conditions-confirmation-action.component';
import {
  EfaPasswordVisibilityToggleDirective
} from './directives/efa-password-visibility-toggle.directive';
import {EfaBadRequestHandler} from './error-handlers/efa-bad-request-handler';
import {RegistrationErrorHandler} from './error-handlers/registration-error-handler';
import {EfaProtectedRoutesGuard} from './guards/efa-protected-routes.guard';
import {EfaOccB2BUserNormalizer} from './occ/efa-occ-b2b-user-normalizer';
import {OccModule} from './occ/occ.modules';
import {EfaAuthRedirectService} from './services/efa-auth-redirect.service';
import {EfaAuthService} from './services/efa-auth.service';
import {
  EfaForgotPasswordComponentService
} from '../update-password/services/efa-forgot-password-component.service';
import {EfaLoginFormComponentService} from './services/efa-login-form-component.service';
import {RegistrationOrchestratorGuard} from './services/registration-orchestrator.guard';
import {effects} from './store/effects';
import {LOGIN_REGISTRATION_FEATURE_KEY, reducers} from './store/reducers';
import {
  loginRegisterTranslationChunksConfig,
  loginRegisterTranslations
} from './translations/login-register.translations';
import {
  RegistrationSubmissionComponent
} from './components/registration-submission/registration-submission.component';
import {EfaUserAccountEventListener} from './events/efa-user-account-event.listener';

@NgModule({
  declarations: [
    EfaLoginFormComponent,
    EfaForgotPasswordComponent,
    RegistrationConfirmationComponent,
    RegistrationStartComponent,
    ConsentManagementDialogComponent,
    TermsAndConditionsConfirmationActionComponent,
    RegistrationFormComponent,
    SsoLoginComponent,
    EfaPasswordVisibilityToggleDirective,
    RegistrationCompanyComponent,
    RegistrationOrchestratorComponent,
    RegistrationProgressComponent,
    RegistrationBankAndDeliveryComponent,
    RegistrationContactPersonsComponent,
    RegistrationDocumentsComponent,
    RegistrationSubmissionComponent,
  ],
  imports: [
    CommonModule,
    UserFormModule,
    I18nModule,
    FormErrorsModule,
    NgSelectModule,
    FormModule,
    ReactiveFormsModule,
    NgbModule,
    UrlModule,
    RouterModule,
    NgbModalModule,
    OccModule,
    GenericLinkModule,
    IconModule,
    SpinnerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ActivateAccountComponent: {
          component: EfaForgotPasswordComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: ForgotPasswordComponentService,
              useClass: EfaForgotPasswordComponentService,
              deps: [
                UserPasswordFacade,
                RoutingService,
                AuthConfigService,
                GlobalMessageService,
              ],
            },
          ],
        },
        ReturningCustomerLoginComponent: {
          component: EfaLoginFormComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: LoginFormComponentService,
              useClass: EfaLoginFormComponentService,
              deps: [
                AuthService, GlobalMessageService,
                WindowRef, UserAccountFacade,
                RoutingService, EventService],
            },
          ],
        },
        RegistrationConfirmationComponent: {
          component: RegistrationConfirmationComponent,
          guards: [NotAuthGuard],
        },
        RegistrationStartComponent: {
          component: RegistrationStartComponent,
          guards: [NotAuthGuard],
        },
        RegistrationOrchestrator: {
          component: RegistrationOrchestratorComponent,
          guards: [NotAuthGuard, RegistrationOrchestratorGuard],
        },
        RegistrationProgress: {
          component: RegistrationProgressComponent,
          guards: [NotAuthGuard],
        },
        RegistrationBankAndDelivery: {
          component: RegistrationBankAndDeliveryComponent,
          guards: [NotAuthGuard],
        },
        RegistrationCompany: {
          component: RegistrationCompanyComponent,
          guards: [NotAuthGuard],
        },
        RegistrationContactPersons: {
          component: RegistrationContactPersonsComponent,
          guards: [NotAuthGuard],
        },
        RegistrationDocuments: {
          component: RegistrationDocumentsComponent,
          guards: [NotAuthGuard],
        },
        RegistrationSubmission: {
          component: RegistrationSubmissionComponent,
          guards: [NotAuthGuard],
        },
        TermsAndConditionsConfirmationAction: {
          component: TermsAndConditionsConfirmationActionComponent,
        },
        RegisterCustomerComponent: {
          component: RegistrationFormComponent,
          guards: [NotAuthGuard],
        },
        SsoLoginComponent: {
          component: SsoLoginComponent,
        },
      },
      i18n: {
        resources: loginRegisterTranslations,
        chunks: loginRegisterTranslationChunksConfig,
      },
      routing: {
        routes: {
          registrationConfirmation: {paths: ['registrationConfirmation'], protected: false},
          registrationStart: {paths: ['registrationStart'], protected: false},
          registration: {paths: ['registration'], protected: false},
          registrationCompany: {paths: ['registrationCompany'], protected: false},
          registrationContactPersons: {paths: ['registrationContactPersons'], protected: false},
          registrationBankAndDelivery: {paths: ['registrationBankAndDelivery'], protected: false},
          registrationDocuments: {paths: ['registrationDocuments'], protected: false},
          registrationSubmission: {paths: ['registrationSubmission'], protected: false},
          termsAndConditions: {paths: ['terms-and-conditions'], protected: false},
          termsAndConditionsConfirmation: {
            paths: ['terms-and-conditions-confirm'],
            protected: false
          },
          ssoLogin: {paths: ['sso-login'], protected: false},
          unsupportedBrowser: {paths: ['unsupported-browser'], protected: false},
          ssoLoginError: {paths: ['sso-login-error'], protected: false},
          aboutUs: {paths: ['about-us'], protected: false},
          privacyProtection: {paths: ['privacy-protection'], protected: false},
          helpAnonymous: {paths: ['help-anonymous'], protected: false},
          faqAnonymous: {paths: ['help/faq-anonymous'], protected: false},
          activateAccount: {paths: ['activate-account'], protected: false}
        }
      },
      backend: {
        occ: {
          endpoints: {
            registrationStart: 'registration/start',
            registrationLoad: 'registration/load/${processId}',
            registrationConfirmation: 'registration/confirm?token=${token}',
            registrationInformationMultiOptionsFieldsValues: 'registration/retrieveMultiOptionsFieldsValues',
          }
        }
      },
      globalMessages: {
        [GlobalMessageType.MSG_TYPE_WARNING]: {
          timeout: 30000,
        },
      },
      layoutSlots: {
        header: {
          lg: {
            slots: [
              'PreHeader',
              'SiteContext',
              'SiteLinks',
              'SiteLogo',
              'NavigationBar',
              'SiteLogin',
              'MiniCart',
              'SearchBox',
            ],
          },
        },
        LoginPageTemplate: {
          slots: ['TopContentSlot', 'LeftContentSlot', 'RightContentSlot', 'WideBodyContent', 'BodyContent', 'TopFooter'],
        },
      },
    }),
    RouterModule.forChild([
      {
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {cxRoute: 'termsAndConditionsConfirmation'},
      },
    ]),
    /* RouterModule.forChild([
      {
        path: null,
        canActivate: [CmsPageGuard, RegistrationCompanyGuard],
        component: PageLayoutComponent,
        data: {cxRoute: 'registrationCompany'},
      },
    ]), */
    StoreModule.forFeature(LOGIN_REGISTRATION_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects)
  ],
  providers: [
    {
      provide: B2B_USER_NORMALIZER,
      useExisting: EfaOccB2BUserNormalizer,
      multi: true,
    },
    {
      provide: HttpErrorHandler,
      useExisting: RegistrationErrorHandler,
      multi: true,
    },
    {
      provide: ProtectedRoutesGuard,
      useClass: EfaProtectedRoutesGuard,
    },
    {
      provide: AuthService,
      useClass: EfaAuthService
    },
    {
      provide: AuthRedirectService,
      useClass: EfaAuthRedirectService
    },
    {
      provide: BadRequestHandler,
      useExisting: EfaBadRequestHandler
    },
    {
      provide: UserAccountEventListener,
      useExisting: EfaUserAccountEventListener
    }
  ]
})
export class LoginRegisterModule {
}

