import {createReducer, on} from '@ngrx/store';
import * as ProductPricesActions from './product-prices.actions';
import {PriceResponseItem} from '../model/product-prices.model';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export const productPricesFeatureKey = 'productPrices';

export interface StateWithPrices {
  productPricesFeatureKey: ProductPriceState;
}

export interface ProductPriceState extends EntityState<PriceResponseItem> {
  isLoading: boolean;
  isError: boolean;
}

export const adapter: EntityAdapter<PriceResponseItem> = createEntityAdapter<PriceResponseItem>({
  selectId: (priceItem) => priceItem.productCode,
});

export const initialState = adapter.getInitialState({
  isError: undefined,
  isLoading: false,
});


export const reducer = createReducer(
  initialState,

  on(ProductPricesActions.loadProductPrices, state => {
    return { ...state, isLoading: true, isError: false };
  }),
  on(ProductPricesActions.loadProductPricesSuccess, (state, action) => {
    return adapter.upsertMany(action.data.productPricesReponse.products, { ...state, isLoading: false });
  }),
  on(ProductPricesActions.loadProductPricesFailure, (state, action) => {
    return { ...state, isLoading: false, isError: true };
  }),
);

