import { Component, OnInit } from '@angular/core';
import { ProcessStepSubmitService } from '@shared/services/process-step-submit.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ICON_TYPE, numberToIcon } from '../../model/login-register-enum.model';
import {
  RegistrationInformationMultiOptionsFieldsValuesResponse,
  RegistrationStepResponse,
} from '../../model/login-register.model';
import { RegistrationInformationMultiOptionFieldsValuesService } from '../../services/registration-information-multi-option-fields-values.service';

@Component({
  selector: 'app-registration-progress',
  templateUrl: './registration-progress.component.html',
})
export class RegistrationProgressComponent implements OnInit {
  steps$: Observable<RegistrationStepResponse[]>;
  activeStepIndex$: Observable<number>;

  readonly numberToIconRecord = numberToIcon;
  readonly iconType = ICON_TYPE;

  constructor(
    protected multiOptionFieldsService: RegistrationInformationMultiOptionFieldsValuesService,
    protected submitService: ProcessStepSubmitService,
  ) {}

  ngOnInit(): void {
    this.multiOptionFieldsService.loadRegistrationInformationMultiOptionFieldsValues();
    this.steps$ = this.multiOptionFieldsService.getResponse().pipe(
      filter(Boolean),
      map(
        (response: RegistrationInformationMultiOptionsFieldsValuesResponse) =>
          response.registrationSteps
      )
    );
    this.activeStepIndex$ = this.submitService.getActiveStepIndex();
  }

  getStepIndexLeftDividerClass(index: number): string {
    if (index === 1) {
      return 'first';
    } 

    return '';
  }

  getStepIndexRightDividerClass(index: number, length: number): string {
    if (index === length) {
      return 'last';
    } 

    return '';
  }

  getStepHighlightClass(index: number, activeIndex: number): string {
    if (index <= activeIndex) {
      return 'registration-step-highlight';
    } 

    return '';
  }
}
