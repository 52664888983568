import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { ConsentManagementDialogComponent } from '../components/consent-management-dialog/consent-management-dialog.component';
import {
  EfaAnonymousConsentDialogComponent
} from "../components/efa-anonymous-consent-dialog/efa-anonymous-consent-dialog.component";

export const defaultConsentManagementLayoutConfig: LayoutConfig = {
    launch: {
       CONSENT_MANAGEMENT: {
          inlineRoot: true,
          component: ConsentManagementDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
       },
    },
};

export const defaultAnonymousConsentLayoutConfig: LayoutConfig = {
  launch: {
    ANONYMOUS_CONSENT: {
      inlineRoot: true,
      component: EfaAnonymousConsentDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
