import '@spartacus/core';
import '@spartacus/organization/administration/core';
import '@spartacus/user/account/root';

declare module '@spartacus/organization/administration/core' {
  export interface Permission {
    name?: string;
    identifier?: string;
  }
}

declare module '@spartacus/user/account/root' {
  export interface User {
    permissionIds?: string[];
    permissionGroupNames?: string[];
    lastLogin?: string;
    portalUser?: boolean;
  }
}

declare module '@spartacus/core' {
  export interface B2BUser {
    portalUser?: boolean;
  }

  interface B2BUnit {
    discountVisible?: boolean;
  }
}

export enum B2BWebShopPermission {
  VIEW_PRICES = 'viewPrices',
  PLACE_ORDERS = 'placeOrders',
}
