import { createAction, props } from '@ngrx/store';

import { AddToCartParam, CartModificationList } from '../../model/cart-administration.model';
import { ErrorType } from '@shared/models/ErrorTypes';

export const loadMultiAddToCart = createAction(
  '[MultiAddToCart] Load MultiAddToCart',
  props<{
    entries: AddToCartParam[],
    userId: string,
    cartId: string
  }>()
);

export const loadMultiAddToCartReset = createAction(
  '[MultiAddToCart] Load MultiAddToCart Reset');

export const loadMultiAddToCartSuccess = createAction(
  '[MultiAddToCart] Load MultiAddToCart Success',
  props<{ modifications: CartModificationList }>()
);

export const loadMultiAddToCartFailure = createAction(
  '[MultiAddToCart] Load MultiAddToCart Failure',
  props<{ errorResponse: ErrorType }>()
);

