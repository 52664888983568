import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EfaAddToWishListComponentBaseComponent
} from '@shared/components/efa-add-to-wish-list-base/efa-add-to-wish-list-base.component';
import { AuthService, UserIdService } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { CartParametersService } from '../../../cart-administration/services/cart-parameters.service';
import { MultiAddToCartService } from '../../../cart-administration/services/multi-add-to-cart.service';
import { PriceResponseItem } from '../../../product-prices/model/product-prices.model';
import { ProductPricesService } from '../../../product-prices/service/product-prices.service';
import { WishListFacade } from '@spartacus/cart/wish-list/root';

@Component({
  selector: 'app-efa-add-to-wish-list',
  templateUrl: './efa-add-to-wish-list.component.html',
})
export class EfaAddToWishListComponent extends EfaAddToWishListComponentBaseComponent implements OnInit, OnDestroy {

  productPriceItem$: Observable<PriceResponseItem>;

  private subscription: Subscription = new Subscription();

  constructor(
    protected wishListService: WishListFacade,
    protected currentProductService: CurrentProductService,
    protected authService: AuthService,
    protected multiAddToCartService: MultiAddToCartService,
    protected productPricesService: ProductPricesService,
    protected userIdService: UserIdService,
    protected cartParametersService: CartParametersService,
  ) {
    super(wishListService, currentProductService, authService, multiAddToCartService);
  }

  ngOnInit(): void {
    this.subscription.add(this.subscription.add(this.currentProductService.getProduct().subscribe(product => {
      this.productPriceItem$ = this.productPricesService.getPrice(product.code);
    })));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
