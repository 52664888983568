import {Component, OnDestroy, OnInit} from '@angular/core';
import {RoutingService} from '@spartacus/core';
import {OrderDetailsService} from '@spartacus/order/components';
import {Observable, Subscription} from 'rxjs';
import {OrderCancelService} from '../../../order-cancel/services/order-cancel.service';
import {OrderOverviewUiService} from '../../services/order-overview-ui.service';

@Component({
  selector: 'app-order-detail-actions',
  templateUrl: './order-detail-actions.component.html'
})
export class OrderDetailActionsComponent implements OnInit, OnDestroy {

  order$: Observable<any>;

  private subscription: Subscription = new Subscription();

  constructor(private routingService: RoutingService, private orderDetailsService: OrderDetailsService, private orderAmendService: OrderCancelService, private orderOverviewUiService: OrderOverviewUiService) {
  }

  ngOnInit(): void {
    this.order$ = this.orderDetailsService.getOrderDetails();
  }

  startCancellation(): void {
    this.subscription = this.order$.subscribe(order => {
      this.orderAmendService.resetForm();
      this.routingService.go({cxRoute: 'orderCancel', params: order});
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goBack(): void {
    this.orderOverviewUiService.setReturningFromDetails(true);
    this.routingService.go({cxRoute: 'orders'});
  }
}
