<app-return-overview-filter [availableReturnStates]="(returnOverview$ | async)?.availableReturnStates"
  (returnOverviewFilterChanges)="setReturnOverviewFilter($event)"></app-return-overview-filter>
<ng-container *ngIf="{
    isLoading: isLoading$ | async,
    returnOverview: returnOverview$ | async
  } as type">
  <ng-container *ngIf="type.returnOverview && !type.isLoading; else loading">
    <!-- BODY -->
    <div class="cx-order-history-body">
      <ng-container *ngIf="type.returnOverview.pagination.totalResults > 0; else noResult">
        <!-- Select Form and Pagination Top -->
        <div class="cx-order-history-sort top row">
          <div class="cx-order-history-form-group form-group col-sm-12 col-md-4 col-lg-4">
            <cx-sorting
              [sortOptions]="type.returnOverview.sorts"
              [sortLabels]="getSortLabels() | async"
              (sortListEvent)="changeSortCode($event)"
              [selectedOption]="type.returnOverview.pagination.sort"
              placeholder=" {{ 'returnOverview.myAccount.sorting' | cxTranslate }}"></cx-sorting>
          </div>
          <div *ngIf="type.returnOverview.pagination.totalPages > 1"
               class="cx-order-history-pagination col-sm-12 col-md-4 col-lg-4">
            <cx-pagination [pagination]="type.returnOverview.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
        <!-- TABLE -->
        <table class="table cx-order-history-table">
          <thead class="cx-order-history-thead-mobile">
          <th scope="col">{{ 'returnOverview.myAccount.returnNumber' | cxTranslate }}</th>
          <th scope="col">{{ 'returnOverview.myAccount.deliveryNoteNumber' | cxTranslate }}</th>
          <th scope="col">{{ 'returnOverview.myAccount.statusDisplay' | cxTranslate }}</th>
          <th scope="col">{{ 'returnOverview.myAccount.creationDate' | cxTranslate }}</th>
          <th scope="col">{{ 'returnOverview.myAccount.positionCount' | cxTranslate }}</th>
          </thead>
          <tbody>
          <tr *ngFor="let return of type.returnOverview.returns">
            <td attr.data-label="{{ 'returnOverview.myAccount.returnNumber' | cxTranslate }}"
                class="cx-order-history-return-number">
              <a [routerLink]="{
                  cxRoute: 'returnsDetails',
                  params: return
                } | cxUrl">{{ return?.returnNumber }}</a>
            </td>
            <td attr.data-label="{{ 'returnOverview.myAccount.deliveryNoteNumber' | cxTranslate }}"
                class="cx-order-history-type-note-number">
              {{ return?.deliveryNoteNumber }}
            </td>
            <td attr.data-label="{{ 'returnOverview.myAccount.statusDisplay' | cxTranslate }}"
                class="cx-order-history-type-status-display">
              {{ return?.statusDisplay }}
            </td>
            <td attr.data-label="{{ 'returnOverview.myAccount.creationDate' | cxTranslate }}"
                class="cx-order-history-type-date">
              {{ return?.creationDate | date: 'dd.MM.yyyy' }}
            </td>
            <td attr.data-label="{{ 'returnOverview.myAccount.positionCount' | cxTranslate }}"
                class="cx-order-history-type-position">
              {{ return?.positionCount }}
            </td>
          </tr>
          </tbody>
        </table>
        <!-- Select Form and Pagination Bottom -->
        <div class="cx-order-history-sort bottom row">
          <div *ngIf="type.returnOverview.pagination.totalPages > 1"
               class="cx-order-history-pagination">
            <cx-pagination [pagination]="type.returnOverview.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
      </ng-container>
      <!-- NO RESULT CONTAINER -->
      <ng-template #noResult>
        <app-no-display-result-notice noticeHeadline="{{ 'filteredDocumentsResult.noResult' | cxTranslate }}"></app-no-display-result-notice>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #loading>
    <div *ngIf="type.isLoading" class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</ng-container>
