<app-invoice-credit-filter
  (invoiceCreditOverviewFilterChanges)="setOrderOverviewFilter($event)"
  [availableDocumentTypes]="(invoicesCredits$ | async)?.availableDocumentTypes"></app-invoice-credit-filter>
<ng-container *ngIf="{
    isLoading: isLoading$ | async,
    invoicesCredits: invoicesCredits$ | async
  } as type">
  <ng-container *ngIf="type.invoicesCredits && !type.isLoading; else loading">
    <!-- BODY -->
    <div class="cx-order-history-body">
      <ng-container *ngIf="type.invoicesCredits.pagination.totalResults > 0; else noResult">
        <!-- Select Form and Pagination Top -->
        <div class="cx-order-history-sort top row">
          <div class="cx-order-history-form-group form-group col-sm-12 col-md-4 col-lg-4">
            <cx-sorting
              [sortOptions]="type.invoicesCredits.sorts"
              [sortLabels]="getSortLabels() | async"
              (sortListEvent)="changeSortCode($event)"
              [selectedOption]="type.invoicesCredits.pagination.sort"
              placeholder=" {{ 'invoicesCreditsCustom.myAccount.creditBonus.sorting' | cxTranslate }}"></cx-sorting>
          </div>
          <div *ngIf="type.invoicesCredits.pagination.totalPages > 1"
               class="cx-order-history-pagination col-sm-12 col-md-4 col-lg-4">
            <cx-pagination [pagination]="type.invoicesCredits.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
        <!-- Download Button Box -->
        <div class="row download-box">
          <div class="d-none d-lg-block offset-lg-9 col-md-3">
            <button class="btn btn-link cx-action-link text-decoration-none mb-0"
            (click)="onDownloadMultipleDocuments()"
            [disabled]="selectedInvoiceCreditDocumentNumbers.length == 0">
              <cx-icon [type]="iconTypes.DOWNLOAD" class="mr-0 o-download-file__icon-table-header"></cx-icon>
              <span class="u-font-bold">{{ 'tooltip.download' | cxTranslate }}</span>
            </button>
          </div>
        </div>
        <!-- TABLE -->
        <table class="table cx-order-history-table">
          <thead class="cx-order-history-thead-mobile">
          <th scope="col" class="align-middle">{{ 'invoicesCreditsCustom.myAccount.documentNumber' | cxTranslate }}</th>
          <th scope="col" class="align-middle">{{ 'invoicesCreditsCustom.myAccount.CreationDate' | cxTranslate }}</th>
          <th scope="col" class="align-middle">{{ 'invoicesCreditsCustom.myAccount.documentTypeName' | cxTranslate }}</th>
          <th scope="col" class="align-middle">{{ 'invoicesCreditsCustom.myAccount.total' | cxTranslate }}</th>
          <th scope="col" class="align-middle d-flex">
            <div style="font-weight: 400;"  class="form-check mb-0 o-check-all_table-header">
              <input class="form-check-input mt-0" type="checkbox" (change)="onAllSelectedChange(type.invoicesCredits.documents)"
                     [checked]="isAllInvoiceCreditDocumentsSelected"/>
                     {{ 'invoicesCreditsCustom.myAccount.selectAll' | cxTranslate }}
            </div>
          </th>
          </thead>
          <tbody>
          <tr *ngFor="let document of type.invoicesCredits.documents">
            <td attr.data-label="{{ 'invoicesCreditsCustom.myAccount.documentNumber' | cxTranslate }}"
                class="cx-order-history-number">
              {{ document?.documentNumber }}
            </td>
            <td attr.data-label="{{ 'invoicesCreditsCustom.myAccount.CreationDate' | cxTranslate }}"
                class="cx-order-history-date">
              {{ document?.creationDate | date: 'dd.MM.yyyy'}}
            </td>
            <td attr.data-label="{{ 'invoicesCreditsCustom.myAccount.documentTypeName' | cxTranslate }}"
                class="cx-order-history-type-name">
              {{ document?.documentTypeName }}
            </td>
            <td attr.data-label="{{ 'invoicesCreditsCustom.myAccount.total' | cxTranslate }}"
                class="cx-order-history-total">
              {{ document?.total.formattedValue }}
            </td>
            <td attr.data-label="{{ 'invoicesCreditsCustom.myAccount.select' | cxTranslate }}">
              <div class="form-check d-none d-lg-block">
                <input class="form-check-input mt-0" type="checkbox" (change)="onDocumentSelectedChange(document?.documentNumber)"
                [checked]="isDocumentSelected(document?.documentNumber)"/>
              </div>
              <button style="color: #7E8490; height: auto;"
                      class="btn btn-link cx-action-link text-decoration-none mb-0 p-0 d-lg-none"
                      (click)="onDowloadSingleDocument(document?.documentNumber)">
                 <cx-icon [type]="iconTypes.DOWNLOAD" class="mr-2 o-download-file__icon-table-header"></cx-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- Select Form and Pagination Bottom -->
        <div class="cx-order-history-sort bottom row">
          <div *ngIf="type.invoicesCredits.pagination.totalPages > 1"
               class="cx-order-history-pagination">
            <cx-pagination [pagination]="type.invoicesCredits.pagination"
                           (viewPageEvent)="pageChange($event)"></cx-pagination>
          </div>
        </div>
      </ng-container>
      <!-- NO RESULT CONTAINER -->
      <ng-template #noResult>
        <app-no-display-result-notice noticeHeadline="{{ 'filteredDocumentsResult.noResult' | cxTranslate }}"></app-no-display-result-notice>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div *ngIf="isLoading$ | async" class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
