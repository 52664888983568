import { Component, OnInit } from '@angular/core';
import {UserGroupDetailsComponent} from '@spartacus/organization/administration/components';

@Component({
  selector: 'app-efa-user-group-details',
  templateUrl: './efa-user-group-details.component.html'
})
export class EfaUserGroupDetailsComponent extends UserGroupDetailsComponent
{
}
