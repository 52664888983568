import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PermissionCheckService} from '../service/permission-check.service';
import {PermissionBasedGuard} from './permission-based.guard';

@Injectable({
  providedIn: 'root',
})
export class ManageUserProfilesGuard extends PermissionBasedGuard {
  constructor(public  permissionCheckService: PermissionCheckService, public router: Router) {
    super(permissionCheckService, router, 'manageUserProfiles');
  }

}
