import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { InvoiceCreditDetailsConnector } from '../../occ/connectors/invoice-credit-details.connector';
import { InvoiceCreditDetailsActions } from '../actions';
import { InvoicesCreditDetailsResponse } from '../../model/invoices-credits.model';
import { ErrorType } from '@shared/models/ErrorTypes';
import { Injectable } from '@angular/core';

@Injectable()
export class InvoiceCreditDetailsEffect {
  loadInvoiceDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InvoiceCreditDetailsActions.loadInvoiceCreditDetails),
      concatMap((action) =>
        this.connector.loadInvoiceDetails(action.documentNumber).pipe(
          map((invoiceDetailsResponse: InvoicesCreditDetailsResponse) => {
            return InvoiceCreditDetailsActions.loadInvoiceCreditDetailsSuccess({ invoiceDetailsResponse });
          }),
          catchError((error: ErrorType) => {
            return of(InvoiceCreditDetailsActions.loadInvoiceCreditDetailsFaillure({ errorResponse: error }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: InvoiceCreditDetailsConnector,
  ) {
  }
}
