import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot([], {
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledBlocking'
})
  ]
})
export class AppRoutingModule { }
