import {Injectable} from '@angular/core';
import {RoutingService} from '@spartacus/core';
import {Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {Category} from '../model/category.model';
import {select, Store} from '@ngrx/store';
import * as CategoryActions from '../store/category.actions';
import * as fromCategory from '../store/category.state';

@Injectable({
  providedIn: 'root',
})
export class CurrentCategoryService {
  get(): Observable<Category | null> {
    return this.routingService.getRouterState().pipe(
      map((state) => state.state.params['categoryCode']),
      switchMap((categoryCode: string) => {
        return categoryCode ? this.getCategoryInternal(categoryCode) : of(undefined);
      }),
      filter((x) => x !== undefined),
      distinctUntilChanged()
    );
  }

  constructor(protected routingService: RoutingService, private store: Store) {
  }

  private getCategoryInternal(categoryCode: string): Observable<any> {
    return this.store.pipe(
      select(fromCategory.selectCategory, {code: categoryCode}),
      tap((category) => {
        if (category === undefined) {
          this.load(categoryCode);
        }
      })
    );
  }

  /**
   * Loads the user's details
   */
  load(categoryCode: string): void {
    this.store.dispatch(CategoryActions.LOAD_CATEGORY({payload: categoryCode}));
  }
}
