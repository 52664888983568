import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';

import { OrderCancelAdapter } from '../order-cancel.adapter';
import { OrderCancelResponse } from '../../../model/order-cancel.model';
import { CancellationRequestEntryInputList } from '@spartacus/order/root';

@Injectable({providedIn: 'root'})
export class OccOrderCancelAdapter implements OrderCancelAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  doOrderCancellation(orderNumber: string, positions: CancellationRequestEntryInputList): Observable<OrderCancelResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpoints.buildUrl(
      'orderCancellation',
      {
        urlParams: {
          userId: 'current',
          code: orderNumber
        }
      }
    );
    return this.http.post<OrderCancelResponse>(url, positions, {headers});
  }
}
