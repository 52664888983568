import {HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BadRequestHandler, GlobalMessageType, RoutingService} from '@spartacus/core';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

const OAUTH_ENDPOINT = '/authorizationserver/oauth/token';

@Injectable({
  providedIn: 'root',
})
export class EfaBadRequestHandler extends BadRequestHandler {
  constructor(protected globalMessageService: EfaGlobalMessageService, private routingService: RoutingService) {
    super(globalMessageService);
  }

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handleLoginDeactivated(request, response);
    this.handleB2BUnitInactive(request, response);
    this.handlePlaceOrderTimeoutError(request, response);
    this.handlePlaceOrderDuplicateError(request, response);
    this.handlePlaceOrderUnknownError(request, response);
    this.handleCalculationError(request, response);
    super.handleError(request, response);
  }

  protected handleLoginDeactivated(
    request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    if (
      response.url?.includes(OAUTH_ENDPOINT) &&
      response.error?.error === 'login_deactivated' &&
      request.body?.get('grant_type') === 'password'
    ) {
      this.globalMessageService.add(
        {
          key: 'efaHttpHandlers.loginDeactivated',
          params: {
            errorMessage:
              response.error.error_description || response.message || '',
            href: 'mailTo:kundencenter@autoteilewelt.de',
            anchorText: 'kundencenter@autoteilewelt.de',
            hideCloseButton: true,
            styleClass: 'staticInfoMessage'
          },
        },
        GlobalMessageType.MSG_TYPE_ERROR
      );
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);
    }
  }

  protected handleB2BUnitInactive(
    request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    if (
      response.url?.includes(OAUTH_ENDPOINT) &&
      response.error?.error === 'b2bunit_inactive' &&
      request.body?.get('grant_type') === 'password'
    ) {
      this.globalMessageService.add(
        {
          key: 'efaHttpHandlers.b2bUnitInactive',
          params: {
            errorMessage:
              response.error.error_description || response.message || '',
            href: 'mailTo:Debitoren@autoteilewelt.de',
            anchorText: 'Debitoren@autoteilewelt.de',
            hideCloseButton: true,
            styleClass: 'staticInfoMessage'
          },
        },
        GlobalMessageType.MSG_TYPE_ERROR
      );
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);
    }
  }

  private handleCalculationError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handlePlaceOrderError(request, response, 'CalculationError', 'efaHttpHandlers.calculationError');
  }

  private handlePlaceOrderTimeoutError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handlePlaceOrderError(request, response, 'PlaceOrderTimeoutError', 'efaHttpHandlers.placeOrderTimeoutError');
  }

  private handlePlaceOrderError(request: HttpRequest<any>,
                                response: HttpErrorResponse,
                                errorType: string,
                                messageKey: string,
                                redirectUrl?: string): void {
    if (response && response.error && response.error.errors && response.error.errors[0] && response.error.errors[0]?.type === errorType) {
      this.globalMessageService.add(
        {
          key: messageKey
        },
        GlobalMessageType.MSG_TYPE_ERROR
      );
      if (redirectUrl) {
        this.routingService.go(redirectUrl, {
          state: {
            messageTypesForRemoval: [GlobalMessageType.MSG_TYPE_WARNING,
              GlobalMessageType.MSG_TYPE_CONFIRMATION,
              GlobalMessageType.MSG_TYPE_INFO]
          }
        });
      }
    }
  }

  private handlePlaceOrderDuplicateError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handlePlaceOrderError(request, response, 'PlaceOrderDuplicateError', 'efaHttpHandlers.placeOrderDuplicateError', '/');
  }

  private handlePlaceOrderUnknownError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handlePlaceOrderError(request, response, 'PlaceOrderUnknownError', 'checkoutOrder.globalMessage.placeOrderFailed');
  }
}
