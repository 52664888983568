import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {
  AuthConfigService,
  ConfigModule,
  GlobalMessageService,
  I18nModule,
  NotAuthGuard,
  RoutingService,
  UrlModule
} from '@spartacus/core';
import {FormErrorsModule, IconModule, SpinnerModule} from '@spartacus/storefront';
import {
  ForgotPasswordComponentService,
  UpdatePasswordComponentService
} from '@spartacus/user/profile/components';
import {
  EfaResetPasswordComponent
} from './components/efa-reset-password/efa-reset-password.component';
import {
  EfaUpdatePasswordComponent
} from './components/efa-update-password/efa-update-password.component';
import {EfaUpdatePasswordComponentService} from './services/efa-update-password-component.service';
import {UserPasswordFacade} from "@spartacus/user/profile/root";
import {
  EfaForgotPasswordComponent
} from "./components/efa-forgot-password/efa-forgot-password.component";
import {
  EfaForgotPasswordComponentService
} from "./services/efa-forgot-password-component.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    UrlModule,
    RouterModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        UpdatePasswordComponent: {
          component: EfaUpdatePasswordComponent,
          providers: [
            {
              provide: UpdatePasswordComponentService,
              useClass: EfaUpdatePasswordComponentService,
            },
          ],
        },
        ResetPasswordComponent: {
          component: EfaResetPasswordComponent
        },
        ForgotPasswordComponent: {
          component: EfaForgotPasswordComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: ForgotPasswordComponentService,
              useClass: EfaForgotPasswordComponentService,
              deps: [
                UserPasswordFacade,
                RoutingService,
                AuthConfigService,
                GlobalMessageService,
              ],
            },
          ],
        },
      }
    })
  ],
  declarations: [EfaUpdatePasswordComponent, EfaResetPasswordComponent],
})
export class UpdatePasswordModule {
}
