<ng-template #details>
  <div class="popover-details">
    <div class="property">
      <label>{{ 'orgUser.name' | cxTranslate }}</label>
      <a
        class="value"
        [routerLink]="
          {
            cxRoute: 'orgUserDetails',
            params: model
          } | cxUrl
        "
      >{{ model['name'] }}</a
      >
    </div>

    <div class="property full-width">
      <label>{{ 'orgUser.uid' | cxTranslate }}</label>
      <span class="value">
        {{ model.email }}
      </span>
    </div>
  </div>
</ng-template>

<button
  class="button text"

>
  {{ model?.name }}
</button>
<button
      class="info-btn"
      [cxPopover]="details"
      [cxPopoverOptions]="{
        placement: 'auto',
        class: 'my-company-popover',
        appendToBody: true,
        displayCloseButton: true
      }"
      >
</button>
