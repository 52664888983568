import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { RegistrationInformationMultiOptionsFieldsValuesResponse } from '../model/login-register.model';
import { RegistrationInformationMultiOptionFieldsValuesActions } from '../store/actions';
import * as RegistrationInformationMultiOptionFieldsValuesFeature from '../store/reducers/registration-information-multi-option-fields-values.reducer';
import * as RegistrationInformationMultiOptionFieldsValuesSelectors from '../store/selectors/registration-information-multi-option-fields-values.selectors';

@Injectable({ providedIn: 'root' })
export class RegistrationInformationMultiOptionFieldsValuesService {

  constructor(protected store: Store<RegistrationInformationMultiOptionFieldsValuesFeature.RegistrationInformationMultiOptionFieldsValuesState>) {}

  loadRegistrationInformationMultiOptionFieldsValues(): void {
    return this.store.dispatch(RegistrationInformationMultiOptionFieldsValuesActions.loadRegistrationInformationMultiOptionsFieldsValues());
  }

  isLoading(): Observable<boolean> {
    return this.store.select(RegistrationInformationMultiOptionFieldsValuesSelectors.selectLoading);
  }

  getResponse(): Observable<RegistrationInformationMultiOptionsFieldsValuesResponse> {
    return this.store.select(RegistrationInformationMultiOptionFieldsValuesSelectors.selectResponse);
  }
}
