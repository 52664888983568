import { KeyValue } from '@angular/common';
import { CxEvent, CmsComponent, CmsLinkComponent } from '@spartacus/core';
import { RegistrationStatus } from './login-register-enum.model';

export interface RegistrationData {
  companyName?: string;
  genderCode: string;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  postalCode: string;
  town: string;
  countryIsocode: string;
  phone: string;
  salesTaxId?: string;
  incomeTaxId: string;
  email: string;
  newsletter: boolean;
  termsAndConditions: boolean;
  privacyProtection: boolean;
  languageIsocode: string;
  documents: RegistrationDocument[];
  roleCode: string;
  noxDepotAvailable: boolean;
  noxCustomerNumber: string;
  newsletterEmail: string;
  businessTypeCode: string;
  specializationCodes: string[];
  membership: string;
  membershipId: string;
  sepaBank: string;
  sepaIban: string;
  sepaBic: string;
  sepaEmail: string;
  deliveryCosts: boolean;
  deliveryPerNoxNightExpress: boolean;
  contactPersons: ContactPerson[];
  emailOrderConfirmation?: string;
  emailDeliveryNoteForwarding?: string;
  emailInvoiceForwarding?: string;
  attentionReasonCodes: string[];
  attentionReasonInfos: KeyValue<string, string>[];
  currentIndex: number;
  routeName: string;
  processId: string;
  status: RegistrationStatus;
}

export interface RegistrationInformationMultiOptionsFieldsValuesResponse {
  registrationRoles: RegistrationInformationEnumOptionTypeResponse[];
  businessTypes: RegistrationInformationEnumOptionTypeResponse[];
  registrationSpecificBusinessTypes: RegistrationInformationEnumOptionTypeResponse[];
  contactPersonPositions: RegistrationInformationEnumOptionTypeResponse[];
  salutations: RegistrationInformationEnumOptionTypeResponse[];
  attentionReasons: RegistrationInformationAttentionReasonResponse[];
  registrationDocumentTypes: RegistrationInformationRegistrationDocumentTypeResponse[];
  registrationSteps: RegistrationStepResponse[];
}

export interface RegistrationInformationEnumOptionTypeResponse {
  code: string;
  name: string;
}

export interface RegistrationInformationAttentionReasonResponse {
  code: string;
  name: string;
  additionalInfo: boolean;
  additionalInfoName: string;
}

export interface RegistrationInformationRegistrationDocumentTypeResponse {
  code: string;
  name: string;
  required: boolean;
}

export interface RegistrationStepResponse {
  index: number;
  title: string;
  routeName: string;
}

export interface ContactPerson {
  positionCode: string;
  salutationCode: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface RegistrationDocument {
  typeId: string;
  encodedContent: string;
  lastModified: number;
  fileName: string;
}

export interface RegistrationStartComponentData extends CmsComponent {
  text1: string;
  text2: string;
  downloadLinks: CmsLinkComponent[];
  downloadLinksSortOrder: string;
  displayButton: boolean;
}

export interface RegistrationStepData extends CmsComponent {
  title?: string;
  stepIndex?: number;
  routeName?: string; 
}

declare module '@spartacus/core' {
  export interface OccEndpoints {
    registrationStart?: string;
    registrationLoad?: string;
    registrationConfirmation?: string;
    registrationInformationMultiOptionsFieldsValues?: string;
  }

  export interface B2BUser {
    termsAccepted?: boolean;
  }

  export interface CmsLinkComponent {
    url?: string;
    linkName?: string;
  }

  export interface Title {
    code?: string;
    name?: string;
  }

  export interface Country {
    isocode?: string;
    name?: string;
  }
}

export class TermsConfirmationEvent extends CxEvent {
  termsAccepted: boolean;

  constructor(termsAccepted: boolean) {
    super();
    this.termsAccepted = termsAccepted;
  }
}

export interface ForgotPasswordComponentData extends CmsComponent {
  activateAccount: boolean;
}

export interface RegistrationStepData extends CmsComponent {
  title?: string;
  stepIndex?: number;
  routeName?: string;
}
