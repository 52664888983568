import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PaginationModel, SortModel } from '@spartacus/core';

import * as InvoiceCreditUiFeature from '../reducers/invoice-credit-ui.reducers';
import { getInvoiceCreditState, InvoiceCreditState } from '../reducers';
import { InvoiceCreditOverviewFilter } from '../../model/invoices-credits.model';
import { INVOICE_CREDIT_UI_FEATURE_KEY } from '../reducers/invoice-credit-ui.reducers';


export const selectFilterEntity: MemoizedSelector<InvoiceCreditUiFeature.InvoiceCreditUiState, InvoiceCreditOverviewFilter>
  = createSelector(
  getInvoiceCreditState,
  (state: InvoiceCreditState) => state[INVOICE_CREDIT_UI_FEATURE_KEY].selectedFilterEntity
);

export const selectPagination: MemoizedSelector<InvoiceCreditUiFeature.InvoiceCreditUiState, PaginationModel> = createSelector(
  getInvoiceCreditState,
  (state: InvoiceCreditState) => state[INVOICE_CREDIT_UI_FEATURE_KEY].selectedPagination
);


export const selectSort: MemoizedSelector<InvoiceCreditUiFeature.InvoiceCreditUiState, SortModel> = createSelector(
  getInvoiceCreditState,
  (state: InvoiceCreditState) => state[INVOICE_CREDIT_UI_FEATURE_KEY].selectedSort
);
