import { Injectable } from '@angular/core';
import {
  ConsentService,
  CxEvent,
  ScriptLoader,
  WindowRef,
} from '@spartacus/core';
import {
  TmsCollector,
  TmsCollectorConfig,
  WindowObject,
} from '@spartacus/tracking/tms/core';
import { GtmCollectorConfig } from '@spartacus/tracking/tms/gtm/config/default-gtm.config';
import { filter, first } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EfaGaCollectorService implements TmsCollector {
  constructor(
    protected winRef: WindowRef,
    protected consentService: ConsentService,
    protected scriptLoader: ScriptLoader
  ) {}

  /**
   * If the `TmsCollectorConfig.dataLayerProperty` is not specified, it uses the default `dataLayer`
   */
  init(config: GtmCollectorConfig, windowObject: WindowObject): void {
    this.consentService
      .checkConsentGivenByTemplateId('003-GOOGLE_TRACKING')
      .pipe(filter(Boolean), first())
      .subscribe(() => {
        const dataLayerProperty = config.dataLayerProperty ?? 'dataLayer';
        windowObject[dataLayerProperty] = windowObject[dataLayerProperty] ?? [];
        this.scriptLoader.embedScript({
          src: 'https://www.googletagmanager.com/gtag/js?id=G-TWS2PRMKZE',
        });
        this.scriptLoader.embedScript({
          src: './assets/scripts/google-analytics-initialization.js',
        });
      });
  }

  pushEvent<T extends CxEvent>(
    config: TmsCollectorConfig,
    windowObject: WindowObject,
    event: T | any
  ): void {
    this.consentService
      .checkConsentGivenByTemplateId('003-GOOGLE_TRACKING')
      .pipe(first())
      .subscribe((given: boolean) => {
        if (given) {
          const dataLayerProperty = config.dataLayerProperty ?? 'dataLayer';
          windowObject[dataLayerProperty].push(event);
        }
      });
  }
}
