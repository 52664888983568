import { createReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { RegistrationData } from '../../model/login-register.model';
import { RegistrationLoadActions } from '../actions';

export const REGISTRATION_LOAD_FEATURE_KEY = 'registrationLoad';

export interface RegistrationLoadState {
  processId: string;
  loading: boolean;
  success: boolean;
  error: ErrorType;
  response: RegistrationData;
}

export const initialStateRegistrationLoad: RegistrationLoadState = {
  processId: undefined,
  loading: false,
  success: false,
  error: undefined,
  response: undefined,
};

export const registrationLoadReducer = createReducer(
  initialStateRegistrationLoad,

  on(RegistrationLoadActions.loadRegistrationData, (state, action) => ({
    ...state,
    processId: action.processId,
    loading: true,
    success: false,
    response: undefined,
  })),
  on(RegistrationLoadActions.loadRegistrationDataSuccess, (state, action) => ({
    ...state,
    loading: false,
    success: true,
    response: action.response,
  })),
  on(RegistrationLoadActions.loadRegistrationDataFailure, (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.errorResponse
  })),
);

