import * as OrderCancelReducers from './order-cancel.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export const orderCancellationFeatureKey = 'orderCancellation';

export interface OrderCancellationState {
  [OrderCancelReducers.ORDER_CANCEL_FEATURE_KEY]: OrderCancelReducers.OrderCancelState;
}

export const reducers: ActionReducerMap<OrderCancellationState> = {
  [OrderCancelReducers.ORDER_CANCEL_FEATURE_KEY]: OrderCancelReducers.OrderCancelReducer,
};

export const getOrderCancelState = createFeatureSelector<OrderCancellationState>(orderCancellationFeatureKey);
