import {Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ForgotPasswordComponentData} from '../../../login-register/model/login-register.model';
import {
  ForgotPasswordComponent,
  ForgotPasswordComponentService,
} from '@spartacus/user/profile/components';

@Component({
  selector: 'app-efa-forgot-password',
  templateUrl: './efa-forgot-password.component.html',
})
export class EfaForgotPasswordComponent extends ForgotPasswordComponent {
  constructor(
    public component: CmsComponentData<ForgotPasswordComponentData>,
    protected service: ForgotPasswordComponentService
  ) {
    super(service);
  }
}
