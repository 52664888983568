import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule, OccConfig} from '@spartacus/core';
import {IconModule, MediaModule, SpinnerModule} from '@spartacus/storefront';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TilesCmsContainerComponent} from './components/tiles-cms-container/tiles-cms-container.component';
import {TileCmsItemComponent} from './components/tile-cms-item/tile-cms-item.component';
import {contentTilesTranslationChunksConfig, contentTilesTranslations} from './translations/content-tiles.translations';
import {TileLinkComponent} from './components/tile-link/tile-link.component';
import {effects} from './store/effects';
import {reducers, TOKEN_GENERATION_INDEX_FEATURE_KEY} from './store/reducers';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {OccModule} from './occ/occ.module';
import {PneuConfiguratorComponent} from './components/pneu-configurator/pneu-configurator.component';
import {SharedModules} from '@shared/shared.modules';
import {ConfiguratorCartComponent} from './components/configurator-cart/configurator-cart.component';
import {ImageTextComponent} from './components/image-text/image-text.component';
import { TysysCatalogComponent } from './components/tysys-catalog/tysys-catalog.component';

@NgModule({
  declarations: [
    TilesCmsContainerComponent,
    TileCmsItemComponent,
    TileLinkComponent,
    PneuConfiguratorComponent,
    ConfiguratorCartComponent,
    ImageTextComponent,
    TysysCatalogComponent,
  ],
  imports: [
    ConfigModule.withConfig({
      i18n:
          {
            resources: contentTilesTranslations,
            chunks: contentTilesTranslationChunksConfig
          },
      cmsComponents: {
        TilesContainerCmsComponent: {
          component: TilesCmsContainerComponent,
        },
        ConfiguratorCartComponent: {
          component: ConfiguratorCartComponent,
        },
        ImageTextComponent: {
          component: ImageTextComponent,
        },
      },
    } as CmsConfig),
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            tokenGeneration: 'users/${userId}/createToken?tokenName=${tokenName}&saveToken=${saveToken}',
          },
        },
      },
    } as OccConfig),
    OccModule,
    CommonModule,
    MediaModule,
    RouterModule,
    I18nModule,
    SpinnerModule,
    SharedModules,
    StoreModule.forFeature(TOKEN_GENERATION_INDEX_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
    IconModule
  ],
})

export class ContentTilesModule {
}
