import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CartImportExportService } from '../../services/cart-import-export-service';
import { FocusConfig, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'app-cart-export',
  templateUrl: './cart-export.component.html'
})
export class CartExportComponent implements OnInit, OnDestroy {

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: true,
    focusOnEscape: true,
  };

  constructor(
    protected exportService: CartImportExportService,
    protected launchDialogService: LaunchDialogService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  setting = {
    element: {
      dynamicDownload: null as HTMLElement,
    },
  };

  subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(
      this.exportService.getExporting().pipe(filter((isExporting: boolean) => isExporting),
        switchMap(() => this.exportService.getExportFilename()), switchMap((filename: string) => this.exportService
          .exportCart(filename).pipe(map((csv: string) => [filename, csv]))), 
        tap(([filename, csv]) => this.download(filename, csv)), tap(() => this.exportService.setExporting(false))).subscribe()
    );
  }

  export(): void {
    this.displayExportDialog();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private download(fileName: string, text: string): void {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = this.document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = 'text/csv';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', fileName);
    const event = new MouseEvent('click');
    element.dispatchEvent(event);
  }

  private displayExportDialog(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CART_EXPORT, undefined, undefined);
  }
}
