import {Injectable} from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PermissionCheckService} from '../service/permission-check.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionBasedGuard  {

  protected permissionId: string;

  constructor(public  permissionCheckService: PermissionCheckService, public router: Router, protected id: string) {
    this.permissionId = id;
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.permissionCheckService.isAllowed(this.permissionId).pipe(
      map((allowed) => {
        return allowed ? true : this.router.createUrlTree(['/not-found']);
      }))
  }
}
