import {InjectionToken, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfigModule, Converter, Product, UrlModule} from '@spartacus/core';
import {ProductPriceRequest} from './model/product-prices.model';
import {StoreModule} from '@ngrx/store';
import {ProductPricesSerializer} from './occ/product-prices.serializer';
import {EfaProductReferencesComponent} from './components/efa-product-references/efa-product-references.component';
import {EfaProductCarouselComponent} from './components/efa-product-carousel/efa-product-carousel.component';
import {RouterModule} from '@angular/router';
import {CarouselModule, MediaModule} from '@spartacus/storefront';
import {EffectsModule} from '@ngrx/effects';
import {productPricesFeatureKey, reducer} from './store/product-prices.reducer';
import {ProductPricesEffects} from './store/product-prices.effects';
import { OccModule } from './occ/occ.module';
import { SharedModules } from '@shared/shared.modules';


export const PRODUCT_PRICES_SERIALIZER = new InjectionToken<Converter<Product[], ProductPriceRequest>>('ProductPricesSerializer');

@NgModule({
  imports: [
    CommonModule,
    SharedModules,
    UrlModule,
    RouterModule,
    CarouselModule,
    MediaModule,
    StoreModule.forFeature(productPricesFeatureKey, reducer),
    EffectsModule.forFeature([ProductPricesEffects]),
    OccModule,
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            productPrices: '/users/${userId}/retrieveProductPrices?fields=DEFAULT',
          },
        },
      },
      cmsComponents: {
        ProductReferencesComponent: {
          component: EfaProductReferencesComponent,
        },
        ProductCarouselComponent: {
          component: EfaProductCarouselComponent,
        },

      }
    })
  ],
  providers: [
    {
      provide: PRODUCT_PRICES_SERIALIZER,
      useExisting: ProductPricesSerializer,
      multi: true,
    },
  ],
  declarations: [
    EfaProductReferencesComponent,
    EfaProductCarouselComponent
  ],
  exports: [
    EfaProductReferencesComponent,
    EfaProductCarouselComponent
  ]
})
export class ProductPricesModule {
}

