import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InterceptorUtil, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';

import { RegistrationData } from '../../../model/login-register.model';
import { RegistrationStartAdapter } from '../registration-start.adapter';

@Injectable({ providedIn: 'root' })
export class OccRegistrationStartAdapter implements RegistrationStartAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  protected getHeaders(userId: string): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    return headers;
  }

  public loadRegistrationStart(request: RegistrationData): Observable<void> {
    const url = this.occEndpoints.buildUrl(
      'registrationStart',
      {
        urlParams: {
          userId: 'current'
        }
      }
    );
    const headers = this.getHeaders('current');

    return this.http.post<void>(url, request, { headers });
  }
}
