import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModules } from '@shared/shared.modules';
import { I18nModule } from '@spartacus/core';
import { ConfigModule, OccConfig } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { DefaultManufacturersComponent } from './components/default-manufacturers/default-manufacturers.component';
import { OccModule } from './occ/occ.module';
import { effects } from './store/effects';
import {
  DEFAULT_MANUFACTURERS_INDEX_FEATURE_KEY,
  reducers,
} from './store/reducers';
import {
  defaultManufacturersTranslationChunksConfig,
  defaultManufacturersTranslations,
} from './translations/default-manufacturers.translations';

@NgModule({
  declarations: [DefaultManufacturersComponent],
  imports: [
    CommonModule,
    OccModule,
    FormsModule,
    SharedModules,
    I18nModule,
    SpinnerModule,
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            defaultManufacturers: 'users/${userId}/defaultManufacturers?defaultManufacturers=${defaultManufacturers}',
          },
        },
      },
    } as OccConfig),
    ConfigModule.withConfig({
      i18n: {
        resources: defaultManufacturersTranslations,
        chunks: defaultManufacturersTranslationChunksConfig,
      },
    }),
    ConfigModule.withConfig({
      cmsComponents: {
        DefaultManufacturersComponent: {
          component: DefaultManufacturersComponent,
        },
      },
    }),
    StoreModule.forFeature(DEFAULT_MANUFACTURERS_INDEX_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
  ],
})
export class ManufacturersSelectionModule {}
