export enum WarrantyProcessDocumentType {
  INSTALLATIONINVOICE = 'INSTALLATIONINVOICE',
  DELIVERYINVOICE = 'DELIVERYINVOICE',
  ADDITIONAL = 'ADDITIONAL'
}

export interface WarrantyProcessDocument {
  encodedContent: string;
  fileName: string;
  type: WarrantyProcessDocumentType;
}

export interface WarrantyProcessRequest {
  oemNumber: string;
  articleName: string;
  manufacturer: string;
  registrationDate: string;
  mileage: number;
  installationDate: string;
  installationMileage: string;
  customerComplaint: string;
  descriptionOfCause: string;
  documents: WarrantyProcessDocument[];
}
