<div class="c-review">
  <div class="c-review__container row">
    <div class="c-review__summary-card col-xs-12 col-sm-12 col-md-7 ">
      <!-- DELIVERY NOTE NUMBER SECTION -->
      <div class="delivery-note-number">
        <cx-card *ngIf="deliveryNoteNumber"
               [content]="getDeliveryNoteNumber() | async">
       </cx-card>
      </div>

        <!-- DELIVERY DATE SECTION -->
        <div class="date-section">
          <cx-card *ngIf="creationDate"
          [content]="getCreationDate() | async">
          </cx-card>
        </div>

        <!-- WAREHOUSE NAME SECTION -->
        <div class="name-section">
          <cx-card *ngIf="warehouseName"
          [content]="getWarehouseName() | async">
          </cx-card>
        </div>

    </div>
    <div class="c-review__summary-card col-xs-12 col-sm-12 col-md-5 address-section">
      <!-- WAREHOUSE NAME SECTION -->
      <cx-card *ngIf="receiverAddress"
               [content]="getReceiverAddress() | async">
      </cx-card>
    </div>
  </div>
</div>
