<div
  class="cx-modal-container cart-delete-modal modal-dialog-centered"
  [cxFocus]="focusConfig"
  (esc)="closeModal('closed')"
>

    <div class="cx-modal-content">

        <div class="cx-dialog-header modal-header">
            <h3>{{ 'cartAdministration.cart.deleteDialog.header' | cxTranslate }}</h3>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal('closed')">
                <span aria-hidden="true"><i class="fa fa-times"></i></span>
            </button>
        </div>

        <div class="cx-dialog-content modal-body">
            <div class="row">
                <p class="col-12">
                    {{ 'cartAdministration.cart.deleteDialog.infoText' | cxTranslate }}
                </p>
            </div>
        </div>

        <div class="cx-dialog-footer modal-footer">
            <button class="btn btn-secondary" type="button" (click)="delete()">
                {{ 'cartAdministration.cart.deleteDialog.buttonConfirm' | cxTranslate }}
            </button>
            <button class="btn btn-primary" type="button" (click)="closeModal('closed')">
                {{ 'cartAdministration.cart.deleteDialog.buttonClose' | cxTranslate }}
            </button>
        </div>

    </div>
</div>
