
<div class="row justify-content-center" id="partsLinkAccessForm" >
  <div class="col-md-8">
    <div class="form-header">
      <p>{{ 'partsLinkAccess.partsLinkAccessForm.formTitle' | cxTranslate }}</p>
    </div>
    <p>{{ 'partsLinkAccess.partsLinkAccessForm.formMandatoryFields' | cxTranslate }}</p>
    <div class="line1"></div>
    <form (ngSubmit)="onSubmit()" [formGroup]="partsLinkAccessForm" >
      <fieldset formGroupName = "dealerData">
        <legend class="tiresWarranty_fieldset-legend">
          {{ 'partsLinkAccess.partsLinkAccessForm.legend1' | cxTranslate }}
        </legend>
        <div class="form-group">
          <label for="customerNumber">{{'partsLinkAccess.partsLinkAccessForm.dealerData.customerNumber.lable' | cxTranslate }}*</label>
          <input type="text"
                 id="customerNumber"
                 formControlName = "customerNumber"
                 placeholder="{{'partsLinkAccess.partsLinkAccessForm.dealerData.customerNumber.placeholder' | cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="partsLinkAccessForm.get('dealerData.customerNumber')"></cx-form-errors>
        </div>
        <div class="form-group">
          <label for="companyName">{{ 'partsLinkAccess.partsLinkAccessForm.dealerData.companyName.lable' | cxTranslate }}*</label>
          <input type="text"
                 id="companyName"
                 formControlName = "companyName"
                 placeholder="{{ 'partsLinkAccess.partsLinkAccessForm.dealerData.companyName.placeholder' | cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="partsLinkAccessForm.get('dealerData.companyName')"></cx-form-errors>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-9 form-group">
            <label for="street">{{'partsLinkAccess.partsLinkAccessForm.dealerData.street.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="street"
                   formControlName = "street"
                   placeholder="{{'partsLinkAccess.partsLinkAccessForm.dealerData.street.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="partsLinkAccessForm.get('dealerData.street')"></cx-form-errors>
          </div>
          <div class="col-sm-12 col-md-3 form-group">
            <label for="streetnumber">{{'partsLinkAccess.partsLinkAccessForm.dealerData.streetnumber.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="streetnumber"
                   formControlName = "streetnumber"
                   placeholder="{{'partsLinkAccess.partsLinkAccessForm.dealerData.streetnumber.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="partsLinkAccessForm.get('dealerData.streetnumber')"></cx-form-errors>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-dealer-data-country form-group select-arrow">
                <label for="countryIsoCode">{{'partsLinkAccess.partsLinkAccessForm.dealerData.countryIsoCode.lable' | cxTranslate }}*</label>
                <select formControlName="countryIsoCode" class="form-control" [ngClass]="getSelectPlaceholderTextClass(partsLinkAccessForm.get('dealerData.countryIsoCode').value)">
                  <option *ngFor="let country of countries$ | async"
                          [value]="country.isocode">
                    {{ country.isocode }}
                  </option>
                </select>
                <cx-form-errors [control]="partsLinkAccessForm.get('dealerData.countryIsoCode')"></cx-form-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-dealer-data-postal-code form-group">
                <label for="postalCode"> {{'partsLinkAccess.partsLinkAccessForm.dealerData.postalCode.placeholder' | cxTranslate }}*</label>
                <input type="text"
                      id="postalCode"
                      formControlName = "postalCode"
                      placeholder="{{'partsLinkAccess.partsLinkAccessForm.dealerData.postalCode.placeholder' | cxTranslate }}"
                      class="form-control"/>
                <cx-form-errors [control]="partsLinkAccessForm.get('dealerData.postalCode')"></cx-form-errors>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-8 form-group">
            <label for="town">{{'partsLinkAccess.partsLinkAccessForm.dealerData.town.lable' | cxTranslate }}*</label>
            <input type="text"
                  id="town"
                  formControlName = "town"
                  placeholder="{{'partsLinkAccess.partsLinkAccessForm.dealerData.town.placeholder' | cxTranslate }}"
                  class="form-control"/>
            <cx-form-errors [control]="partsLinkAccessForm.get('dealerData.town')"></cx-form-errors>
          </div>
        </div>

        <div class="form-group">
          <label for="email">{{'partsLinkAccess.partsLinkAccessForm.dealerData.email.placeholder' | cxTranslate }}*</label>
          <input type="text"
                 id="email"
                 formControlName = "email"
                 placeholder="{{'partsLinkAccess.partsLinkAccessForm.dealerData.email.placeholder' | cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="partsLinkAccessForm.get('dealerData.email')"></cx-form-errors>
        </div>
        </fieldset>
      <div class="line1"></div>

      <fieldset formGroupName = "customerData">
        <legend class="tiresWarranty_fieldset-legend">{{ 'partsLinkAccess.partsLinkAccessForm.legend2' | cxTranslate }}</legend>
        <div class="form-group">
          <span class="label-content">{{'partsLinkAccess.partsLinkAccessForm.customerData.titleCode.lable'| cxTranslate }}*</span>
          <div class="row">
            <div class="col-sm-3 col-md-2" *ngFor="let titleCode of titleCodes">
              <label class="form-check form-check-inline">
                <input type="radio"
                           formControlName="titleCode"
                           [value]="titleCode"/>
                           {{'partsLinkAccess.partsLinkAccessForm.customerData.titleCode.gender.' + titleCode | cxTranslate }}
              </label>
            </div>
          </div>
          <cx-form-errors [control]="partsLinkAccessForm.get('customerData.titleCode')"></cx-form-errors>
        </div>
        <div class="form-group">
          <label for="name">{{'partsLinkAccess.partsLinkAccessForm.customerData.name.lable'| cxTranslate }}*</label>
          <input type="text"
                 id="name"
                 formControlName = "name"
                 placeholder="{{'partsLinkAccess.partsLinkAccessForm.customerData.name.placeholder'| cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="partsLinkAccessForm.get('customerData.name')"></cx-form-errors>
        </div>
      </fieldset>
      <div class="col-12 justify-content-center d-flex partsLinkSubmit" >
        <button class="btn btn-primary btn-block"
                style="width: 70%;"
                [disabled]="!partsLinkAccessForm.valid">
          {{ 'partsLinkAccess.partsLinkAccessForm.submit' | cxTranslate }}
        </button>
      </div>
      <div class="col-12 justify-content-center d-flex partsLinkReset" >
        <button class="btn btn-secondery btn-block"
                style="width: 50%;"
                type="button"
                (click)="onReset()">
          {{ 'partsLinkAccess.partsLinkAccessForm.reset' | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</div>
