import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-cart-actions',
  templateUrl: './cart-actions.component.html'
})
export class CartActionsComponent implements OnInit, OnDestroy {
  public hasEntries = false;
  public internal = true;
  public skipAutomaticCalculation = false;

  private subscription: Subscription = new Subscription();

  constructor(private activeCartService: ActiveCartFacade) {
  }

  ngOnInit(): void {
    this.subscription.add(this.activeCartService.getEntries().pipe(map(entries => entries.length > 0)).subscribe(b => this.hasEntries = b));
    this.subscription.add(this.activeCartService.getActive().pipe(map(cart => cart.fromConfigurator === true)).subscribe(b => this.internal = !b));
    this.subscription.add(this.activeCartService.getActive().pipe(map(cart => cart.skipAutomaticCalculation === true)).subscribe(b => this.skipAutomaticCalculation = b));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getStyle(): string {
    return this.hasEntries ? '' : 'cart-actions__margin-bottom';
  }
}
