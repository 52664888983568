<div class="cx-section">
  <ng-container *ngIf="(addresses$ | async).length; else noAddresses">

    <div class="row cx-address-deck"
         *ngIf="!(addressesStateLoading$ | async); else loading">
      <div *ngFor="let address of addresses$ | async"
           class="col-md-6 cx-address-card">
        <app-efa-card [border]="true"
                 [fitToContainer]="true"
                 [content]="getAddressCardContent(address) | async"
                 (editCard)="editAddressButtonHandle(address)"
                 (setDefaultCard)="setAddressAsDefault(address)"
                 (deleteCard)="setEdit(address.id)"
                 [editMode]="address.id === editCard"
                 (cancelCard)="cancelCard()"></app-efa-card>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="col-md-12 cx-address-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>

<ng-template #noAddresses>
  <div class="noAddresses">{{ 'efaAddressBook.noAddresses' | cxTranslate }}</div>
</ng-template>
