import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EfaBreadcrumbComponent} from './components/efa-breadcrumb/efa-breadcrumb.component';
import {RouterModule} from '@angular/router';
import {ConfigModule} from '@spartacus/core';


@NgModule({
  declarations: [
    EfaBreadcrumbComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BreadcrumbComponent: {
          component: EfaBreadcrumbComponent,
        },
      },
    }),
  ]
})
export class BreadcrumbModule { }
