<section class="c-cart-header">
  <ng-container *ngIf="cart$ | async as cart">
    <div ngbAccordion #acc="ngbAccordion">
      <div ngbAccordionItem="cartHeaderPanelOne" #cartHeaderPanelOne="ngbAccordionItem" [collapsed]="cart.entries?.length > 0">
        <div ngbAccordionHeader>
          <div class="justify-content-between">
            <h5 class="mb-0">
              <button ngbAccordionToggle class="btn btn-block">
                <div>
                  {{ 'cartAdministration.orderDataForm.formHeadline' | cxTranslate }}
                  <app-cart-header-summary *ngIf="!acc.isExpanded('cartHeaderPanelOne')"
                                           [selectedOrderType]="currentOrderType"
                                           [selectedShippingAddress]="selectedShippingAddress"
                                           [selectedShippingType]="selectedShippingType"
                                           [deliveryDate]="deliveryDate"
                                           [displayRequestedDeliveryDate]="displayRequestedDeliveryDate"></app-cart-header-summary>
                </div>
              </button>
            </h5>
          </div>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <form class="row" [formGroup]="orderDataForm">
                <div class="col-md-5">
                  <app-order-type-selection [selectedOrderType]="currentOrderType"
                                            myName="cartOrderTypeSelection"
                                            [isLogin]="false"
                                            [fromConfigurator]="cart.fromConfigurator"
                                            (orderTypeChanges)="orderTypeHasChanged($event)">
                  </app-order-type-selection>
                </div>
                <div class="col-md-7">
                  <label class="form-group">
                  <span
                    class="label-content">{{ 'cartAdministration.orderDataForm.deliveryAddress.label' | cxTranslate }}
                  </span>
                    <select class="form-control"
                            formControlName="deliveryAddress"
                            [(ngModel)]="selectedShippingAddress"
                            (ngModelChange)="setShippingAddress()">
                      <option *ngFor="let shippingAddress of shippingAddresses"
                              [ngValue]="shippingAddress">
                        {{ shippingAddress.line1 }} {{ shippingAddress.line2 }}
                        , {{ shippingAddress.postalCode }} {{ shippingAddress.town }}
                      </option>
                    </select>
                    <cx-form-errors [control]="orderDataForm.get('deliveryAddress')"></cx-form-errors>
                  </label>
                </div>
                <div class="col-md-5" *ngIf="displayRequestedDeliveryDate">
                  <label class="form-group">
                  <span class="label-content">
                    {{ 'cartAdministration.orderDataForm.deliveryDate.label' | cxTranslate }}
                  </span>
                    <div class="input-group">
                      <input class="form-control" ngbDatepicker #d="ngbDatepicker"
                             formControlName="deliveryDate"
                             [markDisabled]="isDisabled"
                             [(ngModel)]="deliveryDate"
                             (ngModelChange)="setRequestedDeliveryDate()"
                             [minDate]="minDate"
                             [readonly]="true"
                             efaNgbDatepickerNavigationTooltipsRemove
                             placeholder="{{ 'cartAdministration.orderDataForm.deliveryDate.placeholder' | cxTranslate }}"
                             container="body"/>
                      <div class="input-group-append">
                        <button class="btn btn-primary d-flex" (click)="d.toggle()"
                                type="button">
                          <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                        </button>
                      </div>
                    </div>
                  </label>
                </div>
                <div [class]="displayRequestedDeliveryDate ? 'col-md-7' : 'col-md-5'">
                  <label *ngIf="shippingTypes?.length > 0 && shippingTypes as shippingTypes"
                         class="form-group">
                  <span class="label-content">
                    {{ 'cartAdministration.orderDataForm.shippingType.label' | cxTranslate }}
                    <span class="label-shipping-type-hint" *ngIf="!cart.fromConfigurator">
                    {{ 'cartAdministration.orderDataForm.shippingType.hint' | cxTranslate }}
                  </span>
                  </span>
    
                    <select class="form-control"
                            formControlName="shippingType"
                            [(ngModel)]="selectedShippingType"
                            (ngModelChange)="setShippingTypeId()">
                      <option *ngFor="let shippingType of shippingTypes"
                              [ngValue]="shippingType">
                        {{ shippingType.name }}
                      </option>
                    </select>
                    <cx-form-errors [control]="orderDataForm.get('shippingType')"></cx-form-errors>
                  </label>
                </div>
              </form>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section>
