export const shippingBacklog = {
  shippingBacklog: {
    globalMessage: {
      loadingShippingBacklogOverviewFailure: 'Das Laden der Rückstandsanzeige schlug fehl.',
      noSelectedShippingBacklogItems: 'Es wurden keine Einträge zur Stornierung selektiert.',
      cancellingShippingBacklogItemsSuccess: 'Die Stornierungsanfrage wurde erfolgreich versendet.',
      cancellingShippingBacklogItemsFailure: 'Das Versenden der Stornierungsanfrage schlug fehl. Bitte versuchen Sie es erneut.',
    },
    shippingBacklogFilterForm: {
      oemNumber: {
        label: 'Artikelnummer',
        placeholder: 'Artikelnummer',
      },
      orderNumber: {
        label: 'Auftragsnummer',
        placeholder: 'Auftragsnummer',
      },
      consignmentInfo: {
        label: 'Ihre zusätzliche Information',
        placeholder: 'Ihre zusätzliche Information',
      },
      dateFrom: {
        label: 'Auftragsdatum von',
        placeholder: 'Auftragsdatum von',
      },
      dateTo: {
        label: 'Auftragsdatum bis',
        placeholder: 'Auftragsdatum bis',
      },
    },
    myAccount: {
      filter: 'Filter',
      sorting: 'Rückstandsanzeige sortieren',
      selectAll:'Alle auswählen',
    },
    shippingBacklogItem: {
      orderNumber: 'Auftragsnummer',
      orderPositionNumber: 'Positionsnr.',
      oemNumber: 'Artikelnummer',
      requestedQuantity: 'Bestellmenge',
      alreadyDeliveredQuantity: 'Gelieferte Menge',
      expectedDeliveryDate: 'Lieferdatum',
      status: 'Status',
      quantityToCancel: 'Stornierbare Menge',
    },
    requestCancellationButton: 'Stornierung anfragen',
  },
};
