import { Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { ImageTextComponentData } from '../../model/image-text.model';

@Component({
  selector: 'app-image-text',
  templateUrl: './image-text.component.html',
})
export class ImageTextComponent {
  constructor(public component: CmsComponentData<ImageTextComponentData>) {}

  round(n: number) {
    return Math.round(n);
  }
}
