import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CartExportComponent } from './components/cart-export/cart-export.component';
import { CartExportDialogComponent } from './components/cart-export-dialog/cart-export-dialog.component';

export const defaultCartExportLayoutConfig: LayoutConfig = {
    launch: {
       CART_EXPORT: {
          inlineRoot: true,
          component: CartExportDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
       },
    },
};
