import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AllowedFileExtensionsResponse } from "@shared/models/shared.model";
import { AllowedFileExtensionsAdapter } from "@shared/occ/adapters/allowed-file-extensions.adapter";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { AllowedImageExtensionsActions } from '../actions';

@Injectable()
export class AllowedImageExtensionsEffect {

  loadAllowedImageExtensions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AllowedImageExtensionsActions.loadAllowedImageExtensions),
      concatMap((action) =>
        this.connector.loadAllowedFileExtensions(action.contextId).pipe(
          map((response: AllowedFileExtensionsResponse) => {
            return AllowedImageExtensionsActions.loadAllowedImageExtensionsSuccess({ response });;
          }),
          catchError((error) => {
            return of(AllowedImageExtensionsActions.loadAllowedImageExtensionsFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: AllowedFileExtensionsAdapter,
  ) {

  }
}