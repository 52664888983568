import { Component, Inject, OnInit } from '@angular/core';
import { Config } from '@spartacus/core';
import { Level } from './display.config';

@Component({
  selector: 'app-config-debug',
  templateUrl: './config-debug.component.html',
})
export class ConfigDebugComponent implements OnInit {
  constructor(@Inject(Config) public config: any) {
  }

  public configAsJson: string;

  ngOnInit(): void {
    if (this.config.displayLevel === Level.CONSOLE) {
      console.log('App Configurations: ');
      console.log(this.config);
    }

    if (this.config.displayLevel === Level.SCREEN) {
      this.configAsJson = this.stringify(this.config, null, 2, null);
    }
  }

  stringify(obj, replacer, spaces, cycleReplacer) {
    return JSON.stringify(obj, this.serializer(replacer, cycleReplacer), spaces);
  }

  serializer(replacer, cycleReplacer) {
    const stack = [];
    const keys = [];

    if (cycleReplacer == null)
      cycleReplacer = function (key, value) {
        if (stack[0] === value) return '[Circular ~]';
        return '[Circular ~.' + keys.slice(0, stack.indexOf(value)).join('.') + ']';
      };

    return function (key, value) {
      if (stack.length > 0) {
        const thisPos = stack.indexOf(this);
        // tslint:disable-next-line:no-bitwise
        ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
        // tslint:disable-next-line:no-bitwise
        ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
        // tslint:disable-next-line:no-bitwise
        if (~stack.indexOf(value)) value = cycleReplacer.call(this, key, value);
      } else stack.push(value);

      return replacer == null ? value : replacer.call(this, key, value);
    };
  }
}
