import { createAction, props } from "@ngrx/store";
import { ErrorType } from "../../model/ErrorTypes";

export const setDefaultManufacturers = createAction(
  '[DefaultManufacturers] Set Default Manufacturers',
  props<{ defaultManufacturers: string }>()
);

export const setDefaultManufacturersSuccess = createAction(
  '[DefaultManufacturers] Set Default Manufacturers Success'
);

export const setDefaultManufacturersFailure = createAction(
  '[DefaultManufacturers] Set Default Manufacturers Failure',
  props<{ errorResponse: ErrorType }>()
);

