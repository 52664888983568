import {NgModule} from '@angular/core';

import {ConfigModule, I18nConfig} from '@spartacus/core';
import {ENGLISH_TRANSLATIONS, GERMAN_TRANSLATIONS} from './translations';

@NgModule({
  declarations: [],
  imports: [
    ConfigModule.withConfig({
      i18n: {
        resources: {
          ...ENGLISH_TRANSLATIONS,
          ...GERMAN_TRANSLATIONS,
        },
        chunks: {
          customCommon: ['uploadFile', 'uploadPdfFile', 'uploadImage', 'uploadFiles', 'clearForm', 'resetForm', 'tooltip', 'processNavigation', 'filteredDocumentsResult', 'noPriceToDisplayForProduct', 'globalMessage']
        }
      }
    } as I18nConfig),
  ]
})
export class CustomTranslationsModule {
}
