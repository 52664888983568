import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as SetCartCalculationModeFeature from '../reducers/set-cart-calculation-mode.reducer';
import { SET_CART_CALCULATION_MODE } from '../reducers/set-cart-calculation-mode.reducer';
import { CartAdministrationState, getCartAdministrationState } from '../reducers';

export const selectIsUpdating: MemoizedSelector<SetCartCalculationModeFeature.SetCartCalculationModeState, boolean> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[SET_CART_CALCULATION_MODE].updating
);

export const selectSuccess: MemoizedSelector<SetCartCalculationModeFeature.SetCartCalculationModeState, boolean> = createSelector(
  getCartAdministrationState,
  (state: CartAdministrationState) => state[SET_CART_CALCULATION_MODE].success
);