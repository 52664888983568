import {Injectable} from '@angular/core';
import {B2BUser, GlobalMessageType, RoutingService} from '@spartacus/core';
import {CurrentUserService, UserFormService, UserItemService} from '@spartacus/organization/administration/components';
import {B2BUserService, LoadStatus, OrganizationItemStatus} from '@spartacus/organization/administration/core';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';

@Injectable({
  providedIn: 'root'
})
export class EfaUserItemService extends UserItemService{

  constructor(protected currentItemService: CurrentUserService,
              protected routingService: RoutingService,
              protected formService: UserFormService,
              protected userService: B2BUserService,
              protected globalMessageService: EfaGlobalMessageService) {
      super(currentItemService, routingService, formService, userService);
  }

  protected create(
    value: B2BUser
  ): Observable<OrganizationItemStatus<B2BUser>> {
    const param = value;
    param.email = value.orgUnit.uid + '_' + value.email;
    this.userService.create(param);
    return this.key$.pipe(
      switchMap((key: string) =>
        this.userService.getLoadingStatus(key)
      ),
      tap((loadingStatus: OrganizationItemStatus<B2BUser>) => {
        if (loadingStatus.status === LoadStatus.SUCCESS) {
          this.globalMessageService.add(
            { key: 'orgUser.messages.create', params: { item: value } },
            GlobalMessageType.MSG_TYPE_INFO
          );
        }
      })
    );
  }

  update(code, value: B2BUser): Observable<OrganizationItemStatus<B2BUser>> {
    delete value.approvers;
    const param = value;
    if (!param.portalUser) {
      param.email = value.orgUnit.uid + '_' + value.email;
    }
    this.userService.update(code, param);
    return this.userService.getLoadingStatus(code);
  }
}
