import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-display-result-notice',
  templateUrl: './no-display-result-notice.component.html'
})
export class NoDisplayResultNoticeComponent {

  @Input() noticeHeadline: string;

}
