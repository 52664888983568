import { B2BUnit } from '@spartacus/core';

export interface ReturnReasonResponse {
  reasons: ReturnReason[];
}

export interface ReturnReason {
  code: string;
  name: string;
  mandatoryComment: boolean;
  neededForDeposits: boolean;
}
export interface ReturnCreationRequest {
  documentNumber: string;
  documentType: ReturnDocumentType;
  positions: ReturnPosition[];
}

export interface ReturnPositionResponseData {
  positionNumber: number;
  oemNumber: string;
  note: string;
}

export interface ReturnCreationResponse {
  returnNumbers: string[];
  rejectedPositions: ReturnPositionResponseData[];
}

export interface ReturnablePosition {
  positionNumber: number;
  name: string;
  oemNumber: string;
  deliveredQuantity: number;
  returnableQuantity: number;
  quantityToReturn: number;
  reasonCode: string;
  comment: string;
  containsDeposit: boolean;
  materialNumber:string;
  depositOnly: boolean;
}

export interface ReturnPosition {
  positionNumber: number;
  materialNumber: string;
  reasonCode: string;
  comment: string;
  quantity: number;
}

export enum ReturnDocumentType {
  Order = 'order',
  DeliveryNote = 'deliveryNote',
}

declare module '@spartacus/core' {
  interface OccEndpoints {
    returnReason?: string;
    returnCreation?: string;
  }

  interface B2BUnit {
    returnsDeduction?: boolean;
  }
}
