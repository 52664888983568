import { Component, OnDestroy, OnInit } from '@angular/core';
import { FocusConfig, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  CartModificationList,
  CartProductSearchResult,
  CartProductSearchResultItem
} from '../../../cart-administration/model/cart-administration.model';
import {
  MultiAddToCartService
} from '../../../cart-administration/services/multi-add-to-cart.service';
import {
  CartImportItem,
  CartImportResult,
  CartImportSearchError,
  CartImportSearchResponse
} from '../../model/import-export.model';
import { CartImportExportService } from '../../services/cart-import-export-service';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CART_IMPORT_SELECTION = 'CART_IMPORT_SELECTION',
  }
}

@Component({
  selector: 'app-cart-import-selection',
  templateUrl: './cart-import-selection.component.html'
})


export class CartImportSelectionComponent implements OnInit, OnDestroy {

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: true,
    focusOnEscape: true,
  };

  searchResponses: CartImportSearchResponse[];
  searchErrors: CartImportSearchError[];
  selectedResultsMap: Map<string, CartImportItem> = new Map<string, CartImportItem>();
  selectedResultItems: CartImportResult[] = [];
  importDone = false;
  nonUniqueResultItemsConstraintFulfilled = true;

  isSearchingOrImporting$ = this.importExportService.status$.pipe(
    map(status => status.searching || status.importing));
  importErrorOccurs = false;

  private subscription: Subscription = new Subscription();
  private nonUniqueResultsToNomSelectedMap: Map<string, number> = new Map<string, number>();
  private firstNonUniqueItemKey: string;

  constructor(
    private multiAddToCartService: MultiAddToCartService,
    private importExportService: CartImportExportService,
    private launchDialogService: LaunchDialogService
  ) {
  }

  setSearchResponses(responses: CartImportSearchResponse[]): void {
    this.searchResponses = responses;
    let index = 0;
    this.searchResponses.forEach(resp => {
      const result = resp.response.results[0];
      const substitutedItemKey = String(index) + resp.substitutedOemNumber;
      if (resp.response.results.length === 1) {
        const selectedItemKey = substitutedItemKey + String(result.id);
        this.selectedResultsMap.set(selectedItemKey, {
          result,
          substitutedOemNumber: resp.substitutedOemNumber,
          consignmentInfo: resp.consignmentInfo
        });
      } else {
        this.nonUniqueResultItemsConstraintFulfilled = false;
        this.nonUniqueResultsToNomSelectedMap.set(substitutedItemKey, 0);

        if (this.firstNonUniqueItemKey == null) {
          this.firstNonUniqueItemKey = substitutedItemKey;
        }
      }
      index = index + 1;
    });

  }

  ngOnInit(): void {
    this.multiAddToCartService.reset();
    this.subscription.add(this.multiAddToCartService.getModifications()
    .pipe(
      filter(Boolean))
    .subscribe((ml: CartModificationList) => {
      let i = 0;
      ml.cartModifications.forEach(m => this.selectedResultItems[i++].modification = m);
      this.importErrorOccurs = ml.cartModifications.find((m) => m.statusCode !== 'success') !== undefined;
      this.importExportService.setImportingStatusTo(false);
      this.importDone = true;
    }));
  }


  ngOnDestroy(): void {
    this.multiAddToCartService.reset();
    this.subscription.unsubscribe();
  }

  closeModal(): void {
    this.launchDialogService.closeDialog('closed');
  }

  changeProductSelection(response: CartImportSearchResponse, result: CartProductSearchResult, index: number, event): void {
    const substitutedItemKey = String(index) + response.substitutedOemNumber;
    const selectedItemKey = substitutedItemKey + String(result.id);
    if (event.target.checked) {

      this.selectedResultsMap.set(selectedItemKey, {
        result,
        substitutedOemNumber: response.substitutedOemNumber,
        consignmentInfo: response.consignmentInfo
      });

      if (this.nonUniqueResultsToNomSelectedMap.has(substitutedItemKey)) {
        this.nonUniqueResultsToNomSelectedMap.set(substitutedItemKey, this.nonUniqueResultsToNomSelectedMap.get(substitutedItemKey) + 1);
      }
    } else {
      this.selectedResultsMap.delete(selectedItemKey);

      if (this.nonUniqueResultsToNomSelectedMap.has(substitutedItemKey)) {
        this.nonUniqueResultsToNomSelectedMap.set(substitutedItemKey, this.nonUniqueResultsToNomSelectedMap.get(substitutedItemKey) - 1);
      }
    }

    let updatedNonUniqueItemsSelectedConstraintFulfilled = true;
    this.nonUniqueResultsToNomSelectedMap.forEach((value) => {
      if (value === 0) {
        updatedNonUniqueItemsSelectedConstraintFulfilled = false;
      }
    });

    this.nonUniqueResultItemsConstraintFulfilled = updatedNonUniqueItemsSelectedConstraintFulfilled;
  }

  startImport(): void {
    this.importExportService.setImportingStatusTo(true);
    const selectedResults = [];
    this.selectedResultItems = [];
    this.importErrorOccurs = false;
    Array.from(this.selectedResultsMap.keys()).forEach((key) => {
      const result = this.selectedResultsMap.get(key);
      selectedResults.push(result);
      result.result.resultItems.forEach(item => this.selectedResultItems.push({key: key + item.materialNumber}));

    });

    this.selectedResultsMap.clear();
    this.multiAddToCartService.addEntriesFromImport(selectedResults);
  }

  getBackgroundColor(responseInd: number,
                     searchResponse: CartImportSearchResponse,
                     result: CartProductSearchResult,
                     item: CartProductSearchResultItem):
    string {
    const keyCompare = String(responseInd) + searchResponse.substitutedOemNumber + String(result.id) + item.materialNumber;
    const resultFound: CartImportResult = this.selectedResultItems.find(resultItem =>
      resultItem.key === keyCompare);
    if (this.importDone) {
      if (resultFound !== undefined && resultFound.modification !== undefined) {
        if (resultFound.modification.statusCode === 'success') {
          return 'u-bg-success';
        } else {
          return 'u-bg-danger';
        }
      }
    } else {
      return searchResponse.response.results?.length > 1 ? 'u-bg-warning' : '';
    }
    return '';
  }

  getScroll(responseInd: number, substitutedOemNumber: string): boolean {
    return (String(responseInd) + substitutedOemNumber) === this.firstNonUniqueItemKey;
  }
}
