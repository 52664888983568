import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { RegistrationData } from '../../model/login-register.model';

import { RegistrationLoadAdapter } from '../adapters/registration-load.adapter';

@Injectable({ providedIn: 'root' })
export class RegistrationLoadConnector {
  constructor(protected adapter: RegistrationLoadAdapter){}

  loadRegistrationData(processId: string): Observable<RegistrationData> {
    return this.adapter.loadRegistrationData(processId);
  }
}
