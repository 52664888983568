import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { tap, withLatestFrom } from "rxjs/operators";
import { UpdateCartArg } from '../model/update-cart.model';
import { UpdateCartActions } from '../store/actions';
import * as UpdateCartFeature from '../store/reducers/update-cart.reducer';
import * as UpdateCartSelectors from '../store/selectors/update-cart.selectors';
import { CartParametersService } from "./cart-parameters.service";

@Injectable({providedIn: 'root'})
export class UpdateCartService {

  constructor(protected store: Store<UpdateCartFeature.UpdateCartState>,
              private activeCartService: ActiveCartFacade,
              private cartParametersService: CartParametersService) {}

  updateCart(data: UpdateCartArg): void {
    // update and optionally recalculate cart
    return this.store.dispatch(UpdateCartActions.updateCart({ data }));
  }

  reset(): void {
    return this.store.dispatch(UpdateCartActions.updateCartReset());
  }

  isUpdating(): Observable<boolean> {
    return this.store.select(UpdateCartSelectors.selectIsUpdating);
  }

  success(): Observable<boolean> {
    return this.store.select(UpdateCartSelectors.selectSuccess);
  }


  calculateCart(userId: string): void {
    this.activeCartService.requireLoadedCart().pipe(
      withLatestFrom(this.cartParametersService.getCurrentOrderType(),
        this.cartParametersService.getCurrentDeliveryAddress(),
        this.cartParametersService.getCurrentShippingType(),
        this.cartParametersService.getRequestDeliveryDate()),
      tap(([cart, ot, da, st, dd]) => {
        if (dd === undefined) {
          const currentDate = new Date();
          dd = {
            day: currentDate.getDate(),
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear()
          };
        }
        const data: UpdateCartArg = {
          cartId: cart.code,
          userId: userId,
          orderTypeId: ot?.id,
          shippingTypeId: st?.code,
          addressId: da?.id,
          calculate: true,
          requestedDeliveryDate: dd?.year + '-' + dd.month + '-' + dd.day,
          reloadCart: true
        };
        this.updateCart(data);
      }),
    )
    .subscribe();
  }

}
