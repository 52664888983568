import { createReducer, on } from '@ngrx/store';
import { Address } from '@spartacus/core';

import { OrderType } from '../../model/cart-administration.model';
import { CartParametersActions } from '../actions';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ProductCategory} from '../../model/cart-administration-enum.model';
import { DeliveryMode } from '@spartacus/cart/base/root';

export const CART_PARAMETERS_FEATURE_KEY = 'cartParameters';

export interface CartParametersState {
  orderType: OrderType;
  shippingType: DeliveryMode;
  deliveryAddress: Address;
  requestedDeliveryDate: NgbDateStruct;
  productCategory: ProductCategory;

}

export const initialState: CartParametersState = {
  orderType: undefined,
  shippingType: undefined,
  deliveryAddress: undefined,
  requestedDeliveryDate: undefined,
  productCategory: ProductCategory.SPARE_PARTS
};

export const cartParametersReducer = createReducer(
  initialState,

  on(CartParametersActions.setOrderType, (state, action) => ({
    ...state,
    orderType: action.orderType
  })),
  on(CartParametersActions.setShippingType, (state, action) => ({
    ...state,
    shippingType: action.shippingType
  })),
  on(CartParametersActions.setDeliveryAddress, (state, action) => ({
    ...state,
    deliveryAddress: action.deliveryAddress
  })),
  on(CartParametersActions.setRequestedDeliveryDate, (state, action) => ({
    ...state,
    requestedDeliveryDate: action.requestedDeliveryDate
  })),
  on(CartParametersActions.setProductCategory, (state, action) => ({
    ...state,
    productCategory: action.productCategory
  })),
);
