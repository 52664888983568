import {createAction, props} from '@ngrx/store';
import {ProductPricesReponse} from '../model/product-prices.model';
import {OrderType} from '../../cart-administration/model/cart-administration.model';

export const loadProductPrices = createAction(
  '[ProductPrices] Load ProductPrices',
  props<{ data: { products: string[], orderType: OrderType, userId: string, returnDeliveryTime: boolean } }>()
);

export const loadProductPricesSuccess = createAction(
  '[ProductPrices] Load ProductPrices Success',
  props<{ data: { productPricesReponse: ProductPricesReponse, returnDeliveryTime: boolean } }>()
);

export const loadProductPricesFailure = createAction(
  '[ProductPrices] Load ProductPrices Failure',
  props<{ error: any }>()
);
