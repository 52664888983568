import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { RegistrationData } from '../model/login-register.model';
import { RegistrationStartActions } from '../store/actions';
import * as RegistrationStartFeature from '../store/reducers/registration-start.reducer';
import * as RegistrationStartSelectors from '../store/selectors/registration-start.selectors';

@Injectable({ providedIn: 'root' })
export class RegistrationStartService {

  constructor(protected store: Store<RegistrationStartFeature.RegistrationStartState>) {}

  registrationStart(request: RegistrationData): void {
    return this.store.dispatch(RegistrationStartActions.loadRegistrationStart({ request }));
  }

  isSending(): Observable<boolean> {
    return this.store.select(RegistrationStartSelectors.selectLoading);
  }

  success(): Observable<boolean> {
    return this.store.select(RegistrationStartSelectors.selectSuccess);
  }
}
