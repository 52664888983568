import {Component, Inject} from '@angular/core';

import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Card, CmsComponentData} from '@spartacus/storefront';
import {Address, CmsOrderDetailOverviewComponent, Config, LanguageService, Price, TranslationService} from '@spartacus/core';

import {OrderDetailsService, OrderOverviewComponent} from '@spartacus/order/components';
import {OrderInfoFieldValue, OrderType} from '../../model/order-history.model';
import {DeliveryMode} from '@spartacus/cart/base/root';

@Component({
  selector: 'app-efa-order-overview',
  templateUrl: './efa-order-overview.component.html',
})
export class EfaOrderOverviewComponent extends OrderOverviewComponent {
  readonly displayRequestedDeliveryDate: boolean = this.config.displayRequestedDeliveryDate;

  constructor(
    protected translation: TranslationService,
    protected language: LanguageService,
    protected orderDetailsService: OrderDetailsService,
    protected component: CmsComponentData<CmsOrderDetailOverviewComponent>,
    @Inject(Config) public config: any
  ) {
    super(translation, orderDetailsService, component);
  }

  getAddressCardContent(deliveryAddress: Address): Observable<Card> {
    return this.translation.translate('addressCard.shipTo').pipe(
      filter(() => Boolean(deliveryAddress)),
      map((textTitle) => ({
        title: textTitle,
        text: [
          (deliveryAddress.companyName ? deliveryAddress.companyName : ''),
          (deliveryAddress.firstName ? deliveryAddress.firstName : '') + (deliveryAddress.lastName ? deliveryAddress.lastName : ''),
          deliveryAddress.line1,
          deliveryAddress.postalCode + ' ' + deliveryAddress.town,
        ],
      }))
    );
  }

  getOrderTypeCardContent(orderType: OrderType): Observable<Card> {
    return this.translation.translate('orderHistoryCustom.myAccount.orderDetails.orderType')
      .pipe(
        map((textTitle) => ({
          title: textTitle,
          text: [orderType.name],
        }))
      );
  }

  getTotalPriceCardContent(totalPrice: Price): Observable<Card> {
    return this.translation.translate('orderHistoryCustom.myAccount.orderDetails.totalPrice')
    .pipe(
      map((textTitle) => ({
        title: textTitle,
        textBold: totalPrice.formattedValue,
      }))
    );
  }

  getConsignmentInfoCardContent(consignmentInfo: string): Observable<Card> {
    return this.translation.translate('orderHistoryCustom.myAccount.orderDetails.consignmentInfo')
      .pipe(
        map((textTitle) => ({
          title: textTitle,
          text: [consignmentInfo],
        }))
      );
  }

  getPlacedByOrderCardContent(placedBy: string): Observable<Card> {
    return this.translation.translate('orderHistoryCustom.myAccount.orderDetails.placedBy')
      .pipe(
        map((textTitle) => ({
          title: textTitle,
          text: [placedBy],
        }))
      );
  }

  getRequestedDeliveryDateCardContent(requestedDeliveryDate: string): Observable<Card> {
    return this.translation.translate('orderHistoryCustom.myAccount.orderDetails.requestedDeliveryDate')
      .pipe(
        map((textTitle) => ({
          title: textTitle,
          text: [this.getDateBy(new Date(requestedDeliveryDate))],
        }))
      );
  }

  getOriginNameCardContent(originName: string): Observable<Card> {
    return this.translation.translate('orderHistoryCustom.myAccount.orderDetails.originName')
      .pipe(
        map((textTitle) => ({
          title: textTitle,
          text: [originName],
        }))
      );
  }

  getOrderInfoFieldsCardContent(orderInfoFields: OrderInfoFieldValue[]): Observable<Card> {
    return this.translation.translate('orderHistoryCustom.myAccount.orderDetails.orderInfoFields')
      .pipe(
        map((textTitle) => {
          const textArray: string[] = [];
          orderInfoFields.forEach((item: OrderInfoFieldValue) => {
            textArray.push(item.orderInfoField.shopText);
            textArray.push(item.value);
          });
          return {
            title: textTitle,
            text: textArray,
          };
        })
      );
  }

  getOrderInfoFieldCardContent(orderInfoField: OrderInfoFieldValue): Observable<Card> {
    return this.translation.translate('orderHistoryCustom.myAccount.orderDetails.orderInfoFields.emptyFieldText')
      .pipe(
        map((emptyText) => {
          return {
            title: orderInfoField.orderInfoField.shopText,
            text: orderInfoField.value == null || orderInfoField.value.length === 0 ? [emptyText] : [orderInfoField.value],
          };
        })
      );
  }

  getOrderStatusCardContent(status: string): Observable<Card> {
    return this.translation.translate('orderHistoryCustom.myAccount.orderDetails.status').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [status],
      }))
    );
  }

  getOrderCurrentDateCardContent(isoDate: string): Observable<Card> {
    return this.translation
      .translate('orderHistoryCustom.myAccount.orderDetails.placedOn')
      .pipe(
        map((textTitle) => {
          return {
            title: textTitle,
            text: [this.getDateBy(new Date(isoDate))],
          };
        })
      );
  }

  getDeliveryModeCardContent(deliveryMode: DeliveryMode): Observable<Card> {
    return this.translation.translate('orderDetails.shippingMethod').pipe(
      filter(() => Boolean(deliveryMode)),
      map((textTitle) => ({
        title: textTitle,
        text: [
          deliveryMode.name,
          deliveryMode.description,
          deliveryMode.deliveryCost?.formattedValue
            ? deliveryMode.deliveryCost?.formattedValue
            : '',
        ],
      }))
    );
  }

  private getDateBy(givenDate: Date): string {

    let ret = '';
    this.language.getActive().pipe(map(l =>
      givenDate.toLocaleDateString(l))).subscribe((s) => ret = s);

    return ret;
  }
}
