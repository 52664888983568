import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DeliveryNotesOverviewAdapter } from '../adapters/delivery-notes-overview.adapter';
import { DeliveryNoteOverviewRequest, DeliveryNoteOverviewResponse } from '../../model/delivery-notes.model';

@Injectable({
  providedIn: 'root',
})
export class DeliveryNotesOverviewConnector {
  constructor(protected adapter: DeliveryNotesOverviewAdapter) {}

  loadOverview(request: DeliveryNoteOverviewRequest): Observable<DeliveryNoteOverviewResponse> {
    return this.adapter.loadOverview(request);
  }
}
