import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartAndCheckoutLoadingService } from '@shared/services/cart-and-checkout-loading.service';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ICON_TYPE } from '../../model/cart-administration-enum.model';
import { B2BUserAccountService } from '../../services/b2-b-user-account.service';
import { SetCartCalculationModeService } from '../../services/set-cart-calculation-mode.service';
import { UpdateCartService } from "../../services/update-cart.service";

@Component({
  selector: 'app-cart-calculate-mode',
  templateUrl: './cart-calculate-mode.component.html'
})
export class CartCalculateModeComponent implements OnInit, OnDestroy {

  @Input() skipAutomaticCalculation: boolean;

  automaticCalculation: boolean;
  iconTypes = ICON_TYPE;

  private subscription: Subscription = new Subscription();
  private cartId: string;

  constructor(
    private b2BUserAccountService: B2BUserAccountService,
    private activeCartService: ActiveCartFacade,
    private multiCartService: MultiCartFacade,
    private updateCartService: UpdateCartService,
    private setCartCalculationModeService: SetCartCalculationModeService, 
    private cartAndCheckoutLoadingService: CartAndCheckoutLoadingService) {
  }

  ngOnInit(): void {
    this.subscription.add(this.activeCartService.getActive().pipe(map(cart => !cart.skipAutomaticCalculation)).subscribe(b => this.automaticCalculation = b));
    this.subscription.add(this.activeCartService.getActiveCartId().subscribe(b => this.cartId = b));
    this.subscription.add(
      this.setCartCalculationModeService.success()
      .subscribe((success) => {
        if (success) {
          if (this.automaticCalculation) {
            this.updateCartService.calculateCart(this.b2BUserAccountService.userId)
          } else {
            this.multiCartService.loadCart({
              cartId: this.cartId,
              userId: this.b2BUserAccountService.userId,
              extraData: {
                active: true,
              },
            })          }
        }
      }));
      
    this.subscription.add(this.setCartCalculationModeService.isUpdating().pipe(distinctUntilChanged()).subscribe(
      (isLoading: boolean) => {
        this.cartAndCheckoutLoadingService.setCartItemListLoading(isLoading);
      }
    ));
  }

  toggleCartCalculationMode(automatic: boolean): void {
    this.setCartCalculationModeService.setCartCalculationMode(automatic, this.cartId);
  }

  ngOnDestroy(): void {
    this.setCartCalculationModeService.reset();
    this.subscription.unsubscribe();
  }
}
