import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import { AuthActions, SiteContextActions } from '@spartacus/core';
import { produce } from 'immer';
import { ReturnDetailsResponse } from '../../model/return-history.model';
import { ReturnDetailsActions } from '../actions';

export const RETURN_DETAILS_FEATURE_KEY = 'returnDetails';

export interface ReturnDetailsState {
  returnNumber: string;
  response: ReturnDetailsResponse;
  loading: boolean;
  error: ErrorType;
}

export const initialState: ReturnDetailsState = {
  returnNumber: undefined,
  response: undefined,
  loading: false,
  error: undefined
};

export const returnDetailsReducer = createReducer(
  initialState,

  on(ReturnDetailsActions.loadReturnDetails, (state, action) => ({
    ...state,
    loading: true,
    returnNumber: action.returnNumber,
    response: undefined
  })),
  on(ReturnDetailsActions.loadReturnDetailsSuccess, (state, action) => ({
    ...state,
    loading: false,
    response: action.response,
  })),
  on(ReturnDetailsActions.loadReturnDetailsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.errorResponse
  })),
  on(ReturnDetailsActions.resetReturnDetails, (state, action) => ({
    ...initialState,
  }))
);

function authenticationOrLanguageChangedDetails(action: Action, state): any {
  if (action.type === AuthActions.LOGIN ||
    action.type === AuthActions.LOGOUT ||
    action.type === SiteContextActions.LANGUAGE_CHANGE
  ) {
    return produce(state, (draftState) => {
      draftState.returnHistory.returnDetails = initialState;
    });
  }
  return state;
}

export function metaReducerFactoryReturnDetails(): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const newState = authenticationOrLanguageChangedDetails(action, state);
    return reducer(newState, action);
  };
}
