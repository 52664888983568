import {Component, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {tap} from 'rxjs/operators';
import {TileItem, TilesComponentData} from '../../model/content-tiles.model';
import {CmsService} from '@spartacus/core';
import {BehaviorSubject} from 'rxjs';
import {ICON_TYPE} from '@shared/models/shared.model';

@Component({
  selector: 'app-efa-tiles-cms-container',
  templateUrl: './tiles-cms-container.component.html',
})
export class TilesCmsContainerComponent implements OnInit {
  public tileItems: TileItem[] = [];
  public isHelpPage = new BehaviorSubject<boolean>(false);
  public isHelpAnonymousPage = new BehaviorSubject<boolean>(false);
  public isTiresAndMorePage = new BehaviorSubject<boolean>(false);
  public isDownloadCustomerInfoPage = new BehaviorSubject<boolean>(false);
  public tilesVisibleLowIndex: number = 0;
  public tilesVisibleHighIndex: number;
  public readonly iconType = ICON_TYPE;

  private readonly tilesCarouselStep: number = 1;

  public tilesComponentData$ = this.component.data$.pipe(
    tap((data) => {
      this.tileItems = [];
      data.tileItemsSortOrder.split(',').forEach((s) => this.tileItems.push(data.tileItems[s]));
      this.tilesVisibleHighIndex = data.numberOfTilesPerRow - 1;
    }),
  );

  public isShowMore = false;
  public colConfig: string = ''

  ngOnInit(): void {
    this.cmsService.getCurrentPage().subscribe(page => {
      if (page?.label === '/help')
        this.isHelpPage.next(true)
      if (page?.label === '/tires-and-more')
        this.isTiresAndMorePage.next(true)
      if (page?.label === '/download-customerinfo')
        this.isDownloadCustomerInfoPage.next(true)
      if (page?.label === '/help-anonymous')
        this.isHelpAnonymousPage.next(true)
    });

    this.isDownloadCustomerInfoPage.subscribe(value => {
      this.colConfig = '';
      if (value) {
        this.colConfig = 'col-md-12';
      } else {
        this.colConfig = 'col-md-10';
      }
    });
  }

  showMore(): void {
    this.isShowMore = true;
  }

  showLess(): void {
    this.isShowMore = false;
  }

  scroll(el): void {
    el.scrollIntoView({behavior: 'smooth'});
  }

  slideRight(tileItemsLength: number): void {
    if (this.tilesVisibleHighIndex < (tileItemsLength - 1)) {
      this.tilesVisibleLowIndex = this.tilesVisibleLowIndex + this.tilesCarouselStep;
      this.tilesVisibleHighIndex = this.tilesVisibleHighIndex + this.tilesCarouselStep;
    }
  }

  slideLeft(): void {
    if (this.tilesVisibleLowIndex > 0) {
      this.tilesVisibleLowIndex = this.tilesVisibleLowIndex - this.tilesCarouselStep;
      this.tilesVisibleHighIndex = this.tilesVisibleHighIndex - this.tilesCarouselStep;
    }
  }

  constructor(public component: CmsComponentData<TilesComponentData>,
              private cmsService: CmsService
  ) {

  }
}
