import { Component, Input } from '@angular/core';
import { GenericLinkComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-tile-link',
  templateUrl: './tile-link.component.html',
})
export class TileLinkComponent extends GenericLinkComponent {
  @Input() url: string;
  @Input() target: string;
  @Input() classes: string[];
  @Input() overridenLocalUrl: string;
}
