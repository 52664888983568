
<div class="tires-warranty-header">
  <h2>{{ 'tiresWarranty.tiresWarrantyHeader.title' | cxTranslate }}</h2>
  <div class="header-box">
    <div class="image-box">
      <img src="../../../../../../assets/images/tiresWarrantyImage.png" alt="">
    </div>
    <div class="left-box">
      <p>{{ 'tiresWarranty.tiresWarrantyHeader.description' | cxTranslate }}</p>
      <div class="nav-items d-flex">
        <div class="warranty" (click)="scrollToForm()">
          <i class="fas fa-chevron-right"></i><a>{{ 'tiresWarranty.tiresWarrantyHeader.goToForm' | cxTranslate }}</a>
        </div>
        <div class="damage" (click)="scrollToFooter()">
          <i class="fas fa-chevron-right"></i> <a>{{ 'tiresWarranty.tiresWarrantyHeader.goToFooter' | cxTranslate }}</a>
        </div>
      </div>
      <div class="inf-list">
        <ul>
          <li *ngFor="let brand of tireBrands"><b>{{brand}}</b></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row justify-content-center" id="tiresWarrantyForm" >
  <div class="col-md-8" >
    <div class="form-header">
      <p>{{ 'tiresWarranty.tiresWarrantyForm.formTitle' | cxTranslate }}</p>
    </div>
    <p>{{ 'tiresWarranty.tiresWarrantyForm.formSubTitle' | cxTranslate }}<br>{{ 'tiresWarranty.tiresWarrantyForm.formMandatoryFields' | cxTranslate }}</p>

    <form (ngSubmit)="onSubmit()" [formGroup]="tiresWarrantyForm" >
      <fieldset formGroupName = "dealerData">
        <legend class="tiresWarranty_fieldset-legend">
          {{ 'tiresWarranty.tiresWarrantyForm.legend1' | cxTranslate }}
        </legend>
        <div class="form-group">
          <label for="companyName">{{ 'tiresWarranty.tiresWarrantyForm.dealerData.companyName.lable' | cxTranslate }}*</label>
          <input type="text"
                 id="companyName"
                 formControlName = "companyName"
                 placeholder="{{ 'tiresWarranty.tiresWarrantyForm.dealerData.companyName.placeholder' | cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.companyName')"></cx-form-errors>
        </div>
        <div class="form-group">
          <label for="customerNumber">{{'tiresWarranty.tiresWarrantyForm.dealerData.customerNumber.lable' | cxTranslate }}*</label>
          <input type="text"
                 id="customerNumber"
                 formControlName = "customerNumber"
                 placeholder="{{'tiresWarranty.tiresWarrantyForm.dealerData.customerNumber.placeholder' | cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.customerNumber')"></cx-form-errors>
        </div>
        <div class="row">
          <div class="col-12 col-md-9 form-group">
            <label for="dealerDataStreet">{{'tiresWarranty.tiresWarrantyForm.dealerData.street.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="dealerDataStreet"
                   formControlName = "street"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.dealerData.street.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.street')"></cx-form-errors>
          </div>
          <div class=" col-12 col-md-3 form-group">
            <label for="dealerDataStreetnumber">{{'tiresWarranty.tiresWarrantyForm.dealerData.streetnumber.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="dealerDataStreetnumber"
                   formControlName = "streetnumber"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.dealerData.streetnumber.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.streetnumber')"></cx-form-errors>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3 form-group">
            <label for="postalCode"> {{'tiresWarranty.tiresWarrantyForm.dealerData.postalCode.placeholder' | cxTranslate }}*</label>
            <input type="text"
                  id="postalCode"
                  formControlName = "postalCode"
                  placeholder="{{'tiresWarranty.tiresWarrantyForm.dealerData.postalCode.placeholder' | cxTranslate }}"
                  class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.postalCode')"></cx-form-errors>
          </div>
          <div class="col-12 col-md-9 form-group">
            <label for="town">{{'tiresWarranty.tiresWarrantyForm.dealerData.town.lable' | cxTranslate }}*</label>
            <input type="text"
                  id="town"
                  formControlName = "town"
                  placeholder="{{'tiresWarranty.tiresWarrantyForm.dealerData.town.placeholder' | cxTranslate }}"
                  class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.town')"></cx-form-errors>
          </div>
        </div>
        <div class="form-group select-arrow">
          <label for="countryIsoCode">{{'tiresWarranty.tiresWarrantyForm.dealerData.countryIsoCode.lable' | cxTranslate }}*</label>
          <select formControlName="countryIsoCode" class="form-control" [ngClass]="getSelectPlaceholderTextClass(tiresWarrantyForm.get('dealerData.countryIsoCode').value)">
            <option selected value="" disabled>
              {{ 'tiresWarranty.tiresWarrantyForm.dealerData.countryIsoCode.placeholder' | cxTranslate }}
            </option>
            <option *ngFor="let county of countries$ | async"
                    [value]="county.isocode">
              {{ county.name }}
            </option>
          </select>
          <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.countryIsoCode')"></cx-form-errors>
        </div>
        <div class="form-group">
          <label for="phone">{{'tiresWarranty.tiresWarrantyForm.dealerData.phone.lable' | cxTranslate }}*</label>
          <input type="text"
                 id="phone"
                 formControlName = "phone"
                 placeholder="{{'tiresWarranty.tiresWarrantyForm.dealerData.phone.placeholder' | cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.phone')"></cx-form-errors>
        </div>
        <div class="form-group">
          <label for="email">{{'tiresWarranty.tiresWarrantyForm.dealerData.email.placeholder' | cxTranslate }}*</label>
          <input type="text"
                 id="email"
                 formControlName = "email"
                 placeholder="{{'tiresWarranty.tiresWarrantyForm.dealerData.email.placeholder' | cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.email')"></cx-form-errors>
        </div>
        <div class="form-group">
          <label for="referenceNumber">{{'tiresWarranty.tiresWarrantyForm.dealerData.referenceNumber.lable' | cxTranslate }}*</label>
          <input type="text"
                 id="referenceNumber"
                 formControlName = "referenceNumber"
                 placeholder="{{'tiresWarranty.tiresWarrantyForm.dealerData.referenceNumber.placeholder' | cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.referenceNumber')"></cx-form-errors>
        </div>
        <div class="form-group">
          <span class="label-content">{{'tiresWarranty.tiresWarrantyForm.dealerData.referenceTypeCode.lable' | cxTranslate }}*</span>
          <div class="row">
            <div class="col-6 col-sm-4" *ngFor="let referenceTypeCode of referenceTypeCodes">
              <label class="form-check form-check-inline">
                <input type="radio"
                           formControlName="referenceTypeCode"
                           [value]="referenceTypeCode"/>
                           {{'tiresWarranty.tiresWarrantyForm.dealerData.referenceTypeCode.code.'+ referenceTypeCode | cxTranslate }}
              </label>
            </div>
            <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.referenceTypeCode')"></cx-form-errors>
          </div>
        </div>
        <div class=" row ">
          <div class="col-12 col-lg-4">
            <label for="invoiceDocument" class="label-content">
              {{ 'tiresWarranty.tiresWarrantyForm.dealerData.invoiceDocument.lable' | cxTranslate }}*
            </label>
          </div>
          <div class="col-12 col-lg-8">
            <div class="d-flex">
              <div class="o-import-file__input">
                <div class="custom-file file-box">
                  <input class="custom-file-input" type="file" formControlName="invoiceDocument"
                          (change)="onInvoiceDocumentChange($event, 'invoiceDocument')"/>
                  <label attr.data-content="{{ 'uploadFile.search2' | cxTranslate }}" id="invoiceDocument"
                          class="custom-file-label">
                          <cx-icon class="u-pointer" [type]="iconTypes.DOCUMENTPDF"></cx-icon>
                    {{ 'tiresWarranty.tiresWarrantyForm.dealerData.invoiceDocument.placeholder' | cxTranslate }}
                  </label>
                </div>
              </div>
              <div class="o-import-file__remove">
                <cx-icon class="u-pointer" [type]="iconTypes.DELETEFOREVER"
                         (click)="removeSelectedFile('invoiceDocument')"
                ></cx-icon>
              </div>
            </div>
            <cx-form-errors [control]="tiresWarrantyForm.get('dealerData.invoiceDocument')"></cx-form-errors>
          </div>
        </div>
        </fieldset>

      <fieldset formGroupName = "tireCarData">
        <legend class="tiresWarranty_fieldset-legend">
          {{ 'tiresWarranty.tiresWarrantyForm.legend2' | cxTranslate }}
        </legend>
        <div class="row">
          <div class="col-12 col-md-5 form-group">
            <label for="installatonDate">{{'tiresWarranty.tiresWarrantyForm.tireCarData.installatonDate.lable' | cxTranslate }}*</label>
            <div class="input-group">
              <input class="form-control" ngbDatepicker #registrationDateDatePicker="ngbDatepicker"
                     style="background-color:transparent"
                     formControlName="installatonDate" [maxDate]="maxDate" [readonly]="true"
                     placeholder="{{'tiresWarranty.tiresWarrantyForm.tireCarData.installatonDate.placeholder'| cxTranslate }}"
                     efaNgbDatepickerNavigationTooltipsRemove/>
              <div class="input-group-append">
                <button class="btn btn-primary d-flex" (click)="registrationDateDatePicker.toggle()"
                        type="button">
                  <cx-icon [type]="iconTypes.CALENDAR"></cx-icon>
                </button>
              </div>
            </div>
            <cx-form-errors [control]="tiresWarrantyForm.get('tireCarData.installatonDate')"></cx-form-errors>
          </div>
          <div class="col-12 col-md-7 form-group select-arrow">
            <label for="tireBrand">{{'tiresWarranty.tiresWarrantyForm.tireCarData.tireBrand.lable'| cxTranslate }}*</label>
            <select formControlName="tireBrand" class="form-control" [ngClass]="getSelectPlaceholderTextClass(tiresWarrantyForm.get('tireCarData.tireBrand').value)">
              <option selected value="" disabled>
                {{'tiresWarranty.tiresWarrantyForm.tireCarData.tireBrand.placeholder'| cxTranslate }}
              </option>
              <option *ngFor="let tireBrand of tireBrands"
                      [value]="tireBrand">
                {{ tireBrand }}
              </option>
            </select>
            <cx-form-errors [control]="tiresWarrantyForm.get('tireCarData.tireBrand')"></cx-form-errors>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 form-group">
            <label for="quantity">{{'tiresWarranty.tiresWarrantyForm.tireCarData.quantity.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="quantity"
                   formControlName = "quantity"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.tireCarData.quantity.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('tireCarData.quantity')"></cx-form-errors>
          </div>
          <div class="col-12 col-md-4 form-group">
            <label for="tireSize">{{'tiresWarranty.tiresWarrantyForm.tireCarData.tireSize.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="tireSize"
                   formControlName = "tireSize"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.tireCarData.tireSize.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('tireCarData.tireSize')"></cx-form-errors>
          </div>
          <div class="col-12 col-md-4 form-group">
            <label for="tireProfile">{{'tiresWarranty.tiresWarrantyForm.tireCarData.tireProfile.placeholder'| cxTranslate }}*</label>
            <input type="text"
                   id="tireProfile"
                   formControlName = "tireProfile"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.tireCarData.tireProfile.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('tireCarData.tireProfile')"></cx-form-errors>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 form-group">
            <label for="carBrand">{{'tiresWarranty.tiresWarrantyForm.tireCarData.carBrand.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="carBrand"
                   formControlName = "carBrand"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.tireCarData.carBrand.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('tireCarData.carBrand')"></cx-form-errors>
          </div>
          <div class="col-12 col-md-3 form-group">
            <label for="numberPlate">{{'tiresWarranty.tiresWarrantyForm.tireCarData.numberPlate.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="numberPlate"
                   formControlName = "numberPlate"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.tireCarData.numberPlate.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('tireCarData.numberPlate')"></cx-form-errors>
          </div>
          <div class="col-12 col-md-3 form-group">
            <label for="totalKilometers">{{'tiresWarranty.tiresWarrantyForm.tireCarData.totalKilometers.placeholder'| cxTranslate }}*</label>
            <input type="number"
                   id="totalKilometers"
                   formControlName = "totalKilometers"
                   step="1" min="0"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.tireCarData.totalKilometers.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('tireCarData.totalKilometers')"></cx-form-errors>
          </div>
        </div>
      </fieldset>

      <fieldset formGroupName = "customerData">
        <legend class="tiresWarranty_fieldset-legend">{{ 'tiresWarranty.tiresWarrantyForm.legend3' | cxTranslate }}</legend>
        <div class="form-group">
          <span class="label-content">{{'tiresWarranty.tiresWarrantyForm.customerData.titleCode.lable'| cxTranslate }}*</span>
          <div class="row">
            <div class="col-4 col-md-3" *ngFor="let titleCode of titleCodes">
              <label class="form-check form-check-inline">
                <input type="radio"
                           formControlName="titleCode"
                           [value]="titleCode"/>
                           {{'tiresWarranty.tiresWarrantyForm.customerData.titleCode.gender.' + titleCode | cxTranslate }}
              </label>
            </div>
          </div>
          <cx-form-errors [control]="tiresWarrantyForm.get('customerData.titleCode')"></cx-form-errors>
        </div>
        <div class="form-group">
          <label for="name">{{'tiresWarranty.tiresWarrantyForm.customerData.name.lable'| cxTranslate }}*</label>
          <input type="text"
                 id="name"
                 formControlName = "name"
                 placeholder="{{'tiresWarranty.tiresWarrantyForm.customerData.name.placeholder'| cxTranslate }}"
                 class="form-control"/>
          <cx-form-errors [control]="tiresWarrantyForm.get('customerData.name')"></cx-form-errors>
        </div>
        <div class="row">
          <div class="col-12 col-md-9 form-group">
            <label for="street">{{'tiresWarranty.tiresWarrantyForm.customerData.street.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="street"
                   formControlName = "street"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.customerData.street.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('customerData.street')"></cx-form-errors>
          </div>
          <div class="col-12 col0-md-3 form-group">
            <label for="streetnumber">{{'tiresWarranty.tiresWarrantyForm.customerData.streetnumber.lable'| cxTranslate }}*</label>
            <input type="text"
                   id="streetnumber"
                   formControlName = "streetnumber"
                   placeholder="{{'tiresWarranty.tiresWarrantyForm.customerData.streetnumber.placeholder'| cxTranslate }}"
                   class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('customerData.streetnumber')"></cx-form-errors>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3 form-group">
            <label for="postalCode">{{'tiresWarranty.tiresWarrantyForm.customerData.postalCode.lable' | cxTranslate }}*</label>
            <input type="text"
                  id="postalCode"
                  formControlName = "postalCode"
                  placeholder=" {{'tiresWarranty.tiresWarrantyForm.customerData.postalCode.placeholder' | cxTranslate }}"
                  class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('customerData.postalCode')"></cx-form-errors>
          </div>
          <div class="col-12 col-md-9 form-group">
            <label for="town">{{'tiresWarranty.tiresWarrantyForm.customerData.town.lable' | cxTranslate }}*</label>
            <input type="text"
                  id="town"
                  formControlName = "town"
                  placeholder=" {{'tiresWarranty.tiresWarrantyForm.customerData.town.placeholder' | cxTranslate }}"
                  class="form-control"/>
            <cx-form-errors [control]="tiresWarrantyForm.get('customerData.town')"></cx-form-errors>
          </div>
        </div>
        <div class="form-group select-arrow">
          <label for="countryIsoCode">{{'tiresWarranty.tiresWarrantyForm.customerData.countryIsoCode.lable' | cxTranslate }}*</label>
          <select formControlName="countryIsoCode" class="form-control" [ngClass]="getSelectPlaceholderTextClass(tiresWarrantyForm.get('customerData.countryIsoCode').value)">
            <option selected value="" disabled>
              {{ 'tiresWarranty.tiresWarrantyForm.customerData.countryIsoCode.placeholder' | cxTranslate }}
            </option>
            <option *ngFor="let county of countries$ | async"
                    [value]="county.isocode">
              {{ county.name }}
            </option>
          </select>
          <cx-form-errors [control]="tiresWarrantyForm.get('customerData.countryIsoCode')"></cx-form-errors>
        </div>
        <div class="form-group">
          <label for="phone">{{'tiresWarranty.tiresWarrantyForm.customerData.phone.lable' | cxTranslate }}*</label>
          <input type="text"
                 id="phone"
                 formControlName = "phone"
                 placeholder=" {{'tiresWarranty.tiresWarrantyForm.customerData.phone.placeholder' | cxTranslate }}"
                 class="form-control"/>

          <cx-form-errors [control]="tiresWarrantyForm.get('customerData.phone')"></cx-form-errors>
        </div>
      </fieldset>

      <fieldset>
        <legend class="tiresWarranty_fieldset-legend">{{ 'tiresWarranty.tiresWarrantyForm.legend4' | cxTranslate }}</legend>
        <label for="warranty-terms">{{ 'tiresWarranty.tiresWarrantyForm.warrantyTerms.lable' | cxTranslate }}*</label>
        <div class="form-group terms-box">
          <input type="checkbox"
                 id="warrantyTerms"
                 formControlName = "warrantyTerms">
          <span>{{ 'tiresWarranty.tiresWarrantyForm.warrantyTerms.placeholder' | cxTranslate }}</span>
          <cx-form-errors [control]="tiresWarrantyForm.get('warrantyTerms')"></cx-form-errors>
        </div>
      </fieldset>
      <div class="col-12 justify-content-center d-flex">
        <button class="btn btn-primary btn-block warranty-submit-btn"
                [disabled]="(!tiresWarrantyForm.valid || (isLoading$ |async))">
          {{ 'tiresWarranty.tiresWarrantyForm.submit' | cxTranslate }}
        </button>
      </div>
      <div class="col-12 justify-content-center d-flex pt-3">
        <button type="button" class="btn btn-link btn-block btn-hover-transform w-50"
                (click)="clearForm()">
          {{ 'resetForm' | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</div>
<div class="cx-spinner" *ngIf="isLoading$ |async">
  <cx-spinner></cx-spinner>
</div>
<hr>


<div class="row tires-warranty-footer" id="tiresWarrantyFooter">
  <div class="col-md-8 footer-box">
      <h2>{{ 'tiresWarranty.tiresWarrantyFooter.title' | cxTranslate }}</h2>
      <p>
        <span>{{ 'tiresWarranty.tiresWarrantyFooter.text1' | cxTranslate }}&nbsp;</span><a href="mailto:efa-reifen@autoteilewelt.de">{{ 'tiresWarranty.tiresWarrantyFooter.email' | cxTranslate }}</a><span>&nbsp;{{ 'tiresWarranty.tiresWarrantyFooter.text2' | cxTranslate }}&nbsp;</span><a href="tel:0800-0001 883 47">{{ 'tiresWarranty.tiresWarrantyFooter.phone' | cxTranslate }}</a><span>.</span>
      </p>
      <p>{{ 'tiresWarranty.tiresWarrantyFooter.text3' | cxTranslate }}</p>
      <p>
        <span>{{ 'tiresWarranty.tiresWarrantyFooter.text4' | cxTranslate }}&nbsp;</span><a href="mailto:efa-reifen@autoteilewelt.de">{{ 'tiresWarranty.tiresWarrantyFooter.email' | cxTranslate }}</a><span>&nbsp;{{ 'tiresWarranty.tiresWarrantyFooter.text5' | cxTranslate }}</span>
      </p>
      <p>{{ 'tiresWarranty.tiresWarrantyFooter.address1' | cxTranslate }}<br>
        {{ 'tiresWarranty.tiresWarrantyFooter.address2' | cxTranslate }}<br>
        {{ 'tiresWarranty.tiresWarrantyFooter.address3' | cxTranslate }}<br>
        {{ 'tiresWarranty.tiresWarrantyFooter.address4' | cxTranslate }}
      </p>
      <div class="register-btn" (click)="scrollToForm()">
        <a>{{ 'tiresWarranty.tiresWarrantyFooter.goToForm' | cxTranslate }}</a>
      </div>
  </div>

</div>
