import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { Card } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-return-history-header',
  templateUrl: './return-history-header.component.html',
})
export class ReturnHistoryHeaderComponent implements OnInit {

  @Input() returnNumber: string;
  @Input() creationDate: Date;
  @Input() deliveryNoteNumber: string;
  @Input() statusDisplay: string;

  constructor(private translation: TranslationService,) { }

  ngOnInit(): void {
  }


  getDeliveryNoteNumber(): Observable<Card> {
    return combineLatest([
      this.translation.translate('returnDetails.deliveryNoteNumber'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: this.deliveryNoteNumber,
        };
      })
    );
  }

  getCreationDate(): Observable<Card> {
    return combineLatest([
      this.translation.translate('returnDetails.creationDate'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: formatDate(this.creationDate, 'dd.MM.yyyy', 'en-US'),
        };
      })
    );
  }

  getReturnNumber(): Observable<Card> {
    return combineLatest([
      this.translation.translate('returnDetails.returnNumber'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: this.returnNumber,
        };
      })
    );
  }

  getStatusDisplay(): Observable<Card> {
    return combineLatest([
      this.translation.translate('returnDetails.statusDisplay'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: this.statusDisplay,
        };
      })
    );
  }
}
