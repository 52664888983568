export const efaAddToCart = {
  eAddToCart: {
    showQuantityHint: 'The amount can be entered in the shopping cart.',
    showQuantityHintForConfiguratorEntry: 'You are currently in the ordering process for the wheel configurator. As soon as you have completed your order, you can add new items to the shopping cart.',
    addToCartDialog: {
      title: 'Add to cart',
      actions: {
        goToCart: 'Go to cart',
        returnToCurrentPage: 'Continue shopping',
        close: 'Cancel',
      }
    }
  }
};
