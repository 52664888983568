import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { OrderCancelAdapter } from '../adapters/order-cancel.adapter';
import { OrderCancelResponse } from '../../model/order-cancel.model';
import { CancellationRequestEntryInputList } from '@spartacus/order/root';

@Injectable({
  providedIn: 'root',
})

export class OrderCancelConnector {
  constructor(protected  adapter: OrderCancelAdapter) {}

  doOrderCancellation( orderNumber: string, positions: CancellationRequestEntryInputList): Observable<OrderCancelResponse> {
    return this.adapter.doOrderCancellation(orderNumber, positions);
  }
}
