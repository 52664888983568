import { Component, Input } from '@angular/core';
import { Product } from '@spartacus/core';
import {
  EfaAddToWishListComponentBaseComponent
} from '@shared/components/efa-add-to-wish-list-base/efa-add-to-wish-list-base.component';
import { AddToCartParam } from '../../../cart-administration/model/cart-administration.model';
import { map, tap } from 'rxjs/operators';
import { ICON_TYPE } from '@shared/models/shared.model';

@Component({
  selector: 'app-efa-cart-item-add-to-wish-list',
  templateUrl: './efa-cart-item-add-to-wish-list.component.html',
})
export class EfaCartItemAddToWishListComponent extends EfaAddToWishListComponentBaseComponent {
  @Input() product: Product;
  @Input() orderInfoRefId = 0;
  @Input() IsAddedFromSearchPage= false;
  sharedIconTypes = ICON_TYPE;
  add(product: Product): void {
    const addToCartParams: AddToCartParam[] = [];
    const addToCartParam: AddToCartParam = {
      productCode: product.code,
      quantity: 1,
      manufacturerId: product.manufacturerId,
      materialName: product.name,
      articleNumber: product.manufacturerAID,
      requiredShippingTypeID: '',
      substitutedOEMNumber: '',
      orderInfoRefId: this.orderInfoRefId,
      consignmentInfo: '',
      unitId: '',
      customerPricePerUnit: product.customerPrice,
      listPricePerUnit: product.listPrice,
      deliveryItems: undefined,
    };
    addToCartParams.push(addToCartParam);
    this.wishListService.getWishList().pipe(map(w => w.code),
      tap(code =>
        this.multiAddToCartService.addEntriesFromParams(addToCartParams, code, true))).subscribe().unsubscribe();
  }
}
