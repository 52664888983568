import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { OrderCancelAdapter } from './adapters/order-cancel.adapter';
import { OccOrderCancelAdapter } from './adapters/impl/occ-order-cancel.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: OrderCancelAdapter,
      useClass: OccOrderCancelAdapter,
    }
  ],
})
export class OccModule {
}
