import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FooterNavigationComponent, NavigationNode } from '@spartacus/storefront';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-efa-footer-navigation',
  templateUrl: './efa-footer-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaFooterNavigationComponent extends FooterNavigationComponent implements OnInit {
  ngOnInit(): void {
    this.node$ = this.node$.pipe(map((node) => this.removeEmptyNodes(node)));
  }

  private removeEmptyNodes(node: NavigationNode): NavigationNode {
    if (node == null || ((node.children == null || node.children?.length === 0) && node.url == null)) {
      return undefined;
    }

    if (node.url != null) {
      return node;
    }

    const filteredChildren: NavigationNode[] = [];
    node.children.forEach((child: NavigationNode) => {
      const processedChild: NavigationNode = this.removeEmptyNodes(child);
      if (processedChild != null) {
        filteredChildren.push(processedChild);
      }
    });

    return {
      ...node,
      children: filteredChildren,
    };
  }
}
