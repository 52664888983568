import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ListService, UserAssignedUserGroupListComponent, UserAssignedUserGroupListService } from '@spartacus/organization/administration/components';
import { ICON_TYPE } from '../../model/user-profiles-icon.model';

@Component({
  selector: 'app-efa-user-assigned-user-group-list',
  templateUrl: './efa-user-assigned-user-group-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'content-wrapper' },
  providers: [
    {
      provide: ListService,
      useExisting: UserAssignedUserGroupListService,
    },
  ],
})
export class EfaUserAssignedUserGroupListComponent extends UserAssignedUserGroupListComponent implements OnInit {
  iconTypes = ICON_TYPE;
  ngOnInit(): void {
  }

}
