import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { OccInvoiceCreditOverviewAdapter } from './adapters/impl/occ-invoice-credit-overview.adapter';
import { InvoiceCreditOverviewAdapter } from './adapters/invoice-credit-overview.adapter';
import { InvoiceCreditDetailsAdapter } from './adapters/invoice-credit-details.adapter';
import { OccInvoiceCreditDetailsAdapter } from './adapters/impl/occ-invoice-credit-details.adapter';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: InvoiceCreditOverviewAdapter,
      useClass: OccInvoiceCreditOverviewAdapter,
    },
    {
      provide: InvoiceCreditDetailsAdapter,
      useClass: OccInvoiceCreditDetailsAdapter,
    }
  ],
})
export class OccModule {
}
