import { Injectable } from '@angular/core';
import { OccProductReferencesAdapter } from '@spartacus/core';

@Injectable()
export class EfaOccProductReferencesAdapter extends OccProductReferencesAdapter {
  protected getEndpoint(
    code: string,
    reference?: string,
    pageSize?: number
  ): string {
    return this.occEndpoints.buildUrl('productReferences', {
      urlParams: { productCode: encodeURIComponent(code) },
      queryParams: { referenceType: reference, pageSize },
    });
  }
}
