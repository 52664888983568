import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { B2bUnitSummaryComponent } from './components/b2b-unit-summary/b2b-unit-summary.component';
import { ConfigModule } from '@spartacus/core';
import { I18nModule } from '@spartacus/core';
import { headerTranslationChunksConfig, headerTranslations } from './translations/header.translations';



@NgModule({
  declarations: [
    B2bUnitSummaryComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        B2BUnitIDComponent: { 
          component: B2bUnitSummaryComponent, 
        },
      },
      i18n: {
        resources: headerTranslations,
        chunks: headerTranslationChunksConfig
      },
    }),
  ]
})
export class HeaderModule { }
