import {TranslationChunksConfig} from '@spartacus/assets';

import { de } from './de/index';
import { en } from './en/index';


export const defaultManufacturersTranslations = {
  de,
  en
};

export const defaultManufacturersTranslationChunksConfig: TranslationChunksConfig = {
  defaultManufacturers: [
    'defaultManufacturers',
  ],
};
