<cx-spinner class="spinner-popover-backdrop" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <label>
    <span class="label-content">{{
      'efaLoginForm.partnerId.label' | cxTranslate
      }}*</span>
    <input
      type="text"
      class="form-control"
      formControlName="partnerId"
      placeholder="{{ 'efaLoginForm.partnerId.placeholder' | cxTranslate }}"
    />
    <cx-form-errors [control]="form.get('partnerId')"></cx-form-errors>
  </label>
  <label>
    <span class="label-content">{{
      'efaLoginForm.emailAddress.label' | cxTranslate
      }}*</span>
    <input
      type="email"
      class="form-control"
      placeholder="{{
        'efaLoginForm.emailAddress.placeholder' | cxTranslate
      }}"
      formControlName="userEmail"
    />
    <cx-form-errors [control]="form.get('userEmail')"></cx-form-errors>
  </label>


  <ng-container *ngIf="(component.data$ | async).activateAccount == 'true'; else forgotPassword">
    <button class="btn btn-block btn-primary mt-4" [disabled]="form.disabled">
      {{ 'efaForgotPasswordForm.submitActivateAccount' | cxTranslate }}
    </button>

    <a
      class="btn btn-block btn-secondary mt-4"
      [routerLink]="{ cxRoute: 'login' } | cxUrl"
    >{{ 'efaForgotPasswordForm.cancelActivateAccount' | cxTranslate }}</a>
  </ng-container>

  <ng-template #forgotPassword>
    <button class="btn btn-block btn-primary mt-4" [disabled]="form.disabled">
      {{ 'efaForgotPasswordForm.submitForgotPassword' | cxTranslate }}
    </button>

    <a
      class="btn btn-block btn-secondary mt-4"
      [routerLink]="{ cxRoute: 'login' } | cxUrl"
    >{{ 'efaForgotPasswordForm.cancelForgotPassword' | cxTranslate }}</a>
  </ng-template>
</form>

