import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class OrderInfoFieldsFormsValidationService {
  private forms: Map<string, UntypedFormGroup> = new Map();

  constructor() {}

  markAllAsTouched(): void {
    return Array.from(this.forms.values()).forEach((form: UntypedFormGroup) => form.markAllAsTouched());
  }

  add(key: string, formGroup: UntypedFormGroup): void {
    this.forms.set(key, formGroup);
  }

  getAllValid(): boolean {
    return Array.from(this.forms.values()).findIndex((form) => form.invalid) < 0; 
  }

  remove(key: string) {
    this.forms.delete(key);
  }

  reset(): void {
    this.forms.clear();
  } 
}
