<ng-container *ngIf="form$ | async as form">
  <div class="frame">
    <div class="c-list-items-header">
      <div class="c-list-items-header-content">
        <p class="c-list-items-header-text c-list-items-header-text--required">
          {{ 'orderCancellation.cancellationAndReturn.selectItems' | cxTranslate }}
        </p>
        <label *ngIf="!isConfirmation" class="form-check form-check-inline c-list-items-header-check-box">
          <input type="checkbox" [checked]="checkAll" (change)="onCheckAllChange(form, $event)"/>
          <span class="c-list-items-header-check-box-text">{{ 'orderCancellation.cancellationAndReturn.selectAll' | cxTranslate }}</span>
        </label>
      </div>
    </div>
    <div class="cancel-or-return-list">
      <table class="table table--responsive">
        <thead>
        <tr>
          <th scope="col" class="text-left">{{ 'orderCancellation.cancellationAndReturn.article' | cxTranslate }}</th>
          <th scope="col" class="text-lg-center">{{ 'orderCancellation.cancellationAndReturn.quantity' | cxTranslate }}</th>
          <th scope="col" class="text-lg-center">{{ 'orderCancellation.cancellationAndReturn.consignmentInfo' | cxTranslate }}</th>
          <th scope="col" class="text-lg-center" *ngIf="!isConfirmation"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of entries; let i = index">
          <tr>
            <!-- Item article manufacturerAID -->
            <td attr.data-label="{{ 'orderCancellation.cancellationAndReturn.article' | cxTranslate }}">
              <!-- Item article name -->
              <div *ngIf="item.product.name" class="cx-name u-font-bold">
                {{ item.product.name }}
                <ng-container *ngIf="item.product.baseOptions?.length">
                  <div *ngFor="
                        let variant of item.product.baseOptions[0]?.selected
                          ?.variantOptionQualifiers
                      "
                      class="cx-property">
                    <div class="cx-label" *ngIf="variant.name">
                      {{ variant.name }}:
                    </div>
                    <div class="cx-value" *ngIf="variant.value">
                      {{ variant.value }}
                    </div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="item.product.manufacturerAID">
                {{ item.product.manufacturerAID }}
                <span
                    *ngIf="item.product.manufacturer; else displayManufacturerId">&nbsp;/&nbsp;{{ item.product.manufacturer }}</span>
                  <ng-template #displayManufacturerId>
                    <span
                      *ngIf="item.product.manufacturerId">&nbsp;/&nbsp;{{ item.product.manufacturerId }}</span>
                  </ng-template>
              </div>
              <!-- Item SubstitutedOemNumber -->
              <div *ngIf="item.substitutedOemNumber">
                <span
                  class="u-font-bold">{{ 'orderCancellation.cancellationAndReturn.searchedArticleNumber' | cxTranslate }}
                  :&nbsp;</span>
                <span>{{ item.substitutedOemNumber }}</span>
              </div>
            </td>

            <!-- Item article quantity -->
            <td attr.data-label="{{ 'orderCancellation.cancellationAndReturn.quantity' | cxTranslate }}" class="table-col-quantity">
              <div class="cx-value text-lg-center">
                {{ item.quantity }}
              </div>
            </td>

            <!-- Item article consignmentInfo -->
            <td attr.data-label="{{ 'orderCancellation.cancellationAndReturn.consignmentInfo' | cxTranslate }}" class="table-col-consignment-info">
              <div *ngIf="item.consignmentInfo" class="text-lg-center">
                {{ item.consignmentInfo }}
              </div>
            </td>

            <td attr.data-label="{{ 'orderCancellation.cancellationAndReturn.selectItem' | cxTranslate }}" class="table-col-select" *ngIf="!isConfirmation">
              <div class="form-check text-lg-center">
                <input type="checkbox"
                      class="mt-0"
                      [checked]="getFormModel(item)"
                      (change)="onCheckChange(form, item, $event)"/>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <div *ngIf="!isConfirmation && form.invalid" class="row">
        <div class="col-12">
          <span class="cancel-or-return-list-selection-hint">
            <small class="cancel-or-return-list-selection-hint-text">
              {{ 'orderCancellation.selectedHint' | cxTranslate }}
              </small>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
