import {Component, OnInit} from '@angular/core';
import {StorefrontComponent} from '@spartacus/storefront';

@Component({
  selector: 'app-efa-storefront',
  templateUrl: './efa-storefront.component.html',
  styleUrls: ['./efa-storefront.component.scss']
})
export class EfaStorefrontComponent extends StorefrontComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

}
