import { NgModule } from '@angular/core';
// TODO:Spartacus - 'SmartEditModule' was removed. Use @spartacus/smartedit instead.
// To benefit from lazy loading it by default, consider removing the module import and running the command 'ng add @spartacus/smartedit'.
// TODO:Spartacus - 'PersonalizationModule' was removed. Use @spartacus/tracking/personalization instead.
// To benefit from lazy loading it by default, consider removing the module import
// and running the command 'ng add @spartacus/tracking --features=Personalization'.
import {
  AnonymousConsentsModule,
  AuthModule,
  ExternalRoutesModule,
  ProductModule,
  ProductOccModule,
  UserModule,
  UserOccModule
} from '@spartacus/core';
import {
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule,
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  ConsentManagementModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  HomePageEventModule,
  LinkModule,
  LoginRouteModule,
  LogoutModule,
  MyCouponsModule,
  MyInterestsModule,
  NavigationEventModule,
  NavigationModule,
  NotificationPreferenceModule,
  PageTitleModule,
  PaymentMethodsModule,
  PDFModule,
  ProductCarouselModule,
  ProductDetailsPageModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
  ProductReferencesModule,
  ProductSummaryModule,
  ProductTabsModule,
  ScrollToTopModule,
  SearchBoxModule,
  SiteContextSelectorModule,
  StockNotificationModule,
  TabParagraphContainerModule,
  VideoModule
} from '@spartacus/storefront';
import { AddressBookModule } from "@spartacus/user/profile/components";
import { CartBaseFeatureModule } from './features/cart/cart-base-feature.module';
import { CartImportExportFeatureModule } from './features/cart/cart-import-export-feature.module';
import { CartQuickOrderFeatureModule } from './features/cart/cart-quick-order-feature.module';
import { CartSavedCartFeatureModule } from './features/cart/cart-saved-cart-feature.module';
import { WishListFeatureModule } from './features/cart/wish-list-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { OrderFeatureModule } from './features/order/order-feature.module';
import { OrganizationAdministrationFeatureModule } from './features/organization/organization-administration-feature.module';
import { ProductImageZoomFeatureModule } from './features/product/product-image-zoom-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { UserFeatureModule } from './features/user/user-feature.module';
import { TagManagementFeatureModule } from './features/tracking/tag-management-feature.module';

@NgModule({
  declarations: [],
  imports: [
    // PersonalizationModule.forRoot(),
    // OccModule.forRoot(),
    ExternalRoutesModule.forRoot(),
    // Auth Core
    AuthModule.forRoot(),
    LogoutModule,
    LoginRouteModule,
    // Basic Cms Components
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,
    ScrollToTopModule,
    PageTitleModule,
    // User Core,
    UserModule,
    UserOccModule,
    // User UI,
    AddressBookModule,
    PaymentMethodsModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ConsentManagementModule,
    MyCouponsModule,
    // Anonymous Consents Core,
    AnonymousConsentsModule.forRoot(),
    // Anonymous Consents UI,
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,
    // Product Core,
    ProductModule.forRoot(),
    ProductOccModule,
    // Product UI,
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductIntroModule,
    // Cart Core,
    CartBaseFeatureModule,
    WishListFeatureModule,
    CartQuickOrderFeatureModule,
    CartImportExportFeatureModule,
    // Page Events,
    NavigationEventModule,
    HomePageEventModule,
    ProductPageEventModule,
    UserFeatureModule,
    SmartEditFeatureModule,
    OrganizationAdministrationFeatureModule,
    CartSavedCartFeatureModule,
    CheckoutFeatureModule,
    // Order
    OrderFeatureModule,
    ProductImageZoomFeatureModule,
    VideoModule,
    PDFModule,
    TagManagementFeatureModule
  ]
})
export class SpartacusFeaturesModule { }
